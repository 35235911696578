import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

interface Props {
  data: any;
  startingSN: number;
  search?: string;
}
type ObjectState = {
  website_active: boolean;
  payment_active: boolean;
};

const ObjektListItems: React.FC<Props> = (props) => {
  const { data, startingSN, search } = props;
  const { id: objectID } = data;

  const toast = useToast();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [objectState, setObjectState] = useState<ObjectState>({
    website_active: false,
    payment_active: false,
  });
  const apartmentAPI = new OMSObjectsResource();
  const queryClient = useQueryClient();
  const checkEditPermission = useIsPermitted([PermissionRequest['manage:oms']]);

  const updateObject = useMutation((data: any) =>
    apartmentAPI.update(objectID, data)
  );

  useEffect(() => {
    if (data) {
      let ob = {
        website_active: data.website_active,
        payment_active: data.payment_active,
      };
      setObjectState(ob);
    }
  }, [data]);

  function submitUpdate(data: any) {
    updateObject.mutate(data, {
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
        setObjectState({
          website_active: objectState.website_active,
          payment_active: objectState.payment_active,
        });
      },
    });
  }

  const deleteApartment = useMutation(() => apartmentAPI.destroy(objectID), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('userList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.user_deleted,
          status: 'success',
          isClosable: true,
        });
      } else {
        setDeleteMsg(strings.user_delete_error);
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    //TODO;
    // deleteApartment.mutate();
  };

  function handleChangeStatus(e: any) {
    let name = e.target.name;
    let checked = e.target.checked;
    let stateObj = { ...objectState, [name]: checked };
    setObjectState(stateObj);
    submitUpdate(stateObj);
  }
  function getType(type: string) {
    switch (type) {
      case 'home':
        return strings.house.toUpperCase();

      case 'apartment':
        return strings.flat.toUpperCase();

      default:
        return '';
    }
  }
  const { name, type, address }: any = data;
  const {
    country: { name: country },
    street,
    house_number,
  } = address;

  return (
    <Tr>
      <Td>{startingSN}</Td>
      <Td>
        <RouterLink
          to={
            routes.oms.objects.details.replace(':id', objectID.toString()) +
            search
          }>
          <Flex>
            <CustomTagTitle
              type="text"
              status={objectState.website_active}
              name={name || '-'}
            />
          </Flex>
        </RouterLink>
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={objectState.website_active}
          name={getType(type)}
        />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={objectState.website_active}
          name={`${country}, ${street}, ${house_number}`}
        />
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="payment_active"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={objectState.payment_active}
            isDisabled={!checkEditPermission}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="website_active"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={objectState.website_active}
            isDisabled={!checkEditPermission}
          />
        </Stack>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.oms.objects.details.replace(':id', objectID.toString()) +
            search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        {/* <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip> */}
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {strings.delete} {strings.object}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[PermissionRequest['read:oms']]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    colorScheme="red"
                    onClick={onDeleteConfirm}
                    isLoading={deleteApartment.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ObjektListItems;

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import React from 'react';

interface Props {
  createdBy: any;
  createdAt: string;
  lastChangedBy: any;
  lastChangedAt: string;
  source: any;
}

const TicketAuthor: React.FC<Props> = (props) => {
  const { createdBy, createdAt, lastChangedBy, lastChangedAt, source } = props;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.author}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.created_by}</FormLabel>
              {createdBy && (
                <Box sx={infoStyles}>
                  <CustomTagTitle
                    type="text"
                    status={createdBy.status}
                    name={createdBy.name}
                  />
                </Box>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.created_at}</FormLabel>
              <Box sx={infoStyles}>{createdAt}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.last_modified_by}</FormLabel>
              {lastChangedBy && (
                <Box sx={infoStyles}>
                  <CustomTagTitle
                    type="text"
                    status={lastChangedBy.status}
                    name={lastChangedBy.name}
                  />
                </Box>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.last_modified_at}</FormLabel>
              <Box sx={infoStyles}>{lastChangedAt}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.source}</FormLabel>
              <Box sx={infoStyles}>
                {strings.getString(source.toLowerCase())}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default TicketAuthor;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import UserDocumentResource from 'api/user_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UserGroupDocumentListItem from 'components/user_document/UserGroupDocumentListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCloudUpload, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
}

const UsersDocumentList: React.FC = () => {
  let userDocumentAPI = new UserDocumentResource();
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword');

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: url_keyword ?? '',
  });

  const userDocumentList = useQuery(
    [
      'userDocumentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await userDocumentAPI.userGrouplist(queryParams);
      response && setIsFilterLoading(false);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.dms.userDocuments.list);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
            {strings.dms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.dms.userDocuments.list}>
            {strings.all_user_documents}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.document_users}
        </Heading>
        <RouterLink to={routes.dms.userDocuments.upload}>
          <Button
            colorScheme="primary"
            leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
            size="lg">
            {strings.upload_document}
          </Button>
        </RouterLink>
      </Flex>

      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="20px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form onSubmit={(e) => e.preventDefault()}>
                <Stack
                  direction={['column', 'row']}
                  spacing="4"
                  align={['start', 'end']}>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>

                  <Stack direction="row" spacing="4">
                    <Button
                      size="lg"
                      colorScheme="primary"
                      variant="outline"
                      onClick={handleReset}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.user_name}</Th>
                <Th isNumeric>{strings.no_of_documents}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!userDocumentList.isLoading &&
                userDocumentList?.data?.data?.map(
                  (userDocumentData: any, index: number) => (
                    <UserGroupDocumentListItem
                      key={userDocumentData?.user_id}
                      index={startingSN + index}
                      userGroupDocumentData={userDocumentData}
                      search={search}
                    />
                  )
                )}

              {userDocumentList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={3} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={userDocumentList}
      />
    </Stack>
  );
};

export default UsersDocumentList;

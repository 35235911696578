import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { Merge } from 'assets/icons';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ContactDetailInfo from 'components/crm/ContactDetailInfo';
import EventLogs from 'components/crm/EventLogs';
import LabelTags from 'components/crm/LabelTags';
import MergeHistoryDetails from 'components/crm/MergeHistoryDetails';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiEnvelopeOpen } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const ContactDetails: React.FC = () => {
  const { id: contactID }: any = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const contactAPI = new CRMContactsResource();

  const { search } = useLocation();

  let { data: mergeDetail } = useQuery(
    [`get-merge-detail`],
    () =>
      contactAPI
        .mergeDetails(contactID, { merge: 'direct' })
        .then((res) => res.data.data)
        .catch(),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  let { data: contactDetails, isLoading } = useQuery(
    ['get-contact-details'],
    () =>
      contactAPI
        .get(contactID)
        .then((res) => res.data.data)
        .catch(),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <CenterSpinner />;
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.contact_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list + search}>
            {strings.contact}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {contactDetails?.first_name ?? '-'}{' '}
            {contactDetails?.last_name ?? '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex
        justify="space-evenly"
        direction={['column', 'column', 'row', 'row']}
        overflow="auto">
        <Stack
          direction="column"
          spacing="4"
          flex="1"
          w={['100%', '100%', '60%', '60%']}>
          <Flex justify="space-between">
            <Flex>
              <Heading size="lg">{strings.contact_details}</Heading>
              <Stack ml="2" justify="space-between">
                <LabelTags label={contactDetails?.type} />
                {contactDetails?.contactDetail.is_merged && (
                  <Box w="6" h="6" ml="2" color="orange.300">
                    <Image src={Merge} />
                  </Box>
                )}
              </Stack>
            </Flex>
            <Flex ml="2" dir="row" justify="space-between">
              <Box pr="2">
                {contactDetails?.email && (
                  <a href={`mailto:${contactDetails?.email}`}>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={contactDetails?.email}>
                      <IconButton
                        aria-label={strings.mails}
                        icon={<BiEnvelopeOpen />}
                        variant="outline"
                        size="lg"
                        colorScheme="yellow"
                      />
                    </Tooltip>
                  </a>
                )}
              </Box>
              <RouterLink
                to={
                  routes.crm.contact.edit.replace(':id', contactID.toString()) +
                  search +
                  `&contactType=${contactDetails?.type}`
                }>
                <Button colorScheme="primary" size="lg">
                  {strings.edit_contact}
                </Button>
              </RouterLink>
            </Flex>
          </Flex>
          <Stack
            bg="white"
            p={['3', '6']}
            shadow="box"
            rounded="sm"
            overflow="auto">
            <ContactDetailInfo data={contactDetails} />

            {contactDetails?.contactDetail.is_merged && (
              <Box
                color="primary.500"
                _hover={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}>
                <Box onClick={onOpen}>
                  <Flex>
                    <Box w="6" h="6" mr="2" color="orange.300">
                      <Image src={Merge} />
                    </Box>
                    {strings.merge_detail}
                  </Flex>
                </Box>
                <Modal isOpen={isOpen} onClose={onClose} size="6xl">
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>{strings.merge_detail}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflowX="auto">
                      <MergeHistoryDetails data={mergeDetail} />
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack
          direction="column"
          spacing="4"
          mt={['2', '2']}
          pl={['0', '0', '5', '5']}
          w={['100%', '100%', '40%', '40%']}
          overflow="auto">
          <Stack>
            <Heading
              size="lg"
              textTransform="capitalize"
              mb={contactDetails?.contactDetail.is_merged ? '2' : '1'}>
              {strings.event_history}
            </Heading>
          </Stack>
          {contactDetails && <EventLogs eventLogs={contactDetails} />}
        </Stack>
      </Flex>
    </Stack>
  );
};

export default ContactDetails;

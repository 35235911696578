import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ScaleFade,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import GoogleAuthentication from 'components/auth/GoogleAuthentication';
import LogoInformation from 'components/auth/LogoInformation';
import LanguageMenu from 'components/common/LanguageMenu';
import { withAuthState } from 'components/hoc/auth';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { UserLoginType } from 'constants/schema';
import React, { CSSProperties, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiEnvelope, BiLock } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { validEmail } from 'utils/validate';

interface LoginFormInputs {
  email: string;
  password: string;
  verifyCode?: string;
  role_group: UserLoginType;
}

const hideFormStyles: CSSProperties = {
  visibility: 'hidden',
  height: '0px',
  padding: '0px',
  opacity: 0,
};

interface Props {
  login: (
    email: string,
    pw: string,
    role_group: UserLoginType,
    verifyCode?: string
  ) => any;
}

const Login: React.FC<Props> = (props) => {
  const { login } = props;

  const methods = useForm<LoginFormInputs>();
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = methods;
  const [isError, setIsError] = useState(false);
  const [twoFA, setTwoFA] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      if (!data?.verifyCode) {
        delete data?.verifyCode;
      }
      const res = await login(
        data.email,
        data.password,
        data.role_group,
        data.verifyCode
      );

      if (res === 'enable_2fa') {
        setIsError(false);
        setTwoFA(true);
      }
    } catch (e) {
      setIsError(true);
    }
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Stack pos="absolute" right="5%">
        <LanguageMenu />
      </Stack>
      <Stack direction="column" spacing="12" alignItems="center" py="6">
        <LogoInformation />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              bg="white"
              p="14"
              shadow="box"
              rounded="sm"
              style={twoFA ? hideFormStyles : {}}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    {strings.log_in}
                  </Heading>
                  <Text color="gray.500">{strings.login_message}</Text>
                  {isError && (
                    <ScaleFade in={isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {strings.invalid_login_msg}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>
                <Stack direction="column" spacing="5">
                  <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                    <FormLabel color="gray.600">{strings.email}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.300" w="4" h="4" as={BiEnvelope} />
                        }
                      />
                      <Input
                        id="email"
                        type="email"
                        placeholder={strings.your_email_address}
                        {...register('email', {
                          required: strings.email_required,
                          validate: (value) =>
                            validEmail(value) || strings.valid_email_address,
                        })}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.email && errors.email?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                    <FormLabel color="gray.600">{strings.password}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.300" w="4" h="4" as={BiLock} />
                        }
                      />
                      <Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        colorScheme="green"
                        placeholder={strings.your_password}
                        {...register('password', {
                          required: strings.required_password,
                        })}
                      />
                      <InputRightElement
                        onClick={() => setShowPassword(!showPassword)}
                        cursor="pointer"
                        children={
                          <Icon
                            color="gray.500"
                            w="4"
                            h="4"
                            as={showPassword ? FiEyeOff : FiEye}
                          />
                        }
                      />
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.password && errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.role_group}>
                    <FormLabel color="gray.600">{strings.type}</FormLabel>
                    <Select
                      id="role_group"
                      placeholder={strings.select_type}
                      rounded="sm"
                      {...register('role_group', {
                        required: strings.required_type,
                      })}>
                      <option value="service_center">{strings.admin}</option>
                      <option value="owner">{strings.owner}</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.role_group && errors.role_group?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <Flex justify="space-between">
                      <Checkbox color="gray.500" colorScheme="primary">
                        {strings.remember_me}
                      </Checkbox>

                      <Button
                        variant="link"
                        color="gray.600"
                        fontWeight="medium">
                        <Link to={routes.auth.forgotPassword}>
                          {strings.forgot_password}
                        </Link>
                      </Button>
                    </Flex>
                  </FormControl>
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    fontSize="md"
                    fontWeight="semibold"
                    colorScheme="primary"
                    textTransform="uppercase"
                    boxShadow="md"
                    isLoading={formState.isSubmitting}>
                    {strings.login_now}
                  </Button>
                </Stack>
              </Stack>
            </Box>

            {twoFA && (
              <GoogleAuthentication
                setTwoFA={setTwoFA}
                isError={isError}
                setIsError={setIsError}
              />
            )}
          </form>
        </FormProvider>
      </Stack>
    </Box>
  );
};

export default withAuthState(Login);

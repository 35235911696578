import {
  Box,
  Button,
  Center,
  FormControl,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
} from '@chakra-ui/react';
import { Thead, Tr } from '@chakra-ui/table';
import CRMEventLogResource from 'api/crm-eventLogs';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import { EventLogSchema } from 'constants/schema';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  BiBookBookmark,
  BiCalendar,
  BiChat,
  BiPurchaseTag,
} from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';

interface Props {
  eventLogs: any;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';
const EventLogs: React.FC<Props> = (props) => {
  let { eventLogs } = props;
  let contactID: any = [];

  if (eventLogs.contactDetail.is_merged) {
    contactID = eventLogs.contactMergeDetail.details.event;
  } else {
    contactID = [eventLogs.contactDetail.contact_id];
  }

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [eventName, setEventName] = useState<string>('');
  const [Events, setEvents] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<any>({
    page: INITIAL_CURRENT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    event: '',
    event_time_from: '',
    event_time_to: '',
  });
  const EventLogAPI = new CRMEventLogResource();

  const { data: EventList, isLoading: eventLoading } = useQuery(
    [
      `get-event-logs`,
      {
        page: filterParams.page,
        size: filterParams.size,
        event: filterParams.event,
        event_time_from: filterParams.event_time_from,
        event_time_to: filterParams.event_time_to,
      },
    ],
    async () => {
      let queryParams: any = {
        contact_id: `[${contactID}]`,
        page: filterParams.page,
        size: filterParams.size,
      };
      if (filterParams.event) queryParams.event = filterParams.event;
      if (filterParams.event_time_from)
        queryParams.event_time_from = DateFormatYMD(
          filterParams.event_time_from
        );
      if (filterParams.event_time_to)
        queryParams.event_time_to = DateFormatYMD(filterParams.event_time_to);
      try {
        const res = await EventLogAPI.list(queryParams);
        setEvents((prev: any) => [...prev, ...res.data.data.data]);
        return res.data.data;
      } catch (err) {
        return err;
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { visible, observer } = useIntersectionObserver(eventLoading);

  useEffect(() => {
    if (visible) {
      setFilterParams((prev: any) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [visible]);

  const handleAdvancedSearch = useCallback(() => {
    setEvents([]);
    setFilterParams((prevState: any) => ({
      ...prevState,
      page: INITIAL_CURRENT_PAGE,
      event_time_from: startDate || '',
      event_time_to: endDate || '',
      event: eventName,
    }));
  }, [startDate, endDate, eventName]);

  useEffect(() => {
    handleAdvancedSearch();
  }, [handleAdvancedSearch]);

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };
  function getDateAndTime(date: string) {
    let dateformat = DateFormat(date);
    let time = date.split('T')[1];
    return [dateformat, time];
  }
  function getEventUrl(event: string, event_id: string | number) {
    let url = '';
    switch (event) {
      case 'ticket':
        url = `/ticket/task/view/${event_id}`;
        break;
      case 'booking':
        url = `/bms/booking/details/${event_id}`;
        break;
      default:
        url = '#';
        break;
    }
    return url;
  }
  //reset form
  function resetForm() {
    if (startDate || endDate || eventName) setEvents([]);
    setStartDate(null);
    setEndDate(null);
    setEventName('');
    setFilterParams({
      page: INITIAL_CURRENT_PAGE,
      size: DEFAULT_PAGE_SIZE,
      event: '',
      event_time_from: '',
      event_time_to: '',
    });
  }

  const getEventChannelIcon = (event: string) => {
    switch (event) {
      case 'ticket':
        return <Icon as={BiPurchaseTag} />;
      case 'chat':
        return <Icon as={BiChat} />;
      case 'booking':
        return <Icon as={BiBookBookmark} />;
      default:
        return null;
    }
  };

  return (
    <Stack sx={wrapperStyles}>
      <form>
        <Stack direction="row" spacing="4" align="end" bg="white" shadow="box">
          <Grid gap="4" templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}>
            <GridItem>
              <FormControl>
                <Select
                  placeholder={strings.select}
                  rounded="sm"
                  onChange={(e) => setEventName(e.target.value)}
                  value={eventName}>
                  <option value="chat">{strings.chat}</option>
                  <option value="booking">{strings.booking}</option>
                  <option value="ticket">{strings.ticket}</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<BiCalendar />}
                    color="gray.500"
                  />
                  <ReactDatePicker
                    placeholderText={strings.select_date}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CreatedAtInput />}
                    onChange={handleDateChange}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    value={getFormattedRangeDate(startDate, endDate)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                    portalId="root-popover"
                  />
                </InputGroup>
              </FormControl>
            </GridItem>
          </Grid>
          <Stack direction={['column', 'row']}>
            <Button
              colorScheme="primary"
              type="button"
              variant="outline"
              fontSize="xs"
              onClick={resetForm}>
              {strings.reset_filter}
            </Button>
          </Stack>
        </Stack>
      </form>

      <Box overflow="auto" maxHeight="26.5rem" minHeight="26.5rem">
        <Table>
          <Thead>
            <Tr>
              <Th>{strings.chanel}</Th>
              <Th isNumeric>{strings.date_time}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Events?.map((item: EventLogSchema, index: number) => {
              if (
                Events.length === index + 1 &&
                EventList?.meta?.last_page !== EventList?.meta?.current_page
              ) {
                return (
                  <Tr key={item.id} ref={observer}>
                    <Td>
                      <Link to={getEventUrl(item.event, item.event_id)}>
                        {getEventChannelIcon(item.event)}&nbsp;
                        {`${strings[item.event]} #${item.event_id}`}
                      </Link>
                    </Td>
                    <Td isNumeric>
                      {`${getDateAndTime(item.event_time)[0]}, ${getDateAndTime(
                        item.event_time
                      )[1].slice(0, 5)}`}
                    </Td>
                  </Tr>
                );
              } else {
                return (
                  <Tr key={item.id}>
                    <Td>
                      <Link to={getEventUrl(item.event, item.event_id)}>
                        {getEventChannelIcon(item.event)}&nbsp;
                        {`${strings[item.event]}  #${item.event_id}`}
                      </Link>
                    </Td>
                    <Td isNumeric>
                      {`${getDateAndTime(item.event_time)[0]}, ${getDateAndTime(
                        item.event_time
                      )[1].slice(0, 5)}`}
                    </Td>
                  </Tr>
                );
              }
            })}
            {EventList?.data?.length === 0 && (
              <Tr>
                <Td colSpan={2} textAlign="center" color="gray.500">
                  {strings.no_data_found}
                </Td>
              </Tr>
            )}
            {eventLoading && (
              <Tr>
                <Td colSpan={6}>
                  <Center color="primary.500">
                    <Spinner size="lg" thickness="3px" />
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  );
};

export default EventLogs;

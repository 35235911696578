import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import BMSCheckOutResource from 'api/bms-check-out';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { BMSCheckInOutBooking } from 'constants/schemas/bookings';
import React, { useEffect, useMemo, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import CheckInCheckOutListItem from './CheckInCheckOutListItem';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  booking_number: string;
  last_name: string;
  first_name: string;
}

const CheckOut: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    booking_number: searchValues.booking_number ?? '',
    first_name: searchValues.first_name ?? '',
    last_name: searchValues.last_name ?? '',
  });

  const bmsCheckOutAPI = new BMSCheckOutResource();

  const bookingListData = useQuery(
    [
      'checkout-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        booking_number: filterParams.booking_number,
        last_name: filterParams.last_name,
        first_name: filterParams.first_name,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.booking_number)
        queryParams.booking_number = filterParams.booking_number;
      if (filterParams.first_name)
        queryParams.first_name = filterParams.first_name;
      if (filterParams.last_name)
        queryParams.last_name = filterParams.last_name;

      return bmsCheckOutAPI
        .list(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch();
    }
  );
  const bookingList: BMSCheckInOutBooking[] = bookingListData?.data?.data;

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      booking_number: '',
      last_name: '',
      first_name: '',
    });
    history.push(routes.bms.checkInCheckOut.list);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form>
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <FormControl>
                      <FormLabel>{strings.booking_number}</FormLabel>

                      <InputGroup>
                        <Input
                          type="text"
                          name="booking_number"
                          size="lg"
                          value={filterParams.booking_number}
                          placeholder={strings.booking_number}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl>
                      <FormLabel>{strings.first_name}</FormLabel>

                      <InputGroup>
                        <Input
                          type="text"
                          name="first_name"
                          size="lg"
                          value={filterParams.first_name}
                          placeholder={strings.first_name}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl>
                      <FormLabel>{strings.last_name}</FormLabel>

                      <InputGroup>
                        <Input
                          type="text"
                          name="last_name"
                          size="lg"
                          value={filterParams.last_name}
                          placeholder={strings.last_name}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                    <Stack direction="row" spacing="4">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        type="button"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.booking_number}</Th>
                  <Th>{strings.customer_name}</Th>
                  <Th>{strings.apartment_name}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {bookingList?.map((checkOut) => (
                  <CheckInCheckOutListItem
                    checkInCheckOutItem={checkOut}
                    key={checkOut.id}
                    status="Check-Out"
                  />
                ))}
                {bookingListData.isLoading && (
                  <TableSkeletonLoader rows={7} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          dataList={bookingListData}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default CheckOut;

import {
  Box,
  ButtonProps,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import CustomTagTitle from 'components/CustomTagTitle';
import DateFromNowToolTip from 'components/DateFromNowToolTip';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { truncateString } from 'utils';

interface Props {
  ticketId: any;
}
const outerLimit = 2;
const innerLimit = 2;
const TicketLogInfo: React.FC<Props> = (props) => {
  const { ticketId } = props;
  const ticketApi = new TicketResource();
  const { ticketLogInfo } = useSelector(
    (state: any) => ({
      ticketLogInfo: state?.data?.ticket.ticketLogInfo,
    }),
    shallowEqual
  );

  /** Pagination */
  // constants
  const [logTotal, setLogTotal] = useState<number | undefined>(undefined);

  const { pagesQuantity, currentPage, setCurrentPage, pageSize } = usePaginator(
    {
      total: logTotal,
      initialState: {
        pageSize: 5,
        currentPage: 1,
      },
    }
  );

  const fetchTicketLogInfo = async () => {
    return await ticketApi.getTickeLog(ticketId, currentPage, pageSize);
  };

  const { data: ticketLogData } = useQuery(
    ['fetchTicketLogInfo', currentPage, pageSize, ticketLogInfo],
    () => fetchTicketLogInfo()
  );

  const mutatedData = React.useMemo(() => {
    setLogTotal(ticketLogData?.data.count);
    return ticketLogData;
  }, [ticketLogData]);

  const getTagTitle = (status: boolean, name: string) => {
    return (
      <CustomTagTitle
        type="text"
        status={status}
        name={name}
        bg="gray.300"
        color="gray.400"
      />
    );
  };

  const getTransferLog = (data: any) => {
    const txtQty = `${data.updated_attributes?.quantity} ${data.updated_attributes?.article_id?.unit}`;
    const txtArticle = `${data.updated_attributes?.article_id?.label}`;
    let txtSource = strings.fresh;
    if (data.updated_attributes?.source_entity_id)
      txtSource = data.updated_attributes?.source_entity_id?.label;
    let txtDestination = strings.consumed;
    if (data.updated_attributes?.destination_entity_id)
      txtDestination = data.updated_attributes?.destination_entity_id?.label;
    return ` ${strings.transferred} ${txtQty} ${strings.article}  ${txtArticle} ${strings.from} ${strings.source}: ${txtSource} ${strings.to} ${strings.destination}: ${txtDestination}`;
  };

  const getLogDescription = (data: any) => {
    switch (data.action) {
      case 'create':
        return ` ${strings.created_ticket} #${data.updated_attributes?.title}`;
      case 'update':
        return ` ${strings.updated_ticket}`;
      case 'status':
        return ` ${strings.changed_status} ${strings.to} #${data.updated_attributes?.status}`;
      case 'assign':
        return ` ${strings.assigned_ticket} ${strings.to} ${data.updated_attributes?.assigned_user_id}`;
      case 'comment':
        return ` ${strings.commented} ${truncateString(
          data.updated_attributes?.comment,
          50
        )}`;
      case 'transfer':
        return getTransferLog(data);
    }
  };

  // styles
  const baseStyles: ButtonProps = {
    w: 7,
    fontSize: 'sm',
  };

  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: 'primary.300',
    },
    bg: 'gray.300',
  };

  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: 'primary.300',
    },
    bg: 'primary.300',
  };

  const separatorStyles: ButtonProps = {
    w: 7,
    bg: 'gray.200',
  };

  const paginationContentStyles = {
    borderTop: '1px',
    borderColor: 'gray.300',
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  return (
    <>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.ticket_log}
        </Heading>
      </Flex>
      <Box
        bg="gray.50"
        p={['3', '6']}
        shadow="box"
        rounded="sm"
        minH="180px"
        position="relative"
        overflow="auto">
        <UnorderedList color="gray.500" pb="8">
          {mutatedData?.data?.data?.map((res: any, index: number) => {
            return (
              <ListItem key={index} padding={1}>
                <Flex justifyContent="space-between">
                  <Flex>
                    <Text mr="2">
                      {getTagTitle(
                        res.created_by_user?.status,
                        res.created_by_user?.name
                      )}
                    </Text>
                    <Text>{getLogDescription(res)}</Text>
                  </Flex>
                  <Box textAlign="right" fontSize="xs" color="gray.400">
                    <DateFromNowToolTip dateLabel={res.createdAt} />
                  </Box>
                </Flex>
              </ListItem>
            );
          })}
        </UnorderedList>
        {mutatedData?.data.count > 5 && (
          <Paginator
            activeStyles={activeStyles}
            currentPage={currentPage}
            normalStyles={normalStyles}
            separatorStyles={separatorStyles}
            pagesQuantity={pagesQuantity}
            outerLimit={outerLimit}
            innerLimit={innerLimit}
            onPageChange={handlePageChange}>
            <Container
              align="center"
              justifyContent="center"
              w="full"
              p={2}
              position="absolute"
              left="0"
              right="0"
              bottom="0"
              sx={paginationContentStyles}>
              <Previous mr="2">{strings.previous}</Previous>
              <PageGroup isInline align="center" />
              <Next ml="2">{strings.next}</Next>
            </Container>
          </Paginator>
        )}
      </Box>
    </>
  );
};
export default TicketLogInfo;

export const messageSentStyles = {
  alignSelf: 'flex-end',
  textAlign: 'left',
  maxW: 'calc(100% - 80px)',
  direction: 'column',
  bg: '#8DA2FB',
  p: '2',
  borderTopLeftRadius: 'md',
  borderTopRightRadius: '2xl',
  borderBottomRightRadius: 'sm',
  borderBottomLeftRadius: '2xl',
};
export const messageSentDateTimeStyled = {
  alignSelf: 'flex-end',
  textAlign: 'right',
  maxW: 'calc(100% - 80px)',
  direction: 'column',
};
export const messageReceiveStyled = {
  direction: 'column',
  spacing: '1',
  bg: '#EBECF2',
  width: 'fit-content',
  maxW: 'calc(100% - 80px)',
  p: '2',
  borderTopLeftRadius: '2xl',
  borderTopRightRadius: 'md',
  borderBottomRightRadius: '2xl',
  borderBottomLeftRadius: 'sm',
};

import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { shallowEqual, useSelector } from 'react-redux';
import { TimeFormatHMA } from 'utils/DateFormat';
import ChatListItem from './ChatListItem';

interface Props {
  phoneRingingRoom: string;
  callInProgressRoom: string;
}

const ChatSearch: React.FC<Props> = (props) => {
  const { phoneRingingRoom, callInProgressRoom } = props;

  const { chatItemList, loggedInUser } = useSelector(
    (state: any) => ({
      chatItemList: state?.data?.chat.chatItemList,
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const getChatUsersList = () => {
    if (chatItemList.length < 1) {
      return (
        <Box
          bg="#F9F9F9"
          p="3"
          position="relative"
          rounded="sm"
          w="300px"
          maxWidth="300px"
          maxHeight="20">
          <Flex direction="row" alignItems="center">
            <Avatar bg="gray.400" color="white" size="lg" name="Status" />
            <Flex direction="column" ml={3}>
              <Heading
                mb="1"
                size="sm"
                color="chat.400"
                fontWeight="medium"
                wordBreak="break-all">
                #status
              </Heading>
              <Text fontSize="sm">{strings.no_chat_assigned}</Text>
            </Flex>
          </Flex>
        </Box>
      );
    }

    return (
      <Stack direction="column" spacing="6" w="300px">
        <Box maxH="80vh" overflow="auto">
          <PerfectScrollbar>
            <Stack direction="column" rounded="md" spacing={0}>
              {chatItemList?.map((data: any) => (
                <ChatListItem
                  key={data._id}
                  name={data.name}
                  roomName={data.room_name}
                  message={data.message}
                  unreadChatCount={data?.employee_unread_chat}
                  avatar={data.avatar}
                  chatId={data._id}
                  date={TimeFormatHMA(data.last_chat?.time)}
                  phoneRingingRoom={phoneRingingRoom}
                  callInProgressRoom={callInProgressRoom}
                  lastChatMessage={data.last_chat.message}
                />
              ))}
            </Stack>
          </PerfectScrollbar>
        </Box>
      </Stack>
    );
  };

  return (
    <Stack shadow="box" bg="#F9F9F9" direction="column" p="3">
      <Stack
        direction={['column', 'column', 'row', 'row']}
        p={['1', '2', '4', '4']}
        spacing="2"
        width="100%">
        <Avatar
          shadow="md"
          src={loggedInUser?.profile_picture_url}
          size={'md'}
          name={`${loggedInUser.first_name} ${loggedInUser.last_name}`}
          bg="gray.400"
          color="white"
        />
        <Stack direction="column" spacing="0">
          <Flex
            justify="space-between"
            direction={['column', 'column', 'row', 'row']}>
            <Heading size="sm" fontWeight="medium" wordBreak="break-all">
              {loggedInUser.first_name} {loggedInUser.last_name}
            </Heading>
          </Flex>
          <Flex justifyContent="space-between">
            <Text textTransform="capitalize" fontSize="sm">
              {loggedInUser.role}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      <Divider borderColor="gray.400" />
      {getChatUsersList()}
    </Stack>
  );
};

export default ChatSearch;

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  RadioGroup,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';
import RoundCustomRadio from 'components/common/RoundCustomRadio';
import { strings } from 'config/localization';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
interface Props {
  data?: any;
}

const GroupForm: React.FC<Props> = (props) => {
  let { data } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();
  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('status', data.status.toString());
    }
  }, [data, setValue]);

  const handleStatusChange = (value: string) => {
    setValue('status', value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'StatusRadio',
    defaultValue: data?.status.toString() ?? '0',
    onChange: handleStatusChange,
  });

  const group = getRootProps();

  return (
    <Stack direction="column" spacing="8">
      <Grid gap="4" templateColumns="repeat(1, 1fr)" w={['100%', '50%']}>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>{strings.name}</FormLabel>
            <Input
              {...register('name', {
                required: strings.required_group_name,
                validate: (value) => {
                  if (value?.trim() === '') return strings.required_group_name;
                },
              })}
              type="text"
              size="lg"
              placeholder={strings.name}
              defaultValue={data?.name}
            />
            <FormErrorMessage>
              <>{errors.name && errors.name?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.status} isRequired>
            <FormLabel>{strings.status}</FormLabel>
            <Controller
              name="status"
              rules={{
                required: strings.required_status,
              }}
              defaultValue={data?.status ?? '0'}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} id="status">
                  <Flex my="2" direction="row" {...group}>
                    <RoundCustomRadio {...getRadioProps({ value: '1' })}>
                      {strings.active}
                    </RoundCustomRadio>
                    <RoundCustomRadio {...getRadioProps({ value: '0' })}>
                      {strings.in_active}
                    </RoundCustomRadio>
                  </Flex>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              <>{errors?.status && errors?.status?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default GroupForm;

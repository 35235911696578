import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomSelect from 'components/common/CustomSelect';
import { strings } from 'config/localization';
import { BMS_APARTMENT_SEARCH_API } from 'constants/common';
import routes from 'constants/routes';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cleanData } from 'utils';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

interface Props {
  handleAdvancedSearch: any;
  filterParams: any;
  handleResetSearch?: () => void;
}

const initialValue = {
  invoice_number: '',
  name: '',
  created_at_from: '',
  created_at_to: '',
  apartment_id: '',
  apartment: '',
  invoice_type: '',
};

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const InvoicesSearch: React.FC<Props> = (props) => {
  const { handleAdvancedSearch, filterParams } = props;
  const history = useHistory();

  const { role } = useSelector((state: any) => ({
    role: state?.data?.auth?.user.role,
  }));

  const DefaultValues: any = {
    invoice_number: filterParams.invoice_number ?? '',
    name: filterParams.name ?? '',
    created_at_from: filterParams.created_at_from ?? '',
    created_at_to: filterParams.created_at_to ?? '',
    invoice_type: filterParams.invoice_type ?? '',
  };
  if (filterParams.apartment_id && filterParams.apartment) {
    DefaultValues.apartment = {
      id: filterParams.apartment_id,
      name: filterParams.apartment,
    };
  }

  const [formValue, setFormValue] = useState<any>(DefaultValues);
  const [startDate, setStartDate] = useState<Date | null>(
    filterParams?.created_at_from
      ? new Date(filterParams?.created_at_from)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    filterParams?.created_at_to ? new Date(filterParams?.created_at_to) : null
  );

  let firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const finalData: any = cleanData(formValue);
    if (startDate) finalData.created_at_from = DateFormatYMD(startDate);
    if (endDate) finalData.created_at_to = DateFormatYMD(endDate);
    handleAdvancedSearch(finalData);
  }, [endDate, formValue, handleAdvancedSearch, startDate]);

  const onApartmentChange = (item: any) => {
    setFormValue((prev: any) => ({ ...prev, apartment: item }));
  };
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleReset = () => {
    setFormValue(initialValue);
    setStartDate(null);
    setEndDate(null);
    history.push(routes.bms.invoices.list);
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={['repeat(auto-fit, minmax(150px, 1fr))']}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.invoice_number}</FormLabel>
                  <Input
                    type="text"
                    name="invoice_number"
                    placeholder={strings.invoice_number}
                    value={formValue.invoice_number}
                    onChange={handleInputChange}
                    size="lg"
                  />
                </FormControl>
              </GridItem>
              {role !== 'owner' && (
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.name}</FormLabel>
                    <Input
                      type="text"
                      name="name"
                      placeholder={strings.name}
                      value={formValue.name}
                      onChange={handleInputChange}
                      size="lg"
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.date_of_invoice}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="dd.MM.yyyy"
                      customInput={<CreatedAtInput />}
                      onChange={handleDateChange}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                      portalId="root-popover"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.object}</FormLabel>
                  <CustomSelect
                    placeholder={strings.select_apartment}
                    value={formValue.apartment}
                    onChange={onApartmentChange}
                    SEARCH_API={BMS_APARTMENT_SEARCH_API}
                    limit={30}
                    replaceNameInOption="apartment"
                  />
                </FormControl>
              </GridItem>
              {role !== 'owner' && (
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.invoice_type}</FormLabel>
                    <Select
                      name="invoice_type"
                      value={formValue.invoice_type}
                      placeholder={strings.invoice_type}
                      onChange={handleInputChange}
                      size="lg">
                      <option value="owner">{strings.owner_invoice}</option>
                      <option value="customer">
                        {strings.customer_invoice}
                      </option>
                    </Select>
                  </FormControl>
                </GridItem>
              )}
            </Grid>
          </Stack>

          <Stack direction="row" justify="flex-end" mt="6">
            <Button
              colorScheme="primary"
              variant="outline"
              onClick={handleReset}
              size="lg">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default InvoicesSearch;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ServiceGroupListItem from 'components/service_group/ServiceGroupListItem';
import { strings } from 'config/localization';
import {
  BOOKING_FEE_TYPE_OPTIONS,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ServiceGroupSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { v4 as uuidv4 } from 'uuid';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  booking_fee_type: string;
  keyword: string;
}

const ServiceGroupList: React.FC = () => {
  const queryClient = useQueryClient();
  const serviceGroupAPI = new SMSServiceGroupResource();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    booking_fee_type: searchValues.booking_fee_type ?? '',
    keyword: searchValues.keyword ?? '',
  });

  const serviceGroupList = useQuery(
    [
      'serviceGroupList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        booking_fee_type: filterParams.booking_fee_type,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.booking_fee_type)
        queryParams.booking_fee_type = filterParams.booking_fee_type;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await serviceGroupAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      booking_fee_type: '',
      keyword: '',
    }));
    history.push(routes.sms.serviceGroups.list);
  };

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination?.index === source?.index) return;
    const sourceId =
      serviceGroupList?.data?.data?.[source?.index].serial_number;
    const destinationId =
      serviceGroupList?.data?.data?.[destination?.index].serial_number;

    await serviceGroupAPI.updateSerialNumber(sourceId, destinationId);
    await queryClient.invalidateQueries('serviceGroupList');
  };

  const checkAddServiceGroupPermission = useCheckPermission(
    [PermissionRequest['manage:sms']],
    routes.sms.serviceGroups.add
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.service_management}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.list}>
              {strings.service_groups}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.service_groups}
          </Heading>
          <Button
            colorScheme="primary"
            size="lg"
            onClick={checkAddServiceGroupPermission}>
            {strings.add_service_group}
          </Button>
        </Flex>

        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          h="100%"
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.800"
                        />
                        <Input
                          size="lg"
                          name="keyword"
                          placeholder={strings.search}
                          value={filterParams.keyword}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.booking_fee_calculation}</FormLabel>
                      <Select
                        name="booking_fee_type"
                        size="lg"
                        value={filterParams.booking_fee_type}
                        placeholder={strings.select_calculation}
                        rounded="sm"
                        onChange={handleInputChange}>
                        {BOOKING_FEE_TYPE_OPTIONS?.map((type: any) => {
                          return (
                            <option key={type.value} value={type.value}>
                              {strings.getString(
                                type.text.toLowerCase().split(' ').join('_')
                              )}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <Stack direction="row" spacing="4">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.booking_fee_calculation}</Th>
                  <Th>{strings.booking_fee} (CHF)</Th>
                  <Th>{strings.price_calculation}</Th>
                  <Th>{strings.status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="ServiceGroupListTable" key={uuidv4()}>
                  {(provided: any) => (
                    <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {!serviceGroupList.isLoading &&
                        serviceGroupList?.data?.data?.map(
                          (
                            serviceGroupData: ServiceGroupSchema,
                            index: number
                          ) => (
                            <Draggable
                              draggableId={serviceGroupData.id.toString()}
                              key={serviceGroupData.id}
                              index={index}>
                              {(provided: any) => (
                                <>
                                  <ServiceGroupListItem
                                    serviceGroupData={serviceGroupData}
                                    key={serviceGroupData.id}
                                    index={startingSN + index}
                                    draggableProvider={provided}
                                    search={search}
                                  />
                                  {provided.placeholder}
                                </>
                              )}
                            </Draggable>
                          )
                        )}
                      {serviceGroupList.isLoading && (
                        <TableSkeletonLoader
                          rows={filterParams.pageSize}
                          cols={7}
                        />
                      )}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={serviceGroupList}
        />
      </Stack>
    </>
  );
};

export default ServiceGroupList;

import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import BMSServiceBookingResource from 'api/bms-service-booking';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { formatPriceLabel } from 'utils';
import { DateFormatDDMM } from 'utils/DateFormat';

interface Props {
  data: any;
  editing?: boolean;
}

const ServiceBookingDetails: React.FC<Props> = (props) => {
  let { data, editing } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: reservationID }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [serviceId, setServiceId] = useState<any>(null);
  const bmsServiceBookingAPI = new BMSServiceBookingResource();
  const cancleServiceBook = useMutation((id: string) =>
    bmsServiceBookingAPI.destroy(id)
  );
  const onDeleteConfirm = () => {
    cancleServiceBook.mutate(serviceId, {
      onSuccess: () => {
        toast({
          title: strings.success,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(`bookingDetails-${reservationID}`);
        closeModal();
      },
      onError: (err: any) => {
        toast({
          title: strings.service_udpate_failed,
          description: err?.response?.data?.message,
          status: 'error',
          isClosable: true,
        });
        closeModal();
      },
    });
  };
  function openModal(id: any) {
    setServiceId(id);
    onOpen();
  }
  function closeModal() {
    setServiceId(null);
    onClose();
  }
  let totalSum = data.reduce((acc: any, curr: any) => {
    let num = Number(curr.total_price) + Number(curr.booking_fee);
    return acc + num;
  }, 0);

  function getDateString(from: any, to: any) {
    if (from && to) {
      return `(${DateFormatDDMM(from)}-${DateFormatDDMM(to)})`;
    }
    return null;
  }

  return (
    <>
      {data.map((individualItem: any) => {
        const totalPriceWithBookingFee =
          Number(individualItem.total_price) +
          Number(individualItem.booking_fee);

        return (
          <Flex key={individualItem.id}>
            <Text color="heading">
              {individualItem.quantity} x {individualItem.service.name}&nbsp;
              {getDateString(individualItem.from_date, individualItem.to_date)}
            </Text>
            <Spacer />
            <Text color="heading">
              {formatPriceLabel(totalPriceWithBookingFee)} CHF
            </Text>
            {editing && (
              <Tooltip hasArrow label={strings.delete_service}>
                <IconButton
                  icon={<BiTrash size="18" />}
                  variant="link"
                  aria-label={strings.delete_service}
                  color="red.300"
                  minW="8"
                  onClick={() => openModal(individualItem.id)}
                />
              </Tooltip>
            )}
          </Flex>
        );
      })}
      <Divider orientation="horizontal" />
      <Flex>
        <Text fontWeight="700" color="heading">
          {strings.total_price} CHF
        </Text>
        <Spacer />
        <Text fontWeight="700" color="heading">
          {formatPriceLabel(totalSum)} CHF
        </Text>
      </Flex>
      <Modal isOpen={isOpen} isCentered onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.cancel_order}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={onDeleteConfirm}
                isLoading={cancleServiceBook.isLoading}>
                {strings.confirm_and_close}
              </Button>

              <Button variant="outline" onClick={closeModal}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ServiceBookingDetails;

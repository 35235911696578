import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CallForUserListItem from 'components/contact-center/CallForUserListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { FeedbackSchema } from 'constants/schema';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { BiCalendar } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  call: string;
  start_date: Date | null;
  end_date: Date | null;
}
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const CallForUser: React.FC = () => {
  const feedbackResourceAPI = new FeedbackResource();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }
  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.start_date ? new Date(searchValues.start_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.end_date ? new Date(searchValues.end_date) : null
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    call: searchValues.call ?? '',
    start_date: startDate,
    end_date: endDate,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      start_date: DateFormatYMD(start),
      end_date: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const apartmentList = useQuery([`apartmentList`], () =>
    feedbackResourceAPI
      .getApartmentList({ limit: 300, page: 1 })
      .then((res) => res.data)
  );

  const queryList = useQuery(
    [
      `callForUserQuery`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_name: filterParams.keyword,
        call: filterParams.call,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
      },
    ],
    async () => {
      let { keyword, call } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.customer_name = keyword;
      if (call) queryParams.apartment_id = call;
      if (startDate)
        queryParams.last_email_sent_at_from = DateFormatYMD(startDate);
      if (endDate) queryParams.last_email_sent_at_to = DateFormatYMD(endDate);
      return await feedbackResourceAPI
        .getFeedbackList(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const {
    data: callForUserQuery,
    isLoading: callForUserQueryLoading,
    error: callForUserQueryError,
  } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      call: '',
      start_date: null,
      end_date: null,
    }));
    history.push(routes.contactCenter.members.profile);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.list}>
            {strings.contact_center}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.profile}>
            Call for User
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          Ronald Richards
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="column" spacing="4">
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <Grid
                gap={['4', '4', '6']}
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                ]}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.call}</FormLabel>
                    <Select
                      name="call"
                      value={filterParams.call}
                      placeholder={strings.select_object}
                      rounded="sm"
                      onChange={handleInputChange}>
                      {apartmentList?.data?.data?.map((apartment: any) => {
                        return (
                          <option key={apartment.id} value={apartment.id}>
                            {apartment.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.phone_number}</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        name="phone_number"
                        placeholder={strings.phone_number}
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.date}</FormLabel>
                    <InputGroup>
                      <InputRightElement
                        pointerEvents="none"
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                      <ReactDatePickerRoot
                        placeholderText={strings.select_date}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CreatedAtInput />}
                        onChange={handleDateChange}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        value={getFormattedRangeDate(startDate, endDate)}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              </Grid>
              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  type="button"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.call}</Th>
                <Th>{strings.phone_number}</Th>
                <Th>{strings.customer_name}</Th>
                <Th>{strings.date_time}</Th>
                <Th>{strings.internal_phone_number}</Th>
                <Th>{strings.duration}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {callForUserQuery?.data?.map(
                (callForUserListItem: FeedbackSchema, index: number) => (
                  <CallForUserListItem
                    data={callForUserListItem}
                    key={callForUserListItem.reservation_id}
                    index={startingSN + index}
                    search={search}
                  />
                )
              )}
            </Tbody>
            {callForUserQueryLoading && (
              <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
            )}
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default CallForUser;

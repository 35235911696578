import BMSInvoicesResource from 'api/bms-invoices';
import { LogoPNG } from 'assets/images';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { MasterInvoice } from 'constants/schemas/invoice';
import React, { forwardRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  masterInvoiceId: number;
  ref?: any;
  setHasInvoiceLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isDownloadClicked: boolean;
}

const OwnerInvoicePreview = forwardRef((props: Props, ref: any) => {
  const { masterInvoiceId, setHasInvoiceLoaded, isDownloadClicked } = props;

  const bmsMasterInvoiceAPI = new BMSInvoicesResource();

  const masterInvoiceQuery = useQuery(
    ['master-invoice', masterInvoiceId],
    async () => {
      const data = await bmsMasterInvoiceAPI.get(masterInvoiceId);
      return data?.data;
    },
    {
      enabled: !!masterInvoiceId,
      refetchOnWindowFocus: false,
    }
  );
  const masterInvoice: MasterInvoice = masterInvoiceQuery?.data?.data;

  const adultsCount =
    masterInvoice?.owner_rent.booking.booking_info.number_of_adults;
  const childrenCount =
    masterInvoice?.owner_rent.booking.booking_info.number_of_children;
  const babiesCount =
    masterInvoice?.owner_rent.booking.booking_info.number_of_babies;
  const dogsCount =
    masterInvoice?.owner_rent.booking.booking_info.number_of_dogs;
  const seasons = masterInvoice?.owner_rent.booking.seasons_info;
  const vatIncludedPrice =
    Number(masterInvoice?.owner_rent?.rent_commission_amount_with_tax) -
    Number(masterInvoice?.owner_rent?.rent_commission_amount);

  const ownerInformation = masterInvoice?.owner_rent?.owner;

  let totalBookedAmount: number = 0;

  useEffect(() => {
    if (masterInvoice && !masterInvoiceQuery.isLoading && isDownloadClicked)
      setHasInvoiceLoaded(true);
  }, [
    masterInvoiceQuery.isLoading,
    isDownloadClicked,
    masterInvoice,
    setHasInvoiceLoaded,
  ]);

  if (masterInvoiceQuery.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <div id="invoice-print" className="invoice-template-container" ref={ref}>
      <div className="invoice-heading">
        <img src={LogoPNG} height="200px" width="200px" alt="MyVal Logo" />
        <h2 className="myval-heading">Swiss Design Apartments</h2>
      </div>
      <div className="invoice-user-information">
        <div>
          <h6 className="myval-sub-heading">
            MYVAL Swiss Design Apartments Bahnhofstrasse 26, 3952 Susten,
            Schweiz
          </h6>
          <ul>
            <li>
              {strings[ownerInformation?.salutation]} {ownerInformation?.name}
            </li>
            <li>
              {ownerInformation?.street} {ownerInformation?.house_no}
            </li>
            <li>
              {ownerInformation?.zip_code} {ownerInformation?.city}
            </li>
            <li>{ownerInformation?.country?.name}</li>
          </ul>
          <h2 className="invoice-number">{strings.owner_billing}</h2>
          <p>
            {`${strings.dear} ${strings[ownerInformation?.salutation]} ${
              ownerInformation?.last_name
            }`}
            ,
          </p>
        </div>
        <div className="myval-information">
          <ul>
            <li>Bahnhofstrasse 26</li>
            <li>3952 Susten</li>
            <li>{strings.phone}: XXXX XXX XXX-X</li>
            <li>Fax: XXXXX XXX XXX</li>
          </ul>
          <ul>
            <li>Email: XXXXXXXXXXXXXXXXX</li>
            <li>Homepage: XXXXXXXXXXXX</li>
          </ul>
          <ul>
            <li>Geschäftsführer: Vorname Nachname</li>
            <li>HRB 10714 FL</li>
            <li>Umsatzsteuer-IdNr.: XXXXXXXXXXXX</li>
          </ul>
          <ul>
            <li> Swift/BIC: XXXXXXXXXX</li>
            <li>IBAN: XXXXXXXXXXXXXXXXXXXXXX</li>
          </ul>
          <ul>
            <li>
              {strings.invoice_date}: {DateFormat(masterInvoice?.created_at)}
            </li>
            <li>
              {strings.invoice_number}: {masterInvoice?.invoice_number}
            </li>
          </ul>
        </div>
      </div>

      <p>
        {
          strings.here_with_you_receive_the_current_rent_statement_for_your_object
        }
        <span className="fw-500">
          &nbsp;{masterInvoice?.owner_rent?.booking?.apartment?.name}&nbsp;
        </span>
        {strings.with_the_invoice_number}
        <span className="fw-500"> {masterInvoice?.invoice_number}</span>.
        <br />
        {strings.For_the_mentioned_period_we_can_invoice_the_following_booking}:
      </p>
      {/* Ihre Einnahmen  */}
      <p>
        <span className="fw-400">{strings.your_income}</span>
      </p>
      <table className="invoice-table">
        <thead className="align-right">
          <tr>
            <th className="services align-left">Leistungen</th>
            <th>{strings.vat}</th>
            <th>{strings.price}</th>
            <th>{strings.amount}</th>
          </tr>
        </thead>
        <tbody className="align-right">
          <tr>
            <td className="align-left fw-600">
              {`${masterInvoice?.owner_rent?.booking.booking_number}, 
              ${masterInvoice?.owner_rent?.booking?.customer.last_name}, 
              ${masterInvoice?.owner_rent?.booking?.customer.first_name}, `}
              {`${DateFormat(
                masterInvoice?.owner_rent?.booking?.from_date
              )} - ${DateFormat(
                masterInvoice?.owner_rent?.booking?.to_date
              )}, `}
              {adultsCount > 0 && `${adultsCount} Erwachsene`}
              {childrenCount > 0 && `, ${childrenCount} Kinder`}
              {babiesCount > 0 && `, ${babiesCount} Baby`}
              {dogsCount > 0 && `, ${dogsCount} Hunde`}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {seasons?.map((season: any) => {
            totalBookedAmount += Number(season?.total_apartment_season_price);
            return (
              <tr key={season?.id}>
                <td className="align-left">
                  {season?.name}, {season?.booked_timespan} Nächte
                </td>
                <td>X%</td>
                <td>
                  {formatCurrency(Number(season?.apartment_season_price))} CHF
                </td>
                <td>
                  {formatCurrency(Number(season?.total_apartment_season_price))}
                  &nbsp;CHF
                </td>
              </tr>
            );
          })}
        </tbody>
        {/* amount */}
        <tbody className="invoice-amount align-right">
          <tr>
            <td className="align-left" colSpan={4}>
              {strings.invoice_total}
              <span className="text-align-right">
                {formatCurrency(totalBookedAmount)} CHF
              </span>
            </td>
          </tr>
          <tr>
            <td className="align-left fade" colSpan={4}>
              {strings.net_total}
              <span className="text-align-right">
                {formatCurrency(totalBookedAmount)} CHF
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Unsere Leistungen */}
      <p className="mt-4">
        <span className="fw-400">{strings.our_services}</span>
      </p>
      <table className="invoice-table">
        <thead className="align-right">
          <tr>
            <th className="services align-left">Leistungen</th>
            <th>{strings.vat}</th>
            <th>{strings.price}</th>
            <th>{strings.amount}</th>
          </tr>
        </thead>
        <tbody className="align-right">
          <tr>
            <td className="align-left">
              Provision ({masterInvoice?.owner_rent?.rent_commission} % zzgl.
              MwSt auf&nbsp;
              {formatCurrency(
                Number(masterInvoice?.owner_rent?.apartment_booking_amount)
              )}
              CHF)
            </td>
            <td>
              {formatCurrency(Number(masterInvoice?.owner_rent?.rent_tax))}%
            </td>
            <td>
              {formatCurrency(
                Number(
                  masterInvoice?.owner_rent?.rent_commission_amount_with_tax
                )
              )}{' '}
              CHF
            </td>
            <td>
              {formatCurrency(
                Number(
                  masterInvoice?.owner_rent?.rent_commission_amount_with_tax
                )
              )}{' '}
              CHF
            </td>
          </tr>
        </tbody>
        {/* amount */}
        <tbody className="invoice-amount align-right">
          <tr>
            <td className="align-left" colSpan={3}>
              {strings.invoice_total}
            </td>
            <td>
              {formatCurrency(
                Number(
                  masterInvoice?.owner_rent?.rent_commission_amount_with_tax
                )
              )}{' '}
              CHF
            </td>
          </tr>
          <tr>
            <td className="align-left" colSpan={3}>
              {strings.vat_included}
            </td>
            <td>{formatCurrency(Number(vatIncludedPrice))} CHF</td>
          </tr>
          <tr className="fade">
            <td className="align-left" colSpan={3}>
              {strings.net_total}
            </td>
            <td>
              {formatCurrency(
                Number(masterInvoice?.owner_rent?.rent_commission_amount)
              )}{' '}
              CHF
            </td>
          </tr>
        </tbody>
      </table>

      {/* Abrechnung */}
      <p className="mt-4">
        <span className="fw-400">{strings.accounting}</span>
      </p>
      <table className="invoice-table">
        <tbody className="invoice-amount align-right">
          <tr>
            <td className="align-left" colSpan={3}>
              Berechnungsgrundlage <br /> Rechnungsbetrag
            </td>
            <td colSpan={2}>
              {formatCurrency(
                Number(masterInvoice?.owner_rent?.apartment_booking_amount)
              )}{' '}
              CHF <br />
              {formatCurrency(
                Number(
                  masterInvoice?.owner_rent?.rent_commission_amount_with_tax
                )
              )}{' '}
              CHF
            </td>
          </tr>
          <tr>
            <td className="align-left" colSpan={3}>
              Verrechnungsbetrag
            </td>
            <td>
              {formatCurrency(
                Number(masterInvoice?.owner_rent?.rent_commission_owner_amount)
              )}{' '}
              CHF
            </td>
          </tr>
        </tbody>
      </table>
      <div className="page-break-inside-avoid">
        <p className="mt-4">
          {strings.balance_to_your_credit}:
          {formatCurrency(
            Number(masterInvoice?.owner_rent?.rent_commission_owner_amount)
          )}{' '}
          CHF.
          {
            strings.the_clearing_ammount_will_be_transferred_to_your_account_in_the_next_few_days
          }
          : DE12 2345 2345 2345 2345 {strings.überwiesen}.
        </p>

        <hr className="divider" />

        <footer className="invoice-footer">
          <ul>
            <li>Appartmentverwaltung</li>
            <li>Swiss Design Apartments</li>
            <li>Bahnhofsstraße 26</li>
            <li>3952 Susten</li>
          </ul>

          <ul>
            <li>Telefon: xxxxxxxxxxxxx</li>
            <li>Telefax: xxxxxxxxxxxxxxxxxx</li>
            <li>E-Mail: xxxxxxxxxxxxxxxxxx</li>
            <li>Internet: xxxxxxxxxxxxxxxxxx</li>
          </ul>

          <ul>
            <li>Telefon: xxxxxxxxxxxxx</li>
            <li>Telefax: xxxxxxxxxxxxxxxxxx</li>
            <li>E-Mail: xxxxxxxxxxxxxxxxxx</li>
            <li>Internet: xxxxxxxxxxxxxxxxxx</li>
          </ul>

          <ul>
            <li>Bankverbiindung:</li>
            <li>Bank XY Susten</li>
            <li>IBAN: xxxxxxxxxxxxxx</li>
            <li>BIC: xxxxxxxxxxxx</li>
          </ul>
        </footer>
      </div>
    </div>
  );
});

OwnerInvoicePreview.displayName = 'Owner_Invoice';

export default OwnerInvoicePreview;

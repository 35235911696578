import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import OMSapartmentImagesResource from 'api/oms-apartment-images';
import { strings } from 'config/localization';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import ApartmentImagesUploader from './ApartmentImagesUploader';

interface Props {
  data?: any;
  editing: boolean;
}

const ApartmentImages: React.FC<Props> = (props) => {
  const { data, editing } = props;
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [clearFileInputToggle, setClearFileInputToggle] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<null | number>(null);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [items, setItems] = useState<any>([]);

  let ImageAPI = new OMSapartmentImagesResource();
  const uploadNewImages = useMutation((body: any) =>
    ImageAPI.upload(data.id, body)
  );
  const updatePosition = useMutation((body: any) =>
    ImageAPI.update(data?.id, body)
  );
  useEffect(() => {
    setItems(data?.images);
  }, [data]);

  const deleteImage = useMutation((id: any) => ImageAPI.destroy(id));
  const onSubmit = (data: any) => {
    let formData = new FormData();
    if (!data.images) return;
    data.images.forEach((item: any) => {
      formData.append('images[]', item);
    });

    setIsLoading(true);
    uploadNewImages.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(`apartmentDetails`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        reset();
      },
      onError: () => {
        setErrMsg(`${strings.apartment} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };
  function reset() {
    setClearFileInputToggle((prev) => !prev);
  }

  const onDeletePress = (id: any) => {
    setDeleteMsg('');
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteId(null);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    setDeleteId(null);
    deleteImage.mutate(deleteId, {
      onSuccess: (res) => {
        queryClient.invalidateQueries('apartmentDetails');
        setDeleteModalOpen(false);
        toast({
          title: strings.delete,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        setDeleteMsg(strings.user_delete_error);
      },
    });
  };

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    if (!editing) return;

    const copyListItems = [...items];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    let formdata = {
      old_position: ++dragItem.current,
      new_position: ++dragOverItem.current,
    };
    if (dragItem.current !== dragOverItem.current) {
      updatePosition.mutate(formdata, {
        onSuccess: (res) => {
          queryClient.invalidateQueries('apartmentDetails');
          toast({
            title: strings.success,
            status: 'success',
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: strings.error,
            status: 'error',
            isClosable: true,
          });
          setItems(data.images);
        },
      });
    }

    dragItem.current = null;
    dragOverItem.current = null;
    setItems(copyListItems);
  };
  if (!data) return null;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack direction="column" spacing="8">
            {errMsg && (
              <Alert status="error">
                <AlertIcon />
                {errMsg}
              </Alert>
            )}
            <Stack spacing="8">
              <Heading color="green.600" size="md" textTransform="capitalize">
                {strings.images}
              </Heading>
              <Text color="black">{strings.adjust_order_of_images}</Text>
            </Stack>
            <Box pb="8" borderBottom="1px solid" borderColor="gray.300">
              {editing && (
                <ApartmentImagesUploader
                  clearFileInputToggle={clearFileInputToggle}
                  multiple={true}
                  fileKey="images"
                />
              )}
            </Box>
            <Stack direction="row" spacing="8">
              <Grid
                templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
                gridGap="4">
                {items?.map((picture: any, index: number) => (
                  <GridItem
                    position="relative"
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={drop}
                    draggable={editing}
                    key={picture.id}>
                    {editing && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          background: 'rgb(146 146 146 / 72%)',
                          padding: '5px',
                          borderRadius: '6px 0 6px 6px',
                          cursor: 'pointer',
                        }}
                        onClick={() => onDeletePress(picture.id)}>
                        <Icon as={BiTrash} h="6" w="6" color="red.500" />
                      </div>
                    )}
                    <Image
                      src={`${picture.media.url}`}
                      key={picture.id}
                      alt={picture.media.original_name}
                    />
                  </GridItem>
                ))}
              </Grid>
            </Stack>
            {editing && (
              <ButtonGroup alignSelf="end">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  type="button"
                  onClick={reset}>
                  {strings.clear}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {strings.upload_image}
                </Button>
              </ButtonGroup>
            )}
          </Stack>
        </form>
      </FormProvider>
      <Modal isOpen={isDeleteModalOpen} isCentered onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_image}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {strings.confirm_delete}
            {deleteMsg && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {deleteMsg}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={onDeleteConfirm}
                isLoading={deleteImage.isLoading}>
                {strings.delete}
              </Button>

              <Button variant="outline" onClick={onDeleteModalClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApartmentImages;

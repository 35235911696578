import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RoleResource from 'api/roles';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UserListItem from 'components/user/UserListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useMemo, useState } from 'react';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  roleId: string;
  keyword: string;
}

const UserList: React.FC = () => {
  const userAPI = new UserResource();
  const roleAPI = new RoleResource();
  const queryClient = useQueryClient();
  const checkAddUserPermission = useCheckPermission(
    [PermissionRequest['read:user'], PermissionRequest['manage:user']],
    routes.users.add
  );

  const { search } = useLocation();
  const history = useHistory();
  let searchParams = new URLSearchParams(search);
  const url_roleId = searchParams.get('roleId') ?? '';
  const url_keyword = searchParams.get('keyword') ?? '';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    roleId: url_roleId ?? '',
    keyword: url_keyword ?? '',
  });

  const roleList = useQuery('roleList', () =>
    roleAPI.list().then((res) => res.data.data)
  );

  const userList = useQuery(
    [
      'userList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        roleId: filterParams.roleId,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.roleId) queryParams.role_id = filterParams.roleId;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await userAPI.list(queryParams);
      response && setIsLoading(false);
      return response?.data;
    }
  );

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      roleId: '',
      keyword: '',
    }));
    history.push(routes.users.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.user_list}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.user_list}
          </Heading>

          <Button
            size="lg"
            colorScheme="primary"
            type="button"
            onClick={checkAddUserPermission}>
            {strings.add_new_user}
          </Button>
        </Flex>
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          h="100%"
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.800"
                        />
                        <Input
                          type="text"
                          name="keyword"
                          value={filterParams.keyword}
                          onChange={(e) => handleInputChange(e)}
                          placeholder={strings.search}
                          size="lg"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.role}</FormLabel>
                      <Select
                        placeholder={strings.select_role}
                        rounded="sm"
                        value={filterParams.roleId}
                        name="roleId"
                        size="lg"
                        onChange={(e) => handleInputChange(e)}>
                        {roleList?.data?.map((role: any) => {
                          return (
                            <option key={role.id} value={role.id}>
                              {strings.getString(
                                role.name.split(' ').join('_')
                              )}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Stack direction="row" spacing="4">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.email}</Th>
                  <Th isNumeric>{strings.phone}</Th>
                  <Th>{strings.role}</Th>
                  <Th textAlign="center">{strings.FA_status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody>
                {!userList.isLoading &&
                  userList?.data?.data?.map(
                    (userData: UserSchema, index: number) => (
                      <UserListItem
                        key={userData.id}
                        userData={userData}
                        index={startingSN + index}
                        search={search}
                      />
                    )
                  )}
                {userList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={userList}
        />
      </Stack>
    </>
  );
};

export default UserList;

import Resource from 'api/resource';
import http from 'utils/http';

class PMSPaymentLinkResource extends Resource {
  constructor() {
    super('bms/payment-link');
  }

  resendEmail(data: any) {
    return http({
      url: 'bms/payment-link/resend',
      method: 'post',
      data: data,
    });
  }
}

export { PMSPaymentLinkResource as default };

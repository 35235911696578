import { createStandaloneToast } from '@chakra-ui/react';
import { refreshUser } from 'api/auth';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { strings } from 'config/localization';
import * as authService from '../services/auth';

const { toast } = createStandaloneToast();
const RETRY_COUNT_LIMIT = 3;
const TOKEN_EXPIRE = 'Token expired. Please refresh your token.';
const AUTHORIZATION_HEADER = 'Authorization';
const SESSION_EXPIRE = 'Refresh token expired';
const REFRESH_TOKEN_DOESNT_EXIST = 'Invalid refresh token';

let refreshTokenCount = 0;

function refreshTokenFailure() {
  const redirectUrl = window.location.pathname + window.location.search;
  const base64Encode = window.btoa(redirectUrl);
  authService.logout('?redirect=' + base64Encode);
}

/**
 * Build authorization header
 *
 * @param {string} accessToken
 * @returns {string}
 */
function buildAuthHeader(accessToken: string) {
  return `Bearer ${accessToken}`;
}

/**
 * Interceptor to add authentication header for all requests.
 *
 * @param {object} request
 * @returns {object}
 */
export function requestInterceptor(request: AxiosRequestConfig) {
  const accessToken = authService.getAccessToken();

  if (accessToken && !request.headers[AUTHORIZATION_HEADER]) {
    request.headers[AUTHORIZATION_HEADER] = buildAuthHeader(accessToken);
  }
  let currentLanguage = strings.getLanguage();
  currentLanguage === 'en'
    ? 'en-US, en;q=0.9, de;q=0.8, fr;q=0.7, it;q=0.6, *;q=0.5'
    : currentLanguage === 'fr'
    ? 'fr-CH, fr;q=0.9, de;q=0.8, en;q=0.7, it;q=0.6, *;q=0.5'
    : currentLanguage === 'it'
    ? 'it-CH, it;q=0.9, de;q=0.8, en;q=0.7, fr;q=0.6, *;q=0.5'
    : 'de-DE, de;q=0.9, en;q=0.8, fr;q=0.7, it;q=0.6, *;q=0.5';
  request.headers['Accept-Language'] = currentLanguage;

  return request;
}

/**
 * Success response Interceptor for refresh token.
 *
 * @param sucess
 * @returns {object}
 */
export async function responseSuccessInterceptor(response: AxiosResponse) {
  let originalRequest = response.config;

  if (
    originalRequest.url === '/user/refresh-token' &&
    response.status === 200
  ) {
    let accessToken = response.data.access_token;
    let refreshToken = response.data.refresh_token;
    let expiryTime = response.data.expires_in;
    refreshTokenCount = 0;
    authService.persist({ accessToken, refreshToken, expiryTime });
  }

  return response;
}

/**
 * Interceptor to refresh access token.
 *
 * @param {object} error
 * @returns {object}
 */
export async function responseErrorInterceptor(error: AxiosError) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config as any;
  const id = originalRequest.url;

  let expiryTime = authService.getExpiryTime();
  let now = Date.now();
  let isTokenExpired = now > expiryTime;

  if (error.response.status === 500) {
    if (!toast.isActive(id)) {
      toast({
        id,
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  // The above block should be enought to handle both unauthorized request
  // and expired token.
  // The data received should be in correct format for above block to work
  if (
    error.response.status === 401 &&
    isTokenExpired &&
    refreshTokenCount === 0
  ) {
    refreshTokenCount++;
    let refresh_token = authService.getRefreshToken() ?? '';
    let userID = authService.getUserInfo()?.id ?? '';

    refreshUser(refresh_token, userID).catch((error) => {
      refreshTokenFailure();
    });
  }

  if (
    originalRequest.url === '/user/refresh-token' &&
    error.response.status === 400
  ) {
    refreshTokenFailure();
  }

  return Promise.reject(error);
}

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Switch,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { OMS_ICAL_BASEURL } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { fallbackCopyTextToClipboard } from 'utils';

interface Props {
  data: any;
  editing: boolean;
}

const SettingsForm: React.FC<Props> = (props) => {
  const { editing, data } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const objectAPI = new OMSObjectsResource();
  const updateArticle = useMutation((formData: any) =>
    objectAPI.update(data.id, formData)
  );

  const [hasCopiedText, setHasCopiedText] = useState<string>(strings.copy);
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data) return;
    let { vimeo_video_link, payment_active, website_active } = data;
    setValue('payment_active', payment_active);
    setValue('website_active', website_active);
    setValue('vimeo_video_link', vimeo_video_link);
  }, [data, setValue]);

  const onSubmit = (formData: any) => {
    setIsLoading(true);
    if (!formData.vimeo_video_link) formData.vimeo_video_link = null;

    updateArticle.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(`apartmentDetails`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(`${strings.apartment} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  const icalLink = OMS_ICAL_BASEURL + `/${data.id}/ical`;

  const copyTextToClipboard = () => {
    // Clipboard API requires a secure origin (https), it gives undefined otherwise
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(icalLink);
    } else {
      navigator.clipboard.writeText(icalLink);
    }
    setHasCopiedText(`${strings.copied}!`);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="column" spacing="8">
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <Stack direction="column" spacing="8">
            <Heading color="green.600" size="md" textTransform="capitalize">
              {strings.setting}
            </Heading>
            <Grid
              gap="4"
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>{strings.payment_active}</FormLabel>
                  <Switch
                    {...register('payment_active')}
                    defaultChecked={!!data?.payment_active}
                    isDisabled={!editing}
                    size="md"
                    colorScheme="green"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>{strings.site_active}</FormLabel>
                  <Switch
                    {...register('website_active')}
                    defaultChecked={!!data?.website_active}
                    isDisabled={!editing}
                    size="md"
                    colorScheme="green"
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>{strings.vimeo_video_link}</FormLabel>
                  <Input
                    {...register('vimeo_video_link')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.vimeo_video_link}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.ical_link}</FormLabel>
                  <Stack
                    direction="row"
                    spacing={0}
                    flexFlow="wrap"
                    alignItems="center"
                    justifyContent="space-between">
                    <Box overflowWrap="anywhere" sx={infoStyles}>
                      {icalLink}
                    </Box>
                    <Button
                      colorScheme="primary"
                      variant="outline"
                      onClick={() => {
                        copyTextToClipboard();
                      }}>
                      {hasCopiedText}
                    </Button>
                  </Stack>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          {editing && (
            <ButtonGroup alignSelf="end">
              <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                {strings.save}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SettingsForm;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import GeneralDocumentResource from 'api/general_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import GeneralDocumentListItem from 'components/general_document/GeneralDocumentListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import moment from 'moment';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar, BiCloudUpload, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
  created_at: string;
  available_from_date: string;
  available_to_date: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const GeneralList: React.FC = () => {
  let generalDocumentAPI = new GeneralDocumentResource();
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    created_at: searchValues.created_at ?? '',
    available_from_date: searchValues.available_from_date ?? '',
    available_to_date: searchValues.available_to_date ?? '',
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [documentCreatedAt, setDocumentCreatedAt] = useState<any>();

  const generalDocumentList = useQuery(
    [
      'generalDocumentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        created_at: filterParams.created_at,
        available_from_date: filterParams.available_from_date,
        available_to_date: filterParams.available_to_date,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.created_at)
        queryParams.created_at = filterParams.created_at;
      if (filterParams.available_from_date)
        queryParams.available_from_date = filterParams.available_from_date;
      if (filterParams.available_to_date)
        queryParams.available_to_date = filterParams.available_to_date;

      const response = await generalDocumentAPI.list(queryParams);
      response && setIsFilterLoading(false);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
      created_at: documentCreatedAt
        ? moment(documentCreatedAt).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.created_at,
      available_from_date: startDate
        ? moment(startDate).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.available_from_date,
      available_to_date: endDate
        ? moment(endDate).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.available_to_date,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  useEffect(() => {
    if (documentCreatedAt) {
      setFilterParams((prevState) => ({
        ...prevState,
        created_at: moment(documentCreatedAt).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
    if (startDate) {
      setFilterParams((prevState) => ({
        ...prevState,
        available_from_date: moment(startDate).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
    if (endDate) {
      setFilterParams((prevState) => ({
        ...prevState,
        available_to_date: moment(endDate).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
  }, [documentCreatedAt, endDate, startDate]);

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setDocumentCreatedAt('');
    setStartDate(null);
    setEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      created_at: '',
      available_from_date: '',
      available_to_date: '',
    }));
    history.push(routes.dms.generalList.list);
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.all_documents}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.general_documents}
          </Heading>
          <AccessControl allowedPermissions={[PermissionRequest['manage:dms']]}>
            <RouterLink to={routes.dms.generalList.upload}>
              <Button
                colorScheme="primary"
                leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
                size="lg">
                {strings.upload_document}
              </Button>
            </RouterLink>
          </AccessControl>
        </Flex>
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="20px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack>
                <Stack sx={wrapperStyles}>
                  <form>
                    <Stack direction="column" spacing="4">
                      <Stack
                        direction={['column', 'column', 'row']}
                        spacing="4"
                        align={['start', 'start', 'end']}>
                        <Grid
                          gap={['4', '4', '6']}
                          templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(2, 1fr)',
                            'repeat(3, 1fr)',
                          ]}
                          w="100%">
                          <GridItem>
                            <FormControl>
                              <FormLabel>{strings.search}</FormLabel>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  children={<BiSearch />}
                                  color="gray.400"
                                />
                                <Input
                                  type="text"
                                  name="keyword"
                                  value={filterParams.keyword}
                                  onChange={(e) => handleInputChange(e)}
                                  size="lg"
                                />
                              </InputGroup>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>{strings.upload_date}</FormLabel>
                              <InputGroup>
                                <InputRightElement
                                  children={<BiCalendar />}
                                  color="gray.400"
                                />
                                <ReactDatePickerRoot
                                  name="created_at"
                                  value={filterParams.created_at}
                                  placeholderText={strings.select_date}
                                  dateFormat={
                                    REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
                                  }
                                  selected={documentCreatedAt}
                                  customInput={
                                    <CreatedAtInput value={documentCreatedAt} />
                                  }
                                  onChange={(date: Date) =>
                                    setDocumentCreatedAt(date)
                                  }
                                />
                              </InputGroup>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>{strings.availability}</FormLabel>
                              <InputGroup>
                                <InputRightElement
                                  children={<BiCalendar />}
                                  color="gray.400"
                                />
                                <ReactDatePickerRoot
                                  dateFormat={
                                    REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
                                  }
                                  placeholderText={strings.select_date}
                                  customInput={<CreatedAtInput />}
                                  onChange={handleDateChange}
                                  selected={startDate}
                                  startDate={startDate}
                                  endDate={endDate}
                                  selectsRange
                                  value={getFormattedRangeDate(
                                    startDate,
                                    endDate
                                  )}
                                  shouldCloseOnSelect={false}
                                  autoComplete="off"
                                />
                              </InputGroup>
                            </FormControl>
                          </GridItem>
                        </Grid>
                        <Stack direction="row" spacing="4">
                          <Button
                            colorScheme="primary"
                            variant="outline"
                            size="lg"
                            onClick={handleReset}>
                            {strings.reset_filter}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.document_name}</Th>
                  <Th>{strings.upload_date}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!generalDocumentList.isLoading &&
                  generalDocumentList?.data?.data?.map(
                    (generalDocumentData: any, index: number) => (
                      <GeneralDocumentListItem
                        key={generalDocumentData.id}
                        index={startingSN + index}
                        generalDocumentData={generalDocumentData}
                      />
                    )
                  )}

                {generalDocumentList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={generalDocumentList}
        />
      </Stack>
    </>
  );
};

export default GeneralList;

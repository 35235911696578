import axios from 'axios';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;
/** Closed Chat Start */

/**
 *
 * Get Closed Chat Details
 */
export function getClosedChat(userId: number, query?: any) {
  return http({
    url: `/chat/users/${userId}/messages`,
    method: 'get',
    params: query,
  });
}

/**
 *
 * Filter By Date and Customer
 */
export function closedChatFilterBy(data: any) {
  return http({
    url: '/chat/getChat',
    method: 'post',
    data: {
      ...data,
      status: ['closed'],
    },
  });
}

/** Closed Chat End */

/** Archived Chat Start */

/**
 *
 * Get Archived Chat Details
 */
export function getArchivedChat(userId: number, query?: any) {
  return http({
    url: `/chat/users/${userId}/messages`,
    method: 'get',
    params: query,
  });
}

/**
 *
 * Filter By Date and Customer
 */
export function archivedChatFilterBy(data: any) {
  return http({
    url: '/chat/getChat',
    method: 'post',
    data: {
      ...data,
      status: ['archived'],
    },
  });
}

/** Archived Chat End */

/** Chat Metrics Start */

/**
 *
 * Get Chat Metrics Details
 */
export function getChatMetrics(query?: any) {
  if (cancel) {
    cancel(); // cancel request
  }
  return http({
    url: '/chat/users/chat-metrics',
    method: 'get',
    params: query,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}

/**
 *
 * Filter By User
 */
export function filterUser(userName: String) {
  return http({
    url: '/chat/users/filter',
    method: 'post',
    data: {
      name: userName,
    },
  });
}

/** Chat Metrics End */

/** Chat Start */

/**
 * Get Logged in User Details
 */
export function getUserDetails(id: number) {
  return http({
    url: `/chat/users/${id}`,
    method: 'get',
  });
}

/**
 * Search in Chat User List (Fetch only inprogress status)
 */
export function searchChatUser(userId: number, query?: any) {
  return http({
    url: `/chat/users/${userId}/messages`,
    method: 'get',
    params: query,
  });
}

/**
 * Get Chat User Detail By ID
 */
export function getChatUserDetail(id: String) {
  return http({
    url: `/chat/messages/${id}`,
    method: 'get',
  });
}

/**
 *
 * Get active users for chat transfer
 */

export function getActiveUsersForChatTransfer(id: number) {
  return http({
    url: `/chat/users/transfer-chat/${id}`,
    method: 'get',
  });
}

/**
 * Transfer chat from one employee to another
 */
export function transferChat(data: any) {
  return http({
    url: '/chat/transfer-chat',
    method: 'post',
    data: {
      ...data,
    },
  });
}

/**
 * Closed or Archive Chat
 * It checked whether user is anynomous or not
 */
export function closedORArchive(data: any) {
  return http({
    url: '/chat/update-status',
    method: 'post',
    data: {
      ...data,
    },
  });
}

/**
 * toggle User status
 */
export function toggleUserStatus(userId: number) {
  return http({
    url: '/chat/users/toggle-status',
    method: 'post',
    data: {
      user_id: userId,
    },
  });
}

/**
 *
 * Get All users (Active)
 */
export function getActiveUsers() {
  return http({
    url: '/chat/users',
    method: 'get',
  });
}

/** Chat End */

/**
 *
 * Update Read Message Status
 */
export function updateReadMessageStatus(roomName: any, positions: number[]) {
  return http({
    url: `/chat/messages/${roomName}/read-message`,
    method: 'put',
    data: {
      positions,
    },
  });
}

/** Chat End */

/**
 * Get Chat Messages
 */
export function getChatMessages(query?: any) {
  if (cancel) {
    cancel(); // cancel request
  }
  return http({
    url: `/chat/messages`,
    method: 'get',
    params: query,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}

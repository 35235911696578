import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import OMSOwnersResource from 'api/oms-owners';
import { reactSelectStyles } from 'assets/css/commonStyles';
import ReactSelectRoot from 'components/ReactSelectRoot/ReactSelectRoot';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { allowNumberOnlyRegex } from 'utils';

interface Props {
  data?: any;
  editing: boolean;
}

const BasicInformationForm: React.FC<Props> = (props) => {
  const { editing, data } = props;
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const objectAPI = new OMSObjectsResource();
  const omsOwnersAPI = new OMSOwnersResource();

  const createObject = useMutation((data: any) => objectAPI.store(data));
  const updateObject = useMutation((formData: any) =>
    objectAPI.update(data.id, formData)
  );
  const { data: countryList } = useQuery(
    'country-list',
    () => objectAPI.listCountries().then((res: any) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const ownersListQuery = useQuery(
    'owners-list',
    () => omsOwnersAPI.list({ limit: 'all' }).then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
  const ownersList = ownersListQuery?.data?.data;

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data || !countryList || !ownersList) return;
    const {
      name,
      type,
      address,
      owner,
      number_of_pets,
      number_of_persons,
    }: any = data;
    const { country, street, zip_code, house_number, town } = address;
    setValue('name', name);
    setValue('type', type);
    setValue('country_id', country.id);
    setValue('zip_code', zip_code);
    setValue('house_number', house_number);
    setValue('street', street);
    setValue('owner_id', { label: owner?.name, value: owner?.id });
    setValue('number_of_persons', number_of_persons);
    setValue('number_of_pets', number_of_pets);
    setValue('town', town);
  }, [countryList, data, setValue, ownersList]);

  const onSubmit = (formData: any) => {
    setIsLoading(true);
    let API;
    if (data?.id) {
      API = updateObject;
    } else {
      API = createObject;
    }

    const newFormData = { ...formData, owner_id: formData.owner_id.value };

    API.mutate(newFormData, {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(`apartmentDetails`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        let newApartmentId = res.data.data.id;
        setIsLoading(false);
        if (!data?.id) {
          history.push(
            routes.oms.objects.details.replace(
              ':id',
              newApartmentId?.toString()
            )
          );
        }
      },
      onError: () => {
        setErrMsg(`${strings.apartment} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack direction="column" spacing="8">
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <Stack direction="row" spacing="8">
            <Grid
              gap="4"
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
              w="100%">
              <GridItem colSpan={[1, 2]}>
                <Heading color="green.600" size="md" textTransform="capitalize">
                  {strings.basic_information}
                </Heading>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.name} isRequired>
                  <FormLabel>{strings.apartment_name}</FormLabel>
                  <Input
                    isDisabled={!editing}
                    {...register('name', {
                      required: strings.appartment_is_required,
                    })}
                    type="text"
                    placeholder={strings.apartment_name}
                  />
                  <FormErrorMessage>
                    <>{errors?.name && errors?.name?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.type} isRequired>
                  <FormLabel>{strings.type}</FormLabel>
                  <Select
                    isDisabled={!editing}
                    placeholder={strings.type}
                    {...register('type', {
                      required: strings.type_is_required,
                    })}>
                    <option value="home">{strings.house}</option>
                    <option value="apartment">{strings.flat}</option>
                  </Select>
                  <FormErrorMessage>
                    <>{errors?.type && errors?.type?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.street} isRequired>
                  <FormLabel>{strings.street}</FormLabel>
                  <Input
                    isDisabled={!editing}
                    type="text"
                    {...register('street', {
                      required: strings.street_name_is_required,
                    })}
                    placeholder={strings.street}
                  />
                  <FormErrorMessage>
                    <>{errors?.street && errors?.street?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.house_number} isRequired>
                  <FormLabel>{strings.house_number}</FormLabel>
                  <Input
                    {...register('house_number', {
                      required: strings.house_number_is_required,
                    })}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.house_number}
                  />
                  <FormErrorMessage>
                    <>{errors?.house_number && errors?.house_number?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  id="owner_id"
                  isInvalid={!!errors?.owner_id}
                  isRequired>
                  <FormLabel>{strings.owner}</FormLabel>
                  <Controller
                    control={control}
                    name="owner_id"
                    rules={{
                      required: strings.required_owner,
                    }}
                    render={({ field }) => (
                      <ReactSelectRoot
                        {...field}
                        id="owner_id"
                        isDisabled={!editing}
                        placeholder={strings.select}
                        options={ownersList?.map((owner: any) => ({
                          label: owner.name,
                          value: owner.id,
                        }))}
                        styles={reactSelectStyles}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    <>{errors?.owner_id && errors?.owner_id?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.zip_code} isRequired>
                  <FormLabel>{strings.post_code}</FormLabel>
                  <Input
                    {...register('zip_code', {
                      required: strings.post_code_is_required,
                    })}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.post_code}
                  />
                  <FormErrorMessage>
                    <>{errors?.zip_code && errors?.zip_code?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors?.country_id} isRequired>
                  <FormLabel>{strings.country}</FormLabel>
                  <Select
                    {...register('country_id', {
                      required: strings.country_is_required,
                    })}
                    isDisabled={!editing}
                    placeholder={strings.select}>
                    {countryList?.data?.map((country: any) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    <>{errors?.country_id && errors?.country_id?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.town} isRequired>
                  <FormLabel>{strings.town}</FormLabel>
                  <Input
                    {...register('town', {
                      required: strings.town_is_required,
                    })}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.town}
                  />
                  <FormErrorMessage>
                    <>{errors?.town && errors?.town?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.number_of_persons} isRequired>
                  <FormLabel>{strings.sleeping_facilities}</FormLabel>
                  <Input
                    type="text"
                    {...register('number_of_persons', {
                      required: strings.required_sleeping_facilities,
                      validate: (value) => {
                        if (value && Number(value) <= 0) {
                          return strings.must_be_greater_than_zero;
                        }
                        return true;
                      },
                    })}
                    isDisabled={!editing}
                    placeholder={strings.number}
                    onChange={(e) =>
                      (e.target.value = allowNumberOnlyRegex(e.target.value))
                    }
                  />
                  <FormErrorMessage>
                    <>
                      {errors?.number_of_persons &&
                        errors?.number_of_persons?.message}
                    </>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.number_of_pets} isRequired>
                  <FormLabel>{strings.pets_allowed}</FormLabel>
                  <Input
                    type="text"
                    {...register('number_of_pets', {
                      required: strings.required_pets_allowed,
                    })}
                    isDisabled={!editing}
                    placeholder={strings.number}
                    onChange={(e) =>
                      (e.target.value = allowNumberOnlyRegex(e.target.value))
                    }
                  />
                  <FormErrorMessage>
                    <>
                      {errors?.number_of_pets &&
                        errors?.number_of_pets?.message}
                    </>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          {editing && (
            <ButtonGroup alignSelf="end">
              {!data && (
                <Button
                  variant="outline"
                  colorScheme="primary"
                  isDisabled={isLoading}
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              )}
              <Button
                colorScheme="primary"
                type="button"
                isLoading={isLoading}
                onClick={methods.handleSubmit(onSubmit)}>
                {strings.save}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default BasicInformationForm;

import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Tag,
  Text,
} from '@chakra-ui/react';
import {
  activeFeedbackScoreStyle,
  feedbackScoreStyle,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import {
  FEEDBACK_QUESTION_TYPE_CLOSED_ENDED,
  FEEDBACK_QUESTION_TYPE_OPEN,
  FEEDBACK_QUESTION_TYPE_RATING,
  FEEDBACK_SCORES,
} from 'constants/common';
import * as React from 'react';

const borderStyled = {
  borderColor: 'gray.200',
  _lastChildren: {
    borderColor: 'transparent',
  },
};

interface Props {
  response: any;
  index: any;
}

const FeedbackQuestionType: React.FC<Props> = (props) => {
  const { response, index } = props;
  const type = response?.question_meta?.type;
  const question = response?.question_meta?.name;

  if (type === FEEDBACK_QUESTION_TYPE_RATING) {
    return (
      <>
        <div className="page-break" />
        <GridItem key={index}>
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap">
            <Box w="45%">
              <FormLabel fontSize="md" fontWeight="normal" mb="0">
                {question}
              </FormLabel>
            </Box>
            <Flex w="55%">
              <Text>{strings.not_at_all}</Text>
              <Box>
                {FEEDBACK_SCORES.map((item) => {
                  return (
                    <Tag
                      key={item}
                      variant="outline"
                      sx={
                        Number(response?.response) === item
                          ? {
                              ...feedbackScoreStyle,
                              ...activeFeedbackScoreStyle,
                            }
                          : feedbackScoreStyle
                      }>
                      {item}
                    </Tag>
                  );
                })}
              </Box>
              <Text ml="2">{strings.absolutely}!</Text>
            </Flex>
          </FormControl>
        </GridItem>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  if (type === FEEDBACK_QUESTION_TYPE_CLOSED_ENDED) {
    return (
      <>
        <div className="page-break" />
        <GridItem key={index}>
          <FormControl
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap">
            <Box display="flex" justifyContent="flex-start" w="45%">
              <FormLabel fontSize="md" fontWeight="normal" mb="0">
                {question}
              </FormLabel>
            </Box>
            <HStack w="55%" spacing="6">
              <Box>
                <Tag
                  mr="1"
                  size="md"
                  variant={response?.response !== '1' ? 'outline' : ''}
                  bg={response?.response === '1' ? 'blackgreen.400' : ''}
                  rounded="full"></Tag>
                {strings.yes}
              </Box>
              <Box>
                <Tag
                  mr="1"
                  size="md"
                  variant={response?.response !== '0' ? 'outline' : ''}
                  bg={response?.response === '0' ? 'blackgreen.400' : ''}
                  rounded="full"></Tag>
                {strings.no}
              </Box>
            </HStack>
          </FormControl>
        </GridItem>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  if (type === FEEDBACK_QUESTION_TYPE_OPEN) {
    return (
      <>
        <div className="page-break" />
        <GridItem key={index}>
          <FormControl
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap">
            <Box w="45%">
              <FormLabel fontSize="md" fontWeight="normal" mb="0">
                {question}
              </FormLabel>
            </Box>
            <Flex w="55%">
              <Tag
                w="100%"
                size="md"
                variant="outline"
                colorScheme="gray"
                rounded="sm"
                padding={2}>
                {response?.response}
              </Tag>
            </Flex>
          </FormControl>
        </GridItem>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  return <></>;
};

export default FeedbackQuestionType;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ObjektListItems from 'components/rms/ObjektListItems';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useMemo, useState } from 'react';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { changeURL, getStartingSerialNumber } from 'utils';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  status: string;
}

const ObjcktList: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  let apartmentAPI = new OMSObjectsResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    status: searchValues.status ?? '',
  });

  let keywordSearch = useDebounce(filterParams.keyword);

  const apartmentList = useQuery(
    [
      'apartmentList',
      {
        type: filterParams.type,
        currentPage: filterParams.currentPage,
        pageSize: filterParams.pageSize,
        status: filterParams.status,
      },
      keywordSearch,
    ],
    async () => {
      let { currentPage, pageSize, type, status } = filterParams;
      let query: any = {
        page: currentPage,
        limit: pageSize,
      };
      if (keywordSearch) query.keyword = keywordSearch;
      if (type) query.type = type;
      if (status) query.status = status;

      const res = await apartmentAPI.list(query);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      keyword: '',
      type: '',
      status: '',
      currentPage: INITIAL_CURRENT_PAGE,
    }));
    history.push(routes.oms.objects.list);
  }

  const checkCreateObjectPermission = useCheckPermission(
    [PermissionRequest['manage:oms']],
    routes.oms.objects.add
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.object_management_system}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.apartments}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack>
          <Flex justify="space-between">
            <Heading size="lg" textTransform="capitalize">
              {strings.apartments}
            </Heading>
            <AccessControl
              allowedPermissions={[PermissionRequest['manage:oms']]}>
              <Button
                bg="primary.400"
                colorScheme="primary"
                onClick={checkCreateObjectPermission}
                size="lg">
                {strings.add_new_apartment}
              </Button>
            </AccessControl>
          </Flex>
        </Stack>{' '}
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack direction="column" spacing="4">
                    <Stack
                      direction={['column', 'column', 'row']}
                      spacing="4"
                      align={['start', 'start', 'end']}>
                      <Grid
                        gap={['4', '4', '6']}
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(3, 1fr)',
                        ]}
                        w="100%">
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.apartment_name}</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<BiSearch />}
                                color="gray.400"
                              />
                              <Input
                                type="text"
                                name="keyword"
                                value={filterParams.keyword}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.type}</FormLabel>
                            <Select
                              name="type"
                              value={filterParams.type}
                              placeholder={`${strings.select} ${strings.type}`}
                              onChange={handleInputChange}>
                              <option value="home">{strings.house}</option>
                              <option value="apartment">{strings.flat}</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.website_active}</FormLabel>
                            <Select
                              name="status"
                              value={filterParams.status}
                              placeholder={`${strings.select} `}
                              onChange={handleInputChange}>
                              <option value="1">{strings.active}</option>
                              <option value="0">{strings.in_active}</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <Stack direction="row" spacing="4">
                        <Button
                          size="lg"
                          colorScheme="primary"
                          variant="outline"
                          onClick={handleReset}>
                          {strings.reset_filter}
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.address}</Th>
                  <Th>{strings.payement_active}</Th>
                  <Th>{strings.website_active}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {apartmentList?.data?.data?.map((data: any, index: number) => (
                  <ObjektListItems
                    key={data.id}
                    data={data}
                    startingSN={startingSN + index}
                    search={search}
                  />
                ))}
              </Tbody>
              {apartmentList.isLoading && (
                <TableSkeletonLoader rows={6} cols={7} />
              )}
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          dataList={apartmentList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default ObjcktList;

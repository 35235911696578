import http from 'utils/http';
import Resource from './resource';

class OMSapartmentImagesResource extends Resource {
  constructor() {
    super(`oms/apartment-images`);
  }
  upload(id: string, resource?: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'post',
      data: resource,
    });
  }
}

export { OMSapartmentImagesResource as default };

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
interface Props {
  data?: any;
}

const FeedbackQuestionForm: React.FC<Props> = (props) => {
  let { data } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<any>();

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('type', data.type);
    }
  }, [data, setValue]);

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Grid gap="4" templateColumns="repeat(1, 1fr)">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired w="100%">
              <FormLabel>{strings.question}</FormLabel>
              <Textarea
                rows={1}
                {...register('name', {
                  required: strings.title_required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                placeholder={strings.question}
                defaultValue={data?.name}
              />
              <FormErrorMessage>
                <>{errors?.name && errors?.name?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl
              isInvalid={!!errors?.type}
              w={['100%', '50%']}
              isRequired>
              <FormLabel>{strings.select_type_art}</FormLabel>
              <Select
                size="lg"
                {...register('type', {
                  required: strings.required_type,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                placeholder={strings.select_type_art}
                rounded="sm"
                isDisabled={data?.type}>
                <option value="open">{strings.open_ended}</option>
                <option value="closed">{strings.closed_ended}</option>
                <option value="rating">{strings.rating}</option>
              </Select>
              <FormErrorMessage>
                <>{errors?.type && errors?.type?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default FeedbackQuestionForm;

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import BasicInformationForm from '../Appartments/BasicInformationForm';

const CreateApartment: React.FC = () => {
  const toast = useToast();
  const local = useLocation();
  let { search } = local;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.oms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.oms.objects.list + search}>
              {strings.object_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.add_new_apartment}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex gridGap="2">
          <Heading size="lg" textTransform="capitalize">
            {strings.add_new_apartment}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <BasicInformationForm editing={true} />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateApartment;

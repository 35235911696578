import { Checkbox, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelectRoot from '../../../../components/ReactSelectRoot/ReactSelectRoot';

interface Props {
  data?: any;
  field: any;
  editing: boolean;
}

const DynamicFields: React.FC<Props> = (props) => {
  const { field, editing } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();
  let { id, name, type, apartment_features, type_values } = field;
  let apartmentvalue = apartment_features[0] ?? {};
  let { feature_values } = apartmentvalue;

  // setValue (default value) for dropdown types
  useEffect(() => {
    if (typeof feature_values !== 'object') return;
    const defaultValue = feature_values?.map((item: string) => ({
      label: item,
      value: item,
    }));
    if (type === 'number') {
      setValue(`${id}`, Number(feature_values));
    } else if (type === 'checkbox') {
      setValue(`${id}`, !!feature_values);
    } else if (type === 'option') {
      setValue(`${id}`, defaultValue);
    }
  }, [id, feature_values, setValue, type]);

  if (type === 'checkbox') {
    return (
      <form>
        <FormControl>
          <Controller
            control={control}
            name={`${id}`}
            render={({ field }) => (
              <Checkbox
                {...field}
                id={`${id}`}
                isDisabled={!editing}
                size="lg"
                mt="2"
                colorScheme="green"
                defaultChecked={!!feature_values}>
                {name}
              </Checkbox>
            )}
          />
        </FormControl>
      </form>
    );
  } else if (type === 'option') {
    return (
      <form>
        <FormControl>
          <FormLabel>{name}</FormLabel>
          <Controller
            control={control}
            name={`${id}`}
            render={({ field }) => (
              <ReactSelectRoot
                {...field}
                id={`${id}`}
                isDisabled={!editing}
                isMulti={true}
                placeholder={strings.select}
                options={type_values.map((item: string) => ({
                  label: item,
                  value: item,
                }))}
                styles={reactSelectStyles}
              />
            )}
          />
        </FormControl>
      </form>
    );
  } else {
    return (
      <form>
        <FormControl>
          <FormLabel>{name}</FormLabel>
          <Input
            isDisabled={!editing}
            type={type}
            defaultValue={feature_values}
            placeholder={name}
            {...register(`${id}`)}
          />
        </FormControl>
      </form>
    );
  }
};

export default DynamicFields;

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import OMSSeasonResource from 'api/oms-season';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import SeasonListItems from 'components/rms/SeasonListItems';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
interface FilterParams {
  currentPage: number;
  pageSize: number;
}

const SeasonList: React.FC = () => {
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  // Local State

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
  });

  let seasonAPI = new OMSSeasonResource();

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const seasonList = useQuery(
    [
      'seasonList',
      {
        currentPage: filterParams.currentPage,
        pageSize: filterParams.pageSize,
      },
    ],
    async () => {
      let { currentPage, pageSize } = filterParams;
      let query: any = {
        page: currentPage,
        limit: pageSize,
      };

      const res = await seasonAPI.list(query);
      setIsFilterLoading(false);
      return res.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const checkCreateSeasonPermission = useCheckPermission(
    [PermissionRequest['manage:oms']],
    routes.oms.season.add
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="6">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.oms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.oms.season.list}>
              {strings.season}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack>
          <Flex justify="space-between">
            <Heading size="lg" textTransform="capitalize">
              {strings.season_list}
            </Heading>
            <Button
              colorScheme="primary"
              size="lg"
              onClick={checkCreateSeasonPermission}>
              {strings.add_season}
            </Button>
          </Flex>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.season_name}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.from_date}</Th>
                  <Th>{strings.to_date}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {seasonList?.data?.data?.map((data: any, index: number) => (
                  <SeasonListItems key={data.id} data={data} />
                ))}
              </Tbody>
              {seasonList.isLoading && (
                <TableSkeletonLoader rows={6} cols={5} />
              )}
            </Table>
          </TableContainer>
        </Stack>

        <Pagination
          dataList={seasonList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default SeasonList;

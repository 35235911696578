import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import { ForgotPassword, Login } from 'pages/auth';
import ResetPassword from 'pages/auth/ResetPassword';
import SetPassword from 'pages/auth/SetPassword';
import VerifyEmail from 'pages/auth/VerifyEmail';
import SecondScreen from 'pages/customer-screen/SecondScreen';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

interface IProps {
  isLoggedIn?: boolean;
  [key: string]: any;
}

const PublicRoute: React.FC<IProps> = (props) => {
  const { isLoggedIn } = props;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get('redirect');

  const redirectUrlBase64Decode = window.atob(redirectUrl as string);

  const redirectRoute = redirectUrlBase64Decode || routes.splash;

  return !isLoggedIn ? (
    <Switch>
      <Route path={routes.auth.login} component={Login} />
      <Route path={routes.auth.forgotPassword} component={ForgotPassword} />
      <Route path={routes.auth.resetPassword} component={ResetPassword} />
      <Route path={routes.auth.setPassword} component={SetPassword} />
      <Route path={routes.auth.verifyEmail} component={VerifyEmail} />
      <Route path={routes.customerScreen.base} component={SecondScreen} />
    </Switch>
  ) : (
    <Redirect to={redirectRoute} />
  );
};

export default withAuthState(PublicRoute);

import { Box, Flex, Heading, Stack, Tooltip } from '@chakra-ui/react';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import { TicketInfoSchema } from 'constants/schema';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useLocation } from 'react-router-dom';
import TaskListItem from './TaskListItem';

interface Props {
  ticketStatus: string;
  ticketList: any;
  ticketMeta: any;
  isLoading: boolean;
  initialRender: boolean;
  handleLoadMore: (ticketStatus: string) => void;
}

const getTicketBorderColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'blue.400';
    case 'inProgress':
      return 'purple.400';
    case 'done':
      return 'green.400';
    case 'closed':
      return 'red.400';
    default:
      return 'blue.400';
  }
};

const getTicketStatusName = (status: string) => {
  switch (status) {
    case 'open':
      return strings.open;
    case 'inProgress':
      return strings.inprogress;
    case 'done':
      return strings.done;
    case 'closed':
      return strings.closed;
    default:
      return '';
  }
};

const MemoizedInnerList = React.memo(function InnerList(props: Props) {
  const {
    ticketStatus,
    ticketList,
    ticketMeta,
    handleLoadMore,
    isLoading,
    initialRender,
  } = props;

  const { search } = useLocation();

  return (
    <Stack bg="white" shadow="box" borderRadius="lg">
      <Flex
        justifyContent="space-between"
        pt="6"
        pb="4"
        mx="6"
        borderBottom="1px"
        borderColor={getTicketBorderColor(ticketStatus)}>
        <Heading size="sm">{getTicketStatusName(ticketStatus)}</Heading>
        <Tooltip hasArrow label={`${ticketMeta.total} ${strings.tickets}`}>
          <Heading size="sm" color="gray.400">
            {ticketMeta.total}
          </Heading>
        </Tooltip>
      </Flex>

      <Box h={['85vh', '80vh', '75vh', '100vh']}>
        {isLoading && initialRender && <TicketSkeletonLoader rows={3} />}
        <PerfectScrollbar
          className="show-scroll"
          id={`scrollable-ticket-parent-target-${ticketStatus}`}>
          <InfiniteScroll
            dataLength={ticketList.length}
            next={() => handleLoadMore(ticketStatus)}
            hasMore={ticketMeta.current_page < ticketMeta.last_page}
            loader={<TicketSkeletonLoader rows={3} />}
            scrollableTarget={`scrollable-ticket-parent-target-${ticketStatus}`}>
            {ticketList?.map((task: TicketInfoSchema, index: number) => (
              <Draggable
                key={task.id}
                draggableId={task.id.toString()}
                index={index}>
                {(provided) => (
                  <Box
                    bg="white"
                    py="2"
                    px="4"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <TaskListItem
                      ticketId={task.id}
                      title={task.title}
                      appointment_date={task?.appointment_date}
                      object={task.apartment?.name}
                      assignedUserStatus={task.assigned_user?.status}
                      assignedUserName={task.assigned_user?.name}
                      external_company={task.external_company}
                      recursive={task.recursive_interval}
                      billable={task.is_billable}
                      priority={task.priority}
                      avatar={task.assigned_user?.image_url}
                      dueDate={task.due_date}
                      search={search}
                    />
                  </Box>
                )}
              </Draggable>
            ))}
          </InfiniteScroll>
        </PerfectScrollbar>
      </Box>
    </Stack>
  );
});

const TaskBoard = (props: Props) => {
  const {
    ticketStatus,
    ticketList,
    ticketMeta,
    handleLoadMore,
    isLoading,
    initialRender,
  } = props;

  return (
    <Box w="25%" minWidth="300px">
      <Droppable droppableId={ticketStatus} key={ticketStatus}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <MemoizedInnerList
              ticketStatus={ticketStatus}
              ticketList={ticketList}
              ticketMeta={ticketMeta}
              handleLoadMore={handleLoadMore}
              isLoading={isLoading}
              initialRender={initialRender}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};

export default TaskBoard;

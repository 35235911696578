import {
  Center,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import imageCompression from 'browser-image-compression';
import { strings } from 'config/localization';
import { imageCompressionOptions } from 'constants/common';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';

interface Props {
  title?: string;
  description?: string;
  uploadedFiles?: any;
  fileKey?: any;
  required?: boolean;
  multiple?: boolean;
  accept?: string | string[];
  clearFileInputToggle?: any;
}
const ApartmentImagesUploader: React.FC<Props> = (props) => {
  const {
    title = strings.upload_files,
    description = strings.upload_image,
    uploadedFiles,
    fileKey = 'files',
    multiple = false,
    accept = 'image/*',
    clearFileInputToggle,
  } = props;
  const toast = useToast();
  const isFirstMount = useRef(true);

  const [currentImage, setCurrentImage] = useState(uploadedFiles);
  const [myFiles, setMyFiles] = useState<any>([]);
  const [previewFile, setPreviewFile] = useState<any>([]);
  const [imageName, setImageName] = useState<any>();
  const { setValue, clearErrors } = useFormContext();
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (multiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
      } else {
        setMyFiles(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        let title = strings.max_upload_size_15;
        if (fileRejections[0]?.errors[0]?.code === 'too-many-files') {
          title = strings.only_1_file_is_allowed;
        }
        toast({
          title: title,
          status: 'error',
          isClosable: true,
        });
      }
    },
    [multiple, myFiles, toast]
  );

  React.useMemo(async () => {
    if (myFiles.length === 0) return;
    let files: File[] = [];
    for (let i = 0; i < myFiles.length; i++) {
      let file = myFiles[i];
      if (!file.type.includes('svg')) {
        let compressedFile = await imageCompression(
          file,
          imageCompressionOptions
        );
        files.push(compressedFile);
      } else {
        files.push(file);
      }
    }
    if (multiple) {
      let typeFile = files.map((item: any) => new File([item], item.name));
      setValue(fileKey, typeFile);
    } else {
      let fl = new File([files[0]], fileKey);
      setValue(fileKey, fl);
    }
    setPreviewFile(() => {
      const previewFiles: any[] = [];
      files.forEach((file) => {
        previewFiles.push(URL.createObjectURL(file));
      });
      return previewFiles;
    });
    setImageName(() => {
      const imagesNames: string[] = [];
      files.forEach((file: any) => {
        imagesNames.push(file[0]?.name);
      });
      return imagesNames;
    });
    if (fileKey === 'icon') clearErrors('icon');
    if (fileKey === 'cover') clearErrors('cover');
  }, [multiple, setValue, fileKey, myFiles, clearErrors]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 15) {
      //Limit 15 MB
      return {
        code: 'file-size-too-large',
        message: strings.max_upload_size_15,
      };
    }

    return null;
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      setMyFiles([]);
      setValue(fileKey, '');
      setPreviewFile([]);
      setImageName([]);
    }
  }, [clearFileInputToggle, fileKey, setValue]);

  const getPreview = () => {
    if (previewFile && previewFile.length > 0) {
      return previewFile.map((file: any, index: number) => {
        return (
          <GridItem key={file}>
            <Image
              src={file}
              alt={imageName[index]}
              title={imageName[index]}
              maxH={280}
              width="100%"
              objectFit="cover"
            />
          </GridItem>
        );
      });
    }

    if (currentImage) {
      return (
        <Image
          src={
            fileKey !== 'files'
              ? currentImage
              : ` ${process.env.REACT_APP_PUBLIC_FILE_PATH}/lbs-mms/${currentImage}`
          }
          alt={currentImage}
          title={currentImage}
          maxH={280}
          width="100%"
          objectFit="cover"
        />
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    onDrop,
    validator: fileSizeValidator,
    multiple: multiple,
  });

  return (
    <>
      <Center
        {...getRootProps()}
        cursor="pointer"
        w="100%"
        h="100%"
        maxH="300px"
        bg="#F9F9F9"
        overflow="hidden"
        objectFit="cover"
        shadow="box"
        rounded="sm"
        border="2px"
        borderStyle="dashed"
        borderColor="#ACACBE"
        mb="4">
        <input {...getInputProps()} />
        <VStack>
          <Icon as={BiPlus} h="16" w="16" color="gray.300" />
          {isDragActive ? (
            <Text textAlign="center" color="gray.400">
              {title}
            </Text>
          ) : (
            <Text textAlign="center" color="gray.400">
              {description}
            </Text>
          )}
        </VStack>
      </Center>
      <Grid gap={4} templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}>
        {getPreview()}
      </Grid>
    </>
  );
};

export default ApartmentImagesUploader;

import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import InternetStatus from 'components/alert/InternetStatus';
import ChatRegister from 'components/chat/ChatRegister';
import Sidebar from 'components/layout/Sidebar';
import PermissionRequest from 'constants/PermissionRequest';
import EmployeeDeviceIdPairProvider, {
  useEmployeeDeviceIdPairContext,
} from 'context/EmployeeDeviceIdPairContext';
import SocketProvider from 'context/SocketContext';
import useChatUserInfo from 'hooks/query-hooks/useChatUserInfo';
import useIsPermitted from 'hooks/usIsPermitted';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PrivateRouter from 'router/PrivateRouter';
import routes from '../../constants/routes';

const DashboardContent: React.FC = () => {
  const { appState, isPairInfoDeviceIdLoading } =
    useEmployeeDeviceIdPairContext();

  const [isLargerThan1140] = useMediaQuery('(min-width: 1140px)');
  const location = useLocation();

  const { status } = useChatUserInfo();
  const isChatPermitted = useIsPermitted([
    PermissionRequest['show:chat-assign'],
  ]);

  const isPaddingDisabled = location.pathname.includes(routes.splash);
  const dashboardPadding = isLargerThan1140 ? 8 : 4;

  return (
    <SocketProvider
      type="Employee"
      isPairInfoDeviceIdLoading={isPairInfoDeviceIdLoading}
      deviceName={appState.employeeDeviceName}
      deviceId={appState.employeeDeviceId}
      sessionId={appState.sessionId}>
      <Flex maxH="100vh" overflow="hidden" position="relative">
        {isChatPermitted && status && <ChatRegister />}
        <Sidebar />
        <Box overflow="auto" flex="1">
          <Box p={isPaddingDisabled ? 0 : dashboardPadding} minH="100vh">
            <PrivateRouter />
          </Box>
        </Box>
        <InternetStatus />
      </Flex>
    </SocketProvider>
  );
};

const Dashboard: React.FC = () => {
  return (
    <EmployeeDeviceIdPairProvider>
      <DashboardContent />
    </EmployeeDeviceIdPairProvider>
  );
};

export default Dashboard;

import {
  Avatar,
  Button,
  ButtonGroup,
  CloseButton,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { closedORArchive } from 'api/chat';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import React, { useState } from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import AccessControl from 'services/AccessControl';
import { socket } from 'services/socket';
import TransferChatPopOver from './TransferChatPopOver';

interface Props {
  chatMessageId: string;
  type: string;
  customerName: string;
  userIdFrom: number;
  roomName: string;
  phoneRingingRoom: string;
  callInProgressRoom: string;
  handleSetPhoneRingingRoom: (roomName: string) => void;
}

const ChatUserDetails: React.FC<Props> = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    chatMessageId,
    type,
    customerName,
    userIdFrom,
    roomName,
    phoneRingingRoom,
    callInProgressRoom,
    handleSetPhoneRingingRoom,
  } = props;
  const toast = useToast();

  /**
   * Handle Close or Archive Chat
   * Check whether ananymous or not
   */
  const handleCloseArchive = async () => {
    await closedORArchive({
      _id: Object(chatMessageId),
      status: type,
      roomName: roomName,
    }).then(() => {
      window.location.reload();
    });
  };

  /**
   * User should be able to cancel the call after clicking the phone icon the second time
   *
   */
  const handleEndCall = () => {
    if (roomName !== phoneRingingRoom) return;
    // Emit socket to end call
    socket.emit('cancel call', roomName, (cb: any) => {
      if (cb.status !== 'ok') {
        toast({
          title: strings.cancel_call_error,
          status: 'error',
          isClosable: true,
        });
      }
    });
  };

  /**
   * Handling call with mediasoup between Customer and Employee
   */
  const handleMediaSoupCall = () => {
    if (callInProgressRoom) return;
    if (phoneRingingRoom) {
      handleEndCall();
      return;
    }
    // emit mediasoup and
    socket.emit('make call', roomName, (cb: any) => {
      if (cb.status !== 'ok') {
        toast({
          title: strings.make_call_error,
          status: 'error',
          isClosable: true,
        });
      }
    });
    handleSetPhoneRingingRoom(roomName);
  };

  const getPhoneIconStyles = () => {
    if (phoneRingingRoom === roomName) {
      // Show ringing animation with the icon if the phone is ringing is the same room
      return {
        color: 'blue.400',
        borderRadius: '50%',
        animation: 'phone-ring 2s ease infinite',
        border: 'solid 1px #fff',
        cursor: 'pointer',
      };
    } else if (callInProgressRoom === roomName) {
      // Show green icon if the the room is in active call
      return {
        color: 'green.400',
      };
    } else if (
      (Boolean(phoneRingingRoom) && phoneRingingRoom !== roomName) ||
      (Boolean(callInProgressRoom) && callInProgressRoom !== roomName)
    ) {
      // Show gray icon (disabled) for other rooms in case a call is being made
      // or in case of active call
      return {
        color: 'gray.200',
      };
    }
    // Show default style in case none of the above cases is true
    return {
      _hover: {
        color: 'blue.400',
        cursor: 'pointer',
      },
    };
  };

  const getPhoneTooltipLabel = () => {
    if (phoneRingingRoom === roomName) {
      return `${strings.call_in_progress} ${strings.click_to_cancel}`;
    }
    if (callInProgressRoom || phoneRingingRoom !== roomName) {
      return strings.call_in_progress;
    }
    return strings.make_call;
  };

  const shouldDisableCloseChat =
    phoneRingingRoom === roomName || callInProgressRoom === roomName;

  return (
    <Stack
      p="4"
      bg="white"
      borderTopRadius="md"
      shadow="0px -7px 60px rgba(0,0,0,0.06);">
      <CloseButton
        isDisabled={!!shouldDisableCloseChat}
        size="sm"
        pos="absolute"
        top="-8px"
        right="-8px"
        bgColor="gray.100"
        color="gray.500"
        rounded="full"
        onClick={() => setModalOpen(true)}
        _hover={{ bgColor: 'red.300', color: 'white' }}
      />
      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {type === 'closed'
              ? strings.transfer_closed_chat
              : strings.transfer_archived_chat}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {type === 'closed'
              ? strings.transfer_modal_closed_message
              : strings.transfer_modal_archived_message}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <AccessControl
                allowedPermissions={[PermissionRequest['read:chat']]}
                renderNoAccess={(data: any) => data}>
                <Button colorScheme="red" onClick={handleCloseArchive}>
                  {strings.yes}
                </Button>
              </AccessControl>
              <Button variant="outline" onClick={() => setModalOpen(false)}>
                {strings.no}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack direction="row" spacing="4">
        <Avatar
          size="lg"
          shadow="lg"
          name={customerName}
          bg="gray.400"
          color="white"
        />
        <Stack direction="column" spacing="3">
          <Heading size="sm" fontWeight="medium" pt="4">
            {customerName}
          </Heading>
        </Stack>
        <Spacer />
        <Stack direction="row" color="gray.300" pt="8">
          <Tooltip hasArrow label={getPhoneTooltipLabel()}>
            <span>
              <AccessControl
                allowedPermissions={[PermissionRequest['call:mediasoup']]}
                renderNoAccess={(data: any) => data}>
                <Icon
                  as={BiPhoneCall}
                  w="6"
                  h="6"
                  sx={getPhoneIconStyles()}
                  onClick={handleMediaSoupCall}
                />
              </AccessControl>
            </span>
          </Tooltip>
          <TransferChatPopOver
            userIdFrom={userIdFrom}
            roomName={roomName}
            shouldDisableTransferChat={
              phoneRingingRoom === roomName || callInProgressRoom === roomName
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ChatUserDetails;

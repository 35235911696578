import http from 'utils/http';
import { API_URL } from './url';

export const createPair = async (payload: any) => {
  const { data } = await http({
    url: API_URL.pair,
    method: 'POST',
    data: payload,
  });
  return data;
};

export const registerCustomerToEmployeeDevice = async (payload: any) => {
  const { data } = await http({
    url: API_URL.pair,
    method: 'PATCH',
    data: payload,
  });
  return data;
};

export const getPairInfo = async () => {
  const { data } = await http({
    url: API_URL.get_pair,
    method: 'GET',
  });
  return data;
};

export const deletePair = async () => {
  const { data } = await http({
    url: API_URL.pair,
    method: 'DELETE',
  });
  return data;
};

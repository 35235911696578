import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import ServiceSchemeType from './ServiceSchemType';

interface Props {
  serviceData: any;
  search: string;
}

const ServiceInfo: React.FC<Props> = (props) => {
  const { serviceData, search } = props;

  const checkEditServicePermission = useCheckPermission(
    [PermissionRequest['manage:sms']],
    routes.sms.services.edit.replace(':id', serviceData.id.toString()) + search
  );

  return (
    <>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.service_details}
        </Heading>
        <Stack direction="row" spacing="6">
          <Button
            size="lg"
            colorScheme="primary"
            onClick={checkEditServicePermission}>
            {strings.edit_service}
          </Button>
        </Stack>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.service_name}</FormLabel>
              <Box sx={infoStyles}>{serviceData?.name}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.service_group}</FormLabel>
              <Box sx={infoStyles}>
                {serviceData?.service_group?.name || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.price_scheme_type}
              </FormLabel>
              <Box sx={infoStyles}>
                {strings.getString(
                  serviceData?.price_scheme_type.toLowerCase()
                ) || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            {serviceData?.prices.length > 0 && (
              <ServiceSchemeType
                schemeData={serviceData?.prices}
                schemeType={serviceData?.price_scheme_type}
              />
            )}
          </GridItem>
        </Grid>

        <Grid pt="2" gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
              <Box sx={infoStyles}>{serviceData?.description || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.cover_image}</FormLabel>
              <Box sx={infoStyles}>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={serviceData?.cover}
                  alt={strings.icon}
                />
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default ServiceInfo;

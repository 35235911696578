const de = {
  /*login Credential*/
  login: 'Anmeldung',
  login_now: 'Jetzt Anmelden',
  log_in: 'Anmelden',
  password: 'Passwort',
  forgot_your_password: 'Passwort vergessen',
  email: 'Email',
  your_email_address: 'Ihre E-Mail-Adresse',
  your_password: 'Ihr Passwort',
  remember_me: 'Daten merken',
  forgot_password: 'Passwort vergessen?',
  forgot_password1: 'Passwort vergessen',
  back_to_login: 'Zurück zum Login',
  password_reset: 'Passwort zurücksetzen',
  login_message:
    'Geben Sie Ihre Zugangsdaten ein, um sich bei der MYVAL AG anzumelden.',
  forgot_message:
    'Bitte geben Sie Ihre E-Mail-Adresse ein, mit der Sie sich bei Ihrem Konto angemeldet haben.',
  myval_ag: 'MYVAL AG',
  password_info:
    'Das Passwort muss mindestens 1 Zahl, 1 Sonderzeichen, 1 Großbuchstaben und 5 weitere Zeichen enthalten',
  validate_recursive_until:
    'Bitte geben Sie ein Datum ein, das später liegt als "Wiederholen ab"',
  change_language: 'Ändern der Sprache',
  invalid_token: 'Mit diesem Link wurde bereits ein neues Passwort festgelegt.',
  two_factor_authentication: 'Zwei-Faktor-Authentifizierung',
  enter_the_pin_from_google_authentication:
    'Geben Sie den Code aus der Google Authenticator App ein.',
  authenticate: 'Authentifizieren',
  one_time_password: 'Einmaliges Passwort',
  FA_status: '2FA-Status',
  disable_2FA: '2FA ausschalten',
  enable_2FA: '2FA einschalten',
  fa_message:
    'Um die Zwei-Faktor-Authentifizierung zu aktivieren, scannen Sie bitte diese QR-Code mit der Google Authenticator App.',
  code: 'Code',
  fa_status_tooltip: 'Geheimen Schlüssel zur Aktivierung von 2FA generieren',
  authenticator_code: 'Authenticator Code',
  FA_successfully: '2FA ist erfolgreich aktiviert',
  FA_enable: '2FA ist zurzeit für Ihr Konto aktiviert',
  fa_Disable: '2FA ist jetzt deaktiviert',
  authenticator_code_error: 'Authenticator Code Fehler',
  required_authenticator_code: 'Erforderlicher Authenticator Code',
  fa_disable_title:
    'Wenn Sie die Zwei-Faktor-Authentifizierung deaktivieren möchten, dann geben Sie bitte Ihr Passwort ein, um die Aktion zu bestätigen.',
  activate_2FA: 'Aktiviere 2FA',
  deactivate_2FA: '2FA deaktivieren',

  /*button*/
  save_now: 'Jetzt Speichern',
  save: 'Speichern',
  back: 'Zurück',
  editing: 'Bearbeiten',
  filter: 'Filter',
  apply_filter: 'Suchen',
  reset_filter: 'Filter zurücksetzen',
  more_filter: 'Mehr Filter',
  cancel: 'Abbrechen',
  clear: 'Freimachen',
  reply: 'Antworten',
  comment: 'Kommentar',
  save_and_create_new_ticket: 'Speichern und neues Ticket erstellen',
  confirm_and_close: 'Bestätigen und schließen',
  confirm_and_transfer_another_items:
    'Bestätigen und weiteren Artikel transferieren',
  edit_article: 'Artikel bearbeiten',
  profile: 'Profil',
  my_profile: 'Mein Profil',
  edit_profile: 'Profil bearbeiten',
  profile_updated: 'Profil aktualisiert',
  password_updated: 'Passwort aktualisiert',
  profle_update_failed: 'Profil-Aktualisierung fehlgeschlagen',
  not_match_new_confirm_password: 'Die Passwörter stimmen nicht überein',
  incorrect_password: 'Das Passwort ist nicht korrekt',
  setting: 'Einstellungen',
  settings: 'Einstellungen',
  loggedOut: 'Ausloggen',
  customer_screen: 'Kundenbildschirm',
  logout: 'Abmeldung',
  change_password: 'Passwort ändern',
  change_now: 'Jetzt ändern',
  Current_password: 'Aktuelles Passwort',
  new_password: 'Neues Passwort',
  confirm_password: 'Passwort bestätigen',
  invalid_login_msg: 'E-Mail und Passwort stimmen nicht überin',
  invalid_current_password: 'Das Passwort ist falsch',
  user_delete_error:
    'Der Nutzer kann nicht gelöscht werden, da er noch Artikel hält',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  less_than_zero: 'Wert kleiner als Null.',
  email_not_found: 'E-Mail Adresse nicht gefunden.',
  email_required: 'E-Mail Adresse ist erforderlich',
  now: 'Jetzt',
  secret_key: 'Geheimer Schlüssel',
  create_pair: 'Paar erstellen',
  must_be_8_characters_long: 'Muss 8 Zeichen lang sein',
  generate: 'Erzeugen Sie',
  unpair: 'Verbindung trennen',
  this_will_unpair_the_device_are_you_sure:
    'Dadurch wird die Kopplung des Geräts aufgehoben. Sind Sie sicher?',
  clear_second_screen: 'Zweiten Bildschirm löschen',
  successfully_unpaired: 'Erfolgreich entpaart',
  pair_created: 'Paar erstellt',

  /*Module*/
  user_management: 'Benutzerverwaltung',
  chat: 'Chat',
  chat_overview: 'Chat Übersicht',
  ticket: 'Ticket',
  crm: 'CRM',
  contact_management: 'Kontakt Verwaltung',
  contact_center: 'Contact Center',
  feedback: 'Feedback',
  mms: 'WaWi',
  dashboard: 'Dashboard',
  /*Splash Screen*/
  choose_subsystem: 'Wählen Sie ein Subsystem',
  /*User Management*/
  user_management_system: 'Benutzerverwaltung',
  user: 'Nutzer',
  user_list: 'Nutzerliste',
  user_rights: 'Nutzerrechte',
  user_roles: 'Nutzer Rollen',
  user_name: 'Nutzername',
  user_profile: 'Nutzerprofil',
  user_details: 'Nutzer Details',
  role_details: 'Rollen Details',
  role: 'Rolle',
  add_additional_role: 'Zusätzliche Rolle hinzufügen',
  add_additional_rights: 'Zusätzliche Rechte hinzufügen',
  additional_rights: 'Zusätzliche Rechte',
  manage_extra_rights: 'Zusätzliche Rechte verwalten',
  right_from_role: 'Rechte der Rolle',
  add_role: 'Rolle hinzufügen',
  role_setup: 'Rollen-Setup',
  system: 'System',
  all_users: 'Alle Nutzer',
  all_user_with_that_role: 'Alle Nutzer mit dieser Rolle',
  add_new_role: 'Add New Role',
  add_new_user: 'Neuen Nutzer hinzufügen',
  role_name: 'Rollenname',
  privillage: 'Privileg',
  view_user: 'Nutzer ansehen',
  add_user: 'Nutzer hinzufügen',
  edit_user: 'Nutzer bearbeiten',
  save_user: 'Nutzer speichern',
  user_deatils: 'Nutzerdetails',
  delete_user: 'Nutzer löschen',
  upload_image: 'Bild hochladen',
  drop_image_or_click_to_select_image:
    'Bild ablegen oder anklicken, um Bild auszuwählen',
  assigned_tickets: 'Zugewiesene Tickets',
  action: 'Aktion',
  first_name: 'Vorname',
  last_name: 'Nachname',
  email_address: 'E-Mail-Addresse',
  valid_email_address: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
  contact_info: 'Kontaktinformationen',
  address: 'Adresse',
  search: 'Suche',
  new_user: 'Neuer Nutzer',
  has_been_assigned: 'ist Ihnen zugewiesen worden',
  has_been_transferred: 'wurde an Sie übertragen',
  login_with_new_password: 'Bitte mit neuem Passwort anmelden',
  msg_valid_password: 'Bitte geben Sie ein gültiges Passwort ein',
  msg_password_reset_link:
    'Wir haben Ihnen den Link zum Zurücksetzen des Passworts per E-Mail zugesandt',
  select_role: 'Rolle auswählen',
  search_username: 'Nutzer suchen',
  rights: 'Rechte',
  reset_password: 'Passwort zurücksetzen',
  reset_password_subtitle:
    'Bitte füllen Sie folgende Felder aus, um Ihr Passwort zurückzusetzen.',
  save_reset_password: 'Passwort zurücksetzen',
  create_password: 'Passwort festlegen',
  create_password_subtitle:
    'Bitte füllen Sie die Felder aus, um ein Passwort für Ihren Account zu erstellen',
  save_password: 'Passwort speichern',
  password_helper_text:
    'Mindestens 8 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen',
  resend_verification_email: 'Verifizierungs-E-Mail erneut senden',
  verification_email_sent: 'Verifizierungs-E-Mail gesendet',
  email_verification_success: 'E-Mail erfolgreich verifiziert',
  email_verification_failed: 'E-Mail-Verifizierung fehlgeschlagen.',
  resend_verify_email_error:
    'Fehler beim erneuten Senden der E-Mail-Bestätigung',
  resend_verify_email_success:
    'Die Verifizierungs-E-Mail wurde erfolgreich versandt. Prüfen Sie Ihren Posteingang.',
  resend_verify_email_success_to:
    'Die Verifizierungs-E-Mail wurde erfolgreich versandt an',
  email_already_verified: 'Die E-Mail ist bereits verifiziert.',
  login_to_continue: 'Bitte loggen Sie sich ein, um fortzufahren.',
  create_password_to_proceed: 'Erstellen Sie ein Passwort, um fortzufahren',
  change_confirm: 'Möchten Sie dies wirklich ändern?',
  cellphone: 'Mobiltelefon',
  invalid_cellphone: 'Ungültige Handynummer',
  email_placeholder: 'ihremail@beispiel.com',
  admin: 'Admin',
  chat_supporter: 'Chat-Mitarbeiter',
  ticket_supporter: 'Ticket-Mitarbeiter',
  mms_employee: 'WaWi-Mitarbeiter',
  verified: 'Bestätigt',
  not_yet_verified: 'Noch nicht bestätigt',
  external_companies: 'Externe Unternehmen',
  external_company: 'Externes Unternehmen',
  company_name: 'Name der Firma',
  add_new_company: 'Neues Unternehmen hinzufügen',
  company_name_is_required: 'Firmenname ist erforderlich.',
  company_email_is_required: 'Firmen-E-Mail ist erforderlich.',
  please_enter_a_valid_phone_number:
    'Bitte geben Sie eine gültige Telefonnummer ein.',
  enter_a_country_code_at_the_start:
    'Geben Sie zu Beginn einen Ländercode ein.',
  company_creation_failed: 'Unternehmenserstellung fehlgeschlagen.',
  the_email_has_already_been_taken: 'Diese E-Mail Adresse ist schon vergeben.',
  company_created: 'Unternehmen wurde erstellt.',
  edit_company: 'Unternehmen bearbeiten',
  company_updated: 'Firma erfolgreich aktualisiert.',
  company_udpate_failed: 'Unternehmensaktualisierung fehlgeschlagen.',
  delete_company: 'Unternehmen löschen',
  company_deleted: 'Firma wurde gelöscht.',
  company_delete_error: 'Unternehmen kann nicht gelöscht werden.',
  company_not_found: 'Firma nicht gefunden.',
  company_profile: 'Unternehmensprofil',
  all_companies: 'Alle Unternehmen',
  company_list: 'Firmenliste',
  companies: 'Firmen',
  view_company: 'Unternehmen anzeigen',
  all_roles: 'Alle Rollen',
  user_login_log: 'Benutzer-Login-Protokolle',
  all_login_logs: 'Alle Anmeldeprotokolle',
  access_user_log: 'Zugang zu Login-Protokollen',
  blocked: 'Blockiert',

  /*Chat System*/
  chat_system: 'Chat System',
  closed_chat: 'Geschlossener Chat',
  all_closed_chats: 'Alle geschlossenen Chats',
  chat_details: 'Chat Details',
  closed_chat_details: 'Geschlossener Chat Details',
  archived_chat: 'Archivierter Chat',
  all_archived_chats: 'Alle archivierten Chats',
  archived_chat_details: 'Archivierter Chat Details',
  chat_metrics: 'Chat Statistik',
  all_chat_metrics: 'Alle Chat-Metriken',
  sn: '#',
  today: 'Heute',
  hours: 'Uhr',
  id: 'ID',
  customer_name: 'Kunden Name',
  employee_name: 'Nutzername',
  closed_at: 'Geschlossen am',
  last_message: 'Letzte Nachricht',
  last_chat_message: 'Letzte Chat Nachricht',
  no_of_message: 'Anzahl der Nachrichten',
  actions: 'Aktion',
  no_of_chat: 'Anzahl der Chats',
  total_chats: 'Chats Gesamt',
  no_of_active_chat: 'Anzahl aktive Chats',
  no_messages_received: 'Anzahl erhaltener Nachrichten',
  total_messages: 'Nachrichten Gesamt',
  no_messages_send: 'Anzhal gesendeter Nachrichten',
  sent_messages: 'Nachrichten Gesendet',
  time_spent_in_call: 'Zeit in Anruf',
  talk_time: 'Gesprächszeit',
  view: 'Ansicht',
  add: 'Hinzufügen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  role_descripition: 'Rollenbeschreibung',
  name: 'Name',
  assignee: 'Beauftragter',
  description: 'Beschreibung',
  apartment: 'Appartement',
  apartments: 'Appartements',
  transfer_chat: 'Chat übertragen',
  chat_forwarding: 'Chat Weiterleitung',
  no_active_chat_history: 'Kein aktiver Chatverlauf',
  forward_the_chat_to_a_free_employee:
    'Leiten Sie den Chat an einen freien Mitarbeiter weiter.',
  forward_chat: 'Chat weiterleiten',
  select_employee: 'Mitarbeiter auswählen',
  select_user: 'Nutzer auswählen',
  transfer_closed_chat: 'Chat schließen?',
  transfer_archived_chat: 'Archivierten Chat übertragen',
  transfer_modal_closed_message: 'Chat wird geschlossen.',
  transfer_modal_archived_message: 'Wirklich archivieren?',
  yes: 'Ja',
  no: 'Nein',
  call: 'Anruf',
  miss_called: 'Teilnehmer hat den Anruf verpasst',
  call_ended: 'Anruf beendet',
  message_not_sent: 'Nachricht konnte nicht gesendet werden',
  join_room_error: 'Raum beitreten nicht möglich',
  make_call_error: 'Anruf starten nicht möglich',
  is_typing: 'schreibt...',
  /*Ticket Board*/
  ticket_system: 'Ticket System',
  all_tickets: 'Alle Tickets',
  ticket_board: 'Ticket Board',
  add_task: 'Aufgabe hinzufügen',
  open: 'Offen',
  inprogress: 'In Bearbeitung',
  done: 'Erledigt',
  closed: 'Geschlossen',
  document: 'Dokumente',
  recursive_task: 'Rekursive Aufgabe',
  normal_task: 'Normale Aufgabe ',
  create_ticket: 'Ticket erstellen',
  ticket_details: 'Ticket Details',
  title: 'Titel',
  ticket_title: 'Ticketname',
  object: 'Objekt',
  all_objects: 'Alle Objekte',
  customer: 'Kunde',
  repeat_task: 'Ticket Wiederholen',
  cost_recipient: 'Kostenempfänger',
  house_owner: 'Hausbesitzer',
  priority_label: 'Priorität',
  priority: 'Priorität',
  upload_files: 'Dokument hochladen',
  descriptions: 'Beschreibung',
  save_create_new_ticket: 'Speichern und neues Ticket erstellen',
  cancel_call_error: 'Anruf kann aktuell nicht beendet werden',
  author: 'Autor',
  created_by: 'Erstellt von',
  created_at: 'Erstellt am',
  last_modified_by: 'Zuletzt geändert von',
  last_modified_at: 'Zuletzt geändert am',
  last_changed_by: 'Letzte Änderung von ',
  last_changed_at: 'Letzte Änderung am ',
  status: 'Status',
  ticket_log: 'Log',
  ticket_list: 'Ticket Liste',
  recursive_interval: 'Wiederhol-Intervall',
  recursive_untill: 'Wiederholen bis',
  recursive_start: 'Wiederholen ab',
  ticket_created: 'Ticket wurde erstellt',
  has_been_created: 'wurde erstellt',
  has_not_been_created: 'zusammenführen nicht',
  has_been_merged: 'zusammenführen',
  has_not_been_merged: 'wurde nicht merged',
  has_been_updated: 'wurde aktualisiert',
  has_not_been_updated: 'wurde nicht aktualisiert',
  has_been_deleted: 'has been deleted',
  has_not_been_deleted: 'has not been deleted',
  ticket_error: 'Ticket konnte nicht erstellt werden',
  active: 'Aktiv',
  in_active: 'Inaktiv',
  select_apartment: 'Apartment auswählen',
  select_assignee: 'Beauftragten auswählen',
  select_customer: 'Kunden auswählen',
  select_cost_recepient: 'Kostenempfänger auswählen',
  select_house_owner: 'Eigentümer auswählen',
  select_priority: 'Priorität auswählen',
  select_source: 'Quelle auswählen',
  write_description: 'Beschreibung',
  expand: 'Ausklappen',
  ticket_list_error:
    'Es ist ein Fehler bei der Bearbeitung Ihrer Anfrage aufgetreten',
  select_recursive_interval: 'Rekursives Intervall auswählen',
  title_required: 'Titel ist erforderlich',
  repeating_info: 'Wiederholte Infos',
  interval: 'Intervall',
  repeat_from: 'Wiederholen von',
  repeat_until: 'Wiederholen bis',
  validate_recursive_date: 'Bitte geben Sie ein gültiges Wiederhol-Datum an',
  comment_here: 'Hier kommentieren',
  comment_required: 'Kommentar ist erforderlich',
  id_not_found: 'ID nicht gefunden',
  edit_ticket: 'Ticket bearbeiten',
  ticket_updated: 'Ticket erfolgreich aktualisiert',
  change_status: 'änderte den Ticket-Status',
  status_updated: 'Status erfolgreich aktualisiert',
  select_status: 'Status auswählen',
  comment_error: 'Kommentar-Fehler',
  view_more: 'Mehr anzeigen',
  view_less: 'Weniger anzeigen',
  billability: 'Abrechenbarkeit',
  billable: 'Abrechenbar',
  spent_time: 'Aufgewendete Zeit',
  adjust_time_spent: 'Aufgewendete Zeit anpassen',
  correct_spent_time: 'Verbrachte Zeit korrigieren',
  spent_time_message: 'Die verbrachte Zeit wurde manuell korrigiert',
  chat_not_found: 'Ihr Feed enthält keinen Chat',
  chat_not_selected: 'Kein Chat ausgewählt',
  chat_active_toggle_tooltip_message:
    'Neue Chats können Ihnen zugewiesen oder an Sie übertragen werden, wenn Sie aktiv sind. Während Sie ein Gespräch führen, wird der Schalter automatisch deaktiviert',
  chat_users_not_available_for_transferral:
    'Im Moment ist leider kein weiterer Mitarbeiter verfügbar.',
  call_in_progress: 'Anruf in Arbeit',
  click_to_cancel: 'Zum Abbrechen erneut klicken',
  call_cancelled: 'Anruf abgebrochen',
  make_call: 'Jemanden anrufen',
  chat_active_info: 'Chat Aktivität Info',
  no_chat_assigned: 'Im Moment ist Ihnen kein Chat zugewiesen.',
  task: 'Ticket',
  low: 'Niedrig',
  medium: 'Mittel',
  high: 'Hoch',
  urgent: 'Drigend',
  normal: 'Normal',
  repeating: 'Wiederholend',
  select_type: 'Typ auswählen',
  select_type_art: 'Art auswählen',
  ticket_detail: 'Ticket Detail',
  edit_task: 'Ticket bearbeiten',
  created_ticket: 'erstellte das Ticket',
  updated_ticket: 'änderte das Ticket',
  changed_status: 'änderte den Ticket-Status',
  assigned_ticket: 'zugeordnetes Ticket',
  commented: 'kommentierte',
  order_by: 'Sortieren nach',
  createdat: 'Erstellt am',
  order: 'Auf/Absteigend',
  asc: 'Aufsteigend',
  desc: 'Absteigend',
  select_sort: 'Sortierung wählen',
  select_order: 'Ordnung auswählen',
  ticket_name: 'Auftrag ',
  select_external_company: 'Wählen Sie Externes Unternehmen aus',
  showing_all_customers: 'Alle Kunden anzeigen',
  showing_all_options: 'Alle Optionen anzeigen',
  appointment: 'Termin',
  appointment_date_time: 'Datum und Uhrzeit des Termins',
  enter_appointment_date_time: 'Datum und Uhrzeit des Termins eingeben',
  full_screen: 'Vollbild',

  /*CRM*/
  customer_relation_management: 'Kundenverwaltung',
  contact_list: 'Kontaktliste',
  all_contacts: 'Alle Kontakte',
  merge_list: 'Liste zusammenführen',
  add_contact: 'Kontakt hinzufügen',
  label: 'Label',
  source: 'Quelle',
  destination: 'Ziel',
  added: 'Hinzugefügt',
  last_activities: 'Letzte Aktivität',
  last_ticket: 'Letztes Ticket',
  street: 'Straße',
  house_no: 'Hausnummer',
  post_code: 'Postleitzahl',
  town: 'Ort',
  country: 'Land',
  phone: 'Telefonnummer',
  mobile: 'Handynummer',
  company: 'Firma',
  fellow_travelers: 'Mitreisende',
  pets: 'Haustiere',
  equipment_features: 'Ausstattungsmerkmale',
  services: 'Services',
  service: 'Dienst',
  notes_external: 'Notizen extern',
  notes_internal: 'Notizen intern',
  add_now: 'Hinzufügen',
  added_on: 'Hinzugefügt am',
  edit_contact: 'Kontakt bearbeiten',
  event_history: 'Event-Log',
  chanel: 'Kanal',
  date_time: 'Datum / Uhrzeit',
  contact_details: 'Kontaktdetails',
  merge: 'Zusammengeführt',
  unmerge: 'Nicht zusammengeführt',
  updated: 'Aktualisiert',
  city: 'Stadt',
  select_channel: 'Select Channel',
  merge_contact: 'Kontakte zusammenführen',
  merge_contact_sidebar: 'Zusammenführen',
  select_contact: 'Select Contact',
  logs: 'Logs',
  merged_date: 'Zusammengeführtes Datum',
  updated_date: 'Neues Datum',
  uploaded_by: 'hochgeladen von',
  file: 'Datei',
  image: 'Bild',
  images: 'Bilder',
  progress_status: 'Fortschrittsstatus',
  upload_file: 'Hochgeladen Datei',
  continue: 'Weiter',
  view_contact: 'Kontakt Details',
  delete_contact: 'Kontakt löschen',
  merge_detail: ' Kontakt Zusammenführung Detail',
  merge_preview: 'Zusammenführung Vorschau',
  merge_contact_is_permanent:
    'Das Zusammenführen von Kontakten ist ein Vorgang, der nicht rückgängig gemacht werden kann.',
  janitor: 'Hausmeister',
  support: 'Unterstützung',
  add_new_guest: 'Neuen Gast hinzufügen',
  add_new_owner: 'Neuen Eigentümer hinzufügen',
  owners_are_not_allowed_to_be_merged:
    'Eigentümer dürfen nicht zusammengelegt werden',
  iban: 'IBAN',
  swift_bic: 'Swift/BIC',
  contact_with_bookings_cannot_be_merged:
    'Kontakt mit Buchungen kann nicht zusammengeführt werden',

  /*Contact Center*/
  total_calls: 'Anrufe',
  aircall_user_name: 'Nutzer',
  total_incoming_call: 'Eingehnde',
  total_call_duration: 'Eingehende Dauer',
  total_outgoing_call: 'Ausgehende',
  outgoing_call_duration: 'Ausgehende Dauer',
  average: 'Durchschnitt',
  total_duration: 'Dauer gesamt',
  internal_phone_number: 'Interne Telefonnummer',

  /*Feedback*/
  feedback_management: 'Feedback Verwaltung',
  feedback_system: 'Feedback System',
  access_feedback: 'Feedback und Mails managen',
  access_feedback_questions: 'Fragen und Einstellungen managen',
  access_feedback_resend: 'Zugang Feedback erneut senden',
  all_feedbacks: 'Alle Feedbacks',
  feedback_lists: 'Feedback Lists',
  mails: 'Mails',
  all_mails: 'Alle Mails',
  mail_lists: 'Mail Liste',
  questions: 'Fragen',
  all_questions: 'Alle Fragen',
  question_lists: 'Fragen Liste',
  date: 'Datum',
  closed_ended: 'Ja/Nein-Frage',
  open_ended: 'Text-Frage',
  rating: 'Bewertungs-Frage',
  time_period: 'Zeitraum',
  feedback_detail: 'Feedback Detail',
  mark_as_done: 'Als erledigt markieren',
  publish_feedback: ' Feedback veröffentlichen',
  publish_feedback_on_object_page: 'Feedback auf Objektseite veröffentlichen',
  do_not_publish_feedback: 'Feedback nicht veröffentlichen',
  do_not_publish_feedback_on_object_page:
    'Kein Feedback auf der Objektseite veröffentlichen',
  use_for_score_calculation: 'Für Score-Berechnung verwenden',
  do_not_use_for_score_calculation: 'Nicht für die Score-Berechnung verwenden',
  contact_customer: 'Kunden kontaktieren',
  contact_house_owner: 'Eigentümer kontaktieren',
  send_mail: 'Mail erneut senden',
  new_question: 'Neue Frage',
  add_new_question: 'Neue Frage hinzufügen',
  edit_question: ' Frage bearbeiten',
  add_question: 'Frage hinzufügen',
  delete_question: 'Frage löschen',
  edit_feedback: ' Feedback Detail',
  view_feedback: 'Rückmeldung anzeigen',
  feedback_question: 'Feedback-Frage',
  feedback_question_deleted_successfully: 'Feedback-Frage erfolgreich gelöscht',
  sent: 'Geschickt',
  dispatched: 'Versendet',
  received: 'Empfangen',
  feedback_mail_sent_sucessfully: 'Feedbackmail erfolgreich versendet',
  feedback_mail_not_sent: 'Feedback-E-Mail wurde nicht gesendet',
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    ' Wie viele Tage nach der Abreise soll die Feedback-Mail versendet werden?',
  select_days: 'Tage auswählen',
  days: 'Tage',
  feedback_received: 'Feedback erhalten',
  question: 'Frage',
  answers: 'Antworten',
  response: 'Antwort',
  the_rating_is_out_of_10: 'Die Bewertung ist von 10',
  absolutely: 'Absolut',
  not_at_all: 'Überhaupt nicht',
  this_is_closed_ended_question: 'Dies ist eine Ja/Nein-Frage',
  this_is_open_ended_question: 'Dies ist eine offene Frage',
  feedback_has_been_published_successfully:
    'Feedback wurde erfolgreich veröffentlicht',
  feedback_publish_error: 'Feedback-Veröffentlichungsfehler',
  published_feedback_on_object_page_successfully:
    'Feedback auf Objektseite erfolgreich veröffentlicht',
  publish_feedback_on_object_page_error:
    'Veröffentlichtes Feedback zum Objektseitenfehler',
  feedback_has_been_unpublished_successfully:
    'Die Veröffentlichung des Feedbacks wurde erfolgreich aufgehoben',
  feedback_unpublish_error:
    'Fehler beim Aufheben der Veröffentlichung des Feedbacks',
  feedback_has_been_marked_as_done_successfully:
    'Feedback wurde als erfolgreich abgeschlossen markiert',
  feedback_mark_as_done_error: 'Feedback als erledigt markieren Fehler',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'Wie würden Sie Ihren Urlaub in List auf Sylt und die Dienstleistung der Appartementvermittlung Familie Clausen insgesamt bewerten?',
  how_satisfied_were_you_with_your_stay_in_our_appartment:
    'Wie zufrieden waren Sie mit Ihrem Aufenthalt in unserem Appartment?',
  publish_status: 'Status veröffentlichen',
  published_on_website: 'Auf der Website veröffentlicht',
  published_on_object_detail_page: 'Veröffentlicht auf der Objekt-Detailseite',
  score_calculation: 'Punkteberechnung',
  feedback_on_object_page_has_been_unpublished_successfully:
    'Feedback zur Objektseite wurde erfolgreich unveröffentlicht',
  feedback_on_object_page_unpublish_error:
    'Feedback zum Fehler beim Unveröffentlichen von Objektseiten',
  score_calculation_used: 'Verwendete Punkteberechnung',
  score_calculation_use_error: 'Punkteberechnung Fehler verwenden',
  score_calculation_unused: 'Punkteberechnung Unbenutzt',
  score_calculation_unuse_error: 'Punkteberechnung Nichtverwendung Fehler',
  object_publish: 'Objekt veröffentlicht',
  website_publish: 'Website veröffentlicht',
  not_publish: 'Nicht veröffentlicht',
  score_total: 'Bewertungen',
  answer_rate: 'Antwort-Rate',
  public_score_calculation: 'Score-Berechnung',
  real_score: 'Gesamt-Score',
  public_score: 'Score öffentlich',
  detail_reviews_general: 'Bewertungen allgemein',
  detail_review_object_page: 'Bewertungen Objekt-Seite',
  reviews_general: 'Bewertungen allgemein',
  reviews_object_page: 'Bewertungen Objekt-Seite',
  score_general: 'Bewertungen Gesamt',
  score_objects: 'Bewertungen nach Objekten',
  no_mail: 'Keine E-Mail vorhanden',
  no_email: 'Keine E-Mail vorhanden',
  analysis: 'Analyse',
  average_rating: 'Durchschnittliche Bewertung.',
  percentage_of_closed_ended: 'Prozentsatz von Ja / Nein.',
  percentage_of_open_ended: 'Prozentsatz beantwortet / nicht beantwortet.',
  feedback_analysis: 'Feedback-Analyse',
  delete_mail: 'Mail löschen',
  feedback_mail_deleted_successfully: 'Erfolgreich gelöschte Feedback-Mail',
  feedback_analysis_detail: 'Details_der_Feedback_Analyse',
  feedback_response_detail: 'Feedback_Antwortdetails',
  feedback_deleted_successfully: 'Feedback erfolgreich gelöscht',
  delete_feedback: 'Feedback löschen',
  resend_feedback: 'Feedback erneut senden',
  resent: 'Erneut geschickt',

  /*Inventory*/
  inventory: 'Inventar',
  inventories: 'Inventar',
  all_inventory: 'Alle Inventare',
  inventory_name: 'Name des Inventars',
  used_item: 'Benutztes Inventar',
  quantity: 'Menge',
  quantity_anzahl: 'Anzahl',
  total_quantity: 'Gesamtmenge',
  from: 'Von',
  to: 'Zu',
  add_item: 'Artikel hinzufügen',
  deselect_all: 'Alle abwählen',

  /*MMS*/
  merchandise_management_system: 'Warenwirtschaftssystem',
  transfer_item: 'Inventar übertragen',
  all_transfer_item: 'Inventar übertragen',
  all_transfer_logs: 'Übertragungsprotokoll',
  select_articles: 'Inventar auswählen',
  search_article: 'Inventar suchen',
  stock_type: 'Stock Type',
  units: 'Einheiten',
  unit: 'Einheit',
  all_units: 'Alle Einheiten',
  unit_details: 'Einheit Details',
  select_source_warehouse: 'Quell-Lager auswählen',
  rooms: 'Räume',
  room: 'Raum',
  room_name: 'Raumname',
  add_room: 'Raum hinzufügen',
  edit_room: 'Raum bearbeiten',
  enter_room_name: 'Raumnamen eingeben',
  delete_room: 'Raum löschen',
  racks: 'Regale',
  rack: 'Regal',
  add_rack: 'Regal hinzufügen',
  delete_rack: 'Regal löschen',
  shelf: 'Regalboden',
  add_shelf: 'Regalboden hinzufügen',
  delete_shelf: 'Regalboden löschen',
  shelves: 'Regalböden',
  select_destination_warehouse: 'Wählen Sie das Ziel-Lager aus',
  warehouse: 'Lager',
  warehouses: 'Lager',
  all_warehouses: 'Alle Lagerhäuser',
  warehouse_name: 'Lagername',
  add_warehouse: 'Lager hinzufügen',
  edit_warehouse: 'Lager bearbeiten',
  delete_warehouse: 'Lager löschen',
  warehouse_details: 'Lagerdetails',
  warehouse_information: 'Lager Informationen',
  warehouse_not_found: 'Lager nicht gefunden',
  shelf_delete_error: 'Beim Löschen des Regalbodens ist ein Fehler aufgetreten',
  rack_delete_error: 'Beim Löschen des Regals ist ein Fehler aufgetreten',
  room_delete_error: 'Beim Löschen des Lagers ist ein Fehler aufgetreten',
  articles: 'Artikel',
  article: 'Artikel',
  all_articles: 'Alle Artikel',
  add_article: 'Artikel hinzufügen',
  articles_names: 'Artikelnamen',
  type: 'Typ',
  types: 'Arten',
  type_details: 'Geben Sie Details ein',
  buying_price: 'Einkaufspreis (EK)',
  selling_price: 'Verkaufspreis (VK)',
  seller: 'Verkäufer',
  seller_home_page_shop: 'Verkäufer Webseite',
  minimum_stock: 'Mindestbestand',
  maximum_stock: 'Höchstbestand',
  out_of_stock: 'Nicht auf Lager',
  minimum_stock_notification: 'Mindestbestandsbenachrichtigung',
  ean_number: 'EAN-Nummer',
  notes: 'Anmerkungen',
  article_details: 'Artikeldetails',
  objects: 'Objekt',
  object_id: 'Objekt ID',
  object_name: 'Objekt name',
  booking: 'Buchung',
  tickets: 'Tickets',
  inventory_load: 'Inventarlast',
  object_details: 'Objektdetails',
  ticket_open_inprogress_done: 'Tickets (Offen / In Bearbeitung / Erledigt)',
  abbreviation: 'Abkürzung',
  street_number: 'Hausnummer',
  is_active: 'Ist aktiv',
  type_name: 'Artname',
  add_type: 'Art hinzufügen',
  all_types: 'Alle Arten',
  unit_name: 'Einheitenname',
  add_unit: 'Einheit hinzufügen',
  edit_unit: 'Einheit bearbeiten',
  transfer_log: 'Übertragungsprotokoll',
  transfer_logs: 'Übertragungsprotokoll',
  my_transfer_log: 'Mein Übertragungsprotokoll',
  my_transfer_logs: 'Mein Übertragungsprotokoll',
  all_my_logs: 'Mein Übertragungsprotokoll',
  ticket_source: 'Ticketquelle',
  done_by: 'Erledigt durch',
  required: 'Erforderlich',
  user_created: 'Benutzer wurde erstellt',
  user_creation_failed: 'Benutzererstellung fehlgeschlagen',
  user_udpate_failed: 'Benutzeraktualisierung fehlgeschlagen',
  password_update_failed: 'Passwort-Aktualisierung fehlgeschlagen',
  user_not_found: 'Benutzer nicht gefunden',
  update: 'Aktualisieren',
  user_updated: 'Benutzer erfolgreich aktualisiert',
  user_deleted: 'Der Benutzer wurde gelöscht',
  roles_not_found: 'Rollen nicht gefunden',
  article_image: 'Artikelbild',
  confirm_delete: 'Möchten Sie das wirklich löschen?',
  edit_type: 'Art bearbeiten',
  article_name: 'Artikelname',
  upload_article_picture: 'Artikelbild hochladen',
  select: 'Auswahl',
  min_stock_notification_tooltip:
    'Sie werden benachrichtigt, wenn der Mindestbestand erreicht ist',
  min_stock_vaidation_msg: 'Der Wert sollte unter dem Höchstbestand liegen',
  max_stock_vaidation_msg: 'Der Wert sollte größer als der Mindestbestand sein',
  valid_data: 'Bitte geben Sie gültige Daten ein',
  valid_only_number: 'Bitte geben Sie nur Zahlen ein.',
  phone_call: 'Telefonanruf',
  collapse_sidebar: 'Seitenleiste einklappen',
  article_type: 'Artikel Art',
  article_unit: 'Artikel Einheit',
  expand_sidebar: 'Seitenleiste ausklappen',
  max_upload_size_5: 'Maximale Dateigröße: 5MB',
  max_upload_size_10: 'Maximale Dateigröße: 10MB',
  max_upload_size_15: 'Maximale Dateigröße: 15MB',
  max_characters_exceeded: 'Maximale Buchstabenanzahl überschritten',
  selected_files: 'Ausgewählte Dateien',
  drag_drop_files_msg:
    'Ziehen Sie Dateien per Drag & Drop in das Feld oder klicken Sie, um Dateien auszuwählen',
  files: 'Dateien',
  delete_file: 'Datei löschen',
  only_1_file_is_allowed: 'Nur 1 Datei ist erlaubt.',
  access_user: 'User Management Zugriff',
  manage_role: 'Rollen verwalten',
  manage_user: 'Nutzer verwalten',
  access_external_companies: 'Greifen Sie auf externe Unternehmen zu',
  manage_external_companies: 'Externe Unternehmen bearbeiten',
  access_chat: 'Chat Zugriff',
  access_closed_chat: 'Geschlossene Chats Zugriff',
  access_archived_chat: 'Archiviertte Chats Zugriff',
  access_chat_metrics: 'Chat Statistik Zugriff',
  access_chat_assign: 'Chat Zuweisung',
  access_chat_mediasoup: 'Chat Video',
  access_ticket: 'Ticket Zugriff',
  access_mms: 'WaWi Zugriff',
  can_hold_item: 'Kann Inventar halten',
  manage_article: 'Lager, Art und Einheit managen',
  stocktaking_mode: 'Inventurmodus',
  stocktaking: 'Inventur',
  uploading: 'Hochladen',
  upload_new: 'Neu hochladen.',
  remove: 'Entfernen',
  invalid_profile_upload: 'Ungültiger Profilbild-Upload',
  file_must_be_an_image_file: 'Die Datei muss ein Bildtyp sein',
  clear_profile_pic: 'Profilbild löschen',
  are_you_sure:
    'Sind Sie sicher? Diese Aktion kann nicht rückgängig gemacht werden',
  upload_label: 'Profilbild hochladen',
  remove_label: 'Profilbild entfernen',
  invalid_file_size: 'Ungültige Dateigröße',
  file_size_info: 'Die Dateigröße muss weniger als 5 MB betragen',
  uploading_profile: 'Profilbild hochladen',
  no_permission_msg:
    'Sie haben keine Zugangsberechtigung. Bitte kontaktieren Sie einen Administrator',
  new: 'Neu',
  fresh: 'Neu',
  consumed: 'Verbraucht',
  employee: 'Benutzer',
  confirm: 'Bestätigen',
  transfer_date: 'Übertragungsdatum',
  transfer_source: 'Übertragungsinstanz',
  transfer_by: 'Übertragen von',
  transfer_quantity: 'Übertragungsmenge',
  category: 'Art',
  select_category: 'Kategorie wählen',
  house_number: 'Hausnummer',
  object_image: 'Objektbild',
  house: 'Haus',
  flat: 'Wohnung',
  transfer_item_success: 'Übertragung erfolgreich',
  transfer_item_failed: 'Übertragung fehlgeschlagen',
  none: 'Keine',
  reconciled: 'Inventur',
  web_ui: 'Web',
  app: 'App',
  select_article_and_quantity_first:
    'Bitte wählen Sie zunächst Artikel und Menge aus',
  min_stock_reached: 'Mindestbestand erreicht!',
  min_stock_reached_description:
    'Dieser Artikel hat den Mindestbestand erreicht, der festgelegt wurde auf ',
  max_stock_reached: 'Höchstbestand überschritten',
  max_stock_reached_description:
    'Dieser Artikel hat den Höchststand überschritten, der festgelegt wurde auf ',
  stocktaking_mode_message: ' Really enter Stocktaking mode.',
  my_inventory: 'Mein Inventar',
  all_my_inventories: 'Mein Inventar',
  transfer: 'Übertragen',
  item: 'Inventar',
  max_min_stock: 'Höchst-/Mindestbestand',
  inventory_detail: 'Inventar Detail',

  // common
  lang: 'de',
  english: 'Englisch',
  german: 'Deutsch',
  polish: 'Polnisch',
  french: 'Französisch',
  italian: 'Italienisch',
  result: 'Ergebnis',
  no_results_found: 'Keine Ergebnisse gefunden',
  no_records_found: 'Schlüssel nicht gefunden',
  no_options: 'Keine Optionen',
  error: 'Fehler',
  showing: 'Zeige',
  manually: 'Manuell',
  general: 'Allgemein',
  gotoDetails: 'Detail-Seite',
  out_of: 'von',
  loading: 'Lade',
  show_rows: 'Anzahl Reihen',
  error_boundary_heading_text: 'Entschuldiung, etwas ist schiefgelaufen',
  error_boundary_paragraph_text:
    'Unser Team wurde benachrichtigt und wir werden den Fehler so schnell wie möglich beheben',
  reload_page: 'Seite neu laden',
  take_me_home: 'Zur Startseite',
  manual: 'Manuell',
  ['v-office']: 'V-Office',
  guest: 'Gast',
  owner: 'Eigentümer',
  lead: 'Lead',
  supplier: 'Lieferant',
  test: 'Prüfen',
  other: 'Andere',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  bi_weekly: 'Zweiwöchentlich',
  monthly: 'Monatlich',
  semi_anually: 'Halbjährlich',
  anually: 'Jährlich',
  previous: 'Zurück',
  next: 'Weiter',
  date_validation_msg: 'Bitte geben Sie ein gültiges Datum an',
  freeze_inventory: 'Inventar einfrieren',
  create_inventory_list: 'Bestandsliste erstellen',
  correct_inventory: 'Korrektes Inventar.',
  create_delta_list: 'Delta-Liste erstellen',
  unfreeze_inventory_end: 'Inventar- und Endbestandsvorrat',
  download_pdf: 'PDF Herunterladen',
  download_excel: 'Excel Herunterladen',
  download__delta_list_pdf: 'DREDA LIST-PDF herunterladen',
  unfreeze_inventory: 'Inventar',
  no_transferring: '(Keine Übertragung von Gegenständen möglich.)',
  download_as:
    '(Laden Sie als PDF herunter und speichern Sie, dann drucken Sie sie an.)',
  transfer_items:
    '(Übertragen Sie die Elemente von oder auf eine Bestandskorrektur.)',
  what_change: '(Welche Änderungen wurden während der Bestellungen getroffen.)',
  step_1: 'Schritt 1',
  step_2: 'Schritt 2',
  step_3: 'Schritt 3',
  step_4: 'Schritt 4',
  step_5: 'Schritt 5',
  actual_quantity: 'Actual Menge.',
  delta: 'delta',
  no_data_found: 'Keine Daten gefunden',
  ms: 'Frau',
  Ms: 'Frau',
  diverse: 'Divers',
  Diverse: 'Divers',

  /*MDM*/

  master_data_magnagement: 'Stammdatenverwaltung',
  mdm: 'Stammdatentool',
  contacts: 'Kontakte',
  contact: 'Kontakt',
  upload_csv: 'CSV Hochladen',
  business: 'Firma',
  language: 'Sprache',
  letter_salutation: 'Brief Anrede',
  letter_closure: 'Brief Abschluss',
  salutation: 'Anrede',
  birthdate: 'Geburtsdatum',
  phone_number: 'Telefonnummer',
  business_phone_number: 'Geschäfts-Telefonnummer',
  mobile_number: 'Mobilnummer',
  fax: 'Fax',
  im: '-im-',
  website: 'Webseite',
  both: 'beide',
  self: 'selbst',
  region: 'Region',
  plant: 'Plant',
  categories: 'Kategorie',
  online: 'Online',
  deactivated: 'Deaktiviert',
  sleeping_facilities: 'Schlafmöglichkeiten',
  longitude: 'Längengrad',
  latitude: 'Breitengrad',
  bedrooms: 'Schlafzimmer',
  bookings: 'Buchungen',
  booking_date: 'Buchungsdatum',
  invoices: 'Rechnungen',
  invoice: 'Rechnungen',
  number: 'Nummer',
  until: 'Bis',
  received_at: 'Erhalten am',
  changed_at: 'Geändert am',
  total: 'Gesamt',
  grand_total: 'Gesamtbetrag',
  marketing_channel: 'Marketing Channel',
  down_payment: 'Anzahlung',
  down_payment_until: 'Anzahlung bis',
  final_payment_until: 'Abschlusszahlung bis',
  ex_price: 'Ex-Preis',
  total_occupancy: 'Gesamtbelegung',
  adult: 'Erwachsene',
  children: 'Kinder',
  babies: 'Babys',
  age_children: 'Alter der Kinder',
  country_customer: 'Land Kunde',
  town_code_customer: 'PLZ Kunde',
  country_objectives: 'Country Objectives',
  rent_statement_exit: 'Mietnachweis existiert',
  invoice_date: 'Rechnungsdatum',
  net: 'Netto',
  due_date: 'Fälligkeitsdatum',
  cancelled_on: 'Storniert am',
  calculation: 'Berechnung',
  provider: 'Anbieter',
  invoice_recepient: 'Rechnungsempfänger',
  warehouse_holds_inventories: 'Das Lager enthält noch Inventar',
  uploads: 'Uploads',
  all_uploads: 'Alle Uploads',
  all_bookings: 'Alle Buchungen',
  all_invoices: 'Alle Rechnungen',
  all_services: 'Alle Services',
  all_logs: 'Alle Logs',
  drag_drop_csv_files_msg: 'Laden Sie hier .csv Dateien hoch (Maximal 10 MB)',
  drag_drop_csv_15MB_files_msg:
    'Laden Sie hier .csv Dateien hoch (Maximal 15 MB)',
  processing_data: 'Daten verarbeiten',
  failed: 'Gescheitert',
  select_calculation: 'Berechnung auswählen',
  select_provider: 'Provider auswählen',
  select_invoice_recepient: 'Invoice Recepient auswählen',
  access_mdm: 'Stammdatentool Zugriff',
  mdm_supporter: 'Stammdatentool Mitarbeiter',
  file_uploaded_successfully: 'Datei erfolgreich hochgeladen',
  uploaded_on: 'Zuletzt aktualisiert am',
  by: 'von',
  delete_article_unit_error:
    'Die Einheit kann nicht gelöscht werden, da sie noch Artikeln zugeordnet ist',
  delete_type_error:
    'Die Art kann nicht gelöscht werden, da sie noch Artikeln zugeordnet ist',
  transferred: 'übertrug',
  internal_server_error: 'Interner Serverfehler',
  cannot_upload_csv_in_worng_category:
    'csv Datei kann nicht in falsche Kategorie hochgeladen werden',
  upload_date: 'Datum des Hochladens',
  crm_employee: 'CRM-Mitarbeiter',
  access_crm: 'CRM Zugriff',
  merge_contacts: 'Kontakte zusammenführen',
  create_owner_crm: 'Neuen Eigentümer anlegen',
  notes_cannot_be_more_than_2000_characters_long:
    'Die Notizen dürfen nicht mehr als 2000 Zeichen lang sein',

  access_oms: 'Zugang zu Objekten und Jahreszeiten',
  manage_oms: 'Verwalten von Objekten und Jahreszeiten',
  access_bms: 'Zugriff Buchungsmanagement',
  access_bread_service: 'Brötchenservice',
  access_sms: 'Zugriff Servicemanagement',
  manage_sms: 'SMS-Verwaltung',
  location: 'Lage',
  letter_completion: 'Briefabschluss',
  homepage: 'Homepage',

  imports: 'Einfuhren',
  all_imports: 'Alle Einfuhren',
  import_type: 'Art der Einfuhr',
  last_id: 'Letzte Id',
  total_records_imported: 'Importierte Datensätze insgesamt',
  imported_at: 'Eingeführt am',
  availabilities: 'Verfügbarkeiten',

  /* OMS */
  object_management_system: 'Apartment Verwaltung',
  oms: 'OMS',
  object_list: 'Objektliste',
  season: 'Saisons',
  season_list: 'Saison Aufführen',
  season_name: 'Saison Name',
  edit_season: 'Saison bearbeiten',
  delete_season: 'Saison löschen',
  add_season: 'Neue Saison anlegen',
  from_date: 'Von',
  to_date: 'Bis',
  status_changed: 'Erfolgreich bearbeitet',
  add_new_apartment: 'Neues Appartment anlegen',
  payement_active: 'Zahlung aktiv',
  website_active: 'Website aktiv',
  video_active: 'Video aktiv',
  seven_for_six: '7 für 6',
  this_discount_will_be_applied_for_season_c:
    'Dieser Rabatt wird nur während der Saison C gewährt.',
  voffice_data_sync: 'V-Office Datensynchronisierung',
  features: 'Features',
  picture: 'Bild',
  text: 'Texte',
  cannot_get_apartment_details: 'Kann nicht die Objekt Details abrufen',
  must_be_greater_than_zero: 'Muss größer als 0 sein',
  basic_information: 'Grundlegende Informationen',
  copy: 'Kopieren',
  ical_link: 'iCal-Link',
  copied: 'Kopiert',
  copy_to_clipboard_ctrl_c_enter:
    'In die Zwischenablage kopieren: Strg+C, Enter',

  /**SMS Service */
  service_management_system: 'Service Management System',
  sms: 'SMS',
  add_service: 'Service erstellen',
  scheme_type: 'Schema Typ',
  select_scheme_type: 'Schema Typ auswählen',
  service_created: 'Service wurde erstellt',
  service_creation_failed: 'Service konnte nicht erstellt werden',
  service_udpate_failed: 'Service konnte nicht bearbeitet werden',
  add_new_service: 'Neuen Service erstellen',
  edit_service: 'Service bearbeiten',
  service_updated: 'Service erfolgreich bearbeitet',
  service_not_found: 'Service nicht gefunden',
  service_details: 'Service Details',
  product_code: 'Produktcode',
  v_office_service: 'VOffice Service',
  price_scheme_type: 'Preistyp',
  by_quantity: 'Nach Menge',
  by_duration: 'Nach Dauer',
  by_day: 'Pro Tag',
  newspaper: 'Zeitung',
  one_time_price: 'Einmaliger Preis',
  beach_chair: 'Strandkorb',
  unit_price: 'Einheit Preis',
  price_a: 'Preis A',
  price_b: 'Preis B',
  view_service: 'Service Details',
  delete_service: 'Service löschen',
  service_deleted: 'Service wurde gelöscht',
  day: 'Tag',
  price: 'Preis',
  duration: 'Dauer',
  priceschemebyday: 'Pro Tag',
  priceschemeonetime: 'Pauschal',
  priceschemebyquantity: 'Nach Menge',
  priceschemebyduration: 'Nach Dauer',
  priceschemebeachchair: 'Strandkorb',
  sun: 'So.',
  mon_fri: 'Mo.-Fr.',
  additional_day: 'Zusätzlicher Tag',
  annual_price: 'Jahrespreis',
  duplicate_price_scheme: 'Duplicate Price Scheme',

  /**Service Group */
  service_group: 'Service Gruppe',
  service_groups: 'Service Gruppen',
  service_management: 'Service Verwaltung',
  select_service_group: 'Service Gruppe auswählen',
  all_service_groups: 'Alle Servicegruppen',
  service_group_list: 'Service Gruppe Liste',
  add_service_group: 'Service Gruppe erstellen',
  booking_type: 'Buchungstyp',
  booking_fee_calculation: 'Buchungsgebühr Berechnung',
  booking_fee: 'Buchungsgebühr',
  service_group_created: 'Service Gruppe wurde erstellt',
  service_group_creation_failed: 'Service Gruppe konnte nicht erstellt werden',
  service_group_udpate_failed: 'Service Gruppe konnte nicht bearbeitet werden',
  add_new_service_group: 'Service Gruppe erstellen',
  service_name: 'Service Name',
  frontend_color: 'Frontend Farbe',
  price_per_night: 'Preis pro Nacht',
  show_on_landing_page: 'Auf Startseite anzeigen?',
  default_service: 'In Buchungsstrecke anzeigen?',
  early_deliverable: 'Frühzeitige Lieferung',
  early_deliverable_on_start: 'Frühzeitige Lieferung bei Beginn',
  pay_to_vendor: 'An den Verkäufer zahlen',
  dog_service: 'Hunde Service',
  icon: 'Icon',
  cover_image: 'Titelbild',
  drag_icon_or_click_to_select_icon: 'Hier klicken, um Icon hochzulagen',
  drag_image_or_click_to_select_image: 'Hier klicken, um Titelbild hochzuladen',
  edit_service_group: 'Service Gruppe bearbeiten',
  service_group_updated: 'Servie Gruppe erfolgreich bearbeitet',
  service_group_not_found: 'Service Gruppe nicht gefunden',
  service_group_details: 'Service Gruppe Details',
  one_time: 'Pauschal',
  per_day: 'Pro Tag',
  per_night: 'Pro Nacht',
  per_week: 'Pro Woche',
  view_service_group: 'Service Gruppe Details',
  delete_service_group: 'Service Gruppe löschen',
  service_group_deleted: 'Service Gruppe wurde gelöscht',
  accept_only_png: 'Bitte .png Datei verwenden',
  enter_booking_fee_in_cents: 'Buchungsgebühr in Cents eingeben',
  enter_price_per_night_in_cents: 'Preis in Cent eingeben',
  enter_value_in_cents: 'Wert in Cents eingeben',
  enter_value_in_euro: 'Wert in Euro eingeben',
  light: 'Hell',
  dark: 'Dunkel',
  price_calculation: 'Preiskalkulation',

  /**DMS Service */
  document_management_system: 'Dokumenten Management System',
  dms: 'DMS',
  general_documents: 'Allgemeine Dokumente',
  document_objects: 'Appartment Dokumente',
  document_customers: 'Kunden Dokumente',
  document_owners: 'Dokumenteneigentümer',
  document_users: 'Nutzer Dokumente',
  upload_document: 'Dokument hochladen',
  all_documents: 'Allgemeine Dokumente',
  all_customers: 'Alle Kunden Dokumente',
  all_owners: 'Alle Eigentümer Dokumente',
  all_object_documents: 'Alle Appartment Dokumente',
  all_user_documents: 'Alle Nutzer-Dokumente',
  document_name: 'Dokument Name',
  owner_name: 'Eigentümer',
  availability: 'Verfügbarkeit',
  edit_general_document: 'Dokument bearbeiten',
  edit_document_object: 'Objekt Dokument bearbeiten',
  edit_document_owner: 'Eigentümer Dokument bearbeiten',
  edit_document_customer: 'Kunden Dokument bearbeiten',
  upload_general_document: 'Allgemeines Dokument hochladen',
  upload_document_object: 'Objekt Dokument hochladen',
  upload_document_owner: 'Eigentümer Dokument hochladen',
  upload_document_customer: 'Dokument für Kunden hochladen',
  to_objects: 'Objekt',
  no_of_documents: 'Anzahl. der Dokumente',
  select_object: 'Objekt auswählen',
  general_document_deleted: 'Dokument wurde gelöscht',
  delete_general_document: 'Dokument löschen',
  general_document_not_found: 'Dokument nicht gefunden',
  general_document_udpate_failed: 'Dokument bearbeiten fehlgeschlagen',
  general_document_created: 'Dokument wurde erstellt',
  general_document_creation_failed: 'Dokument konnte nicht erstellt werden',
  general_document_updated: 'Document updated successfully',
  customer_documents: 'Kunden Dokumente',
  owner_documents: 'Eigentümer Dokumente',
  object_documents: 'Appartment Dokumente',
  users_document: 'Nutzer Dokumente',
  upload_user_document: 'Nutzer Dokument hochladen',
  edit_user_document: 'Nutzer Dokument bearbeiten',
  import_file: 'Datei importieren',
  the_file_field_is_required: 'Das Dateifeld ist erforderlich',

  /**BMS Service */
  booking_management_system: 'Buchung Management System',
  booking_management: 'Buchungs Verwaltung',
  booking_overview: 'Buchung Übersicht',
  booking_status: 'Buchung Status',
  payment_status: 'Zahlung Status',
  distribution_channel: 'Vertriebskanal',
  create_new_booking: 'Neue Buchung anlegen',
  apartment_name: 'Appartement Name',
  total_amount: 'Gesamtbetrag (CHF)',
  amount_open: 'Betrag Offen (CHF)',
  bms: 'BMS',
  customer_information: 'Kunden Informationen',
  booking_creation_failed: 'Buchungserstellung fehlgeschlagen',
  salutation_is_required: 'Anrede ist erforderlich',
  select_salutation: 'Anrede auswählen',
  street_name_is_required: 'Straße ist erforderlich',
  city_is_required: 'Ort ist erforderlich',
  select_country: 'Land auswählen',
  country_is_required: 'Land ist erforderlich',
  invalid_phone_number: 'Ungültige Rufnummer',
  appartment_information: 'Appartment Informationen',
  appartment_is_required: 'Appartment ist erforderlich',
  date_is_required: 'Datum ist erforderlich',
  select_quantity: 'Anzahl auswählen',
  baby: 'Baby',
  select_distribution_channel: 'Vertriebskanal auswählen',
  arrival_status: 'Anreise Status',
  select_arrival_status: 'Anreise Status auswählen',
  arrival_status_is_required: 'Anreise Status ist erforderlich',
  reserved_at: 'Reserviert Am',
  reserved_until: 'Reserviert Bis',
  total_amount_is_required: 'Gesamtbetrag ist erforderlich',
  paid_amount_is_required: 'Bezahlter Betrag ist erforderlich',
  residual_amount: 'Restbetrag',
  deposit_amount: 'Kautionsbetrag',
  residual_amount_is_required: 'Restbetrag ist erforderlich',
  deposit_amount_is_required: 'Kautionsbetrag ist erforderlich',
  notes_notizen: 'Notizen',
  further_remarks_notes: 'Weitere Bemerkungen / Notizen',
  house_number_is_required: 'Hausnummer ist erforderlich',
  town_is_required: 'Ort ist erforderlich',
  post_code_is_required: 'Postleitzahl ist erforderlich',
  phone_number_is_required: 'Telefonnummer ist erforderlich',
  add_booking: 'Buchung hinzufügen',
  mandatory_fields: 'Obligatorische Felder',
  above_18_years_old: 'Über 18 Jahre alt',
  under_18_years_old: 'Unter 18 Jahre alt',
  under_3_years_old: 'Unter 3 Jahre alt',
  booking_created: 'Die Buchung wurde erstellt',
  subtotal: 'Zwischensumme',
  booking_calendar: 'Buchungskalender',
  could_not_fetch_bookings: 'Buchungen konnten nicht abgerufen werden',
  booking_date_conflict_error:
    'Buchungsdatum überschneidet sich mit einer anderen Buchung',
  booking_cannot_be_in_the_past:
    'Die Buchung kann nicht in der Vergangenheit liegen',
  appartment_overview: 'Appartment Übersicht',
  reserved: 'Reserviert',
  vat19: '19% MwSt. auf den Lieferservice',
  vat7: '7% MwST',
  payment_method: 'Zahlungsmethode',
  bank_transfer: 'Überweisung',
  paid_amount: 'Bezahlter Betrag',
  without_tax: 'Ohne MwSt.',
  including: 'Inklusive',
  callback: 'Rückruf',
  callbacks: 'Rückrufe',
  all_callbacks: 'Alle Rückrufe',
  callback_date: 'Rückrufdatum',
  callback_detail: 'Rückrufdetails',
  callback_deleted_successfully: 'Rückruf wurde erfolgreich gelöscht',
  no_callback_details_found: 'Keine Rückrufdetails gefunden',
  inquiry: 'Anfrage',
  mr: 'Herr',
  Mr: 'Herr',
  mrs: 'Frau',
  misc: 'Divers',
  piece: 'Stück',
  please_verify_your_service_bookings:
    'Bitte überprüfen Sie Ihre Servicebuchungen',
  booking_preview: 'Buchungsvorschau',
  selected_dates_not_available: 'Ausgewählte Termine sind nicht verfügbar',
  not_paid: 'Nicht Bezahlt',
  partial_paid: 'Anzahlung Erfolgt',
  fully_paid: 'Komplett Bezahlt',
  cannot_calculate_booking_price_for_the_given_date_range:
    'Kann den Buchungspreis für den angegebenen Datumsbereich nicht berechnen',
  cannot_make_bookings_in_the_given_date_range:
    'Buchungen im angegebenen Datumsbereich sind nicht möglich',
  booked_from: 'Gebucht von',
  booked_until: 'Gebucht bis',
  bank_connection: 'Bank Verbindung',
  institute: 'Institut',
  sales_tax_id_no: 'Umsatzsteuer-IdNr.',
  create_user: 'Benutzer erstellen',
  user_already_exists: 'Benutzer ist bereits vorhanden',
  create: 'Erstellen',
  this_will_create_a_user_for_the_owner_are_you_sure_you_want_to_continue:
    'Dadurch wird ein Benutzer für den Eigentümer erstellt. Sind Sie sicher, dass Sie fortfahren möchten?',

  /**BS Service */
  bread_system: 'Brötchenbuchungen',
  bs: 'BS',
  all_billings: 'Alle Abrechnungen',
  all_daily_view: 'Alle Tagesansichten',
  all_driving_order: 'Fahrstrecke',

  /**PMS Service */
  payment_management_system: 'Zahlungs-Verwaltungs-System',
  pms: 'PMS',
  credit_card: 'Kreditkarten-Zahlungen',
  all_credit_cards: 'Kreditkarten-Zahlungen',
  paypal: 'PayPal-Zahlungen',
  all_paypals: 'PayPal-Zahlungen',
  credit_card_no: 'Kreditkartennummer',
  amount: 'Betrag',
  paypal_address: 'PayPal Adresse',
  send: 'Senden',
  access_pms: 'ZVS Zugriff',
  payment_links: 'Zahlungslinks',
  send_on: 'Weiterleitung',
  last_sent_on: 'Zuletzt gesendet am',
  paid_on: 'Bezahlt am',
  all_payment_links: 'Alle Zahlungslinks',
  create_payment_links: 'Zahlungslink erstellen',
  send_payment_link: 'Zahlungslink senden',
  payment_link_sending_failed: 'Zahlungslink konnte nicht gesendet werden.',
  payment_link_send_successfully: 'Zahlungslink erfolgreich gesendet.',
  identifier: 'Kennung',
  amount_is_required: 'Betrag ist erforderlich',
  required_booking_number: 'Buchungsnummer ist erforderlich.',
  required_customer_name: 'Kundenname ist erforderlich.',
  paid: 'Bezahlt',
  resent_mail_success: 'E-Mail erneut gesendet erfolgreich',
  resent_mail_error: 'E-Mail erneut gesendet fehlgeschlagen',
  confirm_resend_email:
    'Sind Sie sicher, dass Sie die E-Mail erneut senden möchten?',
  service_reservation: 'Service-Buchung',
  apartment_reservation: 'Objekt-Buchung',
  ferry_booking: 'Fährbuchung',
  payment_link: 'Zahlungs-Link',
  /**PNS Service */
  push_notification_system: 'Push-Benachrichtigungen',
  all_notifications: 'Alle Benachrichtigungen',
  pns: 'PNS',
  notifications: 'Benachrichtigungen',
  push_notification: 'Push-Benachrichtigung',
  add_new_push_notification: 'Neue Push-Benachrichtigung',
  link: 'Link',
  total_read: 'Gesamt/Gelesen',
  add_push_notification: 'Push-Benachrichtigung hinzufügen',
  notification_created: 'Push-Benachrichtigung gesendet',
  notification_creation_failed:
    'Push-Benachrichtigung konnte nicht gesendet werden',
  access_pns: 'Push Zugriff',
  manage_pns: 'Push-Benachrichtigungen senden',
  image_link: 'Bild-Link',
  pns_notification_confirm:
    'Achtung: Benachrichtigung wird gesendet, Fortfahren?',

  /**FAQ Service */
  publish_in: 'Veröffentlichen in',
  frequently_ask_question: 'Häufig gestellte Fragen',
  faq: 'FAQs',
  all_faq: 'Alle FAQs',
  internal_faq: 'Interne FAQs',
  all_internals: 'Alle Interne FAQs',
  success: 'Erfolg',
  group: 'Gruppe',
  all_groups: 'Alle Gruppen',
  add_faq: 'FAQ Hinzufügen',
  edit_faq: 'FAQ Bearbeiten',
  delete_faq: 'FAQ Löschen',
  select_group: 'Gruppe auswählen',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Internes_Wissensdokument',
  dateien_hochladen: 'Dateien_Hochladen',
  pdf_upload: 'PDF Upload',
  faq_deleted_successfully: 'FAQ wurde erfolgreich gelöscht',

  faq_group: 'FAQ Gruppe',
  add_group: 'Gruppe Hinzufügen',
  edit_group: 'Gruppe Bearbeiten',
  delete_group: 'Gruppe Löschen',
  faq_group_deleted_successfully: 'FAQ Gruppe wurde erfolgreich gelöscht',

  reservations: 'Reservierungen',
  all_reservations: 'Alle Reservierungen',
  all_requests: 'Alle Anfragen',
  reservation_requests: 'Reservierungsanfrage',
  ferry_bookings: 'Fährbuchungen',
  bread_bookings: 'Brötchen Buchungen',
  bread_daily_view: 'Brötchen Tagesansicht',
  bread_driving_order: 'Brötchen Fahrstrecke',
  save_inorder_to_apply_changes: 'Änderungen speichern, um sie zu übernehmen',

  /** Bread Boking */
  bread_booking: 'Brötchen Buchungen',
  add_bread_booking: 'Neue Brötchenbestellung',
  update_bread_booking: 'Brötchenbuchung aktualisieren',
  pending: 'Ausstehend',
  confirmed: 'Bestätigt',
  cancelled: 'Storniert',
  booking_number: 'Buchungsnummer',
  add_new_bread_booking: 'Speichern',
  edit_bread_booking: 'Brötchenbestellung bearbeiten',
  bread_booking_updated: 'Brötchenbestellung erfolgreich geändert',
  cannot_change_quantity_in_past:
    'Bestellmengen in der Vergangenheit können nicht geändert werden',
  multiple_bookings_in_same_date_not_allowed:
    'Mehrere Buchungen für den gleichen Zeitraum sind nicht möglich',
  bread_booking_not_found: 'Brötchenbestellung nicht gefunden',
  bread_booking_details: 'Detail',
  bread_booking_deleted: 'Brötchenbestellung wurde gelöscht',
  view_bread_booking: 'Brötchenbestellung Detail',
  delete_bread_booking: 'Brötchenbestellung löschen',
  edit_order: 'Bestellung bearbeiten',
  cancel_order: 'Bestellung stornieren',
  property_name: 'Objektname',
  customer_contact: 'Kunden Telefonnummer',
  booking_period: 'Buchungszeitraum',
  timespan: 'Buchungszeitraum',
  bread_booking_created: 'Brötchenbestellung wurde erstellt',
  bread_booking_creation_failed:
    'Brötchenbestellung konnte nicht erstellt werden',
  bread_booking_udpate_failed:
    'Brötchenbestellung konnte nicht bearbeitet werden',
  include_departure_day: 'Lieferung am Abreisetag',
  price_pound: 'Preis (€)',
  booking_fee_pound: 'Buchungsgebühr (€)',
  total_pound: 'Gesamt (€)',
  must_be_date_after_today_or_equal_to_today:
    'muss ein Datum nach dem today oder gleich dem today sein.',
  invalid_data: 'Die angegebenen Daten sind ungültig',
  booking_invalid: 'Die Buchungsnummer ist nicht korrekt',
  service_invalid: 'Leistung ist nicht korrekt',
  customer_invalid: 'Kundenname ist richtig',

  /**Driving Order */
  driving_order: 'Fahrstrecke',
  import_driving_order: 'Fahrstrecke',
  import_driving_success: 'Fahrstrecke erfolgreich importiert',
  import_driving_failed: 'Fahrstrecke konnte nicht importiert werden',

  /** Bread Daily View */
  property: 'Objekt',
  guest_number: 'Gastnummer',
  brotchen: 'Brötchen',
  bread_daily_view_details: 'Brötchenbestellung Details',
  view_bread_daily_view: 'Brötchen Tagesansicht',
  download: 'Herunterladen',
  download_image: 'Download Image',
  download_driving_order: 'Fahrstrecke herunterladen',

  /** Bread Daily View */
  bread_booking_billing: 'Brötchen Abrechnung',
  bread_booking_billing_detils: 'Brotbuchung Abrechnungsdetails',
  bread_booking_billing_edit: 'Brot buchen Abrechnung bearbeiten',
  bread_type: 'Brot Art',
  old_price: 'Alter Preis',
  new_price: 'Neuer Preis',
  price_difference: 'Preisunterschied',
  total_bread: 'Gesamt Brot',
  total_fee: 'Gesamtgebühr',
  total_price_difference: 'Gesamtpreisdifferenz',
  total_price: 'Gesamtpreis',
  bread_quantity_difference: 'Gesamtdifferenz bei Brot',
  total_fee_difference: 'Gebührenunterschied insgesamt',
  grand_total_difference: 'Gesamtdifferenz',
  update_bill: 'Rechnung aktualisieren',
  close_bill: 'Rechnung abschließen',
  this_bill_will_be_closed_permanently:
    'Diese Rechnung wird dauerhaft geschlossen. Sobald sie geschlossen ist, können Sie keine Änderungen mehr daran vornehmen.',

  /** Reservation Request */
  reservation_request_details: 'Reservierungsanfrage Detail',
  booking_detail: 'Buchung Detail',
  reservation_detail: 'Reservierung Detail',
  adults: 'Erwachsene',
  customer_detail: 'Kunden Detail',
  sur_name: 'Nachname',
  reservation_details: 'Reservierungsdetails',
  service_line: 'Services',
  view_reservation_request_view: 'Reservierungsanfrage Detailansicht',
  dog: 'Hund',
  dog_breed: 'Hunderasse',

  /** Ferry Booking */
  view_ferry_booking_view: 'Fährbuchung Detailansicht',
  ferry_booking_details: 'Fährbuchung Detail',
  booking_amount: 'Buchungsbetrag',
  trip_date: 'Datum der Reise',
  time: 'Uhrzeit',
  trip_voyage: 'Reise-Reise',
  departure_harbor: 'Abfahrtshafen',
  destination_harbor: 'Zielhafen',
  trip_return_date: 'Rückreisedatum',
  vehicle_type: 'Fahrzeugtyp',
  licence_plate: 'Kennzeichen',
  length: 'Länge',
  customer_email: 'Kunden-E-Mail',
  trip_detail: 'Reisedetail',
  vehicle_detail: 'Fahrzeugtyp',
  cm: 'cm',
  ferry_booking_cancel_success: 'Fährbuchung wurde storniert.',
  ferry_booking_cancel_failed: 'Fährbuchung storniert fehlgeschlagen.',

  data_synced: 'Daten synchronisiert',
  last_v_office_data_sync: 'Letzte Synchronisierung mit VOffice',
  access_reservations: 'Reservierungen',
  access_bookings: 'Zugang zu Buchungen und Kalender',
  manage_bookings: 'Buchungen, Rechnungen und Check-in verwalten',
  access_invoices: 'Zugang Rechnungen',
  manage_bread_service: 'Brötchenservice bearbeiten',
  access_bread_driving_order: 'Brötchenservice Fahrstrecke',
  access_callbacks: 'Rückruf-Liste',

  access_faq: 'FAQ Zugriff',
  manage_faq: 'FAQ bearbeiten',
  access_faq_internal: 'Internes FAQ Zugriff',
  access_faq_group: 'FAQ-Gruppen Zugriff',
  manage_faq_group: 'FAQ-Gruppen bearbeiten',

  access_dms_general: 'Allgemeine Dokumente Zugriff',
  manage_dms_general: 'Allgemeine Dokumente bearbeiten',
  access_dms_object: 'Objekt-Dokumente Zugriff',
  manage_dms_object: 'Objekt-Dokumente bearbeiten',
  access_dms_customer: 'Kunden-Dokumente Zugriff',
  manage_dms_customer: 'Kunden-Dokumente bearbeiten',
  access_dms_owner: 'Eigentümer-Dokumente Zugriff',
  manage_dms_owner: 'Eigentümer-Dokumente bearbeiten',
  access_dms_user: 'Zugang zum Benutzerdokument',
  manage_dms_user: 'Benutzerdokument verwalten',
  select_date: 'Datum auswählen',
  select_date_time: 'Datum/Uhrzeit auswählen',

  subscribe: 'Abonniert',
  unsubscribe: 'Nicht abonniert',
  /*Internet Status*/
  wifi: 'Ihre Internetverbindung wurde wiederhergestellt.',
  nowifi: 'Sie sind derzeit offline.',
  refresh: 'Aktualisieren',

  /*Contact Center*/
  access_contact_center: 'Contact Center Zugriff',

  /*Newsletter*/
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Newsletters',
  newsletter_overview: 'Newsletter Übersicht',
  draft: 'Entwurf',
  drafts: 'Entwürfe',
  group_of_recipients: 'Gruppe von Empfängern',
  all_newsletters: 'Alle Newsletters',
  new_newsletter: 'Neuer Newsletter',
  create_new_newsletter: 'Neuer Newsletter',
  recipients: 'Empfänger',
  subject: 'Betreff',
  all_draft: 'Alle Entwürfe',
  message: 'Nachricht',
  attach_file: 'Datei anhängen',
  attachments: 'Anhänge:',
  preview: 'Vorschau',
  save_as_draft: 'Entwurf speichern',
  compose_message: 'Nachricht verfassen',
  preview_message: 'Newsletter Vorschau',
  download_our_app_now: 'Laden Sie unsere App jetzt herunter',
  contact_with_us: 'Kontakt mit uns',
  about_us: 'Über uns',
  customer_care: 'Kundenbetreuung',
  contact_us: 'Kontaktieren Sie uns',
  privacy_policy: 'Datenschutzbestimmungen',
  select_recipient_group: 'Empfänger auswählen',
  edit_newsletter: 'Newsletter bearbeiten',
  delete_newsletter: 'Newsletter löschen',
  newsletter_deleted: 'Newsletter gelöscht.',
  newsletter_delete_error: 'Newsletter-Löschung fehlgeschlagen.',
  newsletter_sent_sucessfully: 'Newsletter erfolgreich gesendet.',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter erfolgreich als Entwurf gespeichert.',
  newsletter_updated_sucessfully: 'Newsletter erfolgreich aktualisiert.',
  hello: 'Hallo',
  to_send: 'Zum Senden',
  save_and_send: 'Speichern und Senden',
  recipient_group_field_is_required:
    'Das Feld Empfängergruppe ist erforderlich.',
  subject_field_is_required: 'Betrefffeld ist erforderlich.',
  message_field_is_required: 'Nachrichtenfeld ist erforderlich.',
  send_newsletter: 'Newsletter versenden',
  attention_notification_will_be_send_continue:
    'Achtung: Newsletter wird gesendet, Fortfahren',
  privacy: 'Datenschutz',
  imprint: 'Impressum',
  text_search: 'Textsuche',

  /*required*/
  /*required*/
  required_first_name: 'Vorname ist erforderlich',
  required_last_name: 'Nachname ist erforderlich',
  required_email: 'E-Mail ist erforderlich',
  required_cellphone: 'Mobiltelefon ist erforderlich',
  required_role: 'Rolle ist erforderlich',
  required_address: 'Adresse ist erforderlich',
  required_repate_from: 'Wiederholung von ist erforderlich',
  required_repeate_until: 'Wiederholung der Einheit ist erforderlich',
  required_interval: 'Rekursives Intervall ist erforderlich',
  required_label: 'Bezeichnung ist erforderlich',
  required_type: 'Typ ist erforderlich',
  required_article: 'Artikel ist erforderlich',
  required_destination: 'Ziel ist erforderlich',
  required_source: 'Quelle ist erforderlich',
  required_quantity: 'Menge ist erforderlich',
  required_article_name: 'Artikelname ist erforderlich',
  required_unit: 'Einheit ist erforderlich',
  required_type_name: 'Typname ist erforderlich',
  required_unit_name: 'Einheitsname ist erforderlich',
  required_descriptions: 'Beschreibungen sind erforderlich',
  required_document_name: 'Dokumentname ist erforderlich',
  required_Object: 'Objekt ist erforderlich',
  required_upload: 'Datei hochladen ist erforderlich',
  required_service_name: 'Dienstname ist erforderlich',
  required_voffice_service: 'VOffice Dienst ist erforderlich',
  required_service_group: 'Dienstgruppe ist erforderlich',
  required_cover_image: 'Titelbild ist erforderlich',
  required_booking_type: 'Buchungsgebührentyp ist erforderlich.',
  required_booking_fee: 'Buchungsgebühr ist erforderlich.',
  required_price_per_night: 'Preis pro Nacht ist erforderlich.',
  required_icon: 'Upload-Symbol ist erforderlich',
  required_group_name: 'Gruppenname ist erforderlich',
  required_status: 'Status ist erforderlich',
  required_group_recipients: 'Gruppe von Empfängern ist erforderlich',
  required_subject: 'Betreff ist erforderlich',
  required_message: 'Nachricht ist erforderlich',
  required_room: 'Raum ist erforderlich.',
  required_rack: 'Regal ist erforderlich.',
  required_shelf: 'Regal ist erforderlich.',
  required_warehouse_name: 'Der Name des Lagers ist erforderlich.',
  file_size_is_too_large: 'Die Dateigröße ist zu groß.',
  required_customer: 'Kunde ist erforderlich',
  required_owner: 'Der Eigentümer ist erforderlich.',
  required_user: 'Benutzer ist erforderlich.',
  required_front_color: 'Frontend-Farbe ist erforderlich.',
  only_one_file_is_allowed: 'Nur 1 Datei ist erlaubt',
  please_fill_in_all_mandatory_fields:
    'Alle Pflichtfelder müssen ausgefüllt werden.',
  required_password: 'Passwort ist erfoderlich',
  required_distribution_channel: 'Ein Vertriebskanal ist erforderlich',
  required_sleeping_facilities: 'Schlafmöglichkeiten ist erforderlich',
  required_pets_allowed: 'Feld Haustiere erlaubt ist erforderlich',

  // *Message*/
  email_taken: 'Die E-Mail wurde bereits angenommen.',
  access_newsletter: 'Newsletter und Entwürfe Zugriff',
  manage_newsletter: 'Newsletter managen',

  /*Key*/
  key: 'Schlüssel',
  keyscan: 'Schlüsselscan',
  access_keyscan_keylist: 'Zugriff Schlüsselverwaltung',
  access_keyscan_keylog: 'Schlüsselausgabe Zugriff',
  key_management_system: 'Schlüsselverwaltungssystem',
  key_list: 'Schlüsselliste',
  key_logs: 'Schlüsselprotokolle',
  key_management: 'Schlüsselverwaltung',
  all_key_logs: 'Alle Schlüsselprotokolle',
  search_customer: 'Kunde/Benutzer suchen',
  key_id: 'Schlüssel-Id',
  scan_key: 'Schlüssel scannen',
  key_handling: 'Schlüssel-Behandlung',
  key_information: 'Schlüsselinformationen',
  scan_key_again: 'Schlüssel erneut scannen',
  key_current_status: 'Schlüssel Status',
  new_status: 'Neuer Status',
  rfid_message: 'RFID-Scan erfolgreich',
  rescan_key: 'Schlüssel erneut scannen',
  key_new_status: 'Neuer Schlüssel Status',
  place_rfid: 'Schlüssel auf RFID-Lesegerät legen. ',
  all_key_list: 'Alle Schlüssel-Liste',
  add_key: 'Schlüssel hinzufügen',
  add_new_key: 'Neuen Schlüssel hinzufügen',
  registered_date: 'Registriertes Datum',
  registered_by: 'Registriert von',
  edit_key: 'Schlüssel bearbeiten',
  delete_key: 'Schlüssel löschen',
  view_key: 'Ansichtsschlüssel',
  given_out: 'Ausgegeben',
  in_stock: 'Auf Lager',
  lost: 'Verloren',
  key_history: 'Schlüsselverlauf',
  key_created_successfully: 'Schlüssel erfolgreich erstellt',
  key_created_error: 'Fehler bei der Schlüsselerstellung',
  key_updated_successfully: 'Schlüssel Erfolgreich aktualisiert',
  key_updated_error: 'Fehler bei der Schlüsselaktualisierung',
  key_deleted_successfully: 'Erfolgreich gelöschte Taste',
  key_deleted_error: 'Schlüssel-Löschfehler',
  rescan: 'Rescan',
  rfid_taken_error: 'RFID-Schlüssel bereits vergeben',
  scanner_title_message: 'Bitte den Schlüssel auf den RFID-Scanner legen.',
  scanner_message: 'Schlüssel richtig aufgelegt?',
  key_no_registered: 'Der Schlüssel ist noch nicht registriert worden.',

  /*Aerial Map */
  aerial_map: '360° Karte',
  three_sixty_degree: '360°',
  standard: 'Standard',
  access_aerial_map: '360° Karte Zugriff',
  map: 'Karte',

  /* Appartment Address Form*/
  address_location: 'Adresse & Lage',
  address_suffix: 'Adresszusatz',
  distance_lake: 'Entfernung See',
  distance_shopping: 'Entfernung Einkaufsmöglichkeiten',
  distance_restaurant: 'Entfernung Restaurant',
  distance_city: 'Entfernung Stadt',
  distance_train_station: 'Entfernung Bahnhof',
  distance_bank_atm: 'Entfernung Bank / Geldautomat',
  distance_doctor: 'Entfernung Arzt',
  landscape_view: 'Landschaftsblick',
  garden_view: 'Gartenblick',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'Die Google Maps Karte / Wegbeschreibung erleichtert den Gästen die Navigation zu Ihrer Wohnung und bietet maximale Transparenz',
  type_is_required: 'Typ ist erforderlich',

  /*Premises Features Form */
  sleeping_arrangements: 'Schlafmöglichkeiten',
  number_of_rooms: 'Anzahl Zimmer',
  number_of_bedrooms: 'Anzahl Schlafzimmer',
  number_of_bathrooms: 'Anzahl Schlafzimmer',
  guest_toilet: 'Gäste WC',
  size_in_square_meters: 'Größe in Quadratmeter',
  number_of_parking_spaces: 'Anzahl Parkplätze',
  additional_information: 'Zusätzliche Information',
  kitchen: 'Küche',
  terrace: 'Terrasse',
  balcony: 'Balkon',
  non_smoker: 'Nicht-Raucher',
  pets_allowed: 'Haustiere erlaubt',
  children_allowed: 'Kinder erlaubt',
  diabled_access: 'Behindertengerecht',
  wheelchair_accessible: 'Rollstuhlgerecht',
  parking_available: 'Parkplatz vorhanden',
  washing_machine: 'Waschmaschine',
  dryer: 'Trockner',
  fireplace_stove: 'Kamin / Ofen',
  drying_rack: 'Wäscheständer',
  bedding: 'Bettwäsche',
  e_car_charging_station: 'E-Auto Ladestation',
  air_conditioning: 'Klimaanlage',

  /* Categories Form */
  Wellness: 'Wellness',
  luxury: 'luxus',
  family: 'Familie',
  secluded: 'Abgelegen',
  resort: 'Ferienanlage',
  romantic: 'Romantisch',
  athletic: 'Sportlich',
  tourist_attraction: 'Touristenattraktion',

  /* Equiment Kitchen Form */
  microwave: 'Mikrowelle',
  stove: 'Herd',
  oven: 'Backofen',
  refrigerator: 'Kühlschrank',
  dishwasher: 'Spülmaschiine',
  coffee_machine: 'Kaffeemaschine',
  kettle: 'Wasserkocher',
  toaster: 'Toaster',
  dishes_cutlery: 'Geschirr / Besteck',
  waste_bin: 'Abfalleimer',

  /* Amenities Bathroom Form */
  no_of_showers: ' No Of Showers',
  no_of_toilets: 'No of Toilets',
  shower: 'Shower',
  wc: 'wc',
  bathtub: 'Bathtub',
  bidet: 'bidet',
  hair_dryer: ' Hair Dryer',
  mirror: 'Mirror',
  bathroom_sink: 'Bathroom Sink',

  /* Amenities Children Form */
  stair_gate: 'Stair Gate',
  changing_pad: 'Changing Pad',
  socket_fuse: 'Socket Fuse',
  child_toilet_seat: ' Child Toilet Seat',

  /*Equipment Outside Form */
  type_of_grill: 'Art des Grills',
  grill: 'Grill',
  parasol: 'Sonnenschirm',
  pond: 'Teich',
  swing: 'Schaukel',
  sandbox: 'Sandkasten',
  slide: 'Rutsche',
  fireplace: 'Feuerstelle',
  garden: 'Garten',
  garden_house: 'Gartenhaus',
  hammock: 'Hängematte',

  /* Leisure Activities Entertainment Form */
  free_time_activities: 'Freizeitaktivitäten ',
  sauna: 'Sauna',
  jacuzzi: 'Whirlpool',
  solarium: 'solarium',
  gym: 'Fitnessraum',
  pool: 'Pool',
  entertainment: 'Unterhaltung',
  tv: 'TV',
  hifi: 'Hifi Anlage',
  radio: 'Radio',
  wlan: 'WLAN',
  internat_speed: 'Internet Geschwindigkeit',

  /**text form */
  headline: 'Überschrift',
  full_description: 'Vollständige Beschreibung',
  short_description: 'Kurzbeschreibung',
  directions: 'Wegbeschreibung',

  /**Settings Form */
  season_and_prices: 'Saisons & Preise',
  payment_active: 'Zahlung aktiv?',
  site_active: 'Website aktiv?',
  vimeo_video_link: 'Vimeo video link (optional)',
  please_assign_price:
    'Bitte weisen Sie die verfügbaren Saisons dem Appartment zu und vergeben Sie einen Preis.',
  adjust_order_of_images:
    'Die Anordnung / Reihenfolge der Bilder kann ganz einfach per Drag & Drop angepasst werden.',
  assigned: 'Zugewiesen',

  appartment_name: 'Wohnungsname ',
  upload: 'Hochladen',
  upload_document_to_apartment: 'Dokument zu Appartment hochladen ',
  select_appartment: 'Appartment auswählen',
  delete_image: 'Bild löschen',
  add_new_payment: 'Neue Zahlung hinzufügen',
  payment_made: 'Getätigte Zahlungen',
  date_of_receipt_of_payment: 'Datum Zahlungseingang',
  delete_payment: 'Zahlung löschen',
  service_information: 'Service Informationen',
  prepaid: 'Angezahlt (CHF)',
  balance: 'Restbetrag  (CHF)',
  edit_booking: 'Buchungsdetails anpassen',
  add_more_services: 'Weitere Services hinzufügen',

  checkin_now: 'Check-In vornehmen',
  checkout_now: 'Check-Out vornehmen',
  add_service_for_booking: 'Services zu Buchung {0} hinzufügen',
  confirm_checkin: 'Möchten Sie den  Gast {0} jetzt einchecken?',
  confirm_checkout: 'Möchten Sie den  Gast {0} jetzt auschecken?',
  terminal_system: 'Terminal System',
  terminal: 'Terminal',
  check_in_check_out: 'Check-In / Check-Out',
  check_in: 'Check-In',
  check_out: 'Check-Out',
  guest_check_in: 'Gast Check-In',
  guest_check_out: 'Gast Check-Out',
  checkin_modal_body_first_message: 'Möchten Sie den Gast',
  checkout_modal_body_first_message: 'Möchten Sie Check-Out für den Gast',
  checkout_modal_body_second_message: 'jetzt durchführen',
  checkin_modal_body_last_message: 'jetzt einchecken',
  please_make_sure_the_guest_has_read_the_booking_information:
    'Bitte vergewissern sie sich, dass der Gast die Buchungsinformationen zur Kentniss genommen hat.',
  check_booking_details: 'Buchungsdetails prüfen',
  people: 'Personen',
  period: 'Zeitraum',
  welcome: 'Welcome',
  booked_services: 'Gebuchte Services',
  guest_has_been_successfully_checked_in: 'Gast wurde erfolgreich eingecheckt!',
  guest_has_been_successfully_checked_out:
    'Der Gast wurde erfolgreich ausgecheckt!',
  could_not_check_in_guest: 'Gast konnte nicht eingecheckt werden',
  could_not_check_out_guest: 'Gast konnte nicht auschecken',
  make_check_in: 'Check-In vornehmen',
  make_check_out: 'Check-Out vornehmen',
  disconnected_from_second_screen:
    'Verbindung zum zweiten Bildschirm unterbrochen',
  device_id_pair_context_must_be_used_within_device_id_pair_provider:
    'DeviceIdPair Context muss innerhalb des DeviceIdPair Providers verwendet werden',
  arrived: 'Angereist',
  departed: 'Abgereist',
  generate_invoice: 'Rechnung erzeugen',
  disconnected: 'Getrennt',

  abort: 'Abbrechen',
  mirror_booking_details: 'Buchungsdetails spiegeln',
  did_you_choose_the_right_guest: 'Hast du den richtigen Gast ausgewählt?',
  msg_check_in_check_out_modal:
    'Im nächsten Schritt werden die Buchungsdetails zur Kontrolle dem Gast angezeigt. Anpassungen oder Ergänzungen können im Anschluss noch vorgenommen werden. ',
  should_the_booking_details_of_the_following_guest_be_displayed:
    'Sollen die Buchungsdetails von folgendem Gast angezeigt werden?',
  invoice_number: 'Rechnungsnr',
  invoice_type: 'Rechnungsart',
  date_of_invoice: 'Rechnungsdatum',
  all: 'Alle',
  owner_invoice: 'Mietabrechnung',
  customer_invoice: 'Kundenabrechnung',

  // Owner Invoice information
  owner_billing: 'Eigentümerabrechnung',
  sales_tax_id: 'Umsatzsteuer-IdNr',
  dear: 'Sehr geehrter',
  here_with_you_receive_the_current_rent_statement_for_your_object:
    'hiermit erhalten Sie die aktuelle Mietabrechnung für Ihr Objekt',
  with_the_invoice_number: 'mit der Rechnungsnummer',
  For_the_mentioned_period_we_can_invoice_the_following_booking:
    'Für den genannten Zeitraum können wir folgende Buchung abrechnen',
  your_income: 'Ihre Einnahmen',
  vat: 'MwSt.',
  our_services: 'Unsere Leistungen',
  vat_included: 'Enthaltene MwSt.',
  accounting: 'Abrechnung',
  total_net: 'Summe netto',
  balance_to_your_credit: 'Saldo zu Ihren Gusten',
  the_clearing_ammount_will_be_transferred_to_your_account_in_the_next_few_days:
    'Der Verrechnungsbetrag wird in den nächsten Tagen auf Ihr Konto',
  net_total: 'Summe netto',
  invoice_total: 'Summe',

  successfully_generate_invoice_by_booking_id:
    'Erfolgreich erzeugte Rechnung nach Buchungsnummer',

  select_existing_customer: 'Bestandskunde auswahlen',
  accept_selected: 'Auswahl ubernehman',
  payment_detail: 'Zahlung Detail',
  überwiesen: 'überwiesen',
};

export default de;

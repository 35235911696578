import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { BookingSchema } from 'constants/schema';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatCurrency } from 'utils';
import AppartmentInformation from './bms_booking_preview/AppartmentInformation';
import CustomerInformation from './bms_booking_preview/CustomerInformation';
import ServiceInformation from './bms_booking_preview/ServiceInformation';

type Props = Omit<ModalProps, 'children'> & {
  onCreateBooking: (data: any) => void;
  countriesList: any;
  appartmentBookingPrice: number;
  serviceBookingPrice: any;
  apartmentDetails: any;
};

const BookingPreviewModal = (props: Props) => {
  const {
    onCreateBooking,
    countriesList,
    appartmentBookingPrice,
    serviceBookingPrice,
    apartmentDetails,
  } = props;

  const { getValues } = useFormContext<BookingSchema>();
  const formValues = getValues();

  const countryDetails = countriesList.find(
    (country: any) => country.id === formValues.country_code
  );

  let totalServicePrice = useMemo(() => {
    let total = 0;
    serviceBookingPrice?.map((item: any) => {
      total += Number(item.total_price) + Number(item.booking_fee);
    });

    return total;
  }, [serviceBookingPrice]);

  return (
    <Modal size="4xl" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">{strings.booking_preview}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack direction="column" spacing="6">
            <CustomerInformation countryDetails={countryDetails} />

            <AppartmentInformation
              appartmentDetails={apartmentDetails}
              appartmentBookingPrice={appartmentBookingPrice}
            />

            {serviceBookingPrice && serviceBookingPrice.length > 0 && (
              <ServiceInformation
                totalServicePrice={totalServicePrice}
                serviceBookingsPriceList={serviceBookingPrice}
              />
            )}

            <Box alignSelf="self-end">
              <Heading mb="2" size="md">
                {strings.total_amount}:&nbsp;
                {formatCurrency(totalServicePrice + appartmentBookingPrice)}
              </Heading>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              colorScheme="primary"
              variant="outline"
              onClick={props.onClose}>
              {strings.cancel}
            </Button>
            <Button
              colorScheme="primary"
              onClick={() => {
                props.onClose();
                onCreateBooking(formValues);
              }}>
              {strings.add_booking}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingPreviewModal;

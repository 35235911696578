import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ServiceBookingDetails from './ServiceBookingDetails';

interface Props {
  serviceReserved: any;
  editing?: boolean;
}

const ServiceInformationCard: React.FC<Props> = (props) => {
  let { serviceReserved, editing } = props;
  let color = useColorModeValue('white', 'gray.900');
  if (!serviceReserved) return null;

  let confirmedServicesOnly = serviceReserved
    .map((i: any) => ({
      ...i,
      service_booking_details: i.service_booking_details.filter(
        (j: any) => j.status === 'confirmed'
      ),
    }))
    .filter((k: any) => k.service_booking_details.length > 0);

  return (
    <Grid
      gap="4"
      templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
      w="100%">
      {confirmedServicesOnly.map((service_booking: any, index: number) => (
        <GridItem key={service_booking.id}>
          <Box
            w="full"
            bg={color}
            p={6}
            border="1px solid #D9D9D9"
            overflow="hidden">
            <Heading textAlign="center" fontSize="16px" fontWeight="600">
              {strings.booking}&nbsp;#{++index}
            </Heading>
            <Center h="140px" justifyItems="center">
              <Image
                src={
                  service_booking.service_booking_details[0].service
                    .service_group.cover
                }
                h="110px"
              />
            </Center>
            <Stack lineHeight="0.9" fontSize="14px" mt="2">
              <ServiceBookingDetails
                editing={editing}
                data={service_booking.service_booking_details}
              />
            </Stack>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};

export default ServiceInformationCard;

import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ContactType } from 'constants/schema';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, cleanData } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';

const LABEL: ['guest', 'owner'] = ['guest', 'owner'];
const SOURCE = ['Manually', 'V-Office', 'App'];
const MERGE = ['Merged', 'Unmerged'];

interface Props {
  handleAdvancedSearch: any;
  isStatus?: boolean;
  handleResetSearch?: () => void;
  isLoading?: string | null;
}

const InitialValue = {
  first_name: '',
  last_name: '',
  type: '',
  source: '',
  is_merged: '',
  email: '',
  phone: '',
  mobile: '',
  location: '',
  street: '',
  newsletter_subscribed: '',
};

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} size="lg" />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const ContactSearch: React.FC<Props> = ({ isStatus = true, ...props }) => {
  const { handleAdvancedSearch, handleResetSearch, isLoading } = props;

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const DefaultValues = {
    first_name: searchValues.first_name ?? '',
    last_name: searchValues.last_name ?? '',
    type: searchValues.type ?? '',
    source: searchValues.source ?? '',
    is_merged: searchValues.is_merged ?? '',
    email: searchValues.email ?? '',
    phone: searchValues.phone ?? '',
    mobile: searchValues.mobile ?? '',
    location: searchValues.location ?? '',
    street: searchValues.street ?? '',
    newsletter_subscribed: searchValues.newsletter_subscribed ?? '',
  };

  const [startDate, setStartDate] = useState<Date | null>(
    searchValues?.updated_at_from
      ? new Date(searchValues?.updated_at_from)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues?.updated_at_to ? new Date(searchValues?.updated_at_to) : null
  );
  const [formValue, setFormValue] = useState<any>(DefaultValues);

  let firstRender = useRef(true);

  const ticketAPI = new TicketResource();

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const finalData: any = cleanData(formValue);
    if (startDate) finalData.updated_at_from = startDate;
    if (endDate) finalData.updated_at_to = endDate;
    handleAdvancedSearch(finalData, 'filter');
  }, [endDate, formValue, handleAdvancedSearch, startDate]);

  const handleReset = () => {
    setFormValue(InitialValue);
    setStartDate(null);
    setEndDate(null);
    let selectRoute = '';
    if (history.location.pathname.includes(routes.crm.contact.list))
      selectRoute = routes.crm.contact.list;
    if (history.location.pathname.includes(routes.crm.merge.list))
      selectRoute = routes.crm.merge.list;
    history.push(selectRoute);

    if (handleResetSearch !== undefined) handleResetSearch();
    else handleAdvancedSearch({}, 'reset');
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.first_name}</FormLabel>
                  <Input
                    size="lg"
                    type="text"
                    name="first_name"
                    value={formValue.first_name}
                    placeholder={strings.first_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.last_name}</FormLabel>
                  <Input
                    size="lg"
                    type="text"
                    name="last_name"
                    value={formValue.last_name}
                    placeholder={strings.last_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.label}</FormLabel>
                  <Select
                    size="lg"
                    rounded="sm"
                    name="type"
                    value={formValue.type}
                    placeholder={strings.select}
                    onChange={handleInputChange}>
                    {LABEL.map((item: ContactType) => (
                      <option key={item} value={item}>
                        {capitalizeFirstLetter(strings[item])}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.source}</FormLabel>
                  <Select
                    size="lg"
                    rounded="sm"
                    name="source"
                    value={formValue.source}
                    placeholder={strings.select_source}
                    onChange={handleInputChange}>
                    <option value="manual">{strings.manual}</option>
                    <option value="app">App</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.merge}</FormLabel>
                  <Select
                    size="lg"
                    name="is_merged"
                    value={formValue.is_merged}
                    placeholder={`${strings.select} ${strings.merge}`}
                    onChange={handleInputChange}>
                    <option value="true">{strings.merge}</option>
                    <option value="false">{strings.unmerge}</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.email}</FormLabel>

                  <Input
                    size="lg"
                    name="email"
                    value={formValue.email}
                    type="text"
                    placeholder={strings.email}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.phone_number}</FormLabel>
                  <Input
                    size="lg"
                    type="text"
                    name="phone"
                    value={formValue.phone}
                    placeholder={strings.phone_number}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.mobile}</FormLabel>
                  <Input
                    size="lg"
                    type="text"
                    name="mobile"
                    value={formValue.mobile}
                    placeholder={strings.mobile_number}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.added_on}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      h="100%"
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.500"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CreatedAtInput />}
                      onChange={handleDateChange}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                      portalId="root-popover"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>{strings.city}</FormLabel>

                  <Input
                    type="text"
                    size="lg"
                    name="location"
                    value={formValue.location}
                    placeholder={strings.city}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.street}</FormLabel>
                  <Input
                    name="street"
                    size="lg"
                    value={formValue.street}
                    type="text"
                    placeholder={strings.street}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.newsletter}</FormLabel>
                  <Select
                    size="lg"
                    name="newsletter_subscribed"
                    value={formValue.newsletter_subscribed}
                    placeholder={`${strings.select} ${strings.newsletter}`}
                    onChange={handleInputChange}>
                    <option value="true">{strings.subscribe}</option>
                    <option value="false">{strings.unsubscribe}</option>
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4" pt="4" justifyContent="flex-end">
            <Button
              colorScheme="primary"
              size="lg"
              onClick={handleReset}
              variant="outline">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ContactSearch;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { ExcelFile } from 'assets/icons';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import TicketAddMenu from 'components/ticket/TicketAddMenu';
import TicketListItem from 'components/ticket/TicketListItem';
import TicketSearch from 'components/ticket/TicketSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import * as FileSaver from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
import * as XLSX from 'xlsx';

const FILETYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILEEXTENSION = '.xlsx';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  title: string;
  priority: string;
  status: string;
  assigned_user_id: string;
  apartment_id: string;
  customer_id: string;
  customer: string;
  external_company_id: string;
  company: string;
  source: string;
  created_at_start: Date | null;
  created_at_end: Date | null;
  due_date_start: Date | null;
  due_date_end: Date | null;
  type: string;
  order_by: string;
  order: any;
}

const TicketList: React.FC = () => {
  const ticketApi = new TicketResource();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }
  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    priority: searchValues.priority ?? '',
    status: searchValues.status ?? '',
    assigned_user_id: searchValues.assigned_user_id ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    customer_id: searchValues.customer_id ?? '',
    customer: searchValues.customer ?? '',
    external_company_id: searchValues.external_company_id ?? '',
    company: searchValues.company ?? '',
    source: searchValues.source ?? '',
    created_at_start: searchValues.created_at_start
      ? new Date(searchValues.created_at_start)
      : null,
    created_at_end: searchValues.created_at_end
      ? new Date(searchValues.created_at_end)
      : null,
    due_date_start: searchValues.due_date_start
      ? new Date(searchValues.due_date_start)
      : null,
    due_date_end: searchValues.due_date_end
      ? new Date(searchValues.due_date_end)
      : null,
    type: searchValues.type ?? '',
    order_by: searchValues.order_by ?? '',
    order: searchValues.order ?? '',
  });

  const ticketList = useQuery(
    [
      'ticketList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        title: filterParams.title,
        priority: filterParams.priority,
        status: filterParams.status,
        assigned_user_id: filterParams.assigned_user_id,
        apartment_id: filterParams.apartment_id,
        customer_id: filterParams.customer_id,
        external_company_id: filterParams.external_company_id,
        source: filterParams.source,
        created_at_start: filterParams.created_at_start,
        created_at_end: filterParams.created_at_end,
        due_date_start: filterParams.due_date_start,
        due_date_end: filterParams.due_date_end,
        type: filterParams.type,
        order_by: filterParams.order_by,
        order: filterParams.order,
      },
    ],
    async () => {
      try {
        const response = await getTicketList(
          filterParams.currentPage,
          filterParams.pageSize
        );
        return response?.data;
      } catch (error) {
        throw new Error('Some error occured');
      }
    }
  );

  const getTicketList = async (page: number, limit: number) => {
    const {
      title,
      priority,
      status,
      assigned_user_id,
      apartment_id,
      customer_id,
      external_company_id,
      source,
      created_at_start,
      created_at_end,
      due_date_start,
      due_date_end,
      type,
      order_by,
      order,
    } = filterParams;

    const queryParams: any = {
      page,
      limit,
    };

    if (title) queryParams.title = title;
    if (priority) queryParams.priority = priority;
    if (status) queryParams.status = status;
    if (assigned_user_id) queryParams.assigned_user_id = assigned_user_id;
    if (apartment_id) queryParams.apartment_id = apartment_id;
    if (customer_id) queryParams.customer_id = customer_id;
    if (external_company_id)
      queryParams.external_company_id = external_company_id;
    if (source) queryParams.source = source;
    if (created_at_start) queryParams.created_at_start = created_at_start;
    if (created_at_end) queryParams.created_at_end = created_at_end;
    if (due_date_start) queryParams.due_date_start = due_date_start;
    if (due_date_end) queryParams.due_date_end = due_date_end;
    if (order_by) queryParams.order_by = order_by;
    if (order) queryParams.order = order;
    if (type) queryParams.type = type;

    return await ticketApi.list(queryParams);
  };

  const handleAdvancedSearch = useCallback((data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      title: data.title,
      priority: data.priority,
      status: data.status,
      assigned_user_id: data.assigned_user_id,
      apartment_id: data.apartment_id,
      customer_id: data.customer_id,
      customer: data.customer,
      external_company_id: data.external_company_id,
      company: data.company,
      source: data.source,
      created_at_start: data.created_at_start,
      created_at_end: data.created_at_end,
      due_date_start: data.due_date_start,
      due_date_end: data.due_date_end,
      type: data.type,
      order_by: data.order_by,
      order: data.order,
    }));
  }, []);

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.created_at_start) {
      data.created_at_start = DateFormatYMD(data.created_at_start);
    }
    if (data.created_at_end) {
      data.created_at_end = DateFormatYMD(data.created_at_end);
    }
    if (data.due_date_start) {
      data.due_date_start = DateFormatYMD(data.due_date_start);
    }
    if (data.due_date_end) {
      data.due_date_end = DateFormatYMD(data.due_date_end);
    }
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleExport = async () => {
    const ticketListTotal = await getTicketList(
      1,
      Number(ticketList?.data?.meta?.total)
    );
    let ticketArray = [];
    ticketArray = await ticketListTotal?.data?.data?.map(
      (res: any, index: number) => {
        return {
          [strings.sn]: index + 1,
          [strings.ticket_title]: res.title,
          [strings.priority]: res.priority.toUpperCase(),
          [strings.status]: res.status.toUpperCase(),
          [strings.assignee]: res?.assigned_user?.name,
          [strings.object]: res?.apartment?.name,
          [strings.customer]: res?.customer?.name,
          [strings.created_at]: res.createdAt,
          [strings.ticket_source]: res.source,
        };
      }
    );
    const ws = XLSX.utils.json_to_sheet(ticketArray);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: FILETYPE });
    FileSaver.saveAs(
      data,
      `Ticketliste-${DateFormat(new Date())}` + FILEEXTENSION
    );
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg">{strings.ticket_list}</Heading>

          <Stack direction="row">
            <TicketAddMenu path={routes.ticket.list.create} />

            <Tooltip label={strings.download_excel} hasArrow>
              <Button
                size="lg"
                colorScheme="green"
                variant="outline"
                onClick={handleExport}>
                <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
              </Button>
            </Tooltip>
          </Stack>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          defaultIndex={[defaultIndex]}
          boxShadow="box">
          <AccordionItem>
            <>
              <h2>
                <AccordionButton p="4">
                  <Box flex="1" textAlign="center">
                    <Flex justify="space-between">
                      <Heading fontSize="18px" fontWeight="medium">
                        <Icon as={BiFilter} /> {strings.filter}
                      </Heading>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding="0">
                <TicketSearch
                  handleAdvancedSearch={handleAdvancedSearch}
                  filter={filterParams}
                />
              </AccordionPanel>
            </>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          {ticketList.isError && (
            <Alert status="error">
              <AlertIcon />
              {strings.ticket_list_error}
            </Alert>
          )}
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.title}</Th>
                  <Th>{strings.priority}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.assignee}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.created_at}</Th>
                  <Th>{strings.ticket_source}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {ticketList?.data?.data?.map((ticket: any, index: number) => (
                  <TicketListItem
                    ticketData={ticket}
                    index={startingSN + index}
                    key={ticket.id}
                    search={search}
                  />
                ))}
                {ticketList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={12} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={ticketList}
        />
      </Stack>
    </>
  );
};

export default TicketList;

import { Box, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { customerScreenHeadingStyle } from 'assets/css/commonStyles';
import { BookingInfoScreenImg } from 'assets/images';
import { BookingPaymentStatus } from 'constants/schema';
import { BMSBooking } from 'constants/schemas/bookings';
import { useSocketContext } from 'context/SocketContext';
import React from 'react';
import { DateFormat } from 'utils/DateFormat';

const listItemCategory = {
  color: 'white',
  fontFamily: 'overpass',
  fontSize: '2.4vw',
  minW: '25vw',
  fontWeight: 'normal',
};

const listItemValue = {
  color: 'white',
  fontFamily: 'overpass',
  fontSize: '2.4vw',
  fontWeight: 'bold',
};

const listItemService = {
  color: 'white',
  fontFamily: 'overpass',
  fontSize: '1.25rem',
  fontWeight: 'mdeium',
};

const BookingInformationScreen = () => {
  const { state } = useSocketContext();

  const bookingInfo: BMSBooking = state.message?.message.bookingDetails;
  const serviceGroupInfo = state.message?.message.serviceGroupsName;
  const apartmentCoverImage = state.message?.message.apartmentCoverImage;

  const getPaymentStatusInGerman = (key: BookingPaymentStatus) => {
    if (key === 'paid') return 'Bezahlt';
    if (key === 'partial_paid') return 'Anzahlung Erfolgt';
    return 'Offen';
  };

  return (
    <Box
      h="100vh"
      bgImage={apartmentCoverImage ? apartmentCoverImage : BookingInfoScreenImg}
      backgroundSize="100% 100%"
      bgRepeat="no-repeat">
      <Flex
        direction="column"
        h="inherit"
        bg="linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 38.54%, rgba(0, 0, 0, 0) 100%);">
        <Flex
          py="4"
          px="6"
          bg="primary.500"
          w="fit-content"
          boxShadow="0px 4px 60px rgba(0, 0, 0, 0.06);"
          alignItems="baseline"
          borderRadius="0px 0px 10px 0px;">
          <Heading
            sx={customerScreenHeadingStyle}
            fontSize="3.6vw"
            fontWeight="normal">
            Willkommen&nbsp;
          </Heading>
          <Heading
            sx={customerScreenHeadingStyle}
            fontSize="4.4vw"
            fontWeight="black">
            {bookingInfo?.customer?.first_name}&nbsp;
            {bookingInfo?.customer?.last_name}
          </Heading>
        </Flex>
        <List
          mx="6"
          my="16"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}>
          <ListItem display="flex">
            <Text sx={listItemCategory}>Appartment:</Text>
            <Text sx={listItemValue}>
              {bookingInfo?.apartment?.name || '-'}
            </Text>
          </ListItem>

          <ListItem display="flex">
            <Text sx={listItemCategory}>Zeitraum:</Text>
            <Text sx={listItemValue}>
              {DateFormat(bookingInfo?.from_date)}
              {' - '}
              {DateFormat(bookingInfo?.to_date)}
            </Text>
          </ListItem>

          <ListItem display="flex">
            <Text sx={listItemCategory}>Personen:</Text>
            <Text sx={listItemValue}>
              {bookingInfo?.booking_info?.number_of_adults +
                bookingInfo?.booking_info?.number_of_children +
                bookingInfo?.booking_info?.number_of_babies || '-'}
            </Text>
          </ListItem>

          <ListItem display="flex">
            <Text sx={listItemCategory}>Status:</Text>
            <Text sx={{ ...listItemValue, color: 'primary.500' }}>
              {getPaymentStatusInGerman(bookingInfo?.payment_status)}
            </Text>
          </ListItem>
        </List>

        {serviceGroupInfo && serviceGroupInfo.length > 0 && (
          <Box
            position="absolute"
            minW="320px"
            bottom="0"
            right="0"
            bg="primary.500"
            p="6"
            boxShadow="0px 4px 60px rgba(0, 0, 0, 0.06)"
            borderRadius="10px 0px 0px 0px">
            <Heading mb="4" sx={listItemValue}>
              Gebuchte Services:
            </Heading>
            <List
              my="2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between">
              {serviceGroupInfo.map((serviceGroupName: string) => {
                return (
                  <ListItem key={serviceGroupName}>
                    <Text sx={listItemService}>{serviceGroupName}</Text>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default BookingInformationScreen;

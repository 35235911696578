import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { ExternalCompanySchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BiHelpCircle } from 'react-icons/bi';
import { maskPhone } from 'utils';
import { validEmail } from 'utils/validate';
interface Props {
  companyData?: ExternalCompanySchema;
}

const CompanyForm: React.FC<Props> = (props) => {
  const { companyData } = props;

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<ExternalCompanySchema>();

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.company_name}</FormLabel>
              <Input
                defaultValue={companyData?.name}
                {...register('name', {
                  required: strings.company_name_is_required,
                })}
                type="text"
                size="lg"
                placeholder={strings.company_name}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.email}>
              <FormLabel>{strings.email_address}</FormLabel>
              <Input
                defaultValue={companyData?.email ?? ''}
                size="lg"
                placeholder={strings.email_placeholder}
                {...register('email', {
                  validate: (value) => {
                    if (value) {
                      return validEmail(value) || strings.valid_email_address;
                    }
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.phone}>
              <Flex
                direction="row"
                align="baseline"
                justify="flex-start"
                justifyContent="space-between">
                <FormLabel>{strings.cellphone}</FormLabel>
                <Tooltip
                  hasArrow
                  label={strings.enter_a_country_code_at_the_start}
                  placement="right-start"
                  size="sm"
                  zIndex={1}>
                  <IconButton
                    icon={<BiHelpCircle />}
                    size="sm"
                    variant="ghost"
                    color="gray.500"
                    aria-label="Help"
                  />
                </Tooltip>
              </Flex>
              <Input
                type="text"
                size="lg"
                {...register('phone')}
                defaultValue={
                  companyData?.phone ? maskPhone(companyData.phone) : ''
                }
                placeholder={strings.cellphone}
                onChange={(e) =>
                  (e.target.value = `${maskPhone(e.target.value)}`)
                }
              />
              <FormErrorMessage>
                {errors?.phone && errors?.phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default CompanyForm;

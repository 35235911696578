import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { getActiveUsersForChatTransfer, transferChat } from 'api/chat';
import { EmployeeNotAvailable } from 'assets/images';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { BiRightArrowCircle } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';

interface Props {
  userIdFrom: number;
  roomName: string;
  shouldDisableTransferChat: boolean;
}

const TransferChatPopOver: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const { userIdFrom, roomName, shouldDisableTransferChat } = props;

  const fetchUserList = async () => {
    const response = await getActiveUsersForChatTransfer(userIdFrom);
    return response.data;
  };

  const userQuery = useQuery(`fetchUserList`, () => fetchUserList());

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      roomName: roomName,
      employeeFrom: userIdFrom,
      employeeTo: Number(e.target[0].value),
    };
    await transferChat(data);
    window.location.reload();
  };

  const users = userQuery?.data;

  const renderedTriggerButton = (
    <Button
      isDisabled={!!shouldDisableTransferChat}
      style={{
        background: 'white',
        width: 'var(--chakra-sizes-6)',
        height: 'var(--chakra-sizes-6)',
      }}>
      <Tooltip hasArrow label={strings.transfer_chat} fontSize="sm">
        <span>
          <Icon
            onClick={open}
            as={BiRightArrowCircle}
            w="7"
            h="7"
            mt={0.5}
            title={strings.transfer_chat}
            _hover={{ color: 'red.400' }}
          />
        </span>
      </Tooltip>
    </Button>
  );

  if (!!shouldDisableTransferChat) {
    return renderedTriggerButton;
  }

  return (
    <>
      <Popover
        id={`popover-${roomName}`}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        closeOnBlur
        placement="right">
        <PopoverTrigger>{renderedTriggerButton}</PopoverTrigger>
        <Portal>
          <PopoverContent p={4}>
            <PopoverArrow />
            <PopoverCloseButton />
            {users?.length > 0 ? (
              <>
                <PopoverHeader fontWeight="semibold" mb="2">
                  {strings.chat_forwarding}
                </PopoverHeader>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={4} p="2">
                    <Text fontSize="sm">
                      {strings.forward_the_chat_to_a_free_employee}
                    </Text>
                    <Select
                      placeholder={strings.select_employee}
                      name="employee_to"
                      size="lg"
                      required>
                      {userQuery?.data?.map((users: any) => {
                        if (users.user_id === userIdFrom && !users.status)
                          return null;
                        return (
                          <option key={users.user_id} value={users.user_id}>
                            {users.name}
                          </option>
                        );
                      })}
                    </Select>
                    <ButtonGroup display="flex" justifyContent="flex-end">
                      <Button
                        bg="chat.400"
                        color="white"
                        _hover={{ bg: 'chat.500' }}
                        _active={{ bg: 'chat.500' }}
                        type="submit">
                        {strings.forward_chat}
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </>
            ) : (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                px={8}>
                <Heading size="sm">{strings.chat_forwarding}</Heading>
                <Image src={EmployeeNotAvailable} mb={3} />
                <Text color="gray.500" fontSize="sm" textAlign="center">
                  {strings.chat_users_not_available_for_transferral}
                </Text>
              </Flex>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default TransferChatPopOver;

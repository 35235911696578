import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import { strings } from 'config/localization';
import { AvailableDates, BookingSchema } from 'constants/schema';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { GoInfo } from 'react-icons/go';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

const DatePickerInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

DatePickerInput.displayName = 'DatePickerInput';

interface Props {
  apartmentList: any;
  setAppartmentBookingPrice: React.Dispatch<React.SetStateAction<number>>;
  apartmentDetails: any;
}

const AppartmentInformationBookingForm = (props: Props) => {
  const { apartmentList, setAppartmentBookingPrice, apartmentDetails } = props;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const startDateFromURL = searchParams.get('start');
  const endDateFromURL = searchParams.get('end');
  const objectFromURL = searchParams.get('obj');

  const maxPeopleAllowed = apartmentDetails?.number_of_persons || 1;
  const maxPetsAllowed = apartmentDetails?.number_of_pets || 0;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const bmsBookingAPI = new BMSBookingResource();

  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
    setError,
  } = useFormContext<BookingSchema>();

  const apartmentId = watch('apartment_id');
  const numberOfAdults = Number(watch('number_of_adults')) || 1;
  const numberOfChildren = Number(watch('number_of_children')) || 0;
  const numberOfBabies = Number(watch('number_of_babies')) || 0;
  const numberOfDogs = Number(watch('number_of_dogs')) || 0;

  let maxAdultsAllowed = maxPeopleAllowed - numberOfBabies - numberOfChildren;
  let maxChildrenAllowed = maxPeopleAllowed - numberOfAdults - numberOfBabies;
  let maxBabiesAllowed = maxPeopleAllowed - numberOfAdults - numberOfChildren;

  const datesListQuery = useQuery(
    ['available-dates', apartmentId],
    async () => {
      const res = await bmsBookingAPI.checkDateAvailabilities(apartmentId);
      return res.data;
    },
    {
      enabled: !!apartmentId,
      refetchOnWindowFocus: false,
    }
  );
  const datesList: AvailableDates[] = datesListQuery?.data?.data;

  const availableDates = datesList?.reduce((prev: Date[], acc) => {
    if (acc.available) return [...prev, new Date(acc.date)];
    return prev;
  }, []);

  const { isError, refetch } = useQuery(
    ['appartment-booking-price'],
    () => {
      let query = {
        apartment_id: apartmentId,
        from_date: DateFormatYMD(startDate),
        to_date: DateFormatYMD(endDate),
      };
      return bmsBookingAPI.appartmentBookingPrice(query).then((res) => {
        let price = Number(res.data.data.total_booking_price) || 0;
        setAppartmentBookingPrice(price);
        clearErrors('from_date');
        return res.data;
      });
    },
    {
      enabled: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (isError) {
      setError(
        'from_date',
        {
          type: 'manual',
          message: strings.cannot_make_bookings_in_the_given_date_range,
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [isError, setError]);

  useEffect(() => {
    if (apartmentId && startDate && endDate) {
      refetch();
    }
  }, [apartmentId, startDate, endDate, refetch]);

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    setValue('from_date', DateFormatYMD(start));
    setValue('to_date', DateFormatYMD(end));

    clearErrors(['from_date', 'to_date']);
  };

  useEffect(() => {
    // set default values because the number input component isnt automatically setting them
    setValue('number_of_adults', 1);
    setValue('number_of_children', 0);
    setValue('number_of_babies', 0);
    setValue('number_of_dogs', 0);
  }, [setValue, apartmentId]);

  useEffect(() => {
    if (startDateFromURL) {
      setStartDate(new Date(startDateFromURL));
      setValue('from_date', startDateFromURL);
    }
    if (endDateFromURL) {
      setEndDate(new Date(endDateFromURL));
      setValue('to_date', endDateFromURL);
    }
    if (objectFromURL) {
      setValue('apartment_id', objectFromURL);
    }
  }, [startDateFromURL, endDateFromURL, objectFromURL, setValue]);

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.apartment_id} isRequired>
              <FormLabel>{strings.apartment_name}</FormLabel>
              <Select
                placeholder={strings.select_apartment}
                size="lg"
                {...register('apartment_id', {
                  required: strings.appartment_is_required,
                })}>
                {apartmentList?.map((apartment: any) => {
                  return (
                    <option key={apartment.id} value={apartment.id}>
                      {apartment.name}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.apartment_id && errors?.apartment_id?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl
              isDisabled={datesListQuery.isLoading}
              isInvalid={!!errors?.from_date || !!errors?.to_date}
              isRequired>
              <FormLabel>{strings.date}</FormLabel>
              <Controller
                control={control}
                name={'from_date'}
                rules={{
                  validate: () => {
                    if (!startDate || !endDate) return strings.date_is_required;
                    if (isError)
                      return strings.cannot_make_bookings_in_the_given_date_range;
                    return true;
                  },
                }}
                render={({ field }: any) => {
                  return (
                    <Box className="date-picker-two-months">
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat="dd.MM.yyyy"
                        value={getFormattedRangeDate(startDate, endDate)}
                        selectsRange
                        includeDates={availableDates}
                        selected={startDate}
                        startDate={startDate}
                        monthsShown={2}
                        endDate={endDate}
                        disabled={datesListQuery.isLoading}
                        customInput={
                          <DatePickerInput isRequired={false} size="lg" />
                        }
                        shouldCloseOnSelect={false}
                        onChange={handleDateChange}
                        portalId="root-popover"
                      />
                    </Box>
                  );
                }}
              />
              <FormErrorMessage>
                {errors?.from_date && errors?.from_date?.message}{' '}
                {errors?.to_date && errors?.to_date?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.status} isRequired>
              <FormLabel>{strings.status}</FormLabel>
              <Select
                placeholder={strings.select_status}
                size="lg"
                isRequired={false}
                {...register('status', {
                  required: strings.required_status,
                })}>
                <option value="confirmed">{strings.confirmed}</option>
                <option value="reserved">{strings.reserved}</option>
              </Select>
              <FormErrorMessage>
                {errors?.status && errors?.status?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <Stack direction="row" spacing="4">
              <FormControl isInvalid={!!errors?.number_of_adults}>
                <Flex
                  direction="row"
                  align="baseline"
                  justify="flex-start"
                  justifyContent="space-between">
                  <FormLabel>{strings.adult}</FormLabel>
                  <Tooltip
                    hasArrow
                    label={strings.above_18_years_old}
                    placement="right-start"
                    size="sm"
                    zIndex={1}>
                    <span>
                      <Icon as={GoInfo} h="12px" w="12px" color="gray.500" />
                    </span>
                  </Tooltip>
                </Flex>

                <Controller
                  control={control}
                  name={'number_of_adults'}
                  render={({ field }) => {
                    return (
                      <NumberInput
                        defaultValue={1}
                        min={1}
                        max={maxAdultsAllowed <= 1 ? 1 : maxAdultsAllowed}
                        precision={0}
                        size="lg"
                        value={numberOfAdults}
                        onChange={(value) => field.onChange(Number(value))}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors?.number_of_adults &&
                    errors?.number_of_adults?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.number_of_children}>
                <Flex
                  direction="row"
                  align="baseline"
                  justify="flex-start"
                  justifyContent="space-between">
                  <FormLabel>{strings.children}</FormLabel>
                  <Tooltip
                    hasArrow
                    label={strings.under_18_years_old}
                    placement="right-start"
                    size="sm"
                    zIndex={1}>
                    <span>
                      <Icon as={GoInfo} h="12px" w="12px" color="gray.500" />
                    </span>
                  </Tooltip>
                </Flex>

                <Controller
                  control={control}
                  name={'number_of_children'}
                  render={({ field }) => {
                    return (
                      <NumberInput
                        defaultValue={0}
                        min={0}
                        max={maxChildrenAllowed < 0 ? 0 : maxChildrenAllowed}
                        precision={0}
                        value={numberOfChildren}
                        size="lg"
                        onChange={(value) => field.onChange(Number(value))}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors?.number_of_children &&
                    errors?.number_of_children?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </GridItem>

          <GridItem>
            <Stack direction="row" spacing="4">
              <FormControl isInvalid={!!errors?.number_of_babies}>
                <Flex
                  direction="row"
                  align="baseline"
                  justify="flex-start"
                  justifyContent="space-between">
                  <FormLabel>{strings.baby}</FormLabel>
                  <Tooltip
                    hasArrow
                    label={strings.under_3_years_old}
                    placement="right-start"
                    size="sm"
                    zIndex={1}>
                    <span>
                      <Icon as={GoInfo} h="12px" w="12px" color="gray.500" />
                    </span>
                  </Tooltip>
                </Flex>
                <Controller
                  control={control}
                  name={'number_of_babies'}
                  render={({ field }) => {
                    return (
                      <NumberInput
                        defaultValue={0}
                        min={0}
                        max={maxBabiesAllowed < 0 ? 0 : maxBabiesAllowed}
                        value={numberOfBabies}
                        precision={0}
                        size="lg"
                        onChange={(value) => field.onChange(Number(value))}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors?.number_of_babies &&
                    errors?.number_of_babies?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.number_of_dogs}>
                <FormLabel>{strings.dog}</FormLabel>
                <Controller
                  control={control}
                  name={'number_of_dogs'}
                  render={({ field }) => {
                    return (
                      <NumberInput
                        defaultValue={0}
                        min={0}
                        max={maxPetsAllowed}
                        value={numberOfDogs}
                        precision={0}
                        size="lg"
                        onChange={(value) => field.onChange(Number(value))}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors?.number_of_dogs && errors?.number_of_dogs?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.distribution_channel}>
              <FormLabel>{strings.distribution_channel}</FormLabel>
              <Select
                placeholder={strings.select_distribution_channel}
                size="lg"
                isRequired={false}
                {...register('distribution_channel', {
                  required: strings.required_distribution_channel,
                })}>
                <option value="email">{strings.email}</option>
                <option value="phone">{strings.call}</option>
              </Select>
              <FormErrorMessage>
                {errors?.distribution_channel &&
                  errors?.distribution_channel?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem gridColumn={1} gridColumnEnd={[1, 4]}>
            <FormControl isInvalid={!!errors?.remarks}>
              <FormLabel>{strings.notes_notizen}</FormLabel>
              <Textarea
                placeholder={strings.further_remarks_notes}
                isRequired={false}
                {...register('remarks')}
              />
              <FormErrorMessage>
                {errors?.remarks && errors?.remarks?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default AppartmentInformationBookingForm;

import {
  Avatar,
  Box,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChatNotAvailableImg } from 'assets/images';
import { strings } from 'config/localization';
import React from 'react';

interface ChatNotAvailableProps {
  message: string;
  imageWidth?: string;
}

const ChatNotAvailable: React.FC<ChatNotAvailableProps> = ({ message }) => {
  return (
    <Stack direction="column" flex="1" pos="relative" spacing="0">
      <Stack direction="row" spacing="4" p="4" bg="white">
        <Avatar
          size="lg"
          shadow="lg"
          name="status"
          bg="gray.400"
          color="white"
        />
        <Stack direction="column" spacing="3">
          <Heading size="sm" fontWeight="medium" pt="4">
            #status
          </Heading>
        </Stack>
      </Stack>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        boxShadow="sm"
        rounded="sm"
        w="100%"
        py={20}
        mx="auto">
        <Image src={ChatNotAvailableImg} alt="chat-not-avilable" mb={6} />
        <Heading size="md" mb="2">
          {strings.no_active_chat_history}
        </Heading>
        <Text fontSize="md">{message}</Text>
      </Box>
    </Stack>
  );
};

export default ChatNotAvailable;

import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import PMSPaymentLinkAPI from 'api/pms_payment_link';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { RiMailSendLine } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { formatCurrency } from 'utils';
import { utcToLocal } from 'utils/DateFormat';

interface Props {
  paymentLinkListData: any;
  index: number;
}
const PMSPaymentLinksListItem: React.FC<Props> = (props) => {
  const { paymentLinkListData, index } = props;
  const status: 'pending' | 'confirmed' = paymentLinkListData?.status;
  const newStatus = status === 'confirmed' ? strings.paid : strings.pending;
  const pmsPaymentLinkAPI = new PMSPaymentLinkAPI();
  const toast = useToast();
  const [isResendModalOpen, setResendModalOpen] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState<boolean>(false);
  const [resendErrorMsg, setResendErrorMsg] = useState<string>('');

  const resendEmail = useMutation(
    (data: any) => pmsPaymentLinkAPI.resendEmail(data),
    {
      onSuccess: () => {
        toast({
          title: `${strings.resent_mail_success}`,
          status: 'success',
          isClosable: true,
        });
        setResendModalOpen(false);
        setIsResendLoading(false);
      },
      onError: () => {
        toast({
          title: `${strings.resent_mail_error}`,
          status: 'error',
          isClosable: true,
        });
        setResendErrorMsg(strings.error_boundary_heading_text);
      },
    }
  );

  const resendEmailHandler = () => {
    setIsResendLoading(true);
    const data = {
      id: paymentLinkListData?.id,
    };
    resendEmail.mutate(data);
  };

  const onResendModalClose = () => {
    setResendModalOpen(false);
    setIsResendLoading(false);
  };

  return (
    <Tr key={paymentLinkListData.id}>
      <Td>{index}</Td>
      <Td>{paymentLinkListData?.email || '-'}</Td>
      <Td isNumeric>
        {formatCurrency(Number(paymentLinkListData?.amount))} CHF
      </Td>
      <Td>{paymentLinkListData?.customer_name || '-'}</Td>
      <Td>{paymentLinkListData?.booking_number || '-'}</Td>
      <Td>
        {paymentLinkListData?.payment?.payment_method
          ? paymentLinkListData?.payment?.payment_method
          : '-'}
      </Td>
      <Td>
        {paymentLinkListData?.email_sent_at
          ? utcToLocal(paymentLinkListData?.email_sent_at).replace(' ', ', ')
          : '-'}
      </Td>
      <Td>
        {paymentLinkListData?.payment?.date_time
          ? utcToLocal(paymentLinkListData?.payment?.date_time).replace(
              ' ',
              ', '
            )
          : '-'}
      </Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {newStatus || '-'}
        </Text>
      </Td>
      <Td textAlign="center">
        {status === 'pending' ? (
          <Tooltip hasArrow label={strings.send_mail}>
            <Button
              type="button"
              variant="link"
              color="blue.300"
              onClick={() => setResendModalOpen(true)}>
              <Icon as={RiMailSendLine} w="4" h="4" />
            </Button>
          </Tooltip>
        ) : (
          '-'
        )}
      </Td>
      <Modal
        isOpen={isResendModalOpen}
        isCentered
        closeOnOverlayClick={!isResendLoading}
        onClose={onResendModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.send_mail}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {resendErrorMsg && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {resendErrorMsg}
              </Alert>
            )}
            {strings.confirm_resend_email}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="primary"
                isLoading={isResendLoading}
                onClick={resendEmailHandler}>
                {strings.confirm}
              </Button>
              <Button
                variant="outline"
                isDisabled={isResendLoading}
                onClick={onResendModalClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr>
  );
};

export default PMSPaymentLinksListItem;

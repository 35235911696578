import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ObjectDocumentResource from 'api/object_document';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { BiCloudDownload, BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { DateFormat } from 'utils/DateFormat';
interface Props {
  objectDocumentData: any;
  index: number;
}
const ObjectDocumentListItem: React.FC<Props> = (props) => {
  const { objectDocumentData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const objectDocumentAPI = new ObjectDocumentResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const deleteGeneralDocument = useMutation(
    () => objectDocumentAPI.destroy(objectDocumentData.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('objectDocumentList');
        setDeleteModalOpen(false);
        toast({
          title: strings.general_document_deleted,
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.message);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteGeneralDocument.mutate();
  };

  return (
    <Tr key={objectDocumentData.id}>
      <Td>{index}</Td>
      <Td>{objectDocumentData?.name}</Td>
      <Td>{objectDocumentData?.created_at || '-'}</Td>
      <Td>{DateFormat(objectDocumentData?.available_from_date) || '-'}</Td>
      <Td>{DateFormat(objectDocumentData?.available_to_date) || '-'}</Td>

      <Td>
        <AccessControl
          allowedPermissions={[PermissionRequest['manage:object-dms']]}>
          <RouterLink
            to={routes.dms.objectDocuments.edit.replace(
              ':id',
              objectDocumentData.id.toString()
            )}>
            <Tooltip hasArrow label={strings.edit_general_document}>
              <IconButton
                icon={<BiEdit />}
                variant="link"
                aria-label={strings.edit_general_document}
                color="blue.300"
                minW="6"
              />
            </Tooltip>
          </RouterLink>
          <Tooltip hasArrow label={strings.delete_general_document}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_general_document}
              color="red.300"
              minW="6"
              onClick={onDeletePress}
            />
          </Tooltip>
        </AccessControl>
        {objectDocumentData?.file_path && (
          <Tooltip hasArrow label={strings.download}>
            <IconButton
              icon={<BiCloudDownload />}
              variant="link"
              aria-label={strings.download}
              minW="6"
              onClick={() =>
                FileSaver.saveAs(
                  objectDocumentData?.file_path,
                  objectDocumentData?.file_path
                )
              }
            />
          </Tooltip>
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_general_document}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteGeneralDocument.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ObjectDocumentListItem;

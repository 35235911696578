import http from 'utils/http';
import Resource from './resource';

class BMSCheckOutResource extends Resource {
  constructor() {
    super('bms/booking-registers/check-out');
  }

  checkOut(id: number) {
    return http({
      url: `/bms/booking-registers/${id}/check-out`,
      method: 'PUT',
    });
  }
}

export { BMSCheckOutResource as default };

import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import OMSapartmentFeaturesResource from 'api/oms-apartmentFeatures';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DynamicFields from './DynamicFields';

interface Props {
  apartmentId: string;
  groupId: number;
  groupName: string;
  editing: boolean;
}

const FeaturesForm: React.FC<Props> = (props) => {
  const { apartmentId, groupId, groupName, editing } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const apartmentFeatureAPI = new OMSapartmentFeaturesResource();
  const updateArticle = useMutation((data: any) =>
    apartmentFeatureAPI.update(apartmentId, data)
  );

  const { data: features, isLoading: featuresLoading } = useQuery(
    `apartment-featuresList-${groupId}`,
    () =>
      apartmentFeatureAPI
        .get(apartmentId, {
          limit: 'all',
          feature_group_id: groupId,
        })
        .then((res: any) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onSubmit = (data: any) => {
    setIsLoading(true);
    let feat: any = [];
    Object.entries(data).forEach((item: any) => {
      let [key, value] = item;
      if (value === undefined) return;
      let type = features.data.find((item: any) => item.id == key).type;
      if (type === 'number' && value === '') {
        return;
      }
      if (typeof value === 'object') {
        value = value.map((it: any) => it.value);
      }
      let fields = { feature_id: key, feature_values: value };
      feat.push(fields);
    });
    let formData = { features: feat };

    updateArticle.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(`apartment-featuresList-${groupId}`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(`${strings.apartment} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  if (!features) return null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="column" spacing="8">
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <Stack direction="row" spacing="8">
            <Grid
              gap="4"
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
              w="100%">
              <GridItem colSpan={2}>
                <Heading color="green.600" size="md" textTransform="capitalize">
                  {groupName}
                </Heading>
              </GridItem>

              {features?.data?.map((item: any) => (
                <GridItem key={item.id}>
                  <DynamicFields field={item} editing={editing} />
                </GridItem>
              ))}
            </Grid>
          </Stack>
          {editing && (
            <ButtonGroup alignSelf="end">
              <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                {strings.save}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default FeaturesForm;

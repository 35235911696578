import { Image, Td, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { CheckIn, CheckOut } from 'assets/icons';
import CheckInSecondScreenModal from 'components/terminal/CheckInSecondScreenModal';
import CheckOutConfirmationModal from 'components/terminal/CheckOutConfirmationModal';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import { BMSCheckInOutBooking } from 'constants/schemas/bookings';
import useIsPermitted from 'hooks/usIsPermitted';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  checkInCheckOutItem: BMSCheckInOutBooking;
  status?: string;
}

const CheckInCheckOutListItem = (props: Props) => {
  const { checkInCheckOutItem, status } = props;
  const checkinModalDisclosure = useDisclosure();
  const checkoutModalDisclosure = useDisclosure();
  const toast = useToast();

  const checkEditPermission = useIsPermitted([
    PermissionRequest['manage:bookings'],
  ]);

  const openModal = () => {
    if (!checkEditPermission) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    status === 'Check-In'
      ? checkinModalDisclosure.onOpen()
      : checkoutModalDisclosure.onOpen();
  };

  return (
    <>
      <Tr key={checkInCheckOutItem.id}>
        <Td>{checkInCheckOutItem.booking_number}</Td>
        <Td>
          {checkInCheckOutItem.booking?.customer?.first_name}{' '}
          {checkInCheckOutItem.booking?.customer?.last_name}
        </Td>
        <Td>{checkInCheckOutItem.booking?.apartment.name}</Td>
        <Td>{DateFormat(checkInCheckOutItem.booking?.from_date)}</Td>
        <Td>{DateFormat(checkInCheckOutItem.booking?.to_date)}</Td>

        <Td
          display="flex"
          color="#6266D1 !important"
          fontSize={'14px'}
          onClick={openModal}
          cursor="pointer">
          <Image src={status === 'Check-In' ? CheckIn : CheckOut} w="14px" />
          &nbsp;
          {status}
        </Td>
      </Tr>

      {status === 'Check-In' && checkinModalDisclosure.isOpen && (
        <CheckInSecondScreenModal
          isModalOpen={checkinModalDisclosure.isOpen}
          onModalClose={checkinModalDisclosure.onClose}
          bookingId={checkInCheckOutItem.id}
        />
      )}
      {status === 'Check-Out' && checkoutModalDisclosure.isOpen && (
        <CheckOutConfirmationModal
          isModalOpen={checkoutModalDisclosure.isOpen}
          onModalClose={checkoutModalDisclosure.onClose}
          bookingId={checkInCheckOutItem.id}
        />
      )}
    </>
  );
};

export default CheckInCheckOutListItem;

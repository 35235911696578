import http from 'utils/http';
import Resource from './resource';

class OMSObjectsResource extends Resource {
  constructor() {
    super(`oms/apartments`);
  }
  listCountries() {
    return http({
      url: '/oms/countries?limit=250',
      method: 'get',
    });
  }
  getCountryDetail(id: string) {
    return http({
      url: `/oms/countries/${id}`,
      method: 'get',
    });
  }
  listSeasonPrices(id: string, query: any) {
    return http({
      url: '/' + this.uri + `/${id}/prices`,
      method: 'get',
      params: query,
    });
  }
  updateSeasonPrices(id: string, body: any) {
    return http({
      url: '/' + this.uri + `/${id}/prices`,
      data: body,
      method: 'put',
    });
  }
}

export { OMSObjectsResource as default };

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import MailsListItem from 'components/feedback/MailsListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { FeedbackMailSchema } from 'constants/schema';
import React, { forwardRef, useMemo, useState } from 'react';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  apartment_id: string;
  status: string;
}
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} size="lg" />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const MailsList: React.FC = () => {
  const feedbackResourceAPI = new FeedbackResource();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const apartmentList = useQuery([`apartmentList`], () =>
    feedbackResourceAPI
      .getApartmentList({ limit: 300, page: 1 })
      .then((res) => res.data)
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    apartment_id: searchValues.apartment ?? '',
    status: searchValues.status ?? '',
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const queryList = useQuery(
    [
      `feedbackMailsList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_name: filterParams.keyword,
        apartment_id: filterParams.apartment_id,
        status: filterParams.status,
        start_date: startDate?.toISOString(),
        end_date: endDate?.toISOString(),
      },
    ],
    () => {
      let { keyword, apartment_id, status } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.customer_name = keyword;
      if (apartment_id) queryParams.apartment_id = apartment_id;
      if (startDate) queryParams.last_email_sent_at_from = startDate;
      if (endDate) queryParams.last_email_sent_at_to = endDate;
      if (status) queryParams.status = status;
      return feedbackResourceAPI
        .feedbackMailList(queryParams)
        .then((res) => {
          setIsLoading(false);
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const {
    data: feedbackMailQuery,
    isLoading: feedbackLoading,
    error: feedbackMailsError,
  } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      apartment_id: '',
      status: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
            {strings.feedback}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.mails}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.mails}
        </Heading>
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form>
                <Stack direction="column" spacing="4">
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <Grid
                      gap={['4', '4', '6']}
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(4, 1fr)',
                      ]}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.search}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              h="100%"
                              pointerEvents="none"
                              children={<BiSearch />}
                              color="gray.500"
                            />
                            <Input
                              size="lg"
                              type="text"
                              placeholder={strings.search}
                              name="keyword"
                              value={filterParams.keyword}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.object}</FormLabel>
                          <Select
                            placeholder={strings.select_apartment}
                            size="lg"
                            id="apartment_id"
                            name="apartment_id"
                            rounded="sm"
                            value={filterParams.apartment_id}
                            onChange={handleInputChange}>
                            {apartmentList?.data?.data?.map(
                              (apartment: any) => {
                                return (
                                  <option
                                    key={apartment.id}
                                    value={apartment.id}>
                                    {apartment.name}
                                  </option>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.date}</FormLabel>
                          <InputGroup>
                            <InputRightElement
                              h="100%"
                              pointerEvents="none"
                              children={<BiCalendar />}
                              color="gray.500"
                            />
                            <ReactDatePickerRoot
                              placeholderText={strings.select_date}
                              dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                              customInput={<CreatedAtInput />}
                              onChange={handleDateChange}
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              value={getFormattedRangeDate(startDate, endDate)}
                              shouldCloseOnSelect={false}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.status}</FormLabel>
                          <Select
                            size="lg"
                            name="status"
                            value={filterParams.status}
                            placeholder={strings.select_status}
                            rounded="sm"
                            onChange={handleInputChange}>
                            <option value="pending">{strings.pending}</option>
                            <option value="sent">{strings.sent}</option>
                            <option value="resent">{strings.resent}</option>
                            <option value="received">{strings.received}</option>
                            <option value="closed">{strings.closed}</option>
                            <option value="no_email">{strings.no_mail}</option>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Stack>
                  <Stack direction="row" spacing="4" justifyContent="flex-end">
                    <Button
                      size="lg"
                      colorScheme="primary"
                      type="button"
                      variant="outline"
                      onClick={() => handleReset()}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.customer_name}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.apartment}</Th>
                <Th>{strings.time_period}</Th>
                <Th>{strings.date}</Th>
                <Th>{strings.status}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackMailQuery?.data?.map(
                (feedbackMailItem: FeedbackMailSchema, index: number) => (
                  <MailsListItem
                    data={feedbackMailItem}
                    key={feedbackMailItem?.reservation_id}
                    index={startingSN + index}
                  />
                )
              )}
            </Tbody>
            {feedbackLoading && <TableSkeletonLoader rows={7} cols={8} />}
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default MailsList;

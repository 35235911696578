import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { PasswordSchema } from 'constants/schema';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { validatePassword } from 'utils/validate';

const ChangePassword: React.FC = () => {
  const newPassword = useRef({});
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<PasswordSchema>();
  newPassword.current = watch('new_password', '');

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.old_password} isRequired>
            <FormLabel>{strings.Current_password}</FormLabel>
            <Input
              width={['100%', '50%']}
              size="lg"
              type="password"
              placeholder={strings.Current_password}
              {...register('old_password', {
                required: strings.required,
              })}
            />
            <FormErrorMessage>
              {errors?.old_password && errors?.old_password?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.new_password} isRequired>
            <FormLabel display="flex">{strings.new_password}</FormLabel>
            <Input
              width={['100%', '50%']}
              type="password"
              size="lg"
              placeholder={strings.new_password}
              {...register('new_password', {
                required: strings.required,
                validate: (value) =>
                  validatePassword(value) || strings.msg_valid_password,
              })}
            />
            <FormHelperText>{strings.password_helper_text}</FormHelperText>
            <FormErrorMessage>
              {errors?.new_password && errors?.new_password?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            isInvalid={!!errors?.new_password_confirmation}
            isRequired>
            <FormLabel display="flex">{strings.confirm_password}</FormLabel>
            <Input
              width={['100%', '50%']}
              type="password"
              size="lg"
              placeholder={strings.confirm_password}
              {...register('new_password_confirmation', {
                required: strings.required,
                validate: (value) =>
                  value === newPassword.current ||
                  strings.not_match_new_confirm_password,
              })}
            />
            <FormErrorMessage>
              {errors?.new_password_confirmation &&
                errors?.new_password_confirmation?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default ChangePassword;

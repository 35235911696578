import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import Feedback from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import { PdfBtn } from 'assets/icons';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FeedbackAnalysisPDF from 'components/feedback/FeedbackAnalysisPDF';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';
import FeedbackAnalysisItem from './FeedbackAnalysisItem';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  apartment_id: string;
  apartment_name: string;
  start_date: Date | null;
  end_date: Date | null;
}
interface FeedbackItem {
  id: number;
  title: string;
  type: 'rating' | 'closed' | 'open';
  total_count: number;
  result: number;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} size="lg" />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const FeedbackAnalysis: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const feedbackAnalysisAPI = new Feedback();
  const printRef: any = useRef();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.start_date ? new Date(searchValues.start_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.end_date ? new Date(searchValues.end_date) : null
  );

  const [pdfFilterParamsDate, setPdfFilterParamsDate] = useState<string>('');

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    apartment_name: searchValues.apartment_name ?? '',
    start_date: startDate,
    end_date: endDate,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      start_date: DateFormatYMD(start),
      end_date: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const apartmentList = useQuery([`apartmentList`], () =>
    feedbackAnalysisAPI
      .getApartmentList({ limit: 500, page: 1 })
      .then((res) => res.data)
  );

  const queryList = useQuery(
    [
      `feedbackAnalysisList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        type: filterParams.type,
        apartment_id: filterParams.apartment_id,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
      },
    ],
    async () => {
      const { keyword, type, apartment_id } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.keyword = keyword;
      if (type) queryParams.type = type;
      if (apartment_id) queryParams.apartment = apartment_id;
      if (startDate) queryParams.feedback_at_from = DateFormatYMD(startDate);
      if (endDate) queryParams.feedback_at_to = DateFormatYMD(endDate);
      return await feedbackAnalysisAPI
        .getFeedbackAnalysis(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const { data: feedbackAnalysisList, isLoading } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleApartmanetChange = (e: any) => {
    const { name, value } = e.target;
    const apartmentName = e.target?.selectedOptions?.[0]?.text;

    const selectApartment = apartmentName ? apartmentName : '';

    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
      apartment_name: selectApartment,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      type: '',
      apartment_id: '',
      apartment_name: '',
      start_date: null,
      end_date: null,
    });
    history.push(routes.feedback.analysis);
  };

  useEffect(() => {
    if (filterParams?.start_date || filterParams?.end_date) {
      setPdfFilterParamsDate(
        `_${DateFormat(filterParams?.start_date)}-${DateFormat(
          filterParams?.end_date
        )}`
      );
    }
  }, [filterParams?.end_date, filterParams?.start_date]);

  const handleFeedbackAnalysisPrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { padding-left:2px,padding-right:2px }',
    documentTitle: `${strings.feedback_analysis_detail}${pdfFilterParamsDate}`,
  });

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
            {strings.feedback}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.feedback.analysis}>
            {strings.analysis}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.analysis}
        </Heading>
        <Tooltip label={strings.download_pdf} hasArrow>
          <Button
            size="lg"
            colorScheme="red"
            variant="outline"
            onClick={handleFeedbackAnalysisPrint}>
            <Image w="6" src={PdfBtn} alt="PDF" title="PDF" />
          </Button>
        </Tooltip>
      </Flex>
      <div style={{ display: 'none' }}>
        <FeedbackAnalysisPDF
          feedbackAnalysis={feedbackAnalysisList}
          ref={printRef}
          startingSN={startingSN}
          filterParams={filterParams}
        />
      </div>

      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form>
                <Stack direction="column" spacing="4">
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <Grid
                      gap={['4', '4', '6']}
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(4, 1fr)',
                      ]}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.search}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              h="100%"
                              pointerEvents="none"
                              children={<BiSearch />}
                              color="gray.500"
                            />
                            <Input
                              placeholder={strings.search}
                              size="lg"
                              type="text"
                              name="keyword"
                              value={filterParams.keyword}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.select_type_art}</FormLabel>
                          <Select
                            size="lg"
                            name="type"
                            placeholder={strings.select_type_art}
                            value={filterParams.type}
                            onChange={handleInputChange}>
                            <option value="rating">{strings.rating}</option>
                            <option value="open">{strings.open_ended}</option>
                            <option value="closed">
                              {strings.closed_ended}
                            </option>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.apartment}</FormLabel>
                          <Select
                            size="lg"
                            name="apartment_id"
                            value={filterParams.apartment_id}
                            placeholder={strings.select_apartment}
                            rounded="sm"
                            onChange={handleApartmanetChange}>
                            {apartmentList?.data?.data?.map(
                              (apartment: any) => {
                                return (
                                  <option
                                    key={apartment.id}
                                    value={apartment.id}>
                                    {apartment.name}
                                  </option>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.date}</FormLabel>
                          <InputGroup>
                            <InputRightElement
                              pointerEvents="none"
                              h="100%"
                              children={<BiCalendar />}
                              color="gray.500"
                            />
                            <ReactDatePickerRoot
                              placeholderText={strings.select_date}
                              dateFormat="yyyy-MM-dd"
                              customInput={<CreatedAtInput />}
                              onChange={handleDateChange}
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              value={getFormattedRangeDate(startDate, endDate)}
                              selectsRange
                              shouldCloseOnSelect={false}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Stack>
                  <Stack direction="row" spacing="4" justifyContent="flex-end">
                    <Button
                      size="lg"
                      colorScheme="primary"
                      type="button"
                      variant="outline"
                      onClick={() => handleReset()}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.question}</Th>
                <Th>{strings.select_type_art}</Th>
                <Th textAlign="center">{strings.result} %</Th>
                <Th textAlign="center"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackAnalysisList?.data?.map(
                (feedbackItem: FeedbackItem, index: number) => {
                  return (
                    <FeedbackAnalysisItem
                      key={index}
                      index={startingSN + index}
                      data={feedbackItem}
                      apartmentId={filterParams.apartment_id}
                      apartmentName={filterParams.apartment_name}
                      dateFrom={filterParams.start_date}
                      dateTo={filterParams.end_date}
                    />
                  );
                }
              )}
            </Tbody>
            {isLoading && (
              <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
            )}
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default FeedbackAnalysis;

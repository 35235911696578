import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { SeasonInputSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data?: any;
}

const SeasonForm: React.FC<Props> = (props) => {
  let { data } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<SeasonInputSchema>();

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('description', data.description);
      setValue('from_date', data.from_date);
      setValue('to_date', data.to_date);
    }
  }, [data, setValue]);

  return (
    <Stack direction="column" spacing="8">
      <Stack direction="row" spacing="8">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>{strings.season_name}</FormLabel>
              <Input
                {...register('name', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                size="lg"
                type="text"
                placeholder={strings.season_name}
                defaultValue={data?.season_name}
              />
              <FormErrorMessage>
                {errors.name && errors.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.description} isRequired>
              <FormLabel>{strings.description}</FormLabel>
              <Input
                {...register('description', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                size="lg"
                type="text"
                placeholder={strings.description}
                defaultValue={data?.description}
              />
              <FormErrorMessage>
                {errors.description && errors.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      <Flex justify="space-between">
        <Heading size="md" mt={5} textTransform="capitalize">
          {strings.add_season}
        </Heading>
      </Flex>
      <Stack direction="row" spacing="8">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.from_date}>
              <FormLabel>{strings.from_date}</FormLabel>
              <Input
                {...register('from_date', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                size="lg"
                type="date"
                placeholder={strings.from_date}
                defaultValue={data?.from_date}
              />
              <FormErrorMessage>
                {errors.from_date && errors.from_date?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.to_date}>
              <FormLabel>{strings.to_date}</FormLabel>
              <Input
                {...register('to_date', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                size="lg"
                type="date"
                placeholder={strings.to_date}
                defaultValue={data?.to_date}
              />
              <FormErrorMessage>
                {errors.to_date && errors.to_date?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SeasonForm;

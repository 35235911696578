import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { BookingSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  countryDetails: any;
}

const CustomerInformation: React.FC<Props> = (props: Props) => {
  const { countryDetails } = props;

  const { getValues } = useFormContext<BookingSchema>();
  const formValues = getValues();
  return (
    <Box>
      <Heading mb="2" size="md">
        {strings.customer_information}
      </Heading>
      <Grid
        gap="6"
        templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        flex="1">
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.salutation}
          </Text>
          <Text>
            {strings[formValues?.salutation] || formValues?.salutation}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.title}
          </Text>
          <Text>{formValues?.title || '-'}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.first_name}
          </Text>
          <Text>{formValues?.first_name}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.last_name}
          </Text>
          <Text>{formValues?.last_name}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.town}
          </Text>
          <Text>{formValues?.city}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.house_number}
          </Text>
          <Text>{formValues?.house_number}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.post_code}
          </Text>
          <Text>{formValues?.zip_code}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.street}
          </Text>
          <Text>{formValues?.street}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.country}
          </Text>
          <Text>{countryDetails.name || formValues.country_code}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.email}
          </Text>
          <Text>{formValues?.email}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.phone}
          </Text>
          <Text>{formValues?.phone_number}</Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default CustomerInformation;

import BMSInvoicesResource from 'api/bms-invoices';
import { LogoPNG } from 'assets/images';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { MasterInvoice } from 'constants/schemas/invoice';
import React, { forwardRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  masterInvoiceId: number;
  ref?: any;
  setHasInvoiceLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isDownloadClicked: boolean;
}

const InvoicePreview = forwardRef((props: Props, ref: any) => {
  const { masterInvoiceId, setHasInvoiceLoaded, isDownloadClicked } = props;

  const bmsMasterInvoiceAPI = new BMSInvoicesResource();

  const masterInvoiceQuery = useQuery(
    ['master-invoice', masterInvoiceId],
    async () => {
      const data = await bmsMasterInvoiceAPI.get(masterInvoiceId);

      return data?.data;
    },
    {
      enabled: !!masterInvoiceId,
      refetchOnWindowFocus: false,
    }
  );
  const masterInvoice: MasterInvoice = masterInvoiceQuery?.data?.data;
  const customer = masterInvoice?.booking.customer;

  const adultsCount = masterInvoice?.booking.booking_info.number_of_adults;
  const childrenCount = masterInvoice?.booking.booking_info.number_of_children;
  const babiesCount = masterInvoice?.booking.booking_info.number_of_babies;
  const dogsCount = masterInvoice?.booking.booking_info.number_of_dogs;

  let totalBookedAmount: number = 0;
  let totalPaid: number = 0;

  useEffect(() => {
    if (masterInvoice && !masterInvoiceQuery.isLoading && isDownloadClicked)
      setHasInvoiceLoaded(true);
  }, [
    masterInvoiceQuery.isLoading,
    isDownloadClicked,
    masterInvoice,
    setHasInvoiceLoaded,
  ]);

  if (masterInvoiceQuery.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <div id="invoice-print" className="invoice-template-container" ref={ref}>
      <div className="invoice-heading">
        <img src={LogoPNG} height="200px" width="200px" alt="MyVal Logo" />
        <h2 className="myval-heading">Swiss Design Apartments</h2>
      </div>
      <div className="invoice-user-information">
        <div>
          <h6 className="myval-sub-heading">
            MYVAL Swiss Design Apartments Bahnhofstrasse 26, 3952 Susten,
            Schweiz
          </h6>
          <ul>
            <li>
              {strings[customer.salutation]} {customer.name}
            </li>
            <li>
              {customer.street} {customer.house_no}
            </li>
            <li>
              {customer.zip_code} {customer.city}
            </li>
            <li>{customer.country.name}</li>
          </ul>
          <h2 className="invoice-number">
            Rechnung / {masterInvoice?.invoice_number}
          </h2>
          <p>
            Sehr geehrter
            {` ${strings[customer?.salutation]} ${customer.last_name},`}
          </p>
        </div>
        <div className="myval-information">
          <ul>
            <li>Bahnhofstrasse 26</li>
            <li>3952 Susten</li>
            <li>Telefon: XXXX XXX XXX-X</li>
            <li>Fax: XXXXX XXX XXX</li>
          </ul>
          <ul>
            <li>Email: XXXXXXXXXXXXXXXXX</li>
            <li>Homepage: XXXXXXXXXXXX</li>
          </ul>
          <ul>
            <li>Geschäftsführer: Vorname Nachname</li>
            <li>HRB 10714 FL</li>
            <li>Umsatzsteuer-IdNr.: XXXXXXXXXXXX</li>
          </ul>
          <ul>
            <li> Swift/BIC: XXXXXXXXXX</li>
            <li>IBAN: XXXXXXXXXXXXXXXXXXXXXX</li>
          </ul>
          <ul>
            <li>Belegdatum: {DateFormat(masterInvoice?.created_at)}</li>
            <li>Belegnummer: {masterInvoice?.invoice_number}</li>
          </ul>
        </div>
      </div>

      <p>
        in Ihrer Abschlussrechnung sind alle erbrachten Leistungen aus Ihrem
        Aufenthalt enthalten für die&nbsp;
        <span className="fw-500">
          Buchungsnummer {masterInvoice?.booking.booking_number}&nbsp;
        </span>
        im&nbsp;
        <span className="fw-500">{masterInvoice?.booking.apartment.name}.</span>
      </p>
      <table className="invoice-table">
        <thead className="align-right">
          <tr>
            <th className="services align-left">Leistungen</th>
            <th>MwSt.</th>
            <th>Preis</th>
            <th>Betrag</th>
          </tr>
        </thead>
        <tbody className="align-right">
          <tr>
            <td className="align-left fw-600">
              {`${masterInvoice?.booking.booking_number}, ${customer.last_name}, ${customer.first_name}, `}
              {`${DateFormat(masterInvoice?.booking.from_date)} - ${DateFormat(
                masterInvoice?.booking.to_date
              )}, `}
              {adultsCount > 0 && `${adultsCount} Erwachsene`}
              {childrenCount > 0 && `, ${childrenCount} Kinder`}
              {babiesCount > 0 && `, ${babiesCount} Baby`}
              {dogsCount > 0 && `, ${dogsCount} Hunde`}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {masterInvoice?.booking.seasons_info.map((season) => {
            totalBookedAmount += Number(season.total_apartment_season_price);
            return (
              <tr key={season.id}>
                <td className="align-left">
                  Miete ({season.name}), {season.booked_timespan} Nächte
                </td>
                <td>X%</td>
                <td>
                  {formatCurrency(Number(season.apartment_season_price))} CHF
                </td>
                <td>
                  {formatCurrency(Number(season.total_apartment_season_price))}{' '}
                  CHF
                </td>
              </tr>
            );
          })}
          {masterInvoice?.service_bookings.map((serviceBooking) => {
            totalBookedAmount += Number(serviceBooking.total_price);
            return (
              <tr key={serviceBooking.id}>
                <td className="align-left">
                  {serviceBooking.quantity} x {serviceBooking.service.name}
                  {serviceBooking.timespan &&
                    `, ${serviceBooking.timespan} Tage`}
                </td>
                <td>X%</td>
                <td>{formatCurrency(Number(serviceBooking.unit_price))} CHF</td>
                <td>
                  {formatCurrency(Number(serviceBooking.total_price))} CHF
                </td>
              </tr>
            );
          })}
        </tbody>
        {/* amount */}
        <tfoot className="invoice-amount align-right">
          <tr>
            <td className="align-left" colSpan={3}>
              Summe
            </td>
            <td>{formatCurrency(totalBookedAmount)} CHF</td>
          </tr>
          <tr>
            <td className="align-left" colSpan={3}>
              Enthaltene MwSt.
            </td>
            <td>0,00 CHF</td>
          </tr>
          <tr>
            <td className="align-left" colSpan={3}>
              Summe netto
            </td>
            <td>{formatCurrency(totalBookedAmount)} CHF</td>
          </tr>
        </tfoot>
        {/* payments */}
      </table>
      <table className="invoice-table-payments">
        <thead>
          <tr>
            <th className="w-70 align-left" colSpan={3}>
              Bisherige Zahlungen
            </th>
            <th>Betrag</th>
          </tr>
        </thead>
        <tfoot>
          {masterInvoice?.payment?.map((item) => {
            totalPaid += Number(item.amount);
            return (
              <tr key={item.id}>
                <td className="align-left" colSpan={3}>
                  {DateFormat(item.updated_at)}
                </td>
                <td>{formatCurrency(Number(item.amount))} CHF</td>
              </tr>
            );
          })}
          <tr>
            <th className="align-left" colSpan={3}>
              Restbetrag
            </th>
            <th>{formatCurrency(totalBookedAmount - totalPaid)} CHF</th>
          </tr>
        </tfoot>
      </table>

      <div className="page-break-inside-avoid">
        <p>
          Bei einem Minus vor dem Ergebnis haben Sie ein Guthaben, dass wir auf
          die uns bekannte Bankverbindung zurürck überweisen. Bei einem offenen
          Rechnungsbetrag möchten wir Sie bitten, innerhalb von 5 Werktagen den
          offenen Restbetrag auf unsere Bankverbindung unter Angabe der
          Buchungsnummer {masterInvoice?.booking.booking_number} zu überweisen
        </p>
        <p>
          Vielen Dank für Ihren Besuch. Wir hoffen, Sie haben sich gut erholt
          und besuchen uns bald wieder.
        </p>
        <p>
          Ihr Team Swiss <br />
          Design Apartments
        </p>

        <hr className="divider" />

        <footer className="invoice-footer">
          <ul>
            <li>Appartmentverwaltung</li>
            <li>Swiss Design Apartments</li>
            <li>Bahnhofsstraße 26</li>
            <li>3952 Susten</li>
          </ul>

          <ul>
            <li>Telefon: xxxxxxxxxxxxx</li>
            <li>Telefax: xxxxxxxxxxxxxxxxxx</li>
            <li>E-Mail: xxxxxxxxxxxxxxxxxx</li>
            <li>Internet: xxxxxxxxxxxxxxxxxx</li>
          </ul>

          <ul>
            <li>Telefon: xxxxxxxxxxxxx</li>
            <li>Telefax: xxxxxxxxxxxxxxxxxx</li>
            <li>E-Mail: xxxxxxxxxxxxxxxxxx</li>
            <li>Internet: xxxxxxxxxxxxxxxxxx</li>
          </ul>

          <ul>
            <li>Bankverbiindung:</li>
            <li>Bank XY Susten</li>
            <li>IBAN: xxxxxxxxxxxxxx</li>
            <li>BIC: xxxxxxxxxxxx</li>
          </ul>
        </footer>
      </div>
    </div>
  );
});

InvoicePreview.displayName = 'Customer_Invoice';

export default InvoicePreview;

import { Center, Heading, Image, Stack } from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import React from 'react';

const LogoInformation = () => {
  return (
    <Stack spacing="4">
      <Center>
        <Image src={Logo} w="52" alt="logo" />
      </Center>
      <Stack>
        <Heading
            alignSelf={'center'}
          fontSize="xl"
          fontWeight="medium"
          color="gray.600">
          {strings.myval_ag}
        </Heading>
      </Stack>
    </Stack>
  );
};

export default LogoInformation;

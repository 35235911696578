import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { useSocketContext } from 'context/SocketContext';
import AddBooking from 'pages/dashboard/bms/Booking/AddBooking';
import AddServiceBookings from 'pages/dashboard/bms/Booking/AddServiceBookings';
import BookingCalendar from 'pages/dashboard/bms/Booking/BookingCalendar';
import BookingDetail from 'pages/dashboard/bms/Booking/BookingDetail';
import BookingList from 'pages/dashboard/bms/Booking/BookingList';
import EditBooking from 'pages/dashboard/bms/Booking/EditBooking';
import InvoicesList from 'pages/dashboard/bms/Invoices/InvoicesList';
import {
  Chat,
  ChatMetrics,
  ClosedChat,
  ClosedChatDetails,
} from 'pages/dashboard/chat';
import TotalCallsList from 'pages/dashboard/contact-center/TotalCalls/TotalCallsList';
import CallForUser from 'pages/dashboard/contact-center/users/CallForUser';
import ContactCenterUserList from 'pages/dashboard/contact-center/users/ContactCenterUserList';
import {
  AddContact,
  ContactDetails,
  ContactList,
  Logs,
  MergeContact,
} from 'pages/dashboard/crm';
import {
  CustomerList,
  CustomersDocumentList,
  DMSObjectList,
  DMSUserList,
  EditCustomersDocument,
  EditGeneral,
  EditObjectsDocument,
  EditOwnersDocument,
  EditUsersDocument,
  GeneralList,
  ObjectsDocumentList,
  OwnerList,
  OwnersDocumentList,
  UploadCustomer,
  UploadCustomersDocument,
  UploadObject,
  UploadObjectsDocument,
  UploadOwner,
  UploadOwnersDocument,
  UploadUser,
  UsersDocumentList,
} from 'pages/dashboard/dms';
import CreateGeneralDocument from 'pages/dashboard/dms/General/UploadGeneral';
import UploadUsersDocument from 'pages/dashboard/dms/UserDocuments/UploadUsersDocument';
import {
  AddFaq,
  AddGroup,
  EditFaq,
  Faqs,
  GroupList,
  InternalFaqList,
} from 'pages/dashboard/faq';
import {
  AddQuestion,
  EditQuestion,
  FeedbackDetails,
  FeedbacksList,
  MailsList,
  QuestionsList,
  SettingList,
} from 'pages/dashboard/feedback';
import FeedbackAnalysis from 'pages/dashboard/feedback/Analysis/FeedbackAnalysis';
import FeedbackDashboard from 'pages/dashboard/feedback/Dashboards/FeedbackDashboard';
import {
  AddKey,
  EditKey,
  KeyDetails,
  KeyHandling,
  KeyLists,
  LogInfo,
} from 'pages/dashboard/Key';
import KeyLogs from 'pages/dashboard/Key/KeyLogs/KeyLogs';
import {
  ComposeMessage,
  DraftList,
  NewsletterList,
} from 'pages/dashboard/newsletter';
import EditNewsLetter from 'pages/dashboard/newsletter/Newsletters/EditNewsLetter';
import ViewNewsletterDetails from 'pages/dashboard/newsletter/Newsletters/ViewNewsletterDetails';
import ApartmentDetails from 'pages/dashboard/oms/Apartment/ApartmentDetails';
import CreateApartment from 'pages/dashboard/oms/Apartment/CreateApartments';
import ObjektList from 'pages/dashboard/oms/ObjektList';
import { AddSeason, SeasonList } from 'pages/dashboard/oms/Season';
import {
  CreditCardList,
  PaymentLinksList,
  PaypalList,
} from 'pages/dashboard/pms';
import BankTransferList from 'pages/dashboard/pms/BankTransfer/BankTransferList';
import CreatePaymentLink from 'pages/dashboard/pms/PaymentLinks/CreatePaymentLink';
import { PushNotificationList } from 'pages/dashboard/pns';
import AddPushNotification from 'pages/dashboard/pns/AddPushNotification';
import { EditProfile, MyProfile } from 'pages/dashboard/Profile';
import SecondScreenSocketConnection from 'pages/dashboard/Profile/SecondScreenSocketConnection';
import {
  ServiceDetail,
  ServiceGroupDetail,
  ServiceGroupList,
  ServiceList,
} from 'pages/dashboard/sms';
import AddServiceGroup from 'pages/dashboard/sms/ServiceGroups/AddServiceGroup';
import EditServiceGroup from 'pages/dashboard/sms/ServiceGroups/EditServiceGroup';
import AddService from 'pages/dashboard/sms/Services/AddService';
import EditService from 'pages/dashboard/sms/Services/EditService';
import { SplashScreen } from 'pages/dashboard/splashScreen';
import { CheckInCheckOutList } from 'pages/dashboard/terminal';
import {
  CreateTicket,
  ObjectsInfo,
  TicketBoard,
  TicketList,
} from 'pages/dashboard/ticket';
import EditTicket from 'pages/dashboard/ticket/EditTicket';
import ViewTicket from 'pages/dashboard/ticket/ViewTicket';
import UserLoginLogList from 'pages/dashboard/users/UserLoginLogList';
import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AddNewCompany,
  AddNewUser,
  CompanyProfile,
  EditCompany,
  EditUser,
  ExternalCompanyList,
  UserList,
  UserProfile,
  UserRole,
  UserRoles,
} from '../pages/dashboard/users';
import RoleBasedRouting from './RoleBasedRouting';

const PrivateRouter: React.FC = () => {
  const { state } = useSocketContext();

  useEffect(() => {
    if (state.sessionId) {
      localStorage.setItem('EMP:sessionId', state.sessionId);
    }
  }, [state.sessionId]);
  return (
    <Switch>
      <Route
        exact
        path={routes.dashboard}
        component={() => <Redirect to={routes.splash} />}
      />
      <Route exact path={routes.splash} component={SplashScreen} />
      <Route exact path={routes.profile.myProfile} component={MyProfile} />
      <Route exact path={routes.profile.edit} component={EditProfile} />
      <Route
        exact
        path={routes.profile.socket}
        component={SecondScreenSocketConnection}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.list}
        component={UserList}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.add}
        component={AddNewUser}
        allowedPermissions={[
          PermissionRequest['read:user'],
          PermissionRequest['manage:user'],
        ]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.edit}
        component={EditUser}
        allowedPermissions={[PermissionRequest['manage:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.roles.list}
        component={UserRoles}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.roles.view}
        component={UserRole}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.profile}
        component={UserProfile}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.list}
        component={ExternalCompanyList}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.add}
        component={AddNewCompany}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.edit}
        component={EditCompany}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.profile}
        component={CompanyProfile}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.loginLog.list}
        component={UserLoginLogList}
        allowedPermissions={[PermissionRequest['read:login-log']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.default}
        component={Chat}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.closed.list}
        component={ClosedChat}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.closed.details}
        component={ClosedChatDetails}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.metrics}
        component={ChatMetrics}
        allowedPermissions={[PermissionRequest['read:chat-metrics']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.task.board}
        component={TicketBoard}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.list.default}
        component={TicketList}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.view, routes.ticket.list.view]}
        component={ViewTicket}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.objectsInfo}
        component={ObjectsInfo}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.create, routes.ticket.list.create]}
        component={CreateTicket}
        allowedPermissions={[PermissionRequest['create:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.edit, routes.ticket.list.edit]}
        component={EditTicket}
        allowedPermissions={[PermissionRequest['edit:ticket']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.contact.list}
        component={ContactList}
        allowedPermissions={[PermissionRequest['read:crm']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={[routes.crm.contact.add, routes.crm.contact.edit]}
        component={AddContact}
        allowedPermissions={[PermissionRequest['read:crm']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.contact.details}
        component={ContactDetails}
        allowedPermissions={[PermissionRequest['read:crm']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.merge.list}
        component={MergeContact}
        allowedPermissions={[PermissionRequest['merge:contacts']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.logs.list}
        component={Logs}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.list}
        component={ObjektList}
        allowedPermissions={[
          PermissionRequest['read:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.add}
        component={CreateApartment}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.details}
        component={ApartmentDetails}
        allowedPermissions={[
          PermissionRequest['read:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.season.list}
        component={SeasonList}
        allowedPermissions={[
          PermissionRequest['read:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={[routes.oms.season.add, routes.oms.season.edit]}
        component={AddSeason}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.list}
        component={ServiceList}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.add}
        component={AddService}
        allowedPermissions={[PermissionRequest['manage:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.edit}
        component={EditService}
        allowedPermissions={[PermissionRequest['manage:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.details}
        component={ServiceDetail}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.list}
        component={ServiceGroupList}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.add}
        component={AddServiceGroup}
        allowedPermissions={[PermissionRequest['manage:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.edit}
        component={EditServiceGroup}
        allowedPermissions={[PermissionRequest['manage:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.details}
        component={ServiceGroupDetail}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.checkInCheckOut.list}
        component={CheckInCheckOutList}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.list}
        component={BookingList}
        allowedPermissions={[
          PermissionRequest['read:bookings'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.details}
        component={BookingDetail}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.add}
        component={AddBooking}
        allowedPermissions={[PermissionRequest['manage:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.services}
        component={AddServiceBookings}
        allowedPermissions={[PermissionRequest['manage:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.edit}
        component={EditBooking}
        allowedPermissions={[PermissionRequest['manage:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.bookingCalendar.base}
        component={BookingCalendar}
        allowedPermissions={[
          PermissionRequest['read:bookings'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.invoices.list}
        component={InvoicesList}
        allowedPermissions={[
          PermissionRequest['read:invoice'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.list}
        component={Faqs}
        allowedPermissions={[PermissionRequest['read:faq']]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.add}
        component={AddFaq}
        allowedPermissions={[PermissionRequest['manage:faq']]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.edit}
        component={EditFaq}
        allowedPermissions={[PermissionRequest['manage:faq']]}
      />
      <RoleBasedRouting
        serviceName="internal-faq"
        exact
        path={routes.faq.internalfaq.list}
        component={InternalFaqList}
        allowedPermissions={[PermissionRequest['read:internal-faq']]}
      />
      <RoleBasedRouting
        serviceName="group-faq"
        exact
        path={routes.faq.faqGroup.list}
        component={GroupList}
        allowedPermissions={[PermissionRequest['read:faq-group']]}
      />
      <RoleBasedRouting
        serviceName="group-faq"
        exact
        path={[routes.faq.faqGroup.add, routes.faq.faqGroup.edit]}
        component={AddGroup}
        allowedPermissions={[PermissionRequest['manage:faq-group']]}
      />
      <RoleBasedRouting
        serviceName="general-list"
        exact
        path={routes.dms.generalList.list}
        component={GeneralList}
        allowedPermissions={[
          PermissionRequest['read:dms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="general-list"
        exact
        path={routes.dms.generalList.upload}
        component={CreateGeneralDocument}
        allowedPermissions={[PermissionRequest['manage:dms']]}
      />
      <RoleBasedRouting
        serviceName="general-list"
        exact
        path={routes.dms.generalList.edit}
        component={EditGeneral}
        allowedPermissions={[PermissionRequest['manage:dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.list}
        component={CustomersDocumentList}
        allowedPermissions={[PermissionRequest['read:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.upload}
        component={UploadCustomersDocument}
        allowedPermissions={[PermissionRequest['manage:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.edit}
        component={EditCustomersDocument}
        allowedPermissions={[PermissionRequest['manage:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.customer.list}
        component={CustomerList}
        allowedPermissions={[PermissionRequest['read:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.customer.upload}
        component={UploadCustomer}
        allowedPermissions={[PermissionRequest['manage:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.list}
        component={ObjectsDocumentList}
        allowedPermissions={[
          PermissionRequest['read:object-dms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.upload}
        component={UploadObjectsDocument}
        allowedPermissions={[PermissionRequest['manage:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.edit}
        component={EditObjectsDocument}
        allowedPermissions={[PermissionRequest['manage:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.objects.list}
        component={DMSObjectList}
        allowedPermissions={[
          PermissionRequest['read:object-dms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.objects.upload}
        component={UploadObject}
        allowedPermissions={[PermissionRequest['manage:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.list}
        component={OwnersDocumentList}
        allowedPermissions={[
          PermissionRequest['read:owner-dms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.upload}
        component={UploadOwnersDocument}
        allowedPermissions={[PermissionRequest['manage:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.edit}
        component={EditOwnersDocument}
        allowedPermissions={[PermissionRequest['manage:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.owner.list}
        component={OwnerList}
        allowedPermissions={[
          PermissionRequest['read:owner-dms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.owner.upload}
        component={UploadOwner}
        allowedPermissions={[PermissionRequest['manage:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.user.list}
        component={DMSUserList}
        allowedPermissions={[PermissionRequest['read:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.userDocuments.user.upload}
        component={UploadUser}
        allowedPermissions={[PermissionRequest['manage:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.list}
        component={UsersDocumentList}
        allowedPermissions={[PermissionRequest['read:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.upload}
        component={UploadUsersDocument}
        allowedPermissions={[PermissionRequest['manage:dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.edit}
        component={EditUsersDocument}
        allowedPermissions={[PermissionRequest['manage:dms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.creditCard.list}
        component={CreditCardList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.paypal.list}
        component={PaypalList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.paymentLinks.list}
        component={PaymentLinksList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.paymentLinks.add}
        component={CreatePaymentLink}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.bankTransfer.list}
        component={BankTransferList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pns-service"
        exact
        path={routes.pns.notification.list}
        component={PushNotificationList}
        allowedPermissions={[PermissionRequest['read:pns']]}
      />
      <RoleBasedRouting
        serviceName="pns-service"
        exact
        path={routes.pns.notification.add}
        component={AddPushNotification}
        allowedPermissions={[PermissionRequest['send:push-notificaton']]}
      />
      <RoleBasedRouting
        serviceName="contact-center-service"
        exact
        path={routes.contactCenter.members.list}
        component={ContactCenterUserList}
        allowedPermissions={[PermissionRequest['read:contact-center']]}
      />
      <RoleBasedRouting
        serviceName="contact-center-service"
        exact
        path={routes.contactCenter.members.profile}
        component={CallForUser}
        allowedPermissions={[PermissionRequest['read:contact-center']]}
      />
      <RoleBasedRouting
        serviceName="contact-center-service"
        exact
        path={routes.contactCenter.totalCalls.list}
        component={TotalCallsList}
        allowedPermissions={[PermissionRequest['read:contact-center']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.dashboard}
        component={FeedbackDashboard}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.analysis}
        component={FeedbackAnalysis}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.feedbacks.list}
        component={FeedbacksList}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.feedbacks.details}
        component={FeedbackDetails}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.mails.list}
        component={MailsList}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.questions.list}
        component={QuestionsList}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.questions.add}
        component={AddQuestion}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.questions.edit}
        component={EditQuestion}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.setting.list}
        component={SettingList}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.list}
        component={NewsletterList}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.add}
        component={ComposeMessage}
        allowedPermissions={[PermissionRequest['manage:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.edit}
        component={EditNewsLetter}
        allowedPermissions={[PermissionRequest['manage:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.draft.list}
        component={DraftList}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.view}
        component={ViewNewsletterDetails}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.KeyLogs.list}
        component={KeyLogs}
        allowedPermissions={[PermissionRequest['read:keylog']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.KeyLogs.view}
        component={LogInfo}
        allowedPermissions={[PermissionRequest['read:keylog']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.KeyLogs.keyHandle}
        component={KeyHandling}
        allowedPermissions={[PermissionRequest['read:keylog']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.list}
        component={KeyLists}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.view}
        component={KeyDetails}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.add}
        component={AddKey}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />

      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.edit}
        component={EditKey}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <Route exact path="*" component={() => <Redirect to={routes.splash} />} />
    </Switch>
  );
};

export default PrivateRouter;

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from '@chakra-ui/react';
import CustomSelect from 'components/common/CustomSelect';
import { strings } from 'config/localization';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const PAYMENT_LINK_BOOKING_SEARCH_API = '/bms/bookings';

const CreatePaymentLinkForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  const onBookingChange = (e: any) => {
    setValue('reservation_id', e);
  };

  return (
    <Grid
      gap="4"
      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
      w="100%">
      <GridItem>
        <FormControl isInvalid={!!errors?.bookingNumber}>
          <FormLabel>{strings.booking_number}</FormLabel>
          <Controller
            control={control}
            name="reservation_id"
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder={strings.booking_number}
                  value={field?.value}
                  onChange={onBookingChange}
                  SEARCH_API={PAYMENT_LINK_BOOKING_SEARCH_API}
                  limit={30}
                  replaceNameInOption="booking_number"
                />
              );
            }}
          />
          <FormErrorMessage>
            <>{errors?.bookingNumber && errors?.bookingNumber?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={!!errors?.email} isRequired>
          <FormLabel>{strings.email_address}</FormLabel>
          <Input
            type="email"
            placeholder={strings.email_address}
            {...register('email', {
              required: strings.required_email,
            })}
          />
          <FormErrorMessage>
            <>{errors?.email && errors?.email?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={!!errors?.amount} isRequired>
          <FormLabel>{strings.amount} (CHF)</FormLabel>
          <Input
            type="number"
            placeholder={strings.amount}
            {...register('amount', {
              required: strings.amount_is_required,
              min: {
                value: 0,
                message: strings.less_than_zero,
              },
            })}
          />
          <FormErrorMessage>
            <>{errors?.amount && errors?.amount?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={[1, 2]}>
        <FormControl isInvalid={!!errors?.message} isRequired>
          <FormLabel>{strings.message}</FormLabel>
          <Textarea
            placeholder={strings.message}
            {...register('message', {
              required: strings.required_message,
              validate: (value) => {
                if (value?.trim() === '') return strings.required_message;
              },
            })}
          />
          <FormErrorMessage>
            <>{errors?.message && errors?.message?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default CreatePaymentLinkForm;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import KeyLogAPI from 'api/keyLogs';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import GetKeyId from 'components/keys/GetKeyId';
import KeysListIterm from 'components/keys/KeysListIterm';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  KEY_APARTMENT_SEARCH_API,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useWordSearch from 'hooks/useWordSearch';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { BiBarcodeReader, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
  KeyOptionLocalization,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactSelectRoot from '../../../../components/ReactSelectRoot/ReactSelectRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  apartmentName: string;
  apartmentId: string | null;
  status: string[];
  transfer_date_from: Date | null;
  transfer_date_to: Date | null;
}
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const KeyLogs: React.FC = () => {
  const keyLogAPI = new KeyLogAPI();
  const statusRef = useRef<any>(null);
  const cancelRef = useRef(null);
  const history = useHistory();
  const KeyStatusOptions = KeyOptionLocalization();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkKeyLogsPermission = useIsPermitted([
    PermissionRequest['read:keylog'],
  ]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const [scannedKeyId, setScannedKeyId] = useState<null | string>(null);
  const searchValues: any = {};

  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.transfer_date_from
      ? new Date(searchValues.transfer_date_from)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.transfer_date_to
      ? new Date(searchValues.transfer_date_to)
      : null
  );
  const apartmentValue =
    searchValues.apartmentId && searchValues.apartmentName
      ? {
          value: searchValues.apartmentId,
          label: searchValues.apartmentName,
        }
      : null;

  const status_url: string[] = searchValues?.status?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    apartmentName: searchValues.apartmentName ?? null,
    apartmentId: searchValues.apartmentId ?? '',
    status: status_url ?? [],
    transfer_date_from: startDate,
    transfer_date_to: endDate,
  });
  const [apartmentInputKey, setApartmentInputKey] = useState<string>('');

  const status_value = filterParams?.status?.map((item: any) => {
    const newLabel = capitalizeFirstLetter(item);
    return {
      value: item,
      label: newLabel,
    };
  });

  const queryList = useQuery(
    [
      'keyLogsQuery',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        apartmentId: filterParams.apartmentId,
        transferDateFrom: filterParams.transfer_date_from,
        transferDateTo: filterParams.transfer_date_to,
        status: filterParams.status,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.apartmentId)
        queryParams.apartmentId = filterParams.apartmentId;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (startDate) queryParams.transferDateFrom = DateFormatYMD(startDate);
      if (endDate) queryParams.transferDateTo = DateFormatYMD(endDate);
      const response = await keyLogAPI.list(queryParams);
      return response?.data;
    }
  );

  const {
    data: keyLogsQuery,
    isLoading: keyLogsLoading,
    error: keyLogsQueryError,
  } = queryList;

  const {
    loading: apartmentListLoading,
    result: apartmentQuery,
    setQuery: setApartmentQuery,
  } = useWordSearch(`${KEY_APARTMENT_SEARCH_API}`);

  const apartmentList = apartmentQuery?.data;
  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
  }));

  useEffect(() => {
    if (scannedKeyId) {
      history.push(routes.key.KeyLogs.view.replace(':id', `${scannedKeyId}`));
    }
  }, [history, scannedKeyId]);

  const handleScan = () => {
    //scan logic here
    onOpen();
  };

  const handleCloseScan = () => {
    //if rfid placed then only close the dialog box.
    setScannedKeyId(null);
    onClose();
  };

  const handleApartmentInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setApartmentInputKey(value);
      setApartmentQuery({ keyword: value });
    }
  };

  const handleApartmentChange = (value: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      apartmentName: value.label,
      apartmentId: value.value,
    }));
    const data: any = {
      ...filterParams,
      apartmentName: value.label,
      apartmentId: value.value,
    };
    setSearchURL(data);
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      transfer_date_from: DateFormatYMD(start),
      transfer_date_to: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
    setSearchURL(newFilter);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));

    const data: any = {
      ...filterParams,
      [name]: value,
    };
    setSearchURL(data);
  };

  const handleStatusChange = (status: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      status: status.map((item: any) => item.value),
    }));
    const data: any = {
      ...filterParams,
      status: status.map((item: any) => item.value),
    };
    setSearchURL(data);
  };

  const setSearchURL = (filterQuery: any) => {
    let data = { ...filterQuery };

    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      apartmentId: '',
      status: [],
      transfer_date_from: null,
      transfer_date_to: null,
    }));
    statusRef.current.select.clearValue();
    history.push(routes.key.KeyLogs.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      {checkKeyLogsPermission && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={handleCloseScan}
          isOpen={isOpen}
          isCentered>
          <AlertDialogOverlay />

          <GetKeyId
            setScannedKeyId={setScannedKeyId}
            onClose={onClose}
            databaseCheck={true}
          />
        </AlertDialog>
      )}
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_logs}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.all_key_logs}
          </Heading>

          {checkKeyLogsPermission && (
            <Box
              as={Button}
              size="lg"
              onClick={handleScan}
              variant="solid"
              rounded="sm"
              colorScheme="primary"
              aria-label="key_scan"
              leftIcon={<Icon as={BiBarcodeReader} w="4" h="4" />}
              _hover={{ variant: 'solid', bg: 'primary', color: 'white' }}>
              {strings.scan_key}
            </Box>
          )}
        </Flex>
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form>
                  <Stack direction={['column']} spacing="4">
                    <Grid
                      gap="4"
                      templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.search_customer}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BiSearch />}
                              color="gray.400"
                            />
                            <Input
                              type="text"
                              name="keyword"
                              value={filterParams.keyword}
                              onChange={handleInputChange}
                              size="lg"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.object}</FormLabel>
                          <ReactSelectRoot
                            id="apartmentId"
                            name="apartmentId"
                            placeholder={strings.select_apartment}
                            options={apartmentOptions}
                            value={apartmentValue}
                            styles={reactSelectStyles}
                            onInputChange={handleApartmentInputChange}
                            inputValue={apartmentInputKey}
                            isLoading={apartmentListLoading}
                            onChange={handleApartmentChange}
                            isSearchable
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.status}</FormLabel>
                          <ReactSelectRoot
                            name="status"
                            placeholder={strings.status}
                            closeMenuOnSelect={false}
                            options={KeyStatusOptions}
                            ref={statusRef}
                            isMulti
                            defaultValue={status_value}
                            onChange={handleStatusChange}
                            styles={reactSelectStyles}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>

                    <Stack>
                      <Flex justify="right">
                        <Button
                          colorScheme="primary"
                          variant="outline"
                          onClick={handleReset}
                          size="lg">
                          {strings.reset_filter}
                        </Button>
                      </Flex>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.external_company}</Th>
                  <Th>{strings.date_time}</Th>
                  <Th>{strings.user}</Th>
                  <Th>{strings.key_id}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {keyLogsQuery?.data.map((keyLogItem: any, index: number) => (
                  <KeysListIterm
                    keyLogItem={keyLogItem}
                    key={keyLogItem.id}
                    index={startingSN + index}
                    search={search}
                  />
                ))}
                {keyLogsLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default KeyLogs;

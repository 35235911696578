import { Lang } from './types';

const en: Lang = {
  /*login Credential*/
  login: 'Login',
  login_now: 'Login',
  log_in: 'Log In',
  password: 'Password',
  forgot_your_password: 'Forgot your password',
  email: 'Email',
  your_email_address: 'Your Email Address',
  your_password: 'Your Password',
  remember_me: 'Remember Me',
  forgot_password: 'Forgot Password?',
  forgot_password1: 'Forgot Password',
  back_to_login: 'Back to Login',
  password_reset: 'Request Password Reset',
  login_message: 'Enter your credentials to login in to MYVAL AG.',
  forgot_message: 'Please enter the email you use to sign in to your account.',
  myval_ag: 'MYVAL AG',
  password_info:
    'Password must have at-least 1 number, 1 special character, 1 capital letter and 5 others characters.',
  invalid_login_msg: 'Email or password did not match.',
  user_delete_error: 'User cannot be deleted User is holding MMS Stock',
  change_language: 'Change Language',
  invalid_token: 'This password reset token is invalid.',
  two_factor_authentication: 'Two Factor Authentication',
  enter_the_pin_from_google_authentication:
    'Enter the code from Google Authentication.',
  authenticate: 'Authenticate',
  one_time_password: 'One Time Password',
  FA_status: '2FA Status',
  disable_2FA: 'Disable 2FA',
  enable_2FA: 'Enable 2FA',
  fa_message:
    'To enable two-factor authentication, please scan this QR code with the Google Authenticator app.',
  code: 'Code',
  fa_status_tooltip: 'Generate secret key to enable 2FA',
  authenticator_code: 'Authenticator Code',
  FA_successfully: '2FA is Enable Successfully',
  FA_enable: '2FA is Currently Enabled for your account',
  fa_Disable: '2FA is now Disable',
  authenticator_code_error: 'Authenticator Code Error',
  required_authenticator_code: 'Required Authenticator Code',
  fa_disable_title:
    'If you are looking to disable two factor authentication. Please confirm your password and click Disable 2FA button.',
  activate_2FA: 'Activate 2FA',
  deactivate_2FA: 'Deactivate 2FA',

  /*button*/
  save: 'Save',
  save_now: 'Save Now',
  filter: 'Filter',
  back: 'Back',
  editing: 'Editing',
  apply_filter: 'Apply Filter',
  reset_filter: 'Reset Filter',
  more_filter: 'More Filter',
  cancel: 'Cancel',
  clear: 'Clear',
  reply: 'Reply',
  comment: 'Comment',
  save_and_create_new_ticket: 'Save and Create New Ticket',
  confirm_and_close: 'Confirm and Close',
  confirm_and_transfer_another_items: 'Confirm and Transfer Another Items',
  edit_article: 'Edit Article',
  confirm: 'Confirm',
  profile: 'Profile',
  my_profile: 'My Profile',
  edit_profile: 'Edit Profile',
  profile_updated: 'Profile Updated.',
  profle_update_failed: 'Profile Update Failed.',
  password_updated: 'Password Updated.',
  not_match_new_confirm_password: 'The passwords do not match.',
  incorrect_password: 'Password is not correct.',
  setting: 'Setting',
  settings: 'Settings',
  logout: 'Logout',
  loggedOut: 'Logout',
  customer_screen: 'Customer Screen',
  change_password: 'Change Password',
  change_now: 'Change Now',
  Current_password: 'Current Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  invalid_current_password: 'Current password is incorrect.',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  less_than_zero: 'Value less than zero.',
  email_not_found: 'Email address not found.',
  email_required: 'Email address is required',
  now: 'now',
  secret_key: 'Secret Key',
  create_pair: 'Create Pair',
  must_be_8_characters_long: 'Must be 8 characters long',
  generate: 'Generate',
  unpair: 'Unpair',
  this_will_unpair_the_device_are_you_sure:
    'This will unpair the device. Are you sure?',
  clear_second_screen: 'Clear second screen',
  successfully_unpaired: 'Sucessfully unpaired',
  pair_created: 'Pair created',

  /*Module*/
  user_management: 'User Management',
  chat: 'Chats',
  chat_overview: 'Chat Overview',
  ticket: 'Tickets',
  crm: 'CRM',
  contact_management: 'Contact Management',
  contact_center: 'Contact Center',
  feedback: 'Feedbacks',
  mms: 'MMS',
  dashboard: 'Dashboard',
  /*Splash Screen*/
  choose_subsystem: 'Choose Subsystem',
  /*User Management*/
  user_management_system: 'User Management System',
  user: 'Users',
  user_list: 'User Lists',
  user_rights: 'User Rights',
  user_roles: 'User Roles',
  user_name: 'User Name',
  user_profile: 'User Profile',
  user_details: 'User Details',
  role_details: 'Role Details',
  role: 'Role',
  add_additional_role: 'Add Additional Role',
  add_additional_rights: 'Add Additional Rights',
  additional_rights: 'Additional Rights',
  manage_extra_rights: 'Manage extra rights',
  right_from_role: 'Rights From Role',
  add_role: 'Add Role',
  role_setup: 'Role Setup',
  system: 'System',
  role_name: 'Role Name',
  privillage: 'Privillage',
  add_user: 'Add User',
  all_users: 'All Users',
  all_user_with_that_role: 'All user with that role',
  add_new_user: 'Add New User',
  add_new_role: 'Add New Role',
  view_user: 'View User',
  edit_user: 'Edit User',
  delete_user: 'Delete User',
  save_user: 'Save User',
  user_deatils: 'User Details',
  upload_image: 'Upload Image',
  drop_image_or_click_to_select_image: 'Drop Image or click to select Image',
  assigned_tickets: 'Assigned Tickets',
  action: 'Action',
  first_name: 'First Name',
  last_name: 'Last Name',
  email_address: 'Email Address',
  valid_email_address: 'Please provide a valid email address.',
  contact_info: 'Contact Info',
  address: 'Address',
  view: 'View',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',
  new_user: 'New User',
  has_been_assigned: 'has been assigned to you',
  has_been_transferred: 'has been transferred to you',
  login_with_new_password: 'Please login with new password',
  msg_valid_password: 'Please provide valid password.',
  msg_password_reset_link: 'We have emailed your password reset link!',
  select_role: 'Select Role',
  search_username: 'Search Username',
  rights: 'Rights',
  reset_password: 'Reset Password',
  reset_password_subtitle:
    'Please enter the fields you use to reset to your account.',
  save_reset_password: 'Save Reset Password',
  create_password: 'Create Password',
  create_password_subtitle:
    'Please enter the fields to create a password for your account.',
  save_password: 'Save Password',
  password_helper_text:
    'Minimum 8 chars, at least one uppercase, one lowercase, one number and one special char.',
  resend_verification_email: 'Resend verification email',
  verification_email_sent: 'Verification email sent',
  email_verification_success: 'Email verified successfully.',
  email_verification_failed: 'Email verification failed.',
  resend_verify_email_error: 'Error resending email verification.',
  resend_verify_email_success:
    'Verification email has been sent successfully. Check your inbox.',
  resend_verify_email_success_to:
    'Verification email has been sent successfully to',
  email_already_verified: 'Email is already verified.',
  login_to_continue: 'Please Login to continue.',
  create_password_to_proceed: 'Create a password to proceed.',
  change_confirm: 'Are you sure you want to change this ?',
  cellphone: 'Cellphone',
  invalid_cellphone: 'Invalid Cellphone',
  email_placeholder: 'youremail@example.com',
  admin: 'Admin',
  chat_supporter: 'Chat Supporter',
  ticket_supporter: 'Ticket Supporter',
  mms_employee: 'MMS Employee',
  verified: 'Verified',
  not_yet_verified: 'Not yet verified',
  external_companies: 'External Companies',
  external_company: 'External Company',
  company_name: 'Company Name',
  add_new_company: 'Add New Company',
  company_created: 'Company has been created.',
  company_name_is_required: 'Company name is required.',
  company_email_is_required: 'Company email is required.',
  please_enter_a_valid_phone_number: 'Please enter a valid phone number.',
  enter_a_country_code_at_the_start: 'Enter a country code at the start.',
  company_creation_failed: 'Company creation failed.',
  the_email_has_already_been_taken: 'The email has already been taken.',
  edit_company: 'Edit Company',
  company_updated: 'Company updated successfully.',
  company_udpate_failed: 'Company update failed.',
  delete_company: 'Delete Company',
  company_deleted: 'Company has been deleted.',
  company_delete_error: 'Company cannot be deleted',
  company_not_found: 'Company not found.',
  company_profile: 'Company Profile',
  all_companies: 'All Companies',
  company_list: 'Company Lists',
  companies: 'Companies',
  view_company: 'View Company',
  all_roles: 'All Roles',
  user_login_log: 'User Login Logs',
  all_login_logs: 'All Login Logs',
  access_user_log: 'Access Login Logs',
  blocked: 'Blocked',

  /*Chat System*/
  chat_system: 'Chat System',
  closed_chat: 'Closed Chats',
  all_closed_chats: 'All Closed Chats',
  chat_details: 'Chat Details',
  closed_chat_details: 'Closed Chat Details',
  archived_chat: 'Archived Chats',
  all_archived_chats: 'All Archived Chats',
  archived_chat_details: 'Archived Chat Details',
  chat_metrics: 'Chat Metrics',
  all_chat_metrics: 'All Chat Metrics',
  sn: '#',
  today: 'Today',
  hours: 'hours',
  id: 'ID',
  customer_name: 'Customer Name',
  employee_name: 'Employee Name',
  closed_at: 'Closed At',
  last_message: 'Last Message',
  last_chat_message: 'Last Chat Message',
  actions: 'Action',
  no_of_chat: 'No. of Chat',
  total_chats: 'Total chats',
  no_of_active_chat: 'Active Chats',
  no_of_message: 'No. of Message',
  no_messages_received: 'No. Messages Received',
  total_messages: 'Total Messages',
  no_messages_send: 'No. Messages Send',
  sent_messages: 'Sent Messages',
  time_spent_in_call: 'Time Spent In Call',
  talk_time: 'Talk Time',
  role_descripition: 'Role Descripition',
  name: 'Name',
  assignee: 'Assignee',
  description: 'Description',
  transfer_chat: 'Transfer Chat',
  chat_forwarding: 'Chat Forwarding',
  no_active_chat_history: 'No active chat history',
  forward_the_chat_to_a_free_employee: 'Forward the chat to a free employee.',
  forward_chat: 'Forward chat',
  select_employee: 'Select employee',
  select_user: 'Select User',
  transfer_closed_chat: 'Close Chat?',
  transfer_archived_chat: 'Archive Chat',
  transfer_modal_closed_message: 'Chat will be closed.',
  transfer_modal_archived_message: 'Are you sure you want archived this ?',
  yes: 'Yes',
  no: 'No',
  call: 'Call',
  miss_called: 'User missed the call.',
  active: 'Active',
  in_active: 'Inactive',
  select_apartment: 'Select Apartment',
  select_assignee: 'Select Assignee',
  select_customer: 'Select Customer',
  select_cost_recepient: 'Select Cost Recepient',
  select_house_owner: 'Select House Owner',
  select_priority: 'Select Priority',
  select_source: 'Select Source',
  write_description: 'Write Description',
  call_ended: 'Call ended',
  message_not_sent: 'You message could not be sent',
  join_room_error: 'Could not join the room at the moment',
  make_call_error: 'Could not make call at the moment',
  cancel_call_error: 'Could not cancel call at the moment',
  chat_not_found: 'There is no chat in your feed.',
  chat_not_selected: 'No chat selected!',
  chat_active_toggle_tooltip_message:
    'New chats can be assigned or transferred to you if active. While you are in a call, the toggle will be set to inactive automatically.',
  chat_users_not_available_for_transferral:
    'There is no other user available right now.',
  call_in_progress: 'Call in progress.',
  click_to_cancel: 'Click again to cancel.',
  call_cancelled: 'Call cancelled.',
  make_call: 'Make a phone call',
  chat_active_info: 'Chat Active Info',
  no_chat_assigned: 'There is no chat assigned to you at the moment.',
  is_typing: 'is typing...',

  /*Ticket Board*/
  ticket_system: 'Ticket System',
  all_tickets: 'All Tickets',
  ticket_board: 'Ticket Boards',
  add_task: 'Add Task',
  open: 'Open',
  inprogress: 'In Progress',
  done: 'Done',
  closed: 'Closed',
  document: 'Document',
  recursive_task: 'Recursive Task',
  normal_task: 'Normal Task',
  create_ticket: 'Create Ticket',
  ticket_details: 'Ticket Details',
  ticket_title: 'Ticket Title',
  object: 'Object',
  all_objects: 'All Objects',
  customer: 'Customer',
  cost_recipient: 'Cost Recepient',
  house_owner: 'House Owner',
  priority_label: 'Priority Label',
  priority: 'Priority',
  upload_files: 'Upload Files',
  descriptions: 'Descriptions',
  save_create_new_ticket: 'Save and Create New Ticket',
  author: 'Author',
  created_by: 'Created By',
  created_at: 'Created At',
  last_modified_by: 'Last Modified By',
  last_modified_at: 'Last modified At',
  last_changed_by: 'Last Change By',
  last_changed_at: 'Last Change At',
  status: 'Status',
  ticket_log: 'Log',
  ticket_list: 'Ticket Lists',
  repeat_task: 'Repeat Task',
  recursive_interval: 'Recursive Interval',
  recursive_untill: 'Recursive Untill',
  recursive_start: 'Recursive Start',
  apartment: 'Apartment',
  apartments: 'Apartments',
  ticket_created: 'Ticket has been created.',
  has_been_created: 'has been created.',
  has_not_been_created: 'has not been created.',
  has_been_merged: 'has been merged',
  has_not_been_merged: 'has not been merged',
  has_been_updated: 'has been updated.',
  has_not_been_updated: 'has not been updated.',
  has_been_deleted: 'has been deleted.',
  has_not_been_deleted: 'has not been deleted.',
  ticket_error: 'Ticket has not been created.',
  expand: 'Expand',
  ticket_list_error: 'There was an error processing your request.',
  select_recursive_interval: 'Select Recursive Interval',
  title_required: 'Title is required.',
  repeating_info: 'Repeating Info',
  interval: 'Interval',
  repeat_from: 'Repeat From',
  repeat_until: 'Repeat Until',
  validate_recursive_date: 'Please enter valid recursive date.',
  validate_recursive_until: 'Please enter date later than Repeat From',
  comment_here: 'Comment Here...',
  comment_required: 'Comment is required.',
  id_not_found: 'ID not found.',
  edit_ticket: 'Edit Ticket',
  ticket_updated: 'Ticket updated successfully',
  change_status: 'Change Status',
  status_updated: 'Status updated successfully',
  select_status: 'Select Status',
  comment_error: 'Comment error',
  view_more: 'View More',
  view_less: 'View Less',
  billability: 'Billability',
  billable: 'Billable',
  spent_time: 'Spent Time',
  adjust_time_spent: 'Adjust Time Spent',
  correct_spent_time: 'Correct Spent Time',
  spent_time_message: 'The spent time has been correct manually.',
  task: 'Task',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  urgent: 'Urgent',
  normal: 'Normal',
  repeating: 'Repeating',
  select_type: 'Select Type',
  select_type_art: 'Select Type',
  ticket_detail: 'Ticket Detail',
  edit_task: 'Edit Task',
  created_ticket: 'created ticket',
  updated_ticket: 'updated ticket',
  changed_status: 'changed status',
  assigned_ticket: 'assigned ticket',
  commented: 'commented',
  order_by: 'Order By',
  createdat: 'Created At',
  order: 'Order',
  asc: 'Ascending',
  desc: 'Descending',
  select_sort: 'Select Sort',
  select_order: 'Select Order',
  ticket_name: 'Name ',
  select_external_company: 'Select External Company',
  showing_all_customers: 'Showing all customers',
  showing_all_options: 'Showing all options',
  appointment: 'Appointment',
  appointment_date_time: 'Appointment date and time',
  enter_appointment_date_time: 'Enter appointment date time',
  full_screen: 'Fullscreen',

  /*CRM*/
  customer_relation_management: 'Customer Reletion Management',
  contact_list: 'Contact Lists',
  all_contacts: 'All Contacts',
  merge_list: 'Merge Lists',
  add_contact: 'Add Contact',
  label: 'Label',
  source: 'Source',
  destination: 'Destination',
  added: 'Added',
  last_activities: 'Last Activities',
  last_ticket: 'Last Ticket',
  street: 'Street',
  house_no: 'House No.',
  post_code: 'Post Code',
  town: 'Town',
  country: 'Country',
  phone: 'Phone No.',
  mobile: 'Mobile No.',
  company: 'Company',
  fellow_travelers: 'Fellow Travelers',
  pets: 'Pets',
  equipment_features: 'Equipment Features',
  services: 'Services',
  service: 'Service',
  notes_external: 'Notes External',
  notes_internal: 'Notes Internal',
  add_now: 'Add Now',
  added_on: 'Added On',
  edit_contact: 'Edit Contact',
  event_history: 'Event History',
  chanel: 'Chanel',
  date_time: 'Date/Time',
  contact_details: 'Contact Details',
  merge: 'Merge',
  unmerge: 'Unmerge',
  updated: 'Updated',
  city: 'City',
  select_channel: 'Select Channel',
  merge_contact: 'Merge Contacts',
  merge_contact_sidebar: 'Merge Contact',
  select_contact: 'Select Contact',
  logs: 'Logs',
  merged_date: 'Merged Date',
  updated_date: 'Updated Date',
  uploaded_by: 'Uploaded By',
  file: 'File',
  image: 'Image',
  images: 'Images',
  progress_status: 'Progress Status',
  upload_file: 'Upload File',
  continue: 'Continue',
  view_contact: 'View Contact',
  delete_contact: 'Delete Contact',
  merge_detail: ' Contact Merge Detail Information.',
  merge_preview: 'Merge Preview',
  merge_contact_is_permanent:
    'Merging contacts is permanent, this action cannot be revert.',
  janitor: 'Janitor',
  support: 'Support',
  add_new_guest: 'Add New Guest',
  add_new_owner: 'Add New Owner',
  owners_are_not_allowed_to_be_merged: 'Owners are not allowed to be merged',
  iban: 'IBAN',
  swift_bic: 'Swift/BIC',
  contact_with_bookings_cannot_be_merged:
    'Contact with bookings cannot be merged',
  bank_connection: 'Bank Connection',
  institute: 'Institute',
  sales_tax_id_no: 'Sales Tax ID No.',
  create_user: 'Create user',
  user_already_exists: 'User Already Exists',
  create: 'Create',
  this_will_create_a_user_for_the_owner_are_you_sure_you_want_to_continue:
    'This will create a user for the owner. Are you sure you want to continue?',

  /*Contact Center*/
  total_calls: 'Total Calls',
  aircall_user_name: 'Aircall User Name',
  total_incoming_call: 'Total Incoming Call',
  total_call_duration: 'Total call duration',
  total_outgoing_call: 'Total Outgoing Call',
  outgoing_call_duration: 'Outgoing Call Duration',
  average: 'Average',
  total_duration: 'Total Duration',
  internal_phone_number: 'Internal Phone Number',

  /*Feedback*/
  feedback_management: 'Feedback Management',
  feedback_system: 'Feedback System',
  access_feedback: 'Manage Feedback and Mail ',
  access_feedback_questions: 'Manage Questions and Settings',
  access_feedback_resend: 'Access Resend Feedback',
  all_feedbacks: 'All Feedbacks',
  feedback_lists: 'Feedback Lists',
  mails: 'Mails',
  all_mails: 'All Mails',
  mail_lists: 'Mail Lists',
  questions: 'Questions',
  all_questions: 'All Questions',
  question_lists: 'Question Lists',
  date: 'Date',
  closed_ended: 'Closed Ended',
  open_ended: 'Open Ended',
  rating: 'Rating',
  time_period: 'Time Period',
  feedback_detail: 'Feedback Detail',
  mark_as_done: 'Mark as Done',
  publish_feedback: 'Publish Feedback',
  publish_feedback_on_object_page: 'Publish Feedback on Object Page',
  do_not_publish_feedback: 'Do Not Publish Feedback',
  do_not_publish_feedback_on_object_page:
    'Do Not Publish Feedback on Object Page',
  use_for_score_calculation: 'Use for Score Calculation',
  do_not_use_for_score_calculation: 'Do Not Use for Score Calculation',
  contact_customer: 'Contact Customer',
  contact_house_owner: 'Contact House Owner',
  send_mail: 'Resend Mail',
  new_question: 'New Question',
  add_new_question: 'Add New Question',
  edit_question: 'Edit Question',
  add_question: 'Add Question',
  delete_question: 'Delete Question',
  edit_feedback: 'Feedback Detail',
  view_feedback: 'View Feedback',
  feedback_question: 'Feedback question',
  feedback_question_deleted_successfully:
    'Feedback question deleted successfully',
  sent: 'Sent',
  dispatched: 'Dispatched',
  received: 'Received',
  feedback_mail_sent_sucessfully: 'Feedback mail sent successfully',
  feedback_mail_not_sent: 'Feedback mail not sent',
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    'How many days after departure should the feedback mail be sent?',
  select_days: 'Select Days',
  days: 'Days',
  feedback_received: 'Feedback Received',
  question: 'Question',
  answers: 'Answers',
  response: 'Response',
  the_rating_is_out_of_10: 'The rating is out of 10',
  absolutely: 'Absolutely',
  not_at_all: 'Not at all',
  this_is_closed_ended_question: 'This is yes/no question',
  this_is_open_ended_question: 'This is open ended question',
  feedback_has_been_published_successfully:
    'Feedback has been published successfully',
  feedback_publish_error: 'Feedback publish error',
  published_feedback_on_object_page_successfully:
    'Published feedback on object page successfully',
  publish_feedback_on_object_page_error:
    'Published feedback on object page error',
  feedback_has_been_unpublished_successfully:
    'Feedback has been unpublished successfully',
  feedback_unpublish_error: 'Feedback unpublish error',
  feedback_has_been_marked_as_done_successfully:
    'Feedback has been marked as done successfully',
  feedback_mark_as_done_error: 'Feedback mark as done error',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'How would you rate your holiday in List on Sylt and the service provided by the Clausen family as a whole?',
  how_satisfied_were_you_with_your_stay_in_our_appartment:
    'How satisfied were you with your stay in our apartment?',
  publish_status: 'Publish Status',
  published_on_website: 'Published on Website',
  published_on_object_detail_page: 'Published on Object Detail Page',
  score_calculation: 'Score Calculation',
  feedback_on_object_page_has_been_unpublished_successfully:
    'Feedback on Object Page has been Unpublished Successfully',
  feedback_on_object_page_unpublish_error:
    'Feedback on Object Page Unpublish Error',
  score_calculation_used: 'Score Calculation Used',
  score_calculation_use_error: 'Score Calculation Use Error',
  score_calculation_unused: 'Score Calculation Unused',
  score_calculation_unuse_error: 'Score Calculation Unuse Error',
  object_publish: 'Object Published',
  website_publish: 'Website Published',
  not_publish: 'Not Published',
  score_total: 'Score Total',
  answer_rate: 'Answer Rate',
  public_score_calculation: 'Public Score Calculation',
  real_score: 'Real Score',
  public_score: 'Public Score',
  detail_reviews_general: 'Detail-Reviews General',
  detail_review_object_page: 'Detail-Review Object Page',
  reviews_general: 'Reviews General',
  reviews_object_page: 'Reviews Object Page',
  score_general: 'Score General',
  score_objects: 'Score Objects',
  no_mail: 'No E-mail',
  no_email: 'No E-mail',
  analysis: 'Analysis',
  average_rating: 'Average Rating.',
  percentage_of_closed_ended: 'Percentage of Yes / No.',
  percentage_of_open_ended:
    'Percentage of customers written feedback / not written feedback.',
  feedback_analysis: 'Feedback Analysis',
  delete_mail: 'Delete Mail',
  feedback_mail_deleted_successfully: 'Feedback Mail Deleted Successfully',
  feedback_analysis_detail: 'Feedback_Analysis_Detail',
  text_search: 'Text Search',
  feedback_response_detail: 'Feedback_Response_Detail',
  resend_feedback: 'Resend Feedback',
  resent: 'Resent',
  feedback_deleted_successfully: 'Feedback Deleted Successfully',
  delete_feedback: 'Delete Feedback',

  /*Inventory*/
  inventory: 'Inventory',
  inventories: 'Inventories',
  all_inventory: 'All Inventories',
  inventory_name: 'Inventory Name',
  used_item: 'Used Item',
  quantity: 'Quantity',
  quantity_anzahl: 'Quantity',
  total_quantity: 'Total Quantity',
  from: 'From',
  to: 'To',
  add_item: 'Add Item',
  deselect_all: 'Deselect All',

  /*MMS*/
  merchandise_management_system: 'Merchandise Management System',
  transfer_item: 'Transfer Items',
  all_transfer_item: 'All Transfer Items',
  all_transfer_logs: 'All Transfer Logs',
  select_articles: 'Select Articles',
  search_article: 'Search Article',
  stock_type: 'Stock Type',
  units: 'Units',
  unit: 'Unit',
  all_units: 'All Units',
  unit_details: 'Unit Details',
  select_source_warehouse: 'Select Source Warehouse',
  rooms: 'Rooms',
  room: 'Room',
  delete_room: 'Delete Room',
  add_room: 'Add Room',
  edit_room: 'Edit Room',
  room_name: 'Room Name',
  enter_room_name: 'Enter Room Name',
  racks: 'Racks',
  add_rack: 'Add Rack',
  rack: 'Rack',
  delete_rack: 'Delete Rack',
  shelf: 'Shelf',
  add_shelf: 'Add Shelf',
  delete_shelf: 'Delete Shelf',
  shelves: 'Shelves',
  select_destination_warehouse: 'Select Destination Warehouse',
  warehouse: 'Warehouse',
  warehouse_name: 'Warehouse Name',
  warehouses: 'Warehouses',
  all_warehouses: 'All Warehouses',
  add_warehouse: 'Add Warehouse',
  edit_warehouse: 'Edit Warehouse',
  delete_warehouse: 'Delete Warehouse',
  warehouse_details: 'Warehouse Details',
  warehouse_information: 'Warehouse Information',
  warehouse_not_found: 'Warehouse Not Found',
  shelf_delete_error: 'There was an error deleting the shelf.',
  rack_delete_error: 'There was an error deleting the rack.',
  room_delete_error: 'There was an error deleting the room.',
  articles: 'Articles',
  article: 'Article',
  all_articles: 'All Articles',
  add_article: 'Add Article',
  articles_names: 'Articles Names',
  article_name: 'Articles Name',
  upload_article_picture: 'Upload Article Picture',
  article_image: 'Article Image',
  type: 'Type',
  types: 'Types',
  all_types: 'All Types',
  type_details: 'Type Details',
  buying_price: 'Buying Price',
  selling_price: 'Selling Price',
  seller: 'Seller',
  seller_home_page_shop: 'Seller Home Page/Shop',
  minimum_stock: 'Minimum Stock',
  maximum_stock: 'Maximum Stock',
  out_of_stock: 'Out of Stock',
  minimum_stock_notification: 'Minimum Stock Notification',
  ean_number: 'EAN Number',
  notes: 'Notes',
  article_details: 'Article Details',
  objects: 'Objects',
  object_id: 'Object ID',
  object_name: 'Object Name',
  booking: 'Booking',
  tickets: 'Tickets',
  inventory_load: 'Invenory Load',
  object_details: 'Object Details',
  ticket_open_inprogress_done: 'Ticket (Open / Inprogress / Done)',
  abbreviation: 'Abbreviation',
  street_number: 'Street Number',
  is_active: 'Is Active',
  type_name: 'Type Name',
  add_type: 'Add Type',
  unit_name: 'Unit Name',
  add_unit: 'Add Unit',
  edit_unit: 'Edit Unit',
  transfer_log: 'Transfer Log',
  transfer_logs: 'Transfer Logs',
  my_transfer_log: 'My Log',
  my_transfer_logs: 'My Logs',
  all_my_logs: 'All Logs',
  ticket_source: 'Ticket Source',
  done_by: 'Done By',
  required: 'Required',
  user_created: 'User has been created',
  user_creation_failed: 'User creation failed.',
  user_udpate_failed: 'User update failed.',
  password_update_failed: 'Password update failed.',
  user_not_found: 'User not found',
  update: 'Update',
  user_updated: 'User updated successfully',
  user_deleted: 'User has been deleted.',
  roles_not_found: 'Roles not found',
  confirm_delete: 'Are you sure you want to delete this ?',
  edit_type: 'Edit Type',
  select: 'Select',
  min_stock_notification_tooltip:
    'You will be notified when the number of minimum stock is reached.',
  valid_data: 'Please enter a valid data.',
  valid_only_number: 'Please enters only numbers.',
  min_stock_vaidation_msg: 'Value should be less than maximum stock.',
  max_stock_vaidation_msg: 'Value should be greater than min stock.',
  phone_call: 'Phone Call',
  collapse_sidebar: 'Collapse Sidebar',
  expand_sidebar: 'Expand Sidebar',
  article_type: 'Select Type',
  article_unit: 'Select Unit',
  max_upload_size_5: 'Maximum upload attachments size upto 5 MB.',
  max_upload_size_10: 'Maximum upload attachments size upto 10 MB.',
  max_upload_size_15: 'Maximum upload attachments size upto 15 MB.',
  max_characters_exceeded: 'Maximum character limit exceeded',
  selected_files: 'Selected Files',
  drag_drop_files_msg:
    'Drag & Drop some files here, or click to select files. Upload files upto 5MB.',
  files: 'Files',
  delete_file: 'Delete File',
  only_1_file_is_allowed: 'Only 1 file is allowed.',
  access_user: 'Access User',
  manage_role: 'Manage Role',
  manage_user: 'Manage User',
  access_external_companies: 'Access External Companies',
  manage_external_companies: 'Manage External Companies',
  access_chat: 'Access Chat',
  access_closed_chat: 'Access Closed Chat',
  access_archived_chat: 'Access Archived Chat',
  access_chat_metrics: 'Access Chat Metrics',
  access_chat_assign: 'Access Chat Assign',
  access_chat_mediasoup: 'Access Chat Mediasoup',
  access_ticket: 'Access Ticket',
  access_mms: 'Access MMS',
  can_hold_item: 'Can Hold Item',
  manage_article: 'Manage Article',
  stocktaking_mode: 'Stocktaking Mode',
  stocktaking: 'Stocktaking',
  upload_new: 'Upload New',
  remove: 'Remove',
  uploading: 'Uploading',
  invalid_profile_upload: 'Invalid Profile Upload',
  file_must_be_an_image_file: 'File must be an image type.',
  clear_profile_pic: 'Clear Profile Image',
  are_you_sure: "Are you sure? You can't undo this action afterwards.",
  upload_label: 'Upload Profile',
  remove_label: 'Remove Profile',
  invalid_file_size: 'Invalid file size',
  file_size_info: 'File size must be less than 5MB',
  uploading_profile: 'Uploading Profile',
  no_permission_msg:
    'You do not have permission to access it. Please contact administrator.',
  transfer_date: 'Transfer Date',
  transfer_source: 'Transfer Source',
  transfer_by: 'Transfer By',
  transfer_quantity: 'Transfer Quantity',
  category: 'Category',
  select_category: 'Select Category',
  house_number: 'House number',
  object_image: 'Object Image',
  house: 'House',
  flat: 'Flat',
  new: 'New',
  fresh: 'Fresh',
  consumed: 'Consumed',
  employee: 'Employee',
  transfer_item_success: 'Transfer item successful.',
  transfer_item_failed: 'Transfer item failed.',
  none: 'None',
  reconciled: 'Reconciled',
  web_ui: 'Web UI',
  app: 'App',
  select_article_and_quantity_first:
    'Please select article and quantity first.',
  min_stock_reached: 'Min Stock Reached',
  min_stock_reached_description:
    'This article has reached the minimum stock which was set to ',
  max_stock_reached: 'Max Stock Reached',
  max_stock_reached_description:
    'This article has reached the maximum stock which was set to ',
  stocktaking_mode_message: ' Really enter Stocktaking mode.',
  my_inventory: 'My Inventories',
  all_my_inventories: 'All Inventories',
  transfer: 'Transfer',
  max_min_stock: 'Max-/Min-Stock',
  item: 'Item',
  inventory_detail: 'Inventory Details',

  // common
  lang: 'en',
  english: 'English',
  german: 'German',
  polish: 'Polish',
  french: 'French',
  italian: 'Italian',
  result: 'Result',
  no_results_found: 'No results found.',
  no_records_found: 'No records found',
  error: 'Error',
  no_options: ' No Options',
  showing: 'Showing',
  manually: 'Manually',
  general: 'General',
  gotoDetails: 'Go to Details',
  out_of: 'out of',
  loading: 'Loading...',
  show_rows: 'Show rows',
  error_boundary_heading_text: 'Sorry, something went wrong.',
  error_boundary_paragraph_text:
    'Our team has been notified and we will get it fixed as soon as we can.',
  reload_page: 'Reload page',
  take_me_home: 'Take me home',
  manual: 'Manual',
  ['v-office']: 'V-Office',
  guest: 'Guest',
  owner: 'Owner',
  lead: 'Lead',
  supplier: 'Supplier',
  test: 'Test',
  other: 'Other',
  daily: 'Daily',
  weekly: 'Weekly',
  bi_weekly: 'Bi-Weekly',
  monthly: 'Monthly',
  semi_anually: 'Semi-Anually',
  anually: 'Anually',
  previous: 'Previous',
  next: 'Next',
  date_validation_msg: 'Please enter a valid date',
  freeze_inventory: 'Freeze Inventory',
  create_inventory_list: 'Create Inventory Lists',
  correct_inventory: 'Correct Inventory',
  create_delta_list: 'Create Delta Lists',
  unfreeze_inventory_end: 'Unfreeze Inventory & End Stocktaking',
  download_pdf: 'Download PDF',
  download_excel: 'Download Excel',
  download__delta_list_pdf: 'Download Delta List PDF',
  unfreeze_inventory: 'Unfreeze Inventory',
  no_transferring: '(No Transferring of Items possible.)',
  download_as: '(Download as PDF and save, then print.)',
  transfer_items: '(Transfer Items from or to Stocktaking correction.)',
  what_change: '(What changes have been made during Stocktaking.)',
  step_1: 'Step 1',
  step_2: 'Step 2',
  step_3: 'Step 3',
  step_4: 'Step 4',
  step_5: 'Step 5',
  actual_quantity: 'Actual Quantity',
  delta: 'Delta',
  no_data_found: 'No data found',
  ms: 'Ms.',
  Ms: 'Ms.',
  diverse: 'Diverse',
  Diverse: 'Diverse',

  /*MDM*/
  master_data_magnagement: 'Master Data Management',
  mdm: 'MDM',
  contacts: 'Contacts',
  contact: 'Contact',
  upload_csv: 'Upload CSV',
  business: 'Business',
  language: 'Language',
  letter_salutation: 'Letter Salutation',
  letter_closure: 'Letter Closure',
  salutation: 'Salutation',
  birthdate: 'Birthdate',
  phone_number: 'Phone Number',
  business_phone_number: 'Business Phone Number',
  mobile_number: 'Mobile Number',
  fax: 'Fax',
  im: '-im-',
  website: 'website',
  both: 'both',
  self: 'self',

  region: 'Region',
  plant: 'Plant',
  categories: 'Categories',
  online: 'Online',
  deactivated: 'Deactivated',
  sleeping_facilities: 'Sleeping Facilities',
  longitude: 'Longitude',
  latitude: 'Latitude',
  bedrooms: 'Bedrooms',

  bookings: 'Bookings',
  booking_date: 'Booking Date',
  invoices: 'Invoices',
  invoice: 'Invoice',
  number: 'Number',
  until: 'Until',
  received_at: 'Received At',
  changed_at: 'Changed At',
  total: 'Total',
  grand_total: 'Grand Total',
  marketing_channel: 'Marketing Channel',
  down_payment: 'Down Payment',
  down_payment_until: 'Down Payment Until',
  final_payment_until: 'Final Payment Until',
  ex_price: 'Ex-Price',
  total_occupancy: 'Total Occupancy',
  adult: 'Adult',
  children: 'Children',
  babies: 'Babies',
  age_children: 'Age of Children',
  country_customer: 'Country Customer',
  town_code_customer: 'Town Code Customer',
  country_objectives: 'Country Objectives',
  rent_statement_exit: 'Rent Statement Exit',
  invoice_date: 'Invoice Date',
  net: 'Net',
  due_date: 'Due Date',
  cancelled_on: 'Cancelled On',
  calculation: 'Calculation',
  select_calculation: 'Select Calculation',
  provider: 'Provider',
  select_provider: 'Select Provider',
  invoice_recepient: 'Invoice Recepient',
  select_invoice_recepient: 'Select Invoice Recepient',
  warehouse_holds_inventories: 'Warehouse holds inventories',
  uploads: 'Uploads',
  all_uploads: 'All Uploads',
  all_bookings: 'All Bookings',
  all_invoices: 'All Invoices',
  all_services: 'All Services',
  all_logs: 'All Logs',
  drag_drop_csv_files_msg:
    'Drag & Drop only .csv files here, or click to select .csv files. Upload files upto 10MB.',
  drag_drop_csv_15MB_files_msg:
    'Drag & Drop only .csv files here, or click to select .csv files. Upload files upto 15MB.',
  processing_data: 'Processing data',
  failed: 'Failed',
  access_mdm: 'Access MDM',
  mdm_supporter: 'MDM Supporter',
  file_uploaded_successfully: 'File uploaded successfullly.',
  uploaded_on: 'Last Uploaded on',
  by: 'by',
  delete_article_unit_error:
    'Unable to delete article unit type. It contains many articles.',
  delete_type_error:
    'Unable to delete article category. It contains many articles.',
  transferred: 'transferred',
  internal_server_error: 'Internal Server Error',
  cannot_upload_csv_in_worng_category: 'Cannot Upload CSV in wrong category',
  upload_date: 'Upload Date',
  crm_employee: 'CRM Employee',
  access_crm: 'Access CRM',
  merge_contacts: 'Merge Contacts',
  create_owner_crm: 'Create New Owner',
  notes_cannot_be_more_than_2000_characters_long:
    'Notes cannot be more than 2000 characters long',

  access_oms: 'Access Objects and Seasons',
  manage_oms: 'Manage Objects and Seasons',
  access_bms: 'Access BMS',
  access_bread_service: 'Access Bread Service',
  access_sms: 'Access SMS',
  manage_sms: 'Manage SMS',
  location: 'Location',
  letter_completion: 'Letter Completion',
  homepage: 'Homepage',

  imports: 'Imports',
  all_imports: 'All Imports',
  import_type: 'Import Type',
  last_id: 'Last Id',
  total_records_imported: 'Total Records Imported',
  imported_at: 'Imported At',
  availabilities: 'Availabilities',

  /* OMS */
  object_management_system: 'Object Management System',
  oms: 'OMS',
  object_list: 'Object lists',
  season: 'Season',
  season_list: 'Season Lists',
  season_name: 'Season Name',
  edit_season: 'Edit Season',
  delete_season: 'Delete Season',
  add_season: 'Add New Season',
  from_date: 'Start Date',
  to_date: 'End Date',
  add_new_apartment: 'Add new apartment',
  status_changed: 'Status changed',
  payement_active: 'Payement active',
  website_active: 'Website active',
  video_active: 'Video active',
  seven_for_six: '7 for 6',
  this_discount_will_be_applied_for_season_c:
    'This discount will only be applied during Season C',
  voffice_data_sync: 'VOffice Data Sync',
  features: 'Features',
  picture: 'Picture',
  text: 'Text',
  cannot_get_apartment_details: 'Cannot get apartment details',
  must_be_greater_than_zero: 'Must be greater than 0',
  basic_information: 'Basic Information',
  copy: 'Copy',
  ical_link: 'iCal-Link',
  copied: 'Copied',
  copy_to_clipboard_ctrl_c_enter: 'Copy to clipboard: Ctrl+C, Enter',

  /**SMS Service */
  service_management_system: 'Service Management System',
  sms: 'SMS',
  add_service: 'Add Service',
  scheme_type: 'Scheme Type',
  select_scheme_type: 'Select Scheme Type',
  service_created: 'Service has been created',
  service_creation_failed: 'Service creation failed.',
  service_udpate_failed: 'Service update failed.',
  add_new_service: 'Add New Service',
  edit_service: 'Edit Service',
  service_updated: 'Service updated successfully',
  service_not_found: 'Service not found',
  service_details: 'Service Details',
  product_code: 'Product Code',
  v_office_service: 'VOffice Service',
  price_scheme_type: 'Price Scheme Type',
  by_quantity: 'By Quantity',
  by_duration: 'By Duration',
  by_day: 'By Day',
  newspaper: 'Newspaper',
  one_time_price: 'One Time Price',
  beach_chair: 'Beach Chair',
  unit_price: 'Unit Price',
  price_a: 'Price A',
  price_b: 'Price B',
  view_service: 'View Service',
  delete_service: 'Delete Service',
  service_deleted: 'Service has been deleted.',
  day: 'Day',
  price: 'Price',
  duration: 'Duration',
  priceschemebyday: 'By Day',
  priceschemeonetime: 'One Time',
  priceschemebyquantity: 'By Quantity',
  priceschemebyduration: 'By Duration',
  priceschemebeachchair: 'Beach Chair',
  per_week: 'Per Week',
  sun: 'SUN',
  mon_fri: 'MON-FRI',
  additional_day: 'Additional Day',
  annual_price: 'Annual Price',
  duplicate_price_scheme: 'Duplicate Price Scheme',

  /**SMS Service Group */
  service_group: 'Service Group',
  service_groups: 'Service Groups',
  service_management: 'Service Management',
  select_service_group: 'Select Service Group',
  all_service_groups: 'All Service Groups',
  service_group_list: 'Service Group Lists',
  add_service_group: 'Add Service Group',
  booking_type: 'Booking Type',
  booking_fee: 'Booking Fee',
  booking_fee_calculation: 'Booking Fee Calculation',
  service_group_created: 'Service Group has been created',
  service_group_creation_failed: 'Service Group creation failed.',
  service_group_udpate_failed: 'Service Group update failed.',
  add_new_service_group: 'Add New Service Group',
  service_name: 'Service Name',
  frontend_color: 'Frontend Color',
  price_per_night: 'Price Per Night',
  show_on_landing_page: 'Show on Landing Page',
  default_service: 'Default Service',
  early_deliverable: 'Early Deliverable',
  early_deliverable_on_start: 'Early Deliverable on Start',
  pay_to_vendor: 'Pay to Vendor',
  dog_service: 'Dog Service',
  icon: 'Icon',
  cover_image: 'Cover Image',
  drag_icon_or_click_to_select_icon: 'Drag icon or click to select icon',
  drag_image_or_click_to_select_image: 'Drag image or click to select image',
  edit_service_group: 'Edit Service Group',
  service_group_updated: 'Service Group updated successfully',
  service_group_not_found: 'Service Group not found',
  service_group_details: 'Service Group Details',
  one_time: 'One Time',
  per_day: 'Per Day',
  per_night: 'Per Night',
  view_service_group: 'View Service Group',
  delete_service_group: 'Delete Service Group',
  service_group_deleted: 'Service Group has been deleted.',
  accept_only_png: 'Accept only *.png file',
  enter_booking_fee_in_cents: 'Enter Booking Fee in Cents',
  enter_price_per_night_in_cents: 'Enter Price in Cents',
  enter_value_in_cents: 'Enter Value in Cents',
  enter_value_in_euro: 'Enter Value in Euro',
  light: 'Light',
  dark: 'Dark',
  price_calculation: 'Price Calculation',

  /**DMS Service */
  document_management_system: 'Document Management System',
  dms: 'DMS',
  general_documents: 'General Documents',
  document_objects: 'Document Appartment',
  document_customers: 'Document Customers',
  document_owners: 'Document Owners',
  document_users: 'Document Users',
  all_documents: 'All General Documents',
  all_customers: 'All Customer Documents',
  all_owners: 'All Owner Documents',
  all_object_documents: 'All Object Documents',
  all_user_documents: 'All User Documents',
  upload_document: 'Upload Document',
  document_name: 'Document Name',
  owner_name: 'Owner Name',
  availability: 'Availability',
  edit_general_document: 'Edit Document',
  edit_document_object: 'Edit Upload Document to Object',
  edit_document_owner: 'Edit Upload Document to Owner',
  edit_document_customer: 'Edit Upload Document to Customer',
  upload_general_document: 'Upload General Document',
  upload_document_object: 'Upload Document to Object',
  upload_document_owner: 'Upload Document to Owner',
  upload_document_customer: 'Upload Document to Customer',
  to_objects: 'to Objects',
  no_of_documents: 'No. of Documents',
  select_object: 'Select Object',
  general_document_deleted: 'Document has been deleted.',
  delete_general_document: 'Delete Document',
  general_document_not_found: 'Document not found',
  general_document_udpate_failed: 'Document update failed.',
  general_document_created: 'Document has been created',
  general_document_creation_failed: 'Document creation failed.',
  general_document_updated: 'Document updated successfully',
  customer_documents: 'Customer Documents',
  owner_documents: 'Owner Documents',
  object_documents: 'Object Documents',
  users_document: 'Users Document',
  upload_user_document: 'Upload User Document',
  edit_user_document: 'Edit User Document',
  import_file: 'Import File',
  the_file_field_is_required: 'The file field is required.',

  /**BMS Service */
  booking_management_system: 'Booking Management System',
  booking_management: 'Booking Management',
  booking_overview: 'Booking Overview',
  booking_status: 'Booking Status',
  payment_status: 'Payment Status',
  distribution_channel: 'Distribution Channel',
  create_new_booking: 'Create New Booking',
  apartment_name: 'Apartment Name',
  total_amount: 'Total Amount (CHF)',
  amount_open: 'Amount Open (CHF)',
  bms: 'BMS',
  customer_information: 'Customer Information',
  booking_creation_failed: 'Booking creation failed',
  salutation_is_required: 'Salutation is required',
  select_salutation: 'Select salutation',
  title: 'Title',
  street_name_is_required: 'Street name is required',
  city_is_required: 'City is required',
  select_country: 'Select country',
  country_is_required: 'Country is required',
  invalid_phone_number: 'Invalid phone number',
  appartment_information: 'Appartment Information',
  appartment_is_required: 'Appartment is required',
  date_is_required: 'Date is required',
  select_quantity: 'Select quantity',
  baby: 'Baby',
  select_distribution_channel: 'Select distribution channel',
  arrival_status: 'Arrival Status',
  select_arrival_status: 'Select arrival status',
  arrival_status_is_required: 'Arrival status is required',
  reserved_at: 'Reserved at',
  reserved_until: 'Reserved until',
  total_amount_is_required: 'Total amount is required',
  paid_amount_is_required: 'Paid amount is required',
  residual_amount: 'Residual amount',
  deposit_amount: 'Deposit amount',
  residual_amount_is_required: 'Residual amount is required',
  deposit_amount_is_required: 'Deposit amount is required',
  notes_notizen: 'Notes',
  further_remarks_notes: 'Further Remarks / Notes',
  house_number_is_required: 'House number is required',
  town_is_required: 'Town is required',
  post_code_is_required: 'Post code is required',
  phone_number_is_required: 'Phone number is required',
  add_booking: 'Add Booking',
  mandatory_fields: 'Mandatory fields',
  above_18_years_old: 'Above 18 years old',
  under_18_years_old: 'Under 18 years old',
  under_3_years_old: 'Under 3 years old',
  booking_created: 'Booking has been created',
  subtotal: 'Sub Total',
  booking_calendar: 'Booking Calendar',
  could_not_fetch_bookings: 'Could not fetch bookings',
  booking_date_conflict_error: 'Booking date overlaps another booking',
  booking_cannot_be_in_the_past: 'Booking cannot be in the past',
  appartment_overview: 'Appartment Overview',
  reserved: 'Reserved',
  vat19: '19%VAT on delivery service',
  vat7: '7% VAT',
  payment_method: 'Payment Method',
  bank_transfer: 'Bank Transfer',
  paid_amount: 'Paid Amount',
  without_tax: 'Without Tax',
  including: 'Including',
  callback: 'Callback',
  callbacks: 'Callbacks',
  all_callbacks: 'All Callbacks',
  callback_date: 'Callback Date',
  callback_detail: 'Callback Detail',
  callback_deleted_successfully: 'Callback Deleted Successfully',
  no_callback_details_found: 'No Callback Details Found',
  inquiry: 'Inquiry',
  mr: 'Mr.',
  Mr: 'Mr.',
  mrs: 'Mrs.',
  misc: 'Misc.',
  piece: 'piece',
  please_verify_your_service_bookings: 'Please verify your service bookings',
  booking_preview: 'Booking Preview',
  selected_dates_not_available: 'Selected dates are not available',
  not_paid: 'Not Paid',
  partial_paid: 'Partially Paid',
  fully_paid: 'Fully Paid',
  cannot_calculate_booking_price_for_the_given_date_range:
    'Cannot calculate booking price for the given date range',
  cannot_make_bookings_in_the_given_date_range:
    'Cannot make bookings in the given date range',
  booked_from: 'Booked from',
  booked_until: 'Booked until',

  /**BS Service */
  bread_system: 'Bread System',
  bs: 'BS',
  all_billings: 'All Billings',
  all_daily_view: 'All Daily Views',
  all_driving_order: 'All Driving Orders',

  /**PMS Service */
  payment_management_system: 'Payment Management System',
  pms: 'PMS',
  credit_card: 'Credit Cards Payment',
  all_credit_cards: 'Credit Cards Payment',
  paypal: 'Paypal Payment',
  all_paypals: 'Paypal Payment',
  credit_card_no: 'Credit Card Number',
  amount: 'Amount',
  paypal_address: 'Paypal Address',
  access_pms: 'Access PMS',
  payment_links: 'Payment Links',
  send_on: 'Send On',
  last_sent_on: 'Last Sent On',
  paid_on: 'Paid On',
  all_payment_links: 'All Payment Links',
  create_payment_links: 'Create Payment Link',
  send_payment_link: 'Send Payment Link',
  payment_link_sending_failed: 'Payment link sending failed.',
  payment_link_send_successfully: 'Payment link send successfully',
  identifier: 'Identifier',
  amount_is_required: 'Amount is required',
  required_booking_number: 'Booking number is required.',
  required_customer_name: 'Customer name is required.',
  paid: 'Paid',
  resent_mail_success: 'Email Resent Successfully',
  resent_mail_error: 'Email Resent Failed',
  confirm_resend_email: 'Are you sure you want to resend email?',
  service_reservation: 'Service booking',
  apartment_reservation: 'Object booking',
  ferry_booking: 'Ferry booking',
  payment_link: 'Payment link',

  /**PNS Service */
  push_notification_system: 'Push Notification System',
  all_notifications: 'All Notifications',
  pns: 'PNS',
  notifications: 'Notifications',
  push_notification: 'Push Notification',
  add_new_push_notification: 'New Push Notification',
  link: 'Link',
  total_read: 'Total/Read',
  add_push_notification: 'Add Push Notification',
  notification_created: 'Push Notification has been created',
  notification_creation_failed: 'Push Notification creation failed.',
  send: 'Send',
  access_pns: 'Access PNS',
  manage_pns: 'Send Push-Notifications',
  image_link: 'Image Link',
  pns_notification_confirm: 'Attention: Notification will be sent, continue?',

  /**FAQ Service */
  publish_in: 'Publish In',
  frequently_ask_question: 'Frequently Ask Question',
  faq: 'FAQs',
  all_faq: 'All FAQs',
  internal_faq: 'Internal FAQs',
  all_internals: 'All Intenals',
  success: 'Success',
  group: 'Groups',
  all_groups: 'All Groups',
  add_faq: 'Add FAQ',
  edit_faq: 'Edit FAQ',
  delete_faq: 'Delete FAQ',
  select_group: 'Select Group',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Internes_Wissensdokument',
  dateien_hochladen: 'Dateien_Hochladen',
  pdf_upload: 'PDF Upload',
  faq_deleted_successfully: 'FAQ deleted successfully',

  faq_group: 'FAQ Group',
  add_group: 'Add Group',
  edit_group: 'Edit Group',
  delete_group: 'Delete Group',
  faq_group_deleted_successfully: 'FAQ Group deleted successfully',

  reservations: 'Reservations',
  all_reservations: 'All Reservations',
  all_requests: 'All Requests',
  reservation_requests: 'Reservation requests',
  ferry_bookings: 'Ferry bookings',
  bread_bookings: 'Bread bookings',
  bread_daily_view: 'Bread daily views',
  bread_driving_order: 'Bread driving orders',
  save_inorder_to_apply_changes: 'Save inorder to apply changes',

  /** Bread Boking */
  bread_booking: 'Bread Bookings',
  add_bread_booking: 'Add Bread Booking',
  update_bread_booking: 'Update Bread Booking',
  pending: 'Pending',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  booking_number: 'Booking Number',
  add_new_bread_booking: 'Add New Bread Booking',
  edit_bread_booking: 'Edit Bread Booking',
  bread_booking_updated: 'Bread Booking updated successfully',
  bread_booking_not_found: 'Bread Booking not found',
  cannot_change_quantity_in_past:
    'You cannot change quantity of a booking in the past',
  multiple_bookings_in_same_date_not_allowed:
    'Multiple bookings in same date is not allowed',
  bread_booking_details: 'Bread Booking Details',
  bread_booking_deleted: 'Bread Booking has been deleted.',
  view_bread_booking: 'View Bread Booking',
  delete_bread_booking: 'Delete Bread Booking',
  edit_order: 'Edit Order',
  cancel_order: 'Cancel Order',
  property_name: 'Property Name',
  customer_contact: 'Customer Contact',
  booking_period: 'Booking Period',
  timespan: 'Time Span',
  bread_booking_created: 'Bread Booking has been created',
  bread_booking_creation_failed: 'Bread Booking creation failed.',
  bread_booking_udpate_failed: 'Bread Booking update failed.',
  include_departure_day: 'Include Departure Day',
  price_pound: 'Price (€)',
  booking_fee_pound: 'Booking Fee (€)',
  total_pound: 'Total (€)',
  must_be_date_after_today_or_equal_to_today:
    'Must be date after today or equal to today.',
  invalid_data: 'The given data is invalid',
  booking_invalid: 'Booking Number is not correct',
  service_invalid: 'Service is not correct',
  customer_invalid: 'Customer Name is correct',
  /** Driving Order */
  driving_order: 'Driving Orders',
  import_driving_order: 'Import Driving Orders',
  import_driving_success: 'Import Driving Success',
  import_driving_failed: 'Import Driving Failed',

  /** Bread Daily View */
  bread_booking_billing: 'Bread Booking Billings',
  bread_booking_billing_detils: 'Bread Booking Billing Details',
  bread_booking_billing_edit: 'Bread Booking Billing Edit',
  bread_type: 'Bread Type',
  old_price: 'Old Price',
  new_price: 'New Price',
  price_difference: 'Price Difference',
  total_bread: 'Total Bread',
  total_fee: 'Total Fee',
  total_price_difference: 'Total Price Difference',
  total_price: 'Total Price',
  bread_quantity_difference: 'Bread Quantity Difference',
  total_fee_difference: 'Total Fee Difference',
  grand_total_difference: 'Grand Total Difference',
  update_bill: 'Update Bill',
  close_bill: 'Close Bill',
  this_bill_will_be_closed_permanently:
    'This bill will be closed permanently. You will not be able to make changes to it once its closed.',

  /** Bread Daily View */
  property: 'Property',
  guest_number: 'Guest Number',
  brotchen: 'Brötchen',
  bread_daily_view_details: 'Bread Daily View Details',
  view_bread_daily_view: 'View Bread Daily View',
  download: 'Download',
  download_image: 'Download Image',
  download_driving_order: 'Download Driving Order',

  /** Reservation Request */
  reservation_request_details: 'Reservation Request Details',
  booking_detail: 'Booking Detail',
  reservation_detail: 'Reservation Detail',
  adults: 'Adults',
  customer_detail: 'Customer Detail',
  sur_name: 'Sur Name',
  reservation_details: 'Reservation Details',
  service_line: 'Service Line',
  view_reservation_request_view: 'View Reservation Request',
  dog: 'Dog',
  dog_breed: 'Dog Breed',

  /** Ferry Booking */
  view_ferry_booking_view: 'View Ferry Booking',
  ferry_booking_details: 'Ferry Booking Details',
  booking_amount: 'Booking Amount',
  trip_date: 'Trip Date',
  time: 'Time',
  trip_voyage: 'Trip Voyage',
  departure_harbor: 'Departure Harbor',
  destination_harbor: 'Destination Harbor',
  trip_return_date: 'Trip Return Date',
  vehicle_type: 'Vehicle Type',
  licence_plate: 'Licence Plate',
  length: 'Length',
  customer_email: 'Customer Email',
  trip_detail: 'Trip Detail',
  vehicle_detail: 'Vehicle Detail',
  cm: 'cm',
  ferry_booking_cancel_success: 'Ferry Booking has been cancelled',
  ferry_booking_cancel_failed: 'Ferry Booking cancelled failed.',

  data_synced: 'Data synced.',
  last_v_office_data_sync: 'Last v-office data sync',
  access_reservations: 'Reservations',
  access_bookings: 'Access Bookings and Calendar',
  manage_bookings: 'Manage Bookings, Invoices and Check-in',
  access_invoices: 'Access Invoices',
  manage_bread_service: 'Manage Bread Service',
  access_bread_driving_order: 'Bread Driving Order',
  access_callbacks: 'Callbacks',

  access_faq: 'Access FAQ',
  manage_faq: 'Manage FAQ',
  access_faq_internal: 'Access Internal FAQ',
  access_faq_group: 'Access FAQ Group',
  manage_faq_group: 'Manage FAQ Group',

  access_dms_general: 'Access General Document',
  manage_dms_general: 'Manage General Document',
  access_dms_object: 'Access Document Object',
  manage_dms_object: 'Manage Document Object',
  access_dms_customer: 'Access Document Customer',
  manage_dms_customer: 'Manage Document Customer',
  access_dms_owner: 'Access Document Owner',
  manage_dms_owner: 'Manage Document Owner',
  access_dms_user: 'Access User Document',
  manage_dms_user: 'Manage User Document',
  select_date: 'Select Date',
  select_date_time: 'Select Date/Time',

  /*Internet Status*/
  wifi: 'Internet connection was restored.',
  nowifi: 'You are browsing on offline mode.',
  refresh: 'Refresh',

  /*Contact Center*/
  access_contact_center: 'Access Contact Center',

  /*Newsletter*/
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Newsletters',
  newsletter_overview: 'Newsletter Overview',
  draft: 'Draft',
  drafts: 'Drafts',
  group_of_recipients: 'Group of Recipients',
  all_newsletters: 'All Newsletters',
  new_newsletter: 'New Newsletter',
  create_new_newsletter: 'Create New Newsletter',
  recipients: 'Recipients',
  subject: 'Subject',
  all_draft: 'All Drafts',
  message: 'Message',
  attach_file: 'Attach File',
  attachments: 'Attachments:',
  preview: 'Preview',
  save_as_draft: 'Save as Draft',
  compose_message: 'Compose Message',
  preview_message: 'Preview Newsletter',
  download_our_app_now: 'Download our app now ',
  contact_with_us: 'Contact with us',
  about_us: 'About Us',
  customer_care: 'Customer Care',
  contact_us: 'Contact Us',
  privacy_policy: 'Privacy Policy',
  subscribe: 'Subscribe',
  unsubscribe: 'Unsubscribe',
  select_recipient_group: 'Select Recipient',
  edit_newsletter: 'Edit Newsletter',
  delete_newsletter: 'Delete Newsletter',
  newsletter_deleted: 'Newsletter deleted.',
  newsletter_delete_error: 'Newsletter delete failed.',
  newsletter_sent_sucessfully: 'Newsletter sent successfully.',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter saved to draft successfully.',
  newsletter_updated_sucessfully: 'Newsletter updated successfully.',
  hello: 'Hello',
  to_send: 'To Send',
  save_and_send: 'Save and Send',
  recipient_group_field_is_required: 'Recipient Group field is required.',
  subject_field_is_required: 'Subject field is required.',
  message_field_is_required: 'Message field is required.',
  send_newsletter: 'Send Newsletter',
  attention_notification_will_be_send_continue:
    'Attention! Notification will be send continue',
  privacy: 'Privacy',
  imprint: 'Imprint',

  /*required*/
  required_first_name: 'First name is required.',
  required_last_name: 'Last name is required.',
  required_email: 'Email is required.',
  required_cellphone: 'Cellphone is required.',
  required_role: 'Role is required.',
  required_address: 'Address is required.',
  required_repate_from: 'Repeat from  is required.',
  required_repeate_until: 'Repeat unit is required.',
  required_interval: 'Recursive interval is required.',
  required_label: 'Label is required.',
  required_type: 'Type is required.',
  required_article: 'Article is required.',
  required_destination: 'Destination is required.',
  required_source: 'Source is required.',
  required_quantity: 'Quantity is required.',
  required_article_name: 'Article name is required.',
  required_unit: 'Unit is required.',
  required_type_name: 'Type name is required.',
  required_unit_name: 'Unit name is required.',
  required_descriptions: 'Descriptions is required.',
  required_document_name: 'Document name is required.',
  required_Object: 'Object is required.',
  required_upload: 'Upload file is required.',
  required_service_name: 'Service name is required.',
  required_voffice_service: 'VOffice service is required.',
  required_service_group: 'Service Group is required.',
  required_cover_image: 'Cover image is required.',
  required_booking_type: 'Booking fee type is required.',
  required_booking_fee: 'Booking fee is required.',
  required_price_per_night: 'Price per night is required.',
  required_icon: 'Upload icon is required.',
  required_group_name: 'Group name is required.',
  required_status: 'Status is required.',
  required_group_recipients: 'Group of recipients is required.',
  required_subject: 'Subject is required.',
  required_message: 'Message is required.',
  required_room: 'Room is required.',
  required_rack: 'Rack is required.',
  required_shelf: 'Shelf is required.',
  required_warehouse_name: 'Warehouse name is required.',
  file_size_is_too_large: 'File size is too large.',
  required_customer: 'Customer is required.',
  required_owner: 'Owner is required.',
  required_user: 'User is required.',
  required_front_color: 'Frontend color is required.',
  only_one_file_is_allowed: 'Only 1 file is allowed.',
  please_fill_in_all_mandatory_fields: 'Please fill in all mandatory fields.',
  required_password: 'Password is required.',
  required_distribution_channel: 'Distribution channel is required',
  required_sleeping_facilities: 'Sleeping facilities are required',
  required_pets_allowed: 'Pets allowed field is required',

  /*Message*/
  email_taken: 'The email has already been taken.',

  access_newsletter: 'See Newsletters and drafts',
  manage_newsletter: 'Edit Newsletters and drafts ',

  /*Key*/
  key: 'Key',
  keyscan: 'Keyscan',
  access_keyscan_keylist: 'Access Key List',
  access_keyscan_keylog: 'Access Key Log',
  key_management_system: 'Key Management System',
  key_list: 'Key List',
  key_logs: 'Key Logs',
  key_management: 'Key Management',
  all_key_logs: 'All Key Logs',
  search_customer: 'Search Customer / User',
  key_id: 'Key Id',
  scan_key: 'Scan Key',
  key_handling: 'Key Handling',
  scan_key_again: 'Scan Key Again',
  key_information: 'Key Information',
  key_current_status: 'Key Current Status',
  new_status: 'New Status',
  rfid_message: 'RFID Scan Successfully',
  rescan_key: 'Rescan Key',
  key_new_status: 'Key New Status',
  place_rfid: 'Place Key on RFID Reader. ',
  all_key_list: 'All Key List',
  add_key: 'Add Key',
  add_new_key: 'Add New Key',
  registered_date: 'Registered Date',
  registered_by: 'Registered By',
  edit_key: 'Edit Key',
  delete_key: 'Delete Key',
  view_key: 'View Key',
  given_out: 'Given Out',
  in_stock: 'In Stock',
  lost: 'Lost',
  key_history: 'Key History',
  key_created_successfully: 'Key Created Successfully',
  key_created_error: 'Key Creation Error',
  key_updated_successfully: 'Key Updated Successfully',
  key_updated_error: 'Key Update Error',
  key_deleted_successfully: 'Key Deleted Successfully',
  key_deleted_error: 'Key Deletion Error',
  rescan: 'Rescan',
  rfid_taken_error: 'RFID Key Already Taken',
  scanner_title_message: 'Please place the tag near the Scanner.',
  scanner_message: 'Key placed correctly?',
  key_no_registered: 'The key has not been registered yet.',

  /*Aerial Map */
  aerial_map: 'Aerial Map',
  access_aerial_map: 'Access Aerial Map',
  three_sixty_degree: '360°',
  standard: 'Standard',
  map: 'Map',

  /* Appartment Address Form*/
  address_location: 'Address & Location',
  address_suffix: 'Address suffix',
  distance_lake: 'Distance Lake',
  distance_shopping: 'Distance Shopping',
  distance_restaurant: 'Distance restaurant',
  distance_city: 'Distance city',
  distance_train_station: 'Distance train station',
  distance_bank_atm: 'Distance bank / ATM',
  distance_doctor: 'Distance Doctor',
  landscape_view: 'Landscape View',
  garden_view: 'Garden View',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'The Google Maps map / directions make it easier for guests to navigate to your apartment and offers maximum transparency',
  type_is_required: 'Type is required',

  /*Premises Features Form */
  sleeping_arrangements: 'Sleeping Arrangements',
  number_of_rooms: 'Number of Rooms',
  number_of_bedrooms: 'Number of Bedrooms',
  number_of_bathrooms: 'Number of Bathrooms',
  guest_toilet: 'Guest toilet',
  size_in_square_meters: 'Size in square meters',
  number_of_parking_spaces: 'Number of parking spaces',
  additional_information: 'Additional information',
  kitchen: 'Kitchen',
  terrace: 'Terrace',
  balcony: 'Balcony',
  non_smoker: 'Non-Smoker',
  pets_allowed: 'Pets Allowed',
  children_allowed: 'Children Allowed',
  diabled_access: 'Disabled Access',
  wheelchair_accessible: 'Wheelchair accessible',
  parking_available: 'Parking available',
  washing_machine: 'Washing machine',
  dryer: 'Dryer',
  fireplace_stove: 'fireplace stove',
  drying_rack: 'Drying rack',
  bedding: 'Bedding',
  e_car_charging_station: 'E-car Charging Station',
  air_conditioning: 'Air Conditioning',

  /* Categories Form */
  Wellness: 'Wellness',
  luxury: 'luxury',
  family: 'family',
  secluded: 'secluded',
  resort: 'Resort',
  romantic: 'Romantic',
  athletic: 'Athletic',
  tourist_attraction: 'Tourist Attraction',

  /* Equiment Kitchen Form */
  microwave: 'Microwave',
  stove: 'Stove',
  oven: 'Oven',
  refrigerator: 'Refrigerator',
  dishwasher: 'DishWasher',
  coffee_machine: 'Coffee Machine',
  kettle: 'Kettle',
  toaster: 'Toaster',
  dishes_cutlery: 'Dishes / Cutlery',
  waste_bin: 'Waste Bin',

  /* Amenities Bathroom Form */
  no_of_showers: ' No Of Showers',
  no_of_toilets: 'No of Toilets',
  shower: 'Shower',
  wc: 'wc',
  bathtub: 'Bathtub',
  bidet: 'bidet',
  hair_dryer: ' Hair Dryer',
  mirror: 'Mirror',
  bathroom_sink: 'Bathroom Sink',

  /* Amenities Children Form */
  stair_gate: 'Stair Gate',
  changing_pad: 'Changing Pad',
  socket_fuse: 'Socket Fuse',
  child_toilet_seat: ' Child Toilet Seat',

  /*Equipment Outside Form */
  type_of_grill: 'Type of Grill',
  grill: 'Grill',
  parasol: 'parasol',
  pond: 'pond',
  swing: 'Swing',
  sandbox: 'SandBox',
  slide: 'slide',
  fireplace: 'Fireplace',
  garden: 'Garden',
  garden_house: 'Garden House',
  hammock: 'Hammock',

  /* Leisure Activities Entertainment Form */
  free_time_activities: 'Free Time Activities',
  sauna: 'Sauna',
  jacuzzi: 'jacuzzi',
  solarium: 'solarium',
  gym: 'Gym',
  pool: 'Pool',
  entertainment: 'Entertainment',
  tv: 'TV',
  hifi: 'hifi',
  radio: 'Radio',
  wlan: 'WLAN',
  internat_speed: 'Internat Speed',

  /**lyrics form */
  headline: 'Headline',
  full_description: 'Full description',
  short_description: 'Short description',
  directions: 'Directions',

  /**Settings Form */
  season_and_prices: 'Seasons & Prices',
  payment_active: 'Payment Active',
  site_active: 'Site Active',
  vimeo_video_link: 'Vimeo video link (optional)',
  please_assign_price:
    'Please assign the available seasons to the apartment and give a price.',
  assigned: 'Assigned',

  appartment_name: 'Appartment Name ',
  upload: 'Upload',
  upload_document_to_apartment: 'Upload document to apartment',
  select_appartment: 'Select Appartment',
  adjust_order_of_images:
    'The arrangement / order of the images can be easily adjusted via drag & drop.',
  delete_image: 'Delete Image',
  add_new_payment: 'Add New Payment',
  payment_made: 'Payment Made',
  date_of_receipt_of_payment: 'Date of receipt of payment',
  delete_payment: 'Delete Payment',
  service_information: 'Service Information',
  prepaid: 'Prepaid (CHF)',
  balance: 'Balance (CHF)',
  edit_booking: 'Edit Booking',
  add_more_services: 'Add More Services',
  checkin_now: 'Check-In now',
  checkout_now: 'Check-Out now',
  add_service_for_booking: 'Add services to booking {0}',
  confirm_checkin: 'Would you like to Check-In for the guest {0} now?',
  confirm_checkout: 'Would you like to Check-Out for the guest {0} now?',
  terminal_system: 'Terminal System',
  terminal: 'Terminal',
  check_in_check_out: 'Check-In / Check-Out',
  check_in: 'Check-In',
  check_out: 'Check-Out',
  guest_check_in: 'Guest Check-In',
  guest_check_out: 'Guest Check-Out',
  checkin_modal_body_first_message: 'Would you like to check in the guest',
  checkout_modal_body_first_message:
    'Would you like to check out for the guest',
  checkout_modal_body_second_message: 'now',
  checkin_modal_body_last_message: 'now',
  please_make_sure_the_guest_has_read_the_booking_information:
    'Please make sure that the guest has read the booking information.',
  check_booking_details: 'Check booking details',
  people: 'People',
  period: 'Period',
  welcome: 'Welcome',
  booked_services: 'Booked Services',
  guest_has_been_successfully_checked_in:
    'Guest has been successfully checked in!',
  guest_has_been_successfully_checked_out:
    'Guest has been successfully checked out!',
  could_not_check_in_guest: 'Could not check in guest',
  could_not_check_out_guest: 'Could not check out guest',
  make_check_in: 'Check-In now',
  make_check_out: 'Check-Out now',
  disconnected_from_second_screen: 'Disconnected from second screen',
  device_id_pair_context_must_be_used_within_device_id_pair_provider:
    'DeviceIdPair Context must be used within DeviceIdPair Provider',
  arrived: 'Arrived',
  departed: 'Departed',
  generate_invoice: 'Generate Invoice',
  select_existing_customer: 'Select existing customer',
  accept_selected: 'Accept Selected',
  disconnected: 'Disconnected',

  abort: 'Abort',
  mirror_booking_details: 'Mirror Booking Details',
  did_you_choose_the_right_guest: 'Did you choose the right guest?',
  msg_check_in_check_out_modal:
    ' In the next step, the booking details are displayed for the guest to check. Adjustments or additions can still be made afterwards.',
  should_the_booking_details_of_the_following_guest_be_displayed:
    'Should the booking details of the following guest be displayed?',
  invoice_number: 'Invoice Number',
  invoice_type: 'Invoice Type',
  date_of_invoice: 'Date of invoice',
  all: 'All',
  owner_invoice: 'Rent Statement',
  customer_invoice: 'Customer Invoice',

  // Owner Invoice information
  owner_billing: 'Owner Billing',
  sales_tax_id: 'Sales Tax ID',

  successfully_generate_invoice_by_booking_id:
    'Successfully Generated Invoice by Booking Id',

  // Owner Invoice information
  dear: 'Dear',
  here_with_you_receive_the_current_rent_statement_for_your_object:
    'Here with you receive the current rental statement for your object',
  with_the_invoice_number: 'with the invoice number',
  For_the_mentioned_period_we_can_invoice_the_following_booking:
    'For the mentioned period we can invoice the following booking',
  your_income: 'Your Income',
  vat: 'Vat',
  our_services: 'Our Services',
  vat_included: 'Vat included',
  accounting: 'Accounting',
  total_net: 'Total Net',
  balance_to_your_credit: 'Balance to your credit',
  the_clearing_ammount_will_be_transferred_to_your_account_in_the_next_few_days:
    'The clearing amount will be transferred to your account in the next few days',
  net_total: 'Net Total',
  invoice_total: 'Total',
  payment_detail: 'Payment Detail',
  überwiesen: '',
};
export default en;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { ExitFullScreen } from 'assets/icons';
import Column from 'components/task/TaskBoard';
import TicketAddMenu from 'components/ticket/TicketAddMenu';
import TicketSearch from 'components/ticket/TicketSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, DEFAULT_REFETCH_TIME } from 'constants/common';
import routes from 'constants/routes';
import { TicketSchema } from 'constants/schema';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { BiFilter } from 'react-icons/bi';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import { reorderTickets } from 'utils/reorder';
import { v4 as uuidv4 } from 'uuid';

interface FilterParams {
  title: string;
  priority: string;
  status: string;
  assigned_user_id: string;
  apartment_id: string;
  customer_id: string;
  customer: string;
  external_company_id: string;
  company: string;
  source: string;
  created_at_start: Date | null;
  created_at_end: Date | null;
  due_date_start: Date | null;
  due_date_end: Date | null;
  type: string;
  order_by: string;
  order: any;
}

const TicketBoard = () => {
  /**
   * Initialize
   */
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const ticketBoardRef = useRef<any>(null);

  const ticketBoardFullScreenHandler = () => {
    const fullscreenElement = document.fullscreenElement;
    if (!fullscreenElement) {
      ticketBoardRef.current.requestFullscreen &&
        ticketBoardRef.current.requestFullscreen();
    } else {
      document.exitFullscreen && document.exitFullscreen();
    }
  };

  useEffect(() => {
    const escFullScreenListener = () => {
      const isFullScreen: boolean = !!document.fullscreenElement;
      setFullScreen(isFullScreen);
    };
    document.addEventListener('fullscreenchange', escFullScreenListener);
    return () =>
      document.removeEventListener('fullscreenchange', escFullScreenListener);
  }, [fullScreen]);

  let defaultIndex = search ? 0 : -1;

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const [filter, setFilter] = useState<FilterParams>({
    title: searchValues.title ?? '',
    priority: searchValues.priority ?? '',
    status: searchValues.status ?? '',
    assigned_user_id: searchValues.assigned_user_id ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    customer_id: searchValues.customer_id ?? '',
    customer: searchValues.customer ?? '',
    external_company_id: searchValues.external_company_id ?? '',
    company: searchValues.company ?? '',
    source: searchValues.source ?? '',
    created_at_start: searchValues.created_at_start
      ? new Date(searchValues.created_at_start)
      : null,
    created_at_end: searchValues.created_at_end
      ? new Date(searchValues.created_at_end)
      : null,
    due_date_start: searchValues.due_date_start
      ? new Date(searchValues.due_date_start)
      : null,
    due_date_end: searchValues.due_date_end
      ? new Date(searchValues.due_date_end)
      : null,
    type: searchValues.type ?? '',
    order_by: searchValues.order_by ?? '',
    order: searchValues.order ?? '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [tickets, setTickets] = useState<TicketSchema>({
    open: [],
    inProgress: [],
    done: [],
    closed: [],
  });
  // Meta information for ticket fetch (pagination)
  const [ticketsMeta, setTicketsMeta] = useState<any>({
    open: {},
    inProgress: {},
    done: {},
    closed: {},
  });

  const fetchTicketList = useRef(() => {});

  const ticketApi = new TicketResource();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const fetchTicketListByStatus = async (status: string, page: number = 1) => {
    const statusToSend = status === 'inProgress' ? 'in-progress' : status;
    let query: any = {};
    if (filter) {
      query = filter;
      query['status'] = statusToSend;
    } else {
      query = { status: statusToSend };
    }
    query.page = page;
    query.limit = DEFAULT_PAGE_SIZE + 3;
    const queryData = cleanData(query);

    const response = await ticketApi.list(queryData);
    return [response.data.data, response.data.meta];
  };

  /**
   * Get Ticket List
   * Status = open , in-progress, done, closed
   */
  fetchTicketList.current = async () => {
    try {
      setIsLoading(true);
      const [openTicketList, openTicketMeta] = await fetchTicketListByStatus(
        'open'
      );
      const [inProgressTicketList, inProgressTicketMeta] =
        await fetchTicketListByStatus('in-progress');
      const [doneTicketList, doneTicketMeta] = await fetchTicketListByStatus(
        'done'
      );
      const [closedTicketList, closedTicketMeta] =
        await fetchTicketListByStatus('closed');
      setTickets({
        open: openTicketList,
        inProgress: inProgressTicketList,
        done: doneTicketList,
        closed: closedTicketList,
      });
      setTicketsMeta({
        open: openTicketMeta,
        inProgress: inProgressTicketMeta,
        done: doneTicketMeta,
        closed: closedTicketMeta,
      });
      setInitialRender(false);
    } catch (err: any) {
      if (err.response !== undefined) {
        //if error is isn't cancelled by user
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetch more tickets for a particular ticket status
   *
   * @param ticketStatus 'open' | 'inProgress' | 'done' | 'closed'
   */
  const handleLoadMore = async (ticketStatus: string) => {
    if (!ticketStatus) return;
    let currentPage = ticketsMeta[ticketStatus].current_page;
    const [ticketList, ticketMeta] = await fetchTicketListByStatus(
      ticketStatus,
      ++currentPage
    );
    setTickets((prevState) => ({
      ...prevState,
      [ticketStatus]: [...prevState[ticketStatus], ...ticketList],
    }));
    setTicketsMeta((prevState: any) => ({
      ...prevState,
      [ticketStatus]: ticketMeta,
    }));
  };

  /**
   * Update total of ticket categories
   *
   * If user picks ticket from `Done` to `Closed`,
   * increase the total of closed by 1 and
   * decrease the total of done by 1
   *
   * @param source sourceId
   * @param destination destinationId
   */
  const updateTotalTickets = (source: string, destination: string) => {
    setTicketsMeta((prevState: any) => ({
      ...prevState,
      [source]: {
        ...prevState[source],
        total: --prevState[source].total,
      },
      [destination]: {
        ...prevState[destination],
        total: ++prevState[destination].total,
      },
    }));
  };

  //  Auto Fetch after 90 second
  useEffect(() => {
    const autoreload = setInterval(() => {
      fetchTicketList.current();
    }, DEFAULT_REFETCH_TIME);
    return () => {
      clearInterval(autoreload);
    };
  }, []);

  useEffect(() => {
    if (filter) fetchTicketList.current();
  }, [filter]);

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) return;

    setTickets(
      reorderTickets(
        tickets,
        source,
        destination,
        loggedInUser.id,
        updateTotalTickets
      )
    );
  };
  const handleAdvancedSearch = useCallback(
    (data: any) => {
      if (data.created_at_start) {
        data.created_at_start = DateFormatYMD(data.created_at_start);
      }
      if (data.created_at_end) {
        data.created_at_end = DateFormatYMD(data.created_at_end);
      }
      if (data.due_date_start) {
        data.due_date_start = DateFormatYMD(data.due_date_start);
      }
      if (data.due_date_end) {
        data.due_date_end = DateFormatYMD(data.due_date_end);
      }

      setFilter((prevState: any) => ({
        ...prevState,
        title: data.title,
        priority: data.priority,
        status: data.status,
        assigned_user_id: data.assigned_user_id,
        apartment_id: data.apartment_id,
        customer_id: data.customer_id,
        customer: data.customer,
        external_company_id: data.external_company_id,
        company: data.company,
        source: data.source,
        created_at_start: data.created_at_start,
        created_at_end: data.created_at_end,
        due_date_start: data.due_date_start,
        due_date_end: data.due_date_end,
        type: data.type,
        order_by: data.order_by,
        order: data.order,
      }));
      const searchURL = changeURL(data);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.ticket.task.board}>
            {strings.ticket}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.ticket_board}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading
          size="lg"
          textTransform="capitalize"
          display="flex"
          alignItems="center">
          {strings.ticket_board}
          {isLoading && !initialRender && (
            <Center ml={5}>
              <Spinner color="gray.300" size="sm" />
            </Center>
          )}
        </Heading>
        <Stack direction="row">
          <TicketAddMenu path={routes.ticket.task.create} />
          <Tooltip hasArrow label={strings.full_screen}>
            <Button
              size="lg"
              border="1px solid #EBECF2"
              backgroundColor="gray.50"
              color="paragraph"
              type="button"
              padding="8px"
              onClick={ticketBoardFullScreenHandler}>
              <Icon as={BsArrowsFullscreen} w={6} h={6} />
            </Button>
          </Tooltip>
        </Stack>
      </Flex>

      <Accordion
        bg="white"
        borderColor="white"
        allowToggle
        boxShadow="box"
        defaultIndex={[defaultIndex]}>
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <TicketSearch
              isStatus={false}
              handleAdvancedSearch={handleAdvancedSearch}
              filter={filter}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {isError && (
        <Alert status="error">
          <AlertIcon />
          {strings.ticket_list_error}
        </Alert>
      )}

      <Stack
        direction="row"
        spacing="4"
        width="100%"
        overflow="auto hidden"
        marginBottom="50px !important"
        backgroundColor="gray.50"
        position="relative"
        ref={ticketBoardRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.entries(tickets).map(([key, value], index) => (
            <Column
              key={uuidv4()}
              ticketStatus={key}
              ticketList={value}
              ticketMeta={ticketsMeta[key]}
              handleLoadMore={handleLoadMore}
              isLoading={isLoading}
              initialRender={initialRender}
            />
          ))}
        </DragDropContext>
        {fullScreen && (
          <>
            <Button
              size="md"
              colorScheme="primary"
              type="button"
              position="absolute"
              bottom="10px"
              right="10px"
              padding="8px"
              onClick={ticketBoardFullScreenHandler}>
              <Image
                src={ExitFullScreen}
                alt="Exit Fullscreen"
                w="16px"
                h="16px"
                filter="invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%)"
              />
            </Button>
            {isLoading && (
              <Box
                w="100%"
                backgroundColor="transparent"
                position="absolute"
                top="0px"
                marginInlineStart="0px !important">
                <Center
                  width="max-content"
                  margin="auto"
                  borderRadius="50%"
                  backgroundColor="gray.50"
                  padding="10px">
                  <Spinner color="gray.300" size="lg" />
                </Center>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default TicketBoard;

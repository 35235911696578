import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { CenterSpinner } from 'components/common/CenterSpinner';
import Billability from 'components/ticket/Billability';
import RecursiveInfo from 'components/ticket/RecursiveInfo';
import TicketAuthor from 'components/ticket/TicketAuthor';
import TicketComment from 'components/ticket/TicketComment';
import TicketInfo from 'components/ticket/TicketInfo';
import TicketLogInfo from 'components/ticket/TicketLogInfo';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { TicketSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import { truncateString } from 'utils';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

const ViewTicket: React.FC = () => {
  const { search } = useLocation();

  function matchURL() {
    return matchPath(location.pathname, {
      path: routes.ticket.task.view,
      exact: true,
      strict: false,
    });
  }
  const isTicketBoardView = matchURL();
  const { id }: any = useParams();
  const ticketApi = new TicketResource();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const ticketQuery = useQuery<TicketSchema>(
    [`ticket${id}`, id],
    () => ticketApi.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (ticketQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ticketQuery.isError) {
    history.push(routes.ticket.list.default);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                (isTicketBoardView
                  ? routes.ticket.task.board
                  : routes.ticket.list.default) + search
              }>
              {isTicketBoardView ? strings.ticket : strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              #{id} - {truncateString(ticketQuery?.data?.title)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <TicketInfo
          ticket={ticketQuery?.data}
          path={
            isTicketBoardView
              ? routes.ticket.task.edit
              : routes.ticket.list.edit
          }
        />

        {ticketQuery?.data?.recursive_interval && (
          <RecursiveInfo
            recursiveInterval={ticketQuery?.data?.recursive_interval}
            recursiveFrom={ticketQuery?.data?.recursive_from}
            recursiveUntil={ticketQuery?.data?.recursive_until}
          />
        )}

        <TicketAuthor
          createdBy={ticketQuery?.data?.created_by_user}
          createdAt={DateFormat(ticketQuery?.data?.createdAt)}
          lastChangedBy={ticketQuery?.data?.updated_by_user}
          lastChangedAt={DateFormat(ticketQuery?.data?.updatedAt)}
          source={ticketQuery?.data?.source}
        />

        {ticketQuery?.data?.is_billable && (
          <Billability ticket={ticketQuery?.data} updatedBy={loggedInUser.id} />
        )}

        <TicketLogInfo ticketId={ticketQuery?.data?.id} />

        <TicketComment ticketId={ticketQuery?.data?.id} />
      </Stack>
    </>
  );
};

export default ViewTicket;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import BMSCheckInResource from 'api/bms-check-in';
import BMSCheckOutResource from 'api/bms-check-out';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import AppartmentInformationOverview from 'components/bms_booking/bms_booking_overview/AppartmentInformationOverview';
import CustomerInformationOverview from 'components/bms_booking/bms_booking_overview/CustomerInformationOverview';
import ServiceInformationCard from 'components/bms_booking/bms_booking_overview/ServiceInformationCard';
import PaymentInfo from 'components/bms_booking/PaymentInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE } from 'constants/common';
import routes from 'constants/routes';
import { BookingSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const EditBooking = () => {
  const { id: reservationID }: any = useParams();
  const { search } = useLocation();
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

  const methods = useForm<BookingSchema>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [serviceBookingErrorMessage, setServiceBookingErrorMessage] =
    useState<string>('');
  const [arrivalStatus, setArrivalStatus] = useState('');

  const bmsBookingAPI = new BMSBookingResource();
  const bmsCheckInAPI = new BMSCheckInResource();
  const bmsCheckOutAPI = new BMSCheckOutResource();
  const serviceGroupAPI = new SMSServiceGroupResource();

  const {
    data: bookingDetailData,
    isLoading,
    isError,
  } = useQuery(
    `bookingDetails-${reservationID}`,
    () =>
      bmsBookingAPI
        .get(reservationID)
        .then((res) => res.data.data)
        .catch((err) => err),
    {
      refetchOnWindowFocus: false,
    }
  );

  const serviceGroupsQuery = useQuery(
    [`service-group`],
    () =>
      serviceGroupAPI
        .list({ limit: DEFAULT_PAGE_SIZE * 3 })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const checkIn = useMutation((id: any) => bmsCheckInAPI.checkIn(id));
  const checkOut = useMutation((id: any) => bmsCheckOutAPI.checkOut(id));

  const addBooking = useMutation((data: any) => bmsBookingAPI.store(data), {
    onSuccess: () => {
      toast({
        title: strings.booking_created,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('booking-list');
      history.push(routes.bms.booking.list);
    },
    onError: (err: any) => {
      const errors = err?.response?.data?.errors;
      if (errors) {
        const errorFields: Array<keyof BookingSchema> = Object.keys(
          errors
        ) as Array<keyof BookingSchema>;
        errorFields.forEach((field) => {
          // if service bookings error occurs in backend, set error message to state because
          // service bookings isnt registered to react-hook-form
          if (field.includes('service_bookings')) {
            setServiceBookingErrorMessage(
              strings.please_verify_your_service_bookings
            );
          }
          methods.setError(
            field,
            {
              type: 'manual',
              message: errors[field].join('. '),
            },
            {
              shouldFocus: true,
            }
          );
        });
      }
    },
  });

  function openModal(e: any) {
    let { value } = e.target;
    setArrivalStatus(value);
    if (!value) return;
    onOpen();
  }
  function changeArrivalStatus() {
    let API: any;
    if (!arrivalStatus) return;
    if (arrivalStatus === 'angereist') {
      API = checkIn;
    } else {
      API = checkOut;
    }
    API.mutate(reservationID, {
      onSuccess: () => {
        toast({
          title: strings.success,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(`bookingDetails-${reservationID}`);
        onClose();
      },
      onError: (err: any) => {
        toast({
          title: strings.error,
          description: err?.response?.data?.message,
          status: 'error',
          isClosable: true,
        });
        onClose();
      },
    });
  }

  if (serviceGroupsQuery.isLoading || isLoading) {
    return <CenterSpinner />;
  } else if (isError) {
    return <div>Error</div>;
  }

  let {
    customer,
    service_booking,
    payment,
    reservation,
    booking_register,
    from_date,
    to_date,
    apartment,
    guest_info,
  }: any = bookingDetailData;

  let isCheckout = booking_register?.check_out;
  let isCheckin = booking_register?.check_in;

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.booking_management}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
            {strings.booking_overview}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.bms.booking.details.replace(':id', reservationID)}>
            {bookingDetailData.booking_number}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg">{strings.edit_booking}</Heading>
        <Box>
          <Select
            placeholder="Reisestatus auswählen"
            size="lg"
            onChange={openModal}
            value={isCheckout ? 'abgereist' : isCheckin ? 'angereist' : ''}
            isRequired={false}>
            <option value="angereist">{strings.check_in}</option>
            <option value="abgereist">{strings.check_out}</option>
          </Select>
        </Box>
      </Flex>

      {addBooking.isError && (
        <Alert status="error">
          <AlertIcon />
          {strings.edit_booking}&nbsp;{strings.failed}
        </Alert>
      )}

      <Accordion
        borderColor="white"
        allowToggle
        allowMultiple
        defaultIndex={[0, 1, 2, 3]}>
        <Stack direction="column" spacing="4">
          <CustomerInformationOverview customerInformation={customer} />
          <AppartmentInformationOverview
            appartmentInformation={bookingDetailData}
          />
          <AccordionItem mt="12">
            <h2>
              <AccordionButton background="gray.50">
                <Box flex="1" textAlign="left" fontWeight={700} fontSize="22">
                  {strings.service_information}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} sx={wrapperStyles}>
              <Stack gap="4">
                <ServiceInformationCard
                  editing={true}
                  serviceReserved={bookingDetailData?.service_booking}
                />
                <Box textAlign="right">
                  <RouterLink
                    to={
                      routes.bms.booking.services.replace(
                        ':id',
                        reservationID
                      ) + search
                    }>
                    <Button colorScheme="primary" variant="outline" size="lg">
                      {strings.add_more_services}
                    </Button>
                  </RouterLink>
                </Box>
              </Stack>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem borderColor="white" boxShadow="box" bg="white">
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      {strings.payment_status}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <Stack direction="column" spacing="4">
                  <PaymentInfo
                    editing={true}
                    data={bookingDetailData}
                    id={reservationID}
                  />
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Stack>
      </Accordion>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            {strings.guest}&nbsp;
            {arrivalStatus === 'angereist'
              ? strings.check_in
              : strings.check_out}
          </ModalHeader>
          <ModalBody>
            {strings.formatString(
              arrivalStatus === 'angereist'
                ? strings.confirm_checkin
                : strings.confirm_checkout,
              <strong>{customer.name}</strong>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="purple"
                onClick={changeArrivalStatus}
                isLoading={checkIn.isLoading || checkOut.isLoading}>
                <Icon as={BiCalendar} mr="2" />
                {arrivalStatus === 'angereist'
                  ? strings.checkin_now
                  : strings.checkout_now}
              </Button>
              <Button variant="outline" onClick={onClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default EditBooking;

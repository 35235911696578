import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  serviceBookingsPriceList: any;
  totalServicePrice: number;
}

const ServiceInformation = (props: Props) => {
  const { serviceBookingsPriceList, totalServicePrice } = props;

  return (
    <Box>
      <Heading mb="2" size="md">
        {strings.services}
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>{strings.service}</Th>
              <Th>{strings.from}</Th>
              <Th>{strings.until}</Th>
              <Th>{strings.quantity}</Th>
              <Th>{strings.unit_price} (CHF)</Th>
              <Th>{strings.booking_fee} (CHF)</Th>
              <Th isNumeric>{strings.total} (CHF)</Th>
            </Tr>
          </Thead>

          <Tbody>
            {serviceBookingsPriceList.map((item: any) => {
              const totalPrice =
                Number(item.booking_fee) + Number(item.total_price);
              return (
                <Tr key={item.id}>
                  <Td>{item.service_name}</Td>
                  <Td>{DateFormat(item.from_date) || '-'}</Td>
                  <Td>{DateFormat(item.to_date) || '-'}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>{formatCurrency(item.unit_price)}</Td>
                  <Td isNumeric>{formatCurrency(item.booking_fee)}</Td>
                  <Td isNumeric>{formatCurrency(totalPrice)}</Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr borderTopWidth="1px">
              <Th isNumeric colSpan={7}>
                {formatCurrency(totalServicePrice)}
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ServiceInformation;

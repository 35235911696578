import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FAQGroupResource from 'api/faq-groups';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  data: any;
  index: number;
}

const FaqGroupItem: React.FC<Props> = (props) => {
  let { data, index } = props;
  let { id: groupID, name, status } = data;

  const toast = useToast();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [localStatus, setLocalStatus] = useState<number>(status);
  const checkEditPermission = useIsPermitted([
    PermissionRequest['manage:faq-group'],
  ]);

  let faqGroupAPI = new FAQGroupResource();

  const updateStatus = useMutation((data: any) =>
    faqGroupAPI.updateFAQGroup(groupID, data)
  );

  const deleteFAQAPI = useMutation((id: number) => faqGroupAPI.destroy(id), {
    onSuccess: () => {
      setIsDeleteLoading(false);
      setDeleteModalOpen(false);
      toast({
        title: strings.success,
        description: strings.faq_deleted_successfully,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      queryClient.invalidateQueries('faq-list');
    },
    onError: (error: any) => {
      setDeleteErrorMsg(error?.response?.data?.error?.message);
    },
  });

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  function submitStatusUpdate(formData: any) {
    updateStatus.mutate(formData, {
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
        setLocalStatus(status);
      },
    });
  }

  function handleStatusChange(e: any) {
    let checked = e.target.checked ? 1 : 0;
    let stateObj = { status: checked, _method: 'PUT' };
    submitStatusUpdate(stateObj);
    setLocalStatus(checked);
  }

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setIsDeleteLoading(false);
  };

  const onDeleteConfirm = () => {
    setIsDeleteLoading(true);
    if (groupID) {
      deleteFAQAPI.mutate(groupID);
    }
  };

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>{capitalizeFirstLetter(name)}</Td>

      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="primary"
            isDisabled={!checkEditPermission}
            onChange={handleStatusChange}
            isChecked={!!localStatus}
          />
        </FormControl>
      </Td>
      <Td>
        <RouterLink
          to={routes.faq.faqGroup.edit.replace(':id', groupID.toString())}>
          <Tooltip hasArrow label={strings.edit_group}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_group}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          closeOnOverlayClick={!isDeleteLoading}
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_group}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={onDeleteConfirm}>
                  {strings.delete}
                </Button>
                <Button
                  variant="outline"
                  isDisabled={isDeleteLoading}
                  onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default FaqGroupItem;

const PermissionRequest = {
  /**
   * Permissions for User
   */
  'read:user': 'access-user',
  'manage:user': 'manage-user',
  'manage:external-companies': 'manage-external-companies',
  'read:login-log': 'access-user-log',
  /**
   * Permissions for Chat
   */
  'read:chat': 'access-chat',
  'show:chat-assign': 'access-chat-assign',
  'call:mediasoup': 'access-chat-mediasoup',
  'read:chat-metrics': 'access-chat-metrics',
  /**
   * Permission for Ticket
   */
  'read:ticket': 'access-ticket',
  'create:ticket': 'create-ticket',
  'edit:ticket': 'edit-ticket',
  /**
   * Permission for MMS
   */
  'read:mms': 'access-mms', // Can read Inventory, Article, Warehouse, Type, Unit, Transfer Log (along with their details) and access Transfer Items (can transfer item)
  'manage:article': 'manage-article', // Can edit/manage Inventory, Article, Warehouse, Type, Unit, Transfer Log
  'manage:stocktaking': 'stocktaking-mode', // Can access and manage stocktaking
  'show:hold-item': 'can-hold-item', // Employee with this right can be assigned a chat
  /**
   * Permission for MDM
   */
  'read:mdm': 'access-mdm',
  /**
   * Permission for CRM
   */
  'read:crm': 'access-crm',
  'merge:contacts': 'merge-contacts',
  'create:owner-crm': 'create-owner-crm',
  /**
   * Permissions for OMS
   */
  'read:oms': 'access-oms',
  'manage:oms': 'manage-oms',
  'sync:voffice': 'voffice-data-sync',
  /**
   * Permissions for BMS
   */
  'read:reservations': 'access-reservations',
  'read:bookings': 'access-bookings',
  'manage:bookings': 'manage-bookings',
  'read:invoice': 'access-invoices',
  'read:invoices': 'access-invoices',
  'read:bread-service': 'access-bread-service',
  'manage:bread-service': 'manage-bread-service',
  'read:bread-driving-order': 'access-bread-driving-order',
  'read:callbacks': 'access-callbacks',
  /**
   * Permissions for SMS
   */
  'read:sms': 'access-sms',
  'manage:sms': 'manage-sms',
  /**
   * Permissions for PMS
   */
  'read:pms': 'access-pms',
  /**
   * Permissions for PNS
   */
  'read:pns': 'access-pns',
  'send:push-notificaton': 'manage-pns',
  /**
   * Permissions for Faq Service
   */
  'read:faq': 'access-faq',
  'manage:faq': 'manage-faq',
  'read:internal-faq': 'access-faq-internal',
  'read:faq-group': 'access-faq-group',
  'manage:faq-group': 'manage-faq-group',
  /**
   * Permissions for DMS
   */
  'read:dms': 'access-dms-general',
  'manage:dms': 'manage-dms-general',
  'read:object-dms': 'access-dms-object',
  'manage:object-dms': 'manage-dms-object',
  'read:customer-dms': 'access-dms-customer',
  'manage:customer-dms': 'manage-dms-customer',
  'read:owner-dms': 'access-dms-owner',
  'manage:owner-dms': 'manage-dms-owner',
  'read:user-dms': 'access-dms-user',
  'manage:user-dms': 'manage-dms-user',
  /**
   * Permissions for Feedback Service
   */
  'read:feedback': 'access-feedback',
  'manage:feedback': 'access-feedback-questions',
  'resend:feedback': 'access-feedback-resend',
  /**
   * Permissions for Newsletter service
   */
  'read:newsletter': 'access-newsletter',
  'manage:newsletter': 'manage-newsletter',

  /**
   * Permissions for Contact Center Service
   */
  'read:contact-center': 'access-contact-center',
  /**
   * Permissions for Keyscan
   */
  'read:keylist': 'access-keyscan-keylist',
  'read:keylog': 'access-keyscan-keylog',
  /**
   * Permissions for Aerial Map
   */
  'read:aerial-map': 'access-aerial-map',
  'read:dashboard': 'access-dashboard',

  'read:terminal': 'access-terminal',

  /**
   * Permissions for owner login
   */
  'is:owner': 'access-owner',
};

export default PermissionRequest;

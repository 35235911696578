import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  IconButton,
  Image,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import OMSfeatureGroupsResource from 'api/oms-featureGroups';
import OMSObjectsResource from 'api/oms-objects';
import {
  Bilder,
  Einstellungen,
  Info,
  Location,
  SeasonPrices,
  TextSVG,
} from 'assets/icons';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiPencil, BiShow } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import AddressForm from '../Appartments/AddressForm';
import ApartmentImages from '../Appartments/ApartmentImages';
import BasicInformationForm from '../Appartments/BasicInformationForm';
import FeaturesForm from '../Appartments/FeaturesForm';
import DescriptionForm from '../Appartments/LyricsForm';
import SeasonPricesForm from '../Appartments/SeasonPricesForm';
import SettingsForm from '../Appartments/SettingsForm';

const apartmentTabStyles = {
  color: 'heading',
  fontWeight: '400',
  marginBottom: '1',
  bg: 'white',
  p: '13px',
  borderRadius: '3px',
  justifyContent: 'start',
  textAlign: 'left',
};
const formFieldsStyle = {
  bg: 'white',
  borderRadius: '3px',
  ml: '1',
  boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.06)',
};
const apartmentTabActiveStyles = {
  ...apartmentTabStyles,
  color: 'primary.500',
};
const ApartmentDetails: React.FC = () => {
  const { id: objectID }: any = useParams();
  const queryClient = useQueryClient();
  const [toggleEdit, setToggleEdit] = useState(false);
  const methods = useForm<any>();
  const local = useLocation();
  let { search } = local;

  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const apartmentAPI = new OMSObjectsResource();
  const featureGroupAPI = new OMSfeatureGroupsResource();

  const {
    data: apartmentDetails,
    isLoading,
    isError,
  } = useQuery(
    'apartmentDetails',
    () =>
      apartmentAPI
        .get(objectID)
        .then((res) => res.data.data)
        .catch((err) => {
          throw new Error("Can't get apartment details");
        }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: featureGroups, isLoading: featureGroupLoading } = useQuery(
    'featureGroupList',
    () => featureGroupAPI.list().then((res: any) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  } else if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.cannot_get_apartment_details}
      </Alert>
    );
  }
  let { name: apartmentName } = apartmentDetails;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.oms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.oms.objects.list + search}>
              {strings.object_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {apartmentName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex gridGap="2" alignItems="center">
          <Heading size="lg" textTransform="capitalize">
            {apartmentName}
          </Heading>
          <AccessControl allowedPermissions={[PermissionRequest['manage:oms']]}>
            <Tooltip hasArrow label={toggleEdit ? strings.view : strings.edit}>
              <IconButton
                icon={!toggleEdit ? <BiPencil /> : <BiShow />}
                variant="link"
                aria-label={strings.edit}
                onClick={() => setToggleEdit((prev) => !prev)}
                color="green.300"
                minW="6"
              />
            </Tooltip>
            <>{toggleEdit && <Text>{strings.editing}...</Text>}</>
          </AccessControl>
        </Flex>

        <Tabs orientation="vertical" variant="unstyled">
          <TabList w="xs" borderRight="1px" borderColor="gray.100">
            <Tab
              key="basic_information"
              sx={apartmentTabStyles}
              _selected={{ color: 'green' }}>
              <Box w="6" h="6" mr="2">
                <Image src={Info} />
              </Box>
              {strings.basic_information}
            </Tab>
            {featureGroupLoading &&
              Array(12)
                .fill(0)
                .map((_, index: number) => (
                  <Box key={index} sx={apartmentTabStyles}>
                    <Skeleton height="21px" width="100%" />
                  </Box>
                ))}
            {!featureGroupLoading && (
              <>
                <Tab
                  key="Address"
                  sx={apartmentTabStyles}
                  _selected={{ color: 'green' }}>
                  <Box w="6" h="6" mr="2">
                    <Image src={Location} />
                  </Box>
                  {strings.location}
                </Tab>
                <Tab
                  key="text"
                  sx={apartmentTabStyles}
                  _selected={{ color: 'green' }}>
                  <Box w="6" h="6" mr="2">
                    <Image src={TextSVG} />
                  </Box>
                  {strings.text}
                </Tab>
                <Tab
                  key="bilder"
                  sx={apartmentTabStyles}
                  _selected={{ color: 'green' }}>
                  <Box w="6" h="6" mr="2">
                    <Image src={Bilder} />
                  </Box>
                  {strings.images}
                </Tab>

                {featureGroups?.data?.map((item: any) => (
                  <Tab
                    key={item?.id}
                    sx={apartmentTabStyles}
                    _selected={{ color: 'green' }}>
                    {item.icon && (
                      <Box w="6" h="6" mr="2">
                        <Image src={item.icon.url} />
                      </Box>
                    )}
                    {item?.name}
                  </Tab>
                ))}
                <Tab
                  key="prices"
                  sx={apartmentTabStyles}
                  _selected={{ color: 'green' }}>
                  <Box w="6" h="6" mr="2">
                    <Image src={SeasonPrices} />
                  </Box>
                  {strings.season_and_prices}
                </Tab>
                <Tab
                  key="einstellungen"
                  sx={apartmentTabStyles}
                  _selected={{ color: 'green' }}>
                  <Box w="6" h="6" mr="2">
                    <Image src={Einstellungen} />
                  </Box>
                  {strings.settings}
                </Tab>
              </>
            )}
          </TabList>
          <TabPanels>
            <TabPanel key="basic_informatiom" sx={formFieldsStyle}>
              <BasicInformationForm
                editing={toggleEdit}
                data={apartmentDetails}
              />
            </TabPanel>
            <TabPanel key="address" sx={formFieldsStyle}>
              <AddressForm editing={toggleEdit} data={apartmentDetails} />
            </TabPanel>
            <TabPanel key="text" sx={formFieldsStyle}>
              <DescriptionForm data={apartmentDetails} editing={toggleEdit} />
            </TabPanel>
            <TabPanel key="bilder" sx={formFieldsStyle}>
              <ApartmentImages data={apartmentDetails} editing={toggleEdit} />
            </TabPanel>
            {featureGroups?.data?.map((item: any) => (
              <TabPanel key={item.id} sx={formFieldsStyle}>
                <FeaturesForm
                  apartmentId={objectID}
                  groupName={item.name}
                  groupId={item.id}
                  editing={toggleEdit}
                />
              </TabPanel>
            ))}
            <TabPanel key="prices" sx={formFieldsStyle}>
              <SeasonPricesForm data={apartmentDetails} editing={toggleEdit} />
            </TabPanel>
            <TabPanel key="einstellungen" sx={formFieldsStyle}>
              <SettingsForm data={apartmentDetails} editing={toggleEdit} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  );
};

export default ApartmentDetails;

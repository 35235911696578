import {
  THEME_AERIAL_MAP,
  THEME_BMS,
  THEME_CHAT,
  THEME_CONTACT_CENTER,
  THEME_CRM,
  THEME_DASHBOARD,
  THEME_DMS,
  THEME_FAQ,
  THEME_FEEDBACK,
  THEME_KEY,
  THEME_NEWS,
  THEME_OMS,
  THEME_PMS,
  THEME_PNS,
  THEME_SMS,
  THEME_TERMINAL,
  THEME_TICKET,
  THEME_USER,
} from 'constants/common';
import React, { createContext, useEffect, useMemo, useState } from 'react';
interface ThemeContextInterface {
  theme: any;
  setTheme?: any;
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeContext = createContext<ThemeContextInterface>({
  theme: 'default',
});

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState('theme-default');

  useEffect(() => {
    document.body.classList.remove(
      THEME_USER,
      THEME_CHAT,
      THEME_TICKET,
      THEME_CRM,
      THEME_DMS,
      THEME_CONTACT_CENTER,
      THEME_FEEDBACK,
      THEME_OMS,
      THEME_SMS,
      THEME_BMS,
      THEME_PMS,
      THEME_PNS,
      THEME_FAQ,
      THEME_DASHBOARD,
      THEME_NEWS,
      THEME_KEY,
      THEME_AERIAL_MAP,
      THEME_TERMINAL
    );
    if (theme) document.body.classList.add('theme-default');
  }, [theme, setTheme]);

  const defaultValue = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme, setTheme]
  );

  return (
    <ThemeContext.Provider value={defaultValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

const routes = {
  dashboard: '/',
  splash: '/splash',
  profile: {
    base: '/profile/*',
    myProfile: '/profile/my-profile',
    edit: '/profile/edit',
    socket: '/profile/socket',
  },
  customerScreen: {
    base: '/customer-screen',
  },
  users: {
    base: '/users/info/*',
    list: '/users/info/list',
    add: '/users/info/add',
    edit: '/users/info/edit/:id',
    profile: '/users/info/profile/:id',
    roles: {
      base: '/users/roles/*',
      list: '/users/roles/list',
      view: '/users/roles/:id',
    },
    externalCompanies: {
      base: '/users/externalCompanies/*',
      list: '/users/externalCompanies/list',
      add: '/users/externalCompanies/add',
      edit: '/users/externalCompanies/edit/:id',
      profile: '/users/externalCompanies/profile/:id',
    },
    loginLog: {
      base: '/users/login-log/*',
      list: '/users/login-log/list',
    },
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
  },
  chat: {
    default: '/chat/app',
    closed: {
      base: '/chat/closed/*',
      list: '/chat/closed/list',
      details: '/chat/closed/details/:id',
    },
    archived: {
      base: '/chat/archived/*',
      list: '/chat/archived/list',
      details: '/chat/archived/details/:id',
    },
    metrics: '/chat/metrics',
  },
  ticket: {
    task: {
      base: '/ticket/task/*',
      board: '/ticket/task/board',
      create: '/ticket/task/create',
      edit: '/ticket/task/edit/:id',
      details: '/ticket/task/details',
      view: '/ticket/task/view/:id',
    },
    list: {
      base: '/ticket/list/*',
      create: '/ticket/list/create',
      edit: '/ticket/list/edit/:id',
      details: '/ticket/list/details',
      view: '/ticket/list/view/:id',
      default: '/ticket/list/all',
    },
    objectsInfo: '/ticket/objectsInfo',
  },
  crm: {
    contact: {
      base: '/crm/contact/*',
      list: '/crm/contact/list',
      add: '/crm/contact/add',
      edit: '/crm/contact/edit/:id',
      details: '/crm/contact/details/:id',
    },
    merge: {
      base: '/crm/merge/*',
      list: '/crm/merge/list',
    },
    logs: {
      base: '/crm/logs/*',
      list: '/crm/logs/list',
    },
  },
  oms: {
    objects: {
      base: '/oms/objects/*',
      list: '/oms/objects/list',
      add: '/oms/objects/add',
      details: '/oms/objects/details/:id',
    },
    season: {
      base: '/oms/season/*',
      list: '/oms/season/list',
      add: '/oms/season/add',
      edit: '/oms/season/edit/:id',
    },
    appartments: {
      address: '/oms/appartments/address',
    },
  },
  sms: {
    services: {
      base: '/sms/services/*',
      list: '/sms/services/list',
      add: '/sms/services/add',
      edit: '/sms/services/edit/:id',
      details: '/sms/services/details/:id',
    },
    serviceGroups: {
      base: '/sms/service-groups/*',
      list: '/sms/service-groups/list',
      add: '/sms/service-groups/add',
      edit: '/sms/service-groups/edit/:id',
      details: '/sms/service-groups/details/:id',
    },
  },
  bms: {
    reservation: {
      base: '/bms/reservation/*',
      list: '/bms/reservation/list',
      add: '/bms/reservation/add',
      edit: '/bms/reservation/edit/:id',
      details: '/bms/reservation/details/:id',
    },
    booking: {
      base: '/bms/booking/*',
      list: '/bms/booking/list',
      add: '/bms/booking/add',
      edit: '/bms/booking/edit/:id',
      details: '/bms/booking/details/:id',
      services: '/bms/booking/edit/:id/services',
    },
    checkInCheckOut: {
      list: '/bms/checkInCheckOut/list',
    },
    bookingCalendar: {
      base: '/bms/booking-calendar',
    },
    reservationRequest: {
      base: '/bms/reservation-requests/*',
      list: '/bms/reservation-requests/list',
      details: '/bms/reservation-requests/details/:id',
    },
    ferryBooking: {
      base: '/bms/ferry-bookings/*',
      list: '/bms/ferry-bookings/list',
      details: '/bms/ferry-bookings/details/:id',
    },
    callback: {
      base: '/bms/callback/*',
      list: '/bms/callback/list',
      details: '/bms/callback/details/:id',
    },
    invoices: {
      base: '/bms/invoices/*',
      list: '/bms/invoices/list',
    },
  },
  faq: {
    faqList: {
      base: '/faq/faq-list/*',
      list: '/faq/faq-list/list',
      add: '/faq/faq-list/add',
      edit: '/faq/faq-list/edit/:id',
    },
    faqGroup: {
      base: '/faq/faq-group/*',
      list: '/faq/faq-group/list',
      add: '/faq/faq-group/add',
      edit: '/faq/faq-group/edit/:id',
    },
    internalfaq: {
      base: '/faq/internal-faq/*',
      list: '/faq/internal-faq/list',
    },
  },
  dms: {
    generalList: {
      list: '/dms/generalList/list',
      upload: '/dms/generalList/upload',
      edit: '/dms/generalList/edit/:id',
    },
    objectDocuments: {
      list: '/dms/objectDocuments/list',
      upload: '/dms/objectDocuments/upload',
      edit: '/dms/objectDocuments/edit/:id',

      objects: {
        list: '/dms/objectDocuments/objects/list/:id',
        upload: '/dms/objectDocuments/objects/upload/:id',
      },
    },
    ownerDocuments: {
      list: '/dms/ownerDocuments/list',
      upload: '/dms/ownerDocuments/upload',
      edit: '/dms/ownerDocuments/edit/:id',
      owner: {
        list: '/dms/ownerDocuments/owner/list/:id',
        upload: '/dms/ownerDocuments/owner/upload/:id',
      },
    },

    customerDocuments: {
      list: '/dms/customerDocuments/list',
      upload: '/dms/customerDocuments/upload',
      edit: '/dms/customerDocuments/edit/:id',
      customer: {
        list: '/dms/customerDocuments/customer/list/:id',
        upload: '/dms/customerDocuments/customer/upload/:id',
      },
    },
    userDocuments: {
      list: '/dms/userDocuments/list',
      upload: '/dms/userDocuments/upload',
      edit: '/dms/userDocuments/edit/:id',
      user: {
        list: '/dms/userDocuments/user/list/:id',
        upload: '/dms/userDocuments/user/upload/:id',
      },
    },
  },
  pms: {
    creditCard: {
      list: '/pms/credit-card/list',
    },
    paypal: {
      list: '/pms/paypal/list',
    },
    paymentLinks: {
      list: '/pms/paymentLinks/list',
      add: '/pms/paymentLinks/add',
    },
    bankTransfer: {
      list: '/pms/bank-transfer/list',
    },
  },
  pns: {
    notification: {
      list: '/pns/push-notification/list',
      add: '/pns/push-notification/add',
    },
  },
  contactCenter: {
    members: {
      list: '/contact-center/members/list',
      profile: '/contact-center/members/profile/:id',
    },
    totalCalls: {
      list: '/contact-center/totalCalls/list',
    },
  },
  feedback: {
    dashboard: '/feedback/dashboard',
    analysis: '/feedback/analysis',
    feedbacks: {
      base: '/feedback/feedbacks/*',
      list: '/feedback/feedbacks/list',
      details: '/feedback/feedbacks/details/:id',
    },
    mails: {
      base: '/feedback/mails/*',
      list: '/feedback/mails/list',
    },
    questions: {
      base: '/feedback/questions/*',
      list: '/feedback/questions/list',
      add: '/feedback/questions/add',
      edit: '/feedback/questions/edit/:id',
    },
    setting: {
      base: '/feedback/setting/*',
      list: '/feedback/setting/list',
    },
  },
  newsletter: {
    newsletters: {
      base: '/newsletter/newsletters/*',
      list: '/newsletter/newsletters/list',
      view: '/newsletter/newsletters/view/:id',
      add: '/newsletter/newsletters/add',
      edit: '/newsletter/newsletters/edit/:id',
    },
    draft: {
      list: '/newsletter/draft/list',
    },
  },
  key: {
    keyList: {
      base: '/key/keyList/*',
      list: '/key/keyList/list',
      view: '/key/keyList/view/:id',
      add: '/key/keyList/add',
      edit: '/key/keyList/edit/:id',
    },
    KeyLogs: {
      base: '/key/KeyLogs/*',
      list: '/key/KeyLogs/list',
      view: '/key/KeyLogs/view/:id',
      keyHandle: '/key/KeyLogs/KeyHandle',
    },
  },
};

export default routes;

import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ComposeNewsletterForm from 'components/newsletter/ComposeNewsletterForm';
import { strings } from 'config/localization';
import { NEWSLETTER_STATUS_CONFIRMED } from 'constants/common';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const EditNewsLetter: React.FC = (props) => {
  const newsletterAPI = new NewsletterAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const [errMsg, setErrMsg] = useState('');
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm();
  const queryClient = useQueryClient();
  const { id: newsletterId }: any = useParams();
  const [preview, setPreview] = useState<boolean>(false);
  const [sendButtonClick, setSendButtonClick] = useState<boolean>(false);
  const tinymceRef = useRef<any>();

  const { data: newsletterDetail, isLoading: newsletterDetailLoading } =
    useQuery(
      ['newsletter-edit', newsletterId],
      async () => {
        const result = await newsletterAPI
          .get(newsletterId)
          .then((res) => res.data.data);
        return result;
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  if (newsletterDetail?.status === 'confirmed') {
    history.replace(routes.newsletter.newsletters.list);
  }

  const composeNewsletter = useMutation((data: any) =>
    newsletterAPI.update(newsletterId, data)
  );

  const uploadFiles = useMutation((formData: any) =>
    newsletterAPI.uploadToBucket(formData)
  );

  const handleUploadFiles = async (files: any) => {
    const result: {
      file_id: string;
      file_name: string;
      mime_type: string;
      file_size: string;
      file_path: string;
    }[] = [];

    for (let index = 0; index < files.length; index += 1) {
      const formData = new FormData();
      formData.append('image', files[index], files[index].name);

      const uploads: any = await uploadFiles.mutateAsync(formData);
      if (uploads.status === 201) {
        const { file_id, file_name, mime_type, file_size, file_path } =
          uploads.data;

        result.push({
          file_id: file_id,
          file_name: file_name,
          file_path: file_path,
          file_size: file_size,
          mime_type: mime_type,
        });
      }
    }
    return result;
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const formData = { ...data };
    let uploadedFilesIds = newsletterDetail?.newsletterFiles?.map(
      (file: any) => file.id
    );

    if (data?.file && data?.file?.length > 0) {
      const uploadedFiles = await handleUploadFiles(data.file);
      const newUploadedFiles = uploadedFiles.map((file: any) => file.file_id);
      uploadedFilesIds = [...uploadedFilesIds, ...newUploadedFiles];
    }
    formData.file = uploadedFilesIds;

    composeNewsletter.mutate(formData, {
      onSuccess: () => {
        const newsletterStatus = data.status;
        const confirmedNewsletterRoute = routes.newsletter.newsletters.list;
        const draftNewsletterRoute = routes.newsletter.draft.list;
        let selectRoute: string, updateMsg: string, listToRedirect: string;

        if (newsletterStatus === NEWSLETTER_STATUS_CONFIRMED) {
          selectRoute = confirmedNewsletterRoute;
          updateMsg = strings.newsletter_sent_sucessfully;
          listToRedirect = 'newsLetterList';
        } else {
          selectRoute = draftNewsletterRoute;
          updateMsg = strings.newsletter_updated_sucessfully;
          listToRedirect = 'newsLetterDraftList';
        }
        queryClient.invalidateQueries(listToRedirect);
        toast({
          title: updateMsg,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(selectRoute);
      },
      onError: () => {
        setErrMsg(`${strings.newsletter} error `);
        setIsLoading(false);
      },
    });
  };

  const validateMandatoryFields = () => {
    if (!methods.getValues('subject')) {
      methods.setError('subject', {
        message: strings.subject_field_is_required,
      });
    }
    if (!tinymceRef.current.currentContent) {
      methods.setError('message', {
        message: strings.message_field_is_required,
      });
    }
    if (methods.getValues('recipient_group')?.length === 0) {
      methods.setError('recipient_group', {
        message: strings.recipient_group_field_is_required,
      });
    }
  };

  const handleDraftButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(methods.formState.errors).length > 0) {
      return;
    }

    const formValues = methods.getValues();
    formValues.status = 'pending';
    onSubmit(formValues);
  };

  if (newsletterDetailLoading) {
    return <CenterSpinner />;
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.newsletter.draft.list}>
            {strings.drafts}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink onClick={() => setPreview(false)}>
            {strings.edit_newsletter}
          </BreadcrumbLink>
        </BreadcrumbItem>
        {preview && (
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.preview_message}</BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {preview ? strings.preview_message : strings.edit_newsletter}
        </Heading>
        <Button
          variant="outline"
          size="lg"
          colorScheme="primary"
          onClick={handleDraftButtonClick}
          isLoading={
            newsletterDetail?.status === 'pending' ? isLoading : false
          }>
          {strings.save_as_draft}
        </Button>
      </Flex>
      <FormProvider {...methods}>
        <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          {!newsletterDetailLoading && (
            <ComposeNewsletterForm
              data={newsletterDetail}
              onSubmit={onSubmit}
              isLoading={isLoading}
              tinymceRef={tinymceRef}
              validateMandatoryFields={validateMandatoryFields}
            />
          )}
        </form>
      </FormProvider>
    </Stack>
  );
};

export default EditNewsLetter;

import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import ServiceGroupForm from 'components/service_group/ServiceGroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ServiceGroupSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AddServiceGroup: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<ServiceGroupSchema>();
  const serviceGrouppAPI = new SMSServiceGroupResource();
  const [errorMessage, setErrorMessage] = useState<ServiceGroupSchema>();

  const addServiceGroup = useMutation(
    (data: any) => serviceGrouppAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.service_group_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('serviceGroupList');
        history.push(routes.sms.serviceGroups.list);
      },
      onError: (error: any) => {
        setErrorMessage(error?.response?.data?.errors);
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('is_default', data.is_default);
    formData.append('show_on_page', data.show_on_page);
    formData.append('description', data.description);
    formData.append('booking_fee', data.booking_fee);
    formData.append('booking_fee_type', data.booking_fee_type);
    formData.append('color', data?.color);
    if (data.icon === undefined) {
      methods.setError('icon', { message: strings.accept_only_png });
    } else {
      formData.append('icon', data?.icon);
    }
    if (data.cover === undefined) {
      methods.setError('cover', { message: strings.accept_only_png });
    } else {
      formData.append('cover', data?.cover);
    }
    if (!data.icon || !data.cover) return;
    addServiceGroup.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.service_management}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.list}>
            {strings.service_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.add}>
            {strings.add_service_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.add_new_service_group}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addServiceGroup.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.service_group_creation_failed}
                </Alert>
              )}
              <ServiceGroupForm />
              <ButtonGroup justifyContent="flex-end">
                <Button
                  size="lg"
                  colorScheme="primary"
                  variant="outline"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={addServiceGroup.isLoading}>
                  {strings.add_service_group}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddServiceGroup;

import http from 'utils/http';
import Resource from './resource';

class SMSServiceResource extends Resource {
  constructor() {
    super('sms/services');
  }

  updateSerialNumber(oldSerialNumber: number, newSerialNumber: number) {
    return http({
      url: '/' + this.uri + '/update-serial-number',
      method: 'put',
      data: {
        old_serial_number: oldSerialNumber,
        new_serial_number: newSerialNumber,
      },
    });
  }

  updateService(id: any, data: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'POST',
      data: data,
    });
  }
}

export { SMSServiceResource as default };

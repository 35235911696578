import {
  Box,
  BoxProps,
  Center,
  forwardRef,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import DropdownSvg from 'components/common/DropdownSvg';
import { strings } from 'config/localization';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useWordSearch from 'hooks/useWordSearch';
import { useCallback, useEffect, useRef, useState } from 'react';
import { noop } from '../../utils';

interface Props {
  id?: string;
  placeholder?: string;
  onChange: (option: Option) => void;
  value?: any;
  SEARCH_API: string;
  limit: number;
  parentQueries?: any;
  replaceIdInOption?: string;
  replaceNameInOption?: string;
  disable?: boolean;
}

interface Option {
  id: number;
  name: string;
  disabled?: boolean;
}

interface query {
  page: number;
  limit: number;
}

const PopoverTriggerContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box {...props} ref={ref} />;
});

export default function CustomSelect({
  id,
  placeholder,
  onChange,
  value,
  limit,
  SEARCH_API,
  parentQueries,
  replaceIdInOption = '',
  replaceNameInOption = '',
  disable = false,
}: Props) {
  const {
    isOpen: isModalOpen,
    onToggle: onModalToggle,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();
  const openModalRef = useRef<any>(null);
  const { visible, observer } = useIntersectionObserver();
  const focusRef = useRef<any>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [options, setOptions] = useState<any>([]);
  const [queryParams, setQueryParams] = useState<query>({
    page: 1,
    limit: limit,
    ...parentQueries,
  });

  const {
    loading: loading,
    result: query,
    setQuery,
  } = useWordSearch(`${SEARCH_API}`);

  const handleInputChange = useCallback((value: string) => {
    setQueryParams((prevState: query) => ({
      ...prevState,
      keyword: value ? value : null, // queryparams for search should be "keyword"
      page: 1,
    }));
    setOptions([]);
  }, []);

  useEffect(() => {
    if (!query.data) return;
    const listData = query?.data;
    const meta = query?.meta;
    const currentPage = meta?.current_page;
    const lastPage = meta?.last_page;
    setHasMore(currentPage < lastPage);

    const formattedOptions = listData?.map((item: any) => {
      return {
        id: item[replaceIdInOption] ?? item.id,
        name: item[replaceNameInOption] ?? item.name,
      };
    });

    setOptions((prevState: any) => [...prevState, ...formattedOptions]);
  }, [query?.data, query?.meta, replaceIdInOption, replaceNameInOption]);

  useEffect(() => {
    setQuery(queryParams);
  }, [queryParams, setQuery]);

  useEffect(() => {
    if (isModalOpen && visible && hasMore && !loading) {
      setQueryParams((prevState: query) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [isModalOpen, loading, hasMore, visible]);

  useEffect(() => {
    const inputSearchHandler = (value: string) => {
      handleInputChange(value);
      if (searchInput !== '') onModalOpen();
    };
    inputSearchHandler(searchInput);
  }, [searchInput, handleInputChange]);

  const selectHandler = (option: Option) => {
    if (option.disabled) return;
    setSearchInput('');
    onModalClose();
    onChange(option);
  };

  return (
    <div
      className={`container-and-modal${disable ? ' cursor-not-allowed' : ''}`}>
      <div className="custom-select-container">
        <span
          aria-live="polite"
          aria-atomic="false"
          aria-relevant="additions text"
          className="outer-line-span"
        />
        <Popover
          matchWidth
          initialFocusRef={focusRef}
          gutter={1}
          isOpen={isModalOpen}
          onClose={onModalClose}>
          <PopoverTrigger>
            <div
              className="all-content-container"
              onClick={!disable ? onModalToggle : noop}>
              <PopoverTriggerContent className="text-placeholder-and-input-field-container">
                <div className="input-field-inner-container">
                  <input
                    id={id}
                    value={searchInput}
                    placeholder={value?.name ? value.name : placeholder}
                    className={`input-field${
                      disable ? ' cursor-not-allowed' : ''
                    }`}
                    autoComplete="off"
                    ref={focusRef}
                    disabled={disable}
                    style={{ opacity: disable ? 0.6 : 1 }}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </PopoverTriggerContent>
              <div className="seperator-arrow-outer-container">
                <span className="line-seperator"></span>
                <div className="arrow-inner-container" aria-hidden="true">
                  <div style={{ opacity: disable ? 0.4 : 1 }}>
                    <DropdownSvg />
                  </div>
                </div>
              </div>
            </div>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              width="full"
              rootProps={{ width: 'full', maxWidth: 'unset' }}>
              <div className="modal-box">
                {options?.map((option: Option) => {
                  return (
                    <div key={option.id}>
                      <ul
                        key={option.id}
                        className={`list-item ${
                          option.disabled
                            ? 'list-item-disabled disabled-ul'
                            : ''
                        }`}
                        onClick={() => selectHandler(option)}>
                        {option.name}
                      </ul>
                    </div>
                  );
                })}
                <div ref={observer} style={{ height: '1px' }}></div>
                {loading && (
                  <Center h="20px" color="grey">
                    <Spinner size="sm" />
                  </Center>
                )}
                {!loading && (
                  <Center fontSize={12} color="grey">
                    {options.length === 0
                      ? strings.no_options
                      : strings.showing_all_options}
                  </Center>
                )}
              </div>
            </PopoverContent>
          </Portal>
        </Popover>
      </div>
    </div>
  );
}

import { strings } from 'config/localization';
import {
  ImportTypesArray,
  PAYMENT_LINK_STATUS_OPTIONS,
  PAYMENT_METHOD,
  PNSOptionsArray,
  PRICE_CALCULATION_OPTION,
  SERVICE_SCHEME_OPTIONS_ARRAY,
  STATUS_OPTIONS_ARRAY,
} from 'constants/schema';
import { bookingStatusArray } from './schemas/reservationBooking';

// API urls
export const TICKET_CUSTOMER_SEARCH_API = `/ticket/customers`;
export const OMS_OWNER_SEARCH_API = `/rms/owner`;
export const OMS_APARTMENT_SEARCH_API = `/oms/apartments`;
export const DMS_APARTMENT_SEARCH_API = `/dms/apartment`;
export const DMS_OWNER_SEARCH_API = `/dms/owner`;
export const DMS_CUSTOMER_SEARCH_API = `/dms/customer`;
export const DMS_USER_SEARCH_API = `/dms/user`;
export const EXTERNAL_COMPANY_SEARCH_API = `/ticket/external-company`;
export const SERVICE_GROUP_SEARCH_API = `sms/service-groups`;
export const KEY_APARTMENT_SEARCH_API = `keyscan/apartment`;
export const KEY_USER_SEARCH_API = `keyscan/user`;
export const BMS_CUSTOMER_SEARCH_API = '/bms/customers';
export const BMS_APARTMENT_SEARCH_API = '/bms/apartments';
export const OMS_ICAL_BASEURL =
  process.env.REACT_APP_BASE_URL + '/bms/apartments';

// Auth
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires_in';
export const EMAIL_ALREADY_TAKEN_MESSAGE = strings.email_taken;

// Pagination
export const DEFAULT_PAGE_SIZE = 15;
export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];
export const FEEDBACK_SCORES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const DEFAULT_REFETCH_TIME = 90000; //90 second

// Accepted Files
export const TICKET_ACCEPT_FILE_TYPE =
  'image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';
export const DMS_ACCEPT_FILE_TYPE = 'application/pdf';
export const NEWSLETTER_ACCEPT_FILE_TYPE =
  'image/*,.doc,.docx,application/msword,.pdf';

// Date Picker
export const REACT_DATE_PICKER_FORMAT = 'yyyy-MM-dd';
export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD_TIME = 'yyyy-MM-dd   h:mm aa';
export const MYVAL_SERVICE_CENTER_TITLE = 'MyVal Service Center';

// Recursive interval for ticket
export const RECURSIVE_INTERVAL = [
  'Daily',
  'Weekly',
  'Bi-Weekly',
  'Monthly',
  'Semi-Anually',
  'Anually',
];

//CSV FILE HEADERS
export const BOOKING_HEADER = [
  'Id',
  'Nummer',
  'Objekt',
  'Von',
  'Bis',
  'Kunde',
  'Status',
  'Reserviert am',
  'Geändert am',
  'Gesamt',
  'Offen',
  'Vertriebskanal',
  'Anzahlung',
  'Anzahlung bis',
  'Restzahlung bis',
  'EK-Preis',
  'Gesamt Belegung',
  'Erw.',
  'Kinder',
  'Babys',
  'Alter d. Kinder',
  'Land Kunde',
  'PLZ Kunde',
  'Eigentümer',
  'Lang Objekt',
  'Region',
  'Mietabrechnung existiert',
];

export const INVOICE_HEADER = [
  'Id',
  'Nummer',
  'Kunde',
  'Rechnungsdatum',
  'Objekt',
  'Netto',
  'Gesamt',
  'Offen',
  'Fälligkeitsdatum',
  'Storniert am',
];

export const CONTACT_HEADER = [
  'Id',
  'Nummer',
  'Nachname',
  'Vorname',
  'Straße',
  'Plz',
  'Ort',
  'Firma',
  'Hausnr.',
  'Land',
  'Sprache',
  'Briefanrede',
  'Briefabschluss',
  'Anrede',
  'Titel',
  'Geburtsdatum',
  'Eigentümer',
  'Telefon',
  'Telefon geschäftlich',
  'Mobil',
  'Fax',
  'Email',
  '-im-',
  'Homepage',
];

export const APARTMENT_HEADER = [
  'Id',
  'Kürzel',
  'Anlage',
  'Eigentümer',
  'Kategorie',
  'Region',
  'Live',
  'Online',
  'Deaktiviert',
  'Schlafmöglichkeiten',
  'Längengrad',
  'Breitengrad',
  'Typ',
  'Schlafzimmer',
  'Straße',
  'Hausnr.',
  'Plz',
  'Ort',
  'Land',
];

export const SERVICE_HEADER = [
  'Id',
  'Name',
  'Berechnung',
  'Typ',
  'Anbieter',
  'Rechnungsempfänger',
  'category',
];

export const NEWSLETTER_HEADER = [
  'Id',
  'Nummer',
  'Nachname',
  'Vorname',
  'Straße',
  'Plz',
  'Ort',
  'Firma',
  'Hausnr.',
  'Land',
  'Sprache',
  'Briefanrede',
  'Briefabschluss',
  'Anrede',
  'Titel',
  'Geburtsdatum',
  'Eigentümer',
  'Telefon',
  'Telefon geschäftlich',
  'Mobil',
  'Fax',
  'Email',
  '-im-',
  'Homepage',
];

type MomentDurationTypes = 'days' | 'weeks' | 'months' | 'years';
export const RECURSIVE_INTERVAL_MAP_TO_DURATION_AND_QUANTITY: {
  [name: string]: {
    duration: MomentDurationTypes;
    quantity: number;
    durationInDays: number;
  };
} = {
  Daily: {
    duration: 'days',
    quantity: 1,
    durationInDays: 1,
  },
  Weekly: {
    duration: 'weeks',
    quantity: 1,
    durationInDays: 7,
  },
  'Bi-Weekly': {
    duration: 'weeks',
    quantity: 2,
    durationInDays: 15,
  },
  Monthly: {
    duration: 'months',
    quantity: 1,
    durationInDays: 30,
  },
  'Semi-Anually': {
    duration: 'years',
    quantity: 1 / 2,
    durationInDays: 183,
  },
  Anually: {
    duration: 'years',
    quantity: 1,
    durationInDays: 365,
  },
};

// Themes
export const THEME_USER = 'theme-default';
export const THEME_CHAT = 'theme-green';
export const THEME_TICKET = 'theme-purple';
export const THEME_CRM = 'theme-red';
export const THEME_CONTACT_CENTER = 'theme-cyan';
export const THEME_FEEDBACK = 'theme-teal';
export const THEME_DMS = 'theme-blue2';
export const THEME_OMS = 'theme-purple2';
export const THEME_SMS = 'theme-brown';
export const THEME_BMS = 'theme-darkbrown';
export const THEME_PMS = 'theme-blue3';
export const THEME_PNS = 'theme-pink2';
export const THEME_FAQ = 'theme-green2';
export const THEME_DASHBOARD = 'theme-pink';
export const THEME_NEWS = 'theme-yellow';
export const THEME_KEY = 'theme-sunrise';
export const THEME_AERIAL_MAP = 'theme-greygreen';
export const THEME_TERMINAL = 'theme-green';

// Transfer Item source and destination types
export const FRESH = 'Fresh';
export const SHELF = 'Shelf';
export const APARTMENT = 'Apartment';
export const USER = 'User';
export const CONSUMED = 'Consumed';

export const STOCK_ACTIVITIES_ENTITIES = {
  STOCK_ACTIVITY_ACTIONS: 'stock_activity_actions', // id: 1 -> Fresh, id: 2 -> Consumed
  WAREHOUSE_SHELVES: 'warehouse_shelves',
  APARTMENTS: 'apartments',
  USERS: 'users',
};

export const STOCK_ACTIVITIES_TRANSFER_SOURCE = {
  TICKET: 'Ticket',
  RECONCILED: 'Reconciled',
  WEB_UI: 'Web-UI',
  APP: 'App',
};

export const BOOKING_FEE_TYPE_OPTIONS = [
  {
    value: 'One Time',
    text: 'one_time',
  },
  {
    value: 'Per Day',
    text: 'per_day',
  },
];

export const PRICE_CALCULATION_OPTIONS: PRICE_CALCULATION_OPTION[] = [
  {
    value: 0,
    text: 'per_day',
  },
];

export const SERVICE_SCHEME_TYPE_OPTIONS: SERVICE_SCHEME_OPTIONS_ARRAY = [
  {
    value: 'PriceSchemeByQuantity',
    text: 'by_quantity',
  },
  {
    value: 'PriceSchemeOneTime',
    text: 'one_time',
  },
];

export const SERVICE_DURATION_OPTIONS = [
  {
    value: 'PER_DAY',
    text: strings.per_day,
  },
  {
    value: 'PER_WEEK',
    text: strings.per_week,
  },
];

export const SERVICE_DAY_OPTIONS = [
  {
    value: 'SUN',
    text: strings.sun,
  },
  {
    value: 'MON-FRI',
    text: strings.mon_fri,
  },
];

export const apartmentFeatures: any = {
  küche: {
    kitchenType: 'Küchentyp',
    espressomachine: 'Nespresso Maschine',
    dishwasher: 'Geschirrspüler',
    stove: 'Herd',
    cookingUtensils: 'Kochutensilien',
    electricKettle: 'Wasserkocher',
    oven: 'Ofen',
    ovenConnection: 'Ofen Verbindung',
    fridge: 'Kühlschrank',
    freezer: 'Gefrierschrank',
    toaster: 'Toaster',
    espressomachineType: 'Espresso Maschinentyp',
    dishes: 'Besteck',
    freezerType: 'Gefrierertyp',
    coffeemaker: 'Kaffeemaschine',
    trashcan: 'Mülleimer',
    warmwater: 'Warmes Wasser',
  },
  bad: {
    bathrooms: 'Bäder',
    guestwc: 'Gäste WC',
  },
  schlafzimmer: {
    bedrooms: 'Schlafzimmer',
  },
  entertainment: {
    tv: 'TV',
    tvConnection: 'Fernsehverbindung',
    dvd: 'DVD',
    cd: 'CD',
  },
  aktivitäten: {
    shopping: 'Shopping',
    family: 'Familie',
    day_spa: 'Spa',
    golf: 'Golf',
    loc_ocean: 'Meerblick',
    mountainbiking: 'Mountainbiking',
    wellnessActivity: 'Wellness',
    beach: 'Strand',
    nudeBeach: 'FKK',
    cycling: 'Fahrrad',
    countrysideView: 'Ländliche Gegend',
    birdWatching: 'Vögel beobachten',
    swimming: 'Schwimmen',
    hiking: 'Wandern',
    water_sport: 'Wassersport',
    'nordic-walking': 'Nordic Walking',
    windSurfing: 'Wind Surfing',
    minigolf: 'Mini-Golf',
    gardenView: 'Garten',
    sideboard: 'Komode',
    sauna: 'Sauna',
    wellness: 'Wellness',
  },
};

export const BREAD_BOOKING_STATUS_OPTIONS: bookingStatusArray = [
  'confirmed',
  'pending',
  'cancelled',
];

export const IMPORT_TYPES: ImportTypesArray = [
  'availabilities',
  'units',
  'services',
];

export const PMS_STATUS_OPTIONS: STATUS_OPTIONS_ARRAY = [
  {
    value: 'pending',
    text: 'pending',
  },
  {
    value: 'confirmed',
    text: 'confirmed',
  },
];

export const PMS_PAYMENT_LINK_STATUS_OPTIONS: PAYMENT_LINK_STATUS_OPTIONS[] = [
  {
    value: 'pending',
    text: 'pending',
  },
  {
    value: 'confirmed',
    text: 'paid',
  },
];

export const PAYMENT_OPTIONS = [
  {
    value: 'visa',
    text: 'Visa',
  },
  {
    value: 'master',
    text: 'Master',
  },
];

export const PMS_PAYMENT_METHOD_OPTIONS: PAYMENT_METHOD[] = [
  'credit_card',
  'paypal',
];

//CSV FILE HEADERS FOR BMS DRIVING ORDER
export const BMS_DRIVING_ORDER_HEADER = [
  'Id',
  'Kürzel',
  'Anlage',
  'Eigentümer',
  'Kategorie',
  'Aktiv',
  'Online',
  'Schlafmöglichkeiten',
  'Längengrad',
  'Breitengrad',
  'Typ',
  'Schlafzimmer',
  'Straße',
  'Hausnr.',
  'Plz',
  'Ort',
];

export const DRIVING_ORDER_HEADER = ['Id', 'Kürzel'];

export const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/x-icon',
  'image/ief',
  'image/svg+xml',
];

export const imageCompressionOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const RATING_OPTIONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const FEEDBACK_QUESTION_TYPE_CLOSED_ENDED = 'closed';
export const FEEDBACK_QUESTION_TYPE_RATING = 'rating';
export const FEEDBACK_QUESTION_TYPE_OPEN = 'open';
export const FEEDBACK_STATUS_TYPE: ['pending', 'sent', 'received', 'closed'] = [
  'pending',
  'sent',
  'received',
  'closed',
];

export const PUSH_NOTIFICATION_OPTIONS: PNSOptionsArray = ['web', 'app'];
//Recipient options in Newsletter subsystem

export const VIMEO_URL = 'https://www.google.com/';
export const INSTAGRAM_URL = 'https://www.google.com/';
export const PINTEREST_URL = 'https://www.google.com/';
export const WEBSITE_URL = 'https://www.google.com/';
export const PRIVACY_POLICY_URL = 'https://www.google.com/';
export const IMPRINT_URL = 'https://www.google.com/';

export const NEWSLETTER_STATUS_CONFIRMED = 'confirmed';

export const defaultCreatePaymentLinkMessage =
  'Ihre Zahlung gehört zur Buchung BN ______ und dem von Ihnen gebuchten Feriendomizil ____________ in dem Zeitraum vom __.__.____ bis __.__.____. Für Rückfragen stehen wir Ihnen gerne auch telefonisch unter 04651 8898570 zur Verfügung.';

export const AERIAL_MAP_URL = 'https://aerialmap.listinfo.de';

export const message2FA =
  '2FA authentication enabled, Please enter the verification code.';
export const invalid2FA = 'Invalid Verification Code, Please try again.';

import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Stack,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import { resendVerificationEmail } from 'api/auth';
import UserResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import UserPermissionDisplay from 'components/common/UserPermissionDisplay';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, maskPhone } from 'utils';

const UserProfile: React.FC = () => {
  const { search } = useLocation();

  const { id }: any = useParams();
  const userAPI = new UserResource();
  const toast = useToast();
  const checkUserEditPermission = useCheckPermission(
    [PermissionRequest['manage:user']],
    routes.users.edit.replace(':id', id.toString())
  );

  const userQuery = useQuery<UserSchema>([`user${id}`, id], () =>
    userAPI.get(id).then((res) => res.data.data)
  );

  /**
   * Fetch permissions for a given users
   */
  const { isLoading: isUserPermissionLoading, data: userPermissions } =
    useQuery(
      [`userRole${id}`, id],
      () => userAPI.permissions(id).then((res) => res.data.data),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const resendVerificationEmailMutation = useMutation(
    (data: { email: string }) => resendVerificationEmail(data),
    {
      onSuccess: () => {
        toast({
          title: `${strings.resend_verify_email_success_to} ${userQuery.data?.email}`,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.resend_verify_email_error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleResendVerificationEmail = () => {
    if (!userQuery.data?.email) return;
    const data = {
      email: userQuery.data.email,
    };
    resendVerificationEmailMutation.mutate(data);
  };

  const listItemStyles = {
    display: 'flex',
    mb: '4',
  };

  const titleStyles = {
    minW: '60',
    color: 'heading',
    fontWeight: 'semibold',
  };

  const descriptionStyles = {
    color: 'heading',
    fontWeight: 'medium',
    whiteSpace: 'nowrap',
  };

  if (userQuery.isLoading || isUserPermissionLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  const userId = userQuery?.data?.id;
  const userRole = userQuery?.data?.role;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list + search}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {userQuery?.data?.first_name} {userQuery?.data?.last_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.user_details}
          </Heading>
          {userQuery.data?.role !== 'owner' && (
            <Button
              size="lg"
              colorScheme="primary"
              type="button"
              onClick={checkUserEditPermission}>
              {strings.edit_user}
            </Button>
          )}
        </Flex>
        <Stack direction={['column', 'row']} spacing="4">
          <Stack
            bg="white"
            shadow="box"
            p={['3', '8']}
            rounded="sm"
            maxH="250px">
            <Avatar
              boxSize="180px"
              src={userQuery?.data?.profile_picture_url}
              rounded="full"
              boxShadow="sm"
              bg="primary.400"
              p="2"
            />
          </Stack>

          <Stack
            bg="white"
            shadow="box"
            p={['3', '6']}
            rounded="sm"
            direction="column"
            spacing="4"
            flex="1"
            overflow="auto">
            <Box>
              <List>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.name}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.first_name} {userQuery.data?.last_name}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.address}</Text>
                  <Text sx={descriptionStyles}>{userQuery.data?.address}</Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.phone_number}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.contact_number
                      ? maskPhone(userQuery.data.contact_number)
                      : '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.email_address}</Text>
                  <Text sx={descriptionStyles}>{userQuery.data?.email}</Text>
                </ListItem>
                {/* <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.status}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.email_verified_at
                      ? strings.verified
                      : strings.not_yet_verified}
                  </Text>
                  {!userQuery.data?.email_verified_at && (
                    <Button
                      colorScheme="primary"
                      variant="link"
                      fontSize="sm"
                      ml={6}
                      type="button"
                      onClick={handleResendVerificationEmail}
                      disabled={resendVerificationEmailMutation.isSuccess}
                      isLoading={resendVerificationEmailMutation.isLoading}>
                      {resendVerificationEmailMutation.isSuccess
                        ? strings.verification_email_sent
                        : strings.resend_verification_email}
                    </Button>
                  )}
                </ListItem> */}
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.role}</Text>
                  <Text sx={descriptionStyles}>
                    <Tag bg="primary.500" color="white">
                      {userQuery.data?.role
                        ? capitalizeFirstLetter(
                            strings.getString(
                              userQuery.data.role.split(' ').join('_')
                            )
                          )
                        : ''}
                    </Tag>
                  </Text>
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Stack>
        {userId && userRole && userPermissions && (
          <Box pt="2">
            <UserPermissionDisplay
              userId={userId}
              userRole={userRole}
              userPermissions={userPermissions}
              isUserPermissionLoading={userPermissions?.isLoading}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default UserProfile;

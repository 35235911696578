import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import bmsBookingPayment from 'api/bms-booking-payment';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { forwardRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiTrash } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { cleanData, formatPriceLabel } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
import ManualPaymentForm from './ManualPaymentForm';
const frank = '&#8355';
interface Props {
  data: any;
  id: string;
  editing?: boolean;
}

const DatePickerInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

DatePickerInput.displayName = 'DatePickerInput';

const PaymentInfo: React.FC<Props> = (props) => {
  const { data, id, editing } = props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const toast = useToast();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = methods;

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [paymentInfo, setPaymentInfo] = useState<any>();

  const bmsBookingPaymentAPI = new bmsBookingPayment();

  const addPayment = useMutation((data: any) =>
    bmsBookingPaymentAPI.store(data)
  );
  const deletePayment = useMutation((id: any) =>
    bmsBookingPaymentAPI.canclePayment(id)
  );

  const bookingPaymentListQuery = useQuery(
    ['bookingPaymentList'],
    async () => {
      let query = {
        currentPage: INITIAL_CURRENT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE * 5,
        booking_id: id,
        status: 'confirmed',
      };
      const res = await bmsBookingPaymentAPI.list(query);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const paymentList = bookingPaymentListQuery?.data?.data;

  const onPaymentSubmit = (formData: any) => {
    formData.received_date = DateFormatYMD(formData.received_date);
    formData.booking_id = id;
    formData.payment_method = 'INVOICE';

    const data = cleanData(formData);
    addPayment.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.payment_made}`,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(`bookingDetails-${id}`);
        queryClient.invalidateQueries(`bookingPaymentList`);
        closeAddPayment();
      },
      onError: (err: any) => {
        toast({
          title: strings.error,
          description: err?.response?.data?.message,
          status: 'error',
          isClosable: true,
        });
        closeAddPayment();
      },
    });
  };
  function closeAddPayment() {
    reset();
    onClose();
  }

  function openDelete(data: any) {
    setPaymentInfo(data);
    onDeleteOpen();
  }
  function closeDelete() {
    setPaymentInfo(null);
    onDeleteClose();
  }
  function onDeleteConfirm() {
    deletePayment.mutate(paymentInfo.id, {
      onSuccess: () => {
        toast({
          title: strings.success,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(`bookingDetails-${id}`);
        queryClient.invalidateQueries(`bookingPaymentList`);
        closeDelete();
      },
      onError: (err: any) => {
        toast({
          title: strings.error,
          description: err?.response?.data?.message,
          status: 'error',
          isClosable: true,
        });
        closeDelete();
      },
    });
  }

  let { open_amount, paid_amount, total_amount } = data;
  return (
    <Stack>
      <Grid
        gap="2"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 2fr)']}
        w="100%">
        <GridItem>
          <Grid gap="2" templateColumns={['repeat(2, 2fr)']} w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.total_amount}:</FormLabel>
                <Box sx={infoStyles}>{strings.open}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {formatPriceLabel(total_amount)} CHF
                </FormLabel>
                <Box sx={infoStyles}>{formatPriceLabel(open_amount)} CHF</Box>
              </FormControl>
            </GridItem>
            <Divider orientation="vertical" />
          </Grid>
        </GridItem>
        <GridItem>
          <TableContainer>
            <Table lineHeight="0.5">
              <Thead>
                <Tr>
                  <Th fontWeight="bold" color="blackAlpha.900">
                    {strings.payment_made}
                  </Th>
                  <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                    {formatPriceLabel(paid_amount)} CHF
                  </Th>
                  {editing && <Th></Th>}
                </Tr>
              </Thead>
              <Tbody>
                {paymentList?.map((data: any) => {
                  return (
                    <Tr key={data.id}>
                      <Td lineHeight="1.3" color="blackAlpha.900">
                        {DateFormat(data?.received_date) ?? '-'}
                        <br />
                        {data?.remarks}
                      </Td>
                      <Td color="blackAlpha.900" isNumeric>
                        {formatPriceLabel(data?.amount)} CHF
                      </Td>
                      {editing && (
                        <Td color="red" onClick={() => openDelete(data)}>
                          <BiTrash color="red" />
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Grid gap="2" templateColumns={['repeat(1, 1fr)']} w="100%">
            <Divider orientation="horizontal" />

            {editing && Number(open_amount) > 0 && (
              <Button
                colorScheme="primary"
                type="button"
                size="lg"
                variant="outline"
                onClick={onOpen}>
                {strings.add_new_payment}
              </Button>
            )}
          </Grid>
        </GridItem>
      </Grid>
      <Modal isOpen={isOpen} isCentered onClose={closeAddPayment} size="xl">
        <ModalOverlay />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onPaymentSubmit)}>
            <ModalContent>
              <ModalHeader>{strings.add_new_payment}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ManualPaymentForm />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup justifyContent="flex-end">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    size="lg"
                    type="button"
                    onClick={closeAddPayment}
                    isDisabled={addPayment.isLoading}>
                    {strings.cancel}
                  </Button>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    size="lg"
                    isLoading={addPayment.isLoading}>
                    {strings.save}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>

      <Modal isOpen={isDeleteOpen} isCentered onClose={closeDelete} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_payment}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="md" mb="4">
              {strings.confirm_delete}
            </Heading>
            <UnorderedList>
              <ListItem>
                {strings.date}: {DateFormat(paymentInfo?.received_date) ?? '-'}
              </ListItem>
              <ListItem>
                {strings.amount}: {formatPriceLabel(paymentInfo?.amount)} CHF
              </ListItem>
              <ListItem>
                {strings.payment_method}: {strings.bank_transfer}
              </ListItem>
              <ListItem>
                {strings.notes}: {paymentInfo?.remarks ?? '-'}
              </ListItem>
            </UnorderedList>
          </ModalBody>
          <ModalFooter mt="10">
            <ButtonGroup>
              <Button
                colorScheme="red"
                type="submit"
                size="lg"
                onClick={onDeleteConfirm}
                isLoading={deletePayment.isLoading}>
                {strings.delete_payment}
              </Button>
              <Button
                colorScheme="black"
                variant="outline"
                size="lg"
                isDisabled={deletePayment.isLoading}
                onClick={closeDelete}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default PaymentInfo;

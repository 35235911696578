import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import BMSCheckInResource from 'api/bms-check-in';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CheckIn } from 'assets/icons';
import AppartmentInformationOverview from 'components/bms_booking/bms_booking_overview/AppartmentInformationOverview';
import CustomerInformationOverview from 'components/bms_booking/bms_booking_overview/CustomerInformationOverview';
import ServiceInformationCard from 'components/bms_booking/bms_booking_overview/ServiceInformationCard';
import PaymentInfo from 'components/bms_booking/PaymentInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { sendMessage, useSocketContext } from 'context/SocketContext';
import React, { useRef } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import AccessControl from 'services/AccessControl';

const BookingDetail: React.FC = () => {
  const { id: reservationID }: any = useParams();
  const BookingAPI = new BMSBookingResource();
  const bmsCheckInAPI = new BMSCheckInResource();
  const toast = useToast();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const checkinFromURL = searchParams.get('checkin');
  const printRef: any = useRef();
  const checkinModalDisclosure = useDisclosure();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { dispatch } = useSocketContext();

  const {
    data: bookingDetailData,
    isLoading,
    isError,
  } = useQuery(
    `bookingDetails-${reservationID}`,
    () =>
      BookingAPI.get(reservationID)
        .then((res) => res.data.data)
        .catch((err) => err),
    {
      refetchOnWindowFocus: false,
    }
  );

  const emptySocketMessage = () => {
    sendMessage(dispatch, {});
  };

  const checkIn = useMutation((id: any) => bmsCheckInAPI.checkIn(id), {
    onSuccess: () => {
      toast({
        title: strings.guest_has_been_successfully_checked_in,
        status: 'success',
        isClosable: true,
      });
      emptySocketMessage();
      queryClient.invalidateQueries('checkin-list');
      checkinModalDisclosure.onClose();
      history.push(routes.bms.checkInCheckOut.list);
    },
    onError: (err: any) => {
      if (err?.response?.status !== 400 && err?.response?.status !== 500) {
        toast({
          title: strings.could_not_check_in_guest,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  const generateInvoice = useMutation(
    (id: any) => BookingAPI.generateInvoiceByBookingId(id),
    {
      onSuccess: () => {
        toast({
          title: strings.successfully_generate_invoice_by_booking_id,
          status: 'success',
          isClosable: true,
        });
        history.push(
          routes.bms.invoices.list +
            `?booking_number=${bookingDetailData?.booking_number}`
        );
      },
      onError: (err: any) => {
        toast({
          title: err?.response?.data?.message,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleGenerateInvoice = () => {
    generateInvoice.mutate(reservationID);
  };

  const handleCheckIn = () => {
    checkIn.mutate(reservationID);
  };

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  } else if (isError) {
    return <div>Error</div>;
  }

  let {
    customer,
    service_booking,
    payment,
    reservation,
    booking_number,
    booking_register,
    to_date,
    apartment,
    guest_info,
  }: any = bookingDetailData;

  let status: 'confirmed' | 'cancelled' | 'pending' = bookingDetailData?.status;
  let isCheckout = booking_register?.check_out;
  let isCheckin = booking_register?.check_in;

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list + search}>
            {strings.bms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list + search}>
            {strings.booking}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {booking_number ?? '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Stack direction="column" spacing="4">
        <Stack justify="space-between" direction={['column', 'row']}>
          <Flex>
            <Heading size="lg" textTransform="capitalize">
              {strings.booking_detail}
            </Heading>
            {isCheckin && !isCheckout && (
              <Badge
                variant="solid"
                ml="4"
                p="6px 16px"
                fontSize="14px"
                bg="#1C7D49"
                textTransform="uppercase">
                {strings.arrived}
              </Badge>
            )}
            {isCheckout && (
              <Badge
                variant="solid"
                ml="4"
                p="6px 16px"
                fontSize="14px"
                bg="#C56710"
                textTransform="uppercase">
                {strings.departed}
              </Badge>
            )}
          </Flex>
          <AccessControl
            allowedPermissions={[PermissionRequest['manage:bookings']]}>
            <Flex direction={['column', 'row']} gridGap={['2', '4']}>
              {isCheckout && (
                <Button
                  colorScheme="black"
                  variant="outline"
                  size="lg"
                  isLoading={generateInvoice.isLoading}
                  onClick={handleGenerateInvoice}>
                  <Icon as={AiOutlineFilePdf} mr="2" />
                  {strings.generate_invoice}
                </Button>
              )}

              <RouterLink
                to={
                  routes.bms.booking.edit.replace(':id', reservationID) + search
                }>
                <Button
                  as="div"
                  colorScheme="primary"
                  variant="outline"
                  size="lg">
                  <Icon as={BiPencil} mr="2" />
                  {strings.edit_booking}
                </Button>
              </RouterLink>
            </Flex>
          </AccessControl>
        </Stack>

        <Accordion
          borderColor="white"
          defaultIndex={[0, 1, 2, 3]}
          allowMultiple>
          <CustomerInformationOverview customerInformation={customer} />
          <AppartmentInformationOverview
            appartmentInformation={bookingDetailData}
          />

          <AccordionItem mt="12">
            <h2>
              <AccordionButton background="gray.50">
                <Box flex="1" textAlign="left" fontWeight={700} fontSize="22">
                  {strings.service_information}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} sx={wrapperStyles}>
              <Stack direction="row" gap="4">
                <ServiceInformationCard
                  editing={false}
                  serviceReserved={bookingDetailData?.service_booking}
                />
              </Stack>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem mt="12">
            <h2>
              <AccordionButton background="gray.50">
                <Box flex="1" textAlign="left" fontWeight={700} fontSize="22">
                  {strings.payment_status}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} sx={wrapperStyles}>
              <PaymentInfo id={reservationID} data={bookingDetailData} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
      {checkinFromURL === 'true' && (
        <AccessControl
          allowedPermissions={[PermissionRequest['manage:bookings']]}>
          <ButtonGroup justifyContent="flex-end">
            <Button
              type="submit"
              size="lg"
              color="white"
              onClick={checkinModalDisclosure.onOpen}
              bg="#6266D1"
              _hover={{ bg: '#585CBC' }}
              _active={{ bg: '#585CBC' }}>
              <Image src={CheckIn} w="14px" />
              &nbsp; &nbsp;{strings.make_check_in}
            </Button>
          </ButtonGroup>
          <Modal
            isOpen={checkinModalDisclosure.isOpen}
            onClose={checkinModalDisclosure.onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            size="lg"
            isCentered>
            <ModalOverlay />
            <ModalContent textAlign="center">
              <ModalHeader>{strings.guest_check_in}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack direction="column">
                  <Heading size="sm">
                    {strings.checkin_modal_body_first_message}&nbsp;
                    {customer?.name}&nbsp;
                    {strings.checkin_modal_body_last_message}?
                  </Heading>
                  <Text>
                    {
                      strings.please_make_sure_the_guest_has_read_the_booking_information
                    }
                  </Text>
                </Stack>
              </ModalBody>

              <ModalFooter justifyContent="center">
                <ButtonGroup>
                  <Button
                    size="lg"
                    variant="outline"
                    onClick={checkinModalDisclosure.onClose}>
                    {strings.check_booking_details}
                  </Button>
                  <Button
                    type="submit"
                    size="lg"
                    color="white"
                    bg="#6266D1"
                    isLoading={checkIn.isLoading}
                    isDisabled={checkIn.isLoading}
                    onClick={handleCheckIn}
                    _hover={{ bg: '#585CBC' }}
                    _active={{ bg: '#585CBC' }}>
                    <Image src={CheckIn} w="14px" />
                    &nbsp; &nbsp;{strings.make_check_in}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </AccessControl>
      )}
    </Stack>
  );
};

export default BookingDetail;

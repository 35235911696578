import { useToast } from '@chakra-ui/react';
import { getDeviceId, getPairInfo } from 'api/check-in-socket';
import { strings } from 'config/localization';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface IAppState {
  employeeDeviceId?: string;
  employeeDeviceName?: string;
  customerDeviceName?: string;
  sessionId?: string;
}

interface IPairInfo {
  employeeDeviceName: string;
  customerDeviceName: string;
}

type EmployeeDeviceIdPairProviderProps = {
  children: React.ReactNode;
};

// employeeDeviceName refers to ID and customerDeviceName refers to Secret key in the UI
type EmployeeDeviceIdPairContextProps = {
  appState: IAppState;
  isPairInfoLoading: boolean;
  isDeviceIdLoading: boolean;
  isPairInfoDeviceIdLoading: boolean;
  setAppState: React.Dispatch<React.SetStateAction<IAppState>>;
};

const defaultState: IAppState = {
  employeeDeviceId: undefined,
  employeeDeviceName: '',
  customerDeviceName: '',
  sessionId: undefined,
};

const EmployeeDeviceIdPairContext =
  createContext<EmployeeDeviceIdPairContextProps | null>(null);

export const useEmployeeDeviceIdPairContext = () => {
  const context = useContext(EmployeeDeviceIdPairContext);
  if (!context) {
    throw new Error(
      strings.device_id_pair_context_must_be_used_within_device_id_pair_provider
    );
  }
  return context;
};

const EmployeeDeviceIdPairProvider: React.FC<EmployeeDeviceIdPairProviderProps> =
  (props) => {
    const [appState, setAppState] = useState<IAppState>({
      ...defaultState,
    });

    const toast = useToast({
      isClosable: true,
    });
    const failureToast = (message: string) => {
      toast({
        title: strings.error,
        description: message,
        status: 'error',
      });
    };

    const callback = (data: any, err?: any) => {
      if (err) {
        failureToast(err?.response?.data?.message || err.message);
      } else {
        setAppState((preState) => {
          return {
            ...preState,
            employeeDeviceId: data.id,
          };
        });
        return data;
      }
    };

    const pairInfoQuery = useQuery(
      ['pair-info'],
      async () => {
        const data = await getPairInfo();
        return data;
      },
      {
        refetchOnWindowFocus: false,
      }
    );
    const pairInfo: IPairInfo = pairInfoQuery.data;

    const deviceIdQuery = useQuery(
      [
        'device-id',
        pairInfo?.customerDeviceName && pairInfo.customerDeviceName,
        pairInfo?.employeeDeviceName && pairInfo.employeeDeviceName,
        'Employee',
      ],
      async () => {
        const data = await getDeviceId(
          'Employee',
          pairInfo?.customerDeviceName || '',
          pairInfo?.employeeDeviceName || '',
          callback
        );
        return data;
      },
      {
        enabled:
          !appState.employeeDeviceId &&
          !!pairInfo?.customerDeviceName &&
          !!pairInfo?.employeeDeviceName,
        refetchOnWindowFocus: false,
      }
    );
    const deviceId = deviceIdQuery.data;

    const isPairInfoDeviceIdLoading =
      deviceIdQuery.isLoading || pairInfoQuery.isLoading;

    useEffect(() => {
      const custDeviceName = pairInfo?.customerDeviceName;
      const empDeviceName = pairInfo?.employeeDeviceName;
      const sessionId = localStorage.getItem('EMP:sessionId') || undefined;

      if (custDeviceName && empDeviceName) {
        setAppState((preState) => {
          return {
            ...preState,
            employeeDeviceName: empDeviceName,
            customerDeviceName: custDeviceName,
            sessionId: sessionId,
          };
        });
      }
    }, [pairInfo]);

    return (
      <EmployeeDeviceIdPairContext.Provider
        value={{
          appState,
          isPairInfoLoading: pairInfoQuery.isLoading,
          isDeviceIdLoading: deviceIdQuery.isLoading,
          isPairInfoDeviceIdLoading,
          setAppState,
        }}>
        {props.children}
      </EmployeeDeviceIdPairContext.Provider>
    );
  };

export default EmployeeDeviceIdPairProvider;

import { Td, Text, Tr } from '@chakra-ui/react';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

interface Props {
  pmsBankTransferData: any;
  index: number;
}
const PMSBankTransferListItem: React.FC<Props> = (props) => {
  const { pmsBankTransferData, index } = props;
  const status: 'cancelled' | 'confirmed' = pmsBankTransferData?.status;

  return (
    <Tr key={pmsBankTransferData.id}>
      <Td>{index}</Td>
      <Td>
        <Text
          fontWeight="semibold"
          cursor="pointer"
          onClick={() =>
            history.push(
              routes.crm.contact.details.replace(
                ':id',
                pmsBankTransferData?.booking?.customer?.id.toString()
              )
            )
          }>
          {pmsBankTransferData?.booking?.customer?.name}
        </Text>
      </Td>
      <Td>
        <Text
          fontWeight="semibold"
          cursor="pointer"
          onClick={() =>
            history.push(
              routes.bms.booking.details.replace(
                ':id',
                pmsBankTransferData?.booking_id.toString()
              )
            )
          }>
          {pmsBankTransferData?.booking?.booking_number}
        </Text>
      </Td>
      <Td isNumeric>{DateFormat(pmsBankTransferData?.received_date) || '-'}</Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {strings[status] || '-'}
        </Text>
      </Td>
      <Td isNumeric>
        {formatCurrency(Number(pmsBankTransferData?.amount))} CHF
      </Td>
    </Tr>
  );
};

export default PMSBankTransferListItem;

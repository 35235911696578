import { Button, GridItem, Icon, Image, Stack, Text } from '@chakra-ui/react';
import { ServiceFallbackImage } from 'assets/images';
import { strings } from 'config/localization';
import { ServiceSchema } from 'constants/schema';
import React, { useCallback, useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { formatCurrency } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ServiceBookingFormFields from './ServiceBookingFormFields';

interface Props {
  service: ServiceSchema;
  changeAllServiceFormFields: (val: any[], priceSchemeType: string) => void;
  handleServiceFieldDelete: (id?: string) => void;
}

const ServiceBookingListItem = (props: Props) => {
  const { service, changeAllServiceFormFields, handleServiceFieldDelete } =
    props;

  // condition to display add button, date input field and initial value for input fields
  const isServiceTypePriceSchemeByQuantity =
    service.price_scheme_type === 'PriceSchemeByQuantity';

  const initialValue = isServiceTypePriceSchemeByQuantity
    ? [
        {
          id: `${service.name}${service.id}${Date.now()}`,
          from_date: '',
          to_date: '',
          quantity: 0,
          service_id: service.id,
        },
      ]
    : [
        {
          id: `${service.name}${service.id}${Date.now()}`,
          quantity: 0,
          service_id: service.id,
        },
      ];

  // group of input fields for each service
  const [serviceInputFields, setServiceInputFields] =
    useState<any[]>(initialValue);

  useEffect(() => {
    changeAllServiceFormFields(serviceInputFields, service.price_scheme_type);
  }, [
    serviceInputFields,
    changeAllServiceFormFields,
    service.price_scheme_type,
  ]);

  return (
    <GridItem key={service.id}>
      <Stack direction="column" spacing="0">
        <Stack direction="column" alignItems="center" spacing="0">
          <Image
            src={service.cover}
            borderRadius="full"
            boxSize="78px"
            fallbackSrc={ServiceFallbackImage}
            alt={service.name}
          />
          <Text color="heading" fontWeight="semibold" pt="2">
            {service.name}
          </Text>
          <Text fontSize="sm">
            {formatCurrency(service.prices[0].unit_price)} CHF/ {strings.piece}
          </Text>
        </Stack>

        {serviceInputFields.map((item, index: number) => {
          return (
            <ServiceBookingFormFields
              key={item.id}
              formFieldId={item.id}
              service={service}
              index={index}
              serviceInputFields={serviceInputFields}
              isServiceTypePriceSchemeByQuantity={
                isServiceTypePriceSchemeByQuantity
              }
              onServiceInputFieldsChange={setServiceInputFields}
              handleServiceFieldDelete={handleServiceFieldDelete}
            />
          );
        })}

        {isServiceTypePriceSchemeByQuantity && (
          <Stack>
            <Button
              mt="2"
              w="fit-content"
              alignSelf="center"
              leftIcon={<Icon as={BiPlus} w="5" h="5" />}
              variant="outline"
              colorScheme="primary"
              size="sm"
              onClick={() =>
                setServiceInputFields((prev) => [
                  ...prev,
                  {
                    id: `${service.name}${service.id}${Date.now()}`,
                    from_date: '',
                    to_date: '',
                    quantity: 0,
                    service_id: service.id,
                  },
                ])
              }>
              {strings.add}
            </Button>
          </Stack>
        )}
      </Stack>
    </GridItem>
  );
};

export default ServiceBookingListItem;

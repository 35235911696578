import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/menu';
import { Box, Flex } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { LanguageTypesSchema } from 'constants/schema';
import { BiChevronDown } from 'react-icons/bi';

const LanguageMenu = () => {
  const currentLanguage = strings.getLanguage();

  const handleLanguage = (Language: LanguageTypesSchema) => {
    localStorage.setItem('language', Language);
    window.location.reload();
  };

  return (
    <Menu>
      <MenuButton
        p={2}
        transition="all 0.2s"
        _hover={{ borderBottom: 'gray.300' }}
        _expanded={{ borderBottom: 'gray.400' }}>
        <Flex>
          <MenuItem>
            {currentLanguage === 'en'
              ? strings.english
              : currentLanguage === 'fr'
              ? strings.french
              : currentLanguage === 'it'
              ? strings.italian
              : strings.german}
          </MenuItem>
          <Box pt="2">
            <BiChevronDown />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList rootProps={{ style: { transform: 'scale(0)' } }}>
        <MenuItem onClick={() => handleLanguage('de')}>
          {strings.german}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => handleLanguage('en')}>
          {strings.english}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => handleLanguage('fr')}>
          {strings.french}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => handleLanguage('it')}>
          {strings.italian}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageMenu;

import {
  Button,
  Icon,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { setChatItemList } from 'actions/data/chat';
import { updateReadMessageStatus } from 'api/chat';
import { messageReceiveStyled } from 'components/chat/ChatStyle';
import React, { useEffect, useRef, useState } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { socket } from 'services/socket';
import { isSameDay } from 'utils';
import { ChatDateCategoryFormat } from 'utils/DateFormat';
import { strings } from '../../config/localization';
import ChatMessageDetails from './ChatMessageDetails';

interface Props {
  chatMessageDetails: any;
  user: string;
  roomName: string;
  userId: number;
}

const ChatBot: React.FC<Props> = (props) => {
  const { user, roomName, userId } = props;
  const [scrollEl, setScrollEl] = useState<any>();
  const [typing, setTyping] = useState<String>('');
  const txtAreaChatRef = useRef<HTMLTextAreaElement>(null);
  const [chatMessages, setChatMessages] = useState(props.chatMessageDetails);

  const dispatch = useDispatch();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { chatItemList } = useSelector(
    (state: any) => ({
      chatItemList: state?.data?.chat.chatItemList,
    }),
    shallowEqual
  );

  useEffect(() => {
    // Scroll to bottom
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
    // Displaying typing text to chat
    socket.on('display', (data: any) => {
      if (data.typing && data.user !== user && data.roomName === roomName)
        setTyping(`${data.user} ${strings.is_typing}`);
      else setTyping('');
    });
    // Receiving Message
    socket.on('chat message', (data: any) => {
      if (data.room_name === roomName) {
        setChatMessages((prev: any) => [...prev, data]);
      }
    });
    return () => {
      socket.off('chat message');
      socket.off('display');
    };
  }, [roomName, scrollEl, user]);

  useEffect(() => {
    if (roomName) {
      let unreadPositions: number[] = [];

      chatMessages.map((value: any, index: number) => {
        if (value.status === 'unread' && value.sender === 'user') {
          unreadPositions.push(index);
          chatMessages[index].status = 'read';
        }
      });

      if (unreadPositions.length > 0) {
        updateReadMessageStatus(roomName, unreadPositions).then(() => {
          queryClient.invalidateQueries('fetchSearchChatUser');
        });
      }
    }
  }, [chatMessages, roomName, queryClient]);

  /**
   * Set Chat Message Details
   */
  useEffect(() => {
    setTyping('');
    setChatMessages(props.chatMessageDetails);
  }, [props.chatMessageDetails]);

  /**
   * handle Chat by enter key in textArea
   */
  const handleChatByEnter = (e: any) => {
    let data = {
      user: user,
      roomName: roomName,
    };
    if (txtAreaChatRef.current?.value)
      socket.emit('typing', {
        ...data,
        typing: true,
      });
    if (e.key === 'Enter' && txtAreaChatRef.current?.value) {
      //save to database
      saveChat(txtAreaChatRef.current?.value);
      socket.emit('typing', {
        ...data,
        typing: false,
      });
      e.preventDefault();
    }
  };

  /**
   * Save chat message
   */
  const saveChat = async (message: String) => {
    if (message.trim() === '') return;
    const socketData = {
      value: message,
      user: user,
      employee_id: userId,
      roomName: roomName,
      status: ['in_progress'],
      type: 'message',
    };

    socket.emit('chat message', socketData, (cb: any) => {
      if (cb.status !== 'ok')
        toast({
          title: strings.message_not_sent,
          status: 'error',
          isClosable: true,
        });
    });
    txtAreaChatRef.current!.value = '';
    txtAreaChatRef.current?.focus();

    // Set the currently active chat item to the top
    const sortedChatListData = chatItemList?.reduce(
      (totalList: any, chat: any) => {
        if (chat.room_name === roomName) {
          return [chat, ...totalList];
        }
        return [...totalList, chat];
      },
      []
    );
    dispatch(setChatItemList(sortedChatListData));
  };

  /**
   * handle Chat APP
   */
  const handleChat = () => {
    // save to database
    if (txtAreaChatRef.current?.value?.trim())
      saveChat(txtAreaChatRef.current?.value);
  };

  let messageTimeCategoryRef = useRef<any>();
  messageTimeCategoryRef.current = chatMessages?.[0]?.time;

  return (
    <Stack
      h="100%"
      direction="column"
      pos="relative"
      p="1"
      bg="white"
      borderBottomRadius="md"
      shadow="0px 7px 60px rgba(0,0,0,0.06);"
      maxH="700px"
      overflow="auto">
      <PerfectScrollbar
        containerRef={(ref: any) => {
          setScrollEl(ref);
        }}>
        <Stack h="calc (100vh-184px)" p="4">
          {chatMessages?.length > 0 && (
            <Stack
              justifyContent="center"
              direction="row"
              className="message-date-header"
              whiteSpace="nowrap">
              <Text>
                {ChatDateCategoryFormat(messageTimeCategoryRef.current)}
              </Text>
            </Stack>
          )}
          {chatMessages.map((message: any) => {
            let showMessageDateHeaderFlag: 0 | 1 = 0;
            // update messageTimeRef to next day if day doesn't match and set flag to 1
            // Here, flag determines whether or not to display the date header
            if (!isSameDay(messageTimeCategoryRef.current, message.time)) {
              showMessageDateHeaderFlag = 1;
              messageTimeCategoryRef.current = message.time;
            }
            return (
              <ChatMessageDetails
                key={message.id}
                message={message}
                showMessageDateHeaderFlag={showMessageDateHeaderFlag}
              />
            );
          })}
          {typing && (
            <Stack sx={messageReceiveStyled}>
              <Stack direction="row" spacing={4}>
                <Text>{typing}</Text>
              </Stack>
            </Stack>
          )}
        </Stack>
      </PerfectScrollbar>
      <Stack pt="2">
        <InputGroup size="md">
          <Textarea
            paddingRight="16"
            overflow="hidden"
            ref={txtAreaChatRef}
            onKeyPress={handleChatByEnter}
            placeholder="Aa"
          />
          <InputRightElement p="10" h="100%">
            <Button
              size="md"
              rounded="3xl"
              bg="chat.400"
              p="2"
              onClick={handleChat}
              _hover={{ color: 'white', bgColor: 'chat.400' }}>
              <Icon as={BiPaperPlane} w="6" h="6" color="white" />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Stack>
    </Stack>
  );
};

export default ChatBot;

import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import PMSPaymentLinkAPI from 'api/pms_payment_link';
import { wrapperStyles } from 'assets/css/commonStyles';
import CreateCustomerDetailPaymentLinkForm from 'components/pms/CreateCustomerDetailPaymentLinkForm';
import CreatePaymentLinkForm from 'components/pms/CreatePaymentLinkForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { forwardRef, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const CreatePaymentLink: React.FC = () => {
  const CreatedAtInput = forwardRef((props: any, ref: any) => {
    return <Input {...props} />;
  });

  CreatedAtInput.displayName = 'CreatedAtInput';

  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const pmsPaymentLinkAPI = new PMSPaymentLinkAPI();
  const methods = useForm<any>();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [errorMessage, setErrorMessage] = useState('');

  const createPaymentLink = useMutation(
    (data: any) => pmsPaymentLinkAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.payment_link_send_successfully,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(`paymentLinksList`);
        history.push(routes.pms.paymentLinks.list);
      },
      onError: (error: any) => {
        setErrorMessage(strings.payment_link_sending_failed);
      },
    }
  );
  const onSubmit = (data: any) => {
    const formData = { ...data };
    formData.reservation_id = data?.reservation_id?.id;
    // createPaymentLink.mutate(formData);
  };

  return (
    <>
      <Stack direction="column" spacing="4" minHeight="90vh">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.creditCard.list}>
              {strings.pms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.paymentLinks.list}>
              {strings.payment_links}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pms.paymentLinks.add}>
              {strings.create_payment_links}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.create_payment_links}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form}>
              <Stack direction="column" spacing="4">
                {createPaymentLink.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                    {!errorMessage && strings.payment_link_sending_failed}
                  </Alert>
                )}
                <text>{strings.identifier}</text>
                <Tabs variant="soft-rounded" colorScheme="primary">
                  <TabList mb="1em">
                    <Tab
                      _selected={{ color: 'white', bg: '#7cb599' }}
                      border={'1px solid #ACACBE'}>
                      {strings.booking_number}
                    </Tab>
                    <Tab
                      _selected={{
                        color: 'white',
                        bg: '#7cb599',
                      }}
                      border={'1px solid #ACACBE'}
                      ml="1em">
                      {strings.customer_name}
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <CreatePaymentLinkForm />
                    </TabPanel>
                    <TabPanel>
                      <CreateCustomerDetailPaymentLinkForm />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <ButtonGroup alignSelf="flex-end">
                  <Button
                    colorScheme="primary"
                    size="lg"
                    variant="outline"
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                  <Button
                    size="lg"
                    colorScheme="primary"
                    type="button"
                    onClick={methods.handleSubmit(onSubmit)}>
                    {strings.send_payment_link}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default CreatePaymentLink;

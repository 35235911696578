import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import BMSServiceBookingResource from 'api/bms-service-booking';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import AddServicesBookingForm from 'components/bms_booking/AddServicesBookingForm';
import ServiceInformation from 'components/bms_booking/bms_booking_preview/ServiceInformation';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE } from 'constants/common';
import routes from 'constants/routes';
import { BookingSchema } from 'constants/schema';
import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const AddServiceBookings = () => {
  const { id: reservationID }: any = useParams();
  const { search } = useLocation();
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

  const methods = useForm<BookingSchema>();
  const { setValue, watch } = methods;
  const formValues = watch();

  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [serviceBookingErrorMessage, setServiceBookingErrorMessage] =
    useState<string>('');

  const bmsBookingAPI = new BMSBookingResource();
  const serviceGroupAPI = new SMSServiceGroupResource();
  const bmsServiceBookingAPI = new BMSServiceBookingResource();

  const updateServiceBook = useMutation((data: any) =>
    bmsServiceBookingAPI.store(data)
  );

  const {
    data: bookingDetailData,
    isLoading,
    isError,
  } = useQuery(
    `bookingDetails-${reservationID}`,
    () =>
      bmsBookingAPI.get(reservationID).then((res) => {
        let data = res.data.data;
        setValue('from_date', data.from_date);
        setValue('to_date', data.to_date);
        return data;
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const serviceGroupsQuery = useQuery(
    [`service-group`],
    () =>
      serviceGroupAPI
        .list({ limit: DEFAULT_PAGE_SIZE })
        .then((res) => res.data?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: serviceBookingsPriceList, isLoading: quotePriceLoading } =
    useQuery(
      ['service-booking-price', formValues],
      () =>
        bmsServiceBookingAPI
          .quotePrice(formValues.service_bookings)
          .then((res) => res.data?.data),
      {
        enabled: formValues?.service_bookings?.length > 0,
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  let totalServicePrice = useMemo(() => {
    let total = serviceBookingsPriceList?.reduce(
      (acc: number, current: any) =>
        (acc += Number(current.total_price) + Number(current.booking_fee)),
      0
    );
    return total;
  }, [serviceBookingsPriceList]);

  const onSubmit = (data: any) => {
    setServiceBookingErrorMessage('');
    data.booking_id = reservationID;
    delete data.from_date;
    delete data.to_date;

    updateServiceBook.mutate(data, {
      onSuccess: () => {
        toast({
          title: strings.success,
          status: 'success',
          isClosable: true,
        });
        history.push(
          routes.bms.booking.details.replace(':id', reservationID) + search
        );
      },
      onError: (err: any) => {
        toast({
          title: strings.service_udpate_failed,
          description: err?.response?.data?.message,
          status: 'error',
          isClosable: true,
        });
        onClose();
      },
    });
  };

  if (serviceGroupsQuery.isLoading || isLoading) {
    return <CenterSpinner />;
  } else if (serviceGroupsQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
            {strings.bms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
            {strings.booking}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem
          isCurrentPage
          color="gray.900"
          as={RouterLink}
          to={routes.bms.booking.details.replace(':id', reservationID)}>
          <BreadcrumbLink textTransform="capitalize">
            {bookingDetailData.booking_number}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {strings.add_service}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.formatString(
            strings.add_service_for_booking,
            bookingDetailData.booking_number
          )}
        </Heading>
      </Flex>

      <Accordion
        borderColor="white"
        allowToggle
        allowMultiple
        defaultIndex={[0, 1, 2]}>
        <FormProvider {...methods}>
          <form ref={formRef}>
            <Stack direction="column" spacing="4">
              <AccordionItem borderColor="white" boxShadow="box" bg="white">
                <h2>
                  <AccordionButton p="4">
                    <Box flex="1" textAlign="left">
                      <Flex justify="space-between">
                        <Heading fontSize="18px" fontWeight="medium">
                          {strings.services}
                        </Heading>
                      </Flex>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding="0">
                  <Stack sx={wrapperStyles}>
                    <Stack direction="column" spacing="4">
                      <AddServicesBookingForm
                        serviceGroupList={serviceGroupsQuery?.data}
                        serviceBookingErrorMessage={serviceBookingErrorMessage}
                      />
                    </Stack>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <ButtonGroup justifyContent="flex-end">
                <Button
                  colorScheme="primary"
                  size="lg"
                  variant="outline"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
                <Button
                  colorScheme="primary"
                  type="button"
                  onClick={onOpen}
                  disabled={formValues?.service_bookings?.length === 0}
                  size="lg">
                  {strings.add_service}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Accordion>
      <Modal size="4xl" isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="md">{strings.booking_preview}</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" spacing="6">
              {quotePriceLoading && (
                <Center>
                  <Spinner size="lg" color="primary.500" thickness="3px" />
                </Center>
              )}
              {!quotePriceLoading && serviceBookingsPriceList?.length > 0 && (
                <ServiceInformation
                  totalServicePrice={totalServicePrice}
                  serviceBookingsPriceList={serviceBookingsPriceList}
                />
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={onClose}>
                {strings.cancel}
              </Button>
              <Button
                colorScheme="primary"
                onClick={methods.handleSubmit(onSubmit)}
                isLoading={updateServiceBook.isLoading}
                type="submit">
                {strings.save}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default AddServiceBookings;

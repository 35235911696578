import {
  Box,
  List,
  ListItem,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { PermissionGroup } from 'constants/interfaces';
import { PermissionGroupSchema, PermissionItemSchema } from 'constants/schema';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getCommonPermissions } from 'utils/listInfo';

interface Permission {
  id: number;
  name: string;
  guard_name: string;
}
interface Props {
  permissionViaRoleList: Permission[];
  optimisticPermissionViaRoleAndDirect: Permission[];
  permissionGroupQuery: any;
  onOptimisticPermissionAdd: (permission: PermissionItemSchema) => void;
  onOptimisticPermissionRemove: (permission: PermissionItemSchema) => void;
}

const UserRightsManager: React.FC<Props> = (props) => {
  const {
    permissionViaRoleList,
    permissionGroupQuery,
    optimisticPermissionViaRoleAndDirect,
    onOptimisticPermissionAdd,
    onOptimisticPermissionRemove,
  } = props;
  const { register } = useFormContext();

  const tabStyle = {
    justifyContent: 'space-between',
    px: '4',
    py: '3',
    fontWeight: '500',
    fontSize: 'sm',
    position: 'relative',
    textTransform: 'capitalize',
  };

  const activeTagStyle = {
    color: 'heading',
    bg: 'white',
  };

  const tagStyle = {
    color: 'white',
    bg: 'primary.500',
  };

  const [tabIndex, setTabIndex] = useState<number>(0);

  const rightsFromRoleToDisplay = permissionGroupQuery.data.reduce(
    (acc: PermissionGroup[], currentGroup: PermissionGroup) => {
      const commonPermissions = getCommonPermissions(
        currentGroup.permissions,
        optimisticPermissionViaRoleAndDirect
      );
      const newGroup = { ...currentGroup, permissions: commonPermissions };
      return [...acc, newGroup];
    },
    []
  );

  if (permissionGroupQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Box border="1px" borderColor="gray.100" rounded="sm" mt="4">
      <Tabs
        orientation="vertical"
        variant="unstyled"
        tabIndex={tabIndex}
        onChange={(index: number) => setTabIndex(index)}>
        <TabList w="xs" borderRight="1px" borderColor="gray.100">
          {permissionGroupQuery?.data?.map(
            (permissionGroup: PermissionGroupSchema, index: number) => (
              <Tab
                key={permissionGroup.id}
                sx={tabStyle}
                _selected={{ color: 'white', bg: 'primary.500' }}>
                {strings.getString(permissionGroup.name.split('-').join('_'))}
                {rightsFromRoleToDisplay[index].permissions.length > 0 && (
                  <Tag
                    sx={tabIndex === index ? activeTagStyle : tagStyle}
                    justifyContent="center"
                    borderRadius="xl"
                    w="8">
                    {rightsFromRoleToDisplay[index].permissions.length}
                  </Tag>
                )}
              </Tab>
            )
          )}
        </TabList>
        <TabPanels>
          {permissionGroupQuery?.data?.map(
            (permissionGroup: PermissionGroupSchema) => (
              <TabPanel key={permissionGroup.id}>
                <List spacing="6" color="gray.500">
                  {permissionGroup.permissions.map(
                    (permissionItem: PermissionItemSchema) => {
                      const checkStatus = permissionViaRoleList.some(
                        (permission: Permission) =>
                          permission.id === +permissionItem.id
                      );
                      const permissionItemId = permissionItem.id.toString();
                      return (
                        <ListItem key={permissionItem.id}>
                          {checkStatus ? (
                            <>
                              {/* Actual checkbox component is hidden showing a dummy disabled checkbox 
                              as the checkbox element doesn't have a readonly property */}
                              <input
                                type="checkbox"
                                id={`permissions-${permissionItemId}`}
                                className="primary-accent-color"
                                checked
                                disabled
                              />
                              <input
                                type="hidden"
                                className="primary-accent-color"
                                id={`permissions-${permissionItemId}`}
                                {...register(`permissions.${permissionItemId}`)}
                              />
                            </>
                          ) : (
                            <input
                              type="checkbox"
                              className="primary-accent-color"
                              id={`permissions-${permissionItemId}`}
                              {...register(`permissions.${permissionItemId}`)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  onOptimisticPermissionAdd(permissionItem);
                                } else {
                                  onOptimisticPermissionRemove(permissionItem);
                                }
                              }}
                            />
                          )}
                          <label
                            htmlFor={`permissions-${permissionItemId}`}
                            style={{
                              textTransform: 'capitalize',
                              fontSize: '14px',
                              marginLeft: '0.5rem',
                            }}>
                            {strings.getString(
                              permissionItem.name.split('-').join('_')
                            )}
                          </label>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </TabPanel>
            )
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UserRightsManager;

import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/external_company';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ExternalCompanySchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { maskPhone } from 'utils';

const listItemStyles = {
  display: 'flex',
  mb: '4',
};

const titleStyles = {
  minW: '60',
  color: 'heading',
  fontWeight: 'semibold',
};

const descriptionStyles = {
  color: 'heading',
  fontWeight: 'medium',
  whiteSpace: 'nowrap',
};

const CompanyProfile: React.FC = () => {
  const { id }: { id: string } = useParams();

  const companyAPI = new CompanyResource();
  const toast = useToast();
  const { search } = useLocation();
  const checkUserEditPermission = useCheckPermission(
    [PermissionRequest['manage:external-companies']],
    routes.users.externalCompanies.edit.replace(':id', id.toString())
  );

  const companyDetails = useQuery<ExternalCompanySchema>(
    [`user${id}`, id],
    () => companyAPI.get(id).then((res) => res.data.data)
  );

  if (companyDetails.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }
  if (companyDetails.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.users.externalCompanies.list + search}>
              {strings.external_companies}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {companyDetails.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.company_profile}
          </Heading>
          <Button
            size="lg"
            colorScheme="primary"
            type="button"
            onClick={checkUserEditPermission}>
            {strings.edit_company}
          </Button>
        </Flex>
        <Stack direction={['column', 'row']} spacing="4">
          <Stack sx={wrapperStyles}>
            <Box>
              <List>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.company_name}</Text>
                  <Text sx={descriptionStyles}>
                    {companyDetails.data?.name}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.email}</Text>
                  <Text sx={descriptionStyles}>
                    {companyDetails.data?.email}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.cellphone}</Text>
                  <Text sx={descriptionStyles}>
                    {companyDetails.data?.phone
                      ? maskPhone(companyDetails.data.phone)
                      : '-'}
                  </Text>
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default CompanyProfile;

import { Tag } from '@chakra-ui/tag';
import { strings } from 'config/localization';
import { ContactType } from 'constants/schema';
import React from 'react';

interface Props {
  label: ContactType;
}

const LabelTags: React.FC<Props> = (props) => {
  let { label } = props;
  let bg = '';
  let color = '';
  switch (label) {
    case 'owner':
      bg = 'orange.400';
      color = 'orange.50';
      break;
    case 'guest':
      bg = 'blue.400';
      color = 'blue.50';
      break;
    default:
      bg = '';
      color = '';
  }

  return (
    <Tag
      bg={bg}
      rounded="md"
      color={color}
      fontSize="sm"
      size="sm"
      fontWeight="medium">
      {strings[label]}
    </Tag>
  );
};

export default LabelTags;

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import NewsletterAPI from 'api/newsletter';
import {
  Files,
  InstagramCircle,
  PinterestCircle,
  VimeoCircle,
} from 'assets/icons';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import {
  IMPRINT_URL,
  INSTAGRAM_URL,
  PINTEREST_URL,
  PRIVACY_POLICY_URL,
  VIMEO_URL,
  WEBSITE_URL,
} from 'constants/common';
import routes from 'constants/routes';
import { NewsletterDetail } from 'constants/schema';
import FileSaver from 'file-saver';
import React from 'react';
import { BiCloudDownload, BiPaperclip } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { localizeGroupOfRecipient } from 'utils';

const ViewNewsletterDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const newsletterDetailsAPI = new NewsletterAPI();
  const { search } = useLocation();

  const { data: newsLetterDetail } = useQuery<NewsletterDetail>(
    [`newsletterDetail`],
    async () => {
      return await newsletterDetailsAPI.get(id).then((res) => res.data.data);
    }
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                newsLetterDetail?.status === 'confirmed'
                  ? routes.newsletter.newsletters.list + search
                  : routes.newsletter.draft.list + search
              }>
              {newsLetterDetail?.status === 'confirmed'
                ? strings.newsletters
                : strings.drafts}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.preview_message}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.preview_message}
          </Heading>
          {newsLetterDetail?.status === 'pending' && (
            <RouterLink
              to={`${routes.newsletter.newsletters.edit.replace(
                ':id',
                id.toString() + search
              )}`}>
              <Button colorScheme="primary" size="lg" type="button">
                {strings.edit}
              </Button>
            </RouterLink>
          )}
        </Flex>

        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['4', '32']}
          shadow="box"
          w="full">
          <Stack dir="column">
            <Flex>
              {strings.subject}:&nbsp;
              <Box fontWeight="bold">{newsLetterDetail?.subject}</Box>
            </Flex>
            <Flex>
              {strings.group_of_recipients}:
              {newsLetterDetail?.recipient_group?.map(
                (item: any, id: number) => (
                  <Flex pl="4" key={id}>
                    {id !== 0 && <Divider orientation="vertical" pl="3" />}
                    <Box
                      fontWeight="bold"
                      textTransform="capitalize"
                      alignSelf="center">
                      {localizeGroupOfRecipient(item)}
                    </Box>
                  </Flex>
                )
              )}
            </Flex>
          </Stack>
          <Stack
            direction="column"
            spacing="4"
            bg="#F5F5F5"
            shadow="box"
            w="full">
            <Stack spacing="4" p="4">
              <Center>
                <Image src={Logo} width="340px" objectFit="contain" />
              </Center>
            </Stack>
            <Divider borderColor="gray.300" marginTop="0 !important" />
            <Stack
              dir="column"
              spacing="4"
              height="auto"
              p="8"
              marginTop="0 !important">
              <Editor
                tinymceScriptSrc={
                  process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                }
                disabled={true}
                inline={true}
                initialValue={newsLetterDetail?.message}
              />
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing="4"
            bg="#F0F0F0"
            color="paragraph"
            shadow="box"
            p={['0', '4']}
            marginTop="0px !important"
            w="full">
            <Flex justifyContent="flex-end" marginInline="3">
              <Text>Verbinden Sie sich mit uns</Text>
            </Flex>

            <Flex justifyContent={'flex-end'}>
              <Tooltip label="Instagram" hasArrow>
                <a
                  href={INSTAGRAM_URL}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Box
                    bg="white"
                    shadow="box"
                    w={12}
                    h={12}
                    rounded="full"
                    marginInline="2"
                    p="1">
                    <Image src={InstagramCircle} />
                  </Box>
                </a>
              </Tooltip>
              <Tooltip label="Vemo" hasArrow>
                <a href={VIMEO_URL} target="_blank" rel="noopener noreferrer">
                  <Box
                    bg="white"
                    shadow="box"
                    w={12}
                    h={12}
                    rounded="full"
                    marginInline="2"
                    p="1">
                    <Image src={VimeoCircle} />
                  </Box>
                </a>
              </Tooltip>
              <Tooltip label="Pinterest" hasArrow>
                <a
                  href={PINTEREST_URL}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Box
                    bg="white"
                    shadow="box"
                    w={12}
                    h={12}
                    rounded="full"
                    marginInline="2"
                    p="1">
                    <Image src={PinterestCircle} />
                  </Box>
                </a>
              </Tooltip>
            </Flex>
          </Stack>
          <Stack
            direction="column"
            spacing="4"
            bg="#E5E5E7"
            color="paragraph"
            shadow="box"
            p={['0', '4']}
            marginTop="0px !important"
            w="full">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Flex alignItems="center">
                <Flex paddingInline="4">
                  <a
                    href={WEBSITE_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Box fontWeight="bold" textTransform="capitalize">
                      Website
                    </Box>
                  </a>
                </Flex>
                <Text fontSize="20px" fontWeight="bold">
                  |
                </Text>
                <Flex paddingInline="4">
                  <a
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Box fontWeight="bold" textTransform="capitalize">
                      Datenschutz
                    </Box>
                  </a>
                </Flex>
                <Text fontSize="20px" fontWeight="bold">
                  |
                </Text>
                <Flex paddingInline="4">
                  <a
                    href={IMPRINT_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Box fontWeight="bold" textTransform="capitalize">
                      Impressum
                    </Box>
                  </a>
                </Flex>
              </Flex>

              <Text
                color="blue.400"
                textDecoration="underline"
                fontWeight="medium">
                Newsletter abbestellen
              </Text>
            </Stack>
          </Stack>
          <Stack spacing="4" dir="row">
            {newsLetterDetail?.newsletterFiles?.length > 0 && (
              <Flex justifyItems="center">
                <Icon as={BiPaperclip} mr="2" />
                <Text fontWeight="bold">{strings.attachments}</Text>
              </Flex>
            )}
            <Flex flexWrap={'wrap'}>
              {newsLetterDetail?.newsletterFiles?.map(
                (file: any, index: number) => (
                  <Flex
                    p="3"
                    key={index}
                    border="1px"
                    borderColor="#BFBFBF"
                    borderRadius="md">
                    <Image
                      src={
                        file.mime_type.includes('image') ? file.file_url : Files
                      }
                      w="8"
                    />
                    <Stack dir="column" ml="2" pr="2">
                      <Text color="gray.600">{file.file_name}</Text>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start">
                        <Text
                          color="gray.300"
                          fontSize="sm"
                          mt="0 !important"
                          pr="2">
                          {file?.file_size / (1024 * 1024) >= 1
                            ? (file?.file_size / (1024 * 1024)).toFixed(1) +
                              'MB'
                            : (file?.file_size / 1024).toFixed(1) + 'KB'}
                        </Text>
                        <Tooltip hasArrow label={strings.download}>
                          <IconButton
                            icon={<BiCloudDownload />}
                            variant="link"
                            aria-label={strings.download}
                            minW="6"
                            onClick={() =>
                              FileSaver.saveAs(file.file_url, file.file_url)
                            }
                          />
                        </Tooltip>
                      </Box>
                    </Stack>
                  </Flex>
                )
              )}
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewNewsletterDetails;

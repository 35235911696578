import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import OMSSeasonResource from 'api/oms-season';
import { wrapperStyles } from 'assets/css/commonStyles';
import SeasonForm from 'components/rms/SeasonForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { cleanData } from 'utils';
import history from 'utils/history';

const AddSeason: React.FC = () => {
  const { id: seasonID }: any = useParams();

  const methods = useForm<any>();
  const toast = useToast();
  let seasonAPI = new OMSSeasonResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: seasonDetail } = useQuery(
    ['season-details-page', seasonID],
    () => {
      if (!seasonID) return null; // { data: null };
      return seasonAPI
        .get(seasonID)
        .then((res) => res.data.data)
        .catch();
    }
  );
  const createSeason = useMutation((data: any) => seasonAPI.store(data));
  const updateSeason = useMutation((data: any) =>
    seasonAPI.update(seasonID, data)
  );

  const onSubmit = async (formData: any) => {
    let API;
    if (seasonID) {
      API = updateSeason;
    } else {
      API = createSeason;
    }
    const data = cleanData(formData);
    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.season} ${
            seasonID ? strings.has_been_updated : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.oms.season.list);
      },
      onError: (e: any) => {
        let msg = e.response.data.message;
        let defaultMessage = `${strings.season} ${
          seasonID ? strings.has_not_been_updated : strings.has_not_been_created
        }`;

        setErrMsg(msg ?? defaultMessage);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.oms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.season.list}>
              {strings.season_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.add_season}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.add_season}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg}
                  </Alert>
                )}
                <SeasonForm data={seasonDetail} />
                <Flex justify="right">
                  <ButtonGroup mt={20}>
                    <Button
                      variant="outline"
                      isDisabled={isLoading}
                      onClick={() => history.goBack()}>
                      {strings.cancel}
                    </Button>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={isLoading}>
                      {seasonID ? strings.edit : strings.add_season}
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddSeason;

import {
  Alert,
  AlertDialog,
  AlertDialogOverlay,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Input,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import { wrapperStyles } from 'assets/css/commonStyles';
import GetNewKeyId from 'components/keys/GetNewKeyId';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import AddKeyForm from 'pages/dashboard/Key/KeyList/AddKeyForm';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiBarcodeReader } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const RFID_TAKEN_ERROR = 'rfid key already registered';

const AddKey: React.FC = () => {
  const CreatedAtInput = forwardRef((props: any, ref: any) => {
    return <Input {...props} />;
  });

  CreatedAtInput.displayName = 'CreatedAtInput';

  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [scannedKeyId, setScannedKeyId] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const keyAPI = new KeyAPI();
  const methods = useForm<any>();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  const keyService = useMutation((data: any) => keyAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.key_created_successfully,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries(`keyLogList`);
      history.push(routes.key.keyList.view.replace(':id', res.data.data.rfid));
    },
    onError: (error: any) => {
      setIsLoading(false);
      if (error?.response?.data?.message?.[0] === RFID_TAKEN_ERROR) {
        setErrorMessage(strings.rfid_taken_error);
        return;
      }
      setErrorMessage(strings.key_created_error);
    },
  });
  const onSubmit = (data: any) => {
    const formData = { ...data };
    formData.rfid = scannedKeyId;
    formData.apartmentId = data.apartmentId.value;
    setIsLoading(true);
    keyService.mutate(formData);
  };

  return (
    <>
      <Stack direction="column" spacing="4" minHeight="90vh">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.keyList.list}>
              {strings.key_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.key.keyList.add}>
              {strings.add_key}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.add_key}
          </Heading>
          <Button
            size="lg"
            colorScheme="primary"
            type="button"
            onClick={onOpen}>
            <Icon as={BiBarcodeReader} />
            {strings.rescan_key}
          </Button>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {keyService.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                    {!errorMessage && strings.notification_creation_failed}
                  </Alert>
                )}
                <AddKeyForm keyId={scannedKeyId} />
                <ButtonGroup alignSelf="flex-end">
                  <Button
                    size="lg"
                    colorScheme="primary"
                    variant="outline"
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                  <Button
                    size="lg"
                    colorScheme="primary"
                    type="submit"
                    isDisabled={!scannedKeyId}
                    isLoading={isLoading}>
                    {strings.add_key}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
        <>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            closeOnOverlayClick={false}
            isCentered>
            <AlertDialogOverlay backdropFilter="blur(10px)" />

            <GetNewKeyId
              databaseCheck={true}
              setScannedKeyId={setScannedKeyId}
              onClose={onClose}
            />
          </AlertDialog>
        </>
      </Stack>
    </>
  );
};

export default AddKey;

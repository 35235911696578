import Resource from 'api/resource';
import http from 'utils/http';

class CRMContactsResource extends Resource {
  constructor() {
    super('crm/contacts');
  }

  mergeContacts(formdata: any) {
    return http({
      url: `/crm/contact-merges`,
      method: 'POST',
      data: formdata,
    });
  }
  mergeDetails(id: string | number, query?: any) {
    return http({
      url: `/crm/contact-merges/${id}`,
      method: 'GET',
      params: query,
    });
  }
  createOwnerUser(id: number) {
    return http({
      url: `/crm/contacts/${id}/create-login`,
      method: 'PUT',
    });
  }
  listCountries() {
    return http({
      url: '/crm/countries?limit=250',
      method: 'get',
    });
  }
  getCountryDetail(id: string) {
    return http({
      url: `/crm/countries/${id}`,
      method: 'get',
    });
  }
}

export { CRMContactsResource as default };

import { SOCK_CONN_TYPE } from 'context/SocketContext';
import http from 'utils/http';
import { API_URL } from './url';

export const getDeviceId = async (
  type: SOCK_CONN_TYPE,
  cDeviceName: string,
  eDeviceName: string,
  cb?: (data: any, err?: unknown) => void
) => {
  try {
    const { data } = await http({
      url: API_URL.get_device_id.replace(':type', type),
      params: {
        customerDeviceName: cDeviceName,
        employeeDeviceName: eDeviceName,
      },
      method: 'get',
    });
    if (cb) {
      return cb(data);
    }
    return data;
  } catch (err) {
    if (cb) {
      return cb(null, err);
    }
    throw err;
  }
};

import { Td, Tooltip, Tr } from '@chakra-ui/react';
import { PNSOptions } from 'constants/schema';
import React from 'react';
import { truncateString } from 'utils';
import { utcToLocal } from 'utils/DateFormat';

interface notificationData {
  id: number;
  createdAt: string;
  channel: PNSOptions;
  title: string;
  message: string;
  launchUrl: string;
  successCount: number;
  respondCount: number;
}
interface Props {
  notificationData: notificationData;
  index: number;
}
const NotificationListItem: React.FC<Props> = (props) => {
  const { notificationData, index } = props;

  return (
    <Tr key={notificationData.id}>
      <Td>{index}</Td>
      <Td>{utcToLocal(notificationData?.createdAt).replace(' ', ', ')}</Td>
      <Td title={notificationData?.title}>
        {truncateString(notificationData?.title, 25) ?? '-'}
      </Td>
      <Td>
        <Tooltip
          bg="white"
          color="gray.400"
          label={notificationData?.message}
          textAlign="justify"
          p={5}>
          {truncateString(notificationData?.message, 25)}
        </Tooltip>
      </Td>
      <Td>
        <Tooltip
          bg="white"
          color="gray.400"
          textAlign="justify"
          p={5}
          label={notificationData?.launchUrl}>
          {truncateString(notificationData?.launchUrl, 20) ?? '-'}
        </Tooltip>
      </Td>
      <Td isNumeric>
        {notificationData?.successCount ?? '-'}
        &nbsp;/&nbsp;
        {notificationData?.respondCount ?? '-'}
      </Td>
    </Tr>
  );
};

export default NotificationListItem;

import { Flex, Heading, List, ListItem, Stack, Text } from '@chakra-ui/react';
import PermissionResources from 'api/permissions';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { Permission, PermissionGroup } from 'constants/interfaces';
import React from 'react';
import { useQuery } from 'react-query';
import { getCommonPermissions } from 'utils/listInfo';

interface Props {
  userId: string | number;
  userRole: string;
  isUserPermissionLoading: boolean;
  userPermissions: any;
}

const UserPermissionDisplay: React.FC<Props> = (props) => {
  const { userRole, isUserPermissionLoading, userPermissions } = props;
  const permissionGroupsApi = new PermissionResources();

  /**
   * Fetch Permission Groups (menus along with permissions)
   */
  const { isLoading: isPermissionGroupLoading, data: permissionGroups } =
    useQuery('permissionGroups', () =>
      permissionGroupsApi.list().then((res) => res.data.data)
    );

  const rightsStyles = {
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
  };

  const rightsStylesWithTrailingDash = {
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
    _after: {
      content: `""`,
      position: 'absolute',
      top: '1',
      right: '0',
      width: '1px',
      height: '10px',
      bg: 'gray.400',
    },
  };

  if (isUserPermissionLoading || isPermissionGroupLoading)
    return <CenterSpinner />;

  const rightsFromRole: Permission[] = userPermissions?.via_role;
  const additionalRights: Permission[] = userPermissions?.direct;

  /**
   * Filter the permissionGroupArray with only the permissions that are present
   * in a given user's permissions
   *
   * If permissionArray inside permissionGroupArray is a empty array after filtering,
   * remove the permission group
   */
  const rightsFromRoleToDisplay = permissionGroups.reduce(
    (acc: PermissionGroup[], currentGroup: PermissionGroup) => {
      const commonPermissions = getCommonPermissions(
        currentGroup.permissions,
        rightsFromRole
      );
      if (commonPermissions.length < 1) return [...acc];
      const newGroup = { ...currentGroup, permissions: commonPermissions };
      return [...acc, newGroup];
    },
    []
  );

  const additionalRightsToDisplay = permissionGroups.reduce(
    (acc: PermissionGroup[], currentGroup: PermissionGroup) => {
      const commonPermissions = getCommonPermissions(
        currentGroup.permissions,
        additionalRights
      );
      if (commonPermissions.length < 1) return [...acc];
      const newGroup = { ...currentGroup, permissions: commonPermissions };
      return [...acc, newGroup];
    },
    []
  );

  return (
    <>
      {rightsFromRole?.length > 0 && (
        <Stack spacing="4" direction="column">
          <Heading size="md">
            {strings.right_from_role} :{' '}
            {strings.getString(userRole.split(' ').join('_'))}
          </Heading>

          <Stack
            bg="white"
            shadow="box"
            p={['3', '6']}
            rounded="sm"
            overflow="auto">
            <List>
              {rightsFromRoleToDisplay.map(
                (pGroup: PermissionGroup, index: number) => (
                  <ListItem
                    display="flex"
                    mt={index !== 0 ? 4 : 0}
                    key={pGroup.id}>
                    <Text minW="40" color="gray.900" fontWeight="semibold">
                      {strings.getString(pGroup.name.split('-').join('_'))}
                    </Text>
                    <Flex wrap="wrap">
                      {pGroup.permissions?.map(
                        (per: Permission, perIndex: Number) => (
                          <Text
                            color="heading"
                            fontWeight="medium"
                            sx={
                              perIndex === pGroup.permissions.length - 1
                                ? rightsStyles
                                : rightsStylesWithTrailingDash
                            }
                            key={per.id}>
                            {strings.getString(per.name.split('-').join('_'))}
                          </Text>
                        )
                      )}
                    </Flex>
                  </ListItem>
                )
              )}
            </List>
          </Stack>
        </Stack>
      )}

      {additionalRights?.length > 0 && (
        <Stack spacing="4" direction="column" mt="6">
          <Heading size="md">{strings.additional_rights}</Heading>

          <Stack
            bg="white"
            shadow="box"
            p={['3', '6']}
            rounded="sm"
            overflow="auto">
            <List>
              {additionalRightsToDisplay.map(
                (pGroup: PermissionGroup, index: number) => (
                  <ListItem
                    display="flex"
                    mt={index !== 0 ? 6 : 0}
                    key={pGroup.id}>
                    <Text minW="40" color="gray.900" fontWeight="semibold">
                      {strings.getString(pGroup.name.split('-').join('_'))}
                    </Text>
                    <Flex wrap="wrap">
                      {pGroup.permissions?.map(
                        (per: Permission, perIndex: Number) => (
                          <Text
                            color="heading"
                            fontWeight="medium"
                            sx={
                              perIndex === pGroup.permissions.length - 1
                                ? rightsStyles
                                : rightsStylesWithTrailingDash
                            }
                            key={per.id}>
                            {strings.getString(per.name.split('-').join('_'))}
                          </Text>
                        )
                      )}
                    </Flex>
                  </ListItem>
                )
              )}
            </List>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default UserPermissionDisplay;

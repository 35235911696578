import {
  Box,
  Flex,
  IconButton,
  Image,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { Merge } from 'assets/icons';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ContactSchema } from 'constants/schema';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BiEdit, BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';
import LabelTags from './LabelTags';

interface Props {
  contact: ContactSchema;
  index: number;
  startingSN: number;
  search: string;
  openCreateOwnerUserModal: (id: number) => void;
}

const ContactListItem = (props: Props) => {
  const { contact, index, startingSN, search, openCreateOwnerUserModal } =
    props;
  const {
    id,
    first_name,
    last_name,
    type,
    source,
    contactDetail,
    contactEvent,
    is_user,
  } = contact;

  return (
    <Tr key={id}>
      <Td>{startingSN + index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.crm.contact.details.replace(':id', id.toString()) + search
          }>
          <Flex>
            {first_name + ' ' + last_name}
            {contactDetail?.is_merged && (
              <Box w="6" h="6" ml="2" color="orange.300">
                <Image src={Merge} />
              </Box>
            )}
          </Flex>
        </RouterLink>
      </Td>
      <Td>
        <LabelTags label={type} />
      </Td>
      <Td>{strings[source]}</Td>
      <Td>{DateFormat(contactDetail?.created_at)}</Td>
      <Td>
        <RouterLink to={routes.chat.archived.details}>{'-'}</RouterLink>
      </Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        {contactEvent?.event === 'ticket' && contactEvent?.event_id ? (
          <RouterLink
            to={routes.ticket.task.view.replace(
              ':id',
              contactEvent?.event_id.toString()
            )}>
            {strings.ticket} #{contactEvent?.event_id}
          </RouterLink>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        <RouterLink
          to={
            routes.crm.contact.details.replace(':id', id.toString()) + search
          }>
          <Tooltip hasArrow label={strings.view_contact}>
            <IconButton
              icon={<BiShow size="18" />}
              variant="link"
              aria-label={strings.view_contact}
              color="green.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={
            routes.crm.contact.edit.replace(':id', id.toString()) +
            search +
            `&contactType=${type}`
          }>
          <Tooltip hasArrow label={strings.edit_contact}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_contact}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        {type === 'owner' && (
          <Tooltip
            hasArrow
            label={is_user ? strings.user_already_exists : strings.create_user}>
            <IconButton
              icon={<AiOutlineUserAdd size="18" />}
              isDisabled={is_user}
              variant="link"
              aria-label={strings.create_user}
              color="purple.300"
              minW="8"
              onClick={() => {
                openCreateOwnerUserModal(contact.id);
              }}
            />
          </Tooltip>
        )}
      </Td>
    </Tr>
  );
};

export default ContactListItem;

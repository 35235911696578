import {
  Heading,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import {
  tableRowSelectStyles,
  tableRowStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import { BookingSchema } from 'constants/schema';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

type Props = Omit<ModalProps, 'children'>;
interface FilterParams {
  currentPage: number;
  pageSize: number;
  name: string;
}
const CustomerListModal = (props: Props) => {
  const { onClose } = props;
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const [customerData, setCustomerData] = useState<any>({});
  const { setValue } = useFormContext<BookingSchema>();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    name: searchValues.name ?? '',
  });

  const bookingAPI = new BMSBookingResource();

  const existingCustomerListQuery: any = useQuery(
    ['existingCustomerList', filterParams.name],
    async () => {
      let { name } = filterParams;
      let queryParams: any = {
        page: 1,
        limit: DEFAULT_PAGE_SIZE * 2,
      };
      if (name) queryParams.keyword = name;
      const res = await bookingAPI.existingCustomerList(queryParams);
      return res.data;
    }
  );

  const customerList = existingCustomerListQuery?.data?.data;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      name: '',
    }));
  }

  const setCustomerInformationInForm = useCallback(
    (customerInfo: any) => {
      setValue('customer_id', customerInfo?.id);
      setValue('salutation', customerInfo?.salutation);
      setValue('title', customerInfo?.title);
      setValue('first_name', customerInfo?.first_name);
      setValue('last_name', customerInfo?.last_name);
      setValue('street', customerInfo?.street);
      setValue('house_number', customerInfo?.house_no);
      setValue('zip_code', customerInfo?.zip_code);
      setValue('city', customerInfo?.city);
      setValue('country_code', customerInfo?.country_code);
      setValue('email', customerInfo?.email);
      setValue('phone_number', customerInfo?.phone);
      setValue('customer_type', customerInfo?.type);
      setCustomerData(customerInfo);
    },
    [setValue]
  );

  useEffect(() => {
    if (customerList && customerData?.id) {
      const selectedCustomer = customerList.find(
        (customer: any) => customer.id === customerData.id
      );
      setCustomerInformationInForm(selectedCustomer);
    }
  }, [customerList, customerData, setValue, setCustomerInformationInForm]);

  const handleSelectedCustomer = (data: any) => {
    let formData = { ...data };
    handleReset();
    setCustomerInformationInForm(formData);
    onClose();
  };

  return (
    <Modal size="6xl" {...props}>
      <ModalOverlay />
      <ModalContent sx={wrapperStyles}>
        <ModalHeader>
          <Heading size="md">{strings.select_existing_customer}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            <Stack sx={wrapperStyles}>
              <form onSubmit={(e) => e.preventDefault()}>
                <Stack
                  direction={['column', 'row']}
                  spacing="4"
                  align={['start', 'end']}>
                  <InputGroup>
                    <Input
                      type="text"
                      name="name"
                      placeholder={strings.name}
                      value={filterParams.name}
                      onChange={handleInputChange}
                      size="lg"
                    />
                    {/* <InputRightElement>
                        <IconButton
                          icon={<IoClose />}
                          variant="link"
                          aria-label={strings.delete}
                          color="red.400"
                          ml={1}
                          minW="10"
                          onClick={handleReset}
                        />
                      </InputRightElement> */}
                  </InputGroup>
                </Stack>
              </form>
            </Stack>
            <Stack>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{strings.first_name}</Th>
                      <Th>{strings.last_name}</Th>
                      <Th>{strings.street}</Th>
                      <Th>{strings.house_no}</Th>
                      <Th>{strings.post_code}</Th>
                      <Th>{strings.town}</Th>
                      <Th>{strings.country}</Th>
                      <Th>{strings.email_address}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {customerList?.map((customer: any) => {
                      return (
                        <Tr
                          key={customer.id}
                          sx={
                            customerData?.id === customer?.id
                              ? tableRowSelectStyles
                              : tableRowStyles
                          }
                          onClick={() => handleSelectedCustomer(customer)}>
                          <Td>{customer?.first_name}</Td>
                          <Td>{customer?.last_name}</Td>
                          <Td>{customer?.street}</Td>
                          <Td>{customer?.house_no}</Td>
                          <Td>{customer?.zip_code}</Td>
                          <Td>{customer?.city}</Td>
                          <Td>{customer?.country?.name}</Td>
                          <Td>{customer?.email}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  {existingCustomerListQuery?.isLoading && (
                    <TableSkeletonLoader rows={7} cols={9} />
                  )}
                </Table>
              </TableContainer>
            </Stack>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomerListModal;

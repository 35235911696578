import { Box, FormControl, FormLabel, Grid, GridItem } from '@chakra-ui/react';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { formatCurrency, formatPrice } from 'utils/index';

interface Props {
  schemeData: any;
  schemeType: string;
}

const ServiceSchemeType: React.FC<Props> = (props) => {
  const { schemeData, schemeType } = props;

  const titleStyles = {
    color: 'gray.800',
  };
  const schemeList = () => {
    return schemeData.map((data: any, key: any) => {
      let price,
        price1,
        meta,
        priceTitle,
        priceTitle1,
        metaTitle = '-';
      let cols = 2;

      if (schemeType === 'PriceSchemeByDay') {
        priceTitle = strings.price;
        metaTitle = strings.day;
        price = data.price;
        meta = data.meta.day;
      }

      if (schemeType === 'PriceSchemeByQuantity') {
        priceTitle = strings.price;
        metaTitle = strings.quantity;
        price = data.unit_price;
        meta = data.meta.unit;
      }

      if (schemeType === 'PriceSchemeByDuration') {
        priceTitle = strings.price;
        metaTitle = strings.duration;
        price = data.price;
        meta = strings.getString(data.meta.duration.toLowerCase());
      }

      if (schemeType === 'PriceSchemeBeachChair') {
        priceTitle = strings.price_a;
        priceTitle1 = strings.price_b;
        price = data.prices.summer;
        price1 = data.prices.winter;
        meta = data.scheme_name;
        cols = 3;
      }

      if (schemeType === 'PriceSchemeOneTime') {
        metaTitle = strings.unit_price;
        meta = data.unit_price;
      }

      return (
        <>
          <Grid gap="4" templateColumns={`repeat(${cols}, 1fr)`}>
            {schemeType !== 'PriceSchemeBeachChair' && (
              <>
                {!priceTitle ? (
                  <GridItem>
                    <FormControl>
                      <FormLabel sx={labelStyles}>{metaTitle} (CHF)</FormLabel>
                      <Box sx={titleStyles}>{formatCurrency(meta)} CHF</Box>
                    </FormControl>
                  </GridItem>
                ) : (
                  <>
                    <GridItem>
                      <FormControl>
                        <FormLabel sx={labelStyles}>{metaTitle}</FormLabel>
                        <Box sx={titleStyles}>{meta}</Box>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel sx={labelStyles}>
                          {priceTitle} (CHF)
                        </FormLabel>
                        <Box sx={infoStyles}>{formatCurrency(price)} CHF</Box>
                      </FormControl>
                    </GridItem>
                  </>
                )}
              </>
            )}
            {schemeType === 'PriceSchemeBeachChair' && (
              <>
                <GridItem>
                  <FormControl>
                    <Box sx={titleStyles}>{meta} </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>{priceTitle} (CHF)</FormLabel>
                    <Box sx={infoStyles}>{formatPrice(price)} CHF</Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>{priceTitle1} (CHF)</FormLabel>
                    <Box sx={infoStyles}>{formatPrice(price1)} CHF</Box>
                  </FormControl>
                </GridItem>
              </>
            )}
          </Grid>
        </>
      );
    });
  };

  return <>{schemeList()}</>;
};
export default ServiceSchemeType;

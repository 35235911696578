import {
  Box,
  Center,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import LanguageMenu from 'components/common/LanguageMenu';
import { strings } from 'config/localization';
import { AERIAL_MAP_URL } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import { serviceRoute } from 'constants/schema';
import { serviceListData } from 'data/serviceListData';
import React from 'react';
import { IconType } from 'react-icons/lib';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { checkPermissions, checkSomePermissions } from 'utils/listInfo';

const navBoxStyles = {
  p: [4, 4, 4, 8],
  color: 'white',
  rounded: 'sm',
  cursor: 'pointer',
  transition: 'ease-in-out',
  transitionDuration: '0.35s',
  bg: 'blackgreen.400',
  boxShadow: '10px 10px 5px rgba(56, 69, 73, 0.35);',
  hover: {
    bg: 'blackgreen.500',
    boxShadow: '10px 10px 5px rgba(56, 69, 73, 0.55);',
  },
};

const iconStyles = {
  w: [6, 8, 12, 12],
  h: [6, 8, 12, 12],
  m: 'auto',
};

const innerBoxStyled = {
  spacing: [2, 2, 4, 4],
  direction: 'column',
  alignItem: 'center',
};

const titleStyled = {
  color: 'white',
  align: 'center',
  textAlign: 'center',
  fontSize: [8, 10, 12, 14],
};
const linkStyled = {
  textDecoration: 'none',
};

const SplashScreen: React.FC = () => {
  const history = useHistory();

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const handleSplashTileNavigation = (routes: serviceRoute[]) => {
    const role: string = routes[0][1];
    if (role === 'read:aerial-map') {
      return window.open(AERIAL_MAP_URL, '_blank');
    }
    let authorizedRoute = routes.find((currentRoute: serviceRoute) => {
      if (currentRoute[2]) {
        return checkSomePermissions(userPermissions, [
          PermissionRequest[currentRoute[1]],
          PermissionRequest[currentRoute[2]],
        ]);
      } else {
        return checkPermissions(userPermissions, [
          PermissionRequest[currentRoute[1]],
        ]);
      }
    });
    if (authorizedRoute !== undefined) {
      history.push(authorizedRoute[0]);
    }
  };

  /**
   * Returns Icon or Image depending upon params
   *
   * @param icon
   * @returns Image | Icon
   */
  const getIcon = (icon: IconType | string) => {
    if (typeof icon === 'string') {
      return <Image src={icon} sx={iconStyles} color="white" />;
    }
    return <Icon as={icon} sx={iconStyles} />;
  };

  return (
    <>
      <Stack spacing="8" bg="primary.500" p="8" minH="100vh" id="splashScreen">
        <Stack direction="row" justifyContent="center" position="relative">
          <Stack direction="column" spacing="8">
            <Stack spacing="4">
              <Center>
                <Image src={Logo} w={[10, 16, 20, 24]} />
              </Center>
              <Stack>
                <Text
                  align="center"
                  fontSize={['sm', 'lg', '3xl', '6xl']}
                  color="blackgreen.400"
                  fontWeight="semibold">
                  MYVAL
                </Text>
              </Stack>
            </Stack>
            <Stack spacing="4">
              <Text
                align="center"
                fontSize={['sm', 'lg', 'xl', '3xl']}
                color="blackgreen.400">
                Service Center
              </Text>
            </Stack>
          </Stack>

          <Stack pos="absolute" right="5%">
            <LanguageMenu />
          </Stack>
        </Stack>
        <Center>
          <SimpleGrid
            columns={[2, 3, 4, 4]}
            spacing="6"
            maxW="container.lg"
            w="full">
            {serviceListData.map((item) => (
              <HideControl
                key={item.id}
                hideFor={item.hideForLabel}
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  sx={linkStyled}
                  onClick={() => handleSplashTileNavigation(item.routes)}>
                  <Tooltip hasArrow label={strings[item.tooltipLabel]}>
                    <Box sx={navBoxStyles}>
                      <Stack sx={innerBoxStyled}>
                        {getIcon(item.icon)}
                        <Text sx={titleStyled}>
                          {strings[item.splashLabel]}
                        </Text>
                      </Stack>
                    </Box>
                  </Tooltip>
                </Link>
              </HideControl>
            ))}
          </SimpleGrid>
        </Center>
      </Stack>
    </>
  );
};
export default SplashScreen;

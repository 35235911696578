import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import NewsletterListItem from 'components/newsletter/NewsletterListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
  GroupOfRecipientOptionLocalization,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';
import ReactSelectRoot from '../../../../components/ReactSelectRoot/ReactSelectRoot';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  title: string;
  created_at_start: Date | null;
  created_at_end: Date | null;
  group_of_recipients: string[];
  status: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} size="lg" />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const DraftList: React.FC = () => {
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const newsletterAPI = new NewsletterAPI();
  const groupOfRecipientRef = useRef<any>(null);
  const groupOfRecipientOptions = GroupOfRecipientOptionLocalization();

  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.created_at_start
      ? new Date(searchValues.created_at_start)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.created_at_start ? new Date(searchValues.created_at_end) : null
  );

  const group_of_recipients_url: string[] =
    searchValues?.group_of_recipients?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    created_at_start: startDate,
    created_at_end: endDate,
    group_of_recipients: group_of_recipients_url ?? [],
    status: 'pending',
  });

  const recipient_group_option = filterParams?.group_of_recipients?.map(
    (item: any) => {
      let newLabel = capitalizeFirstLetter(item);
      return {
        value: item,
        label: newLabel,
      };
    }
  );

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      created_at_start: DateFormatYMD(start),
      created_at_end: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
    setSearchURL(newFilter);
  };
  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const queryList = useQuery(
    [
      'newsLetterDraftList',
      {
        page: filterParams.currentPage,
        pageSize: filterParams.pageSize,
        title: filterParams.title,
        created_at_start: filterParams.created_at_start,
        created_at_end: filterParams.created_at_end,
        recipient_group: filterParams.group_of_recipients,
        status: filterParams.status,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        pageSize: filterParams.pageSize,
      };
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.title) queryParams.keyword = filterParams.title;
      if (startDate) queryParams.created_at_start = DateFormatYMD(startDate);
      if (endDate) queryParams.created_at_end = DateFormatYMD(endDate);
      if (filterParams.group_of_recipients)
        queryParams.recipient_group = filterParams.group_of_recipients;
      const response = await newsletterAPI.list(queryParams);
      response && setIsFilterLoading(false);
      return response?.data;
    }
  );

  const {
    data: newsletterDraftQuery,
    isLoading: newsletterDraftLoading,
    error: newsletterDraftError,
  } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    setSearchURL(data);
  };
  const setSearchURL = (filterQuery: any) => {
    let data = { ...filterQuery };

    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleGroupOfRecipientsChange = (recipient: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      group_of_recipients: recipient.map((item: any) => item.value),
    }));
    const data: any = {
      ...filterParams,
      group_of_recipients: recipient.map((item: any) => item.value),
    };
    setSearchURL(data);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      title: '',
      created_at_start: null,
      created_at_end: null,
      group_of_recipients: [],
    }));
    groupOfRecipientRef.current.select.clearValue();
    history.push(routes.newsletter.draft.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.newsletter.draft.list}>
              {strings.drafts}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.drafts}
          </Heading>
        </Flex>

        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form>
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          h="100%"
                          children={<BiSearch />}
                          color="gray.500"
                        />
                        <Input
                          type="text"
                          name="title"
                          size="lg"
                          value={filterParams.title}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.date}</FormLabel>
                      <InputGroup>
                        <InputRightElement
                          children={<BiCalendar />}
                          color="gray.500"
                          h="100%"
                        />
                        <ReactDatePickerRoot
                          placeholderText={strings.select_date}
                          dateFormat="yyyy-MM-dd"
                          customInput={<CreatedAtInput />}
                          onChange={handleDateChange}
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          value={getFormattedRangeDate(startDate, endDate)}
                          shouldCloseOnSelect={false}
                          autoComplete="off"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.group_of_recipients}</FormLabel>
                      <ReactSelectRoot
                        name="group_of_recipients"
                        closeMenuOnSelect={false}
                        placeholder={strings.select_recipient_group}
                        ref={groupOfRecipientRef}
                        isMulti
                        options={groupOfRecipientOptions}
                        defaultValue={recipient_group_option}
                        onChange={handleGroupOfRecipientsChange}
                        styles={reactSelectStyles}
                      />
                    </FormControl>

                    <Stack direction="row" spacing="4" pt={['2', '8']}>
                      <Button
                        colorScheme="primary"
                        variant="outline"
                        size="lg"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.date}</Th>
                  <Th>{strings.group_of_recipients}</Th>
                  <Th>{strings.subject}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {newsletterDraftQuery?.data.map(
                  (newsletterItem: any, index: number) => (
                    <NewsletterListItem
                      newsletterItem={newsletterItem}
                      key={newsletterItem.id}
                      index={startingSN + index}
                      status={newsletterItem.status}
                      search={search}
                    />
                  )
                )}
                {newsletterDraftLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default DraftList;

import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;
class BMSServiceBookingResource extends Resource {
  constructor() {
    super('bms/service-booking');
  }

  // Get service booking quote price
  quotePrice(data: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: `/bms/quote/service-booking`,
      method: 'POST',
      data: { service_bookings: data },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  getServicesInBooking(id: number) {
    return http({
      url: `/bms/booking/${id}/services`,
      method: 'GET',
    });
  }
}

export { BMSServiceBookingResource as default };

import { Lang } from './types';

const it: Lang = {
  /*login Credential*/
  login: 'Accesso',
  login_now: 'Accesso',
  log_in: 'Accedi',
  password: 'Password',
  forgot_your_password: 'Hai dimenticato la password',
  email: 'Email',
  your_email_address: 'Il tuo indirizzo email',
  your_password: 'La tua password',
  remember_me: 'Ricordami',
  forgot_password: 'Password dimenticata?',
  forgot_password1: 'Password dimenticata',
  back_to_login: 'Torna al login',
  password_reset: 'Richiedi il reset della password',
  login_message: 'Inserisci le tue credenziali per accedere a MYVAL AG.',
  forgot_message: "Inserisci l'email che utilizzi per accedere al tuo account.",
  myval_ag: 'MYVAL AG',
  password_info:
    'La password deve avere almeno 1 numero, 1 carattere speciale, 1 lettera maiuscola e 5 altri caratteri.',
  invalid_login_msg: 'Email o password non corrispondenti.',
  user_delete_error:
    "L'utente non può essere eliminato in quanto sta detenendo le azioni MMS",
  change_language: 'Cambia lingua',
  invalid_token: 'Questo token di reset della password non è valido.',
  two_factor_authentication: 'Autenticazione a due fattori',
  enter_the_pin_from_google_authentication:
    'Inserisci il codice da Google Authentication.',
  authenticate: 'Autenticare',
  one_time_password: 'Password a tempo',
  FA_status: 'Stato 2FA',
  disable_2FA: 'Disattiva 2FA',
  enable_2FA: 'Abilita 2FA',
  fa_message:
    "Per abilitare l'autenticazione a due fattori, scansiona questo codice QR con l'app Google Authenticator.",
  code: 'Codice',
  fa_status_tooltip: 'Genera la chiave segreta per abilitare 2FA',
  authenticator_code: 'Codice di autenticazione',
  FA_successfully: '2FA è stato abilitato con successo',
  FA_enable: '2FA è attualmente abilitato per il tuo account',
  fa_Disable: '2FA ora è disabilitato',
  authenticator_code_error: 'Errore codice di autenticazione',
  required_authenticator_code: 'Codice di autenticazione richiesto',
  fa_disable_title:
    "Se stai cercando di disabilitare l'autenticazione a due fattori, conferma la tua password e fai clic sul pulsante Disattiva 2FA.",
  activate_2FA: 'Attiva 2FA',
  deactivate_2FA: 'Disattiva 2FA',

  /*button*/
  save: 'Salva',
  save_now: 'Salva ora',
  filter: 'Filtro',
  back: 'Indietro',
  editing: 'Modifica',
  apply_filter: 'Applica filtro',
  reset_filter: 'Ripristina filtro',
  more_filter: 'Altri filtri',
  cancel: 'Annulla',
  clear: 'Cancella',
  reply: 'Rispondi',
  comment: 'Commenta',
  save_and_create_new_ticket: 'Salva e crea un nuovo ticket',
  confirm_and_close: 'Conferma e chiudi',
  confirm_and_transfer_another_items: 'Conferma e trasferisci altri elementi',
  edit_article: 'Modifica articolo',
  confirm: 'Conferma',
  profile: 'Profilo',
  my_profile: 'Il mio profilo',
  edit_profile: 'Modifica profilo',
  profile_updated: 'Profilo aggiornato.',
  profle_update_failed: 'Aggiornamento profilo non riuscito.',
  password_updated: 'Password aggiornata.',
  not_match_new_confirm_password: 'Le password non corrispondono.',
  incorrect_password: 'Password non corretta.',
  setting: 'Impostazione',
  settings: 'Impostazioni',
  logout: 'Esci',
  loggedOut: 'Disconnesso',
  customer_screen: 'Schermo cliente',
  change_password: 'Cambia password',
  change_now: 'Cambia ora',
  Current_password: 'Password attuale',
  new_password: 'Nuova password',
  confirm_password: 'Conferma password',
  invalid_current_password: 'Password attuale non valida.',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  less_than_zero: 'Valore inferiore a zero.',
  email_not_found: 'Indirizzo e-mail non trovato.',
  email_required: "L'indirizzo e-mail è richiesto",
  now: 'ora',
  secret_key: 'Chiave segreta',
  create_pair: 'Crea coppia',
  must_be_8_characters_long: 'Deve essere lungo 8 caratteri',
  generate: 'Genera',
  unpair: 'Disaccoppiare',
  this_will_unpair_the_device_are_you_sure:
    'Questo disaccoppia il dispositivo. Sei sicuro?',
  clear_second_screen: 'Cancella la seconda schermata',
  successfully_unpaired: 'Disaccoppiato con successo',
  pair_created: 'Coppia creata',

  /*Module*/
  user_management: 'Gestione utenti',
  chat: 'Chat',
  chat_overview: 'Panoramica chat',
  ticket: 'Biglietti',
  crm: 'CRM',
  contact_management: 'Gestione contatti',
  contact_center: 'Contact Center',
  feedback: 'Feedback',
  mms: 'MMS',
  dashboard: 'Dashboard',
  /*Splash Screen*/
  choose_subsystem: 'Scegliere il sottosistema',
  /*User Management*/
  user_management_system: 'Sistema di gestione degli utenti',
  user: 'Utenti',
  user_list: 'Elenchi utenti',
  user_rights: "Diritti dell'utente",
  user_roles: 'Ruoli utente',
  user_name: 'Nome utente',
  user_profile: 'Profilo utente',
  user_details: 'Dettagli utente',
  role_details: 'Dettagli ruolo',
  role: 'Ruolo',
  add_additional_role: 'Aggiungi ruolo aggiuntivo',
  add_additional_rights: 'Aggiungi diritti aggiuntivi',
  additional_rights: 'Diritti aggiuntivi',
  manage_extra_rights: 'Gestisci diritti extra',
  right_from_role: 'Diritti dal ruolo',
  add_role: 'Aggiungi ruolo',
  role_setup: 'Impostazione del ruolo',
  system: 'Sistema',
  role_name: 'Nome del ruolo',
  privillage: 'Privillage',
  add_user: 'Aggiungi utente',
  all_users: 'Tutti gli utenti',
  all_user_with_that_role: 'Tutti gli utenti con quel ruolo',
  add_new_user: 'Aggiungi nuovo utente',
  add_new_role: 'Aggiungi un nuovo ruolo',
  view_user: 'Visualizza utente',
  edit_user: 'Modifica utente',
  delete_user: 'Elimina utente',
  save_user: 'Salva utente',
  user_deatils: 'Dettagli utente',
  upload_image: 'Carica immagine',
  drop_image_or_click_to_select_image:
    "Rilascia immagine o clicca per selezionare l'immagine",
  assigned_tickets: 'Biglietti assegnati',
  action: 'Azione',
  first_name: 'Nome',
  last_name: 'Cognome',
  email_address: 'Indirizzo e-mail',
  valid_email_address: 'Si prega di fornire un indirizzo e-mail valido',
  contact_info: 'Informazioni di contatto',
  address: 'Indirizzo',
  view: 'Vista',
  add: 'Aggiungi',
  edit: 'Modifica',
  delete: 'Elimina',
  search: 'Cerca',
  new_user: 'Nuovo utente',
  has_been_assigned: "è stato assegnato all'utente",
  has_been_transferred: "è stato trasferito all'utente",
  login_with_new_password: 'Effettua il login con una nuova password',
  msg_valid_password: 'Si prega di fornire una password valida',
  msg_password_reset_link:
    'Abbiamo inviato per e-mail il link per la reimpostazione della password!',
  select_role: 'Seleziona ruolo',
  search_username: 'Cerca nome utente',
  rights: 'Diritti',
  reset_password: 'Reimposta password',
  reset_password_subtitle:
    'Inserisci i campi che usi per resettare il tuo account',
  save_reset_password: 'Salva la password di reset',
  create_password: 'Crea password',
  create_password_subtitle:
    'Inserisci i campi per creare una password per il tuo account',
  save_password: 'Salva password',
  password_helper_text:
    'Minimo 8 caratteri, almeno uno maiuscolo, uno minuscolo, un numero e un carattere speciale',
  resend_verification_email: "Reinvia l'e-mail di verifica",
  verification_email_sent: 'Email di verifica inviata',
  email_verification_success: 'Email verificata con successo',
  email_verification_failed: "Verifica dell'e-mail non riuscita",
  resend_verify_email_error: "Errore nel reinvio dell'e-mail di verifica",
  resend_verify_email_success:
    "L'e-mail di verifica è stata inviata con successo. Controlla la tua casella di posta.",
  resend_verify_email_success_to:
    "L'e-mail di verifica è stata inviata con successo a",
  email_already_verified: "L'e-mail è già verificata",
  login_to_continue: 'Effettua il login per continuare',
  create_password_to_proceed: 'Crea una password per procedere',
  change_confirm: 'Sei sicuro di voler cambiare questa password?',
  cellphone: 'Cellulare',
  invalid_cellphone: 'Cellulare non valido',
  email_placeholder: 'youremail@example.com',
  admin: 'Amministratore',
  chat_supporter: 'Chat Supporter',
  ticket_supporter: 'Ticket Supporter',
  mms_employee: 'MMS Employee',
  verified: 'Verificato',
  not_yet_verified: 'Non ancora verificato',
  external_companies: 'Aziende esterne',
  external_company: 'Azienda esterna',
  company_name: "Nome dell'azienda",
  add_new_company: 'Aggiungi nuova azienda',
  company_created: "L'azienda è stata creata",
  company_name_is_required: "Il nome dell'azienda è richiesto",
  company_email_is_required: "L'e-mail dell'azienda è richiesta",
  please_enter_a_valid_phone_number: 'Inserire un numero di telefono valido',
  enter_a_country_code_at_the_start: "Inserire un codice paese all'inizio",
  company_creation_failed: "La creazione di un'azienda non è riuscita",
  the_email_has_already_been_taken: "L'e-mail è già stata presa",
  edit_company: 'Modifica azienda',
  company_updated: 'Azienda aggiornata con successo',
  company_udpate_failed: "Aggiornamento dell'azienda non riuscito",
  delete_company: 'Elimina azienda',
  company_deleted: "L'azienda è stata cancellata",
  company_delete_error: "L'azienda non può essere eliminata",
  company_not_found: 'Azienda non trovata',
  company_profile: "Profilo dell'azienda",
  all_companies: 'Tutte le aziende',
  company_list: 'Elenchi di aziende',
  companies: 'Aziende',
  view_company: 'Visualizza azienda',
  all_roles: 'Tutti i ruoli',
  user_login_log: 'Log di accesso utente',
  all_login_logs: 'Tutti i registri di accesso',
  access_user_log: 'Log di accesso',
  blocked: 'Bloccato',

  /*Chat System*/
  chat_system: 'Sistema di chat',
  closed_chat: 'Chat chiuse',
  all_closed_chats: 'Tutte le chat chiuse',
  chat_details: 'Dettagli chat',
  closed_chat_details: 'Dettagli chat chiuse',
  archived_chat: 'Chat archiviate',
  all_archived_chats: 'Tutte le chat archiviate',
  archived_chat_details: 'Dettagli chat archiviate',
  chat_metrics: 'Metriche chat',
  all_chat_metrics: 'Tutte le metriche della chat',
  sn: '#',
  today: 'Oggi',
  hours: 'ore',
  id: 'ID',
  customer_name: 'Nome cliente',
  employee_name: 'Nome del dipendente',
  closed_at: 'Chiuso a',
  last_message: 'Ultimo messaggio',
  last_chat_message: 'Ultimo messaggio di chat',
  actions: 'Azione',
  no_of_chat: 'Numero di chat',
  total_chats: 'Totale chat',
  no_of_active_chat: 'Chat attive',
  no_of_message: 'Numero di messaggi',
  no_messages_received: 'Numero di messaggi ricevuti',
  total_messages: 'Totale messaggi',
  no_messages_send: 'Numero di messaggi inviati',
  sent_messages: 'Messaggi inviati',
  time_spent_in_call: 'Tempo trascorso in chiamata',
  talk_time: 'Tempo di conversazione',
  role_descripition: 'Descrizione del ruolo',
  name: 'Nome',
  assignee: 'Cessionario',
  description: 'Descrizione',
  transfer_chat: 'Chat di trasferimento',
  chat_forwarding: 'Inoltro chat',
  no_active_chat_history: 'Nessuna chat attiva',
  forward_the_chat_to_a_free_employee: 'Inoltra la chat a un dipendente libero',
  forward_chat: 'Inoltra la chat',
  select_employee: 'Seleziona dipendente',
  select_user: 'Seleziona utente',
  transfer_closed_chat: 'Chiudere la chat?',
  transfer_archived_chat: 'Archivia chat',
  transfer_modal_closed_message: 'La chat sarà chiusa',
  transfer_modal_archived_message:
    'Sei sicuro di voler archiviare questa chat?',
  yes: 'Sì',
  no: 'No',
  call: 'Chiamata',
  miss_called: "L'utente ha perso la chiamata",
  active: 'Attivo',
  in_active: 'Inattivo',
  select_apartment: 'Seleziona appartamento',
  select_assignee: 'Seleziona assegnatario',
  select_customer: 'Seleziona cliente',
  select_cost_recepient: 'Selezionare il destinatario dei costi',
  select_house_owner: 'Seleziona il proprietario della casa',
  select_priority: 'Seleziona priorità',
  select_source: 'Seleziona fonte',
  write_description: 'Scrivi descrizione',
  call_ended: 'Chiamata terminata',
  message_not_sent: 'Non è stato possibile inviare il messaggio',
  join_room_error: 'Non è stato possibile unirsi alla stanza al momento',
  make_call_error: 'Impossibile effettuare una chiamata al momento',
  cancel_call_error: 'Impossibile annullare una chiamata al momento',
  chat_not_found: "Non c'è nessuna chat nel vostro feed",
  chat_not_selected: 'Nessuna chat selezionata!',
  chat_active_toggle_tooltip_message:
    'Le nuove chat possono essere assegnate o trasferite a voi se sono attive. Mentre si è impegnati in una chiamata, il toggle sarà impostato automaticamente su inattivo',
  chat_users_not_available_for_transferral:
    "Non c'è nessun altro utente disponibile in questo momento",
  call_in_progress: 'Chiamata in corso',
  click_to_cancel: 'Clicca di nuovo per annullare',
  call_cancelled: 'Chiamata annullata',
  make_call: 'Effettua una telefonata',
  chat_active_info: 'Chat Info attive',
  no_chat_assigned: "Non c'è nessuna chat assegnata al momento",
  is_typing: 'sta digitando...',

  /*Ticket Board*/
  ticket_system: 'Sistema di biglietti',
  all_tickets: 'Tutti i biglietti',
  ticket_board: 'Schede biglietti',
  add_task: 'Aggiungi attività',
  open: 'Aperto',
  inprogress: 'In corso',
  done: 'Fatto',
  closed: 'Chiuso',
  document: 'Documento',
  recursive_task: 'Attività ricorsiva',
  normal_task: 'Attività normale',
  create_ticket: 'Crea biglietto',
  ticket_details: 'Dettagli del biglietto',
  ticket_title: 'Titolo del biglietto',
  object: 'Oggetto',
  all_objects: 'Tutti gli oggetti',
  customer: 'Cliente',
  cost_recipient: 'Destinatario dei costi',
  house_owner: 'Proprietario della casa',
  priority_label: 'Etichetta di priorità',
  priority: 'Priorità',
  upload_files: 'Carica file',
  descriptions: 'Descrizioni',
  save_create_new_ticket: 'Salva e crea un nuovo biglietto',
  author: 'Autore',
  created_by: 'Creato da',
  created_at: 'Creato a',
  last_modified_by: 'Ultima modifica da',
  last_modified_at: 'Ultima modifica a',
  last_changed_by: 'Ultima modifica da',
  last_changed_at: 'Ultima modifica a',
  status: 'Stato',
  ticket_log: 'Log',
  ticket_list: 'Elenchi di biglietti',
  repeat_task: 'Attività di ripetizione',
  recursive_interval: 'Intervallo ricorsivo',
  recursive_untill: 'Ricorsivo Fino a quando',
  recursive_start: 'Inizio ricorsivo',
  apartment: 'Appartamento',
  apartments: 'Appartamenti',
  ticket_created: 'Il biglietto è stato creato',
  has_been_created: 'è stato creato',
  has_not_been_created: 'non è stato creato',
  has_been_merged: 'è stato unito',
  has_not_been_merged: 'non è stato unito',
  has_been_updated: 'è stato aggiornato',
  has_not_been_updated: 'non è stato aggiornato',
  has_been_deleted: 'è stato cancellato',
  has_not_been_deleted: 'non è stato cancellato',
  ticket_error: 'Il biglietto non è stato creato',
  expand: 'Espandi',
  ticket_list_error:
    "Si è verificato un errore nell'elaborazione della richiesta.",
  select_recursive_interval: 'Seleziona intervallo ricorsivo',
  title_required: 'Il titolo è richiesto',
  repeating_info: 'Informazioni ripetute',
  interval: 'Intervallo',
  repeat_from: 'Ripetizione da',
  repeat_until: 'Ripeti fino a quando',
  validate_recursive_date: 'Inserire una data ricorsiva valida',
  validate_recursive_until: 'Inserire una data successiva a Ripeti da',
  comment_here: 'Commenta qui...',
  comment_required: 'Il commento è richiesto',
  id_not_found: 'ID non trovato',
  edit_ticket: 'Modifica biglietto',
  ticket_updated: 'Biglietto aggiornato con successo',
  change_status: 'Cambia stato',
  status_updated: 'Stato aggiornato con successo',
  select_status: 'Seleziona stato',
  comment_error: 'Errore di commento',
  view_more: 'Visualizza di più',
  view_less: 'Visualizza di meno',
  billability: 'Fatturabilità',
  billable: 'Fatturabile',
  spent_time: 'Tempo speso',
  adjust_time_spent: 'Regola il tempo speso',
  correct_spent_time: 'Tempo speso corretto',
  spent_time_message: 'Il tempo trascorso è stato corretto manualmente',
  task: 'Attività',
  low: 'Basso',
  medium: 'Medio',
  high: 'Alto',
  urgent: 'Urgente',
  normal: 'Normale',
  repeating: 'Ripetitivo',
  select_type: 'Seleziona tipo',
  select_type_art: 'Seleziona tipo',
  ticket_detail: 'Dettaglio biglietto',
  edit_task: 'Modifica attività',
  created_ticket: 'biglietto creato',
  updated_ticket: 'biglietto aggiornato',
  changed_status: 'stato modificato',
  assigned_ticket: 'biglietto assegnato',
  commented: 'commentato',
  order_by: 'Ordine da',
  createdat: 'Creato presso',
  order: 'Ordine',
  asc: 'Ascendente',
  desc: 'Discendente',
  select_sort: 'Seleziona ordinamento',
  select_order: 'Seleziona ordine',
  ticket_name: 'Nome',
  select_external_company: 'Seleziona azienda esterna',
  showing_all_customers: 'Mostra tutti i clienti',
  showing_all_options: 'Mostra tutte le opzioni',
  appointment: 'Appuntamento',
  appointment_date_time: "Data e ora dell'appuntamento",
  enter_appointment_date_time: "Inserire la data e l'ora dell'appuntamento",
  full_screen: 'Schermo intero',

  /*CRM*/
  customer_relation_management: 'Gestione delle relazioni con i clienti',
  contact_list: 'Liste di contatti',
  all_contacts: 'Tutti i contatti',
  merge_list: 'Unisci liste',
  add_contact: 'Aggiungi contatto',
  label: 'Etichetta',
  source: 'Origine',
  destination: 'Destinazione',
  added: 'Aggiunto',
  last_activities: 'Ultime attività',
  last_ticket: 'Ultimo biglietto',
  street: 'Strada',
  house_no: 'Numero di casa',
  post_code: 'Codice postale',
  town: 'Città',
  country: 'Paese',
  phone: 'Numero di telefono',
  mobile: 'Numero di cellulare',
  company: 'Azienda',
  fellow_travelers: 'Compagni di viaggio',
  pets: 'Animali domestici',
  equipment_features: "Caratteristiche dell'equipaggiamento",
  services: 'Servizi',
  service: 'Servizio',
  notes_external: 'Note esterne',
  notes_internal: 'Note interne',
  add_now: 'Aggiungi ora',
  added_on: 'Aggiunto il',
  edit_contact: 'Modifica contatto',
  event_history: 'Cronologia degli eventi',
  chanel: 'Canale',
  date_time: 'Data/Ora',
  contact_details: 'Dettagli del contatto',
  merge: 'Unisci',
  unmerge: 'Separa',
  updated: 'Aggiornato',
  city: 'Città',
  select_channel: 'Seleziona canale',
  merge_contact: 'Unisci contatti',
  merge_contact_sidebar: 'Unisci contatti',
  select_contact: 'Seleziona contatto',
  logs: 'Logs',
  merged_date: 'Data di fusione',
  updated_date: 'Data di aggiornamento',
  uploaded_by: 'Uploaded By',
  file: 'File',
  image: 'Immagine',
  images: 'Immagini',
  progress_status: 'Stato di avanzamento',
  upload_file: 'Carica file',
  continue: 'Continua',
  view_contact: 'Visualizza contatto',
  delete_contact: 'Elimina contatto',
  merge_detail: "Informazioni di dettaglio sull'unione dei contatti",
  merge_preview: 'Anteprima unione',
  merge_contact_is_permanent:
    "L'unione dei contatti è permanente, questa azione non può essere ripristinata",
  janitor: 'Janitor',
  support: 'Supporto',
  add_new_guest: 'Aggiungi nuovo ospite',
  add_new_owner: 'Aggiungi nuovo proprietario',
  owners_are_not_allowed_to_be_merged: 'I proprietari non possono essere uniti',
  iban: 'IBAN',
  swift_bic: 'Swift/BIC',
  contact_with_bookings_cannot_be_merged:
    'Il contatto con le prenotazioni non può essere unito',
  bank_connection: 'Connessione bancaria',
  institute: 'Istituto',
  sales_tax_id_no: 'Codice fiscale delle vendite',
  create_user: 'Creare un utente',
  user_already_exists: "L'utente esiste già",
  create: 'Creare',
  this_will_create_a_user_for_the_owner_are_you_sure_you_want_to_continue:
    'In questo modo verrà creato un utente per il proprietario. Siete sicuri di voler continuare?',

  /*Contact Center*/
  total_calls: 'Numero totale di chiamate',
  aircall_user_name: 'Nome utente Aircall',
  total_incoming_call: 'Numero totale di chiamate in entrata',
  total_call_duration: 'Durata totale delle chiamate',
  total_outgoing_call: 'Numero totale di chiamate in uscita',
  outgoing_call_duration: 'Durata delle chiamate in uscita',
  average: 'Media',
  total_duration: 'Durata totale',
  internal_phone_number: 'Numero interno di telefono',

  /*Feedback*/
  feedback_management: 'Gestione dei feedback',
  feedback_system: 'Sistema di feedback',
  access_feedback: 'Gestisci feedback e posta',
  access_feedback_questions: 'Gestisci domande e impostazioni',
  access_feedback_resend: 'Accesso alla reinvio dei feedback',
  all_feedbacks: 'Tutti i feedback',
  feedback_lists: 'Liste dei feedback',
  mails: 'Posta',
  all_mails: 'Tutta la posta',
  mail_lists: 'Liste di posta',
  questions: 'Domande',
  all_questions: 'Tutte le domande',
  question_lists: 'Liste di domande',
  date: 'Data',
  closed_ended: 'Chiuso',
  open_ended: 'Aperto',
  rating: 'Valutazione',
  time_period: 'Periodo di tempo',
  feedback_detail: 'Dettagli del feedback',
  mark_as_done: 'Segna come fatto',
  publish_feedback: 'Pubblica il feedback',
  publish_feedback_on_object_page:
    "Pubblica il feedback sulla pagina dell'oggetto",
  do_not_publish_feedback: 'Non pubblicare il feedback',
  do_not_publish_feedback_on_object_page:
    "Non pubblicare il feedback sulla pagina dell'oggetto",
  use_for_score_calculation: 'Utilizzare per il calcolo della valutazione',
  do_not_use_for_score_calculation:
    'Non utilizzare per il calcolo della valutazione',
  contact_customer: 'Contatta il cliente',
  contact_house_owner: 'Contatta il proprietario della casa',
  send_mail: 'Reinvia la posta',
  new_question: 'Nuova domanda',
  add_new_question: 'Aggiungi nuova domanda',
  edit_question: 'Modifica domanda',
  add_question: 'Aggiungi domanda',
  delete_question: 'Cancella domanda',
  edit_feedback: 'Dettaglio feedback',
  view_feedback: 'Visualizza feedback',
  feedback_question: 'Domanda di feedback',
  feedback_question_deleted_successfully:
    'Domanda di feedback cancellata con successo',
  sent: 'Inviato',
  dispatched: 'Inviato',
  received: 'Ricevuto',
  feedback_mail_sent_sucessfully: 'Posta di feedback inviata con successo',
  feedback_mail_not_sent: 'Messaggio di feedback non inviato',
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    'Quanti giorni dopo la partenza dovrebbe essere inviata la mail di feedback?',
  select_days: 'Seleziona i giorni',
  days: 'Giorni',
  feedback_received: 'Feedback Ricevuto',
  question: 'Domanda',
  answers: 'Risposte',
  response: 'Risposta',
  the_rating_is_out_of_10: 'La valutazione è su 10',
  absolutely: 'Assolutamente',
  not_at_all: 'Per niente',
  this_is_closed_ended_question: 'Questa è una domanda a risposta chiusa',
  this_is_open_ended_question: 'Questa è una domanda a risposta aperta',
  feedback_has_been_published_successfully:
    'Il feedback è stato pubblicato con successo',
  feedback_publish_error: 'Errore di pubblicazione del feedback',
  published_feedback_on_object_page_successfully:
    "Feedback pubblicato con successo sulla pagina dell'oggetto",
  publish_feedback_on_object_page_error:
    "Errore di pubblicazione del feedback sulla pagina dell'oggetto",
  feedback_has_been_unpublished_successfully:
    'Il feedback è stato annullato con successo',
  feedback_unpublish_error: 'Errore di annullamento del feedback',
  feedback_has_been_marked_as_done_successfully:
    'Il feedback è stato contrassegnato come completato con successo',
  feedback_mark_as_done_error:
    'Errore di contrassegno del feedback come completato',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'Come valuterebbe la tua vacanza a List su Sylt e il servizio fornito dalla famiglia Clausen nel complesso?',
  how_satisfied_were_you_with_your_stay_in_our_appartment:
    'Quanto sei stato soddisfatto del tuo soggiorno nel nostro appartamento?',
  publish_status: 'Stato di pubblicazione',
  published_on_website: 'Pubblicato sul sito web',
  published_on_object_detail_page:
    "Pubblicato sulla pagina dei dettagli dell'oggetto",
  score_calculation: 'Calcolo del punteggio',
  feedback_on_object_page_has_been_unpublished_successfully:
    "Il feedback sulla pagina dell'oggetto è stato pubblicato con successo",
  feedback_on_object_page_unpublish_error:
    "Errore di pubblicazione della pagina di feedback sull'oggetto",
  score_calculation_used: 'Calcolo del punteggio utilizzato',
  score_calculation_use_error: "Errore nell'uso del calcolo del punteggio",
  score_calculation_unused: 'Calcolo del punteggio non utilizzato',
  score_calculation_unuse_error:
    'Errore di calcolo del punteggio non utilizzato',
  object_publish: 'Oggetto pubblicato',
  website_publish: 'Sito web pubblicato',
  not_publish: 'Non pubblicato',
  score_total: 'Punteggio totale',
  answer_rate: 'Tasso di risposta',
  public_score_calculation: 'Calcolo del punteggio pubblico',
  real_score: 'Punteggio reale',
  public_score: 'Punteggio pubblico',
  detail_reviews_general: 'Dettaglio-recensioni generali',
  detail_review_object_page: 'Dettaglio-Pagina oggetto recensione',
  reviews_general: 'Recensioni generali',
  reviews_object_page: 'Pagina oggetto recensioni',
  score_general: 'Punteggio generale',
  score_objects: 'Oggetti Punteggio',
  no_mail: 'Nessuna e-mail',
  no_email: 'Nessuna e-mail',
  analysis: 'Analisi',
  average_rating: 'Valutazione media.',
  percentage_of_closed_ended: 'Percentuale di Sì/No.',
  percentage_of_open_ended:
    'Percentuale di feedback scritti dai clienti/non scritti.',
  feedback_analysis: 'Analisi del feedback',
  delete_mail: 'Elimina email',
  feedback_mail_deleted_successfully:
    'Email di Feedback eliminata con successo',
  feedback_analysis_detail: 'Dettagli analisi del feedback',
  text_search: 'Ricerca testuale',
  feedback_response_detail: 'Dettagli risposta al feedback',
  resend_feedback: 'Invia di nuovo il Feedback',
  resent: 'Inviata nuovamente',
  feedback_deleted_successfully: 'Feedback eliminato con successo',
  delete_feedback: 'Elimina feedback',

  /*Inventory*/
  inventory: 'Inventario',
  inventories: 'Inventari',
  all_inventory: 'Tutti gli inventari',
  inventory_name: 'Nome inventario',
  used_item: 'Oggetto usato',
  quantity: 'Quantità',
  quantity_anzahl: 'Quantità',
  total_quantity: 'Quantità totale',
  from: 'Da',
  to: 'A',
  add_item: 'Aggiungi articolo',
  deselect_all: 'Deseleziona tutto',

  /*MMS*/
  merchandise_management_system: 'Sistema di gestione della merce',
  transfer_item: 'Trasferisci articoli',
  all_transfer_item: 'Tutti gli elementi di trasferimento',
  all_transfer_logs: 'Tutti i registri di trasferimento',
  select_articles: 'Seleziona articoli',
  search_article: 'Cerca articolo',
  stock_type: 'Tipo di stock',
  units: 'Unità',
  unit: 'Unità',
  all_units: 'Tutte le unità',
  unit_details: "Dettagli dell'unità",
  select_source_warehouse: 'Seleziona magazzino di origine',
  rooms: 'Stanze',
  room: 'Stanza',
  delete_room: 'Elimina stanza',
  add_room: 'Aggiungi stanza',
  edit_room: 'Modifica stanza',
  room_name: 'Nome della stanza',
  enter_room_name: 'Inserisci il nome della stanza',
  racks: 'Rastrelliere',
  add_rack: 'Aggiungi rastrelliera',
  rack: 'Rastrelliera',
  delete_rack: 'Elimina rastrelliera',
  shelf: 'Scaffale',
  add_shelf: 'Aggiungi scaffale',
  delete_shelf: 'Elimina scaffale',
  shelves: 'Mensole',
  select_destination_warehouse: 'Seleziona magazzino di destinazione',
  warehouse: 'Magazzino',
  warehouse_name: 'Nome del magazzino',
  warehouses: 'Magazzini',
  all_warehouses: 'Tutti i magazzini',
  add_warehouse: 'Aggiungi magazzino',
  edit_warehouse: 'Modifica magazzino',
  delete_warehouse: 'Elimina magazzino',
  warehouse_details: 'Dettagli del magazzino',
  warehouse_information: 'Informazioni sul magazzino',
  warehouse_not_found: 'Magazzino non trovato',
  shelf_delete_error:
    "Si è verificato un errore nell'eliminazione dello scaffale",
  rack_delete_error:
    "Si è verificato un errore nell'eliminazione dello scaffale",
  room_delete_error: "Si è verificato un errore nell'eliminazione della stanza",
  articles: 'Articoli',
  article: 'Articolo',
  all_articles: 'Tutti gli articoli',
  add_article: 'Aggiungi articolo',
  articles_names: 'Nomi articoli',
  article_name: 'Nome articolo',
  upload_article_picture: 'Carica immagine articolo',
  article_image: 'Immagine articolo',
  type: 'Tipo',
  types: 'Tipi',
  all_types: 'Tutti i tipi',
  type_details: 'Dettagli del tipo',
  buying_price: 'Prezzo di acquisto',
  selling_price: 'Prezzo di vendita',
  seller: 'Venditore',
  seller_home_page_shop: 'Pagina principale del venditore/Negozio',
  minimum_stock: 'Stock minimo',
  maximum_stock: 'Stock massimo',
  out_of_stock: 'Esaurito',
  minimum_stock_notification: 'Notifica di Stock Minimo',
  ean_number: 'Numero EAN',
  notes: 'Note',
  article_details: 'Dettagli articolo',
  objects: 'Oggetti',
  object_id: 'ID Oggetto',
  object_name: 'Nome Oggetto',
  booking: 'Prenotazione',
  tickets: 'Biglietti',
  inventory_load: 'Carico di inventario',
  object_details: 'Dettagli oggetto',
  ticket_open_inprogress_done: 'Biglietto (Aperto / In corso / Fatto)',
  abbreviation: 'Abbreviazione',
  street_number: 'Numero di strada',
  is_active: 'È attivo',
  type_name: 'Nome tipo',
  add_type: 'Aggiungi tipo',
  unit_name: 'Nome unità',
  add_unit: 'Aggiungi unità',
  edit_unit: 'Modifica unità',
  transfer_log: 'Registro di trasferimento',
  transfer_logs: 'Registri di trasferimento',
  my_transfer_log: 'Il mio registro',
  my_transfer_logs: 'I miei registri',
  all_my_logs: 'Tutti i registri',
  ticket_source: 'Fonte biglietto',
  done_by: 'Fatto da',
  required: 'Richiesto',
  user_created: "L'utente è stato creato",
  user_creation_failed: 'Creazione utente non riuscita.',
  user_udpate_failed: 'Aggiornamento utente non riuscito.',
  password_update_failed: 'Aggiornamento password fallito',
  user_not_found: 'Utente non trovato',
  update: 'Aggiornamento',
  user_updated: 'Utente aggiornato con successo',
  user_deleted: "L'utente è stato cancellato",
  roles_not_found: 'Ruoli non trovati',
  confirm_delete: 'Sei sicuro di volerlo cancellare?',
  edit_type: 'Tipo di modifica',
  select: 'Seleziona',
  min_stock_notification_tooltip:
    'Verrai avvisato quando il numero di scorte minime sarà raggiunto',
  valid_data: 'Inserire un dato valido',
  valid_only_number: 'Si prega di inserire solo numeri',
  min_stock_vaidation_msg:
    'Il valore deve essere inferiore alla scorta massima',
  max_stock_vaidation_msg: 'Il valore deve essere maggiore della scorta minima',
  phone_call: 'Telefonata',
  collapse_sidebar: 'Riduci la barra laterale',
  expand_sidebar: 'Espandi la barra laterale',
  article_type: 'Seleziona il tipo',
  article_unit: "Seleziona l'unità",
  max_upload_size_5: 'Dimensione massima degli allegati di upload fino a 5 MB.',
  max_upload_size_10:
    'Dimensione massima degli allegati di upload fino a 10 MB.',
  max_upload_size_15:
    'Dimensione massima degli allegati di upload fino a 15 MB.',
  max_characters_exceeded: 'Limite massimo di caratteri superato',
  selected_files: 'File selezionati',
  drag_drop_files_msg:
    'Trascina & Rilascia alcuni file qui o fai clic per selezionare i file. Carica file fino a 5 MB.',
  files: 'File',
  delete_file: 'Elimina file',
  only_1_file_is_allowed: 'È consentito solo 1 file.',
  access_user: 'Accesso utente',
  manage_role: 'Gestisci ruolo',
  manage_user: 'Gestisci utente',
  access_external_companies: 'Accesso alle aziende esterne',
  manage_external_companies: 'Gestisci aziende esterne',
  access_chat: 'Accesso al chat',
  access_closed_chat: 'Accesso al chat chiuso',
  access_archived_chat: 'Accesso al chat archiviato',
  access_chat_metrics: 'Accesso alle metriche del chat',
  access_chat_assign: "Accesso all'assegnazione del chat",
  access_chat_mediasoup: 'Accesso al chat Mediasoup',
  access_ticket: 'Accesso al ticket',
  access_mms: 'Accesso a MMS',
  can_hold_item: "Può tenere l'oggetto",
  manage_article: 'Gestisci articolo',
  stocktaking_mode: 'Modalità di inventario',
  stocktaking: 'Inventario',
  upload_new: 'Carica nuovo',
  remove: 'Rimuovi',
  uploading: 'Caricamento',
  invalid_profile_upload: 'Caricamento del profilo non valido',
  file_must_be_an_image_file: 'Il file deve essere di tipo immagine.',
  clear_profile_pic: 'Cancella immagine del profilo',
  are_you_sure:
    'Sei sicuro? Non potrai annullare questa azione successivamente.',
  upload_label: 'Carica profilo',
  remove_label: 'Rimuovi profilo',
  invalid_file_size: 'Dimensione del file non valida',
  file_size_info: 'La dimensione del file deve essere inferiore a 5 MB',
  uploading_profile: 'Caricamento del profilo',
  no_permission_msg:
    "Non hai il permesso di accedervi. Si prega di contattare l'amministratore.",
  transfer_date: 'Data di trasferimento',
  transfer_source: 'Sorgente di trasferimento',
  transfer_by: 'Trasferito da',
  transfer_quantity: 'Quantità di trasferimento',
  category: 'Categoria',
  select_category: 'Seleziona categoria',
  house_number: 'Numero di casa',
  object_image: "Immagine dell'oggetto",
  house: 'Casa',
  flat: 'Appartamento',
  new: 'Nuovo',
  fresh: 'Fresco',
  consumed: 'Consumato',
  employee: 'Impiegato',
  transfer_item_success: 'Trasferimento articolo riuscito.',
  transfer_item_failed: 'Trasferimento articolo non riuscito.',
  none: 'Nessuno',
  reconciled: 'Riconciliato',
  web_ui: 'Interfaccia web',
  app: 'Applicazione',
  select_article_and_quantity_first:
    "Si prega di selezionare prima l'articolo e la quantità.",
  min_stock_reached: 'Raggiunto il minimo di magazzino',
  min_stock_reached_description:
    'Questo articolo ha raggiunto il minimo di magazzino impostato su ',
  max_stock_reached: 'Raggiunto il massimo di magazzino',
  max_stock_reached_description:
    'Questo articolo ha raggiunto il massimo di magazzino impostato su ',
  stocktaking_mode_message: ' Entra davvero in modalità di inventario.',
  my_inventory: 'I miei inventari',
  all_my_inventories: 'Tutti gli inventari',
  transfer: 'Trasferimento',
  max_min_stock: 'Massimo-/Minimo-Stock',
  item: 'Articolo',
  inventory_detail: "Dettagli dell'inventario",

  // common
  lang: 'it',
  english: 'Inglese',
  german: 'Tedesco',
  polish: 'Polacco',
  french: 'Francese',
  italian: 'Italiano',
  result: 'Risultato',
  no_results_found: 'Nessun risultato trovato.',
  no_records_found: 'Nessun record trovato',
  error: 'Errore',
  no_options: 'Nessuna opzione',
  showing: 'Mostrando',
  manually: 'Manualmente',
  general: 'Generale',
  gotoDetails: 'Vai ai dettagli',
  out_of: 'fuori da',
  loading: 'Caricamento...',
  show_rows: 'Mostra righe',
  error_boundary_heading_text: 'Siamo spiacenti, qualcosa è andato storto.',
  error_boundary_paragraph_text:
    'Il nostro team è stato informato e lo risolveremo il prima possibile.',
  reload_page: 'Ricarica pagina',
  take_me_home: 'Portami a casa',
  manual: 'Manuale',
  ['v-office']: 'V-Office',
  guest: 'Ospite',
  owner: 'Proprietario',
  lead: 'Condurre',
  supplier: 'Fornitore',
  test: 'Prova',
  other: 'Altro',
  daily: 'Giornaliero',
  weekly: 'Settimanale',
  bi_weekly: 'Ogni due settimane',
  monthly: 'Mensile',
  semi_anually: 'Semestrale',
  anually: 'Annualmente',
  previous: 'Precedente',
  next: 'Successivo',
  date_validation_msg: 'Si prega di inserire una data valida',
  freeze_inventory: "Congela l'inventario",
  create_inventory_list: 'Crea elenchi di inventario',
  correct_inventory: 'Inventario corretto',
  create_delta_list: 'Crea elenchi delta',
  unfreeze_inventory_end:
    "Sblocca l'inventario e termina la contabilità delle scorte",
  download_pdf: 'Scarica PDF',
  download_excel: 'Scarica Excel',
  download__delta_list_pdf: 'Scarica la lista delta PDF',
  unfreeze_inventory: "Sblocca l'inventario",
  no_transferring: '(Nessuna trasmissione degli elementi possibile)',
  download_as: '(Scarica come PDF e salva, quindi stampa)',
  transfer_items:
    '(Trasferisci elementi dalla o alla correzione della contabilità delle scorte)',
  what_change:
    '(Quali sono stati i cambiamenti effettuati durante la contabilità delle scorte)',
  step_1: 'Passo 1',
  step_2: 'Passo 2',
  step_3: 'Passo 3',
  step_4: 'Passo 4',
  step_5: 'Passo 5',
  actual_quantity: 'Quantità Effettiva',
  delta: 'Delta',
  no_data_found: 'Nessun dato trovato',
  ms: 'Sig.ra',
  Ms: 'Sig.ra',
  diverse: 'Diverse',
  Diverse: 'Diverse',

  /*MDM*/
  master_data_magnagement: 'Gestione Dati Principali',
  mdm: 'MDM',
  contacts: 'Contatti',
  contact: 'Contatto',
  upload_csv: 'Carica CSV',
  business: 'Azienda',
  language: 'Lingua',
  letter_salutation: 'Saluto della Lettera',
  letter_closure: 'Chiusura della Lettera',
  salutation: 'Saluto',
  birthdate: 'Data di nascita',
  phone_number: 'Numero di Telefono',
  business_phone_number: 'Numero di Telefono Aziendale',
  mobile_number: 'Numero di cellulare',
  fax: 'Fax',
  im: '-IM-',
  website: 'sito web',
  both: 'entrambi',
  self: 'sé',

  region: 'Regione',
  plant: 'Impianto',
  categories: 'Categorie',
  online: 'Online',
  deactivated: 'Disattivato',
  sleeping_facilities: 'Strutture per il Sonno',
  longitude: 'Longitudine',
  latitude: 'Latitudine',
  bedrooms: 'Camere da letto',

  bookings: 'Prenotazioni',
  booking_date: 'Data di Prenotazione',
  invoices: 'Fatture',
  invoice: 'Fattura',
  number: 'Numero',
  until: 'Fino a',
  received_at: 'Ricevuto Al',
  changed_at: 'Modificato Al',
  total: 'Totale',
  grand_total: 'Totale Finale',
  marketing_channel: 'Canale di Marketing',
  down_payment: 'Acconto',
  down_payment_until: 'Acconto Fino a',
  final_payment_until: 'Pagamento Finale Fino a',
  ex_price: 'Prezzo escluso',
  total_occupancy: 'Occupazione Totale',
  adult: 'Adulto',
  children: 'Bambini',
  babies: 'Bambini neonati',
  age_children: 'Età dei Bambini',
  country_customer: 'Paese del Cliente',
  town_code_customer: 'Codice della Città del Cliente',
  country_objectives: 'Obiettivi del Paese',
  rent_statement_exit: 'Rendiconto di Affitto di Uscita',
  invoice_date: 'Data fattura',
  net: 'Netto',
  due_date: 'Data di scadenza',
  cancelled_on: 'Annullato il',
  calculation: 'Calcolo',
  select_calculation: 'Seleziona calcolo',
  provider: 'Fornitore',
  select_provider: 'Seleziona fornitore',
  invoice_recepient: 'Destinatario fattura',
  select_invoice_recepient: 'Seleziona destinatario fattura',
  warehouse_holds_inventories: 'Magazzino che detiene inventari',
  uploads: 'Caricamenti',
  all_uploads: 'Tutti i caricamenti',
  all_bookings: 'Tutte le prenotazioni',
  all_invoices: 'Tutte le fatture',
  all_services: 'Tutti i servizi',
  all_logs: 'Tutti i log',
  drag_drop_csv_files_msg:
    'Trascina e rilascia solo file .csv qui, o fai clic per selezionare i file .csv. Carica file fino a 10 MB.',
  drag_drop_csv_15MB_files_msg:
    'Trascina e rilascia solo file .csv qui, o fai clic per selezionare i file .csv. Carica file fino a 15 MB.',
  processing_data: 'Elaborazione dati',
  failed: 'Non riuscito',
  access_mdm: 'Accesso MDM',
  mdm_supporter: 'Supporto MDM',
  file_uploaded_successfully: 'File caricato correttamente.',
  uploaded_on: 'Ultimo caricato il',
  by: 'da',
  delete_article_unit_error:
    'Impossibile eliminare il tipo di unità di articolo. Contiene molte articoli.',
  delete_type_error:
    'Impossibile eliminare la categoria di articoli. Contiene molte articoli.',
  transferred: 'trasferito',
  internal_server_error: 'Errore interno del server',
  cannot_upload_csv_in_worng_category:
    'Impossibile caricare il file CSV in categoria errata',
  upload_date: 'Data di caricamento',
  crm_employee: 'Impiegato CRM',
  access_crm: 'Accesso a CRM',
  merge_contacts: 'Unisci contatti',
  create_owner_crm: 'Creare un nuovo proprietario',
  notes_cannot_be_more_than_2000_characters_long:
    'Le note non possono essere più lunghe di 2000 caratteri',

  access_oms: 'Oggetti di accesso e stagioni',
  manage_oms: 'Gestire oggetti e stagioni',
  access_bms: 'Accesso a BMS',
  access_bread_service: 'Accesso a Bread Service',
  access_sms: 'Gestire gli SMS',
  manage_sms: 'Manage SMS',
  location: 'Posizione',
  letter_completion: 'Completamento lettera',
  homepage: 'Homepage',
  imports: 'Importazioni',
  all_imports: 'Tutte le importazioni',
  import_type: 'Tipo di importazione',
  last_id: 'Ultimo ID',
  total_records_imported: 'Totale record importati',
  imported_at: 'Importato il',
  availabilities: 'Disponibilità',

  /* OMS */
  object_management_system: 'Sistema di gestione degli oggetti',
  oms: 'OMS',
  object_list: 'Liste degli oggetti',
  season: 'Stagione',
  season_list: 'Liste di stagione',
  season_name: 'Nome della stagione',
  edit_season: 'Modifica stagione',
  delete_season: 'Elimina stagione',
  add_season: 'Aggiungi nuova stagione',
  from_date: 'Data di inizio',
  to_date: 'Data di fine',
  add_new_apartment: 'Aggiungi nuovo appartamento',
  status_changed: 'Stato modificato',
  payement_active: 'Pagamento attivo',
  website_active: 'Sito web attivo',
  video_active: 'Video attivo',
  seven_for_six: '7 per 6',
  this_discount_will_be_applied_for_season_c:
    'Questo sconto verrà applicato solo durante la stagione C',
  voffice_data_sync: 'Sincronizzazione dei dati VOffice',
  features: 'Funzionalità',
  picture: 'Immagine',
  text: 'Testo',
  cannot_get_apartment_details:
    "Impossibile ottenere i dettagli dell'appartamento",
  must_be_greater_than_zero: 'Deve essere maggiore di 0',
  basic_information: 'Informazioni di base',
  copy: 'Copia',
  ical_link: 'Collegamento iCal',
  copied: 'Copiato',
  copy_to_clipboard_ctrl_c_enter: 'Copia negli appunti: Ctrl+C, Enter',

  /**SMS Service */
  service_management_system: 'Sistema di gestione del servizio',
  sms: 'SMS',
  add_service: 'Aggiungi servizio',
  scheme_type: 'Tipo di schema',
  select_scheme_type: 'Seleziona tipo di schema',
  service_created: 'Il servizio è stato creato',
  service_creation_failed: 'Creazione del servizio non riuscita.',
  service_udpate_failed: 'Aggiornamento del servizio non riuscito.',
  add_new_service: 'Aggiungi nuovo servizio',
  edit_service: 'Modifica servizio',
  service_updated: 'Servizio aggiornato con successo',
  service_not_found: 'Servizio non trovato',
  service_details: 'Dettagli del servizio',
  product_code: 'Codice prodotto',
  v_office_service: 'Servizio VOffice',
  price_scheme_type: 'Tipo di schema di prezzo',
  by_quantity: 'Per quantità',
  by_duration: 'Per durata',
  by_day: 'Per giorno',
  newspaper: 'Giornale',
  one_time_price: 'Prezzo una tantum',
  beach_chair: 'Sedia da spiaggia',
  unit_price: 'Prezzo unitario',
  price_a: 'Prezzo A',
  price_b: 'Prezzo B',
  view_service: 'Visualizza servizio',
  delete_service: 'Elimina servizio',
  service_deleted: 'Il servizio è stato eliminato.',
  day: 'Giorno',
  price: 'Prezzo',
  duration: 'Durata',
  priceschemebyday: 'Per giorno',
  priceschemeonetime: 'Una tantum',
  priceschemebyquantity: 'Per quantità',
  priceschemebyduration: 'Per durata',
  priceschemebeachchair: 'Sedia da spiaggia',
  per_week: 'Per settimana',
  sun: 'SUN',
  mon_fri: 'LUN-VEN',
  additional_day: 'Giorno aggiuntivo',
  annual_price: 'Prezzo annuale',
  duplicate_price_scheme: 'Duplicate Price Scheme',

  /**SMS Service Group */
  service_group: 'Gruppo di servizi',
  service_groups: 'Gruppi di servizi',
  service_management: 'Gestione dei servizi',
  select_service_group: 'Seleziona il gruppo di servizi',
  all_service_groups: 'Tutti i gruppi di servizi',
  service_group_list: 'Elenco dei gruppi di servizi',
  add_service_group: 'Aggiungi gruppo di servizi',
  booking_type: 'Tipo di prenotazione',
  booking_fee: 'Tariffa di prenotazione',
  booking_fee_calculation: 'Calcolo della tariffa di prenotazione',
  service_group_created: 'Il gruppo di servizi è stato creato',
  service_group_creation_failed:
    'Creazione del gruppo di servizi non riuscita.',
  service_group_udpate_failed:
    'Aggiornamento del gruppo di servizi non riuscito.',
  add_new_service_group: 'Aggiungi nuovo gruppo di servizi',
  service_name: 'Nome del servizio',
  frontend_color: 'Colore Frontend',
  price_per_night: 'Prezzo a notte',
  show_on_landing_page: 'Mostra sulla pagina di destinazione',
  default_service: 'Servizio predefinito',
  early_deliverable: 'Consegna anticipata',
  early_deliverable_on_start: "Consegna anticipata all'avvio",
  pay_to_vendor: 'Paga al fornitore',
  dog_service: 'Servizio per cani',
  icon: 'Icona',
  cover_image: 'Immagine di copertina',
  drag_icon_or_click_to_select_icon:
    "Trascina l'icona o fai clic per selezionare l'icona",
  drag_image_or_click_to_select_image:
    "Trascina l'immagine o fai clic per selezionare l'immagine",
  edit_service_group: 'Modifica gruppo di servizi',
  service_group_updated: 'Gruppo di servizi aggiornato con successo',
  service_group_not_found: 'Gruppo di servizi non trovato',
  service_group_details: 'Dettagli del gruppo di servizi',
  one_time: 'Una sola volta',
  per_day: 'Per giorno',
  per_night: 'Per notte',
  view_service_group: 'Visualizza gruppo di servizi',
  delete_service_group: 'Elimina gruppo di servizi',
  service_group_deleted: 'Il gruppo di servizi è stato cancellato',
  accept_only_png: 'Accetta solo file *.png',
  enter_booking_fee_in_cents:
    'Inserire la tariffa di prenotazione in centesimi',
  enter_price_per_night_in_cents: 'Inserire il prezzo in centesimi',
  enter_value_in_cents: 'Inserisci valore in centesimi',
  enter_value_in_euro: 'Inserire il valore in euro',
  light: 'Luce',
  dark: 'Scuro',
  price_calculation: 'Calcolo del prezzo',

  /**DMS Service */
  document_management_system: 'Sistema di gestione dei documenti',
  dms: 'DMS',
  general_documents: 'Documenti generali',
  document_objects: 'Apparato documentale',
  document_customers: 'Clienti del documento',
  document_owners: 'Proprietari del documento',
  document_users: 'Utenti del documento',
  all_documents: 'Tutti i documenti generali',
  all_customers: 'Tutti i documenti dei clienti',
  all_owners: 'Tutti i documenti del proprietario',
  all_object_documents: 'Tutti i documenti degli oggetti',
  all_user_documents: 'Tutti i documenti degli utenti',
  upload_document: 'Carica documento',
  document_name: 'Nome del documento',
  owner_name: 'Nome del proprietario',
  availability: 'Disponibilità',
  edit_general_document: 'Modifica documento',
  edit_document_object: 'Modifica caricamento documento su oggetto',
  edit_document_owner: 'Modifica caricamento documento su proprietario',
  edit_document_customer: 'Modifica il caricamento del documento sul cliente',
  upload_general_document: 'Carica documento generale',
  upload_document_object: 'Carica documento su oggetto',
  upload_document_owner: 'Carica il documento sul proprietario',
  upload_document_customer: 'Carica documento su cliente',
  to_objects: 'agli oggetti',
  no_of_documents: 'Numero di documenti',
  select_object: 'Seleziona oggetto',
  general_document_deleted: 'Il documento è stato cancellato',
  delete_general_document: 'Elimina documento',
  general_document_not_found: 'Documento non trovato',
  general_document_udpate_failed: 'Aggiornamento del documento non riuscito',
  general_document_created: 'Il documento è stato creato',
  general_document_creation_failed: 'Creazione del documento fallita',
  general_document_updated: 'Documento aggiornato con successo',
  customer_documents: 'Documenti del cliente',
  owner_documents: 'Documenti del proprietario',
  object_documents: 'Documenti oggetto',
  users_document: 'Documento utente',
  upload_user_document: 'Carica documento utente',
  edit_user_document: 'Modifica documento utente',
  import_file: 'File di importazione',
  the_file_field_is_required: 'Il campo file è obbligatorio',

  /**BMS Service */
  booking_management_system: 'Sistema di gestione delle prenotazioni',
  booking_management: 'Gestione delle prenotazioni',
  booking_overview: 'Panoramica delle prenotazioni',
  booking_status: 'Stato della prenotazione',
  payment_status: 'Stato del pagamento',
  distribution_channel: 'Canale di distribuzione',
  create_new_booking: 'Crea una nuova prenotazione',
  apartment_name: "Nome dell'appartamento",
  total_amount: 'Importo totale (CHF)',
  amount_open: 'Importo aperto (CHF)',
  bms: 'BMS',
  customer_information: 'Informazioni sul cliente',
  booking_creation_failed: 'La creazione della prenotazione non è riuscita',
  salutation_is_required: 'Il saluto è richiesto',
  select_salutation: 'Seleziona il saluto',
  title: 'Titolo',
  street_name_is_required: 'Il nome della strada è richiesto',
  city_is_required: 'La città è richiesta',
  select_country: 'Seleziona il paese',
  country_is_required: 'Il paese è richiesto',
  invalid_phone_number: 'Numero di telefono non valido',
  appartment_information: "Informazioni sull'appartamento",
  appartment_is_required: "L'appartamento è richiesto",
  date_is_required: 'La data è richiesta',
  select_quantity: 'Seleziona la quantità',
  baby: 'Bambino',
  select_distribution_channel: 'Seleziona il canale di distribuzione',
  arrival_status: 'Stato di arrivo',
  select_arrival_status: 'Seleziona lo stato di arrivo',
  arrival_status_is_required: 'Lo stato di arrivo è richiesto',
  reserved_at: 'Riservato a',
  reserved_until: 'Riservato fino a',
  total_amount_is_required: "L'importo totale è richiesto",
  paid_amount_is_required: "È richiesto l'importo pagato",
  residual_amount: 'Importo residuo',
  deposit_amount: 'Importo del deposito',
  residual_amount_is_required: "L'importo residuo è richiesto",
  deposit_amount_is_required: "L'importo del deposito è richiesto",
  notes_notizen: 'Note',
  further_remarks_notes: 'Ulteriori osservazioni / note',
  house_number_is_required: 'Il numero civico è richiesto',
  town_is_required: 'Il Città è richiesto',
  post_code_is_required: 'Il codice postale è richiesto',
  phone_number_is_required: 'Il numero di telefono è richiesto',
  add_booking: 'Aggiungi prenotazione',
  mandatory_fields: 'Campi obbligatori',
  above_18_years_old: 'Maggiorenni',
  under_18_years_old: 'Minorenni',
  under_3_years_old: 'Sotto i 3 anni',
  booking_created: 'La prenotazione è stata creata',
  subtotal: 'Sub Totale',
  booking_calendar: 'Calendario delle prenotazioni',
  could_not_fetch_bookings: 'Impossibile recuperare le prenotazioni',
  booking_date_conflict_error:
    "La data di prenotazione sovrappone un'altra prenotazione",
  booking_cannot_be_in_the_past: 'La prenotazione non può essere nel passato',
  appartment_overview: "Panoramica dell'appartamento",
  reserved: 'Prenotato',
  vat19: '19% IVA sul servizio di consegna',
  vat7: '7% IVA',
  payment_method: 'Metodo di pagamento',
  bank_transfer: 'Bonifico bancario',
  paid_amount: 'Importo pagato',
  without_tax: 'Senza tasse',
  including: 'Compreso',
  callback: 'Richiamata',
  callbacks: 'Richiamate',
  all_callbacks: 'Tutte le richiamate',
  callback_date: 'Data della richiamata',
  callback_detail: 'Dettaglio della richiamata',
  callback_deleted_successfully: 'Richiamata cancellata con successo',
  no_callback_details_found: 'Nessun dettaglio di richiamata trovato',
  inquiry: 'Inchiesta',
  mr: 'Sig.',
  Mr: 'Sig.',
  mrs: 'Sig.ra',
  misc: 'Varie',
  piece: 'pezzo',
  please_verify_your_service_bookings:
    'Verifica le tue prenotazioni di servizi',
  booking_preview: 'Anteprima della prenotazione',
  selected_dates_not_available: 'Le date selezionate non sono disponibili',
  not_paid: 'Non pagato',
  partial_paid: 'Parzialmente pagato',
  fully_paid: 'Completamente pagato',
  cannot_calculate_booking_price_for_the_given_date_range:
    "Impossibile calcolare il prezzo di prenotazione per l'intervallo di date indicato",
  cannot_make_bookings_in_the_given_date_range:
    "Impossibile effettuare prenotazioni nell'intervallo di date indicato",
  booked_from: 'Prenotato da',
  booked_until: 'Prenotato fino a',

  /**BS Service */
  bread_system: 'Sistema pane',
  bs: 'BS',
  all_billings: 'Tutte le fatture',
  all_daily_view: 'All Daily Views',
  all_driving_order: 'Tutti gli ordini di guida',

  /**PMS Service */
  payment_management_system: 'Sistema di gestione dei pagamenti',
  pms: 'PMS',
  credit_card: 'Pagamento con carte di credito',
  all_credit_cards: 'Pagamento con carte di credito',
  paypal: 'Pagamento con Paypal',
  all_paypals: 'Pagamento Paypal',
  credit_card_no: 'Numero di carta di credito',
  amount: 'Importo',
  paypal_address: 'Indirizzo Paypal',
  access_pms: 'Access PMS',
  payment_links: 'Link di pagamento',
  send_on: 'Invia il',
  last_sent_on: 'Ultimo invio',
  paid_on: 'Pagato il',
  all_payment_links: 'Tutti i link di pagamento',
  create_payment_links: 'Crea il link di pagamento',
  send_payment_link: 'Invia il link di pagamento',
  payment_link_sending_failed: 'Invio del link di pagamento non riuscito.',
  payment_link_send_successfully:
    'Invio del link di pagamento avvenuto con successo',
  identifier: 'Identificatore',
  amount_is_required: "L'importo è obbligatorio",
  required_booking_number: 'Il numero di prenotazione è obbligatorio.',
  required_customer_name: 'Il nome del cliente è obbligatorio.',
  paid: 'Pagato',
  resent_mail_success: 'Email rispedita con successo',
  resent_mail_error: 'Rispedizione email fallita',
  confirm_resend_email: "Sei sicuro di voler rispedire l'email?",
  service_reservation: 'Prenotazione di servizi',
  apartment_reservation: 'Prenotazione di oggetti',
  ferry_booking: 'Prenotazione di traghetti',
  payment_link: 'Link di pagamento',

  /**PNS Service */
  push_notification_system: 'Sistema di notifica push',
  all_notifications: 'Tutte le notifiche',
  pns: 'PNS',
  notifications: 'Notifiche',
  push_notification: 'Notifica push',
  add_new_push_notification: 'Nuova notifica push',
  link: 'Link',
  total_read: 'Totale/Lettura',
  add_push_notification: 'Aggiungi notifica push',
  notification_created: 'La notifica push è stata creata',
  notification_creation_failed:
    'La creazione di una notifica push non è riuscita',
  send: 'Invia',
  access_pns: 'Accesso PNS',
  manage_pns: 'Invia notifiche push',
  image_link: 'Link immagine',
  pns_notification_confirm: 'Attenzione: La notifica sarà inviata, continuare?',

  /**FAQ Service */
  publish_in: 'Pubblicare in',
  frequently_ask_question: 'Domanda frequente',
  faq: 'FAQ',
  all_faq: 'Tutte le FAQ',
  internal_faq: 'FAQ interne',
  all_internals: 'Tutte le domande interne',
  success: 'Successo',
  group: 'Gruppi',
  all_groups: 'Tutti i gruppi',
  add_faq: 'Aggiungi FAQ',
  edit_faq: 'Modifica FAQ',
  delete_faq: 'Elimina FAQ',
  select_group: 'Seleziona gruppo',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Internes_Wissensdokument',
  dateien_hochladen: 'Dateien_Hochladen',
  pdf_upload: 'PDF Upload',
  faq_deleted_successfully: 'FAQ cancellate con successo',

  faq_group: 'Gruppo FAQ',
  add_group: 'Aggiungi gruppo',
  edit_group: 'Modifica gruppo',
  delete_group: 'Elimina gruppo',
  faq_group_deleted_successfully: 'Gruppo FAQ cancellato con successo',

  reservations: 'Prenotazioni',
  all_reservations: 'Tutte le prenotazioni',
  all_requests: 'Tutte le richieste',
  reservation_requests: 'Richieste di prenotazione',
  ferry_bookings: 'Prenotazioni traghetti',
  bread_bookings: 'Prenotazioni di pane',
  bread_daily_view: 'Pane viste giornaliere',
  bread_driving_order: 'Ordini di guida del pane',
  save_inorder_to_apply_changes: 'Salva per applicare le modifiche',

  /** Bread Boking */
  bread_booking: 'Prenotazione pane',
  add_bread_booking: 'Aggiungi prenotazione pane',
  update_bread_booking: 'Aggiorna prenotazione pane',
  pending: 'In sospeso',
  confirmed: 'Confermato',
  cancelled: 'Annullato',
  booking_number: 'Numero di prenotazione',
  add_new_bread_booking: 'Aggiungi nuova prenotazione pane',
  edit_bread_booking: 'Modifica prenotazione pane',
  bread_booking_updated: 'Prenotazione pane aggiornata con successo',
  bread_booking_not_found: 'Prenotazione pane non trovata',
  cannot_change_quantity_in_past:
    'Non è possibile modificare la quantità di una prenotazione nel passato',
  multiple_bookings_in_same_date_not_allowed:
    'Non sono consentite prenotazioni multiple nella stessa data',
  bread_booking_details: 'Dettagli prenotazione pane',
  bread_booking_deleted: 'La prenotazione pane è stata eliminata.',
  view_bread_booking: 'Visualizza prenotazione pane',
  delete_bread_booking: 'Elimina prenotazione pane',
  edit_order: 'Modifica ordine',
  cancel_order: 'Annulla ordine',
  property_name: 'Nome proprietà',
  customer_contact: 'Contatto cliente',
  booking_period: 'Periodo di prenotazione',
  timespan: 'Intervallo di tempo',
  bread_booking_created: 'La prenotazione pane è stata creata',
  bread_booking_creation_failed: 'Creazione prenotazione pane non riuscita.',
  bread_booking_udpate_failed: 'Aggiornamento prenotazione pane non riuscito.',
  include_departure_day: 'Includi giorno di partenza',
  price_pound: 'Prezzo (€)',
  booking_fee_pound: 'Tariffa di prenotazione (€)',
  total_pound: 'Totale (€)',
  must_be_date_after_today_or_equal_to_today:
    'Deve essere una data successiva o uguale a oggi.',
  invalid_data: 'I dati forniti non sono validi',
  booking_invalid: 'Il numero di prenotazione non è corretto',
  service_invalid: 'Il servizio non è corretto',
  customer_invalid: 'Il nome del cliente è corretto',
  /** Driving Order */
  driving_order: 'Ordini di guida',
  import_driving_order: 'Importazione ordini di guida',
  import_driving_success: 'Importazione di guida riuscita',
  import_driving_failed: 'Importazione di guida fallita',

  /** Bread Daily View */
  bread_booking_billing: 'Fatture prenotazione pane',
  bread_booking_billing_detils: 'Dettagli fatturazione prenotazione pane',
  bread_booking_billing_edit: 'Modifica fatturazione prenotazione pane',
  bread_type: 'Tipo di pane',
  old_price: 'Vecchio prezzo',
  new_price: 'Nuovo prezzo',
  price_difference: 'Differenza di prezzo',
  total_bread: 'Totale pane',
  total_fee: 'Totale tariffe',
  total_price_difference: 'Differenza totale prezzo',
  total_price: 'Prezzo totale',
  bread_quantity_difference: 'Differenza quantità pane',
  total_fee_difference: 'Differenza totale tariffe',
  grand_total_difference: 'Differenza totale grande',
  update_bill: 'Aggiorna fattura',
  close_bill: 'Chiudi fattura',
  this_bill_will_be_closed_permanently:
    'Questa fattura sarà chiusa in modo permanente. Non sarai in grado di apportare modifiche',

  /** Bread Daily View */
  property: 'Proprietà',
  guest_number: 'Numero ospite',
  brotchen: 'Brötchen',
  bread_daily_view_details: 'Dettagli vista pane quotidiano',
  view_bread_daily_view: 'Vista pane quotidiano',
  download: 'Scaricare',
  download_image: 'Scarica immagine',
  download_driving_order: "Scarica l'ordine di guida",

  /** Reservation Request */
  reservation_request_details: 'Dettagli della richiesta di prenotazione',
  booking_detail: 'Dettaglio prenotazione',
  reservation_detail: 'Dettaglio prenotazione',
  adults: 'Adulti',
  customer_detail: 'Dettaglio cliente',
  sur_name: 'Sur Name',
  reservation_details: 'Dettagli della prenotazione',
  service_line: 'Linea di servizio',
  view_reservation_request_view: 'Visualizza richiesta di prenotazione',
  dog: 'Cane',
  dog_breed: 'Razza di cane',

  /** Ferry Booking */
  view_ferry_booking_view: 'Visualizza prenotazione traghetto',
  ferry_booking_details: 'Dettagli prenotazione traghetto',
  booking_amount: 'Importo prenotazione',
  trip_date: 'Data del viaggio',
  time: 'Orario',
  trip_voyage: 'Viaggio',
  departure_harbor: 'Porto di partenza',
  destination_harbor: 'Porto di destinazione',
  trip_return_date: 'Data di ritorno del viaggio',
  vehicle_type: 'Tipo di veicolo',
  licence_plate: 'Targa',
  length: 'Lunghezza',
  customer_email: 'Email del cliente',
  trip_detail: 'Dettaglio viaggio',
  vehicle_detail: 'Dettaglio veicolo',
  cm: 'cm',
  ferry_booking_cancel_success:
    'La prenotazione del traghetto è stata annullata',
  ferry_booking_cancel_failed:
    'Annullamento prenotazione traghetto non riuscito.',

  data_synced: 'Dati sincronizzati.',
  last_v_office_data_sync: 'Ultima sincronizzazione dati v-office',
  access_reservations: 'Prenotazioni',
  access_bookings: 'Accesso alle prenotazioni e al calendario',
  manage_bookings: 'Gestire prenotazioni, fatture e check-in  ',
  access_invoices: 'Accesso alle fatture',
  manage_bread_service: 'Gestisci servizio pane',
  access_bread_driving_order: 'Ordine di guida del pane',
  access_callbacks: 'Richiami',

  access_faq: 'Accesso FAQ',
  manage_faq: 'Gestisci FAQ',
  access_faq_internal: 'Accesso FAQ interna',
  access_faq_group: 'Accesso gruppo FAQ',
  manage_faq_group: 'Gestisci gruppo FAQ',

  access_dms_general: 'Accesso al documento generale',
  manage_dms_general: 'Gestione del documento generale',
  access_dms_object: 'Oggetto documento di accesso',
  manage_dms_object: 'Gestisci oggetto documento',
  access_dms_customer: 'Accesso al documento cliente',
  manage_dms_customer: 'Gestisci cliente documento',
  access_dms_owner: 'Proprietario del documento di accesso',
  manage_dms_owner: 'Gestire il proprietario del documento',
  access_dms_user: "Accesso al documento dell'utente",
  manage_dms_user: "Gestire il documento dell'utente",
  select_date: 'Seleziona data',
  select_date_time: 'Seleziona data/ora',

  /*Internet Status*/
  wifi: 'La connessione a Internet è stata ripristinata',
  nowifi: 'Stai navigando in modalità offline',
  refresh: 'Aggiorna',

  /*Contact Center*/
  access_contact_center: 'Accesso al Contact Center',

  /*Newsletter*/
  newsletter: 'Newsletter',
  newsletter_contact: 'Contatto Newsletter',
  newsletters: 'Newsletters',
  newsletter_overview: 'Panoramica Newsletter',
  draft: 'Bozza',
  drafts: 'Bozze',
  group_of_recipients: 'Gruppo di destinatari',
  all_newsletters: 'Tutte le newsletters',
  new_newsletter: 'Nuova newsletter',
  create_new_newsletter: 'Crea nuova newsletter',
  recipients: 'Destinatari',
  subject: 'Oggetto',
  all_draft: 'Tutte le bozze',
  message: 'Messaggio',
  attach_file: 'Allega file',
  attachments: 'Allegati:',
  preview: 'Anteprima',
  save_as_draft: 'Salva come bozza',
  compose_message: 'Componi messaggio',
  preview_message: 'Anteprima newsletter',
  download_our_app_now: 'Scarica la nostra app adesso',
  contact_with_us: 'Contattaci',
  about_us: 'Su di noi',
  customer_care: 'Assistenza clienti',
  contact_us: 'Contattaci',
  privacy_policy: 'Politica sulla privacy',
  subscribe: 'Iscriviti',
  unsubscribe: 'Annulla iscrizione',
  select_recipient_group: 'Seleziona destinatario',
  edit_newsletter: 'Modifica newsletter',
  delete_newsletter: 'Elimina newsletter',
  newsletter_deleted: 'Newsletter eliminata.',
  newsletter_delete_error: 'Eliminazione newsletter non riuscita.',
  newsletter_sent_sucessfully: 'Newsletter inviata con successo',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter salvata in bozza con successo',
  newsletter_updated_sucessfully: 'Newsletter aggiornata con successo',
  hello: 'Ciao',
  to_send: 'Da inviare',
  save_and_send: 'Salva e invia',
  recipient_group_field_is_required: 'Il campo gruppo destinatario è richiesto',
  subject_field_is_required: 'Il campo oggetto è richiesto',
  message_field_is_required: 'Il campo messaggio è obbligatorio',
  send_newsletter: 'Invia newsletter',
  attention_notification_will_be_send_continue:
    'Attenzione! La notifica sarà inviata in modo continuativo',
  privacy: 'Privacy',
  imprint: 'Impronta',

  /*required*/
  required_first_name: 'Il nome è obbligatorio.',
  required_last_name: 'Il cognome è obbligatorio.',
  required_email: "L'email è obbligatoria.",
  required_cellphone: 'Il cellulare è obbligatorio.',
  required_role: 'Il ruolo è obbligatorio.',
  required_address: "L'indirizzo è obbligatorio.",
  required_repate_from: 'Ripetere da è obbligatorio.',
  required_repeate_until: 'Ripetere fino a è obbligatorio.',
  required_interval: "L'intervallo ricorsivo è obbligatorio.",
  required_label: "L'etichetta è obbligatoria.",
  required_type: 'Il tipo è obbligatorio.',
  required_article: "L'articolo è obbligatorio.",
  required_destination: 'La destinazione è obbligatoria.',
  required_source: 'La fonte è obbligatoria.',
  required_quantity: 'La quantità è obbligatoria.',
  required_article_name: "Il nome dell'articolo è obbligatorio.",
  required_unit: "L'unità è obbligatoria.",
  required_type_name: 'Il nome del tipo è obbligatorio.',
  required_unit_name: "Il nome dell'unità è obbligatorio.",
  required_descriptions: 'La descrizione è obbligatoria.',
  required_document_name: 'Il nome del documento è obbligatorio.',
  required_Object: "L'oggetto è obbligatorio.",
  required_upload: 'Il caricamento del file è obbligatorio.',
  required_service_name: 'Il nome del servizio è obbligatorio.',
  required_voffice_service: 'Il servizio VOffice è obbligatorio.',
  required_service_group: 'Il gruppo di servizi è obbligatorio.',
  required_cover_image: "L'immagine di copertina è obbligatoria.",
  required_booking_type: 'Il tipo di prenotazione è obbligatorio.',
  required_booking_fee: 'La tassa di prenotazione è obbligatoria.',
  required_price_per_night: 'Il prezzo per notte è obbligatorio.',
  required_icon: "Il caricamento dell'icona è obbligatorio.",
  required_group_name: 'Il nome del gruppo è obbligatorio',
  required_status: 'Lo stato è richiesto',
  required_group_recipients: 'Il gruppo di destinatari è obbligatorio',
  required_subject: "L'oggetto è obbligatorio",
  required_message: 'Il messaggio è obbligatorio',
  required_room: 'La stanza è obbligatoria',
  required_rack: 'È richiesto un rack',
  required_shelf: 'È richiesto uno scaffale',
  required_warehouse_name: 'Il nome del magazzino è richiesto',
  file_size_is_too_large: 'La dimensione del file è troppo grande',
  required_customer: 'Il cliente è richiesto',
  required_owner: 'Il proprietario è richiesto',
  required_user: "L'utente è richiesto",
  required_front_color: 'Il colore del frontend è obbligatorio',
  only_one_file_is_allowed: 'È consentito solo un file',
  please_fill_in_all_mandatory_fields: 'Compilare tutti i campi obbligatori',
  required_password: 'È richiesta la password',
  required_distribution_channel: 'Il canale di distribuzione è obbligatorio',
  required_sleeping_facilities: 'Sono richieste strutture per dormire',
  required_pets_allowed: 'Il campo animali ammessi è obbligatorio',
  /*Message*/
  email_taken: "L'email è già stata presa",

  access_newsletter: 'Vedi Newsletter e bozze',
  manage_newsletter: 'Modifica newsletter e bozze',

  /*Key*/
  key: 'Chiave',
  keyscan: 'Keyscan',
  access_keyscan_keylist: 'Accesso alla lista delle chiavi',
  access_keyscan_keylog: 'Accesso al registro delle chiavi',
  key_management_system: 'Sistema di gestione delle chiavi',
  key_list: 'Lista delle chiavi',
  key_logs: 'Registri delle chiavi',
  key_management: 'Gestione delle chiavi',
  all_key_logs: 'Tutti i registri delle chiavi',
  search_customer: 'Cerca cliente / utente',
  key_id: 'Id chiave',
  scan_key: 'Scansiona chiave',
  key_handling: 'Gestione delle chiavi',
  scan_key_again: 'Scansiona di nuovo la chiave',
  key_information: 'Informazioni sulla chiave',
  key_current_status: 'Stato attuale della chiave',
  new_status: 'Nuovo stato',
  rfid_message: 'Scansione RFID riuscita',
  rescan_key: 'Riscansiona chiave',
  key_new_status: 'Nuovo stato della chiave',
  place_rfid: 'Posiziona la chiave sul lettore RFID.',
  all_key_list: 'Lista di tutte le chiavi',
  add_key: 'Aggiungi chiave',
  add_new_key: 'Aggiungi nuova chiave',
  registered_date: 'Data di registrazione',
  registered_by: 'Registrato da',
  edit_key: 'Modifica chiave',
  delete_key: 'Elimina chiave',
  view_key: 'Visualizza chiave',
  given_out: 'Ceduta',
  in_stock: 'Disponibile',
  lost: 'Persa',
  key_history: 'Storia della chiave',
  key_created_successfully: 'Chiave creata con successo',
  key_created_error: 'Errore di creazione della chiave',
  key_updated_successfully: 'Chiave aggiornata con successo',
  key_updated_error: 'Errore di aggiornamento della chiave',
  key_deleted_successfully: 'Chiave eliminata con successo',
  key_deleted_error: 'Errore di cancellazione della chiave',
  rescan: 'Rescan',
  rfid_taken_error: 'Chiave RFID già presa',
  scanner_title_message: 'Si prega di posizionare il tag vicino allo scanner',
  scanner_message: 'La chiave è stata posizionata correttamente?',
  key_no_registered: 'La chiave non è ancora stata registrata',

  /*Aerial Map */
  aerial_map: 'Mappa aerea',
  access_aerial_map: 'Accesso alla mappa aerea',
  three_sixty_degree: '360°',
  standard: 'Standard',
  map: 'Mappa',

  /* Appartment Address Form*/
  address_location: 'Indirizzo e posizione',
  address_suffix: "Suffisso dell'indirizzo",
  distance_lake: 'Distanza dal lago',
  distance_shopping: 'Distanza Shopping',
  distance_restaurant: 'Distanza ristorante',
  distance_city: 'Distanza città',
  distance_train_station: 'Distanza stazione ferroviaria',
  distance_bank_atm: 'Distanza banca / bancomat',
  distance_doctor: 'Distanza medico',
  landscape_view: 'Vista del paesaggio',
  garden_view: 'Vista giardino',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'La mappa/indicazioni di Google Maps facilita agli ospiti la navigazione verso il vostro appartamento e offre la massima trasparenza',
  type_is_required: 'Il tipo è richiesto',

  /*Premises Features Form */
  sleeping_arrangements: 'Disposizioni per il sonno',
  number_of_rooms: 'Numero di camere',
  number_of_bedrooms: 'Numero di camere da letto',
  number_of_bathrooms: 'Numero di bagni',
  guest_toilet: 'Servizi igienici per gli ospiti',
  size_in_square_meters: 'Dimensione in metri quadrati',
  number_of_parking_spaces: 'Numero di posti auto',
  additional_information: 'Informazioni aggiuntive',
  kitchen: 'Cucina',
  terrace: 'Terrazza',
  balcony: 'Balcone',
  non_smoker: 'Non fumatori',
  pets_allowed: 'Animali ammessi',
  children_allowed: 'Bambini ammessi',
  diabled_access: 'Accesso per disabili',
  wheelchair_accessible: 'Accessibile in sedia a rotelle',
  parking_available: 'Parcheggio disponibile',
  washing_machine: 'Lavatrice',
  dryer: 'Asciugatrice',
  fireplace_stove: 'Camino a legna',
  drying_rack: 'Appendino per stendere',
  bedding: 'Biancheria da letto',
  e_car_charging_station: 'Stazione di ricarica per auto elettriche',
  air_conditioning: 'Aria condizionata',

  /* Categories Form */
  Wellness: 'Benessere',
  luxury: 'lusso',
  family: 'famiglia',
  secluded: 'appartato',
  resort: 'Località',
  romantic: 'Romantico',
  athletic: 'Atletico',
  tourist_attraction: 'Attrazione turistica',

  /* Equiment Kitchen Form */
  microwave: 'Microonde',
  stove: 'Fornello',
  oven: 'Forno',
  refrigerator: 'Frigorifero',
  dishwasher: 'Lavastoviglie',
  coffee_machine: 'Macchina per il caffè',
  kettle: 'Bollitore',
  toaster: 'Tostapane',
  dishes_cutlery: 'Piatti / Posate',
  waste_bin: 'Pattumiera',

  /* Amenities Bathroom Form */
  no_of_showers: 'No Of Showers',
  no_of_toilets: 'No of Toilets',
  shower: 'Doccia',
  wc: 'wc',
  bathtub: 'Vasca da bagno',
  bidet: 'bidet',
  hair_dryer: 'Asciugacapelli',
  mirror: 'Specchio',
  bathroom_sink: 'Lavandino del bagno',

  /* Amenities Children Form */
  stair_gate: 'Cancello per scale',
  changing_pad: 'Fasciatoio',
  socket_fuse: 'Fusibile di presa',
  child_toilet_seat: 'Seggiolino da bagno per bambini',

  /*Equipment Outside Form */
  type_of_grill: 'Tipo di Grill',
  grill: 'Grill',
  parasol: 'Ombrellone',
  pond: 'Stagno',
  swing: 'Altalena',
  sandbox: 'Bacino di Sabbia',
  slide: 'Scivolo',
  fireplace: 'Camino',
  garden: 'Giardino',
  garden_house: 'Casa da Giardino',
  hammock: 'Amaca',

  /* Leisure Activities Entertainment Form */
  free_time_activities: 'Attività Tempo Libero',
  sauna: 'Sauna',
  jacuzzi: 'Jacuzzi',
  solarium: 'Solarium',
  gym: 'Palestra',
  pool: 'Piscina',
  entertainment: 'Intrattenimento',
  tv: 'TV',
  hifi: 'Hifi',
  radio: 'Radio',
  wlan: 'WLAN',
  internat_speed: 'Velocità di Internet',

  /**lyrics form */
  headline: 'Titolo',
  full_description: 'Descrizione completa',
  short_description: 'Descrizione breve',
  directions: 'Indicazioni',

  /**Settings Form */
  season_and_prices: 'Stagioni e prezzi',
  payment_active: 'Pagamento attivo',
  site_active: 'Sito attivo',
  vimeo_video_link: 'Link video Vimeo (opzionale)',
  please_assign_price:
    "Si prega di assegnare le stagioni disponibili all'appartamento e di indicare un prezzo",
  assigned: 'Assegnato',

  appartment_name: 'Nome Appartamento',
  upload: 'Carica',
  upload_document_to_apartment: "Carica documento nell'appartamento",
  select_appartment: 'Seleziona Appartamento',
  adjust_order_of_images:
    "L'ordine delle immagini può essere facilmente regolato tramite trascinamento e rilascio.",
  delete_image: 'Elimina Immagine',
  add_new_payment: 'Aggiungi Nuovo Pagamento',
  payment_made: 'Pagamento Effettuato',
  date_of_receipt_of_payment: 'Data di Ricezione del Pagamento',
  delete_payment: 'Elimina Pagamento',
  service_information: 'Informazioni sul Servizio',
  prepaid: 'Prepaid (CHF)',
  balance: 'Bilancio (CHF)',
  edit_booking: 'Modifica Prenotazione',
  add_more_services: 'Aggiungi Più Servizi',
  checkin_now: 'Check-In ora',
  checkout_now: 'Check-Out ora',
  add_service_for_booking: 'Aggiungi servizi alla prenotazione {0}',
  confirm_checkin: 'Vuoi effettuare il Check-In per il cliente {0} ora?',
  confirm_checkout: 'Vuoi effettuare il Check-Out per il cliente {0} ora?',
  terminal_system: 'Sistema Terminal',
  terminal: 'Terminale',
  check_in_check_out: 'Check-In / Check-Out',
  check_in: 'Check-In',
  check_out: 'Check-Out',
  guest_check_in: 'Check-In Ospite',
  guest_check_out: 'Check-Out Ospite',
  checkin_modal_body_first_message: "Vuoi effettuare il check-in per l'ospite",
  checkout_modal_body_first_message:
    "Vuoi effettuare il check-out per l'ospite",
  checkout_modal_body_second_message: 'ora',
  checkin_modal_body_last_message: 'ora',
  please_make_sure_the_guest_has_read_the_booking_information:
    "Assicurati che l'ospite abbia letto le informazioni sulla prenotazione.",
  check_booking_details: 'Controlla i dettagli della prenotazione',
  people: 'Persone',
  period: 'Periodo',
  welcome: 'Benvenuti',
  booked_services: 'Servizi Prenotati',
  guest_has_been_successfully_checked_in: 'Ospite registrato con successo!',
  guest_has_been_successfully_checked_out: 'Ospite check-out con successo!',
  could_not_check_in_guest: "Impossibile registrare l'ospite",
  could_not_check_out_guest: "Impossibile effettuare il check-out dell'ospite",
  make_check_in: 'Registrati ora',
  make_check_out: 'Check-out ora',
  disconnected_from_second_screen: 'Disconnesso dallo schermo secondario',
  device_id_pair_context_must_be_used_within_device_id_pair_provider:
    "Il contesto DeviceIdPair deve essere utilizzato all'interno del fornitore DeviceIdPair",
  arrived: 'Arrivato',
  departed: 'Partito',
  generate_invoice: 'Genera fattura',
  select_existing_customer: 'Seleziona cliente esistente',
  accept_selected: 'Accetta selezionato',
  disconnected: 'Disconnesso',

  abort: 'Abort',
  mirror_booking_details: 'Specchio dei dettagli della prenotazione',
  did_you_choose_the_right_guest: "Hai scelto l'ospite giusto?",
  msg_check_in_check_out_modal:
    "Nella fase successiva, i dettagli della prenotazione vengono visualizzati affinché l'ospite possa controllarli. In seguito è possibile apportare modifiche o aggiunte.",
  should_the_booking_details_of_the_following_guest_be_displayed:
    "Devono essere visualizzati i dettagli della prenotazione dell'ospite seguente?",
  invoice_number: 'Numero di fattura',
  invoice_type: 'Tipo di fattura',
  date_of_invoice: 'Data della fattura',
  all: 'Tutti',
  owner_invoice: 'Dichiarazione di affitto',
  customer_invoice: 'Fattura cliente',

  // Owner Invoice information
  owner_billing: 'Fatturazione del proprietario',
  sales_tax_id: 'ID imposta sulle vendite',

  successfully_generate_invoice_by_booking_id:
    'Fattura generata con successo per ID prenotazione',

  // Owner Invoice information
  dear: 'Cara/o',
  here_with_you_receive_the_current_rent_statement_for_your_object:
    'Ecco a te il rendiconto attuale del noleggio per il tuo oggetto',
  with_the_invoice_number: 'con il numero fattura',
  For_the_mentioned_period_we_can_invoice_the_following_booking:
    'Per il periodo menzionato possiamo fatturare la seguente prenotazione',
  your_income: 'Il tuo reddito',
  vat: 'IVA',
  our_services: 'I nostri servizi',
  vat_included: 'IVA inclusa',
  accounting: 'Contabilità',
  total_net: 'Totale netto',
  balance_to_your_credit: 'Saldo a tuo credito',
  the_clearing_ammount_will_be_transferred_to_your_account_in_the_next_few_days:
    "L'importo di compensazione verrà trasferito sul tuo conto nei prossimi giorni",
  net_total: 'Totale netto',
  invoice_total: 'Totale',
  payment_detail: 'Dettagli pagamento',
  überwiesen: '',
};
export default it;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { getChatMetrics } from 'api/chat';
import { wrapperStyles } from 'assets/css/commonStyles';
import ChatMetricListItem from 'components/chat/ChatMetricListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useRef, useState } from 'react';
import { BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const ChatMetrics: React.FC = () => {
  const searchFormRef = useRef<HTMLFormElement>(null);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword');
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: url_keyword ?? '',
  });
  const chatMetricsList = useQuery(
    [
      'chatMetricsList',
      {
        page: filterParams.currentPage,
        name: filterParams.keyword,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.name = filterParams.keyword;
      const response = await getChatMetrics(queryParams);
      return response?.data;
    }
  );

  const handleReset = async () => {
    searchFormRef.current?.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.chat.metrics);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.chat.default}>
            {strings.chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.chat_metrics}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="lg" textTransform="capitalize">
        {strings.chat_metrics}
      </Heading>

      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form ref={searchFormRef} onSubmit={(e) => e.preventDefault()}>
                <Stack
                  direction={['column', 'row']}
                  spacing="4"
                  align={['start', 'end']}>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        h="100%"
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.500"
                      />
                      <Input
                        placeholder={strings.search}
                        size="lg"
                        name="keyword"
                        type="text"
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>

                  <Stack direction="row" spacing="4">
                    <Button
                      size="lg"
                      colorScheme="primary"
                      onClick={handleReset}
                      variant="outline">
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.employee_name}</Th>
                <Th>{strings.active}</Th>
                <Th textAlign="center">{strings.no_of_active_chat}</Th>
                <Th textAlign="center">{strings.total_chats}</Th>
                <Th textAlign="center">{strings.total_messages}</Th>
                <Th textAlign="center">{strings.sent_messages}</Th>
                <Th textAlign="center">{strings.talk_time}</Th>
              </Tr>
            </Thead>

            <Tbody>
              {chatMetricsList?.data?.data?.map((item: any, index: number) => (
                <ChatMetricListItem
                  key={item._id}
                  startingSN={startingSN + index}
                  data={item}
                />
              ))}
              {chatMetricsList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={chatMetricsList}
      />
    </Stack>
  );
};

export default ChatMetrics;

import { FormControl, Input, Switch, Td, Tr } from '@chakra-ui/react';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data: any;
  editing: boolean;
}

const SeasonPriceListItems: React.FC<Props> = (props) => {
  const { data, editing } = props;
  const methods = useFormContext<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const [seasonState, setSeasonState] = useState<any>({});

  useEffect(() => {
    if (data) {
      let ob = {
        name: data.name,
        from_date: data.from_date,
        to_date: data.to_date,
      };
      setSeasonState(ob);
      setValue(`${data.id}.price`, data?.prices[0]?.price);
      setValue(`${data.id}.assigned`, data?.prices[0]?.assigned);
    }
  }, [data, setValue]);

  return (
    <Tr>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.name}
          name={seasonState.name}
        />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.from_date}
          name={seasonState.from_date}
        />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.to_date}
          name={seasonState.to_date}
        />
      </Td>
      <Td>
        <FormControl>
          <Input
            {...register(`${data.id}.price`)}
            isDisabled={!editing}
            type="text"
            placeholder={`${strings.price}(${strings.euro_symbol})`}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl isRequired>
          <Switch
            {...register(`${data.id}.assigned`)}
            defaultChecked={!!data?.prices[0]?.assigned}
            isDisabled={!editing}
            size="md"
            colorScheme="green"
          />
        </FormControl>
      </Td>
    </Tr>
  );
};

export default SeasonPriceListItems;

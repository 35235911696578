import React from 'react';
import SchemeFormByBeachChair from './SchemeFormByBeachChair';
import SchemeFormByDay from './SchemeFormByDay';
import SchemeFormByDuration from './SchemeFormByDuration';
import SchemeFormByQty from './SchemeFormByQty';

interface Props {
  schemeType: string;
  schemeData: any;
}

const ServiceSchemeForm: React.FC<Props> = (props) => {
  const { schemeType, schemeData } = props;

  const schemeForm = () => {
    if (schemeType === 'PriceSchemeByQuantity') {
      return (
        <SchemeFormByQty
          schemeData={schemeData?.prices[0]}
          currentType="PriceSchemeByQuantity"
          schemeType={schemeData?.price_scheme_type}
        />
      );
    }
    if (schemeType === 'PriceSchemeByDuration') {
      return (
        <SchemeFormByDuration
          schemeData={schemeData?.prices}
          type={schemeData?.price_scheme_type}
        />
      );
    }
    if (schemeType === 'PriceSchemeByDay') {
      return (
        <SchemeFormByDay
          schemeData={schemeData?.prices}
          currentType="PriceSchemeByDay"
          schemeType={schemeData?.price_scheme_type}
        />
      );
    }
    if (schemeType === 'PriceSchemeNewspaper') {
      return (
        <SchemeFormByDay
          schemeData={schemeData?.prices}
          currentType="PriceSchemeNewspaper"
          schemeType={schemeData?.price_scheme_type}
        />
      );
    }
    if (schemeType === 'PriceSchemeOneTime') {
      return (
        <SchemeFormByQty
          schemeData={schemeData?.prices[0]}
          currentType="PriceSchemeOneTime"
          schemeType={schemeData?.price_scheme_type}
        />
      );
    }
    if (schemeType === 'PriceSchemeBeachChair') {
      return (
        <SchemeFormByBeachChair
          schemeData={schemeData?.prices}
          type={schemeData?.price_scheme_type}
        />
      );
    }
    return <></>;
  };

  return schemeForm();
};

export default ServiceSchemeForm;

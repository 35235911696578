import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import BMSCheckOutResource from 'api/bms-check-out';
import { CheckOut } from 'assets/icons';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { BMSBooking } from 'constants/schemas/bookings';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  bookingId: number;
}

const CheckOutConfirmationModal = (props: Props) => {
  const { isModalOpen, bookingId, onModalClose } = props;

  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const bmsBookingAPI = new BMSBookingResource();
  const bmsCheckOutAPI = new BMSCheckOutResource();

  const bookingDetailsQuery = useQuery(
    ['booking-details', bookingId],
    async () => {
      const data = await bmsBookingAPI.get(bookingId);
      return data.data;
    },
    {
      enabled: !!bookingId,
    }
  );
  const bookingDetails: BMSBooking | undefined =
    bookingDetailsQuery?.data?.data;

  const checkOut = useMutation((id: any) => bmsCheckOutAPI.checkOut(id), {
    onSuccess: () => {
      toast({
        title: strings.guest_has_been_successfully_checked_out,
        status: 'success',
        isClosable: true,
      });

      queryClient.invalidateQueries('checkout-list');
      onModalClose();
      history.push(routes.bms.checkInCheckOut.list);
    },
    onError: (err: any) => {
      toast({
        title: strings.could_not_check_out_guest,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const handleCheckOut = () => {
    checkOut.mutate(bookingId);
  };

  const getModalBody = () => {
    if (bookingDetailsQuery.isLoading) {
      return (
        <ModalBody>
          <Flex height="200px" alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        </ModalBody>
      );
    }
    return (
      <>
        <ModalBody>
          <Stack direction="column">
            <Heading size="sm">
              {strings.checkout_modal_body_first_message}&nbsp;
              {bookingDetails?.customer?.name}&nbsp;
              {strings.checkout_modal_body_second_message}?
            </Heading>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <ButtonGroup>
            <Button
              type="submit"
              size="lg"
              color="white"
              bg="#6266D1"
              isLoading={checkOut.isLoading}
              onClick={handleCheckOut}
              _hover={{ bg: '#585CBC' }}
              _active={{ bg: '#585CBC' }}>
              <Image src={CheckOut} w="14px" />
              &nbsp; &nbsp;{strings.make_check_out}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="xl"
      isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center">
        <ModalHeader>{strings.guest_check_out}</ModalHeader>
        <ModalCloseButton />
        {getModalBody()}
      </ModalContent>
    </Modal>
  );
};

export default CheckOutConfirmationModal;

import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { setTicketLogInfo } from 'actions/data/ticket';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import { BillableSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiTime } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { maskSpentTime } from 'utils';
import { checkPermissions } from 'utils/listInfo';

interface Props {
  ticket?: any;
  updatedBy?: any;
}

const Billability: React.FC<Props> = (props) => {
  const { ticket, updatedBy } = props;
  const [isSpenTimeUpdated, setIsSpenTimeUpdated] = useState(
    ticket.is_spent_time_updated
  );
  const methods = useForm<BillableSchema>();
  const dispatch = useDispatch();
  const toast = useToast();
  const ticketAPI = new TicketResource();

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const updateTicket = useMutation((data: BillableSchema) =>
    ticketAPI.updateTicketBillableTime(
      ticket.id,
      data.spent_time,
      data.is_spent_time_updated,
      updatedBy
    )
  );

  const onSubmit = (data: BillableSchema) => {
    const isPermitted = checkPermissions(userPermissions, [
      PermissionRequest['edit:ticket'],
    ]);
    if (!isPermitted) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    data.is_spent_time_updated = true;
    updateTicket.mutate(data, {
      onSuccess: (res) => {
        setIsSpenTimeUpdated(true);
        dispatch(setTicketLogInfo(res.data));
      },
    });
  };

  return (
    <>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.billability}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction={['column', 'column', 'row']}>
              <FormControl>
                <FormLabel>{strings.spent_time}</FormLabel>
                <HStack>
                  <InputGroup>
                    <InputRightElement
                      h="100%"
                      pointerEvents="none"
                      children={<BiTime color="gray.100" />}
                    />
                    {/**
                     * accepts maximum 8 character including ':' and mask the input.
                     */}
                    <Input
                      {...methods.register('spent_time')}
                      type="text"
                      size="lg"
                      placeholder="HH:MM:SS"
                      maxLength={8}
                      defaultValue={maskSpentTime(ticket.spent_time)}
                      onChange={(e) => {
                        e.target.value = maskSpentTime(e.target.value);
                      }}
                    />
                  </InputGroup>

                  <Button
                    whiteSpace="normal"
                    w="220px"
                    colorScheme="primary"
                    type="submit"
                    size="lg">
                    {strings.adjust_time_spent}
                  </Button>
                </HStack>
              </FormControl>

              {isSpenTimeUpdated && (
                <FormControl>
                  <FormLabel>&nbsp;</FormLabel>
                  <Alert status="success">
                    <AlertIcon />
                    {strings.spent_time_message}
                  </Alert>
                </FormControl>
              )}
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};

export default Billability;

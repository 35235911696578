import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import OMSSeasonResource from 'api/oms-season';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

interface Props {
  data: any;
}

const SeasonListItems: React.FC<Props> = (props) => {
  const { data } = props;
  const [seasonState, setSeasonState] = useState<any>({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const seasonAPI = new OMSSeasonResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const checkEditPermission = useIsPermitted([PermissionRequest['manage:sms']]);

  useEffect(() => {
    if (data) {
      let ob = {
        id: data.id,
        name: data.name,
        description: data.description,
        from_date: DateFormat(data.from_date),
        to_date: DateFormat(data.to_date),
      };
      setSeasonState(ob);
    }
  }, [data]);

  const deleteSeason = useMutation(() => seasonAPI.destroy(seasonState.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('seasonList');

      if (res.data?.success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.user_deleted,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.oms.season.list);
      } else {
        setDeleteMsg(strings.user_delete_error);
      }
    },
  });

  const onDeletePress = () => {
    if (!checkEditPermission) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteSeason.mutate();
  };

  return (
    <Tr>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.name}
          name={seasonState.name}
        />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.description}
          name={seasonState.description}
        />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.from_date}
          name={seasonState.from_date}
        />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={seasonState.to_date}
          name={seasonState.to_date}
        />
      </Td>

      <Td>
        <RouterLink
          to={`${routes.oms.season.edit.replace(':id', seasonState.id)}`}>
          <Tooltip hasArrow label={strings.edit_season}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_season}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <Tooltip hasArrow label={strings.delete_season}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_season}
            color="green.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_season}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteSeason.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default SeasonListItems;

import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import UserForm from 'components/user/UserForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { unmaskPhone } from 'utils';

const AddNewUser: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<UserSchema>();
  const userAPI = new UserResource();

  const [errorMessage, setErrorMessage] = useState<UserSchema>();

  const addUser = useMutation((data: any) => userAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.user_created,
        description: res.data.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('userList');
      history.push(routes.users.list);
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  /*
   *set error message if Email is already taken.
   */
  const { setError } = methods;

  useEffect(() => {
    if (errorMessage?.email) {
      setError(
        'email',
        {
          type: 'manual',
          message: errorMessage?.email?.toString(),
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [errorMessage, setError]);

  const onSubmit = (data: any) => {
    const permissionObj = { ...data.permissions };
    const permissions = Object.keys(permissionObj).filter(
      (k) => permissionObj[k]
    );
    data.permissions = permissions;
    data.contact_number = unmaskPhone(data.contact_number);
    addUser.mutate(data);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.add_new_user}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.add_new_user}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.user_creation_failed}
                </Alert>
              )}
              <UserForm />
              <ButtonGroup justifyContent="flex-end">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  size="lg"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  size="lg"
                  isLoading={addUser.isLoading}>
                  {strings.add_user}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddNewUser;

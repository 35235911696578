import http from 'utils/http';
import Resource from './resource';

class BMSBookingResource extends Resource {
  constructor() {
    super('bms/bookings');
  }

  // get appartment booking price
  appartmentBookingPrice(query: any) {
    return http({
      url: `/bms/bookings/totalPriceForBooking`,
      method: 'GET',
      params: query,
    });
  }

  listApartmentWithBookings(query?: any) {
    return http({
      url: '/bms/apartments',
      method: 'GET',
      params: query,
    });
  }

  checkDateAvailabilities(id: number | string) {
    return http({
      url: `/bms/apartments/${id}/availability`,
      method: 'GET',
    });
  }
  listCountries() {
    return http({
      url: '/bms/countries?limit=250',
      method: 'get',
    });
  }
  getCountryDetail(id: string) {
    return http({
      url: `/bms/countries/${id}`,
      method: 'get',
    });
  }

  generateInvoiceByBookingId(id: number | string) {
    return http({
      url: `/bms/master-invoices/${id}`,
      method: 'PUT',
    });
  }

  existingCustomerList(query?: any) {
    return http({
      url: '/bms/customers',
      method: 'GET',
      params: query,
    });
  }
}

export { BMSBookingResource as default };

import { Box, List } from '@chakra-ui/react';
import {
  THEME_AERIAL_MAP,
  THEME_BMS,
  THEME_CHAT,
  THEME_CONTACT_CENTER,
  THEME_CRM,
  THEME_DMS,
  THEME_FAQ,
  THEME_FEEDBACK,
  THEME_KEY,
  THEME_NEWS,
  THEME_OMS,
  THEME_PMS,
  THEME_PNS,
  THEME_SMS,
  THEME_TICKET,
  THEME_USER,
} from 'constants/common';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomTheme } from 'utils/CustomTheme';
import {
  BMSSubMenuList,
  ChatSubMenuList,
  ContactCenterSubMenuList,
  CrmSubMenuList,
  DMSSubMenuList,
  FAQSubMenuList,
  FeedbackSubMenuList,
  KeySubMenuList,
  NewsletterSubMenuList,
  OmsSubMenuList,
  PMSSubMenuList,
  PNSSubMenuList,
  SMSSubMenuList,
  TicketSubMenuList,
  UserSubMenuList,
} from './SubMenus';
const SidebarSubMenu: React.FC = (props) => {
  const location = useLocation();
  const theme = CustomTheme(location.pathname);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);
  return (
    <Box position="relative" width="100%">
      {theme && (
        <List
          p={['3', '4']}
          pt="12"
          spacing="4"
          fontSize="xs"
          color="white"
          height={['115vh', '120vh']}>
          {theme === THEME_USER && <UserSubMenuList />}
          {theme === THEME_CHAT && <ChatSubMenuList />}
          {theme === THEME_TICKET && <TicketSubMenuList />}
          {theme === THEME_CRM && <CrmSubMenuList />}
          {theme === THEME_OMS && <OmsSubMenuList />}
          {theme === THEME_SMS && <SMSSubMenuList />}
          {theme === THEME_BMS && <BMSSubMenuList />}
          {theme === THEME_FAQ && <FAQSubMenuList />}
          {theme === THEME_DMS && <DMSSubMenuList />}
          {theme === THEME_PMS && <PMSSubMenuList />}
          {theme === THEME_PNS && <PNSSubMenuList />}
          {theme === THEME_CONTACT_CENTER && <ContactCenterSubMenuList />}
          {theme === THEME_FEEDBACK && <FeedbackSubMenuList />}
          {theme === THEME_NEWS && <NewsletterSubMenuList />}
          {theme === THEME_KEY && <KeySubMenuList />}
          {theme === THEME_AERIAL_MAP}
        </List>
      )}
    </Box>
  );
};

export default SidebarSubMenu;

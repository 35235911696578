import { Stack, Stat, StatHelpText, Text } from '@chakra-ui/react';
import {
  messageReceiveStyled,
  messageSentDateTimeStyled,
  messageSentStyles,
} from 'components/chat/ChatStyle';
import { strings } from 'config/localization';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  ChatDateCategoryFormat,
  TimeFormatHMA,
  TimeFormatHMS,
} from 'utils/DateFormat';

interface MessageTimeHeaderProps {
  nextDate: string;
  showMessageDateHeaderFlag: 0 | 1;
}

const MessageTimeHeader = ({
  nextDate,
  showMessageDateHeaderFlag,
}: MessageTimeHeaderProps) => {
  if (showMessageDateHeaderFlag === 1) {
    return (
      <Stack
        justifyContent="center"
        direction="row"
        whiteSpace="nowrap"
        className="message-date-header">
        <Text>{ChatDateCategoryFormat(nextDate)}</Text>
      </Stack>
    );
  }
  return null;
};

interface Props {
  message: any;
  showMessageDateHeaderFlag: 0 | 1;
}

const ChatMessageDetails: React.FC<Props> = (props) => {
  const { message, showMessageDateHeaderFlag } = props;

  const getMessage = () => {
    if (message.type === 'message') {
      if (message.sender === 'user') {
        return (
          <>
            <MessageTimeHeader
              nextDate={message.time}
              showMessageDateHeaderFlag={showMessageDateHeaderFlag}
            />
            <Stack sx={messageReceiveStyled}>
              <Text wordBreak="break-all">{message.message}</Text>
            </Stack>
            <Stack>
              <Text color="#BFBFBF" fontSize="xs">
                {TimeFormatHMA(message.time)}
              </Text>
            </Stack>
          </>
        );
      } else {
        return (
          <>
            <MessageTimeHeader
              nextDate={message.time}
              showMessageDateHeaderFlag={showMessageDateHeaderFlag}
            />
            <Stack sx={messageSentStyles}>
              <Text color="white" wordBreak="break-all">
                {message.message}
              </Text>
            </Stack>
            <Stack sx={messageSentDateTimeStyled}>
              <Text color="#BFBFBF" fontSize="xs">
                {TimeFormatHMA(message.time)}
              </Text>
            </Stack>
          </>
        );
      }
    } else if (message.type === 'call') {
      return (
        <Stat style={{ textAlign: 'center' }}>
          <MessageTimeHeader
            nextDate={message.time}
            showMessageDateHeaderFlag={showMessageDateHeaderFlag}
          />
          <StatHelpText color="#BFBFBF" fontSize="xs">
            {TimeFormatHMA(message.end_time)}
          </StatHelpText>
          <StatHelpText>
            <b>{strings.call_ended}</b>
            <Text color="#BFBFBF" fontSize="xs">
              {TimeFormatHMS(message.call_duration)}
            </Text>
          </StatHelpText>
        </Stat>
      );
    }
  };

  return <>{getMessage()}</>;
};

export default ChatMessageDetails;

import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import history from 'utils/history';

interface Props {
  data?: any;
  editing: boolean;
}

const AddressForm: React.FC<Props> = (props) => {
  const { editing, data } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const objectAPI = new OMSObjectsResource();

  const createObject = useMutation((data: any) => objectAPI.store(data));
  const updateObject = useMutation((formData: any) =>
    objectAPI.update(data.id, formData)
  );

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data) return;
    const { address, garden_view, landscape_view }: any = data;
    const {
      address_line,
      distance_to_lake,
      distance_to_shopping,
      distance_to_restaurant,
      distance_to_train,
      distance_to_doctor,
      distance_to_city,
      distance_to_bank,
    } = address;
    setValue('address_line', address_line);
    setValue('distance_to_lake', distance_to_lake);
    setValue('distance_to_city', distance_to_city);
    setValue('distance_to_doctor', distance_to_doctor);
    setValue('distance_to_shopping', distance_to_shopping);
    setValue('distance_to_restaurant', distance_to_restaurant);
    setValue('distance_to_restaurant', distance_to_restaurant);
    setValue('distance_to_train', distance_to_train);
    setValue('distance_to_bank', distance_to_bank);
    setValue('garden_view', garden_view);
    setValue('landscape_view', landscape_view);
  }, [data, setValue]);

  const onSubmit = (formData: any) => {
    setIsLoading(true);
    let API;
    if (data?.id) {
      API = updateObject;
    } else {
      API = createObject;
    }

    API.mutate(formData, {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(`apartmentDetails`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        let newApartmentId = res.data.data.id;
        setIsLoading(false);
        if (!data?.id) {
          history.push(
            routes.oms.objects.details.replace(
              ':id',
              newApartmentId?.toString()
            )
          );
        }
      },
      onError: () => {
        setErrMsg(`${strings.apartment} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack direction="column" spacing="8">
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <Stack direction="row" spacing="8">
            <Grid
              gap="4"
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
              w="100%">
              <GridItem colSpan={[1, 2]}>
                <Heading color="green.600" size="md" textTransform="capitalize">
                  {strings.location}
                </Heading>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.address_suffix}</FormLabel>
                  <Input
                    {...register('address_line')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.address_suffix}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_lake} (m)</FormLabel>
                  <Input
                    {...register('distance_to_lake')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_lake}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_shopping} (m)</FormLabel>
                  <Input
                    {...register('distance_to_shopping')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_shopping}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_restaurant} (m)</FormLabel>
                  <Input
                    {...register('distance_to_restaurant')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_restaurant}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_city} (m)</FormLabel>
                  <Input
                    {...register('distance_to_city')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_city}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_train_station} (m)</FormLabel>
                  <Input
                    {...register('distance_to_train')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_train_station}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_bank_atm} (m)</FormLabel>
                  <Input
                    {...register('distance_to_bank')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_bank_atm}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.distance_doctor} (m)</FormLabel>
                  <Input
                    {...register('distance_to_doctor')}
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.distance_doctor}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>

          <Stack direction="row" spacing="8">
            <Grid
              gap="4"
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <Checkbox
                    {...register('landscape_view')}
                    defaultChecked={!!data?.landscape_view}
                    isDisabled={!editing}
                    size="lg"
                    colorScheme="green">
                    {strings.landscape_view}
                  </Checkbox>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <Checkbox
                    {...register('garden_view')}
                    defaultChecked={!!data?.garden_view}
                    isDisabled={!editing}
                    size="lg"
                    colorScheme="green">
                    {strings.garden_view}
                  </Checkbox>
                </FormControl>
              </GridItem>
              {/* Disable map features for now
              <GridItem colSpan={2}>
                <Heading color="green.600" size="md" textTransform="capitalize">
                  Google Maps
                </Heading>
                <Text color="gray.500">
                  {
                    strings.the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency
                  }
                </Text>
              </GridItem>
              
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.longitude}</FormLabel>
                  <Input
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.longitude}
                  />
                </FormControl>
              </GridItem>
              <GridItem rowSpan={3} colSpan={1}>
                <Image
                  boxSize="376px"
                  src="https://via.placeholder.com/376"
                  alt="Google Maps"
                />
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.latitude}</FormLabel>
                  <Input
                    isDisabled={!editing}
                    type="text"
                    placeholder={strings.latitude}
                  />
                </FormControl>
              </GridItem>
               */}
            </Grid>
          </Stack>
          {editing && (
            <ButtonGroup alignSelf="end">
              {!data && (
                <Button
                  variant="outline"
                  colorScheme="primary"
                  isDisabled={isLoading}
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              )}
              <Button
                colorScheme="primary"
                type="button"
                isLoading={isLoading}
                onClick={methods.handleSubmit(onSubmit)}>
                {strings.save}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AddressForm;

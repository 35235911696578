import io from 'socket.io-client';
const CONNECTION_PORT: any = process.env.REACT_APP_SOCKET_CONNECTION_PORT;

export const socket = io(CONNECTION_PORT, {
  transports: ['websocket'],
  upgrade: false,
  autoConnect: false,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
});

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ContactListItem from 'components/crm/ContactListItem';
import ContactSearch from 'components/crm/ContactSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ContactSchema } from 'constants/schema';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import { checkPermissions } from 'utils/listInfo';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  first_name: string | null;
  last_name: string | null;
  location: string | null;
  street: string | null;
  type: string | null;
  source: string | null;
  updated_at_from: Date | null;
  updated_at_to: Date | null;
  is_merged: string | null;
  email: string | null;
  phone: string | null;
  newsletter_subscribed: string | null;
}

const ContactList: React.FC = () => {
  const crmAPI = new CRMContactsResource();

  const [isLoading, setIsLoading] = useState<string | null>();
  const [idForCreateOwnerUser, setIdForCreateOwnerUser] = useState<
    number | null
  >(null);

  const { onClose, isOpen, onOpen } = useDisclosure();
  const toast = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const queryClient = useQueryClient();
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];
  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    first_name: searchValues.first_name ?? null,
    last_name: searchValues.last_name ?? null,
    location: searchValues.location ?? null,
    street: searchValues.street ?? null,
    type: searchValues.type ?? null,
    source: searchValues.source ?? null,
    updated_at_from: searchValues.updated_at_from ?? null,
    updated_at_to: searchValues.updated_at_to ?? null,
    is_merged: searchValues.is_merged ?? null,
    email: searchValues.email ?? null,
    phone: searchValues.phone ?? null,
    newsletter_subscribed: searchValues.newsletter_subscribed ?? null,
  });

  const contactList: any = useQuery(
    [
      'list-crm-contacts',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        first_name: filterParams.first_name,
        last_name: filterParams.last_name,
        location: filterParams.location,
        street: filterParams.street,
        type: filterParams.type,
        source: filterParams.source,
        updated_at_from: filterParams.updated_at_from,
        updated_at_to: filterParams.updated_at_to,
        is_merged: filterParams.is_merged,
        email: filterParams.email,
        phone: filterParams.phone,
        newsletter_subscribed: filterParams.newsletter_subscribed,
      },
    ],
    () => {
      let {
        first_name,
        last_name,
        location,
        source,
        street,
        type,
        updated_at_from,
        updated_at_to,
        is_merged,
        email,
        phone,
        newsletter_subscribed,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (first_name) queryParams.first_name = first_name;
      if (last_name) queryParams.last_name = last_name;
      if (location) queryParams.location = location;
      if (street) queryParams.street = street;
      if (type) queryParams.type = type;
      if (source) queryParams.source = source;
      if (updated_at_from) queryParams.updated_at_from = updated_at_from;
      if (updated_at_to) queryParams.updated_at_to = updated_at_to;
      if (is_merged) queryParams.is_merged = is_merged;
      if (email) queryParams.email = email;
      if (phone) queryParams.phone = phone;
      if (newsletter_subscribed)
        queryParams.newsletter_subscribed = newsletter_subscribed;
      return crmAPI
        .list(queryParams)
        .then((res) => {
          res && setIsLoading(() => null);
          return res.data.data;
        })
        .catch();
    }
  );
  const handleAdvancedSearch = useCallback((data: any, whichButton: string) => {
    setIsLoading(whichButton);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      first_name: data.first_name,
      last_name: data.last_name,
      location: data.location,
      street: data.street,
      type: data.type,
      source: data.source,
      updated_at_from: data.updated_at_from,
      updated_at_to: data.updated_at_to,
      is_merged: data.is_merged,
      email: data.email,
      phone: data.phone,
      newsletter_subscribed: data.newsletter_subscribed,
    }));
  }, []);

  const closeCreateOwnerUserConfirmationModal = () => {
    setIdForCreateOwnerUser(null);
    onClose();
  };

  const createOwnerUserMutation = useMutation(
    (id: number) => crmAPI.createOwnerUser(id),
    {
      onSuccess: () => {
        toast({
          title: strings.user_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('list-crm-contacts');
        closeCreateOwnerUserConfirmationModal();
      },
      onError: () => {
        toast({
          title: strings.user_creation_failed,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const checkCreateOwnerPermission = (id: number) => {
    const isPermitted: boolean = checkPermissions(userPermissions, [
      PermissionRequest['create:owner-crm'],
    ]);
    if (!isPermitted) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setIdForCreateOwnerUser(id);
    onOpen();
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.updated_at_from) {
      data.updated_at_from = DateFormatYMD(data.updated_at_from);
    }
    if (data.updated_at_to) {
      data.updated_at_to = DateFormatYMD(data.updated_at_to);
    }
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
    window.scrollTo({
      top: 0,
    });
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.contact_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.contacts}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.contacts}
        </Heading>

        <Stack spacing="2" direction="row">
          <Button
            colorScheme="primary"
            size="lg"
            onClick={() =>
              history.push(routes.crm.contact.add + '?contactType=owner')
            }>
            {strings.add_new_owner}
          </Button>
          <Button
            colorScheme="primary"
            size="lg"
            onClick={() =>
              history.push(routes.crm.contact.add + '?contactType=guest')
            }>
            {strings.add_new_guest}
          </Button>
        </Stack>
      </Flex>
      <Accordion
        bg="white"
        borderColor="white"
        allowToggle
        shadow="box"
        defaultIndex={[defaultIndex]}>
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <ContactSearch
              isStatus={false}
              isLoading={isLoading}
              handleAdvancedSearch={handleAdvancedSearch}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.label}</Th>
                <Th>{strings.source}</Th>
                <Th>{strings.added}</Th>
                <Th>{strings.last_activities}</Th>
                <Th>{strings.last_ticket}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactList?.data?.data?.map(
                (contact: ContactSchema, index: number) => (
                  <ContactListItem
                    key={contact.id}
                    index={index}
                    contact={contact}
                    startingSN={startingSN}
                    search={search}
                    openCreateOwnerUserModal={(id: number) => {
                      checkCreateOwnerPermission(id);
                    }}
                  />
                )
              )}
              {contactList.isLoading && (
                <TableSkeletonLoader rows={7} cols={8} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={contactList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />

      <Modal
        isOpen={isOpen}
        isCentered
        onClose={closeCreateOwnerUserConfirmationModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.create_user}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              strings.this_will_create_a_user_for_the_owner_are_you_sure_you_want_to_continue
            }
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="primary"
                onClick={() => {
                  createOwnerUserMutation.mutate(idForCreateOwnerUser || 0);
                }}
                isLoading={createOwnerUserMutation.isLoading}>
                {strings.create}
              </Button>

              <Button
                variant="outline"
                onClick={closeCreateOwnerUserConfirmationModal}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ContactList;

import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { formatCurrency } from 'utils';
import { utcToLocal } from 'utils/DateFormat';
import history from 'utils/history';

interface Props {
  pmsCreditCardData: any;
  index: number;
  // payable_type: any;
}
const PMSCreditCardListItem: React.FC<Props> = (props) => {
  const { pmsCreditCardData, index } = props;
  const status: 'pending' | 'confirmed' = pmsCreditCardData?.status;

  const linkStatus:
    | 'service_reservation'
    | 'apartment_reservation'
    | 'ferry_booking'
    | 'payment_link' = pmsCreditCardData?.payable_type;

  const identifyService = (payable_id: number, payable_type: string) => {
    if (payable_type === 'service_reservation') {
      history.push(
        routes.bms.booking.details.replace(':id', payable_id.toString())
      );
    } else if (payable_type === 'apartment_reservation') {
      history.push(
        routes.bms.reservation.details.replace(':id', payable_id.toString())
      );
    } else if (payable_type === 'ferry_booking') {
      history.push(
        routes.bms.ferryBooking.details.replace(':id', payable_id.toString())
      );
    }
  };

  return (
    <Tr key={pmsCreditCardData.id}>
      <Td>{index}</Td>
      <Td>
        <Text
          fontWeight="semibold"
          cursor={
            pmsCreditCardData?.payable_type !== 'payment_link' ? 'pointer' : ''
          }
          onClick={() =>
            identifyService(
              pmsCreditCardData?.payable_id,
              pmsCreditCardData?.payable_type
            )
          }>
          {pmsCreditCardData?.customer_name}
          {pmsCreditCardData?.payable_type !== 'payment_link' && (
            <Tooltip label={strings[linkStatus]} hasArrow>
              <IconButton
                icon={<BiLinkExternal />}
                variant="link"
                aria-label={strings[linkStatus]}
                color="blue.400"
                ml={1}
                w="2"
                size="sm"
              />
            </Tooltip>
          )}
        </Text>
      </Td>
      <Td isNumeric>
        {utcToLocal(pmsCreditCardData?.created_at).replace(' ', ', ') || '-'}
      </Td>
      <Td>{pmsCreditCardData?.payment_method || '-'}</Td>
      <Td isNumeric>{pmsCreditCardData?.card_number || '-'}</Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {strings[status] || '-'}
        </Text>
      </Td>
      <Td isNumeric>{formatCurrency(Number(pmsCreditCardData?.amount))} CHF</Td>
    </Tr>
  );
};

export default PMSCreditCardListItem;

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { KEY_APARTMENT_SEARCH_API } from 'constants/common';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import ReactSelectRoot from '../../../../components/ReactSelectRoot/ReactSelectRoot';

interface Props {
  keyId: string | null;
}

const AddKeyForm: React.FC<Props> = (props) => {
  let { keyId } = props;

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  const { loading: apartmentListLoading, result: apartmentQuery } =
    useWordSearch(`${KEY_APARTMENT_SEARCH_API}?limit=500`);

  useEffect(() => {
    if (keyId) {
      setValue('rfid', keyId);
    }
  }, [keyId, setValue]);

  const apartmentList = apartmentQuery?.data;
  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
  }));

  return (
    <form>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl isInvalid={!!errors?.rfid} isDisabled>
            <FormLabel>{strings.key_id}</FormLabel>
            <Input
              type="text"
              placeholder={strings.id}
              {...register('rfid', {
                required: strings.required,
              })}
              readOnly
            />
            <FormErrorMessage>
              <> {errors?.rfid && errors?.rfid?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.apartmentId} isRequired>
            <FormLabel>{strings.object}</FormLabel>
            <Controller
              control={control}
              name="apartmentId"
              rules={{
                required: strings.required_Object,
              }}
              render={({ field }) => (
                <ReactSelectRoot
                  {...field}
                  id="apartmentId"
                  placeholder={strings.select_apartment}
                  value={field.value}
                  options={apartmentOptions}
                  styles={reactSelectStyles}
                  isLoading={apartmentListLoading}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.apartmentId &&
                (errors.apartmentId as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={[1, 2]}>
          <FormControl isInvalid={!!errors?.description} isRequired>
            <FormLabel>{strings.descriptions}</FormLabel>
            <Textarea
              placeholder={strings.descriptions}
              {...register('description', {
                required: strings.required_descriptions,
                validate: (value) => {
                  if (value?.trim() === '')
                    return strings.required_descriptions;
                },
              })}
            />
            <FormErrorMessage>
              <> {errors?.description && errors?.description?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </form>
  );
};

export default AddKeyForm;

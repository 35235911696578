import {
  getDeviceId,
  registerCustomerToEmployeeDevice,
} from 'api/check-in-socket';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IAppState {
  customerDeviceId?: string;
  customerDeviceName?: string;
  sessionId?: string;
  employeeDeviceName?: string;
}

const defaultState: IAppState = {
  customerDeviceId: undefined,
  employeeDeviceName: '',
  customerDeviceName: '',
  sessionId: undefined,
};

const useCustomerScreenDeviceId = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const employeeDeviceName = atob(searchParams.get('id') as string);
  const customerDeviceName = atob(searchParams.get('secret') as string);

  const [appState, setAppState] = useState<IAppState>({
    ...defaultState,
  });
  const [isPairing, setIsPairing] = useState<boolean>(false);

  const setDevice = (data: any) => {
    localStorage.setItem('CUST:custdname', data.customerDeviceName as string);
    localStorage.setItem('CUST:empdname', data.employeeDeviceName as string);

    setAppState((preState) => {
      return {
        ...preState,
        customerDeviceName: data.customerDeviceName,
        employeeDeviceName: data.employeeDeviceName,
        customerDeviceId: data.customerDeviceId,
      };
    });
  };

  useEffect(() => {
    const custDeviceName = localStorage.getItem('CUST:custdname');
    const empDeviceName = localStorage.getItem('CUST:empdname');
    const sessionId = localStorage.getItem('CUST:sessionId') || undefined;
    if (custDeviceName && empDeviceName) {
      setAppState((preState) => {
        return {
          ...preState,
          customerDeviceName: custDeviceName,
          employeeDeviceName: empDeviceName,
          sessionId: sessionId,
        };
      });
    }
  }, []);

  useEffect(() => {
    const handlePairCreation = async () => {
      setIsPairing(true);
      try {
        if (
          !appState.customerDeviceId &&
          customerDeviceName &&
          employeeDeviceName
        ) {
          const data = await getDeviceId(
            'Customer',
            customerDeviceName,
            employeeDeviceName
          );
          setDevice({
            customerDeviceName,
            employeeDeviceName,
            customerDeviceId: data.id,
          });
          if (data.id) {
            await registerCustomerToEmployeeDevice({
              customerDeviceName,
              employeeDeviceName,
              customerDeviceId: data.id,
            });
          }
        }
      } catch (err: any) {
        console.error(err?.response?.data?.message || err.message);
      } finally {
        setIsPairing(false);
      }
    };

    handlePairCreation();
  }, [customerDeviceName, employeeDeviceName]);

  return { appState, isPairing };
};

export default useCustomerScreenDeviceId;

import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import BMSServiceBookingResource from 'api/bms-service-booking';
import OMSObjectsResource from 'api/oms-objects';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { BMSBooking, ServiceInBookings } from 'constants/schemas/bookings';
import { sendMessage, useSocketContext } from 'context/SocketContext';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  bookingId: number;
}

const CheckInSecondScreenModal = (props: Props) => {
  const { isModalOpen, onModalClose, bookingId } = props;

  const history = useHistory();
  const { dispatch } = useSocketContext();

  const bmsBookingAPI = new BMSBookingResource();
  const bmsServiceBookingAPI = new BMSServiceBookingResource();
  const omsApartmentAPI = new OMSObjectsResource();

  const bookingDetailsQuery = useQuery(
    ['booking-details', bookingId],
    async () => {
      const data = await bmsBookingAPI.get(bookingId);
      return data.data;
    },
    {
      enabled: !!bookingId,
    }
  );
  const bookingDetails: BMSBooking = bookingDetailsQuery?.data?.data;

  const servicesInBookingQuery = useQuery(
    ['services-booking', bookingId],
    async () => {
      const data = await bmsServiceBookingAPI.getServicesInBooking(bookingId);
      return data.data;
    },
    {
      enabled: !!bookingId,
    }
  );
  const servicesInBooking: ServiceInBookings[] =
    servicesInBookingQuery?.data?.data;

  const apartmentDetailQuery = useQuery(
    ['apartment-details', bookingId, bookingDetails?.apartment_id],
    async () => {
      const data = await omsApartmentAPI.get(bookingDetails.apartment_id);
      return data.data;
    },
    {
      enabled: !!bookingDetails?.apartment_id,
    }
  );
  const apartmentCoverImage: string =
    apartmentDetailQuery?.data?.data?.images?.[0]?.media?.url;

  const uniqueServiceGroupsName: string[] = [
    ...new Set(
      servicesInBooking?.map((item: any) => item.service.service_group.name)
    ),
  ];

  const emitMessage = () => {
    if (Object.keys(bookingDetails).length > 0) {
      sendMessage(dispatch, {
        bookingDetails,
        serviceGroupsName: uniqueServiceGroupsName,
        apartmentCoverImage,
      });
    }
  };

  const redirectToBookingOverview = () => {
    onModalClose();
    emitMessage();
    history.push(
      routes.bms.booking.details.replace(
        ':id',
        bookingId.toString() + '?checkin=true'
      )
    );
  };

  const getModalBody = () => {
    if (
      bookingDetailsQuery.isLoading ||
      servicesInBookingQuery.isLoading ||
      apartmentDetailQuery.isLoading
    ) {
      return (
        <ModalBody>
          <Flex height="200px" alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        </ModalBody>
      );
    }

    if (
      bookingDetailsQuery.isError ||
      servicesInBookingQuery.isError ||
      apartmentDetailQuery.isError
    ) {
      return (
        <ModalBody>
          <Flex height="200px" alignItems="center" justifyContent="center">
            {strings.error}
          </Flex>
        </ModalBody>
      );
    }

    return (
      <>
        <ModalBody>
          <Alert status="error">
            <AlertIcon color="#BF3535" />

            <Text
              fontSize={'12px'}
              lineHeight="1.2"
              color="#940A0A"
              textAlign="justify">
              {strings.msg_check_in_check_out_modal}
            </Text>
          </Alert>

          <Text mt="8" pl="4" fontSize="18px" color="#424360">
            {
              strings.should_the_booking_details_of_the_following_guest_be_displayed
            }
          </Text>
          <Text mt="8" pl="4" fontSize="16px" color="#424360" fontWeight={700}>
            {strings.booking_number}: {bookingDetails?.booking_number}&nbsp;
            {bookingDetails?.customer?.last_name},&nbsp;
            {bookingDetails?.customer?.first_name}
          </Text>
        </ModalBody>

        <ModalFooter mt="8">
          <ButtonGroup width="100%">
            <Button
              size="lg"
              color="#7C7D8F"
              borderColor="#D3D3D3"
              type="button"
              variant="outline"
              width="50%"
              fontWeight="700"
              onClick={onModalClose}>
              {strings.abort}
            </Button>

            <Button
              size="lg"
              colorScheme="primary"
              type="button"
              width="50%"
              fontWeight="700"
              onClick={redirectToBookingOverview}>
              {strings.mirror_booking_details}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="xl"
      isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{strings.did_you_choose_the_right_guest}</ModalHeader>
        <ModalCloseButton />
        {getModalBody()}
      </ModalContent>
    </Modal>
  );
};

export default CheckInSecondScreenModal;

import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { Salutation } from 'constants/schema';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Box, FormControl, FormLabel, Grid, GridItem } from '@chakra-ui/react';
interface Props {
  data?: any;
}

const ContactDetailsPreview: React.FC<Props> = (props) => {
  let { data } = props;

  if (!data) return null;

  let {
    v_office_id,
    id,
    number,
    last_name,
    first_name,
    street,
    zip_code,
    location,
    company,
    house_no,
    city,
    country_code,
    country_name,
    language,
    letter_opening,
    letter_closing,
    salutation,
    title,
    birth_date,
    owner,
    phone,
    business_phone,
    mobile,
    fax,
    email,
    in_the,
    homepage,
    type,
    source,
    fellow_travellers,
    pet,
    equipment_features,
    services,
    internal_notes,
    external_notes,
    is_merged,
    created_by,
    updated_by,
    created_at,
    updated_at,
    is_subscribed,
  } = data;

  return (
    <>
      <Grid
        gap="2"
        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.salutation}</FormLabel>
            <Box sx={infoStyles}>
              {strings[salutation?.val as Salutation] ?? '-'}
            </Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.title}</FormLabel>
            <Box sx={infoStyles}>{title?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.first_name}</FormLabel>
            <Box sx={infoStyles}>{first_name?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.last_name}</FormLabel>
            <Box sx={infoStyles}>{last_name?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
            <Box sx={infoStyles}>{street?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.house_no}</FormLabel>
            <Box sx={infoStyles}>{house_no?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.post_code}</FormLabel>
            <Box sx={infoStyles}>{zip_code?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.town}</FormLabel>
            <Box sx={infoStyles}>{city?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.country}</FormLabel>
            <Box sx={infoStyles}> {country_name?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.phone}</FormLabel>
            <Box sx={infoStyles}>{phone?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.mobile}</FormLabel>
            <Box sx={infoStyles}>{mobile?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.email}</FormLabel>
            <Box sx={infoStyles}>{email?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
            <Box sx={infoStyles}>{company?.val ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.newsletter}</FormLabel>
            <Box sx={infoStyles}>
              {is_subscribed?.val ? strings.subscribe : strings.unsubscribe}
            </Box>
          </FormControl>
        </GridItem>
      </Grid>

      <Box>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.notes_notizen}</FormLabel>
          <Box sx={infoStyles}>{internal_notes?.val ?? '-'}</Box>
        </FormControl>
      </Box>
    </>
  );
};

export default ContactDetailsPreview;

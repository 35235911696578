import { strings } from 'config/localization';
/**
 * Utils that are specific to List Info Business Solutions Domain
 */
import {
  Permission,
  RackInput,
  ShelfInput,
  UserPermission,
} from 'constants/interfaces';
import { generateUniqueId, getNextLetter } from 'utils';

/**
 * Get an array combining common permissions from two permission arrays
 *
 * @param permissionArrayOne
 * @param permissionArrayTwo
 * @returns single array having common permissions
 */
export const getCommonPermissions = (
  permissionArrayOne: Permission[],
  permissionArrayTwo: Permission[]
) => {
  return permissionArrayOne?.filter(({ name: id1 }) =>
    permissionArrayTwo?.some(({ name: id2 }) => id2 === id1)
  );
};

/**
 * Check permissions for given route by tallying userPermission with allowedPermissions
 *
 * @param userPermissions : Permissions provided for logged in user
 * @param allowedPermissions
 * @returns true if permitted
 */
export const checkPermissions = (
  userPermissions: UserPermission,
  allowedPermissions: string[]
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  const permissions = [...userPermissions.direct, ...userPermissions.via_role];
  return allowedPermissions.every((allowedPer) =>
    permissions.some((permission: Permission) => allowedPer === permission.name)
  );
};

export const checkSomePermissions = (
  userPermissions: UserPermission,
  allowedPermissions: string[]
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  const permissions = [...userPermissions.direct, ...userPermissions.via_role];
  return permissions.some((permission: any) =>
    allowedPermissions.includes(permission.name)
  );
};

/**
 * Get name for the new shelf (name is the next number higher to the one present)
 * if shelves consists of [2, 3, 4], shelfName will be 5
 *
 * @param shelfList
 * @returns
 */
export const getNewShelfName = (shelfList: ShelfInput[]) => {
  let shelfName: string | number = shelfList?.reduce((prev, current) =>
    parseInt(prev.name) > parseInt(current.name) ? prev : current
  )?.name;
  if (!shelfName) return '';
  shelfName = parseInt(shelfName);
  return ++shelfName;
};

/**
 * Get name for the next rack (name is the alphabet next to the one present)
 * if shelves consists of [B, C, D], rackName will be E
 *
 * @param rackList
 * @returns
 */
export const getNewRackName = (rackList: RackInput[]) => {
  const list = [...rackList];
  const highestLetter = list
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    ?.pop()?.name;
  if (!highestLetter) return '';
  return getNextLetter(highestLetter);
};

/**
 * Get default room data for a warehouse when the warehouse is set to add
 * Default data consists of a room (with locally generated id) with a rack
 * inside the room and a shelf inside the rack
 *
 * @returns array consisting of single element (default room data)
 */
export const getDefaultRoomData = () => {
  return [
    {
      generatedId: generateUniqueId(),
      name: `${strings.enter_room_name}`,
      racks: [
        {
          name: 'A',
          shelves: [
            {
              name: '1',
            },
          ],
        },
      ],
    },
  ];
};

/**
 * Removes unrequired properties from room, rack and shelf from
 * room data when the warehouse is set to edit
 *
 * @param editData warehouse detail data set to edit
 * @returns rooms array
 */
export const getRoomDataFromEditData = (editData: any) => {
  // Remove created_by, updated_by, created_at, updated_at from each room, rack and shelf
  // Add a locally generatedId to each room
  const rooms = editData.rooms.map(
    ({ created_by, updated_by, created_at, updated_at, ...roomRest }: any) => {
      roomRest.racks = roomRest.racks.map(
        ({
          created_by,
          updated_by,
          created_at,
          updated_at,
          ...rackRest
        }: any) => {
          rackRest.shelves = rackRest.shelves.map(
            ({
              created_by,
              updated_by,
              created_at,
              updated_at,
              ...shelfRest
            }: any) => shelfRest
          );
          return rackRest;
        }
      );
      roomRest.generatedId = generateUniqueId();
      return roomRest;
    }
  );
  return rooms;
};

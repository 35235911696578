import {
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getChatUserDetail } from 'api/chat';
import { wrapperStyles } from 'assets/css/commonStyles';
import ChatMessageDetails from 'components/chat/ChatMessageDetails';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { isSameDay } from 'utils';
import { ChatDateCategoryFormat, DateTimeFormat } from 'utils/DateFormat';
import history from 'utils/history';

const ClosedChatDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const [scrollEl, setScrollEl] = useState<any>();
  const { search } = useLocation();

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [scrollEl]);

  const {
    isLoading,
    data: closedChatDetails,
    isError,
  } = useQuery(
    ['chatDetail', id],
    () => getChatUserDetail(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      retry: false,
    }
  );

  let messageTimeCategoryRef = useRef<any>();
  messageTimeCategoryRef.current = closedChatDetails?.messages?.[0].time;

  if (isError) {
    history.push(routes.chat.closed.list);
  }

  if (isLoading) return <CenterSpinner />;

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.chat.default}>
            {strings.chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.chat.closed.list + search}>
            {strings.closed_chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {strings.chat_details}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="lg" textTransform="capitalize">
        {strings.chat_details}
      </Heading>
      <Stack direction="column" flex="1" spacing="4" pos="relative">
        <Stack sx={wrapperStyles}>
          <Stack direction="row" spacing="4">
            <Avatar
              size="md"
              name={closedChatDetails?.name}
              bg="gray.400"
              color="white"
            />
            <Stack direction="column" alignSelf="center">
              <Heading size="sm" fontWeight="normal">
                {closedChatDetails?.name}
              </Heading>
            </Stack>
            <Spacer />
            <Stack direction="row" alignSelf="center">
              <Text>
                {DateTimeFormat(closedChatDetails.close_archive_date)}{' '}
                {strings.hours}
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          pos="relative"
          bg="white"
          shadow="box"
          rounded="md"
          maxH="65vh"
          p="1"
          overflow="auto">
          <PerfectScrollbar
            containerRef={(ref: any) => {
              setScrollEl(ref);
            }}>
            <Stack p="4">
              {closedChatDetails?.messages.length > 0 && (
                <Stack
                  justifyContent="center"
                  direction="row"
                  className="message-date-header"
                  whiteSpace="nowrap">
                  <Text>
                    {ChatDateCategoryFormat(messageTimeCategoryRef.current)}
                  </Text>
                </Stack>
              )}
              {closedChatDetails?.messages?.map((message: any) => {
                let showMessageDateHeaderFlag: 0 | 1 = 0;
                // update messageTimeRef to next day if day doesn't match and set flag to 1
                if (!isSameDay(messageTimeCategoryRef.current, message.time)) {
                  showMessageDateHeaderFlag = 1;
                  messageTimeCategoryRef.current = message.time;
                }
                return (
                  <ChatMessageDetails
                    message={message}
                    key={message.sender}
                    showMessageDateHeaderFlag={showMessageDateHeaderFlag}
                  />
                );
              })}
            </Stack>
          </PerfectScrollbar>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ClosedChatDetails;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ContactCenterUserListItem from 'components/contact-center/ContactCenterUserListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { FeedbackSchema } from 'constants/schema';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  start_date: Date | null;
  end_date: Date | null;
}
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const ContactCenterUserList: React.FC = () => {
  const feedbackResourceAPI = new FeedbackResource();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];
  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.start_date ? new Date(searchValues.start_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.end_date ? new Date(searchValues.end_date) : null
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    start_date: startDate,
    end_date: endDate,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      start_date: DateFormatYMD(start),
      end_date: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const queryList = useQuery(
    [
      `contactCenterUserList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_name: filterParams.keyword,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword)
        queryParams.customer_name = filterParams.keyword;
      if (startDate)
        queryParams.last_email_sent_at_from = DateFormatYMD(startDate);
      if (endDate) queryParams.last_email_sent_at_to = DateFormatYMD(endDate);
      return await feedbackResourceAPI
        .getFeedbackList(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const {
    data: contactCenterUserQuery,
    isLoading: contactCenterUserLoading,
    error: contactCenterUserError,
  } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      start_date: null,
      end_date: null,
    }));
    history.push(routes.contactCenter.members.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.list}>
            {strings.contact_center}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.user}
        </Heading>
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form>
                <Stack direction="column" spacing="4">
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <Grid
                      gap={['4', '4']}
                      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.search}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BiSearch />}
                              color="gray.400"
                            />
                            <Input
                              type="text"
                              name="keyword"
                              placeholder={strings.search}
                              value={filterParams.keyword}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.date}</FormLabel>
                          <InputGroup>
                            <InputRightElement
                              pointerEvents="none"
                              children={<BiCalendar />}
                              color="gray.400"
                            />
                            <ReactDatePickerRoot
                              placeholderText={strings.select_date}
                              dateFormat="yyyy-MM-dd"
                              customInput={<CreatedAtInput />}
                              onChange={handleDateChange}
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              value={getFormattedRangeDate(startDate, endDate)}
                              shouldCloseOnSelect={false}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Stack direction="row" spacing="4">
                      <Button
                        colorScheme="primary"
                        type="button"
                        size="lg"
                        variant="outline"
                        onClick={() => handleReset()}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.aircall_user_name}</Th>
                <Th>{strings.total_incoming_call}</Th>
                <Th>{strings.total_call_duration}</Th>
                <Th>{strings.total_outgoing_call}</Th>
                <Th>{strings.outgoing_call_duration}</Th>
                <Th>{strings.average}</Th>
                <Th>{strings.total_duration}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactCenterUserQuery?.data?.map(
                (contactCenterUserListItem: FeedbackSchema, index: number) => (
                  <ContactCenterUserListItem
                    data={contactCenterUserListItem}
                    key={contactCenterUserListItem.reservation_id}
                    index={startingSN + index}
                    search={search}
                  />
                )
              )}
            </Tbody>
            {contactCenterUserLoading && (
              <TableSkeletonLoader rows={filterParams.pageSize} cols={9} />
            )}
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default ContactCenterUserList;

import Resource from 'api/resource';
import axios, { AxiosPromise } from 'axios';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;

class UserResource extends Resource {
  constructor() {
    super('user/users');
  }

  permissions(id: any) {
    return http({
      url: '/' + this.uri + '/' + id + '/permissions',
      method: 'get',
    });
  }

  updatePermission(id: any, permissions: any) {
    return http({
      url: '/' + this.uri + '/' + id + '/permissions',
      method: 'put',
      data: permissions,
    });
  }
  list(query?: any): AxiosPromise<any> {
    return http({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }

  /**
   * Upload profile
   */
  uploadProfile(data: any) {
    return http({
      url: `/user/profile-picture`,
      method: 'post',
      data: data,
    });
  }
  /**
   * Delete profile
   */

  deleteProfile() {
    return http({
      url: '/user/profile-picture',
      method: 'delete',
    });
  }

  loginLogList(query: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: `/user/user-logs`,
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  generateQRCode() {
    return http({
      url: '/user/generate-2fa',
      method: 'post',
    });
  }

  enable2FA(data: any) {
    return http({
      url: '/user/enable-2fa',
      method: 'post',
      data: data,
    });
  }

  disable2FA(data: any) {
    return http({
      url: '/user/disable-2fa',
      method: 'post',
      data: data,
    });
  }
}

export { UserResource as default };

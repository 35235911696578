import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { changeURL } from 'utils';
import SeasonPriceListItems from './SeasonPriceListItems';

interface Props {
  data?: any;
  editing: boolean;
}

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  status: string;
}

const SeasonPricesForm: React.FC<Props> = (props) => {
  const { editing, data } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    status: searchValues.status ?? '',
  });

  let seasonAPI = new OMSObjectsResource();
  const updatePrices = useMutation((fromdata: any) =>
    seasonAPI.updateSeasonPrices(data.id, fromdata)
  );
  const seasonsList = useQuery(
    `season-list`,
    () =>
      seasonAPI
        .listSeasonPrices(data.id, {
          limit: '30',
        })
        .then((res: any) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    let feat = Object.entries(data).map((item: any) => {
      let [key, value] = item;

      return { season_id: key, ...value };
    });
    let formatted = feat.filter((item: any) => item.price);
    let formData = { prices: formatted };

    updatePrices.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(`season-list`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: (e: any) => {
        let msg = e.response.data.message;
        let defaultMessage = `${strings.apartment} ${strings.has_not_been_updated}`;

        setErrMsg(msg ?? defaultMessage);
        setIsLoading(false);
      },
    });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="column" spacing="8">
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <Stack direction="row" spacing="8">
            <Stack spacing="8">
              <Heading color="green.600" size="md" textTransform="capitalize">
                {strings.season_and_prices}
              </Heading>
              <Text color="black">{strings.please_assign_price}</Text>
            </Stack>
          </Stack>
          <Stack sx={wrapperStyles}>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{strings.season_name}</Th>
                    <Th>{strings.from_date}</Th>
                    <Th>{strings.to_date}</Th>
                    <Th>{`${strings.price} (CHF)`}</Th>
                    <Th>{strings.assigned}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {seasonsList?.data?.data?.map((data: any, index: number) => (
                    <SeasonPriceListItems
                      key={data.id}
                      data={data}
                      editing={editing}
                    />
                  ))}
                </Tbody>
                {seasonsList.isLoading && (
                  <TableSkeletonLoader rows={6} cols={9} />
                )}
              </Table>
            </TableContainer>
          </Stack>

          <Pagination
            dataList={seasonsList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          {editing && (
            <ButtonGroup alignSelf="end">
              <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                {strings.save}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SeasonPricesForm;

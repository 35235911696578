import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ServiceGroupResource from 'api/sms_service_groups';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ServiceGroupSchema } from 'constants/schema';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { formatCurrency } from 'utils/index';

interface Props {
  serviceGroupData: ServiceGroupSchema;
  index: number;
  draggableProvider: any;
  search?: any;
}
const ServiceGroupListItem: React.FC<Props> = (props) => {
  const { serviceGroupData, index, draggableProvider, search } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const serviceGroupAPI = new ServiceGroupResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const checkEditPermission = useIsPermitted([PermissionRequest['manage:sms']]);

  const deleteServiceGroup = useMutation(
    () => serviceGroupAPI.destroy(serviceGroupData.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('serviceGroupList');
        setDeleteModalOpen(false);
        toast({
          title: strings.service_group_deleted,
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.message);
      },
    }
  );

  const updateServiceGroup = useMutation(
    (data: any) => serviceGroupAPI.update(serviceGroupData.id, data),
    {
      onSuccess: () => {
        toast({
          title: strings.status_updated,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    if (!checkEditPermission) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteServiceGroup.mutate();
  };

  /**
   * Handle SG status changes
   */
  const handleServiceGroupStatus = async (e: any) => {
    const formData = new FormData();
    formData.append('status', e.target.checked ? '1' : '0');
    formData.append('_method', 'PUT');

    updateServiceGroup.mutate(formData);
  };

  return (
    <Tr
      key={serviceGroupData.id}
      ref={draggableProvider.innerRef}
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.sms.serviceGroups.details.replace(
              ':id',
              serviceGroupData.id.toString()
            ) + search
          }>
          <HStack>
            <Avatar size="sm" rounded="full" src={serviceGroupData?.cover} />
            <Text fontWeight="semibold">{serviceGroupData.name}</Text>
          </HStack>
        </RouterLink>
      </Td>
      <Td>
        {serviceGroupData.booking_fee_type
          ? strings.getString(
              serviceGroupData.booking_fee_type
                .toLowerCase()
                .split(' ')
                .join('_')
            )
          : '-'}
      </Td>
      <Td>{formatCurrency(serviceGroupData.booking_fee)}</Td>
      <Td>
        {serviceGroupData.service_per_night
          ? strings.per_night
          : strings.per_day}
      </Td>
      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="green"
            onChange={handleServiceGroupStatus}
            defaultChecked={serviceGroupData.status}
            isDisabled={!checkEditPermission}
          />
        </FormControl>
      </Td>

      <Td>
        <RouterLink
          to={
            routes.sms.serviceGroups.details.replace(
              ':id',
              serviceGroupData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_service_group}>
            <IconButton
              icon={<BiShow size="18" />}
              variant="link"
              aria-label={strings.view_service_group}
              color="green.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={
            routes.sms.serviceGroups.edit.replace(
              ':id',
              serviceGroupData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.edit_service_group}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_service_group}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_service_group}>
          <IconButton
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete_service_group}
            color="red.300"
            minW="8"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_service_group}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteServiceGroup.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ServiceGroupListItem;

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import { DMS_ACCEPT_FILE_TYPE } from 'constants/common';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import { DateFormatYMD } from 'utils/DateFormat';

interface Props {
  generalDocumentData?: any;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const GeneralDocumentForm: React.FC<Props> = (props) => {
  const { generalDocumentData } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    getValues,
  } = useFormContext<any>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!generalDocumentData) return;
    setValue(
      'available_from_date',
      generalDocumentData && generalDocumentData?.available_from_date
    );
    setValue(
      'available_to_date',
      generalDocumentData && generalDocumentData?.available_to_date
    );
    if (generalDocumentData?.available_from_date) {
      let startD = new Date(generalDocumentData?.available_from_date);
      setStartDate(startD);
    }
    if (generalDocumentData?.available_to_date) {
      let endD = new Date(generalDocumentData?.available_to_date);
      setEndDate(endD);
    }
  }, [generalDocumentData, setValue]);

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setValue('available_from_date', DateFormatYMD(start));
    setValue('available_to_date', DateFormatYMD(end));
  };

  return (
    <form>
      <Stack direction="column" spacing="4">
        {!generalDocumentData && (
          <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
            <GridItem>
              <FormControl isInvalid={!!errors?.upload_files} isRequired={true}>
                <FormLabel>{strings.upload_files}</FormLabel>
                <Uploader
                  fileKey="upload_files"
                  uploadedFiles={generalDocumentData?.upload_files}
                  acceptFileType={DMS_ACCEPT_FILE_TYPE}
                  maxFiles={1}
                  clearErrors={clearErrors}
                  multiple={false}
                />
                <FormErrorMessage>
                  <>{errors?.upload_files && errors?.upload_files?.message}</>
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        )}
        <Grid gap="4" templateColumns="repeat(2, 1fr)" flex="1" w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.document_name}</FormLabel>
              <Input
                type="text"
                defaultValue={generalDocumentData?.name}
                placeholder={strings.document_name}
                {...register('name', {
                  required: strings.required_document_name,
                })}
              />
              <FormErrorMessage>
                <>{errors?.name && errors?.name?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.availability}</FormLabel>
              <Controller
                control={control}
                name="availability"
                render={({ field: { onChange, value } }) => (
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CreatedAtInput />}
                      onChange={(date: any) => handleDateChange(date)}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                      portalId="root-popover"
                    />
                  </InputGroup>
                )}
              />
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default GeneralDocumentForm;

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { createPair, deletePair, getDeviceId } from 'api/check-in-socket';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { useEmployeeDeviceIdPairContext } from 'context/EmployeeDeviceIdPairContext';
import { resetSocket, useSocketContext } from 'context/SocketContext';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

// employeeDeviceName refers to ID and customerDeviceName refers to Secret key in the UI
const SecondScreenSocketConnection: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { appState, setAppState, isPairInfoLoading } =
    useEmployeeDeviceIdPairContext();
  const { dispatch, state } = useSocketContext();

  const unpairDisclosure = useDisclosure();
  const queryClient = useQueryClient();

  const [url, setUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const unpairMutation = useMutation(
    async () => {
      await deletePair();
    },
    {
      onSuccess: () => {
        setAppState({
          employeeDeviceName: '',
          customerDeviceName: '',
        });
        state.socket?.disconnect();
        resetSocket(dispatch);

        queryClient.invalidateQueries('pair-info');
        toast({
          title: strings.disconnected,
          description: strings.disconnected_from_second_screen,
          status: 'success',
        });
        unpairDisclosure.onClose();
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const startConnection = (data: any) => {
    setAppState((preState) => {
      return {
        ...preState,
        customerDeviceName: data.customerDeviceName,
        employeeDeviceName: data.employeeDeviceName,
        employeeDeviceId: data.employeeDeviceId,
      };
    });

    setUrl(
      `${process.env.REACT_APP_SECOND_SCREEN_URL}?id=${btoa(
        data.employeeDeviceName
      )}&secret=${btoa(data.customerDeviceName)}`
    );

    dispatch({
      type: 'INITIALIZE',
      payload: {
        type: 'Employee',
        deviceName: data.employeeDeviceName,
        deviceId: data.employeeDeviceId,
      },
    });
  };

  useEffect(() => {
    if (appState?.customerDeviceName && appState?.employeeDeviceName) {
      setValue('employeeDeviceName', appState.employeeDeviceName);
      setValue('customerDeviceName', appState.customerDeviceName);
      setUrl(
        `${process.env.REACT_APP_SECOND_SCREEN_URL}?id=${btoa(
          appState.employeeDeviceName
        )}&secret=${btoa(appState.customerDeviceName)}`
      );
    } else {
      setValue('employeeDeviceName', '');
      setValue('customerDeviceName', '');
    }
    return () => setUrl('');
  }, [appState, setValue]);

  const onConfirmUnpair = () => {
    unpairMutation.mutate();
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const res = await getDeviceId(
        'Employee',
        data.customerDeviceName,
        data.employeeDeviceName
      );
      const postPayload = { ...data, employeeDeviceId: res.id };

      if (res.id) {
        await createPair(postPayload);
        startConnection(postPayload);
      }
      toast({
        title: strings.pair_created,
        status: 'success',
        isClosable: true,
      });
    } catch (err: any) {
      toast({
        title: strings.error,
        description: err?.response?.data?.message || err.message,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isPairInfoLoading) {
    return <CenterSpinner />;
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.profile}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.profile.socket}>
            {strings.customer_screen}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.customer_screen}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing="4">
            <FormControl isInvalid={!!errors.employeeDeviceName}>
              <FormLabel>{strings.id}</FormLabel>
              <InputGroup>
                <Input
                  isDisabled={!!appState.employeeDeviceName}
                  type="text"
                  placeholder={strings.id}
                  size="lg"
                  maxLength={8}
                  {...register('employeeDeviceName', {
                    required: strings.required,
                    validate: (value: string) => {
                      if (value && value.length < 8) {
                        return strings.must_be_8_characters_long;
                      }
                      return true;
                    },
                  })}
                />
                {!appState.employeeDeviceName && (
                  <Button
                    variant="outline"
                    colorScheme="primary"
                    size="lg"
                    onClick={() => setValue('employeeDeviceName', nanoid(8))}
                    ml="3">
                    {strings.generate}
                  </Button>
                )}
              </InputGroup>
              <FormErrorMessage>
                <>
                  {errors?.employeeDeviceName &&
                    errors.employeeDeviceName?.message}
                </>
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.customerDeviceName}>
              <FormLabel>{strings.secret_key}</FormLabel>
              <InputGroup>
                <Input
                  isDisabled={!!appState.customerDeviceName}
                  type="text"
                  placeholder={strings.secret_key}
                  size="lg"
                  maxLength={8}
                  {...register('customerDeviceName', {
                    required: strings.required,
                    validate: (value: string) => {
                      if (value && value.length < 8) {
                        return strings.must_be_8_characters_long;
                      }
                      return true;
                    },
                  })}
                />
                {!appState.customerDeviceName && (
                  <Button
                    variant="outline"
                    colorScheme="primary"
                    size="lg"
                    onClick={() => setValue('customerDeviceName', nanoid(8))}
                    ml="3">
                    {strings.generate}
                  </Button>
                )}
              </InputGroup>
              <FormErrorMessage>
                <>
                  {errors?.customerDeviceName &&
                    errors.customerDeviceName?.message}
                </>
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel sx={labelStyles}>{strings.link}</FormLabel>
              <Box sx={infoStyles}>{url || '-'}</Box>
            </FormControl>
          </Stack>

          <Stack direction="row" justify="flex-end" mt="6">
            {!!appState.customerDeviceName && !!appState.employeeDeviceName ? (
              <Button
                colorScheme="red"
                onClick={unpairDisclosure.onOpen}
                size="lg"
                isLoading={isLoading}>
                {strings.unpair}
              </Button>
            ) : (
              <Button
                type="submit"
                colorScheme="primary"
                size="lg"
                isLoading={isLoading}>
                {strings.create_pair}
              </Button>
            )}
          </Stack>
        </form>
      </Stack>
      <Modal
        isOpen={unpairDisclosure.isOpen}
        isCentered
        onClose={unpairDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.unpair}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {strings.this_will_unpair_the_device_are_you_sure}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                isLoading={unpairMutation.isLoading}
                isDisabled={unpairMutation.isLoading}
                onClick={onConfirmUnpair}>
                {strings.unpair}
              </Button>
              <Button
                variant="outline"
                isDisabled={unpairMutation.isLoading}
                onClick={unpairDisclosure.onClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default SecondScreenSocketConnection;

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { formatCurrency } from 'utils/index';

interface Props {
  serviceGroupData: any;
  search: string;
}

const ServiceGroupInfo: React.FC<Props> = (props) => {
  const { serviceGroupData, search } = props;

  const checkAddServiceGroupPermission = useCheckPermission(
    [PermissionRequest['manage:sms']],
    routes.sms.serviceGroups.edit.replace(
      ':id',
      serviceGroupData.id.toString()
    ) + search
  );

  return (
    <>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.service_group_details}
        </Heading>
        <Stack direction="row" spacing="6">
          <Button
            size="lg"
            colorScheme="primary"
            onClick={checkAddServiceGroupPermission}>
            {strings.edit_service_group}
          </Button>
        </Stack>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.service_name}</FormLabel>
              <Box sx={infoStyles}>{serviceGroupData?.name}</Box>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.frontend_color}</FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData.color
                  ? strings.getString(
                      serviceGroupData.color.toLowerCase().split(' ').join('_')
                    )
                  : '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.booking_fee_calculation}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData.booking_fee_type
                  ? strings.getString(
                      serviceGroupData.booking_fee_type
                        .toLowerCase()
                        .split(' ')
                        .join('_')
                    )
                  : '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.booking_fee} (CHF)
              </FormLabel>
              <Box sx={infoStyles}>
                {formatCurrency(serviceGroupData?.booking_fee)}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid pt="2" gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
              <Box sx={infoStyles}>{serviceGroupData?.description || '-'}</Box>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid
          pt="2"
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.show_on_landing_page}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.show_on_page
                  ? strings.yes
                  : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.default_service}</FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.is_default ? strings.yes : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          pt="2"
          gap="4"
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
          ]}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.icon}</FormLabel>
              <Box sx={infoStyles}>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={serviceGroupData?.icon}
                  alt={strings.icon}
                />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.cover_image}</FormLabel>
              <Box sx={infoStyles}>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={serviceGroupData?.cover}
                  alt={strings.icon}
                />
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default ServiceGroupInfo;

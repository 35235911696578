import { forwardRef } from '@chakra-ui/react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

const ReactSelectRoot = forwardRef<ReactSelectProps, 'select'>((props, ref) => {
  return (
    <ReactSelect
      menuPortalTarget={document.getElementById('root-popover')}
      {...props}
      ref={ref}
    />
  );
});
ReactSelectRoot.displayName = 'ReactSelectRoot';
export default ReactSelectRoot;

// import { LogoWithText } from 'assets/images';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { forgotPassword } from 'api/auth';
import LogoInformation from 'components/auth/LogoInformation';
import LanguageMenu from 'components/common/LanguageMenu';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiEnvelope } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { validEmail } from 'utils/validate';

const ForgotPassword: React.FC = () => {
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm();
  const forgotMutation = useMutation((email) => forgotPassword(email), {
    onSuccess: (res: any) => {
      reset();
      toast({
        title: strings.msg_password_reset_link,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onSubmit = (data: any) => {
    forgotMutation.mutate(data);
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Stack pos="absolute" right="5%">
        <LanguageMenu />
      </Stack>
      <Stack direction="column" spacing="12" alignItems="center" py="6">
        <LogoInformation />
        <Box bg="white" p="14" shadow="box" rounded="sm" maxW="2xl">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="8">
              <Stack direction="column" spacing="4">
                <Heading as="h1" size="xl">
                  {strings.forgot_password}
                </Heading>
                <Text color="gray.500">{strings.forgot_message}</Text>
                {forgotMutation.isError && (
                  <ScaleFade in={forgotMutation.isError}>
                    <Alert status="error">
                      <AlertIcon />
                      {strings.email_not_found}
                    </Alert>
                  </ScaleFade>
                )}
              </Stack>

              <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                <FormLabel color="gray.600">{strings.email}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon color="gray.300" w="4" h="4" as={BiEnvelope} />
                    }
                  />
                  <Input
                    id="email"
                    type="email"
                    placeholder={strings.your_email_address}
                    {...register('email', {
                      required: strings.email_required,
                      validate: (value) =>
                        validEmail(value) || strings.valid_email_address,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <>{errors.email && errors.email?.message}</>
                </FormErrorMessage>
              </FormControl>
              <VStack spacing="6">
                <Button
                  w="full"
                  type="submit"
                  size="lg"
                  fontSize="md"
                  fontWeight="semibold"
                  colorScheme="primary"
                  textTransform="uppercase"
                  boxShadow="md"
                  isLoading={forgotMutation.isLoading}>
                  {strings.password_reset}
                </Button>
                <Link to={routes.auth.login}>
                  <Button
                    variant="link"
                    color="gray.600"
                    fontWeight="medium"
                    leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                    {strings.back_to_login}
                  </Button>
                </Link>
              </VStack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default ForgotPassword;

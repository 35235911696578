import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  RadioGroup,
  Select,
  Stack,
  Textarea,
  useRadioGroup,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import RoundCustomRadio from 'components/common/RoundCustomRadio';
import { strings } from 'config/localization';
import { BOOKING_FEE_TYPE_OPTIONS } from 'constants/common';
import { ServiceGroupSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const COLOR_OPTIONS = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
];
interface Props {
  serviceGroupData?: ServiceGroupSchema;
}

const ServiceGroupForm: React.FC<Props> = (props) => {
  let { serviceGroupData } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<ServiceGroupSchema>();

  useEffect(() => {
    if (!serviceGroupData) return;
    setValue('show_on_page', serviceGroupData?.show_on_page ? '1' : '0');
    setValue('is_default', serviceGroupData?.is_default ? '1' : '0');
  }, [serviceGroupData, setValue]);

  const handleShowOnLanding = (value: any) => {
    setValue('show_on_page', value);
  };

  const handleIsDefault = (value: any) => {
    setValue('is_default', value);
  };

  const {
    getRootProps: getRootPropsShowOnLanding,
    getRadioProps: getRadioPropsShowOnLanding,
  } = useRadioGroup({
    name: 'SchemeTypeRadioShowOnLanding',
    defaultValue: serviceGroupData?.show_on_page ? '1' : '0',
    onChange: handleShowOnLanding,
  });

  const groupShowOnLanding = getRootPropsShowOnLanding();

  const {
    getRootProps: getRootPropsIsDefault,
    getRadioProps: getRadioPropsIsDefault,
  } = useRadioGroup({
    name: 'SchemeTypeRadioIsDefault',
    defaultValue: serviceGroupData?.is_default ? '1' : '0',
    onChange: handleIsDefault,
  });

  const groupIsDefault = getRootPropsIsDefault();

  return (
    <form>
      <Stack direction="column" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.service_name}</FormLabel>
              <Input
                size="lg"
                type="text"
                defaultValue={serviceGroupData?.name}
                placeholder={strings.service_name}
                {...register('name', {
                  required: strings.required_service_name,
                })}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.color} isRequired>
              <FormLabel>{strings.frontend_color}</FormLabel>
              <Select
                placeholder={strings.select}
                rounded="sm"
                size="lg"
                defaultValue={serviceGroupData?.color}
                {...register('color', {
                  required: strings.required_front_color,
                })}>
                {COLOR_OPTIONS?.map((type: any) => {
                  return (
                    <option key={type.value} value={type.value}>
                      {strings.getString(
                        type.label.toLowerCase().split(' ').join('_')
                      )}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormErrorMessage>
              {errors?.color && errors?.color?.message}
            </FormErrorMessage>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.booking_fee} isRequired>
              <FormLabel>{strings.booking_fee} (CHF)</FormLabel>
              <Input
                size="lg"
                type="number"
                defaultValue={serviceGroupData?.booking_fee}
                placeholder={strings.booking_fee}
                {...register('booking_fee', {
                  required: strings.required_booking_type,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.booking_fee && errors?.booking_fee?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.booking_fee_type} isRequired>
              <FormLabel>{strings.booking_fee_calculation}</FormLabel>
              <Select
                size="lg"
                placeholder={strings.select_calculation}
                rounded="sm"
                defaultValue={serviceGroupData?.booking_fee_type}
                {...register('booking_fee_type', {
                  required: strings.required_booking_fee,
                })}>
                {BOOKING_FEE_TYPE_OPTIONS?.map((type: any) => {
                  const optionLabel: 'one_time' | 'per_day' = type.text;
                  return (
                    <option key={type.value} value={type.value}>
                      {strings[optionLabel]}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.booking_fee_type && errors?.booking_fee_type?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.description} isRequired>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                defaultValue={serviceGroupData?.description}
                placeholder={strings.description}
                {...register('description', {
                  required: strings.required_descriptions,
                })}
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid
          gap="4"
          templateColumns="repeat(2, 1fr)"
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <FormControl isInvalid={!!errors?.show_on_page}>
              <FormLabel>{strings.show_on_landing_page}</FormLabel>
              <Controller
                name="show_on_page"
                rules={{
                  required: strings.required,
                }}
                defaultValue="0"
                control={control}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field} id="show_on_page">
                      <Flex {...groupShowOnLanding} my="2">
                        <RoundCustomRadio
                          type={'radioNav'}
                          {...getRadioPropsShowOnLanding({ value: '1' })}>
                          {strings.yes}
                        </RoundCustomRadio>
                        <RoundCustomRadio
                          type={'radioNav'}
                          {...getRadioPropsShowOnLanding({ value: '0' })}>
                          {strings.no}
                        </RoundCustomRadio>
                      </Flex>
                    </RadioGroup>
                  );
                }}
              />
              <FormErrorMessage>
                {errors?.show_on_page && errors?.show_on_page?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.is_default}>
              <FormLabel>{strings.default_service}</FormLabel>
              <Controller
                name="is_default"
                rules={{
                  required: strings.required,
                }}
                defaultValue="0"
                control={control}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field} id="is_default">
                      <Flex {...groupIsDefault} my="2">
                        <RoundCustomRadio
                          type={'radioNav'}
                          {...getRadioPropsIsDefault({ value: '1' })}>
                          {strings.yes}
                        </RoundCustomRadio>
                        <RoundCustomRadio
                          type={'radioNav'}
                          {...getRadioPropsIsDefault({ value: '0' })}>
                          {strings.no}
                        </RoundCustomRadio>
                      </Flex>
                    </RadioGroup>
                  );
                }}
              />
              <FormErrorMessage>
                {errors?.is_default && errors?.is_default?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.icon} isRequired>
              <FormLabel>{strings.icon}</FormLabel>
              <ImageUploader
                fileKey="icon"
                required={true}
                accept={['image/png', 'image/svg+xml']}
                uploadedFiles={serviceGroupData?.icon}
                description={strings.upload_image + ' ( .png / .svg )'}
                title={strings.drag_icon_or_click_to_select_icon}
              />
              <FormErrorMessage>
                {errors.icon && errors.icon?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.cover} isRequired>
              <FormLabel>{strings.cover_image}</FormLabel>
              <ImageUploader
                fileKey="cover"
                accept="image/*"
                uploadedFiles={serviceGroupData?.cover}
                description={strings.upload_image + ' ( .png / .svg / .jpg )'}
                title={strings.drag_image_or_click_to_select_image}
              />
              <FormErrorMessage>
                {errors.cover && errors.cover?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default ServiceGroupForm;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackQuestionResource from 'api/feedback_question';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import QuestionListItem from 'components/feedback/QuestionsListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { v4 as uuidv4 } from 'uuid';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  status: string;
}

const QuestionsList: React.FC = () => {
  const feedbackQuestionAPI = new FeedbackQuestionResource();
  const queryClient = useQueryClient();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    status: searchValues.status ?? '',
  });

  const queryList = useQuery(
    [
      `feedbackQuestionsList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        type: filterParams.type,
        status: filterParams.status,
      },
    ],
    () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.type) queryParams.type = filterParams.type;
      if (filterParams.status) queryParams.status = filterParams.status;
      return feedbackQuestionAPI
        .list(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      type: '',
      status: '',
    }));
    history.push(routes.feedback.questions.list);
  };

  /**
   * Handle filter params submit
   */

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination?.index === source?.index) return;
    const sourceId = queryList?.data?.data?.[source?.index].serial;
    const destinationId = queryList?.data?.data?.[destination?.index].serial;

    await feedbackQuestionAPI.updateSerialNumber(sourceId, destinationId);
    await queryClient.invalidateQueries('feedbackQuestionsList');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
              {strings.feedback}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.feedback.questions.list}>
              {strings.questions}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg">{strings.questions}</Heading>
          <RouterLink to={routes.feedback.questions.add}>
            <Button colorScheme="primary" size="lg">
              {strings.add_new_question}
            </Button>
          </RouterLink>
        </Flex>

        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form>
                  <Stack direction="column" spacing="4">
                    <Stack
                      direction={['column', 'row']}
                      spacing="4"
                      align={['start', 'end']}>
                      <Grid
                        gap={['4', '4', '6']}
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(3, 1fr)',
                        ]}
                        w="100%">
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.search}</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                h="100%"
                                pointerEvents="none"
                                children={<BiSearch />}
                                color="gray.500"
                              />
                              <Input
                                size="lg"
                                placeholder={strings.search}
                                name="keyword"
                                value={filterParams.keyword}
                                type="text"
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.select_type_art}</FormLabel>
                            <Select
                              size="lg"
                              name="type"
                              value={filterParams.type}
                              onChange={handleInputChange}
                              placeholder={strings.select_type_art}
                              rounded="sm">
                              <option value="open">{strings.open_ended}</option>
                              <option value="closed">
                                {strings.closed_ended}
                              </option>
                              <option value="rating">{strings.rating}</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.status}</FormLabel>
                            <Select
                              size="lg"
                              name="status"
                              placeholder={strings.select_status}
                              value={filterParams.status}
                              onChange={handleInputChange}
                              rounded="sm">
                              <option value="1">{strings.active}</option>
                              <option value="0">{strings.deactivated}</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </Grid>

                      <Button
                        size="lg"
                        colorScheme="primary"
                        type="button"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.title}</Th>
                  <Th>{strings.select_type_art}</Th>
                  <Th>{strings.status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="feedbackQuestionList" key={uuidv4()}>
                  {(provided: any) => (
                    <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {!queryList.isLoading &&
                        queryList?.data?.data?.map(
                          (feedbackQuestion: any, index: number) => (
                            <Draggable
                              draggableId={feedbackQuestion.id.toString()}
                              key={feedbackQuestion.id}
                              index={index}>
                              {(provided: any) => (
                                <>
                                  <QuestionListItem
                                    data={feedbackQuestion}
                                    key={feedbackQuestion.id}
                                    index={startingSN + index}
                                    draggableProvider={provided}
                                  />
                                  {provided.placeholder}
                                </>
                              )}
                            </Draggable>
                          )
                        )}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
              {queryList.isLoading && <TableSkeletonLoader rows={7} cols={5} />}
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default QuestionsList;

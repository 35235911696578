import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { UserLoginLogSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { utcToLocal } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const UserLoginLogList: React.FC = () => {
  const userAPI = new UserResource();
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
  });

  const loginLogList = useQuery(
    [
      'loginLogList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await userAPI.loginLogList(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.all_login_logs}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.all_logs}
        </Heading>
      </Flex>

      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form onSubmit={(e) => e.preventDefault()}>
                <Stack
                  direction={['column', 'row']}
                  spacing="4"
                  align={['start', 'end']}>
                  <FormControl>
                    <FormLabel>{strings.user_name}</FormLabel>
                    <Input
                      type="text"
                      size="lg"
                      name="keyword"
                      placeholder={strings.user_name}
                      value={filterParams.keyword}
                      onChange={handleInputChange}
                    />
                  </FormControl>

                  <Button
                    size="lg"
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleReset}>
                    {strings.reset_filter}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.date_time}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loginLogList.isLoading &&
                loginLogList?.data?.data?.map(
                  (userData: UserLoginLogSchema, index: number) => (
                    <Tr key={index}>
                      <Td>{startingSN + index}</Td>
                      <Td>
                        <HStack>
                          <Avatar
                            size="sm"
                            rounded="full"
                            src={userData.user.profile_picture_url}
                          />
                          <Text>
                            {userData.user.first_name}&nbsp;
                            {userData.user.last_name}
                          </Text>
                        </HStack>
                      </Td>
                      <Td>{strings[userData.status] ?? '-'}</Td>
                      <Td>
                        {utcToLocal(userData.created_at).replace(' ', ', ')}
                      </Td>
                    </Tr>
                  )
                )}
              {loginLogList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={loginLogList}
      />
    </Stack>
  );
};

export default UserLoginLogList;

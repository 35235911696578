import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/overpass/400.css';
import '@fontsource/overpass/700.css';
import '@fontsource/overpass/900.css';
import '@fontsource/poppins/700.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'assets/css/App.css';
import 'assets/css/theme.css';
import 'assets/scss/App.scss';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import { strings } from 'config/localization';
import theme from 'config/theme';
import ThemeProvider from 'context/ThemeContext';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from 'router/Router';

const queryClient = new QueryClient();

const App = () => {
  let interfaceLang = strings.getInterfaceLanguage();
  let interfaceLangTwoChar = interfaceLang.substring(0, 2);
  if (
    interfaceLangTwoChar !== 'en' &&
    interfaceLangTwoChar !== 'fr' &&
    interfaceLangTwoChar !== 'it'
  ) {
    interfaceLangTwoChar = 'de';
  }
  let storedLang = localStorage.getItem('language') ?? interfaceLangTwoChar;
  strings.setLanguage(storedLang);

  return (
    <ThemeProvider>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;

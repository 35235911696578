import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Grid,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import BMSServiceBookingResource from 'api/bms-service-booking';
import { BookingSchema, ServiceGroupSchema } from 'constants/schema';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ServiceBookingListItem from './ServiceBookingListItem';

interface Props {
  serviceGroupList: ServiceGroupSchema[];
  serviceBookingErrorMessage: string;
  setServiceBookingPrice?: React.Dispatch<React.SetStateAction<any[]>>;
}

const AddServicesBookingForm = (props: Props) => {
  const {
    serviceGroupList,
    serviceBookingErrorMessage,
    setServiceBookingPrice,
  } = props;
  const { id: reservationID }: any = useParams();

  const { setValue } = useFormContext<BookingSchema>();

  const bmsServiceBookingAPI = new BMSServiceBookingResource();

  // filter service groups which dont have any services
  // later might have to filter on backend if pagination is to be implemented
  const serviceGroupFilteredList = serviceGroupList?.filter(
    (serviceGroup: ServiceGroupSchema) => serviceGroup.services.length > 0
  );

  const [allServiceFormFields, setAllServiceFormFields] = useState<any[]>([]);

  const { data: priceList, refetch } = useQuery(
    ['service-booking-price', allServiceFormFields],
    () =>
      bmsServiceBookingAPI
        .quotePrice(allServiceFormFields)
        .then((res) => res.data?.data),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleServiceFieldDelete = (id?: string) => {
    setAllServiceFormFields((prev: any) => {
      let info = [...prev];
      info = info.filter((item) => item.id !== id);
      return info;
    });
  };

  const changeAllServiceFormFields = useCallback(
    (serviceInputFields: any[], priceSchemeType: string) => {
      // console.log(serviceInputFields);

      setAllServiceFormFields((prev: any) => {
        let info = [...prev];

        serviceInputFields.forEach((vItem, vIndex) => {
          let index = info.findIndex((item: any) => item.id === vItem.id);
          const setCondition =
            (vItem.from_date &&
              vItem.to_date &&
              priceSchemeType === 'PriceSchemeByQuantity') ||
            priceSchemeType === 'PriceSchemeOneTime';

          if (!vItem.quantity) {
            info = info.filter((item) => item.id !== vItem.id);
            return;
          }
          if (!setCondition) return;
          // if the field doesnt exist, add
          if (index === -1) {
            info = [...info, serviceInputFields[vIndex]];
          } else {
            // if the field already exists, replace
            info[index] = {
              ...info[index],
              ...serviceInputFields[vIndex],
            };
          }
        });
        return info;
      });
    },
    []
  );

  useEffect(() => {
    setServiceBookingPrice?.(priceList);
  }, [priceList, setServiceBookingPrice]);

  useEffect(() => {
    setValue('service_bookings', allServiceFormFields);

    if (allServiceFormFields.length === 0) {
      setServiceBookingPrice?.([]);
      return;
    }
    refetch();
  }, [allServiceFormFields, refetch, setServiceBookingPrice, setValue]);

  return (
    <Stack direction="column" align="start" spacing="4">
      <Accordion
        allowToggle
        w="100%"
        defaultIndex={
          !reservationID
            ? [-1]
            : serviceGroupFilteredList?.map((_, index: number) => index)
        }>
        {serviceBookingErrorMessage && (
          <Alert mb="2" status="error">
            <AlertIcon />
            {serviceBookingErrorMessage}
          </Alert>
        )}
        {serviceGroupFilteredList?.map((serviceGroup: ServiceGroupSchema) => {
          return (
            <AccordionItem
              mb="6"
              key={serviceGroup.id}
              border="0.4px solid rgba(0, 0, 0, 0.5);">
              {({ isExpanded }) => (
                <>
                  <AccordionButton outline="0px">
                    <Image src={serviceGroup.icon} boxSize="40px" />
                    <Box flex="1" textAlign="left" ml="2">
                      <Text color="heading" fontWeight="semibold">
                        {serviceGroup.name}
                      </Text>
                    </Box>
                    {isExpanded ? (
                      <Icon as={AiOutlineMinus} />
                    ) : (
                      <Icon as={AiOutlinePlus} />
                    )}
                  </AccordionButton>

                  <AccordionPanel>
                    <Grid
                      gap="6"
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(3, 1fr)',
                      ]}
                      flex="1">
                      {serviceGroup.services.map((service) => {
                        return (
                          <ServiceBookingListItem
                            key={service.id}
                            changeAllServiceFormFields={
                              changeAllServiceFormFields
                            }
                            service={service}
                            handleServiceFieldDelete={handleServiceFieldDelete}
                          />
                        );
                      })}
                    </Grid>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Stack>
  );
};

export default AddServicesBookingForm;

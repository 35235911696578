import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import UserDocumentResource from 'api/user_document';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import { CenterSpinner } from 'components/common/CenterSpinner';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UserDocumentListItem from 'components/user_document/UserDocumentListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import moment from 'moment';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import {
  BiCalendar,
  BiChevronLeft,
  BiChevronRight,
  BiCloudUpload,
  BiSearch,
} from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import history from 'utils/history';
import ReactDatePickerRoot from '../../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  user_id: number;
  keyword: any;
  created_at: string;
  available_from_date: string;
  available_to_date: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';
const DMSUserList: React.FC = () => {
  const { id }: any = useParams();
  const { search } = useLocation();
  const methods = useForm<any>();
  let userDocumentAPI = new UserDocumentResource();
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [totalUserDocument, setTotalUserDocument] = useState<
    number | undefined
  >(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    user_id: id,
    keyword: null,
    created_at: '',
    available_from_date: '',
    available_to_date: '',
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [documentCreatedAt, setDocumentCreatedAt] = useState<any>();

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalUserDocument,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  useEffect(() => {
    if (documentCreatedAt) {
      setFilterParams((prevState) => ({
        ...prevState,
        created_at: moment(documentCreatedAt).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
    if (startDate) {
      setFilterParams((prevState) => ({
        ...prevState,
        available_from_date: moment(startDate).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
    if (endDate) {
      setFilterParams((prevState) => ({
        ...prevState,
        available_to_date: moment(endDate).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
  }, [documentCreatedAt, endDate, startDate]);

  const { data: userDocumentList, isLoading: isUserDocumentListLoading } =
    useQuery(
      [
        'userDocumentList',
        {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
          user_id: id,
          keyword: filterParams.keyword,
          created_at: filterParams.created_at,
          available_from_date: filterParams.available_from_date,
          available_to_date: filterParams.available_to_date,
        },
      ],
      async () => {
        const queryParams: any = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        };
        queryParams.user_id = id;
        if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
        if (filterParams.created_at)
          queryParams.created_at = filterParams.created_at;
        if (filterParams.available_from_date)
          queryParams.available_from_date = filterParams.available_from_date;
        if (filterParams.available_to_date)
          queryParams.available_to_date = filterParams.available_to_date;

        const response = await userDocumentAPI.list(queryParams);
        setTotalUserDocument(response?.data?.meta?.total);
        response && setIsFilterLoading(false);
        return response?.data?.data;
      }
    );

  const filterList = async (data: any) => {
    setIsFilterLoading(true);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      user_id: id,
      keyword: data.keyword,
      created_at: data.created_at,
      available_from_date: data.available_from_date,
      available_to_date: data.available_to_date,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
      created_at: documentCreatedAt
        ? moment(documentCreatedAt).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.created_at,
      available_from_date: startDate
        ? moment(startDate).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.available_from_date,
      available_to_date: endDate
        ? moment(endDate).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.available_to_date,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setDocumentCreatedAt('');
    setStartDate(null);
    setEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      user_id: id,
      keyword: '',
      created_at: '',
      available_from_date: '',
      available_to_date: '',
    }));
    history.push(
      routes.dms.userDocuments.user.list.replace(':id', id.toString())
    );
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const userDocumentQuery = useQuery<any>(
    [`userDocument${id}`, id],
    () => userDocumentAPI.userDetail(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  if (userDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userDocumentQuery.isError) {
    history.push(routes.dms.userDocuments.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.userDocuments.list + search}>
              {strings.document_users}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{userDocumentQuery?.data?.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.users_document}
          </Heading>
          <RouterLink
            to={routes.dms.userDocuments.user.upload.replace(
              ':id',
              id.toString()
            )}>
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
              size="lg">
              {strings.upload_document}
            </Button>
          </RouterLink>
        </Flex>
        <Stack>
          <Stack sx={wrapperStyles}>
            <form>
              <Stack direction="column" spacing="4">
                <Stack
                  direction={['column', 'column', 'row']}
                  spacing="4"
                  align={['start', 'start', 'end']}>
                  <Grid
                    gap={['4', '4', '6']}
                    templateColumns={[
                      'repeat(1, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(3, 1fr)',
                    ]}
                    w="100%">
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.search}</FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BiSearch />}
                            color="gray.400"
                          />
                          <Input
                            id="title"
                            type="text"
                            {...methods.register('keyword')}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.upload_date}</FormLabel>
                        <InputGroup>
                          <InputRightElement
                            pointerEvents="none"
                            children={<BiCalendar />}
                            color="gray.400"
                          />
                          <ReactDatePickerRoot
                            name="created_at"
                            value={filterParams.created_at}
                            placeholderText={strings.select_date}
                            // id="created_at"
                            dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                            selected={documentCreatedAt}
                            customInput={
                              <CreatedAtInput value={documentCreatedAt} />
                            }
                            onChange={(date: Date) =>
                              setDocumentCreatedAt(date)
                            }
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.availability}</FormLabel>
                        <InputGroup>
                          <InputRightElement
                            pointerEvents="none"
                            children={<BiCalendar />}
                            color="gray.400"
                          />
                          <ReactDatePickerRoot
                            placeholderText={strings.select_date}
                            dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                            customInput={<CreatedAtInput />}
                            onChange={handleDateChange}
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            value={getFormattedRangeDate(startDate, endDate)}
                            shouldCloseOnSelect={false}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Stack direction="row" spacing="4">
                    <Button
                      size="lg"
                      colorScheme="primary"
                      variant="outline"
                      onClick={handleReset}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.document_name}</Th>
                  <Th>{strings.upload_date}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isUserDocumentListLoading &&
                  userDocumentList?.map(
                    (userDocumentData: any, index: number) => (
                      <UserDocumentListItem
                        key={userDocumentData.id}
                        index={startingSN + index}
                        userDocumentData={userDocumentData}
                      />
                    )
                  )}
                {isUserDocumentListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isUserDocumentListLoading}
              totalCount={totalUserDocument}
              resultCount={userDocumentList?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {userDocumentList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isUserDocumentListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default DMSUserList;

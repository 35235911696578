import Resource from 'api/resource';
import http from 'utils/http';

class BMSBookingPaymentResource extends Resource {
  constructor() {
    super('bms/payments');
  }

  canclePayment(id: number | string) {
    return http({
      url: `/bms/payments/change-status/${id}`,
      method: 'PUT',
      data: {
        status: 'cancelled',
      },
    });
  }
}

export { BMSBookingPaymentResource as default };

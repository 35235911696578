export const logsData = [
  {
    id: '1',
    name: 'Rogers Schimmel',
    category: 'Owner',
    merged_date: '01.02.2021',
  },
  {
    id: '2',
    name: 'Rogers Schimmel',
    category: 'Owner',
    merged_date: '01.02.2021',
  },
  {
    id: '3',
    name: 'Rogers Schimmel',
    category: 'Owner',
    merged_date: '01.02.2021',
  },
  {
    id: '4',
    name: 'Rogers Schimmel',
    category: 'Owner',
    merged_date: '01.02.2021',
  },
];

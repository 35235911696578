import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import OMSObjectsResource from 'api/oms-objects';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import AddServicesBookingForm from 'components/bms_booking/AddServicesBookingForm';
import AppartmentInformationBookingForm from 'components/bms_booking/AppartmentInformationBookingForm';
import CustomerListModal from 'components/bms_booking/bms_existing_customer_select/CustomerListModal';
import BookingPreviewModal from 'components/bms_booking/BookingPreviewModal';
import CustomerInformationBookingForm from 'components/bms_booking/CustomerInformationBookingForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { BookingSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { DateFormatYMD } from 'utils/DateFormat';
import { checkPermissions } from 'utils/listInfo';

const AddBooking = () => {
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

  const methods = useForm<BookingSchema>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const bookingPreviewDisclosure = useDisclosure();
  const existingCustomerDisclosure = useDisclosure();

  const formValues = methods.getValues();

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const [serviceBookingErrorMessage, setServiceBookingErrorMessage] =
    useState<string>('');
  const [appartmentBookingPrice, setAppartmentBookingPrice] = useState(0);
  const [serviceBookingPrice, setServiceBookingPrice] = useState<any>(null);

  const bmsBookingAPI = new BMSBookingResource();
  const serviceGroupAPI = new SMSServiceGroupResource();
  const apartmentAPI = new OMSObjectsResource();

  const apartmentListQuery = useQuery(
    ['apartmentList'],
    async () => {
      let query: any = {
        page: 1,
        limit: DEFAULT_PAGE_SIZE * 5,
      };
      const res = await apartmentAPI.list(query);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const apartmentList = apartmentListQuery?.data?.data;

  const apartmentDetailsQuery = useQuery(
    ['apartment-details', formValues.apartment_id],
    () =>
      apartmentAPI.get(formValues.apartment_id).then((res) => res.data.data),
    {
      enabled: !!formValues.apartment_id,
      refetchOnWindowFocus: false,
    }
  );
  const apartmentDetails = apartmentDetailsQuery?.data;

  const countriesListQuery = useQuery(
    'country-list',
    () => bmsBookingAPI.listCountries().then((res: any) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const countriesList = countriesListQuery?.data?.data;

  const serviceGroupsQuery = useQuery(
    [`service-group`],
    () =>
      serviceGroupAPI
        .list({ limit: DEFAULT_PAGE_SIZE * 3 })
        .then((res) => res.data?.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const addBooking = useMutation((data: any) => bmsBookingAPI.store(data), {
    onSuccess: () => {
      toast({
        title: strings.booking_created,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('booking-list');
      history.push(routes.bms.booking.list);
    },
    onError: (err: any) => {
      const errors = err?.response?.data?.errors;
      if (errors) {
        const errorFields: Array<keyof BookingSchema> = Object.keys(
          errors
        ) as Array<keyof BookingSchema>;
        errorFields.forEach((field) => {
          // if service bookings error occurs in backend, set error message to state because
          // service bookings isnt registered to react-hook-form
          if (field.includes('service_bookings')) {
            setServiceBookingErrorMessage(
              strings.please_verify_your_service_bookings
            );
          }
          methods.setError(
            field,
            {
              type: 'manual',
              message: errors[field].join('. '),
            },
            {
              shouldFocus: true,
            }
          );
        });
      }
    },
  });

  const onSubmit = () => {
    setServiceBookingErrorMessage('');
    bookingPreviewDisclosure.onOpen();
  };

  const existingCustomerListModalPopup = () => {
    existingCustomerDisclosure.onOpen();
  };

  const onCreateBooking = (data: any) => {
    if (data['service_bookings'].length === 0) {
      delete data['service_bookings'];
    }
    if (!data['customer_id']) {
      data['customer_id'] = null;
    }
    addBooking.mutate({
      ...data,
      from_date: DateFormatYMD(data.from_date),
      to_date: DateFormatYMD(data.to_date),
    });
  };

  if (
    serviceGroupsQuery.isLoading ||
    apartmentListQuery.isLoading ||
    countriesListQuery.isLoading
  ) {
    return <CenterSpinner />;
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.booking_management}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
            {strings.booking_overview}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.add}>
            {strings.create_new_booking}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.create_new_booking}
        </Heading>
      </Flex>

      {addBooking.isError && (
        <Alert status="error">
          <AlertIcon />
          {strings.booking_creation_failed}
        </Alert>
      )}

      <Accordion borderColor="white" allowMultiple defaultIndex={[0, 1]}>
        <FormProvider {...methods}>
          <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              <AccordionItem borderColor="white" boxShadow="box" bg="white">
                <h2>
                  <AccordionButton p="4">
                    <Box flex="1" textAlign="left">
                      <Flex justify="space-between">
                        <Heading fontSize="18px" fontWeight="medium">
                          {strings.customer_information}
                        </Heading>
                      </Flex>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding="0">
                  <Stack sx={wrapperStyles}>
                    <Stack direction="column" spacing="4">
                      <Grid
                        gap="4"
                        templateColumns={['repeat(1, 1fr)']}
                        flex="1">
                        <GridItem>
                          <ButtonGroup>
                            <Button
                              variant="outline"
                              onClick={existingCustomerListModalPopup}
                              size="lg">
                              {strings.select_existing_customer}
                            </Button>
                            {formValues?.customer_id &&
                              formValues?.customer_type &&
                              checkPermissions(userPermissions, [
                                PermissionRequest['read:crm'],
                              ]) && (
                                <RouterLink
                                  to={
                                    routes.crm.contact.edit.replace(
                                      ':id',
                                      formValues.customer_id.toString()
                                    ) +
                                    `?contactType=${formValues.customer_type}`
                                  }
                                  target="_blank">
                                  <Button
                                    variant="outline"
                                    colorScheme="primary"
                                    size="lg">
                                    {strings.edit_contact}
                                  </Button>
                                </RouterLink>
                              )}
                          </ButtonGroup>
                        </GridItem>
                      </Grid>
                      {countriesListQuery.data && (
                        <CustomerInformationBookingForm
                          countriesList={countriesList}
                        />
                      )}
                    </Stack>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem borderColor="white" boxShadow="box" bg="white">
                <h2>
                  <AccordionButton p="4">
                    <Box flex="1" textAlign="left">
                      <Flex justify="space-between">
                        <Heading fontSize="18px" fontWeight="medium">
                          {strings.appartment_information}
                        </Heading>
                      </Flex>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding="0">
                  <Stack sx={wrapperStyles}>
                    <Stack direction="column" spacing="4">
                      {apartmentListQuery.data && (
                        <AppartmentInformationBookingForm
                          apartmentList={apartmentList}
                          apartmentDetails={apartmentDetails}
                          setAppartmentBookingPrice={setAppartmentBookingPrice}
                        />
                      )}
                    </Stack>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem borderColor="white" boxShadow="box" bg="white">
                <h2>
                  <AccordionButton p="4">
                    <Box flex="1" textAlign="left">
                      <Flex justify="space-between">
                        <Heading fontSize="18px" fontWeight="medium">
                          {strings.services}
                        </Heading>
                      </Flex>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding="0">
                  <Stack sx={wrapperStyles}>
                    <Stack direction="column" spacing="4">
                      {serviceGroupsQuery.data && (
                        <AddServicesBookingForm
                          serviceGroupList={serviceGroupsQuery?.data}
                          serviceBookingErrorMessage={
                            serviceBookingErrorMessage
                          }
                          setServiceBookingPrice={setServiceBookingPrice}
                        />
                      )}
                    </Stack>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <Stack alignItems="flex-end">
                <Button colorScheme="primary" type="submit" size="lg">
                  {strings.add_booking}
                </Button>
                <Text fontSize="sm">
                  <Text as="span" color="red">
                    *
                  </Text>
                  &nbsp; - {strings.mandatory_fields}
                </Text>
              </Stack>
            </Stack>
            <BookingPreviewModal
              isOpen={bookingPreviewDisclosure.isOpen}
              onClose={bookingPreviewDisclosure.onClose}
              onCreateBooking={onCreateBooking}
              countriesList={countriesList}
              appartmentBookingPrice={appartmentBookingPrice}
              serviceBookingPrice={serviceBookingPrice}
              apartmentDetails={apartmentDetails}
            />
            <CustomerListModal
              isOpen={existingCustomerDisclosure.isOpen}
              onClose={existingCustomerDisclosure.onClose}
            />
          </form>
        </FormProvider>
      </Accordion>
    </Stack>
  );
};

export default AddBooking;

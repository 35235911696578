import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import GroupForm from 'components/faq/GroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { buildFormData } from 'utils';
import history from 'utils/history';

const AddGroup: React.FC = () => {
  const { id: groupID }: any = useParams();

  const methods = useForm<any>();
  const toast = useToast();
  let faqGroupAPI = new FAQGroupResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: groupDetails, isLoading: groupDetailsLoading } = useQuery(
    ['faq-group-add/edit', groupID],
    () => {
      if (!groupID) return null;
      return faqGroupAPI
        .get(groupID)
        .then((res) => res.data.data)
        .catch();
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const createFaqGroup = useMutation((data: any) => faqGroupAPI.store(data));
  const updateFaqGroup = useMutation((data: any) =>
    faqGroupAPI.updateFAQGroup(groupID, data)
  );

  const onSubmit = async (data: any) => {
    let API;
    if (groupID) {
      data['_method'] = 'PUT';
      API = updateFaqGroup;
    } else {
      API = createFaqGroup;
    }
    const formData = buildFormData(data);
    setIsLoading(true);
    API.mutate(formData, {
      onSuccess: () => {
        toast({
          title: `${strings.faq_group} ${
            groupID ? strings.has_been_updated : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.faq.faqGroup.list);
      },
      onError: () => {
        setErrMsg(
          `${strings.faq_group} ${
            groupID
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };
  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqGroup.list}>
              {strings.faq}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {groupID ? groupDetails?.name : strings.add_group}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {groupID ? strings.edit_group : strings.add_group}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              {groupID && groupDetailsLoading ? (
                <CenterSpinner />
              ) : (
                <GroupForm data={groupDetails} />
              )}
              <ButtonGroup pt="8" justifyContent="flex-end" w="100%">
                <RouterLink to={routes.faq.faqGroup.list}>
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    isDisabled={isLoading}
                    size="lg">
                    {strings.cancel}
                  </Button>
                </RouterLink>
                <Button
                  colorScheme="primary"
                  type="submit"
                  size="lg"
                  isLoading={isLoading}>
                  {!groupID ? strings.add_now : strings.update}
                </Button>
              </ButtonGroup>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddGroup;

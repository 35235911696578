import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import { inputTypeStyle } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { BookingSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { phoneNumberRegex } from 'utils';
import { validEmail } from 'utils/validate';

interface Props {
  countriesList: any;
}

const CustomerInformationBookingForm = (props: Props) => {
  const { countriesList } = props;

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext<BookingSchema>();

  const formValues = getValues();

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.salutation} isRequired>
              <FormLabel>{strings.salutation}</FormLabel>
              <Select
                placeholder={strings.select_salutation}
                size="lg"
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                {...register('salutation')}>
                <option disabled={!!formValues?.customer_id} value="Ms">
                  {strings.ms}
                </option>
                <option disabled={!!formValues?.customer_id} value="Diverse">
                  {strings.diverse}
                </option>
                <option disabled={!!formValues?.customer_id} value="Mr">
                  {strings.mr}
                </option>
              </Select>
              <FormErrorMessage>
                {errors?.salutation && errors?.salutation?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem gridColumn={1}>
            <FormControl isInvalid={!!errors?.title}>
              <FormLabel>{strings.title}</FormLabel>
              <Input
                size="lg"
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                placeholder={strings.title}
                {...register('title')}
              />
              <FormErrorMessage>
                {errors?.title && errors?.title?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>{strings.first_name}</FormLabel>
              <Input
                size="lg"
                isReadOnly={!!formValues.customer_id}
                sx={inputTypeStyle}
                placeholder={strings.first_name}
                {...register('first_name', {
                  required: strings.required_first_name,
                })}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{strings.last_name}</FormLabel>
              <Input
                size="lg"
                isReadOnly={!!formValues.customer_id}
                sx={inputTypeStyle}
                placeholder={strings.last_name}
                {...register('last_name', {
                  required: strings.required_last_name,
                })}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.street} isRequired>
              <FormLabel>{strings.street}</FormLabel>
              <Input
                size="lg"
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                placeholder={strings.street}
                {...register('street', {
                  required: strings.street_name_is_required,
                })}
              />
              <FormErrorMessage>
                {errors?.street && errors?.street?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <Stack direction="row" spacing="4">
              <FormControl isInvalid={!!errors?.house_number} isRequired>
                <FormLabel>{strings.house_number}</FormLabel>
                <Input
                  size="lg"
                  isReadOnly={!!formValues?.customer_id}
                  sx={inputTypeStyle}
                  placeholder={strings.house_number}
                  {...register('house_number', {
                    required: strings.house_number_is_required,
                  })}
                />
                <FormErrorMessage>
                  {errors?.house_number && errors?.house_number?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.zip_code} isRequired>
                <FormLabel>{strings.post_code}</FormLabel>
                <Input
                  size="lg"
                  isReadOnly={!!formValues?.customer_id}
                  sx={inputTypeStyle}
                  placeholder={strings.post_code}
                  {...register('zip_code', {
                    required: strings.post_code_is_required,
                  })}
                />
                <FormErrorMessage>
                  {errors?.zip_code && errors?.zip_code?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.city} isRequired>
              <FormLabel>{strings.town}</FormLabel>
              <Input
                size="lg"
                placeholder={strings.town}
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                {...register('city', {
                  required: strings.city_is_required,
                })}
              />
              <FormErrorMessage>
                {errors?.city && errors?.city?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.country_code} isRequired>
              <FormLabel>{strings.country}</FormLabel>
              <Select
                placeholder={strings.select_country}
                size="lg"
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                {...register('country_code', {
                  required: strings.country_is_required,
                })}>
                {countriesList?.map((country: any) => (
                  <option
                    disabled={!!formValues?.customer_id}
                    key={country.id}
                    value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors?.country_code && errors?.country_code?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>{strings.email_address}</FormLabel>
              <Input
                size="lg"
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                placeholder={strings.email_address}
                {...register('email', {
                  validate: (value) => {
                    if (value) {
                      return validEmail(value) || strings.valid_email_address;
                    } else return strings.email_required;
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.phone_number} isRequired>
              <FormLabel>{strings.phone_number}</FormLabel>
              <Input
                type="text"
                size="lg"
                isReadOnly={!!formValues?.customer_id}
                sx={inputTypeStyle}
                {...register('phone_number', {
                  validate: (value) => {
                    if (value) {
                      return (
                        phoneNumberRegex.test(value.trim()) ||
                        strings.invalid_phone_number
                      );
                    } else return strings.phone_number_is_required;
                  },
                })}
                placeholder={strings.phone_number}
              />
              <FormErrorMessage>
                {errors?.phone_number && errors?.phone_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default CustomerInformationBookingForm;

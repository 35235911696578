import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { BookingSchema, ServiceSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { BiCalendar, BiTrash } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

const DatePickerInput = React.forwardRef((props: any, ref: any) => {
  return <Input _disabled={{ opacity: 1, cursor: 'not-allowed' }} {...props} />;
});

DatePickerInput.displayName = 'DatePickerInput';

interface Props {
  service: ServiceSchema;
  formFieldId?: string;
  serviceInputFields: any[];
  isServiceTypePriceSchemeByQuantity: boolean;
  index: number;
  onServiceInputFieldsChange: (data: any) => void;
  handleServiceFieldDelete: (id?: string) => void;
}

const ServiceBookingFormFields = (props: Props) => {
  const {
    service,
    index,
    formFieldId,
    serviceInputFields,
    isServiceTypePriceSchemeByQuantity,
    onServiceInputFieldsChange,
    handleServiceFieldDelete,
  } = props;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [quantity, setQuantity] = useState<number>(0);

  const { watch } = useFormContext<BookingSchema>();

  const bookingFromDate = watch('from_date');
  const bookingToDate = watch('to_date');

  // if service book startDate is earlier than booking startDate change service book date to booking date
  // if service book endDate is later than booking endDate change service book date to booking date
  useEffect(() => {
    if (startDate && startDate < new Date(bookingFromDate)) {
      setStartDate(new Date(bookingFromDate));
    }
    if (endDate && endDate > new Date(bookingToDate)) {
      setEndDate(new Date(bookingToDate));
    }
  }, [bookingFromDate, bookingToDate, startDate, endDate]);

  const handleQuantityChange = (e: any) => {
    let quantity = Number(e.target.value);
    setQuantity(quantity);
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (
      isServiceTypePriceSchemeByQuantity &&
      ((startDate && !endDate) || (!startDate && endDate))
    )
      return;

    let fields: any = {
      quantity,
    };
    if (isServiceTypePriceSchemeByQuantity) {
      (fields.from_date = DateFormatYMD(startDate)),
        (fields.to_date = DateFormatYMD(endDate));
    }

    onServiceInputFieldsChange((prev: any) => {
      let newServiceInputFields = [...prev];
      let singleServiceInputField = prev.find(
        (serviceInputField: any) => serviceInputField.id === formFieldId
      );
      let newServiceInputFieldIndex = newServiceInputFields.findIndex(
        (item: any) => item.id === formFieldId
      );
      newServiceInputFields[newServiceInputFieldIndex] = {
        ...singleServiceInputField,
        ...fields,
      };
      return newServiceInputFields;
    });
  }, [
    endDate,
    formFieldId,
    isServiceTypePriceSchemeByQuantity,
    onServiceInputFieldsChange,
    quantity,
    startDate,
  ]);

  const handleInputFieldDelete = () => {
    let newServiceInputFields = serviceInputFields.filter(
      (item) => item.id !== formFieldId
    );
    onServiceInputFieldsChange(newServiceInputFields);
    handleServiceFieldDelete(formFieldId);
  };
  const resetFields = () => {
    setStartDate(null);
    setEndDate(null);
    setQuantity(0);
  };

  return (
    <Flex>
      <FormControl>
        <Stack direction="row" spacing="0" mt="2">
          {isServiceTypePriceSchemeByQuantity && (
            <FormLabel w="60%">{strings.date}</FormLabel>
          )}
          <FormLabel w={isServiceTypePriceSchemeByQuantity ? '40%' : '100%'}>
            {strings.quantity_anzahl}
          </FormLabel>
        </Stack>
        <Stack direction="row">
          {isServiceTypePriceSchemeByQuantity && (
            <InputGroup w="60%">
              <InputRightElement
                pointerEvents="none"
                h="100%"
                children={<BiCalendar />}
                color="gray.500"
              />
              <ReactDatePicker
                placeholderText={strings.select_date}
                dateFormat="YYYY-MM-dd"
                value={getFormattedRangeDate(startDate, endDate)}
                selectsRange
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                customInput={<DatePickerInput isRequired={false} size="lg" />}
                shouldCloseOnSelect={false}
                onChange={handleDateChange}
                disabled={!bookingFromDate || !bookingToDate}
                minDate={new Date(bookingFromDate)}
                maxDate={new Date(bookingToDate)}
                portalId="root-popover"
              />
            </InputGroup>
          )}
          <Input
            isRequired={false}
            w={isServiceTypePriceSchemeByQuantity ? '40%' : '100%'}
            placeholder={strings.quantity_anzahl}
            type="number"
            disabled={!bookingFromDate || !bookingToDate}
            size="lg"
            value={quantity}
            onChange={handleQuantityChange}
            _disabled={{ opacity: 1, cursor: 'not-allowed' }}
          />
        </Stack>
      </FormControl>
      <Flex gridGap="1">
        {(quantity > 0 || startDate || endDate) && (
          <IconButton
            height="42px"
            alignSelf="flex-end"
            icon={<IoClose size="18" />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            _hover={{
              bg: 'gray.200',
            }}
            minW="8"
            onClick={resetFields}
          />
        )}
        {index !== 0 && serviceInputFields.length > 1 && (
          <IconButton
            height="42px"
            alignSelf="flex-end"
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="8"
            onClick={handleInputFieldDelete}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ServiceBookingFormFields;

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { ContactInputSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data?: any;
}

const OwnerOnlyFormFields = (props: Props) => {
  const { data } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<ContactInputSchema>();

  useEffect(() => {
    if (data) {
      setValue('iban', data.iban);
      setValue('swift', data.swift);
      setValue('institute', data.institute);
      setValue('sales_tax_number', data.sales_tax_number);
    }
  }, [data, setValue]);

  return (
    <>
      <Heading size="lg" textTransform="capitalize">
        {strings.bank_connection}
      </Heading>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl id="iban" isInvalid={!!errors?.iban}>
            <FormLabel>{strings.iban}</FormLabel>
            <Input
              type="text"
              placeholder={strings.iban}
              isRequired={false}
              {...register('iban')}
            />

            <FormErrorMessage>
              {errors.iban && errors.iban?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="swift" isInvalid={!!errors?.swift}>
            <FormLabel>{strings.swift_bic}</FormLabel>
            <Input
              placeholder={strings.swift_bic}
              type="text"
              isRequired={false}
              {...register('swift')}
            />

            <FormErrorMessage>
              {errors.swift && errors.swift?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="institute" isInvalid={!!errors?.institute}>
            <FormLabel>{strings.institute}</FormLabel>
            <Input
              placeholder={strings.institute}
              type="text"
              isRequired={false}
              {...register('institute')}
            />

            <FormErrorMessage>
              {errors.institute && errors.institute?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            id="sales_tax_number"
            isInvalid={!!errors?.sales_tax_number}>
            <FormLabel>{strings.sales_tax_id_no}</FormLabel>
            <Input
              placeholder={strings.sales_tax_id_no}
              type="text"
              isRequired={false}
              {...register('sales_tax_number')}
            />

            <FormErrorMessage>
              {errors.sales_tax_number && errors.sales_tax_number?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default OwnerOnlyFormFields;

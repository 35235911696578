import { useSocketContext } from 'context/SocketContext';
import React, { useEffect } from 'react';
import BookingInformationScreen from './BookingInformationScreen';
import WelcomeScreen from './WelcomeScreen';

const ScreenSelector = () => {
  const { state } = useSocketContext();

  useEffect(() => {
    if (state.sessionId) {
      localStorage.setItem('CUST:sessionId', state.sessionId);
    }
  }, [state.sessionId]);

  if (state?.message && Object.keys(state.message?.message).length > 0) {
    return <BookingInformationScreen />;
  }

  return <WelcomeScreen />;
};

export default ScreenSelector;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import GetKeyId from 'components/keys/GetKeyId';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  KEY_APARTMENT_SEARCH_API,
  KEY_USER_SEARCH_API,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import useWordSearch from 'hooks/useWordSearch';
import useIsPermitted from 'hooks/usIsPermitted';
import KeyListItem from 'pages/dashboard/Key/KeyList/KeyListItem';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { BiCalendar, BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';
import ReactSelectRoot from '../../../../components/ReactSelectRoot/ReactSelectRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  apartmentName: string;
  apartmentId: number | null;
  registeredDateFrom: Date | null;
  registeredDateTo: Date | null;
  registeredById: number | null;
  registeredByName: string;
  status: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const KeyLists: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [scannedKeyId, setScannedKeyId] = useState<null | string>(null);

  const keyAPI = new KeyAPI();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.registeredDateFrom
      ? new Date(searchValues.registeredDateFrom)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.registeredDateTo
      ? new Date(searchValues.registeredDateTo)
      : null
  );

  const apartmentValue =
    searchValues.apartmentId && searchValues.apartmentName
      ? {
          value: searchValues.apartmentId,
          label: searchValues.apartmentName,
        }
      : null;

  const registeredByValue =
    searchValues.registeredById && searchValues.registeredByName
      ? {
          value: searchValues.registeredById,
          label: searchValues.registeredByName,
        }
      : null;

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    apartmentName: searchValues.apartmentName ?? null,
    apartmentId: searchValues.apartmentId ?? null,
    registeredDateFrom: startDate,
    registeredDateTo: endDate,
    registeredById: searchValues.registeredById ?? null,
    registeredByName: searchValues.registeredByName ?? '',
    status: searchValues.status ?? '',
  });

  const [apartmentInputKey, setApartmentInputKey] = useState<string>('');
  const [userInputKey, setUserInputKey] = useState<string>('');

  const queryList: any = useQuery(
    [
      'keyList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        apartmentId: filterParams.apartmentId,
        registeredDateFrom: filterParams.registeredDateFrom,
        registeredDateTo: filterParams.registeredDateTo,
        registeredById: filterParams.registeredById,
        registeredByName: filterParams.registeredByName,
        status: filterParams.status,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.status) queryParams.status = filterParams.status;
      if (startDate) queryParams.registeredDateFrom = DateFormatYMD(startDate);
      if (endDate) queryParams.registeredDateTo = DateFormatYMD(endDate);
      if (filterParams.registeredById)
        queryParams.registeredBy = filterParams.registeredById;
      if (filterParams.apartmentId)
        queryParams.apartmentId = filterParams.apartmentId;

      const response = await keyAPI.list(queryParams);
      return response?.data;
    }
  );

  const { data: keyLists, isLoading: keyLoading } = queryList;

  const {
    loading: apartmentListLoading,
    result: apartmentQuery,
    setQuery: setApartmentQuery,
  } = useWordSearch(`${KEY_APARTMENT_SEARCH_API}?limit=500`);

  const apartmentList = apartmentQuery?.data;

  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
  }));

  const {
    loading: userListLoading,
    result: userQuery,
    setQuery: setUserQuery,
  } = useWordSearch(`${KEY_USER_SEARCH_API}?limit=500`);

  const userList = userQuery?.data;
  const userOptions = userList?.map((user: any) => ({
    label: user.fullName,
    value: user.id,
  }));

  const setSearchURL = (filterQuery: any) => {
    let data = { ...filterQuery };

    delete data?.page;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      registeredDateFrom: DateFormatYMD(start),
      registeredDateTo: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
    setSearchURL(newFilter);
  };

  const handleStatusChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    setSearchURL(data);
  };

  const handleUserChange = (value: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      registeredByName: value.label,
      registeredById: value.value,
    }));
    const data: any = {
      ...filterParams,
      registeredByName: value.label,
      registeredById: value.value,
    };
    setSearchURL(data);
  };

  const handleApartmentInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setApartmentInputKey(value);
      setApartmentQuery({ keyword: value });
    }
  };

  const handleUserInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setUserInputKey(value);
      setUserQuery({ keyword: value });
    }
  };

  const handleApartmentChange = (value: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      apartmentName: value.label,
      apartmentId: value.value,
    }));
    const data: any = {
      ...filterParams,
      apartmentName: value.label,
      apartmentId: value.value,
    };
    setSearchURL(data);
  };

  const checkKeyLogsPermission = useIsPermitted([
    PermissionRequest['read:keylog'],
  ]);

  useEffect(() => {
    if (scannedKeyId) {
      history.push(routes.key.keyList.view.replace(':id', scannedKeyId));
    }
  }, [scannedKeyId, history]);

  const handleCloseScan = () => {
    //if rfid placed then only close the dialog box.
    setScannedKeyId(null);
    onClose();
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      apartmentName: '',
      apartmentId: null,
      registeredDateFrom: null,
      registeredDateTo: null,
      registeredById: null,
      registeredByName: '',
      status: '',
    });
    history.push(routes.key.keyList.list);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };
  const checkKeyPermission = useCheckPermission(
    [PermissionRequest['read:keylist']],
    routes.key.keyList.add
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.key.keyList.list}>
              {strings.key_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.all_key_list}
          </Heading>
          <Stack direction="row" spacing="4">
            {/* {checkKeyLogsPermission && (
              <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={onOpen}>
                <Icon as={BiBarcodeReader} marginRight={1} />
                {strings.scan_key}
              </Button>
            )} */}
            <Button
              size="lg"
              colorScheme="primary"
              type="button"
              onClick={checkKeyPermission}>
              {strings.add_new_key}
            </Button>
          </Stack>
        </Flex>

        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form>
                  <Stack direction={['column']} spacing="4">
                    <Grid
                      gap="4"
                      templateColumns={['repeat(1, 1fr)', 'repeat(4, 1fr)']}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.apartment}</FormLabel>
                          <ReactSelectRoot
                            id="apartmentId"
                            name="apartmentId"
                            placeholder={strings.select_apartment}
                            options={apartmentOptions}
                            value={apartmentValue}
                            styles={reactSelectStyles}
                            onInputChange={handleApartmentInputChange}
                            inputValue={apartmentInputKey}
                            isLoading={apartmentListLoading}
                            onChange={handleApartmentChange}
                            isSearchable
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.registered_date}</FormLabel>
                          <InputGroup>
                            <ReactDatePickerRoot
                              placeholderText={strings.select_date}
                              dateFormat="yyyy-MM-dd"
                              customInput={<CreatedAtInput />}
                              onChange={handleDateChange}
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              value={getFormattedRangeDate(startDate, endDate)}
                              shouldCloseOnSelect={false}
                              autoComplete="off"
                            />
                            <InputRightElement
                              children={<BiCalendar />}
                              color="gray.400"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.registered_by}</FormLabel>
                          <ReactSelectRoot
                            id="registeredBy"
                            name="registeredBy"
                            placeholder={strings.registered_by}
                            options={userOptions}
                            value={registeredByValue}
                            styles={reactSelectStyles}
                            onInputChange={handleUserInputChange}
                            inputValue={userInputKey}
                            isLoading={userListLoading}
                            onChange={handleUserChange}
                            isSearchable
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.status}</FormLabel>
                          <Select
                            name="status"
                            value={filterParams.status}
                            placeholder={strings.status}
                            onChange={handleStatusChange}
                            rounded="sm">
                            <option value="in stock">{strings.in_stock}</option>
                            <option value="given out">
                              {strings.given_out}
                            </option>
                            <option value="lost">{strings.lost}</option>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </Grid>

                    <Flex justify="right">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        type="button"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Flex>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.key_id}</Th>
                  <Th>{strings.registered_date}</Th>
                  <Th>{strings.registered_by}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!keyLoading &&
                  keyLists?.data?.map((keyItem: any, index: number) => (
                    <KeyListItem
                      keyItem={keyItem}
                      index={startingSN + index}
                      key={index}
                      search={search}
                    />
                  ))}
                {keyLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          dataList={queryList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>

      {checkKeyLogsPermission && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={handleCloseScan}
          isOpen={isOpen}
          isCentered>
          <AlertDialogOverlay />

          <GetKeyId
            setScannedKeyId={setScannedKeyId}
            onClose={onClose}
            databaseCheck={true}
          />
        </AlertDialog>
      )}
    </>
  );
};

export default KeyLists;

import { Lang } from './types';

const fr: Lang = {
  /*login Credential*/
  login: 'Connexion',
  login_now: 'Connexion',
  log_in: 'Connexion',
  password: 'Mot de passe',
  forgot_your_password: 'Vous avez oublié votre mot de passe',
  email: 'Courriel',
  your_email_address: 'Votre adresse électronique',
  your_password: 'Votre mot de passe',
  remember_me: 'Souviens-toi de moi',
  forgot_password: 'Mot de passe oublié?',
  forgot_password1: 'Mot de passe oublié',
  back_to_login: 'Retour à la page de connexion',
  password_reset: 'Demander la réinitialisation du mot de passe',
  login_message:
    "Entrez vos données d'identification pour vous connecter à MYVAL AG.",
  forgot_message:
    "Veuillez saisir l'adresse électronique que vous utilisez pour vous connecter à votre compte.",
  myval_ag: 'MYVAL AG',
  password_info:
    'Le mot de passe doit comporter au moins 1 chiffre, 1 caractère spécial, 1 lettre majuscule et 5 autres caractères.',
  invalid_login_msg: "L'email ou le mot de passe ne correspond pas.",
  user_delete_error:
    "L'utilisateur ne peut pas être supprimé L'utilisateur détient un stock de MMS",
  change_language: 'Changer de langue',
  invalid_token: 'Ce jeton de réinitialisation de mot de passe est invalide.',
  two_factor_authentication: 'Authentification à deux facteurs',
  enter_the_pin_from_google_authentication:
    "Entrez le code de l'authentification Google.",
  authenticate: 'Authentifier',
  one_time_password: 'Mot de passe à usage unique',
  FA_status: 'Statut 2FA',
  disable_2FA: 'Désactiver 2FA',
  enable_2FA: 'Activer 2FA',
  fa_message:
    "Pour activer l'authentification à deux facteurs, veuillez scanner ce code QR avec l'application Google Authenticator.",
  code: 'Code',
  fa_status_tooltip: 'Générer la clé secrète pour activer le 2FA',
  authenticator_code: "Code d'authentification",
  FA_successfully: '2FA est activé avec succès',
  FA_enable: '2FA est actuellement activé pour votre compte',
  fa_Disable: '2FA est maintenant désactivé',
  authenticator_code_error: "Erreur de code d'authentification",
  required_authenticator_code: "Code d'authentification requis",
  fa_disable_title:
    "Si vous souhaitez désactiver l'authentification à deux facteurs. Veuillez confirmer votre mot de passe et cliquer sur le bouton Désactiver 2FA.",
  activate_2FA: 'Activer 2FA',
  deactivate_2FA: 'Désactiver 2FA',

  /*button*/
  save: 'Sauvez',
  save_now: 'Sauvez maintenant',
  filter: 'Filtre',
  back: 'Dos',
  editing: 'Modification de',
  apply_filter: 'Appliquer le filtre',
  reset_filter: 'Réinitialiser le filtre',
  more_filter: 'Plus de filtres',
  cancel: 'Annuler',
  clear: 'Clair',
  reply: 'Réponse',
  comment: 'Commentaire',
  save_and_create_new_ticket: 'Sauvegarder et créer un nouveau ticket',
  confirm_and_close: 'Confirmer et fermer',
  confirm_and_transfer_another_items:
    'Confirmer et transférer un autre élément',
  edit_article: "Editer l'article",
  confirm: 'Confirmer',
  profile: 'Profil',
  my_profile: 'Mon profil',
  edit_profile: 'Modifier le profil',
  profile_updated: 'Profil mis à jour.',
  profle_update_failed: 'La mise à jour du profil a échoué.',
  password_updated: 'Mot de passe mis à jour.',
  not_match_new_confirm_password: 'Les mots de passe ne correspondent pas.',
  incorrect_password: "Le mot de passe n'est pas correct.",
  setting: 'Réglage de',
  settings: 'Paramètres',
  logout: 'Déconnexion',
  loggedOut: 'Déconnecté',
  customer_screen: 'Écran client',
  change_password: 'Changer le mot de passe',
  change_now: 'Changez maintenant',
  Current_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmer le mot de passe',
  invalid_current_password: 'Le mot de passe actuel est incorrect.',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  less_than_zero: 'Valeur inférieure à zéro.',
  email_not_found: 'Adresse e-mail non trouvée.',
  email_required: "L'adresse électronique est requise",
  now: 'maintenant',
  secret_key: 'Clé secrète',
  create_pair: 'Créer une paire',
  must_be_8_characters_long: 'Doit comporter 8 caractères',
  generate: 'Générer',
  unpair: 'Unpair',
  this_will_unpair_the_device_are_you_sure:
    "Cela va dé-coupler l'appareil. Vous êtes sûr?",
  clear_second_screen: 'Effacer le deuxième écran',
  successfully_unpaired: 'Non appariés avec succès',
  pair_created: 'Paire créée',

  /*Module*/
  user_management: 'Gestion des utilisateurs',
  chat: 'Chats',
  chat_overview: 'Aperçu du chat',
  ticket: 'Billets',
  crm: 'CRM',
  contact_management: 'Gestion des contacts',
  contact_center: 'Centre de contact',
  feedback: 'Commentaires',
  mms: 'MMS',
  dashboard: 'Dashboard',
  /*Splash Screen*/
  choose_subsystem: 'Choisir le sous-système',
  /*User Management*/
  user_management_system: 'Système de gestion des utilisateurs',
  user: 'Utilisateurs',
  user_list: "Listes d'utilisateurs",
  user_rights: 'Droits des utilisateurs',
  user_roles: 'Rôles des utilisateurs',
  user_name: "Nom de l'utilisateur",
  user_profile: "Profil de l'utilisateur",
  user_details: "Détails de l'utilisateur",
  role_details: 'Détails du rôle',
  role: 'Rôle',
  add_additional_role: 'Ajouter un rôle supplémentaire',
  add_additional_rights: 'Ajouter des droits supplémentaires',
  additional_rights: 'Droits supplémentaires',
  manage_extra_rights: 'Gérer les droits supplémentaires',
  right_from_role: 'Droits du rôle',
  add_role: 'Ajouter un rôle',
  role_setup: 'Configuration du rôle',
  system: 'Système',
  role_name: 'Nom du rôle',
  privillage: 'Privilège',
  add_user: 'Ajouter un utilisateur',
  all_users: 'Tous les utilisateurs',
  all_user_with_that_role: 'Tous les utilisateurs ayant ce rôle',
  add_new_user: 'Ajouter un nouvel utilisateur',
  add_new_role: 'Ajouter un nouveau rôle',
  view_user: "Afficher l'utilisateur",
  edit_user: "Modifier l'utilisateur",
  delete_user: "Supprimer l'utilisateur",
  save_user: "Sauvegarder l'utilisateur",
  user_deatils: "Détails de l'utilisateur",
  upload_image: "Télécharger l'image",
  drop_image_or_click_to_select_image:
    'Déposer une image ou cliquer pour sélectionner une image',
  assigned_tickets: 'Billets assignés',
  action: 'Action',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email_address: 'Adresse électronique',
  valid_email_address: 'Veuillez fournir une adresse électronique valide.',
  contact_info: 'Informations de contact',
  address: 'Adresse',
  view: 'Voir',
  add: 'Ajouter',
  edit: 'Modifier',
  delete: 'Supprimer',
  search: 'Recherche',
  new_user: 'Nouvel utilisateur',
  has_been_assigned: 'vous a été attribué',
  has_been_transferred: 'vous a été transféré',
  login_with_new_password:
    'Veuillez vous connecter avec un nouveau mot de passe',
  msg_valid_password: 'Veuillez fournir un mot de passe valide.',
  msg_password_reset_link:
    'Nous avons envoyé par e-mail le lien de réinitialisation de votre mot de passe!',
  select_role: 'Sélectionner le rôle',
  search_username: "Recherche de nom d'utilisateur",
  rights: 'Droits',
  reset_password: 'Réinitialiser le mot de passe',
  reset_password_subtitle:
    'Veuillez saisir les champs que vous utilisez pour réinitialiser votre compte.',
  save_reset_password: 'Enregistrer Réinitialiser le mot de passe',
  create_password: 'Créer un mot de passe',
  create_password_subtitle:
    'Veuillez saisir les champs pour créer un mot de passe pour votre compte.',
  save_password: 'Enregistrer le mot de passe',
  password_helper_text:
    'Minimum 8 caractères, au moins une majuscule, une minuscule, un chiffre et un caractère spécial.',
  resend_verification_email: "Renvoyer l'email de vérification",
  verification_email_sent: 'Courriel de vérification envoyé',
  email_verification_success: 'Email vérifié avec succès.',
  email_verification_failed: "La vérification de l'e-mail a échoué.",
  resend_verify_email_error: "Erreur de renvoi de la vérification de l'e-mail.",
  resend_verify_email_success:
    "L'e-mail de vérification a été envoyé avec succès. Vérifiez votre boîte de réception.",
  resend_verify_email_success_to:
    "L'e-mail de vérification a été envoyé avec succès à",
  email_already_verified: "L'email est déjà vérifié.",
  login_to_continue: 'Veuillez vous connecter pour continuer.',
  create_password_to_proceed: 'Créez un mot de passe pour continuer.',
  change_confirm: 'Etes-vous sûr de vouloir changer cela ?',
  cellphone: 'Téléphone portable',
  invalid_cellphone: 'Téléphone portable invalide',
  email_placeholder: 'youremail@example.com',
  admin: 'Admin',
  chat_supporter: 'Supporter du chat',
  ticket_supporter: 'Supporteur de billets',
  mms_employee: 'Employé de MMS',
  verified: 'Vérifié',
  not_yet_verified: 'Pas encore vérifié',
  external_companies: 'Sociétés externes',
  external_company: 'Société externe',
  company_name: 'Nom de la société',
  add_new_company: 'Ajouter une nouvelle société',
  company_created: 'La société a été créée.',
  company_name_is_required: 'Le nom de la société est obligatoire.',
  company_email_is_required:
    "L'adresse électronique de l'entreprise est obligatoire.",
  please_enter_a_valid_phone_number:
    'Veuillez saisir un numéro de téléphone valide.',
  enter_a_country_code_at_the_start: 'Entrez un code de pays au début.',
  company_creation_failed: "La création d'entreprise a échoué.",
  the_email_has_already_been_taken: "L'email a déjà été pris.",
  edit_company: "Société d'édition",
  company_updated: 'La société a été mise à jour avec succès.',
  company_udpate_failed: "La mise à jour de l'entreprise a échoué.",
  delete_company: 'Supprimer la société',
  company_deleted: 'La société a été supprimée.',
  company_delete_error: "L'entreprise ne peut être supprimée",
  company_not_found: 'Entreprise non trouvée.',
  company_profile: "Profil de l'entreprise",
  all_companies: 'Toutes les entreprises',
  company_list: "Listes d'entreprises",
  companies: 'Entreprises',
  view_company: "Voir l'entreprise",
  all_roles: 'Tous les rôles',
  user_login_log: 'Journaux de connexion des utilisateurs',
  all_login_logs: 'Tous les journaux de connexion',
  access_user_log: 'Accès aux journaux de connexion',
  blocked: 'Bloqué',

  /*Chat System*/
  chat_system: 'Système de chat',
  closed_chat: 'Chats fermés',
  all_closed_chats: 'Tous les chats fermés',
  chat_details: 'Détails du chat',
  closed_chat_details: 'Détails du chat fermé',
  archived_chat: 'Chats archivés',
  all_archived_chats: 'Toutes les discussions archivées',
  archived_chat_details: 'Détails du chat archivé',
  chat_metrics: 'Paramètres du chat',
  all_chat_metrics: 'Toutes les mesures de chat',
  sn: '#',
  today: "Aujourd'hui",
  hours: 'heures',
  id: 'ID',
  customer_name: 'Nom du client',
  employee_name: "Nom de l'employé",
  closed_at: 'Fermé à',
  last_message: 'Dernier message',
  last_chat_message: 'Dernier message de chat',
  actions: 'Action',
  no_of_chat: 'Nombre de chats',
  total_chats: 'Total des chats',
  no_of_active_chat: 'Chats actifs',
  no_of_message: 'Nombre de messages',
  no_messages_received: 'Nombre de messages reçus',
  total_messages: 'Total des messages',
  no_messages_send: 'Nombre de messages envoyés',
  sent_messages: 'Messages envoyés',
  time_spent_in_call: 'Temps passé en appel',
  talk_time: 'Temps de parole',
  role_descripition: 'Description du rôle',
  name: 'Nom',
  assignee: 'Cessionnaire',
  description: 'Description',
  transfer_chat: 'Chat de transfert',
  chat_forwarding: 'Transfert de conversation',
  no_active_chat_history: "Pas d'historique de chat actif",
  forward_the_chat_to_a_free_employee: 'Transférer le chat à un employé libre.',
  forward_chat: 'Chat avant',
  select_employee: "Sélectionner l'employé",
  select_user: "Sélectionner l'utilisateur",
  transfer_closed_chat: 'Fermer le chat ?',
  transfer_archived_chat: 'Archive Chat',
  transfer_modal_closed_message: 'Le chat sera fermé.',
  transfer_modal_archived_message: 'Etes-vous sûr de vouloir archiver ceci ?',
  yes: 'Oui',
  no: 'Non',
  call: 'Appelez',
  miss_called: "L'utilisateur a manqué l'appel.",
  active: 'Actif',
  in_active: 'Inactif',
  select_apartment: 'Sélectionnez un appartement',
  select_assignee: 'Sélectionner le destinataire',
  select_customer: 'Sélectionner le client',
  select_cost_recepient: 'Sélectionner le récepteur de coûts',
  select_house_owner: 'Sélectionnez le propriétaire de la maison',
  select_priority: 'Sélectionner la priorité',
  select_source: 'Sélectionner la source',
  write_description: 'Description écrite',
  call_ended: 'Appel terminé',
  message_not_sent: "Votre message n'a pas pu être envoyé",
  join_room_error: 'Impossible de rejoindre la salle pour le moment',
  make_call_error: 'Impossible de téléphoner pour le moment',
  cancel_call_error: "Impossible d'annuler l'appel pour le moment",
  chat_not_found: "Il n'y a pas de chat dans votre flux.",
  chat_not_selected: 'Aucun chat sélectionné !',
  chat_active_toggle_tooltip_message:
    "Les nouveaux chats peuvent vous être attribués ou transférés s'ils sont actifs. Lorsque vous êtes en communication, la bascule est automatiquement mise sur inactif.",
  chat_users_not_available_for_transferral:
    "Il n'y a pas d'autre utilisateur disponible pour le moment.",
  call_in_progress: 'Appel en cours.',
  click_to_cancel: 'Cliquez à nouveau pour annuler.',
  call_cancelled: 'Appel annulé.',
  make_call: 'Passez un appel téléphonique',
  chat_active_info: 'Chat Active Info',
  no_chat_assigned: 'Aucun chat ne vous est attribué pour le moment.',
  is_typing: 'est en train de taper...',

  /*Ticket Board*/
  ticket_system: 'Système de tickets',
  all_tickets: 'Tous les billets',
  ticket_board: 'Planches à billets',
  add_task: 'Ajouter une tâche',
  open: 'Ouvrir',
  inprogress: 'En cours',
  done: 'Terminé',
  closed: 'Fermé',
  document: 'Document',
  recursive_task: 'Tâche récursive',
  normal_task: 'Tâche normale',
  create_ticket: 'Créer un ticket',
  ticket_details: 'Détails du billet',
  ticket_title: 'Titre du billet',
  object: 'Objet',
  all_objects: 'Tous les objets',
  customer: 'Client',
  cost_recipient: 'Récepteur de coûts',
  house_owner: 'Propriétaire de la maison',
  priority_label: 'Label de priorité',
  priority: 'Priorité',
  upload_files: 'Télécharger des fichiers',
  descriptions: 'Descriptions',
  save_create_new_ticket: 'Sauvegarder et créer un nouveau ticket',
  author: 'Auteur',
  created_by: 'Créé par',
  created_at: 'Créé à',
  last_modified_by: 'Dernière modification par',
  last_modified_at: 'Dernière modification à',
  last_changed_by: 'Dernière modification par',
  last_changed_at: 'Dernier changement à',
  status: 'Statut',
  ticket_log: 'Log',
  ticket_list: 'Listes de tickets',
  repeat_task: 'Répéter la tâche',
  recursive_interval: 'Intervalle récursif',
  recursive_untill: "Récursif jusqu'à",
  recursive_start: 'Démarrage récursif',
  apartment: 'Appartement',
  apartments: 'Appartements',
  ticket_created: 'Le ticket a été créé.',
  has_been_created: 'a été créé.',
  has_not_been_created: "n'a pas été créé.",
  has_been_merged: 'a été fusionné',
  has_not_been_merged: "n'a pas été fusionné",
  has_been_updated: 'a été mis à jour.',
  has_not_been_updated: "n'a pas été mis à jour.",
  has_been_deleted: 'a été supprimé.',
  has_not_been_deleted: "n'a pas été supprimé.",
  ticket_error: "Le ticket n'a pas été créé.",
  expand: 'Développez',
  ticket_list_error:
    "Une erreur s'est produite dans le traitement de votre demande.",
  select_recursive_interval: "Sélectionner l'intervalle récursif",
  title_required: 'Le titre est obligatoire.',
  repeating_info: 'Répétition des informations',
  interval: 'Intervalle',
  repeat_from: 'Répéter à partir de',
  repeat_until: "Répéter jusqu'à ce que",
  validate_recursive_date: 'Veuillez entrer une date récursive valide.',
  validate_recursive_until: 'Veuillez saisir une date ultérieure à Répéter du',
  comment_here: 'Commentez ici...',
  comment_required: 'Un commentaire est nécessaire.',
  id_not_found: 'ID non trouvé.',
  edit_ticket: 'Modifier le ticket',
  ticket_updated: 'Ticket mis à jour avec succès',
  change_status: 'Changement de statut',
  status_updated: 'Statut mis à jour avec succès',
  select_status: 'Sélectionner le statut',
  comment_error: 'Erreur de commentaire',
  view_more: 'Voir plus',
  view_less: 'Voir moins',
  billability: 'Facturation',
  billable: 'Facturable',
  spent_time: 'Temps passé',
  adjust_time_spent: 'Ajuster le temps passé',
  correct_spent_time: 'Temps passé correct',
  spent_time_message: 'Le temps passé a été correct manuellement.',
  task: 'Tâche',
  low: 'Faible',
  medium: 'Moyen',
  high: 'Haut',
  urgent: 'Urgent',
  normal: 'Normal',
  repeating: 'Répétant',
  select_type: 'Sélectionnez le type',
  select_type_art: 'Sélectionnez le type',
  ticket_detail: 'Détail du billet',
  edit_task: 'Modifier la tâche',
  created_ticket: 'billet créé',
  updated_ticket: 'billet actualisé',
  changed_status: 'changement de statut',
  assigned_ticket: 'billet assigné',
  commented: 'a commenté',
  order_by: 'Commander par',
  createdat: 'Créé à',
  order: 'Commandez',
  asc: 'Ascendant',
  desc: 'Descendant',
  select_sort: 'Sélectionner le tri',
  select_order: 'Sélectionner la commande',
  ticket_name: 'Nom ',
  select_external_company: 'Sélectionnez une société externe',
  showing_all_customers: 'Montrer tous les clients',
  showing_all_options: 'Afficher toutes les options',
  appointment: 'Rendez-vous',
  appointment_date_time: 'Date et heure du rendez-vous',
  enter_appointment_date_time: "Entrez la date et l'heure du rendez-vous",
  full_screen: 'Plein écran',

  /*CRM*/
  customer_relation_management: 'Gestion des relations avec la clientèle',
  contact_list: 'Listes de contacts',
  all_contacts: 'Tous les contacts',
  merge_list: 'Fusionner les listes',
  add_contact: 'Ajouter un contact',
  label: 'Étiquette',
  source: 'Source',
  destination: 'Destination',
  added: 'Ajouté',
  last_activities: 'Dernières activités',
  last_ticket: 'Dernier billet',
  street: 'Rue',
  house_no: 'Numéro de maison',
  post_code: 'Code postal',
  town: 'Ville',
  country: 'Pays',
  phone: 'Numéro de téléphone',
  mobile: 'Mobile',
  company: 'Compagnie',
  fellow_travelers: 'Voyageurs accompagnateurs',
  pets: 'Animaux de compagnie',
  equipment_features: "Caractéristiques de l'équipement",
  services: 'Services',
  service: 'Service',
  notes_external: 'Notes externes',
  notes_internal: 'Notes internes',
  add_now: 'Ajouter maintenant',
  added_on: 'Ajouté le',
  edit_contact: 'Modifier le contact',
  event_history: 'Historique des événements',
  chanel: 'Canal',
  date_time: 'Date/Heure',
  contact_details: 'Détails du contact',
  merge: 'Fusionner',
  unmerge: 'Séparer',
  updated: 'Mis à jour',
  city: 'Ville',
  select_channel: 'Sélectionner un canal',
  merge_contact: 'Fusionner les contacts',
  merge_contact_sidebar: 'Fusionner le contact',
  select_contact: 'Sélectionner un contact',
  logs: 'Journaux',
  merged_date: 'Date de fusion',
  updated_date: 'Date de mise à jour',
  uploaded_by: 'Téléversé par',
  file: 'Fichier',
  image: 'Image',
  images: 'Images',
  progress_status: 'Statut de progression',
  upload_file: 'Télécharger un fichier',
  continue: 'Continuer',
  view_contact: 'Afficher le contact',
  delete_contact: 'Supprimer le contact',
  merge_detail: 'Information de détail de la fusion des contacts.',
  merge_preview: 'Aperçu de la fusion',
  merge_contact_is_permanent:
    'La fusion des contacts est permanente, cette action ne peut pas être annulée.',
  janitor: 'Concierge',
  support: 'Assistance',
  add_new_guest: 'Ajouter un nouveau invité',
  add_new_owner: 'Ajouter un nouveau propriétaire',
  owners_are_not_allowed_to_be_merged:
    'Les propriétaires ne peuvent pas être fusionnés',
  iban: 'IBAN',
  swift_bic: 'Swift/BIC',
  contact_with_bookings_cannot_be_merged:
    'Le contact avec des réservations ne peut pas être fusionné',
  bank_connection: 'Connexion bancaire',
  institute: 'Institut',
  sales_tax_id_no: "Numéro d'identification fiscale des ventes",
  create_user: 'Créer un utilisateur',
  user_already_exists: "L'utilisateur existe déjà",
  create: 'Créer',
  this_will_create_a_user_for_the_owner_are_you_sure_you_want_to_continue:
    'Cela va créer un utilisateur pour le propriétaire. Êtes-vous sûr de vouloir continuer?',

  /*Contact Center*/
  total_calls: 'Appels totaux',
  aircall_user_name: "Nom d'utilisateur Aircall",
  total_incoming_call: "Nombre total d'appels entrants",
  total_call_duration: 'Durée totale des appels',
  total_outgoing_call: "Nombre total d'appels sortants",
  outgoing_call_duration: "Durée d'appel sortant",
  average: 'Moyenne',
  total_duration: 'Durée totale',
  internal_phone_number: 'Numéro de téléphone interne',

  /*Feedback*/
  feedback_management: 'Gestion de la rétroaction',
  feedback_system: 'Système de rétroaction',
  access_feedback: 'Gérer les commentaires et les courriels',
  access_feedback_questions: 'Gérer les questions et les paramètres',
  access_feedback_resend: 'Accéder à la réédition des commentaires',
  all_feedbacks: 'Tous les commentaires',
  feedback_lists: 'Listes de commentaires',
  mails: 'Courriels',
  all_mails: 'Tous les courriels',
  mail_lists: 'Listes de courriels',
  questions: 'Questions',
  all_questions: 'Toutes les questions',
  question_lists: 'Listes de questions',
  date: 'Date',
  closed_ended: 'Fermé',
  open_ended: 'Ouvert',
  rating: 'Note',
  time_period: 'Période de temps',
  feedback_detail: 'Détails du commentaire',
  mark_as_done: 'Marquer comme terminé',
  publish_feedback: 'Publier le commentaire',
  publish_feedback_on_object_page:
    "Publier le commentaire sur la page de l'objet",
  do_not_publish_feedback: 'Ne pas publier le commentaire',
  do_not_publish_feedback_on_object_page:
    "Ne pas publier le commentaire sur la page de l'objet",
  use_for_score_calculation: 'Utiliser pour le calcul du score',
  do_not_use_for_score_calculation: 'Ne pas utiliser pour le calcul du score',
  contact_customer: 'Contacter le client',
  contact_house_owner: 'Contacter le propriétaire de la maison',
  send_mail: 'Renvoyer le courriel',
  new_question: 'Nouvelle question',
  add_new_question: 'Ajouter une nouvelle question',
  edit_question: 'Modifier la question',
  add_question: 'Ajouter une question',
  delete_question: 'Supprimer la question',
  edit_feedback: 'Détails du commentaire',
  view_feedback: 'Voir le commentaire',
  feedback_question: 'Question de rétroaction',
  feedback_question_deleted_successfully:
    'La question de commentaire a été supprimée avec succès',
  sent: 'Envoyé',
  dispatched: 'Expédié',
  received: 'Reçu',
  feedback_mail_sent_sucessfully:
    'Le courriel de commentaire a été envoyé avec succès',
  feedback_mail_not_sent: "Le courriel de commentaire n'a pas été envoyé",
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    'Combien de jours après le départ le courriel de commentaire doit-il être envoyé?',
  select_days: 'Sélectionner les jours',
  days: 'Jours',
  feedback_received: 'Retour reçu',
  question: 'Question',
  answers: 'Réponses',
  response: 'Réponse',
  the_rating_is_out_of_10: 'Le classement est sur 10',
  absolutely: 'Absolument',
  not_at_all: 'Pas du tout',
  this_is_closed_ended_question: "C'est une question oui/non",
  this_is_open_ended_question: "C'est une question ouverte",
  feedback_has_been_published_successfully:
    'Le retour a été publié avec succès',
  feedback_publish_error: 'Erreur de publication de retour',
  published_feedback_on_object_page_successfully:
    "Retour publié sur la page d'objet avec succès",
  publish_feedback_on_object_page_error:
    "Erreur de publication de retour sur la page d'objet",
  feedback_has_been_unpublished_successfully:
    'Le retour a été retiré avec succès',
  feedback_unpublish_error: 'Erreur de suppression de retour',
  feedback_has_been_marked_as_done_successfully:
    'Le retour a été marqué comme terminé avec succès',
  feedback_mark_as_done_error: 'Erreur de marquage du retour comme terminé',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'Comment évalueriez-vous vos vacances à List sur Sylt et le service fourni par la famille Clausen dans son ensemble ?',
  how_satisfied_were_you_with_your_stay_in_our_appartment:
    'À quel point êtes-vous satisfait de votre séjour dans notre appartement ?',
  publish_status: 'Statut de publication',
  published_on_website: 'Publié sur le site web',
  published_on_object_detail_page: "Publié sur la page de détail de l'objet",
  score_calculation: 'Calcul du score',
  feedback_on_object_page_has_been_unpublished_successfully:
    "Le retour sur la page d'objet a été retiré avec succès",
  feedback_on_object_page_unpublish_error:
    "Erreur de suppression de retour sur la page d'objet",
  score_calculation_used: 'Calcul du score utilisé',
  score_calculation_use_error: "Erreur d'utilisation du calcul du score",
  score_calculation_unused: 'Calcul du score non utilisé',
  score_calculation_unuse_error: 'Erreur de non-utilisation du calcul du score',
  object_publish: 'Objet publié',
  website_publish: 'Site Web publié',
  not_publish: 'Non publié',
  score_total: 'Score total',
  answer_rate: 'Taux de réponse',
  public_score_calculation: 'Calcul du score public',
  real_score: 'Score réel',
  public_score: 'Score public',
  detail_reviews_general: 'Détails des avis généraux',
  detail_review_object_page: "Détails des avis de la page de l'objet",
  reviews_general: 'Avis généraux',
  reviews_object_page: "Avis de la page de l'objet",
  score_general: 'Score général',
  score_objects: 'Score des objets',
  no_mail: 'Pas de courriel',
  no_email: 'Pas de courriel',
  analysis: 'Analyse',
  average_rating: 'Note moyenne.',
  percentage_of_closed_ended: 'Pourcentage de Oui / Non.',
  percentage_of_open_ended:
    'Pourcentage des commentaires écrits des clients / des commentaires non écrits.',
  feedback_analysis: 'Analyse des commentaires',
  delete_mail: 'Supprimer le courriel',
  feedback_mail_deleted_successfully:
    'Courriel de commentaire supprimé avec succès',
  feedback_analysis_detail: "Détails de l'analyse des commentaires",
  text_search: 'Recherche de texte',
  feedback_response_detail: 'Détails de la réponse aux commentaires',
  resend_feedback: 'Renvoi des commentaires',
  resent: 'Renvoyé',
  feedback_deleted_successfully: 'Commentaire supprimé avec succès',
  delete_feedback: 'Supprimer les commentaires',

  /*Inventory*/
  inventory: 'Inventaire',
  inventories: 'Inventaires',
  all_inventory: 'Tous les inventaires',
  inventory_name: "Nom d'inventaire",
  used_item: 'Article utilisé',
  quantity: 'Quantité',
  quantity_anzahl: 'Quantité',
  total_quantity: 'Quantité totale',
  from: 'De',
  to: 'À',
  add_item: 'Ajouter un article',
  deselect_all: 'Tout désélectionner',

  /*MMS*/
  merchandise_management_system: 'Système de gestion de marchandises',
  transfer_item: 'Transférer des articles',
  all_transfer_item: 'Tous les articles de transfert',
  all_transfer_logs: 'Tous les journaux de transfert',
  select_articles: 'Sélectionner des articles',
  search_article: 'Rechercher un article',
  stock_type: 'Type de stock',
  units: 'Unités',
  unit: 'Unité',
  all_units: 'Toutes les unités',
  unit_details: "Détails de l'unité",
  select_source_warehouse: 'Sélectionner un entrepôt source',
  rooms: 'Pièces',
  room: 'Pièce',
  delete_room: 'Supprimer la pièce',
  add_room: 'Ajouter une pièce',
  edit_room: 'Modifier la pièce',
  room_name: 'Nom de la pièce',
  enter_room_name: 'Entrer le nom de la pièce',
  racks: 'Racks',
  add_rack: 'Ajouter un rack',
  rack: 'Rack',
  delete_rack: 'Supprimer le rack',
  shelf: 'Étagère',
  add_shelf: 'Ajouter une étagère',
  delete_shelf: "Supprimer l'étagère",
  shelves: 'Étagères',
  select_destination_warehouse: 'Sélectionner un entrepôt de destination',
  warehouse: 'Entrepôt',
  warehouse_name: "Nom de l'entrepôt",
  warehouses: 'Entrepôts',
  all_warehouses: 'Tous les entrepôts',
  add_warehouse: 'Ajouter un entrepôt',
  edit_warehouse: "Modifier l'entrepôt",
  delete_warehouse: "Supprimer l'entrepôt",
  warehouse_details: "Détails de l'entrepôt",
  warehouse_information: "Informations sur l'entrepôt",
  warehouse_not_found: 'Entrepôt non trouvé',
  shelf_delete_error:
    "Une erreur s'est produite lors de la suppression de l'étagère.",
  rack_delete_error:
    "Une erreur s'est produite lors de la suppression du rack.",
  room_delete_error:
    "Une erreur s'est produite lors de la suppression de la pièce.",
  articles: 'Articles',
  article: 'Article',
  all_articles: 'Tous les articles',
  add_article: 'Ajouter un article',
  articles_names: "Noms d'articles",
  article_name: "Nom de l'article",
  upload_article_picture: "Télécharger une image d'article",
  article_image: "Image de l'article",
  type: 'Type',
  types: 'Types',
  all_types: 'Tous les types',
  type_details: 'Détails du type',
  buying_price: "Prix d'achat",
  selling_price: 'Prix de vente',
  seller: 'Vendeur',
  seller_home_page_shop: "Page d'accueil du vendeur / boutique",
  minimum_stock: 'Stock minimum',
  maximum_stock: 'Stock maximum',
  out_of_stock: 'En rupture de stock',
  minimum_stock_notification: 'Notification de stock minimum',
  ean_number: 'Numéro EAN',
  notes: 'Notes',
  article_details: "Détails de l'article",
  objects: 'Objets',
  object_id: "ID d'objet",
  object_name: "Nom de l'objet",
  booking: 'Réservation',
  tickets: 'Billets',
  inventory_load: "Charge de l'inventaire",
  object_details: "Détails de l'objet",
  ticket_open_inprogress_done: 'Billet (Ouvert / En cours / Terminé)',
  abbreviation: 'Abréviation',
  street_number: 'Numéro de rue',
  is_active: 'Est actif',
  type_name: 'Nom du type',
  add_type: 'Ajouter un type',
  unit_name: "Nom de l'unité",
  add_unit: 'Ajouter une unité',
  edit_unit: "Modifier l'unité",
  transfer_log: 'Journal de transfert',
  transfer_logs: 'Journaux de transfert',
  my_transfer_log: 'Mon journal',
  my_transfer_logs: 'Mes journaux',
  all_my_logs: 'Tous les journaux',
  ticket_source: 'Source du billet',
  done_by: 'Fait par',
  required: 'Requis',
  user_created: "L'utilisateur a été créé",
  user_creation_failed: "La création de l'utilisateur a échoué.",
  user_udpate_failed: "La mise à jour de l'utilisateur a échoué.",
  password_update_failed: 'La mise à jour du mot de passe a échoué.',
  user_not_found: 'Utilisateur introuvable',
  update: 'Mettre à jour',
  user_updated: "L'utilisateur a été mis à jour avec succès",
  user_deleted: "L'utilisateur a été supprimé.",
  roles_not_found: 'Rôles introuvables',
  confirm_delete: 'Êtes-vous sûr de vouloir supprimer ceci ?',
  edit_type: 'Modifier le type',
  select: 'Sélectionner',
  min_stock_notification_tooltip:
    'Vous serez informé lorsque le nombre de stock minimum sera atteint.',
  valid_data: 'Veuillez entrer des données valides.',
  valid_only_number: 'Veuillez entrer uniquement des nombres.',
  min_stock_vaidation_msg: 'La valeur doit être inférieure au stock maximum.',
  max_stock_vaidation_msg: 'La valeur doit être supérieure au stock minimum.',
  phone_call: 'Appel téléphonique',
  collapse_sidebar: 'Réduire la barre latérale',
  expand_sidebar: 'Développer la barre latérale',
  article_type: 'Sélectionner le type',
  article_unit: "Sélectionner l'unité",
  max_upload_size_5:
    "Taille maximum des pièces jointes à télécharger jusqu'à 5 Mo.",
  max_upload_size_10:
    "Taille maximum des pièces jointes à télécharger jusqu'à 10 Mo.",
  max_upload_size_15:
    "Taille maximum des pièces jointes à télécharger jusqu'à 15 Mo.",
  max_characters_exceeded: 'Limite de caractères maximale dépassée',
  selected_files: 'Fichiers sélectionnés',
  drag_drop_files_msg:
    "Glisser & Déposer des fichiers ici, ou cliquez pour sélectionner des fichiers. Téléchargez des fichiers jusqu'à 5 Mo.",
  files: 'Fichiers',
  delete_file: 'Supprimer le fichier',
  only_1_file_is_allowed: 'Seul 1 fichier est autorisé.',
  access_user: "Accéder à l'utilisateur",
  manage_role: 'Gérer le rôle',
  manage_user: "Gérer l'utilisateur",
  access_external_companies: 'Accéder aux entreprises externes',
  manage_external_companies: 'Gérer les entreprises externes',
  access_chat: 'Accéder à la discussion',
  access_closed_chat: 'Accéder à la discussion fermée',
  access_archived_chat: 'Accéder à la discussion archivée',
  access_chat_metrics: 'Accéder aux métriques de discussion',
  access_chat_assign: "Accéder à l'assignation de discussion",
  access_chat_mediasoup: 'Accéder à Mediasoup de discussion',
  access_ticket: 'Accéder au ticket',
  access_mms: 'Accéder à MMS',
  can_hold_item: 'Peut tenir un élément',
  manage_article: "Gérer l'article",
  stocktaking_mode: "Mode d'inventaire",
  stocktaking: 'Inventaire',
  upload_new: 'Télécharger nouveau',
  remove: 'Supprimer',
  uploading: 'Téléchargement en cours',
  invalid_profile_upload: 'Téléchargement de profil non valide',
  file_must_be_an_image_file: 'Le fichier doit être un fichier image.',
  clear_profile_pic: "Effacer l'image de profil",
  are_you_sure: 'Êtes-vous sûr ? Vous ne pourrez pas annuler cette action.',
  upload_label: 'Télécharger le profil',
  remove_label: 'Supprimer le profil',
  invalid_file_size: 'Taille de fichier non valide',
  file_size_info: 'La taille du fichier doit être inférieure à 5 Mo',
  uploading_profile: 'Téléchargement du profil',
  no_permission_msg:
    "Vous n'avez pas la permission d'y accéder. Veuillez contacter l'administrateur.",
  transfer_date: 'Date de transfert',
  transfer_source: 'Source de transfert',
  transfer_by: 'Transféré par',
  transfer_quantity: 'Quantité de transfert',
  category: 'Catégorie',
  select_category: 'Sélectionner une catégorie',
  house_number: 'Numéro de maison',
  object_image: "Image de l'objet",
  house: 'Maison',
  flat: 'Appartement',
  new: 'Nouveau',
  fresh: 'Frais',
  consumed: 'Consommé',
  employee: 'Employé',
  transfer_item_success: "Transfert d'article réussi.",
  transfer_item_failed: "Échec du transfert d'article.",
  none: 'Aucun',
  reconciled: 'Rapproché',
  web_ui: 'Web UI',
  app: 'Application',
  select_article_and_quantity_first:
    "Veuillez sélectionner d'abord l'article et la quantité.",
  min_stock_reached: 'Stock minimum atteint',
  min_stock_reached_description:
    'Cet article a atteint le stock minimum qui a été défini à',
  max_stock_reached: 'Stock maximum atteint',
  max_stock_reached_description:
    'Cet article a atteint le stock maximum qui a été défini à',
  stocktaking_mode_message: 'Entrer vraiment en mode de contrôle des stocks.',
  my_inventory: 'Mes inventaires',
  all_my_inventories: 'Tous les inventaires',
  transfer: 'Transfert',
  max_min_stock: 'Stock max-/min-',
  item: 'Article',
  inventory_detail: "Détails de l'inventaire",
  // common
  lang: 'fr',
  english: 'Anglais',
  german: 'Allemand',
  polish: 'Polonais',
  french: 'Français',
  italian: 'Italien',
  result: 'Résultat',
  no_results_found: 'Aucun résultat trouvé.',
  no_records_found: 'Aucun enregistrement trouvé',
  error: 'Erreur',
  no_options: "Pas d'options",
  showing: 'Afficher',
  manually: 'Manuellement',
  general: 'Général',
  gotoDetails: 'Aller aux détails',
  out_of: 'sur',
  loading: 'Chargement...',
  show_rows: 'Afficher les lignes',
  error_boundary_heading_text: "Désolé, quelque chose s'est mal passé.",
  error_boundary_paragraph_text:
    'Notre équipe a été informée et nous allons corriger cela dès que possible.',
  reload_page: 'Recharger la page',
  take_me_home: 'Ramène-moi à la maison',
  manual: 'Manuel',
  ['v-office']: 'V-Office',
  guest: 'Invité',
  owner: 'Propriétaire',
  lead: 'Menant',
  supplier: 'Fournisseur',
  test: 'Test',
  other: 'Autre',
  daily: 'Quotidien',
  weekly: 'Hebdomadaire',
  bi_weekly: 'Bi-hebdomadaire',
  monthly: 'Mensuel',
  semi_anually: 'Semi-Annuel',
  anually: 'Annuel',
  previous: 'Précédent',
  next: 'Suivant',
  date_validation_msg: 'Veuillez entrer une date valide',
  freeze_inventory: "Geler l'inventaire",
  create_inventory_list: "Créer des listes d'inventaire",
  correct_inventory: "Correctif d'inventaire",
  create_delta_list: 'Créer des listes delta',
  unfreeze_inventory_end: "Dégeler l'inventaire et finir le stockage",
  download_pdf: 'Télécharger en PDF',
  download_excel: 'Télécharger en Excel',
  download__delta_list_pdf: 'Télécharger la liste delta en PDF',
  unfreeze_inventory: "Dégeler l'inventaire",
  no_transferring: "(Aucun transfert d'articles possible.)",
  download_as: '(Télécharger en PDF et enregistrer, puis imprimer.)',
  transfer_items: '(Transférer des articles pour correction de stockage)',
  what_change: '(Quels changements ont été effectués pendant le stockage.)',
  step_1: 'Etape 1',
  step_2: 'Etape 2',
  step_3: 'Etape 3',
  step_4: 'Etape 4',
  step_5: 'Etape 5',
  actual_quantity: 'Quantité réelle',
  delta: 'Delta',
  no_data_found: 'Aucune donnée trouvée',
  ms: 'Mme.',
  Ms: 'Mme.',
  diverse: 'Diverse',
  Diverse: 'Diverse',

  /*MDM*/
  master_data_magnagement: 'Gestion des données maîtresses',
  mdm: 'MDM',
  contacts: 'Contacts',
  contact: 'Contact',
  upload_csv: 'Télécharger CSV',
  business: 'Entreprise',
  language: 'Langue',
  letter_salutation: 'Formule de salutation',
  letter_closure: 'Formule de clôture',
  salutation: 'Salutation',
  birthdate: 'Date de naissance',
  phone_number: 'Numéro de téléphone',
  business_phone_number: 'Numéro de téléphone professionnel',
  mobile_number: 'Numéro de portable',
  fax: 'Fax',
  im: '-im-',
  website: 'site web',
  both: 'les deux',
  self: 'soi',

  region: 'Région',
  plant: 'Usine',
  categories: 'Catégories',
  online: 'En ligne',
  deactivated: 'Désactivé',
  sleeping_facilities: 'Installations de couchage',
  longitude: 'Longitude',
  latitude: 'Latitude',
  bedrooms: 'Chambres',

  bookings: 'Réservations',
  booking_date: 'Date de réservation',
  invoices: 'Factures',
  invoice: 'Facture',
  number: 'Numéro',
  until: "Jusqu'à",
  received_at: 'Reçu à',
  changed_at: 'Changé à',
  total: 'Total',
  grand_total: 'Somme totale',
  marketing_channel: 'Canal de marketing',
  down_payment: 'Acompte',
  down_payment_until: "Acompte jusqu'à",
  final_payment_until: "Paiement final jusqu'à",
  ex_price: 'Prix ex.',
  total_occupancy: 'Occupation totale',
  adult: 'Adulte',
  children: 'Enfants',
  babies: 'Bébés',
  age_children: 'Age des enfants',
  country_customer: 'Pays client',
  town_code_customer: 'Code ville client',
  country_objectives: 'Pays objectifs',
  rent_statement_exit: 'Déclaration de loyer de sortie',
  invoice_date: 'Date de facture',
  net: 'Net',
  due_date: "Date d'échéance",
  cancelled_on: 'Annulé le',
  calculation: 'Calcul',
  select_calculation: 'Sélectionnez le calcul',
  provider: 'Fournisseur',
  select_provider: 'Sélectionnez le fournisseur',
  invoice_recepient: 'Destinataire de la facture',
  select_invoice_recepient: 'Sélectionnez le destinataire de la facture',
  warehouse_holds_inventories: 'Le dépôt tient les inventaires',
  uploads: 'Téléchargements',
  all_uploads: 'Tous les téléchargements',
  all_bookings: 'Toutes les réservations',
  all_invoices: 'Toutes les factures',
  all_services: 'Tous les services',
  all_logs: 'Tous les journaux',
  drag_drop_csv_files_msg:
    "Glisser & Déposer uniquement les fichiers .csv ici, ou cliquer pour sélectionner les fichiers .csv. Télécharger des fichiers jusqu'à 10 MB.",
  drag_drop_csv_15MB_files_msg:
    "Glisser & Déposer uniquement les fichiers .csv ici, ou cliquer pour sélectionner les fichiers .csv. Télécharger des fichiers jusqu'à 15 MB.",
  processing_data: 'Traitement des données',
  failed: 'Échoué',
  access_mdm: 'Accéder à MDM',
  mdm_supporter: 'Supporter MDM',
  file_uploaded_successfully: 'Fichier téléchargé avec succès.',
  uploaded_on: 'Dernier téléchargé le',
  by: 'par',
  delete_article_unit_error:
    "Impossible de supprimer le type d'unité d'article. Il contient de nombreux articles.",
  delete_type_error:
    "Impossible de supprimer la catégorie d'article. Il contient de nombreux articles.",
  transferred: 'Transféré',
  internal_server_error: 'Erreur interne du serveur',
  cannot_upload_csv_in_worng_category:
    'Impossible de télécharger le fichier CSV dans la mauvaise catégorie',
  upload_date: "Date d'importation",
  crm_employee: 'Employé CRM',
  access_crm: 'Accéder à CRM',
  merge_contacts: 'Fusionner les contacts',
  create_owner_crm: 'Créer un nouveau propriétaire',
  notes_cannot_be_more_than_2000_characters_long:
    'Les notes ne peuvent pas dépasser 2000 caractères',

  access_oms: 'Accéder aux objets et aux saisons',
  manage_oms: 'Gérer les objets et les saisons',
  access_bms: 'Accéder à BMS',
  access_bread_service: 'Accéder au service Bread',
  access_sms: 'Accéder à SMS',
  manage_sms: 'Gérer les SMS',
  location: 'Emplacement',
  letter_completion: 'Complétion de la lettre',
  homepage: "Page d'accueil",

  imports: 'Importations',
  all_imports: 'Toutes les importations',
  import_type: "Type d'importation",
  last_id: 'Dernier identifiant',
  total_records_imported: "Nombre total d'enregistrements importés",
  imported_at: 'Importé le',
  availabilities: 'Disponibilités',

  /* OMS */
  object_management_system: "Système de gestion d'objets",
  oms: 'OMS',
  object_list: "Listes d'objets",
  season: 'Saison',
  season_list: 'Listes de saisons',
  season_name: 'Nom de la saison',
  edit_season: 'Modifier la saison',
  delete_season: 'Supprimer la saison',
  add_season: 'Ajouter une nouvelle saison',
  from_date: 'Date de début',
  to_date: 'Date de fin',
  add_new_apartment: 'Ajouter un nouvel appartement',
  status_changed: 'Statut modifié',
  payement_active: 'Paiement actif',
  website_active: 'Site Web actif',
  video_active: 'Vidéo active',
  seven_for_six: '7 pour 6',
  this_discount_will_be_applied_for_season_c:
    'Ce rabais ne sera appliqué que pendant la saison C',
  voffice_data_sync: 'Synchronisation des données VOffice',
  features: 'Caractéristiques',
  picture: 'Image',
  text: 'Texte',
  cannot_get_apartment_details:
    "Impossible d'obtenir les détails de l'appartement",
  must_be_greater_than_zero: 'Doit être supérieur à 0',
  basic_information: 'Informations de base',
  copy: 'Copie',
  ical_link: 'Lien iCal',
  copied: 'Copié',
  copy_to_clipboard_ctrl_c_enter:
    'Copier dans le presse-papiers : Ctrl+C, Enter',

  /**SMS Service */
  service_management_system: 'Système de gestion des services',
  sms: 'SMS',
  add_service: 'Ajouter un service',
  scheme_type: 'Type de plan',
  select_scheme_type: 'Sélectionner le type de plan',
  service_created: 'Le service a été créé',
  service_creation_failed: 'La création du service a échoué.',
  service_udpate_failed: 'La mise à jour du service a échoué.',
  add_new_service: 'Ajouter un nouveau service',
  edit_service: 'Modifier le service',
  service_updated: 'Service mis à jour avec succès',
  service_not_found: 'Service introuvable',
  service_details: 'Détails du service',
  product_code: 'Code produit',
  v_office_service: 'Service VOffice',
  price_scheme_type: 'Type de plan tarifaire',
  by_quantity: 'Par quantité',
  by_duration: 'Par durée',
  by_day: 'Par jour',
  newspaper: 'Journal',
  one_time_price: 'Prix unique',
  beach_chair: 'Chaise de plage',
  unit_price: 'Prix unitaire',
  price_a: 'Prix A',
  price_b: 'Prix B',
  view_service: 'Voir le service',
  delete_service: 'Supprimer le service',
  service_deleted: 'Le service a été supprimé.',
  day: 'Jour',
  price: 'Prix',
  duration: 'Durée',
  priceschemebyday: 'Par jour',
  priceschemeonetime: 'Unique',
  priceschemebyquantity: 'Par quantité',
  priceschemebyduration: 'Par durée',
  priceschemebeachchair: 'Chaise de plage',
  per_week: 'Par semaine',
  sun: 'DIM',
  mon_fri: 'LUN-VEN',
  additional_day: 'Jour supplémentaire',
  annual_price: 'Prix annuel',
  duplicate_price_scheme: 'Plan tarifaire en double',

  /**SMS Service Group */
  service_group: 'Groupe de services',
  service_groups: 'Groupes de services',
  service_management: 'Gestion des services',
  select_service_group: 'Sélectionnez un groupe de services',
  all_service_groups: 'Tous les groupes de services',
  service_group_list: 'Listes de groupes de services',
  add_service_group: 'Ajouter un groupe de services',
  booking_type: 'Type de réservation',
  booking_fee: 'Frais de réservation',
  booking_fee_calculation: 'Calcul des frais de réservation',
  service_group_created: 'Le groupe de services a été créé',
  service_group_creation_failed: 'La création du groupe de services a échoué.',
  service_group_udpate_failed: 'La mise à jour du groupe de services a échoué.',
  add_new_service_group: 'Ajouter un nouveau groupe de services',
  service_name: 'Nom du service',
  frontend_color: "Couleur de l'interface",
  price_per_night: 'Prix par nuit',
  show_on_landing_page: "Afficher sur la page d'accueil",
  default_service: 'Service par défaut',
  early_deliverable: 'Livrable en avance',
  early_deliverable_on_start: 'Livrable en avance au départ',
  pay_to_vendor: 'Payer au fournisseur',
  dog_service: 'Service pour chiens',
  icon: 'Icône',
  cover_image: 'Image de couverture',
  drag_icon_or_click_to_select_icon:
    "Faites glisser l'icône ou cliquez pour sélectionner l'icône",
  drag_image_or_click_to_select_image:
    "Faites glisser l'image ou cliquez pour sélectionner l'image",
  edit_service_group: 'Modifier le groupe de services',
  service_group_updated: 'Le groupe de services a été mis à jour avec succès',
  service_group_not_found: 'Groupe de services introuvable',
  service_group_details: 'Détails du groupe de services',
  one_time: 'Une fois',
  per_day: 'Par jour',
  per_night: 'Par nuit',
  view_service_group: 'Voir le groupe de services',
  delete_service_group: 'Supprimer le groupe de services',
  service_group_deleted: 'Le groupe de services a été supprimé.',
  accept_only_png: 'Acceptez uniquement les fichiers *.png',
  enter_booking_fee_in_cents: 'Entrez les frais de réservation en cents',
  enter_price_per_night_in_cents: 'Entrez le prix en cents',
  enter_value_in_cents: 'Entrez la valeur en cents',
  enter_value_in_euro: 'Entrez la valeur en euro',
  light: 'Lumière',
  dark: 'Obscurité',
  price_calculation: 'Calcul du prix',

  /**DMS Service */
  document_management_system: 'Système de gestion de documents',
  dms: 'DMS',
  general_documents: 'Documents généraux',
  document_objects: "Documents d'appartement",
  document_customers: 'Documents de clients',
  document_owners: 'Documents de propriétaires',
  document_users: "Documents d'utilisateurs",
  all_documents: 'Tous les documents généraux',
  all_customers: 'Tous les documents de clients',
  all_owners: 'Tous les documents de propriétaires',
  all_object_documents: "Tous les documents d'objets",
  all_user_documents: "Tous les documents d'utilisateurs",
  upload_document: 'Télécharger un document',
  document_name: 'Nom du document',
  owner_name: 'Nom du propriétaire',
  availability: 'Disponibilité',
  edit_general_document: 'Modifier le document',
  edit_document_object: "Modifier le document téléchargé pour l'objet",
  edit_document_owner: 'Modifier le document téléchargé pour le propriétaire',
  edit_document_customer: 'Modifier le document téléchargé pour le client',
  upload_general_document: 'Télécharger un document général',
  upload_document_object: "Télécharger un document pour l'objet",
  upload_document_owner: 'Télécharger un document pour le propriétaire',
  upload_document_customer: 'Télécharger un document pour le client',
  to_objects: 'vers les objets',
  no_of_documents: 'Nombre de documents',
  select_object: 'Sélectionner un objet',
  general_document_deleted: 'Le document a été supprimé.',
  delete_general_document: 'Supprimer le document',
  general_document_not_found: 'Document non trouvé',
  general_document_udpate_failed: 'La mise à jour du document a échoué.',
  general_document_created: 'Le document a été créé',
  general_document_creation_failed: 'La création du document a échoué.',
  general_document_updated: 'Document mis à jour avec succès',
  customer_documents: 'Documents de clients',
  owner_documents: 'Documents de propriétaires',
  object_documents: "Documents d'objets",
  users_document: "Documents d'utilisateurs",
  upload_user_document: "Télécharger le document de l'utilisateur",
  edit_user_document: "Modifier le document de l'utilisateur",
  import_file: 'Importer le fichier',
  the_file_field_is_required: 'Le champ de fichier est requis.',

  /**BMS Service */
  booking_management_system: 'Système de gestion de réservation',
  booking_management: 'Gestion de réservation',
  booking_overview: "Vue d'ensemble des réservations",
  booking_status: 'Statut de réservation',
  payment_status: 'Statut de paiement',
  distribution_channel: 'Canal de distribution',
  create_new_booking: 'Créer une nouvelle réservation',
  apartment_name: "Nom de l'appartement",
  total_amount: 'Montant total (CHF)',
  amount_open: 'Montant ouvert (CHF)',
  bms: 'BMS',
  customer_information: 'Informations sur le client',
  booking_creation_failed: 'La création de réservation a échoué',
  salutation_is_required: 'La civilité est requise',
  select_salutation: 'Sélectionner la civilité',
  title: 'Titre',
  street_name_is_required: 'Le nom de rue est requis',
  city_is_required: 'La ville est requise',
  select_country: 'Sélectionner un pays',
  country_is_required: 'Le pays est requis',
  invalid_phone_number: 'Numéro de téléphone non valide',
  appartment_information: "Informations sur l'appartement",
  appartment_is_required: "L'appartement est requis",
  date_is_required: 'La date est requise',
  select_quantity: 'Sélectionner la quantité',
  baby: 'Bébé',
  select_distribution_channel: 'Sélectionner un canal de distribution',
  arrival_status: "Statut d'arrivée",
  select_arrival_status: "Sélectionner le statut d'arrivée",
  arrival_status_is_required: "Le statut d'arrivée est requis",
  reserved_at: 'Réservé à',
  reserved_until: "Réservé jusqu'à",
  total_amount_is_required: 'Le montant total est requis',
  paid_amount_is_required: 'Le montant payé est requis',
  residual_amount: 'Montant restant',
  deposit_amount: 'Montant de dépôt',
  residual_amount_is_required: 'Le montant restant est requis',
  deposit_amount_is_required: 'Le montant de dépôt est requis',
  notes_notizen: 'Notes',
  further_remarks_notes: 'Remarques / Notes supplémentaires',
  house_number_is_required: 'Le numéro de maison est requis',
  town_is_required:'Le Ville est requis',
  post_code_is_required: 'Le code postal est requis',
  phone_number_is_required: 'Le numéro de téléphone est requis',
  add_booking: 'Ajouter une réservation',
  mandatory_fields: 'Champs obligatoires',
  above_18_years_old: 'Plus de 18 ans',
  under_18_years_old: 'Moins de 18 ans',
  under_3_years_old: 'Moins de 3 ans',
  booking_created: 'La réservation a été créée',
  subtotal: 'Sous-total',
  booking_calendar: 'Calendrier de réservation',
  could_not_fetch_bookings: 'Impossible de récupérer les réservations',
  booking_date_conflict_error:
    'La date de réservation chevauche une autre réservation',
  booking_cannot_be_in_the_past:
    'La réservation ne peut pas être dans le passé',
  appartment_overview: "Vue d'ensemble de l'appartement",
  reserved: 'Réservé',
  vat19: '19%TVA sur le service de livraison',
  vat7: '7% TVA',
  payment_method: 'Moyen de paiement',
  bank_transfer: 'Virement bancaire',
  paid_amount: 'Montant payé',
  without_tax: 'Sans taxe',
  including: 'Y compris',
  callback: 'Rappel',
  callbacks: 'Rappels',
  all_callbacks: 'Tous les rappels',
  callback_date: 'Date de rappel',
  callback_detail: 'Détail du rappel',
  callback_deleted_successfully: 'Rappel supprimé avec succès',
  no_callback_details_found: 'Aucun détail de rappel trouvé',
  inquiry: 'Demande',
  mr: 'M.',
  Mr: 'M.',
  mrs: 'Mme',
  misc: 'Divers',
  piece: 'pièce',
  please_verify_your_service_bookings:
    'Veuillez vérifier vos réservations de service',
  booking_preview: 'Aperçu de la réservation',
  selected_dates_not_available:
    'Les dates sélectionnées ne sont pas disponibles',
  not_paid: 'Non payé',
  partial_paid: 'Partiellement payé',
  fully_paid: 'Entièrement payé',
  cannot_calculate_booking_price_for_the_given_date_range:
    'Impossible de calculer le prix de réservation pour la plage de dates donnée',
  cannot_make_bookings_in_the_given_date_range:
    'Impossible de faire des réservations dans la plage de dates donnée',
  booked_from: 'Réservé à partir de',
  booked_until: "Réservé jusqu'à",

  /**BS Service */
  bread_system: 'Système de pain',
  bs: 'BS',
  all_billings: 'Toutes les facturations',
  all_daily_view: 'Toutes les vues quotidiennes',
  all_driving_order: 'Toutes les commandes de conduite',

  /**PMS Service */
  payment_management_system: 'Système de gestion de paiement',
  pms: 'PMS',
  credit_card: 'Paiement par carte de crédit',
  all_credit_cards: 'Paiement par carte de crédit',
  paypal: 'Paiement Paypal',
  all_paypals: 'Paiement Paypal',
  credit_card_no: 'Numéro de carte de crédit',
  amount: 'Montant',
  paypal_address: 'Adresse Paypal',
  access_pms: 'Accéder à PMS',
  payment_links: 'Liens de paiement',
  send_on: 'Envoyer le',
  last_sent_on: 'Dernier envoi le',
  paid_on: 'Payé le',
  all_payment_links: 'Tous les liens de paiement',
  create_payment_links: 'Créer un lien de paiement',
  send_payment_link: 'Envoyer un lien de paiement',
  payment_link_sending_failed: "L'envoi du lien de paiement a échoué.",
  payment_link_send_successfully: 'Lien de paiement envoyé avec succès',
  identifier: 'Identifiant',
  amount_is_required: 'Le montant est requis',
  required_booking_number: 'Le numéro de réservation est requis.',
  required_customer_name: 'Le nom du client est requis.',
  paid: 'Payé',
  resent_mail_success: 'Email renvoyé avec succès',
  resent_mail_error: "Le renvoi de l'email a échoué",
  confirm_resend_email: "Êtes-vous sûr de vouloir renvoyer l'email ?",
  service_reservation: 'Réservation de service',
  apartment_reservation: "Réservation d'objet",
  ferry_booking: 'Réservation de ferry',
  payment_link: 'Lien de paiement',

  /**PNS Service */
  push_notification_system: 'Système de notification push',
  all_notifications: 'Toutes les notifications',
  pns: 'PNS',
  notifications: 'Notifications',
  push_notification: 'Notification push',
  add_new_push_notification: 'Nouvelle notification push',
  link: 'Lien',
  total_read: 'Total/Lu',
  add_push_notification: 'Ajouter une notification push',
  notification_created: 'La notification push a été créée',
  notification_creation_failed: 'La création de la notification push a échoué.',
  send: 'Envoyer',
  access_pns: 'Accéder à PNS',
  manage_pns: 'Envoyer des notifications push',
  image_link: "Lien de l'image",
  pns_notification_confirm:
    'Attention: la notification sera envoyée, continuer?',

  /**FAQ Service */
  publish_in: 'Publier dans',
  frequently_ask_question: 'Question fréquemment posée',
  faq: 'FAQ',
  all_faq: 'Toutes les FAQ',
  internal_faq: 'FAQ internes',
  all_internals: 'Tous les internes',
  success: 'Succès',
  group: 'Groupes',
  all_groups: 'Tous les groupes',
  add_faq: 'Ajouter FAQ',
  edit_faq: 'Modifier FAQ',
  delete_faq: 'Supprimer FAQ',
  select_group: 'Sélectionner le groupe',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Document de connaissances internes',
  dateien_hochladen: 'Téléchargement de fichiers',
  pdf_upload: 'Téléchargement PDF',
  faq_deleted_successfully: 'FAQ supprimée avec succès',

  faq_group: 'Groupe FAQ',
  add_group: 'Ajouter un groupe',
  edit_group: 'Modifier le groupe',
  delete_group: 'Supprimer le groupe',
  faq_group_deleted_successfully: 'Groupe FAQ supprimé avec succès',

  reservations: 'Réservations',
  all_reservations: 'Toutes les réservations',
  all_requests: 'Toutes les demandes',
  reservation_requests: 'Demandes de réservation',
  ferry_bookings: 'Réservations de ferry',
  bread_bookings: 'Réservations de pain',
  bread_daily_view: 'Vues quotidiennes de pain',
  bread_driving_order: 'Commandes de conduite de pain',
  save_inorder_to_apply_changes: 'Enregistrer pour appliquer les modifications',

  /** Bread Boking */
  bread_booking: 'Réservations de pain',
  add_bread_booking: 'Ajouter une réservation de pain',
  update_bread_booking: 'Mettre à jour la réservation de pain',
  pending: 'En attente',
  confirmed: 'Confirmé',
  cancelled: 'Annulé',
  booking_number: 'Numéro de réservation',
  add_new_bread_booking: 'Ajouter une nouvelle réservation de pain',
  edit_bread_booking: 'Modifier la réservation de pain',
  bread_booking_updated: 'La réservation de pain a été mise à jour avec succès',
  bread_booking_not_found: 'La réservation de pain est introuvable',
  cannot_change_quantity_in_past:
    "Vous ne pouvez pas changer la quantité d'une réservation dans le passé",
  multiple_bookings_in_same_date_not_allowed:
    'Les réservations multiples à la même date ne sont pas autorisées',
  bread_booking_details: 'Détails de la réservation de pain',
  bread_booking_deleted: 'La réservation de pain a été supprimée.',
  view_bread_booking: 'Voir la réservation de pain',
  delete_bread_booking: 'Supprimer la réservation de pain',
  edit_order: 'Modifier la commande',
  cancel_order: 'Annuler la commande',
  property_name: 'Nom de la propriété',
  customer_contact: 'Contact du client',
  booking_period: 'Période de réservation',
  timespan: 'Durée',
  bread_booking_created: 'La réservation de pain a été créée',
  bread_booking_creation_failed:
    'La création de la réservation de pain a échoué.',
  bread_booking_udpate_failed:
    'La mise à jour de la réservation de pain a échoué.',
  include_departure_day: 'Inclure le jour de départ',
  price_pound: 'Prix (€)',
  booking_fee_pound: 'Frais de réservation (€)',
  total_pound: 'Total (€)',
  must_be_date_after_today_or_equal_to_today:
    "Doit être une date après aujourd'hui ou égale à aujourd'hui.",
  invalid_data: 'Les données données sont invalides',
  booking_invalid: "Le numéro de réservation n'est pas correct",
  service_invalid: "Le service n'est pas correct",
  customer_invalid: "Le nom du client n'est pas correct",
  /** Driving Order */
  driving_order: 'Ordres de conduite',
  import_driving_order: 'Importer des ordres de conduite',
  import_driving_success: 'Importation de conduite réussie',
  import_driving_failed: "Échec de l'importation de conduite",

  /** Bread Daily View */
  bread_booking_billing: 'Facturation des réservations de pain',
  bread_booking_billing_detils:
    'Détails de la facturation des réservations de pain',
  bread_booking_billing_edit:
    'Modification de la facturation des réservations de pain',
  bread_type: 'Type de pain',
  old_price: 'Ancien prix',
  new_price: 'Nouveau prix',
  price_difference: 'Différence de prix',
  total_bread: 'Total pain',
  total_fee: 'Frais totaux',
  total_price_difference: 'Différence totale de prix',
  total_price: 'Prix total',
  bread_quantity_difference: 'Différence de quantité de pain',
  total_fee_difference: 'Différence totale de frais',
  grand_total_difference: 'Différence totale',
  update_bill: 'Mettre à jour la facture',
  close_bill: 'Fermer la facture',
  this_bill_will_be_closed_permanently:
    'Cette facture sera fermée définitivement. Vous ne pourrez plus la modifier une fois fermée.',

  /** Bread Daily View */
  property: 'Propriété',
  guest_number: "Nombre d'invités",
  brotchen: 'Brötchen',
  bread_daily_view_details: 'Détails de la vue quotidienne du pain',
  view_bread_daily_view: 'Voir la vue quotidienne du pain',
  download: 'Télécharger',
  download_image: "Télécharger l'image",
  download_driving_order: 'Télécharger la commande de conduite',

  /** Reservation Request */
  reservation_request_details: 'Détails de la demande de réservation',
  booking_detail: 'Détails de la réservation',
  reservation_detail: 'Détails de la réservation',
  adults: 'Adultes',
  customer_detail: 'Détails du client',
  sur_name: 'Nom de famille',
  reservation_details: 'Détails de la réservation',
  service_line: 'Ligne de service',
  view_reservation_request_view: 'Voir la demande de réservation',
  dog: 'Chien',
  dog_breed: 'Race de chien',

  /** Ferry Booking */
  view_ferry_booking_view: 'Voir la réservation de ferry',
  ferry_booking_details: 'Détails de la réservation de ferry',
  booking_amount: 'Montant de réservation',
  trip_date: 'Date du voyage',
  time: 'Heure',
  trip_voyage: 'Voyage',
  departure_harbor: 'Havre de départ',
  destination_harbor: 'Havre de destination',
  trip_return_date: 'Date de retour du voyage',
  vehicle_type: 'Type de véhicule',
  licence_plate: "Plaque d'immatriculation",
  length: 'Longueur',
  customer_email: 'Email du client',
  trip_detail: 'Détail du voyage',
  vehicle_detail: 'Détails du véhicule',
  cm: 'cm',
  ferry_booking_cancel_success: 'La réservation de ferry a été annulée',
  ferry_booking_cancel_failed:
    "L'annulation de la réservation de ferry a échoué.",

  data_synced: 'Données synchronisées.',
  last_v_office_data_sync: 'Dernière synchronisation de données v-office',
  access_reservations: 'Réservations',
  access_bookings: 'Accéder aux réservations et au calendrier',
  manage_bookings:
    "Gestion des réservations, des factures et de l'enregistrement",
  access_invoices: 'Accéder aux factures',
  manage_bread_service: 'Gérer le service de pain',
  access_bread_driving_order: 'Ordre de conduite de pain',
  access_callbacks: "Retours d'appel",

  access_faq: 'Accéder à la FAQ',
  manage_faq: 'Gérer la FAQ',
  access_faq_internal: 'Accéder à la FAQ interne',
  access_faq_group: 'Accéder au groupe de FAQ',
  manage_faq_group: 'Gérer le groupe de FAQ',

  access_dms_general: 'Accéder au document général',
  manage_dms_general: 'Gérer le document général',
  access_dms_object: 'Accéder au document objet',
  manage_dms_object: 'Gérer le document objet',
  access_dms_customer: 'Accéder au document client',
  manage_dms_customer: 'Gérer le document client',
  access_dms_owner: 'Accéder au document propriétaire',
  manage_dms_owner: 'Gérer le document propriétaire',
  access_dms_user: 'Accéder à un document utilisateur',
  manage_dms_user: 'Gérer un document utilisateur',
  select_date: 'Sélectionner une date',
  select_date_time: 'Sélectionner une date/heure',

  /*Internet Status*/
  wifi: 'La connexion Internet a été restaurée.',
  nowifi: 'Vous naviguez en mode hors ligne.',
  refresh: 'Actualiser',

  /*Contact Center*/
  access_contact_center: "Centre de contact d'accès",

  /*Newsletter*/
  newsletter: "Lettre d'information",
  newsletter_contact: "Lettre d'information",
  newsletters: "Lettres d'information",
  newsletter_overview: "Vue d'ensemble de la lettre d'information",
  draft: 'Brouillon',
  drafts: 'Brouillons',
  group_of_recipients: 'Groupe de destinataires',
  all_newsletters: "Toutes les lettres d'information",
  new_newsletter: "Nouvelle lettre d'information",
  create_new_newsletter: "Créer une nouvelle lettre d'information",
  recipients: 'Destinataires',
  subject: 'Objet',
  all_draft: 'Tous les brouillons',
  message: 'Message',
  attach_file: 'Joindre un fichier',
  attachments: 'Pièces jointes :',
  preview: 'Aperçu',
  save_as_draft: 'Enregistrer en tant que brouillon',
  compose_message: 'Composer un message',
  preview_message: "Aperçu de la lettre d'information",
  download_our_app_now: 'Téléchargez notre application maintenant',
  contact_with_us: 'Contactez-nous',
  about_us: 'À propos de nous',
  customer_care: 'Service client',
  contact_us: 'Contactez-nous',
  privacy_policy: 'Politique de confidentialité',
  subscribe: "S'abonner",
  unsubscribe: 'Se désabonner',
  select_recipient_group: 'Sélectionner le destinataire',
  edit_newsletter: "Modifier la lettre d'information",
  delete_newsletter: "Supprimer la lettre d'information",
  newsletter_deleted: "Lettre d'information supprimée.",
  newsletter_delete_error:
    "La suppression de la lettre d'information a échoué.",
  newsletter_sent_sucessfully: "Lettre d'information envoyée avec succès.",
  newsletter_saved_to_draft_sucessfully:
    'La newsletter a été sauvegardée dans le brouillon avec succès.',
  newsletter_updated_sucessfully:
    'La newsletter a été mise à jour avec succès.',
  hello: 'Bonjour',
  to_send: 'Envoyer',
  save_and_send: 'Enregistrer et envoyer',
  recipient_group_field_is_required:
    'Le champ Groupe de destinataires est obligatoire.',
  subject_field_is_required: 'Le champ "Sujet" est obligatoire.',
  message_field_is_required: 'Le champ "Message" est obligatoire.',
  send_newsletter: "Envoyer le bulletin d'information",
  attention_notification_will_be_send_continue:
    'Attention! La notification sera envoyée en continu',
  privacy: 'Vie privée',
  imprint: 'Impression',

  /*required*/
  required_first_name: 'Le prénom est requis.',
  required_last_name: 'Le nom de famille est requis.',
  required_email: "L'email est requis.",
  required_cellphone: 'Le numéro de téléphone est requis.',
  required_role: 'Le rôle est requis.',
  required_address: "L'adresse est requise.",
  required_repate_from: 'La répétition débute est requise.',
  required_repeate_until: 'La répétition se termine est requise.',
  required_interval: "L'intervalle récursif est requis.",
  required_label: "L'étiquette est requise.",
  required_type: 'Le type est requis.',
  required_article: "L'article est requis.",
  required_destination: 'La destination est requise.',
  required_source: 'La source est requise.',
  required_quantity: 'La quantité est requise.',
  required_article_name: "Le nom de l'article est requis.",
  required_unit: "L'unité est requise.",
  required_type_name: 'Le nom de type est requis.',
  required_unit_name: "Le nom d'unité est requis.",
  required_descriptions: 'Les descriptions sont requises.',
  required_document_name: 'Le nom du document est requis.',
  required_Object: "L'objet est requis.",
  required_upload: 'Le téléchargement de fichier est requis.',
  required_service_name: 'Le nom du service est requis.',
  required_voffice_service: 'Le service VOffice est requis.',
  required_service_group: 'Le groupe de service est requis.',
  required_cover_image: "L'image de couverture est requise.",
  required_booking_type: 'Le type de réservation est requis.',
  required_booking_fee: 'Les frais de réservation sont requis.',
  required_price_per_night: 'Le prix par nuit est requis.',
  required_icon: "Le téléchargement d'icône est requis.",
  required_group_name: 'Le nom du groupe est requis.',
  required_status: 'Le statut est requis.',
  required_group_recipients: 'Le groupe de destinataires est requis.',
  required_subject: 'Le sujet est requis.',
  required_message: 'Le message est requis.',
  required_room: 'Une salle est nécessaire.',
  required_rack: 'Un rack est nécessaire.',
  required_shelf: 'Une étagère est nécessaire.',
  required_warehouse_name: "Le nom de l'entrepôt est obligatoire.",
  file_size_is_too_large: 'La taille du fichier est trop importante.',
  required_customer: 'Le client est requis.',
  required_owner: 'Le propriétaire est requis.',
  required_user: "L'utilisateur est requis.",
  required_front_color: 'Une couleur frontale est requise.',
  only_one_file_is_allowed: 'Un seul fichier est autorisé.',
  please_fill_in_all_mandatory_fields:
    'Veuillez remplir tous les champs obligatoires.',
  required_password: 'Un mot de passe est requis.',
  required_distribution_channel: 'Un canal de distribution est nécessaire',
  required_sleeping_facilities:
    'Des installations de couchage sont nécessaires',
  required_pets_allowed: 'Le champ "Animaux autorisés" est obligatoire',

  /*Message*/
  email_taken: "L'email a déjà été pris.",

  access_newsletter: "Voir les bulletins d'information et les projets",
  manage_newsletter: "Éditer les bulletins d'information et les brouillons",

  /*Key*/
  key: 'Clé',
  keyscan: 'Scanneur de clés',
  access_keyscan_keylist: "Liste d'accès aux clés",
  access_keyscan_keylog: "Journal d'accès aux clés",
  key_management_system: 'Système de gestion de clés',
  key_list: 'Liste de clés',
  key_logs: 'Journaux de clés',
  key_management: 'Gestion de clés',
  all_key_logs: 'Tous les journaux de clés',
  search_customer: 'Rechercher un client / utilisateur',
  key_id: 'Identifiant de clé',
  scan_key: 'Scanner une clé',
  key_handling: 'Gestion de clé',
  scan_key_again: 'Scanner à nouveau la clé',
  key_information: 'Information sur la clé',
  key_current_status: 'Statut actuel de la clé',
  new_status: 'Nouveau statut',
  rfid_message: 'Scanne RFID réussie',
  rescan_key: 'Rescanner la clé',
  key_new_status: 'Nouveau statut de la clé',
  place_rfid: 'Placez la clé sur le lecteur RFID.',
  all_key_list: 'Liste de toutes les clés',
  add_key: 'Ajouter une clé',
  add_new_key: 'Ajouter une nouvelle clé',
  registered_date: "Date d'enregistrement",
  registered_by: 'Enregistré par',
  edit_key: 'Modifier la clé',
  delete_key: 'Supprimer la clé',
  view_key: 'Voir la clé',
  given_out: 'Remis',
  in_stock: 'En stock',
  lost: 'Perdu',
  key_history: 'Historique des clés',
  key_created_successfully: 'Clé créée avec succès',
  key_created_error: 'Erreur de création de clé',
  key_updated_successfully: 'Clé mise à jour avec succès',
  key_updated_error: 'Erreur de mise à jour de clé',
  key_deleted_successfully: 'Clé supprimée avec succès',
  key_deleted_error: 'Erreur de suppression de clé',
  rescan: 'Rescanner',
  rfid_taken_error: 'La clé RFID est déjà prise',
  scanner_title_message: 'Veuillez placer le tag à proximité du scanner.',
  scanner_message: 'Clé placée correctement?',
  key_no_registered: "La clé n'a pas encore été enregistrée.",

  /*Aerial Map */
  aerial_map: 'Carte aérienne',
  access_aerial_map: 'Accéder à la carte aérienne',
  three_sixty_degree: '360°',
  standard: 'Standard',
  map: 'Carte',

  /* Appartment Address Form*/
  address_location: 'Adresse & Emplacement',
  address_suffix: "Suffixe d'adresse",
  distance_lake: 'Distance du lac',
  distance_shopping: 'Distance du shopping',
  distance_restaurant: 'Distance du restaurant',
  distance_city: 'Distance de la ville',
  distance_train_station: 'Distance de la gare',
  distance_bank_atm: 'Distance banque / ATM',
  distance_doctor: 'Distance du médecin',
  landscape_view: 'Vue sur le paysage',
  garden_view: 'Vue sur le jardin',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'Les cartes / directions de Google Maps facilitent la navigation des invités vers votre appartement et offrent une transparence maximale',
  type_is_required: 'Le type est requis',

  /*Premises Features Form */
  sleeping_arrangements: 'Aménagement de couchage',
  number_of_rooms: 'Nombre de chambres',
  number_of_bedrooms: 'Nombre de chambres à coucher',
  number_of_bathrooms: 'Nombre de salles de bain',
  guest_toilet: "Toilette d'invité",
  size_in_square_meters: 'Taille en mètres carrés',
  number_of_parking_spaces: 'Nombre de places de stationnement',
  additional_information: 'Informations complémentaires',
  kitchen: 'Cuisine',
  terrace: 'Terrasse',
  balcony: 'Balcon',
  non_smoker: 'Non-fumeur',
  pets_allowed: 'Animaux acceptés',
  children_allowed: 'Enfants acceptés',
  diabled_access: 'Accès pour personnes handicapées',
  wheelchair_accessible: 'Accessible en fauteuil roulant',
  parking_available: 'Stationnement disponible',
  washing_machine: 'Lave-linge',
  dryer: 'Sèche-linge',
  fireplace_stove: 'Poêle à bois',
  drying_rack: 'Porte-étendage',
  bedding: 'Literie',
  e_car_charging_station: 'Station de recharge pour voitures électriques',
  air_conditioning: 'Climatisation',

  /* Categories Form */
  Wellness: 'Bien-être',
  luxury: 'luxe',
  family: 'famille',
  secluded: 'isolé',
  resort: 'Resort',
  romantic: 'Romantique',
  athletic: 'Athlétisme',
  tourist_attraction: 'Attraction touristique',

  /* Equiment Kitchen Form */
  microwave: 'Micro-ondes',
  stove: 'Cuisinière',
  oven: 'Four',
  refrigerator: 'Réfrigérateur',
  dishwasher: 'Lave-vaisselle',
  coffee_machine: 'Machine à café',
  kettle: 'Bouilloire',
  toaster: 'Grille-pain',
  dishes_cutlery: 'Vaisselle / Coutellerie',
  waste_bin: 'Poubelle',

  /* Amenities Bathroom Form */
  no_of_showers: 'Nombre de douches',
  no_of_toilets: 'Nombre de toilettes',
  shower: 'Douche',
  wc: 'WC',
  bathtub: 'Baignoire',
  bidet: 'Bidet',
  hair_dryer: 'Sèche-cheveux',
  mirror: 'Miroir',
  bathroom_sink: 'Lavabo de salle de bain',

  /* Amenities Children Form */
  stair_gate: "Barrière d'escalier",
  changing_pad: 'Table à langer',
  socket_fuse: 'Fusible de prise',
  child_toilet_seat: 'Siège de toilette pour enfant',

  /*Equipment Outside Form */
  type_of_grill: 'Type de Grill',
  grill: 'Grill',
  parasol: 'parasol',
  pond: 'étang',
  swing: 'Balançoire',
  sandbox: 'Bac à sable',
  slide: 'Toboggan',
  fireplace: 'Cheminée',
  garden: 'Jardin',
  garden_house: 'Maison de jardin',
  hammock: 'Hamac',

  /* Leisure Activities Entertainment Form */
  free_time_activities: 'Activités de temps libre',
  sauna: 'Sauna',
  jacuzzi: 'jacuzzi',
  solarium: 'solarium',
  gym: 'Gym',
  pool: 'Piscine',
  entertainment: 'Divertissement',
  tv: 'TV',
  hifi: 'hifi',
  radio: 'Radio',
  wlan: 'WLAN',
  internat_speed: "Vitesse d'internet",

  /**lyrics form */
  headline: 'A la une',
  full_description: 'Description complète',
  short_description: 'Brève description',
  directions: 'Directions',

  /**Settings Form */
  season_and_prices: 'Saisons et prix',
  payment_active: 'Paiement actif',
  site_active: 'Site actif',
  vimeo_video_link: 'Lien vidéo Vimeo (facultatif)',
  please_assign_price:
    "Veuillez attribuer les saisons disponibles à l'appartement et donner un prix.",
  assigned: 'Attribué',

  appartment_name: "Nom de l'appartement",
  upload: 'Télécharger',
  upload_document_to_apartment: "Télécharger un document à l'appartement",
  select_appartment: "Sélectionnez l'appartement",
  adjust_order_of_images:
    "Le dispositif / l'ordre des images peut être facilement ajusté via glisser-déposer.",
  delete_image: "Supprimer l'image",
  add_new_payment: 'Ajouter un nouveau paiement',
  payment_made: 'Paiement effectué',
  date_of_receipt_of_payment: 'Date de réception du paiement',
  delete_payment: 'Supprimer le paiement',
  service_information: 'Informations sur le service',
  prepaid: 'Prépayé (CHF)',
  balance: 'Solde (CHF)',
  edit_booking: 'Modifier la réservation',
  add_more_services: 'Ajouter plus de services',
  checkin_now: 'Check-in maintenant',
  checkout_now: 'Check-out maintenant',
  add_service_for_booking: 'Ajouter des services à la réservation {0}',
  confirm_checkin:
    'Voulez-vous faire le check-in pour le client {0} maintenant?',
  confirm_checkout:
    'Voulez-vous faire le check-out pour le client {0} maintenant?',
  terminal_system: 'Système terminal',
  terminal: 'Terminal',
  check_in_check_out: 'Check-in / Check-out',
  check_in: 'Check-in',
  check_out: 'Check-out',
  guest_check_in: 'Enregistrement des clients',
  guest_check_out: 'Sortie des clients',
  checkin_modal_body_first_message: 'Voulez-vous enregistrer le client',
  checkout_modal_body_first_message:
    'Voulez-vous faire la sortie pour le client',
  checkout_modal_body_second_message: 'maintenant',
  checkin_modal_body_last_message: 'maintenant',
  please_make_sure_the_guest_has_read_the_booking_information:
    'Veuillez vous assurer que le client a lu les informations sur la réservation.',
  check_booking_details: 'Vérifier les détails de la réservation',
  people: 'Personnes',
  period: 'Période',
  welcome: 'Bienvenue',
  booked_services: 'Services réservés',
  guest_has_been_successfully_checked_in:
    'Le client a été enregistré avec succès !',
  guest_has_been_successfully_checked_out:
    'Le client a été départi avec succès !',
  could_not_check_in_guest: "Impossible d'enregistrer le client",
  could_not_check_out_guest: 'Impossible de faire la sortie du client',
  make_check_in: 'Enregistrement maintenant',
  make_check_out: 'Sortie maintenant',
  disconnected_from_second_screen: "Déconnecté de l'écran secondaire",
  device_id_pair_context_must_be_used_within_device_id_pair_provider:
    "Le contexte DeviceIdPair doit être utilisé à l'intérieur du fournisseur DeviceIdPair",
  arrived: 'Arrivé',
  departed: 'Parti',
  generate_invoice: 'Générer la facture',
  select_existing_customer: 'Sélectionner un client existant',
  accept_selected: 'Accepter la sélection',
  disconnected: 'Déconnecté',

  abort: 'Abandonner',
  mirror_booking_details: 'Voir les détails de la réservation',
  did_you_choose_the_right_guest: 'Avez-vous choisi le bon invité ?',
  msg_check_in_check_out_modal:
    "Dans l'étape suivante, les détails de la réservation sont affichés pour que l'invité puisse les vérifier. Des ajustements ou des additions peuvent toujours être effectués par la suite.",
  should_the_booking_details_of_the_following_guest_be_displayed:
    'Les détails de la réservation du invité suivant doivent-ils être affichés ?',
  invoice_number: 'Numéro de facture',
  invoice_type: 'Type de facture',
  date_of_invoice: 'Date de facture',
  all: 'Tout',
  owner_invoice: 'Relevé de location',
  customer_invoice: 'Facture client',

  // Owner Invoice information
  owner_billing: 'Facturation par le propriétaire',
  sales_tax_id: 'ID de la taxe de vente',

  successfully_generate_invoice_by_booking_id:
    "Facture générée avec succès par l'Id de réservation",

  // Owner Invoice information
  dear: 'Cher',
  here_with_you_receive_the_current_rent_statement_for_your_object:
    'Voici le relevé de location actuel pour votre objet',
  with_the_invoice_number: 'avec le numéro de facture',
  For_the_mentioned_period_we_can_invoice_the_following_booking:
    'Pour la période mentionnée, nous pouvons facturer la réservation suivante',
  your_income: 'Votre revenu',
  vat: 'TVA',
  our_services: 'Nos services',
  vat_included: 'TVA incluse',
  accounting: 'Comptabilité',
  total_net: 'Total Net',
  balance_to_your_credit: 'Solde créditeur',
  the_clearing_ammount_will_be_transferred_to_your_account_in_the_next_few_days:
    'Le montant du règlement sera transféré sur votre compte dans les prochains jours',
  net_total: 'Total Net',
  invoice_total: 'Total',
  payment_detail: 'Détails de paiement',
  überwiesen: '',
};
export default fr;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import NewsletterAPI from 'api/newsletter';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import { InstagramCircle, PinterestCircle, VimeoCircle } from 'assets/icons';
import { Logo } from 'assets/images';
import CompressedUploader from 'components/common/CompressedUploader';
import { strings } from 'config/localization';
import {
  IMPRINT_URL,
  INSTAGRAM_URL,
  NEWSLETTER_ACCEPT_FILE_TYPE,
  PINTEREST_URL,
  PRIVACY_POLICY_URL,
  VIMEO_URL,
  WEBSITE_URL,
} from 'constants/common';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BiShow, BiTrash } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  capitalizeFirstLetter,
  GroupOfRecipientOptionLocalization,
  localizeGroupOfRecipient,
} from 'utils';

interface Props {
  data?: any;
  clearFileInputToggle?: boolean;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
  tinymceRef: React.MutableRefObject<any>;
  validateMandatoryFields: () => void;
}
interface ComposeMessageParams {
  recipient_group: string[];
  subject: string;
  message: string;
  status?: string;
  file: {
    fileName: string[];
    fileSize: number[];
  };
  recipient_count?: number;
}

const ComposeNewsletterForm: React.FC<Props> = (props) => {
  let {
    data,
    clearFileInputToggle = false,
    onSubmit,
    isLoading,
    tinymceRef,
    validateMandatoryFields,
  } = props;
  const groupOfRecipientOptions = GroupOfRecipientOptionLocalization();

  const cancelConfirmRef = useRef<any>();
  const newsletterAPI = new NewsletterAPI();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const history = useHistory();

  const deleteAttachment = useMutation((attachmentId: number) =>
    newsletterAPI.destroyAttachment(attachmentId)
  );

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachment.mutate(attachmentId);
  };

  const {
    register,
    formState: { errors },
    setValue,
    control,
    getValues,
    setError,
    clearErrors,
  } = useFormContext<any>();

  const [composeNewsletterContent, setComposeNewsletterContent] =
    useState<ComposeMessageParams>({
      recipient_group: data?.recipient_group ?? [],
      subject: data?.subject ?? '',
      message: data?.message ?? '',
      file: {
        fileName: [],
        fileSize: [],
      },
      status: data?.status ?? '',
    });

  const addedFiles = useWatch({ name: 'file' });
  useEffect(() => {
    if (addedFiles?.length > 0) {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: addedFiles.map((item: any) => item.name),
          fileSize: addedFiles.map((item: any) => item.size),
        },
      }));
    } else {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: [],
          fileSize: [],
        },
      }));
    }
  }, [addedFiles]);

  useEffect(() => {
    const previouslyAddedFileName: string[] = data?.newsletterFiles?.map(
      (item: any) => item?.file_name
    );
    const previouslyAddedFileSize: number[] = data?.newsletterFiles?.map(
      (item: any) => item?.file_size
    );

    if (data?.newsletterFiles.length > 0) {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: previouslyAddedFileName,
          fileSize: previouslyAddedFileSize,
        },
      }));
    }
  }, [data?.newsletterFiles]);

  useEffect(() => {
    setValue('recipient_group', composeNewsletterContent.recipient_group);
    setValue('subject', composeNewsletterContent.subject);
    setValue('message', tinymceRef.current.currentContent);
    setValue('status', composeNewsletterContent.status);

    if (composeNewsletterContent.recipient_group.length > 0) {
      clearErrors('recipient_group');
    }
    if (composeNewsletterContent.subject) {
      clearErrors('subject');
    }
    if (tinymceRef.current.currentContent) {
      clearErrors('message');
    }
  }, [composeNewsletterContent, setValue, clearErrors, tinymceRef]);

  const handleSelectOptionChange = (recipient: any) => {
    const recipient_group_array = recipient?.map(
      (element: any) => element.value
    );
    setComposeNewsletterContent((prevState) => ({
      ...prevState,
      recipient_group: recipient_group_array,
    }));
  };

  const handleEditorChange = (content: any, editor: any) => {
    setComposeNewsletterContent((prevState: any) => ({
      ...prevState,
      message: content,
    }));
  };

  const handleInputChange = (e: any) => {
    const subject = e.target.value;
    setComposeNewsletterContent((prevState: any) => ({
      ...prevState,
      subject,
    }));
  };

  const handleClearEditor = () => {
    tinymceRef.current.editor.setContent('');
    setComposeNewsletterContent((prevState: any) => ({
      ...prevState,
      file: {
        fileName: [],
        fileSize: [],
      },
    }));
  };

  const handleSaveAndSendButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(errors).length === 0) {
      onConfirmOpen();
    }
  };

  const handleConfirmClick = () => {
    const formValues = getValues();
    formValues.status = 'confirmed';
    onSubmit(formValues);
  };

  const handleDraftButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formValues = getValues();
    formValues.status = 'pending';
    onSubmit(formValues);
  };

  return (
    <>
      <Stack sx={wrapperStyles} overflow="auto">
        <Grid gap="4">
          <GridItem>
            <FormControl
              width="100%"
              isInvalid={!!errors?.recipient_group}
              isRequired>
              <FormLabel>{strings.recipients}</FormLabel>
              <Controller
                control={control}
                name="recipient_group"
                rules={{
                  required: strings.recipient_group_field_is_required,
                }}
                render={({ field: { ref } }) => (
                  <ReactSelect
                    ref={ref}
                    id="recipient_group"
                    placeholder={strings.select_recipient_group}
                    styles={reactSelectStyles}
                    value={composeNewsletterContent?.recipient_group?.map(
                      (item: any) => ({
                        value: item,
                        label: localizeGroupOfRecipient(
                          capitalizeFirstLetter(item)
                        ),
                      })
                    )}
                    options={groupOfRecipientOptions}
                    onChange={handleSelectOptionChange}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
              <FormErrorMessage>
                <>
                  {errors?.recipient_group && errors?.recipient_group?.message}
                </>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl width="100%" isInvalid={!!errors?.subject} isRequired>
              <FormLabel>{strings.subject}</FormLabel>
              <Input
                type="text"
                size="lg"
                placeholder={strings.subject}
                {...register('subject', {
                  required: strings.subject_field_is_required,
                  validate: (value) => {
                    if (value?.trim() === '')
                      return strings.subject_field_is_required;
                  },
                })}
                value={composeNewsletterContent?.subject}
                onChange={handleInputChange}
              />
              <FormErrorMessage>
                <>{errors?.subject && errors?.subject?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl width="100%" isInvalid={!!errors?.message} isRequired>
              <FormLabel>{strings.message}</FormLabel>
              <Controller
                control={control}
                name="message"
                rules={{
                  required: strings.message_field_is_required,
                }}
                render={() => {
                  return (
                    <Editor
                      tinymceScriptSrc={
                        process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                      }
                      plugins="wordcount"
                      init={{
                        icons: 'material',
                        plugins: 'code image link lists ',
                        toolbar:
                          'undo redo | fontsize | fontfamily | styles | bold italic underline forecolor backcolor | link image code | align | bullist numlist | preview ',
                        font_size_formats:
                          '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                        toolbar_location: 'bottom',
                        height: 500,
                        menubar: 'false',
                        textcolor_rows: '4',
                        branding: false,
                        link_default_target: '_blank',
                      }}
                      onEditorChange={handleEditorChange}
                      value={composeNewsletterContent?.message}
                      ref={tinymceRef}
                      id="message"
                    />
                  );
                }}
              />

              <FormErrorMessage>
                <>{errors?.message && errors?.message?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <ButtonGroup
            variant="outline"
            spacing="2"
            justifyContent={'flex-end'}
            p="1"
            rounded="md">
            <Tooltip label={strings.preview} hasArrow>
              <IconButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                aria-label="Send email"
                icon={<BiShow />}
                _hover={{
                  color: 'green',
                }}
                onClick={onOpen}
              />
            </Tooltip>
            <Tooltip label={strings.clear} hasArrow>
              <IconButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                aria-label="Send email"
                icon={<BiTrash />}
                _hover={{
                  color: 'red',
                }}
                onClick={handleClearEditor}
              />
            </Tooltip>
          </ButtonGroup>
          <GridItem>
            <FormControl width="100%">
              <FormLabel>{strings.upload_files}</FormLabel>
              <CompressedUploader
                uploadedFiles={data?.newsletterFiles}
                deleteAttachment={(attachmentId: number) =>
                  handleDeleteAttachment(attachmentId)
                }
                clearFileInputToggle={clearFileInputToggle}
                fileKey="file"
                acceptFileType={NEWSLETTER_ACCEPT_FILE_TYPE}
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Stack>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelConfirmRef}
            onClose={onConfirmClose}
            isOpen={isConfirmOpen}
            isCentered>
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>{strings.send_newsletter} ?</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                {strings.attention_notification_will_be_send_continue} ?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelConfirmRef} onClick={onConfirmClose}>
                  {strings.no}
                </Button>
                <Button
                  ml={3}
                  colorScheme="red"
                  isLoading={isLoading}
                  onClick={handleConfirmClick}>
                  {strings.yes}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Stack>

        <Stack>
          <ButtonGroup justifyContent="flex-end">
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={() => history.goBack()}
              size="lg">
              {strings.cancel}
            </Button>
            <Button
              size="lg"
              colorScheme="primary"
              onClick={handleSaveAndSendButtonClick}>
              {strings.send_newsletter}
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>

      {/* preview page Modal */}

      <Modal isOpen={isOpen} size="5xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.preview}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction="column"
              spacing="4"
              bg="white"
              p={['4', '6']}
              shadow="box"
              w="full">
              <Stack dir="column">
                <Flex>
                  {strings.subject}:&nbsp;
                  <Box fontWeight="bold">
                    {composeNewsletterContent.subject}
                  </Box>
                </Flex>
                <Flex>
                  {strings.group_of_recipients}:
                  {composeNewsletterContent.recipient_group.map(
                    (item: any, id: number) => (
                      <Flex pl="4" key={id}>
                        {id !== 0 && <Divider orientation="vertical" pl="3" />}
                        <Box
                          fontWeight="bold"
                          textTransform="capitalize"
                          alignSelf="center">
                          {localizeGroupOfRecipient(item)}
                        </Box>
                      </Flex>
                    )
                  )}
                </Flex>
              </Stack>
              <Stack
                direction="column"
                spacing="4"
                bg="#F5F5F5"
                shadow="box"
                w="full">
                <Stack spacing="4" p="4">
                  <Center>
                    <Image src={Logo} width="340px" objectFit="contain" />
                  </Center>
                </Stack>
                <Divider borderColor="gray.300" />
                <Stack
                  dir="column"
                  spacing="4"
                  height="auto"
                  p="8"
                  marginTop="0 !important">
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                    }
                    disabled={true}
                    inline={true}
                    initialValue={composeNewsletterContent?.message}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing="4"
                bg="#F0F0F0"
                color="paragraph"
                shadow="box"
                p={['0', '4']}
                marginTop="0px !important"
                w="full">
                <Flex justifyContent="flex-end" marginInline="3">
                  <Text>Verbinden Sie sich mit uns</Text>
                </Flex>

                <Flex justifyContent={'flex-end'}>
                  <Tooltip label="Instagram" hasArrow>
                    <a
                      href={INSTAGRAM_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Box
                        bg="white"
                        shadow="box"
                        w={12}
                        h={12}
                        rounded="full"
                        marginInline="2"
                        p="1">
                        <Image src={InstagramCircle} />
                      </Box>
                    </a>
                  </Tooltip>
                  <Tooltip label="Vemo" hasArrow>
                    <a
                      href={VIMEO_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Box
                        bg="white"
                        shadow="box"
                        w={12}
                        h={12}
                        rounded="full"
                        marginInline="2"
                        p="1">
                        <Image src={VimeoCircle} />
                      </Box>
                    </a>
                  </Tooltip>
                  <Tooltip label="Pinterest" hasArrow>
                    <a
                      href={PINTEREST_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Box
                        bg="white"
                        shadow="box"
                        w={12}
                        h={12}
                        rounded="full"
                        marginInline="2"
                        p="1">
                        <Image src={PinterestCircle} />
                      </Box>
                    </a>
                  </Tooltip>
                </Flex>
              </Stack>
              <Stack
                direction="column"
                spacing="4"
                bg="#E5E5E7"
                color="paragraph"
                shadow="box"
                p={['0', '4']}
                marginTop="0px !important"
                w="full">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Flex alignItems="center">
                    <Flex paddingInline="4">
                      <a
                        href={WEBSITE_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Box fontWeight="bold" textTransform="capitalize">
                          Website
                        </Box>
                      </a>
                    </Flex>
                    <Text fontSize="20px" fontWeight="bold">
                      |
                    </Text>
                    <Flex paddingInline="4">
                      <a
                        href={PRIVACY_POLICY_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Box fontWeight="bold" textTransform="capitalize">
                          Datenschutz
                        </Box>
                      </a>
                    </Flex>
                    <Text fontSize="20px" fontWeight="bold">
                      |
                    </Text>
                    <Flex paddingInline="4">
                      <a
                        href={IMPRINT_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Box fontWeight="bold" textTransform="capitalize">
                          Impressum
                        </Box>
                      </a>
                    </Flex>
                  </Flex>

                  <Text
                    color="blue.400"
                    textDecoration="underline"
                    fontWeight="medium">
                    Newsletter abbestellen
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComposeNewsletterForm;

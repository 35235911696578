import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FeedbackListItem from 'components/feedback/FeedbackListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  FEEDBACK_STATUS_TYPE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import { FeedbackSchema, FEEDBACK_PUBLISH_STATUS_TYPE } from 'constants/schema';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';
import ReactSelectRoot from '../../../../components/ReactSelectRoot/ReactSelectRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  status: string;
  apartment_id: string;
  start_date: Date | null;
  end_date: Date | null;
  publish_status: FEEDBACK_PUBLISH_STATUS_TYPE[];
}
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} size="lg" />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const FeedbacksList: React.FC = () => {
  const feedbackResourceAPI = new FeedbackResource();
  const publishStatusRef = useRef<any>(null);
  const publishStatusOptions: {
    value: FEEDBACK_PUBLISH_STATUS_TYPE;
    label: string;
  }[] = [
    { value: 'website_publish', label: strings.website_publish },
    { value: 'object_publish', label: strings.object_publish },
    { value: 'score_calculation', label: strings.score_calculation },
    { value: 'not_publish', label: strings.not_publish },
  ];
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];
  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.start_date ? new Date(searchValues.start_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.end_date ? new Date(searchValues.end_date) : null
  );
  const publishStatusSearchValues: FEEDBACK_PUBLISH_STATUS_TYPE[] =
    searchValues?.publish_status?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    status: searchValues.status ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    start_date: startDate,
    end_date: endDate,
    publish_status: publishStatusSearchValues ?? [],
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFilter: any = {
      ...filterParams,
      start_date: DateFormatYMD(start),
      end_date: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const apartmentList = useQuery([`apartmentList`], () =>
    feedbackResourceAPI
      .getApartmentList({ limit: 300, page: 1 })
      .then((res) => res.data)
  );

  const publishStatusValue = filterParams?.publish_status?.map(
    (item: FEEDBACK_PUBLISH_STATUS_TYPE) => {
      return {
        value: item,
        label: strings[item],
      };
    }
  );

  const queryList = useQuery(
    [
      `feedbacksList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_name: filterParams.keyword,
        apartment_id: filterParams.apartment_id,
        status: filterParams.status,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
        publish_status: filterParams.publish_status,
      },
    ],
    () => {
      let { keyword, apartment_id, status } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.customer_name = keyword;
      if (apartment_id) queryParams.apartment_id = apartment_id;
      if (startDate)
        queryParams.last_email_sent_at_from = DateFormatYMD(startDate);
      if (endDate) queryParams.last_email_sent_at_to = DateFormatYMD(endDate);
      if (status) queryParams.status = status;
      if (filterParams.publish_status)
        queryParams.publish_status = filterParams.publish_status;
      return feedbackResourceAPI
        .getFeedbackList(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const {
    data: feedbackQuery,
    isLoading: feedbackLoading,
    error: feedbackError,
  } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handlePublishStatusChange = (publishStatus: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      publish_status: publishStatus.map((item: any) => item.value),
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      apartment_id: '',
      status: '',
      start_date: null,
      end_date: null,
      publish_status: [],
    }));
    publishStatusRef.current.select.clearValue();
    history.push(routes.feedback.feedbacks.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
            {strings.feedback_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
            {strings.feedback}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.feedback}
        </Heading>
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form>
                <Stack direction="column" spacing="4">
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <Grid
                      gap="4"
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(4, 1fr)',
                      ]}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.search}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              h="100%"
                              pointerEvents="none"
                              children={<BiSearch />}
                              color="gray.500"
                            />
                            <Input
                              size="lg"
                              type="text"
                              name="keyword"
                              value={filterParams.keyword}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.apartment}</FormLabel>
                          <Select
                            size="lg"
                            name="apartment_id"
                            value={filterParams.apartment_id}
                            placeholder={strings.select_apartment}
                            rounded="sm"
                            onChange={handleInputChange}>
                            {apartmentList?.data?.data?.map(
                              (apartment: any) => {
                                return (
                                  <option
                                    key={apartment.id}
                                    value={apartment.id}>
                                    {apartment.name}
                                  </option>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.date}</FormLabel>
                          <InputGroup>
                            <InputRightElement
                              h="100%"
                              pointerEvents="none"
                              children={<BiCalendar />}
                              color="gray.500"
                            />
                            <ReactDatePickerRoot
                              placeholderText={strings.select_date}
                              dateFormat="yyyy-MM-dd"
                              customInput={<CreatedAtInput />}
                              onChange={handleDateChange}
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              value={getFormattedRangeDate(startDate, endDate)}
                              shouldCloseOnSelect={false}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.status}</FormLabel>
                          <Select
                            name="status"
                            value={filterParams.status}
                            placeholder={strings.select_status}
                            rounded="sm"
                            size="lg"
                            onChange={handleInputChange}>
                            {FEEDBACK_STATUS_TYPE.map((status) => (
                              <option key={status} value={status}>
                                {strings[status]}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.publish_status}</FormLabel>
                          <ReactSelectRoot
                            name="publish_status"
                            closeMenuOnSelect={false}
                            placeholder={strings.publish_status}
                            ref={publishStatusRef}
                            isMulti
                            options={publishStatusOptions}
                            defaultValue={publishStatusValue}
                            onChange={handlePublishStatusChange}
                            styles={reactSelectStyles}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Stack>
                  <Stack direction="row" spacing="4" justifyContent="flex-end">
                    <Button
                      colorScheme="primary"
                      type="button"
                      size="lg"
                      variant="outline"
                      onClick={() => handleReset()}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.customer_name}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.apartment}</Th>
                <Th>{strings.time_period}</Th>
                <Th>{strings.date}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.publish_status}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackQuery?.data?.map(
                (feedbackItem: FeedbackSchema, index: number) => (
                  <FeedbackListItem
                    data={feedbackItem}
                    key={feedbackItem.reservation_id}
                    index={startingSN + index}
                    search={search}
                  />
                )
              )}
            </Tbody>
            {feedbackLoading && (
              <TableSkeletonLoader rows={filterParams.pageSize} cols={9} />
            )}
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default FeedbacksList;

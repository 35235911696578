import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  Textarea,
} from '@chakra-ui/react';
import { setTicketLogInfo } from 'actions/data/ticket';
import TicketResource from 'api/ticket';
import { strings } from 'config/localization';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

interface Props {
  ticketId: number;
  loggedInId: number;
  commentId?: number;
  handleComment: any;
}

interface CommentFormInputs {
  comment: string;
}

const CommentForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { ticketId, loggedInId, commentId, handleComment } = props;
  const ticketAPI = new TicketResource();
  const [errMsg, setErrMsg] = useState('');
  const methods = useForm<CommentFormInputs>();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const createComment = useMutation((data: CommentFormInputs) =>
    ticketAPI.ticketComment(ticketId, data.comment, loggedInId, commentId)
  );

  /**
   * Create Comment
   */
  const handleCreateComment = async (data: CommentFormInputs) => {
    createComment.mutate(data, {
      onSuccess: (res) => {
        handleReset();
        dispatch(setTicketLogInfo(res));
        handleComment(res); // call parent component
      },
      onError: () => setErrMsg(strings.comment_error),
    });
  };

  /**
   * Reset inputs
   */
  const handleReset = () => {
    methods.reset();
    setErrMsg('');
  };

  return (
    <>
      {errMsg && (
        <Alert status="error">
          <AlertIcon />
          {errMsg}
        </Alert>
      )}
      <form ref={form} onSubmit={methods.handleSubmit(handleCreateComment)}>
        <FormControl pb="4" isInvalid={!!methods.formState.errors.comment}>
          <Textarea
            id="comment"
            placeholder={strings.comment_here}
            {...methods.register('comment', {
              validate: (value) =>
                value?.trim() !== '' || strings.comment_required,
            })}
            w="100%"
          />
        </FormControl>
        <ButtonGroup>
          <Button
            size="lg"
            colorScheme="primary"
            type="submit"
            isLoading={createComment.isLoading}>
            {strings.comment}
          </Button>
          <Button
            size="lg"
            colorScheme="primary"
            variant="outline"
            onClickCapture={handleReset}>
            {strings.cancel}
          </Button>
        </ButtonGroup>
      </form>
    </>
  );
};
export default CommentForm;

import { Box, Heading } from '@chakra-ui/react';
import { customerScreenHeadingStyle } from 'assets/css/commonStyles';
import { WelcomeScreenImg } from 'assets/images';
import React from 'react';

const WelcomeScreen = () => {
  return (
    <Box
      h="100vh"
      bgImage={WelcomeScreenImg}
      backgroundSize="100% 100%"
      bgRepeat="no-repeat">
      <Heading
        display="flex"
        h="100vh"
        sx={customerScreenHeadingStyle}
        bg="rgba(0, 0, 0, 0.6)"
        alignItems="center"
        justifyContent="center"
        fontSize="12rem"
        fontWeight="black">
        Willkommen
      </Heading>
    </Box>
  );
};

export default WelcomeScreen;

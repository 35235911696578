import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CompressedUploader from 'components/common/CompressedUploader';
import { strings } from 'config/localization';
import {
  EXTERNAL_COMPANY_SEARCH_API,
  TICKET_ACCEPT_FILE_TYPE,
  TICKET_CUSTOMER_SEARCH_API,
} from 'constants/common';
import { ReactSelectOption } from 'constants/interfaces';
import { ExternalCompanySchema } from 'constants/schema';
import React, { forwardRef, useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import useWordSearch from '../../hooks/useWordSearch';
import CustomerCustomSelect from './CustomerCustomSelect';

const PRIORITIES = ['low', 'medium', 'high', 'urgent'];
const COSTRECIPIENTS = ['Guest', 'Object', 'Owner', 'Company', 'Other'];
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

interface User {
  id: number;
  email?: string;
  image_url?: string;
  name: string;
  status?: boolean;
  is_intermixed?: boolean;
}

interface Customer {
  id: number;
  name: string;
  is_intermixed: boolean;
}

interface Props {
  ticketData?: any;
  isBillable?: boolean;
  clearFileInputToggle?: boolean;
}

const customerQueryParams = {
  is_owner: false,
};

const TicketForm: React.FC<Props> = (props) => {
  const location = useLocation<any>();

  const { customerValue: customerObj, apartmentObj } = useSelector(
    (state: any) => ({
      customerValue: state?.data?.feedback?.customer,
      apartmentObj: state?.data?.feedback?.apartment,
    })
  );

  const {
    ticketData,
    isBillable = false,
    clearFileInputToggle = false,
  } = props;
  const ticketApi = new TicketResource();
  const ticketDueDate =
    ticketData && ticketData.due_date
      ? new Date(ticketData.due_date)
      : new Date();

  const ticketAppointmentDate =
    ticketData && ticketData.appointment_date
      ? new Date(ticketData.appointment_date)
      : null;

  const [dueDate, setDueDate] = useState<Date | null>(ticketDueDate);
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(
    ticketAppointmentDate
  );
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  const [companyInputKey, setCompanyInputKey] = useState(
    ticketData?.external_company?.name ?? ''
  );
  const [customerInputKey, setCustomerInputKey] = useState(
    ticketData?.customer?.name ?? ''
  );

  const [ownerName, setOwnerName] = useState(ticketData?.owner?.name || '');

  const {
    loading: customerListLoading,
    result: customerQuery,
    setQuery,
  } = useWordSearch(`${TICKET_CUSTOMER_SEARCH_API}/?is_owner=false&limit=50`);

  const {
    loading: companyListLoading,
    result: companyQuery,
    setQuery: setCompanyQuery,
  } = useWordSearch(`${EXTERNAL_COMPANY_SEARCH_API}`);

  const apartmentQuery = useQuery(
    [`apartmentList`],
    () => ticketApi.getApartmentList().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const userQuery = useQuery(
    'userList',
    () => ticketApi.getUserList().then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteAttachment = useMutation((attachmentId: number) =>
    ticketApi.destroyAttachment(attachmentId)
  );

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachment.mutate(attachmentId);
  };

  const apartmentList = apartmentQuery?.data?.data;
  const userList = userQuery?.data;
  const customerList = customerQuery?.data;
  const companyList = companyQuery?.data;

  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
    owner: apartment?.owner,
  }));

  const userOptions = userList?.map((user: any) => ({
    label: user.name,
    value: user.id,
  }));

  const customerOptions = customerList?.map((customer: Customer) => ({
    label: customer.name,
    value: customer.id,
    is_intermixed: customer.is_intermixed,
    isDisabled: customer.is_intermixed,
  }));

  const companyOptions = companyList?.map((company: ExternalCompanySchema) => ({
    label: company.name,
    value: company.id,
  }));

  // Set defaultValue with setValue as the inline defaultValue is lost occasionally on page refresh

  // setValue (default value) for object/apartment
  useEffect(() => {
    if (!ticketData) return;
    const defaultValue = apartmentOptions?.find(
      (item: ReactSelectOption) => item.value === ticketData?.apartment_id
    );
    if (defaultValue?.value) setValue('apartment_id', defaultValue);
  }, [apartmentOptions, ticketData, setValue]);

  // setValue (default value) for assignee/user
  useEffect(() => {
    if (!ticketData) return;
    const defaultValue = userOptions?.find(
      (item: ReactSelectOption) => item.value === ticketData?.assigned_user_id
    );
    if (defaultValue?.value) setValue('assigned_user_id', defaultValue);
  }, [userOptions, ticketData, setValue]);

  // setValue (default value) for customer
  useEffect(() => {
    if (!ticketData) return;
    if (!ticketData.customer) return;

    let { id, name, is_intermixed } = ticketData.customer;
    let defaultValue = { value: id, label: name, isDisabled: is_intermixed };
    setQuery({ name });
    setValue('customer_id', defaultValue);
  }, [ticketData, setValue, setQuery]);

  //setValue(default value) for customer and apartment and houseOwner from feedback detail page
  useEffect(() => {
    if (location?.state?.redirectedFromFeedback) {
      setValue('customer_id', customerObj);

      const apartment = apartmentObj;
      const apartmentValue = apartmentOptions?.find(
        (item: ReactSelectOption) => item.value === apartment?.value
      );
      setValue('apartment_id', apartmentValue);

      let ownerValue = {
        value: apartment?.owner?.id,
        label: apartment?.owner?.name,
        isDisabled: apartment?.owner?.is_intermixed,
      };
      if (ownerValue.value) {
        setOwnerName(ownerValue.label);
        setValue('house_owner', {
          label: ownerValue.label,
          value: ownerValue.value,
        });
      }
    }
  }, [
    apartmentObj,
    apartmentOptions,
    customerObj,
    location?.state?.redirectedFromFeedback,
    setValue,
  ]);

  //setValue (default value) for external company
  useEffect(() => {
    if (!ticketData) return;
    if (!ticketData.external_company) return;

    let { id, name } = ticketData.external_company;
    let defaultValue = { value: id, label: name };

    setCompanyQuery({ keyword: name });
    setValue('external_company_id', defaultValue);
  }, [ticketData, setValue, setCompanyQuery]);

  // setValue (default value) for house owner
  useEffect(() => {
    if (!ticketData) return;
    if (!ticketData?.owner) return;

    let { id, name, is_intermixed } = ticketData.owner;
    let defaultValue = { value: id, label: name, isDisabled: is_intermixed };

    if (defaultValue?.value) setValue('house_owner', defaultValue);
  }, [ticketData, setValue]);

  // setValue (default value) for rest of the form inputs
  useEffect(() => {
    if (!ticketData) return;
    setValue('title', ticketData.title);
    setValue('cost_recipient', ticketData.cost_recipient);
    setValue('priority', ticketData.priority);
    setValue('description', ticketData.description);
    setValue('is_billable', ticketData.is_billable ? true : false);
    setValue('house_owner', ticketData.house_owner);
    if (!ticketData.due_date) {
      setValue('due_date', new Date());
    }
  }, [ticketData, setValue]);

  if (apartmentQuery.isLoading && customerListLoading && userQuery.isLoading)
    return <CenterSpinner />;

  const isTicketTypeBillable = ticketData
    ? !ticketData?.recursive_interval
    : isBillable;

  const handleCustomerInputChange = (
    id: number,
    name: string,
    is_intermixed: boolean
  ) => {
    setValue('customer_id', {
      value: id,
      label: name,
      isDisabled: is_intermixed,
    });
  };

  const handleCompanyChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setCompanyInputKey(value);
      setCompanyQuery({ keyword: value });
    }
  };

  const handleApartment = (apartment: any) => {
    if (apartment?.owner) {
      setOwnerName(apartment?.owner?.name || '');
      setValue('house_owner', {
        label: apartment?.owner?.name,
        value: apartment?.owner?.id,
      });
    } else {
      setOwnerName('');
      setValue('house_owner', {
        label: '',
        value: '',
      });
    }
  };
  const handleDateChange = (date: Date) => {
    setDueDate(date);
    setValue('due_date', date);
  };

  const handleDateTimeChange = (date: Date) => {
    setAppointmentDate(date);
    var parseDate = Date.parse(date?.toISOString());
    setValue('appointment_date', parseDate);
  };

  return (
    <Stack direction="column" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl isInvalid={!!errors?.title} isRequired>
            <FormLabel>{strings.title}</FormLabel>
            <Input
              id="title"
              size="lg"
              type="text"
              placeholder={strings.title}
              {...register('title', {
                validate: (value) => {
                  if (!value) return strings.title_required;
                  if (value.trim() === '') return strings.title_required;
                },
              })}
            />
            <FormErrorMessage>
              <>{errors?.title && errors?.title?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.apartment_id}>
            <FormLabel>{strings.object}</FormLabel>
            <Controller
              control={control}
              name="apartment_id"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="apartment_id"
                  placeholder={strings.select_object}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleApartment(selectedOption);
                  }}
                  options={apartmentOptions}
                  styles={reactSelectStyles}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.apartment_id &&
                (errors.apartment_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.assigned_user_id}>
            <FormLabel>{strings.assignee}</FormLabel>
            <Controller
              control={control}
              name="assigned_user_id"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="assigned_user_id"
                  placeholder={strings.select_assignee}
                  options={userOptions}
                  styles={reactSelectStyles}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.assigned_user_id &&
                (errors.assigned_user_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.customer_id}>
            <FormLabel>{strings.customer}</FormLabel>
            <Controller
              control={control}
              name="customer_id"
              render={({ field }) => (
                <CustomerCustomSelect
                  id="customer_id"
                  placeholder={strings.select_customer}
                  value={field.value ? field.value.label : null}
                  onChange={handleCustomerInputChange}
                  parentQueries={customerQueryParams}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.customer_id &&
                (errors.customer_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.cost_recipient}>
            <FormLabel>{strings.cost_recipient}</FormLabel>
            <Select
              size="lg"
              id="cost_recipient"
              placeholder={strings.select_cost_recepient}
              {...register('cost_recipient')}>
              {COSTRECIPIENTS.map((costRecipient: any) => {
                return (
                  <option key={costRecipient} value={costRecipient}>
                    {strings.getString(costRecipient.toLowerCase())}
                  </option>
                );
              })}
            </Select>
            <FormErrorMessage>
              <>{errors?.cost_recipient && errors?.cost_recipient?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>{strings.house_owner}</FormLabel>
            <Input
              id="house_owner"
              type="hidden"
              {...register('house_owner')}
            />
            <Input size="lg" id="house_owner" value={ownerName} disabled />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.priority}>
            <FormLabel>{strings.priority_label}</FormLabel>
            <Select
              size="lg"
              id="priority"
              placeholder={strings.select_priority}
              {...register('priority')}>
              {PRIORITIES.map((priority: any) => {
                return (
                  <option key={priority} value={priority}>
                    {strings
                      .getString(priority.split('-').join(''))
                      .toUpperCase()}
                  </option>
                );
              })}
            </Select>
            <FormErrorMessage>
              <>{errors?.priority && errors?.priority?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.description}>
            <FormLabel>{strings.descriptions}</FormLabel>
            <Textarea
              id="description"
              placeholder={strings.write_description}
              {...register('description')}
            />
            <FormErrorMessage>
              <>{errors?.description && errors?.description?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl>
            <FormLabel>{strings.upload_files}</FormLabel>
            <CompressedUploader
              uploadedFiles={ticketData?.ticket_attachments}
              deleteAttachment={(attachmentId: number) =>
                handleDeleteAttachment(attachmentId)
              }
              acceptFileType={TICKET_ACCEPT_FILE_TYPE}
              clearFileInputToggle={clearFileInputToggle}
              fileKey="files"
            />
          </FormControl>
        </GridItem>
        {isTicketTypeBillable && (
          <GridItem gridColumnStart={1}>
            <FormControl>
              <FormLabel>{strings.billability}</FormLabel>
              <Controller
                name="is_billable"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    isChecked={field.value}>
                    {strings.billable}?
                  </Checkbox>
                )}
              />
            </FormControl>
          </GridItem>
        )}
      </Grid>
    </Stack>
  );
};

export default TicketForm;

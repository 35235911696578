import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FeedbackQuestionResource from 'api/feedback_question';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  data: any;
  index: number;
  draggableProvider: any;
}

const QuestionListItem: React.FC<Props> = (props) => {
  const { data, index, draggableProvider } = props;
  const { id: feedbackQuestionID, status } = data;
  const { search } = useLocation();

  // Library Init
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [localStatus, setLocalStatus] = useState<number>(status);
  const checkEditPermission = useIsPermitted([
    PermissionRequest['manage:feedback'],
  ]);

  const feedbackQuestionAPI = new FeedbackQuestionResource();
  const updateStatus = useMutation((formdata: any) =>
    feedbackQuestionAPI.update(feedbackQuestionID, formdata)
  );

  const deleteFeedbackAPI = useMutation(
    (id: number) => feedbackQuestionAPI.destroy(id),
    {
      onSuccess: () => {
        setIsDeleteLoading(false);
        setDeleteModalOpen(false);
        toast({
          title: strings.success,
          description: strings.feedback_question_deleted_successfully,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries('feedbackQuestionsList');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  function submitStatusUpdate(formData: any) {
    updateStatus.mutate(formData, {
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
        setLocalStatus(status);
      },
    });
  }

  function handleStatusChange(e: any) {
    let checked = e.target.checked ? 1 : 0;
    let stateObj = { status: checked, _method: 'PUT' };
    submitStatusUpdate(stateObj);
    setLocalStatus(checked);
  }
  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteLoading(false);
    setDeleteModalOpen(false);
  };

  function onDeleteConfirm() {
    setIsDeleteLoading(true);
    deleteFeedbackAPI.mutate(feedbackQuestionID);
  }

  let feedbackQuestionType: string = '';
  switch (data.type) {
    case 'open':
      feedbackQuestionType = strings.open_ended;
      break;
    case 'closed':
      feedbackQuestionType = strings.closed_ended;
      break;
    case 'rating':
      feedbackQuestionType = strings.rating;
      break;
    default:
      feedbackQuestionType = '';
  }

  return (
    <Tr
      key={feedbackQuestionID}
      ref={draggableProvider.innerRef}
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}>
      <Td>{index}</Td>
      <Td>
        <Text>{capitalizeFirstLetter(data.name)}</Text>
      </Td>
      <Td>{feedbackQuestionType}</Td>
      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="primary"
            isDisabled={!checkEditPermission}
            isChecked={!!localStatus}
            onChange={handleStatusChange}
          />
        </FormControl>
      </Td>
      <Td>
        <RouterLink
          to={routes.feedback.questions.edit.replace(
            ':id',
            feedbackQuestionID.toString() + search
          )}>
          <Tooltip hasArrow label={strings.edit_question}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_question}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_question}>
          <IconButton
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete_question}
            color="red.300"
            minW="8"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          closeOnOverlayClick={!isDeleteLoading}
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_question}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={onDeleteConfirm}>
                  {strings.delete}
                </Button>

                <Button
                  variant="outline"
                  isDisabled={isDeleteLoading}
                  onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default QuestionListItem;

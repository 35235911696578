import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import CRMEventLogResource from 'api/crm-eventLogs';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import RecursiveInfoForm from 'components/ticket/RecursiveInfoForm';
import TicketForm from 'components/ticket/TicketForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { TicketInfoSchema } from 'constants/schema';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DateFormatYMD } from 'utils/DateFormat';
import history from 'utils/history';

function matchURL() {
  return matchPath(location.pathname, {
    path: routes.ticket.task.view,
    exact: true,
    strict: false,
  });
}
const isTicketBoardView = matchURL();

const EditTicket: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const methods = useForm<TicketInfoSchema>();
  const { search } = useLocation();

  const ticketApi = new TicketResource();
  const eventLogAPI = new CRMEventLogResource();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );
  const ticketQuery = useQuery<TicketInfoSchema>(
    [`ticket${id}-edit`, id],
    () => ticketApi.get(id).then((res) => res.data.data),
    { cacheTime: 0, refetchOnWindowFocus: false }
  );
  const [isLargerThan1140] = useMediaQuery('(min-width: 1140px)');
  const createEventLog = useMutation((data: any) => eventLogAPI.store(data));
  const updateTicket = useMutation((data: TicketInfoSchema) =>
    ticketApi.update(id, data)
  );

  const uploadFiles = useMutation((formData: any) =>
    ticketApi.uploadToBucket(formData)
  );

  if (ticketQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ticketQuery.isError) {
    history.push(routes.ticket.list.default);
  }

  const handleUploadFiles = async (files: any) => {
    const result = [];
    for (let index = 0; index < files.length; index += 1) {
      const formData = new FormData();
      formData.append('image', files[index]);
      const uploads: any = await uploadFiles.mutateAsync(formData);
      const { status, file_name, mime_type, file_size, file_path } =
        uploads?.data;
      if (status) {
        result.push({
          file_name: file_name,
          mime_type: mime_type,
          file_size: file_size,
          file_path: file_path,
        });
      }
    }
    return result;
  };

  const handleEditTicket = async (data: any) => {
    // upload to Bucket First
    let files: any = [];
    if (data.files?.length > 0) {
      files = await handleUploadFiles(data.files);
      data.files = files;
    }
    // Create Ticket
    let cleanData: any = setFormData(data);
    cleanData.updated_by = loggedInUser.id;
    cleanData && (await saveTicket(cleanData));
  };

  /**
   * Actual save ticket
   */
  const saveTicket = (formData: any) => {
    updateTicket.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.ticket_updated,
          status: 'success',
          isClosable: true,
        });

        let customer_id = ticketQuery?.data?.customer_id;
        let house_owner_id = ticketQuery?.data?.house_owner;
        //Create event log from here
        const eventLogData = {
          event: 'ticket',
          event_id: res.data.ticket_id,
          contact_id: formData.customer_id,
          event_time: res.data.updated_at,
        };

        const eventLogDataOwner = {
          event: 'ticket',
          event_id: res.data.ticket_id,
          contact_id: formData.house_owner,
          event_time: res.data.updated_at,
        };

        if (formData.customer_id && customer_id !== formData.customer_id) {
          createEventLog.mutate(eventLogData, {});
        }
        if (formData.house_owner && house_owner_id !== formData.house_owner) {
          createEventLog.mutate(eventLogDataOwner, {});
        }
        history.goBack();
      },
    });
  };
  const showActionButton = () => {
    return (
      <Flex justifyContent="flex-end">
        <ButtonGroup>
          <Button
            size="lg"
            colorScheme="primary"
            variant="outline"
            onClick={() => history.goBack()}
            isDisabled={updateTicket.isLoading || uploadFiles.isLoading}>
            {strings.cancel}
          </Button>
          <Button
            size="lg"
            colorScheme="primary"
            isLoading={uploadFiles.isLoading || updateTicket.isLoading}
            disabled={uploadFiles.isLoading || updateTicket.isLoading}
            onClick={methods.handleSubmit(handleEditTicket)}
            type="submit">
            {strings.save_now}
          </Button>
        </ButtonGroup>
      </Flex>
    );
  };
  /**
   * Remove keys if no data
   */
  const setFormData = (data: TicketInfoSchema) => {
    let formData: any = data;
    if (formData.recursive_from)
      formData.recursive_from = DateFormatYMD(formData.recursive_from);
    if (formData.recursive_until)
      formData.recursive_until = DateFormatYMD(formData.recursive_until);
    if (formData.apartment_id?.value)
      formData.apartment_id = formData.apartment_id.value;
    if (formData.assigned_user_id?.value)
      formData.assigned_user_id = formData.assigned_user_id.value;
    if (formData.customer_id?.value)
      formData.customer_id = formData.customer_id.value;
    if (formData.house_owner?.value)
      formData.house_owner = formData.house_owner.value;
    if (formData.external_company_id?.value)
      formData.external_company_id = formData.external_company_id.value;

    let cleanObj: any = {};
    Object.keys(formData).forEach((val: any) => {
      const newVal = formData[val];
      cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
    });
    return cleanObj;
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                (isTicketBoardView
                  ? routes.ticket.task.board
                  : routes.ticket.list.default) + search
              }>
              {isTicketBoardView ? strings.ticket : strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              ticket{id} - {ticketQuery?.data?.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.edit_ticket}
          </Heading>
        </Flex>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleEditTicket)}>
            <Box mb={4}>
              <Stack sx={wrapperStyles}>
                <TicketForm ticketData={ticketQuery?.data} />
              </Stack>
              {ticketQuery?.data?.recursive_interval && (
                <RecursiveInfoForm
                  repeatInterval={ticketQuery?.data?.recursive_interval}
                  repeatFrom={new Date(ticketQuery?.data?.recursive_from)}
                  repeatUntil={new Date(ticketQuery?.data?.recursive_until)}
                />
              )}
            </Box>
            {showActionButton()}
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};
export default EditTicket;

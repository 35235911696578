import routes from 'constants/routes';
import { ServiceListSchema } from 'constants/schema';
import {
  BiBell,
  BiBookBookmark,
  BiBuildingHouse,
  BiChat,
  BiCoffee,
  BiCreditCard,
  BiFile,
  BiHelpCircle,
  BiKey,
  BiMailSend,
  BiMapAlt,
  BiMessageRoundedEdit,
  BiPurchaseTag,
  BiSupport,
  BiUser,
  BiUserPin,
} from 'react-icons/bi';

export const serviceListData: ServiceListSchema[] = [
  {
    id: 1,
    serviceName: 'user',
    hideForLabel: 'user-service',
    tooltipLabel: 'user_management_system',
    icon: BiUser,
    splashLabel: 'user_management',
    rootLink: '/users',
    routes: [
      [routes.users.list, 'read:user'],
      [routes.users.roles.list, 'read:user'],
      [routes.users.externalCompanies.list, 'manage:external-companies'],
      [routes.users.loginLog.list, 'read:login-log'],
    ],
    subMenusComp: 'UserSubMenuList',
  },
  {
    id: 2,
    serviceName: 'chat',
    hideForLabel: 'chat-service',
    tooltipLabel: 'chat_system',
    icon: BiChat,
    splashLabel: 'chat',
    rootLink: '/chat',
    routes: [
      [routes.chat.default, 'read:chat'],
      [routes.chat.closed.list, 'read:chat'],
      [routes.chat.metrics, 'read:chat-metrics'],
    ],
    subMenusComp: 'ChatSubMenuList',
  },
  {
    id: 3,
    serviceName: 'ticket',
    hideForLabel: 'ticket-service',
    tooltipLabel: 'ticket_system',
    icon: BiPurchaseTag,
    splashLabel: 'ticket',
    rootLink: '/ticket',

    routes: [
      [routes.ticket.task.board, 'read:ticket'],
      [routes.ticket.list.default, 'read:ticket'],
      [routes.ticket.objectsInfo, 'read:ticket'],
    ],
    subMenusComp: 'TicketSubMenuList',
  },
  {
    id: 4,
    serviceName: 'crm',
    hideForLabel: 'crm-service',
    tooltipLabel: 'customer_relation_management',
    icon: BiUserPin,
    splashLabel: 'crm',
    rootLink: '/crm',

    routes: [
      [routes.crm.contact.list, 'read:crm'],
      [routes.crm.merge.list, 'merge:contacts'],
    ],
    subMenusComp: 'CrmSubMenuList',
  },
  {
    id: 5,
    serviceName: 'contactCenter',
    hideForLabel: 'contact-center-service',
    tooltipLabel: 'contact_center',
    icon: BiSupport,
    splashLabel: 'contact_center',
    rootLink: '/contact-center',
    routes: [
      [routes.contactCenter.members.list, 'read:contact-center'],
      [routes.contactCenter.totalCalls.list, 'read:contact-center'],
    ],
    subMenusComp: 'ContactCenterSubMenuList',
  },
  {
    id: 6,
    serviceName: 'feedback',
    hideForLabel: 'feedback-service',
    tooltipLabel: 'feedback_system',
    icon: BiMessageRoundedEdit,
    splashLabel: 'feedback',
    rootLink: '/feedback',
    routes: [
      [routes.feedback.feedbacks.list, 'read:feedback'],
      [routes.feedback.dashboard, 'read:feedback'],
      [routes.feedback.analysis, 'read:feedback'],
      [routes.feedback.mails.list, 'read:feedback'],
      [routes.feedback.dashboard, 'manage:feedback'],
      [routes.feedback.setting.list, 'manage:feedback'],
    ],
    subMenusComp: 'FeedbackSubMenuList',
  },
  {
    id: 9,
    serviceName: 'dms',
    hideForLabel: 'dms-service',
    tooltipLabel: 'document_management_system',
    icon: BiFile,
    splashLabel: 'dms',
    rootLink: '/dms',
    routes: [
      [routes.dms.generalList.list, 'read:dms', 'is:owner'],
      [routes.dms.customerDocuments.list, 'read:customer-dms'],
      [routes.dms.ownerDocuments.list, 'read:owner-dms', 'is:owner'],
      [routes.dms.objectDocuments.list, 'read:object-dms', 'is:owner'],
      [routes.dms.userDocuments.list, 'read:dms'],
    ],
    subMenusComp: 'DMSSubMenuList',
  },
  {
    id: 10,
    serviceName: 'oms',
    hideForLabel: 'oms-service',
    tooltipLabel: 'object_management_system',
    icon: BiBuildingHouse,
    splashLabel: 'objects',
    rootLink: '/oms',
    routes: [[routes.oms.objects.list, 'read:oms', 'is:owner']],
    subMenusComp: 'OmsSubMenuList',
  },
  {
    id: 11,
    serviceName: 'sms',
    hideForLabel: 'sms-service',
    tooltipLabel: 'service_management_system',
    icon: BiCoffee,
    splashLabel: 'sms',
    rootLink: '/sms',
    routes: [
      [routes.sms.services.list, 'read:sms'],
      [routes.sms.serviceGroups.list, 'read:sms'],
    ],
    subMenusComp: 'SMSSubMenuList',
  },
  {
    id: 12,
    serviceName: 'bms',
    hideForLabel: 'bms-service',
    tooltipLabel: 'booking_management_system',
    icon: BiBookBookmark,
    splashLabel: 'bms',
    rootLink: '/bms',
    routes: [
      [routes.bms.booking.list, 'read:bookings', 'is:owner'],
      [routes.bms.bookingCalendar.base, 'read:bookings', 'is:owner'],
      [routes.bms.checkInCheckOut.list, 'read:bookings'],
      [routes.bms.invoices.list, 'read:invoice'],
    ],
    subMenusComp: 'BMSSubMenuList',
  },
  {
    id: 14,
    serviceName: 'pms',
    hideForLabel: 'pms-service',
    tooltipLabel: 'payment_management_system',
    icon: BiCreditCard,
    splashLabel: 'pms',
    rootLink: '/pms',
    routes: [
      [routes.pms.creditCard.list, 'read:pms'],
      [routes.pms.paypal.list, 'read:pms'],
      [routes.pms.paymentLinks.list, 'read:pms'],
      [routes.pms.bankTransfer.list, 'read:pms'],
    ],
    subMenusComp: 'PMSSubMenuList',
  },
  {
    id: 15,
    serviceName: 'pns',
    hideForLabel: 'pns-service',
    tooltipLabel: 'push_notification_system',
    icon: BiBell,
    splashLabel: 'pns',
    rootLink: '/pns',
    routes: [[routes.pns.notification.list, 'read:pns']],
    subMenusComp: 'PNSSubMenuList',
  },

  {
    id: 16,
    serviceName: 'faq',
    hideForLabel: 'faq-service',
    tooltipLabel: 'frequently_ask_question',
    icon: BiHelpCircle,
    splashLabel: 'faq',
    rootLink: '/faq',
    routes: [
      [routes.faq.faqList.list, 'read:faq'],
      [routes.faq.internalfaq.list, 'read:internal-faq'],
      [routes.faq.faqGroup.list, 'read:faq-group'],
    ],
    subMenusComp: 'FAQSubMenuList',
  },
  {
    id: 17,
    serviceName: 'newsletter',
    hideForLabel: 'newsletter-service',
    tooltipLabel: 'newsletter',
    icon: BiMailSend,
    splashLabel: 'newsletter',
    rootLink: '/newsletter',
    routes: [
      [routes.newsletter.newsletters.list, 'read:newsletter'],
      [routes.newsletter.draft.list, 'read:newsletter'],
    ],
    subMenusComp: 'NewsletterSubMenuList',
  },
  {
    id: 18,
    serviceName: 'key',
    hideForLabel: 'key-service',
    tooltipLabel: 'key_management_system',
    icon: BiKey,
    splashLabel: 'key',
    rootLink: '/key',
    routes: [
      [routes.key.KeyLogs.list, 'read:keylog'],
      [routes.key.keyList.list, 'read:keylist'],
    ],
    subMenusComp: 'KeySubMenuList',
  },
  {
    id: 19,
    serviceName: 'aerialMap',
    hideForLabel: 'aerial-map-service',
    tooltipLabel: 'aerial_map',
    icon: BiMapAlt,
    splashLabel: 'aerial_map',
    rootLink: '/aerial-map',
    routes: [['AERIAL_MAP_URL', 'read:aerial-map']],
    subMenusComp: 'null',
  },
];

import {
  Box,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import ReactDatePickerRoot from 'components/ReactDatePickerRoot/ReactDatePickerRoot';
import { strings } from 'config/localization';
import { PaymentSchema } from 'constants/schema';
import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  data?: any;
  id?: number;
}

const DatePickerInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

DatePickerInput.displayName = 'DatePickerInput';

const ManualPaymentForm: React.FC<Props> = (props) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<PaymentSchema>();

  return (
    <form>
      <GridItem gridColumn={1}>
        <FormControl isInvalid={!!errors?.received_date} isRequired>
          <FormLabel>{strings.date_of_receipt_of_payment}</FormLabel>
          <Controller
            control={control}
            name={'received_date'}
            rules={{ required: strings.date_is_required }}
            render={({ field }: any) => {
              return (
                <ReactDatePickerRoot
                  placeholderText={strings.select_date}
                  dateFormat="dd.MM.yyyy"
                  selected={field.value && new Date(field.value)}
                  customInput={<DatePickerInput size="lg" />}
                  onChange={(value) => {
                    return field.onChange(value);
                  }}
                  maxDate={new Date()}
                  portalId="root-popover"
                />
              );
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem gridColumn={1} mt="8">
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.payment_method}:</FormLabel>
          <Box sx={infoStyles}>{strings.bank_transfer}</Box>
        </FormControl>
      </GridItem>

      <GridItem gridColumn={1} mt="8">
        <FormControl isInvalid={!!errors?.amount} isRequired>
          <FormLabel>{strings.amount} (CHF)</FormLabel>
          <Input
            size="lg"
            type="number"
            placeholder={strings.amount}
            {...register('amount', {
              required: strings.total_amount_is_required,
            })}
          />
        </FormControl>
      </GridItem>

      <GridItem gridColumn={1} gridColumnEnd={[1, 4]} mt="8">
        <FormControl isInvalid={!!errors?.remarks}>
          <FormLabel>{strings.notes_notizen}</FormLabel>
          <Textarea
            placeholder={strings.further_remarks_notes}
            {...register('remarks')}
          />
        </FormControl>
      </GridItem>
    </form>
  );
};

export default ManualPaymentForm;

import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { setPermissions } from 'actions/data/auth';
import { getAuthPermission } from 'api/auth';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import UserForm from 'components/user/UserForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { unmaskPhone } from 'utils';

const EditUser: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();

  const methods = useForm<UserSchema>();

  const userAPI = new UserResource();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const userQuery = useQuery(
    [`user${id}-edit`, id],
    () => userAPI.get(id).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateUser = useMutation((data: UserSchema) =>
    userAPI.update(id, data)
  );

  const onSubmit = (data: UserSchema) => {
    const permissionObj = { ...data.permissions };
    const permissions = Object.keys(permissionObj).filter(
      (k: any) => permissionObj[k]
    );
    data.permissions = permissions;
    data.contact_number = unmaskPhone(data.contact_number);

    updateUser.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.user_updated,
          status: 'success',
          isClosable: true,
        });
        if (+id === +loggedInUser.id) updatePermissionsInStore();
        queryClient.invalidateQueries('userList');
        history.push(routes.users.list);
      },
    });
  };

  const updatePermissionsInStore = async () => {
    const response = await getAuthPermission();
    const updatedPermissions = response?.data?.data;
    if (!updatedPermissions) return;
    dispatch(setPermissions(updatedPermissions));
  };

  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError || userQuery?.data?.data?.role === 'owner') {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {userQuery?.data?.data?.first_name}{' '}
            {userQuery?.data?.data?.last_name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg">{strings.edit_user}</Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.user_udpate_failed}
                </Alert>
              )}
              <UserForm userData={userQuery.data.data} />
              <ButtonGroup justifyContent="flex-end">
                <Button
                  colorScheme="primary"
                  size="lg"
                  variant="outline"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateUser.isLoading}>
                  {strings.update}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditUser;

import { useToast } from '@chakra-ui/react';
import { ChatActionTypes, setChatItemList } from 'actions/data/chat';
import { searchChatUser } from 'api/chat';
import { strings } from 'config/localization';
import { MYVAL_SERVICE_CENTER_TITLE } from 'constants/common';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { socket } from 'services/socket';
import listinfoLogo from '../../assets/images/logo-default.png';

function displayNotification(title: string, options: any) {
  if (Notification.permission === 'granted') {
    navigator.serviceWorker.getRegistration().then(function (reg: any) {
      reg.showNotification(title, options);
    });
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        navigator.serviceWorker.getRegistration().then(function (reg: any) {
          reg.showNotification(title, options);
        });
      }
    });
  }
}
const CHAT_STATUS_UNREAD = 'unread';
const CHAT_STATUS_READ = 'read';
const CHAT_SENDER_EMPLOYEE = 'employee';

const ChatRegister = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const dispatch = useDispatch();

  const [totalUnreads, setTotalUnreads] = useState<number>(0);

  const { loggedInUser, chatPageVisible } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      chatPageVisible: state.data.chat.chatPageVisible,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (totalUnreads === 0) {
      document.title = MYVAL_SERVICE_CENTER_TITLE;
    } else if (totalUnreads > 0) {
      document.title = `(${totalUnreads}) ${MYVAL_SERVICE_CENTER_TITLE}`;
    }
  }, [totalUnreads]);

  /**
   * Update Number of chat
   */
  useEffect(() => {
    socket.connect();
    socket.on('chat message', (data: any) => {
      if (loggedInUser.id === data.assinged_employee) {
        queryClient.invalidateQueries('fetchSearchChatUser');
        if (
          data.sender !== CHAT_SENDER_EMPLOYEE &&
          data.status === CHAT_STATUS_UNREAD &&
          !chatPageVisible
        ) {
          let title = `New Message From ${data.name}`;

          let options = {
            body: data.message,
            icon: listinfoLogo,
          };
          displayNotification(title, options);
        }
      }
    });

    return () => {
      socket.off('chat message');
      socket.disconnect();
    };
  }, [chatPageVisible, loggedInUser, queryClient]);

  /**
   * Handle new assigned user
   */
  useEffect(() => {
    socket.on('assigned new chat', (data: any) => {
      if (loggedInUser.id === data.employee_id) {
        queryClient.invalidateQueries('fetchSearchChatUser');
      }
    });
  }, [loggedInUser.id, queryClient]);

  /**
   * Handle chat transferred by other employees
   */
  useEffect(() => {
    socket.on('transferred new chat', (data: any) => {
      if (loggedInUser.id === data.employee_id) {
        queryClient.invalidateQueries('fetchSearchChatUser');
      }
    });
  }, [loggedInUser.id, queryClient]);

  /**
   * Get Inprogress Chat
   */
  const fetchSearchChatUser = async () => {
    let query: any = {
      status: 'in_progress',
    };
    const chatLists = await searchChatUser(loggedInUser.id, query);
    chatLists?.data?.data?.map((res: any) => {
      socket.emit(
        'join room',
        {
          username: loggedInUser.name,
          roomName: res.room_name,
        },
        (cb: any) => {
          if (cb.status !== 'ok')
            toast({
              title: strings.join_room_error,
              status: 'error',
              isClosable: true,
            });
        }
      );
      let totalUnreads = chatLists?.data?.data?.reduce(
        (acc: number, item: any) => {
          return acc + item.employee_unread_chat ?? 0;
        },
        0
      );
      setTotalUnreads(totalUnreads);
      dispatch({
        type: ChatActionTypes.SET_CHAT_UNREAD_COUNT,
        data: totalUnreads,
      });
    });
    dispatch(setChatItemList(chatLists?.data?.data));
  };

  const { isLoading } = useQuery('fetchSearchChatUser', () =>
    fetchSearchChatUser()
  );

  useEffect(() => {
    dispatch({
      type: ChatActionTypes.SET_EMPLOYEE_SEARCH_LOADING,
      data: isLoading,
    });
  }, [dispatch, isLoading]);

  return null;
};

export default ChatRegister;

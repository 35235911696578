import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FAQResource from 'api/faq';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FaqForm from 'components/faq/FaqForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { buildFormData } from 'utils';
import history from 'utils/history';

const EditFaq: React.FC = () => {
  const { id: faqID }: any = useParams();

  const methods = useForm<any>({ defaultValues: { _method: 'PUT' } });
  const toast = useToast();

  let faqGroupAPI = new FAQGroupResource();
  let FAQAPI = new FAQResource();

  const { search } = useLocation();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: faqDetail, isLoading: faqDetailLoading } = useQuery(
    ['faq-edit-page', faqID],
    () => {
      if (!faqID) return null;
      return FAQAPI.get(faqID)
        .then((res) => res.data.data)
        .catch();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const faqGroups = useQuery(
    ['list-faq-groups'],
    async () => {
      const res = await faqGroupAPI.list();
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateFAQ = useMutation((data: any) => FAQAPI.updateFAQ(faqID, data));

  const onSubmit = async (data: any) => {
    const formData = buildFormData(data);

    if (data?.file_object === null) formData.append('file_object', 'null');
    if (data?.file_contact === null) formData.append('file_contact', 'null');
    setIsLoading(true);
    updateFAQ.mutate(formData, {
      onSuccess: () => {
        toast({
          title: `${strings.faq} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.faq.faqList.list);
      },
      onError: () => {
        setErrMsg(`${strings.faq} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.faq.faqList.list + search}>
              {strings.faq}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {faqDetail?.faq_group?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.edit_faq}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              {faqDetailLoading || !faqGroups?.data ? (
                <CenterSpinner />
              ) : (
                <FaqForm data={faqDetail} faqGroups={faqGroups} />
              )}
              <ButtonGroup pt="4" justifyContent="flex-end" w="100%">
                <RouterLink to={routes.faq.faqList.list}>
                  <Button
                    colorScheme="primary"
                    size="lg"
                    variant="outline"
                    isDisabled={isLoading}>
                    {strings.cancel}
                  </Button>
                </RouterLink>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {strings.edit_faq}
                </Button>
              </ButtonGroup>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditFaq;

import {
  THEME_AERIAL_MAP,
  THEME_BMS,
  THEME_CHAT,
  THEME_CONTACT_CENTER,
  THEME_CRM,
  THEME_DMS,
  THEME_FAQ,
  THEME_FEEDBACK,
  THEME_KEY,
  THEME_NEWS,
  THEME_OMS,
  THEME_PMS,
  THEME_PNS,
  THEME_SMS,
  THEME_TERMINAL,
  THEME_TICKET,
  THEME_USER,
} from 'constants/common';
export const CustomTheme = (pathName: string): string => {
  const path = pathName.split('/');
  switch (path[1]) {
    case 'users':
      return THEME_USER;
    case 'chat':
      return THEME_CHAT;
    case 'ticket':
      return THEME_TICKET;
    case 'crm':
      return THEME_CRM;
    case 'contact-center':
      return THEME_CONTACT_CENTER;
    case 'feedback':
      return THEME_FEEDBACK;
    case 'dms':
      return THEME_DMS;
    case 'oms':
      return THEME_OMS;
    case 'sms':
      return THEME_SMS;
    case 'bms':
      return THEME_BMS;
    case 'pms':
      return THEME_PMS;
    case 'pns':
      return THEME_PNS;
    case 'faq':
      return THEME_FAQ;
    case 'newsletter':
      return THEME_NEWS;
    case 'key':
      return THEME_KEY;
    case 'aerial-map':
      return THEME_AERIAL_MAP;
    case 'terminal':
      return THEME_TERMINAL;
    default:
      return '';
  }
};

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { logsData } from 'data/logsData';
import React, { forwardRef, useState } from 'react';
import { BiCalendar, BiGitMerge } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

const Logs: React.FC = () => {
  const CreatedAtInput = forwardRef((props: any, ref: any) => {
    return <Input {...props} />;
  });

  CreatedAtInput.displayName = 'CreatedAtInput';
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };
  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
              {strings.contact_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.crm.logs.list}>
              {strings.logs}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.logs}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction="row" spacing="4" align="end">
              <FormControl>
                <FormLabel>{strings.category}</FormLabel>
                <Select placeholder={strings.category} rounded="sm">
                  <option>All</option>
                  <option>Owner</option>
                  <option>Guest</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.merged_date}</FormLabel>
                <InputGroup>
                  <ReactDatePickerRoot
                    placeholderText={strings.select_date}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CreatedAtInput />}
                    onChange={handleDateChange}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    value={getFormattedRangeDate(startDate, endDate)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                  <InputRightElement
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                </InputGroup>
              </FormControl>
              <Stack direction="row" spacing="4" pt="8">
                <Button colorScheme="primary" type="submit" variant="outline">
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.category}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.merged_date}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {logsData.map(({ id, category, name, merged_date }) => (
                  <Tr key={id}>
                    <Td>{id}</Td>
                    <Td>{category}</Td>
                    <Td>
                      <RouterLink to={routes.crm.contact.details}>
                        {name}
                        <Icon
                          as={BiGitMerge}
                          w="6"
                          h="6"
                          ml="2"
                          color="orange.300"
                        />
                      </RouterLink>
                    </Td>

                    <Td>{merged_date}</Td>
                    <Td></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
};

export default Logs;
function setFilter(data: any) {
  throw new Error('Function not implemented.');
}

function setIsLoading(whichButton: string) {
  throw new Error('Function not implemented.');
}

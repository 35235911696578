import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import ServiceResource from 'api/sms_services';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ServiceInfo from 'components/sms_service/ServiceInfo';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ServiceGroupSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const ServiceDetails: React.FC = () => {
  const { id }: any = useParams();
  const serviceApi = new ServiceResource();
  const { search } = useLocation();

  const serviceQuery = useQuery<ServiceGroupSchema>(
    [`service${id}`, id],
    () => serviceApi.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (serviceQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (serviceQuery.isError) {
    history.push(routes.sms.serviceGroups.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.service_management}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.sms.services.list + search}>
              {strings.services}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {serviceQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <ServiceInfo serviceData={serviceQuery?.data} search={search} />
      </Stack>
    </>
  );
};

export default ServiceDetails;

import { Link, ListItem } from '@chakra-ui/react';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { subMenuDataType } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { checkPermissions, checkSomePermissions } from 'utils/listInfo';
import { strings } from '../../config/localization';

const linkStyles = {
  d: 'block',
  rounded: 'md',
  p: '2',
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
  _hover: {
    bg: 'rgba(255,255,255, 0.1)',
  },
};

const activeLinkStyles = {
  ...linkStyles,
  p: '2',
  bg: 'rgba(255,255,255, 0.2)',
  shadow: 'box',
};

function getRouteStyle(path: string) {
  let match = matchPath(location.pathname, {
    path: path,
  });
  return match?.isExact ? activeLinkStyles : linkStyles;
}

type DisplaySubLinksType = {
  subMenuData: subMenuDataType[];
};
const DisplaySubLinks: React.FC<DisplaySubLinksType> = (props) => {
  const { subMenuData } = props;

  return (
    <>
      {subMenuData.map((item) => {
        return (
          item.isPermitted && (
            <ListItem key={item.id}>
              <Link
                sx={getRouteStyle(item.link)}
                onClick={() => {
                  item.checkPermission();
                }}>
                {strings[item.text]}
              </Link>
            </ListItem>
          )
        );
      })}
    </>
  );
};

export const UserSubMenuList: React.FC = () => {
  useLocation();
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const checkUserListPermission = useCheckPermission(
    [PermissionRequest['read:user']],
    routes.users.list
  );
  const checkRolesListPermission = useCheckPermission(
    [PermissionRequest['read:user']],
    routes.users.roles.list
  );
  const checkCompanyListPermission = useCheckPermission(
    [PermissionRequest['manage:external-companies']],
    routes.users.externalCompanies.list
  );
  const checkLoginLogPermission = useCheckPermission(
    [PermissionRequest['read:login-log']],
    routes.users.loginLog.list
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.users.base,
      text: 'user_list',
      checkPermission: checkUserListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:user'],
      ]),
    },
    {
      id: 2,
      link: routes.users.roles.base,
      text: 'user_roles',
      checkPermission: checkRolesListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:user'],
      ]),
    },
    {
      id: 3,
      link: routes.users.externalCompanies.base,
      text: 'external_companies',
      checkPermission: checkCompanyListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['manage:external-companies'],
      ]),
    },
    {
      id: 4,
      link: routes.users.loginLog.base,
      text: 'user_login_log',
      checkPermission: checkLoginLogPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:login-log'],
      ]),
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const ChatSubMenuList: React.FC = () => {
  useLocation();

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const checkChatPermission = useCheckPermission(
    [PermissionRequest['read:chat']],
    routes.chat.default
  );
  const checkClosedChatPermission = useCheckPermission(
    [PermissionRequest['read:chat']],
    routes.chat.closed.list
  );
  const checkChatMetricsPermission = useCheckPermission(
    [PermissionRequest['read:chat-metrics']],
    routes.chat.metrics
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.chat.default,
      text: 'chat_overview',
      checkPermission: checkChatPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:chat'],
      ]),
    },
    {
      id: 2,
      link: routes.chat.closed.list,
      text: 'closed_chat',
      checkPermission: checkClosedChatPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:chat'],
      ]),
    },
    {
      id: 3,
      link: routes.chat.metrics,
      text: 'chat_metrics',
      checkPermission: checkChatMetricsPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:chat-metrics'],
      ]),
    },
  ];

  // Hide chat sidebar option if permission is disabled
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const TicketSubMenuList: React.FC = () => {
  useLocation();

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const checkTicketBoardPermission = useCheckPermission(
    [PermissionRequest['read:ticket']],
    routes.ticket.task.board
  );
  const checkTicketListPermission = useCheckPermission(
    [PermissionRequest['read:ticket']],
    routes.ticket.list.default
  );
  const checkObjectInfoPermission = useCheckPermission(
    [PermissionRequest['read:ticket']],
    routes.ticket.objectsInfo
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.ticket.task.base,
      text: 'ticket_board',
      checkPermission: checkTicketBoardPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:ticket'],
      ]),
    },
    {
      id: 2,
      link: routes.ticket.list.base,
      text: 'ticket_list',
      checkPermission: checkTicketListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:ticket'],
      ]),
    },
    {
      id: 3,
      link: routes.ticket.objectsInfo,
      text: 'objects',
      checkPermission: checkObjectInfoPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:ticket'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const CrmSubMenuList: React.FC = () => {
  useLocation();

  const checkContactListPermission = useCheckPermission(
    [PermissionRequest['read:crm']],
    routes.crm.contact.list
  );
  const checkMergeContactListPermission = useCheckPermission(
    [PermissionRequest['merge:contacts']],
    routes.crm.merge.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.crm.contact.base,
      text: 'contacts',
      checkPermission: checkContactListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:crm'],
      ]),
    },
    {
      id: 2,
      link: routes.crm.merge.base,
      text: 'merge_contact_sidebar',
      checkPermission: checkMergeContactListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['merge:contacts'],
      ]),
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const OmsSubMenuList: React.FC = () => {
  useLocation();
  const checkObjektListPermission = useCheckPermission(
    [PermissionRequest['read:oms'], PermissionRequest['is:owner']],
    routes.oms.objects.list,
    undefined,
    'some'
  );
  const checkSeasonListPermission = useCheckPermission(
    [PermissionRequest['read:oms'], PermissionRequest['is:owner']],
    routes.oms.season.list,
    undefined,
    'some'
  );
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.oms.objects.list,
      text: 'objects',
      checkPermission: checkObjektListPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:oms'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 2,
      link: routes.oms.season.list,
      text: 'season',
      checkPermission: checkSeasonListPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:oms'],
        PermissionRequest['is:owner'],
      ]),
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const SMSSubMenuList: React.FC = () => {
  useLocation();
  const checkSMSServicePermission = useCheckPermission(
    [PermissionRequest['read:sms']],
    routes.sms.services.list
  );
  const checkSMSServiceGroupPermission = useCheckPermission(
    [PermissionRequest['read:sms']],
    routes.sms.serviceGroups.list
  );
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.sms.services.base,
      text: 'services',
      checkPermission: checkSMSServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:sms'],
      ]),
    },
    {
      id: 2,
      link: routes.sms.serviceGroups.base,
      text: 'service_group',
      checkPermission: checkSMSServiceGroupPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:sms'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const BMSSubMenuList: React.FC = () => {
  useLocation();

  const checkBMSBookingPermission = useCheckPermission(
    [PermissionRequest['read:bookings'], PermissionRequest['is:owner']],
    routes.bms.booking.list,
    undefined,
    'some'
  );
  const checkBMSBookingCalendarPermission = useCheckPermission(
    [PermissionRequest['read:bookings'], PermissionRequest['is:owner']],
    routes.bms.bookingCalendar.base,
    undefined,
    'some'
  );
  const checkCheckInCheckOutListPermission = useCheckPermission(
    [PermissionRequest['read:bookings']],
    routes.bms.checkInCheckOut.list
  );

  const checkBMSInvoicePermission = useCheckPermission(
    [PermissionRequest['read:invoice'], PermissionRequest['is:owner']],
    routes.bms.invoices.list,
    undefined,
    'some'
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.bms.booking.base,
      text: 'booking_overview',
      checkPermission: checkBMSBookingPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:bookings'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 2,
      link: routes.bms.bookingCalendar.base,
      text: 'booking_calendar',
      checkPermission: checkBMSBookingCalendarPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:bookings'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 3,
      link: routes.bms.checkInCheckOut.list,
      text: 'check_in_check_out',
      checkPermission: checkCheckInCheckOutListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:bookings'],
      ]),
    },
    {
      id: 4,
      link: routes.bms.invoices.list,
      text: 'invoices',
      checkPermission: checkBMSInvoicePermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:invoice'],
        PermissionRequest['is:owner'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const FAQSubMenuList: React.FC = () => {
  useLocation();
  const checkFAQServicePermission = useCheckPermission(
    [PermissionRequest['read:faq']],
    routes.faq.faqList.list
  );

  const checkInternalFAQServicePermission = useCheckPermission(
    [PermissionRequest['read:internal-faq']],
    routes.faq.internalfaq.list
  );
  const checkFAQGroupServicePermission = useCheckPermission(
    [PermissionRequest['read:faq-group']],
    routes.faq.faqGroup.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.faq.faqList.base,
      text: 'faq',
      checkPermission: checkFAQServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:faq'],
      ]),
    },
    {
      id: 2,
      link: routes.faq.internalfaq.base,
      text: 'internal_faq',
      checkPermission: checkInternalFAQServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:internal-faq'],
      ]),
    },
    {
      id: 3,
      link: routes.faq.faqGroup.base,
      text: 'group',
      checkPermission: checkFAQGroupServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:faq-group'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const DMSSubMenuList: React.FC = () => {
  useLocation();

  const checkDMSServicePermission = useCheckPermission(
    [PermissionRequest['read:dms'], PermissionRequest['is:owner']],
    routes.dms.generalList.list,
    undefined,
    'some'
  );

  const checkCustomerDMSServicePermission = useCheckPermission(
    [PermissionRequest['read:customer-dms']],
    routes.dms.customerDocuments.list
  );
  const checkOwnerDMSServicePermission = useCheckPermission(
    [PermissionRequest['read:owner-dms'], PermissionRequest['is:owner']],
    routes.dms.ownerDocuments.list,
    undefined,
    'some'
  );
  const checkObjectDMSServicePermission = useCheckPermission(
    [PermissionRequest['read:object-dms'], PermissionRequest['is:owner']],
    routes.dms.objectDocuments.list,
    undefined,
    'some'
  );
  const checkUserDMSServicePermission = useCheckPermission(
    [PermissionRequest['read:user-dms']],
    routes.dms.userDocuments.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.dms.generalList.list,
      text: 'general_documents',
      checkPermission: checkDMSServicePermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:dms'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 2,
      link: routes.dms.objectDocuments.list,
      text: 'document_objects',
      checkPermission: checkObjectDMSServicePermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:object-dms'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 3,
      link: routes.dms.customerDocuments.list,
      text: 'document_customers',
      checkPermission: checkCustomerDMSServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:customer-dms'],
      ]),
    },
    {
      id: 4,
      link: routes.dms.ownerDocuments.list,
      text: 'owner_documents',
      checkPermission: checkOwnerDMSServicePermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:owner-dms'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 5,
      link: routes.dms.userDocuments.list,
      text: 'users_document',
      checkPermission: checkUserDMSServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:user-dms'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const PMSSubMenuList: React.FC = () => {
  useLocation();

  const checkPMSCreditCardPermission = useCheckPermission(
    [PermissionRequest['read:pms']],
    routes.pms.creditCard.list
  );

  const checkPMSPayPalPermission = useCheckPermission(
    [PermissionRequest['read:pms']],
    routes.pms.paypal.list
  );

  const checkPaymentLinksPermission = useCheckPermission(
    [PermissionRequest['read:pms']],
    routes.pms.paymentLinks.list
  );

  const checkPMSBankTransferPermission = useCheckPermission(
    [PermissionRequest['read:pms']],
    routes.pms.bankTransfer.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.pms.creditCard.list,
      text: 'credit_card',
      checkPermission: checkPMSCreditCardPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:pms'],
      ]),
    },
    {
      id: 2,
      link: routes.pms.paypal.list,
      text: 'paypal',
      checkPermission: checkPMSPayPalPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:pms'],
      ]),
    },
    {
      id: 3,
      link: routes.pms.bankTransfer.list,
      text: 'bank_transfer',
      checkPermission: checkPMSBankTransferPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:pms'],
      ]),
    },
    {
      id: 4,
      link: routes.pms.paymentLinks.list,
      text: 'payment_links',
      checkPermission: checkPaymentLinksPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:pms'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const PNSSubMenuList: React.FC = () => {
  const location = useLocation();

  const checkPNSNotificationPermission = useCheckPermission(
    [PermissionRequest['read:pns']],
    routes.pns.notification.list
  );

  return (
    <>
      <ListItem>
        <Link
          sx={
            location.pathname === routes.pns.notification.list
              ? activeLinkStyles
              : linkStyles
          }
          onClick={() => {
            checkPNSNotificationPermission();
          }}>
          {strings.notifications}
        </Link>
      </ListItem>
    </>
  );
};

export const ContactCenterSubMenuList: React.FC = () => {
  const checkContactCenterUserPermission = useCheckPermission(
    [PermissionRequest['read:contact-center']],
    routes.contactCenter.members.list
  );
  const checkContactCenterTotalCallsPermission = useCheckPermission(
    [PermissionRequest['read:contact-center']],
    routes.contactCenter.totalCalls.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.contactCenter.members.list,
      text: 'user',
      checkPermission: checkContactCenterUserPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:contact-center'],
      ]),
    },
    {
      id: 2,
      link: routes.contactCenter.totalCalls.list,
      text: 'total_calls',
      checkPermission: checkContactCenterTotalCallsPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:contact-center'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const FeedbackSubMenuList: React.FC = () => {
  useLocation();

  const checkFeedbackPermission = useCheckPermission(
    [PermissionRequest['read:feedback']],
    routes.feedback.feedbacks.list
  );
  const checkFeedbackDashboardPermission = useCheckPermission(
    [PermissionRequest['read:feedback']],
    routes.feedback.dashboard
  );
  const checkFeedbackAnalysisPermission = useCheckPermission(
    [PermissionRequest['read:feedback']],
    routes.feedback.analysis
  );
  const checkMailsPermission = useCheckPermission(
    [PermissionRequest['read:feedback']],
    routes.feedback.mails.list
  );
  const checkQuestionsPermission = useCheckPermission(
    [PermissionRequest['manage:feedback']],
    routes.feedback.questions.list
  );
  const checkSettingPermission = useCheckPermission(
    [PermissionRequest['manage:feedback']],
    routes.feedback.setting.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.feedback.feedbacks.list,
      text: 'feedback',
      checkPermission: checkFeedbackPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:feedback'],
      ]),
    },
    {
      id: 2,
      link: routes.feedback.dashboard,
      text: 'dashboard',
      checkPermission: checkFeedbackDashboardPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:feedback'],
      ]),
    },
    {
      id: 3,
      link: routes.feedback.analysis,
      text: 'analysis',
      checkPermission: checkFeedbackAnalysisPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:feedback'],
      ]),
    },
    {
      id: 4,
      link: routes.feedback.mails.list,
      text: 'mails',
      checkPermission: checkMailsPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:feedback'],
      ]),
    },
    {
      id: 5,
      link: routes.feedback.questions.list,
      text: 'questions',
      checkPermission: checkQuestionsPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['manage:feedback'],
      ]),
    },
    {
      id: 6,
      link: routes.feedback.setting.list,
      text: 'setting',
      checkPermission: checkSettingPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['manage:feedback'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const NewsletterSubMenuList: React.FC = () => {
  useLocation();

  const checkNewsletterPermission = useCheckPermission(
    [PermissionRequest['read:newsletter']],
    routes.newsletter.newsletters.list
  );
  const checkNewsletterDraftPermission = useCheckPermission(
    [PermissionRequest['read:newsletter']],
    routes.newsletter.draft.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.newsletter.newsletters.list,
      text: 'newsletter_overview',
      checkPermission: checkNewsletterPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:newsletter'],
      ]),
    },
    {
      id: 2,
      link: routes.newsletter.draft.list,
      text: 'drafts',
      checkPermission: checkNewsletterDraftPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:newsletter'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const KeySubMenuList: React.FC = () => {
  useLocation();

  const checkKeyLogsPermission = useCheckPermission(
    [PermissionRequest['read:keylog']],
    routes.key.KeyLogs.list
  );
  const checkKeyListPermission = useCheckPermission(
    [PermissionRequest['read:keylist']],
    routes.key.keyList.list
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.key.KeyLogs.list,
      text: 'key_logs',
      checkPermission: checkKeyLogsPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:keylog'],
      ]),
    },
    {
      id: 2,
      link: routes.key.keyList.list,
      text: 'key_list',
      checkPermission: checkKeyListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:keylist'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

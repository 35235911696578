import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { BookingSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  appartmentDetails: any;
  appartmentBookingPrice: number;
}

const AppartmentInformation: React.FC<Props> = (props: Props) => {
  const { appartmentDetails, appartmentBookingPrice } = props;

  const { getValues } = useFormContext<BookingSchema>();

  const formValues = getValues();
  const distribution_channel: 'call' | 'email' =
    formValues.distribution_channel === 'phone'
      ? 'call'
      : formValues.distribution_channel;

  return (
    <Box>
      <Heading mb="2" size="md">
        {strings.appartment_information}
      </Heading>
      <Grid
        gap="6"
        templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        flex="1">
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.apartment_name}
          </Text>
          <Text>{appartmentDetails?.name}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.from}
          </Text>
          <Text>{DateFormat(formValues.from_date)}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.until}
          </Text>
          <Text>{DateFormat(formValues.to_date)}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.adult}
          </Text>
          <Text>{formValues.number_of_adults}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.children}
          </Text>
          <Text>{formValues.number_of_children}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.baby}
          </Text>
          <Text>{formValues.number_of_babies}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.dog}
          </Text>
          <Text>{formValues.number_of_dogs}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.distribution_channel}
          </Text>
          <Text>{strings[distribution_channel]}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium" color="heading">
            {strings.status}
          </Text>
          <Text>{strings[formValues.status] || formValues.status}</Text>
        </GridItem>
        <GridItem>
          <Text as="b" color="heading">
            {strings.price} (CHF)
          </Text>
          <br />
          <Text as="b" color="heading">
            {formatCurrency(Number(appartmentBookingPrice))}
          </Text>
        </GridItem>
        <GridItem gridColumnStart={['span 2', 'span 3']}>
          <Text fontWeight="medium" color="heading">
            {strings.notes_notizen}
          </Text>
          <Text>{formValues.remarks || '-'}</Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default AppartmentInformation;

import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ServiceGroupForm from 'components/service_group/ServiceGroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ServiceGroupSchema } from 'constants/schema';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const EditServiceGroup: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { search } = useLocation();

  const methods = useForm<ServiceGroupSchema>();

  const serviceGroupAPI = new ServiceGroupResource();

  const serviceGroupQuery = useQuery(
    [`service-group${id}-edit`, id],
    () => serviceGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateServiceGroup = useMutation((data: any) =>
    serviceGroupAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('service_group_id', '1');
    formData.append('is_default', data.is_default);
    formData.append('show_on_page', data.show_on_page);
    formData.append('description', data.description);
    formData.append('booking_fee', data.booking_fee);
    formData.append('booking_fee_type', data.booking_fee_type);
    formData.append('color', data?.color);
    if (data?.icon) formData.append('icon', data?.icon);
    if (data?.cover) formData.append('cover', data?.cover);
    formData.append('_method', 'PUT');

    updateServiceGroup.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.service_group_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('serviceGroupList');
        history.push(
          routes.sms.serviceGroups.details.replace(':id', id.toString()) +
            search
        );
      },
    });
  };

  if (serviceGroupQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (serviceGroupQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.service_group_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.service_management_system}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.sms.serviceGroups.list + search}>
            {strings.service_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {serviceGroupQuery?.data?.data?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.edit_service_group}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateServiceGroup.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.service_group_udpate_failed}
                </Alert>
              )}
              <ServiceGroupForm
                serviceGroupData={serviceGroupQuery.data.data}
              />
              <ButtonGroup justifyContent="flex-end">
                <Button
                  size="lg"
                  colorScheme="primary"
                  variant="outline"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateServiceGroup.isLoading}>
                  {strings.update}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditServiceGroup;

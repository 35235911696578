import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PMSBankTransferResource from 'api/pms_bank_transfer';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PMSBankTransferListItem from 'components/pms/PMSBankTransferListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
  received_date: string;
  status: string;
  booking_number: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const BankTransferList: React.FC = () => {
  const pmsBankTransferAPI = new PMSBankTransferResource();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    received_date: searchValues.received_date ?? '',
    status: searchValues.status ?? '',
    booking_number: searchValues.booking_number ?? '',
  });

  const [documentCreatedAt, setDocumentCreatedAt] = useState<any>();

  const pmsBankTransferList = useQuery(
    [
      'pmsBankTransferList',
      {
        page: filterParams.currentPage,
        per_page: filterParams.pageSize,
        keyword: filterParams.keyword,
        received_date: filterParams.received_date,
        status: filterParams.status,
        booking_number: filterParams.booking_number,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        per_page: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.received_date)
        queryParams.received_date = filterParams.received_date;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.booking_number)
        queryParams.booking_number = filterParams.booking_number;
      return { data: [] };
      const response = await pmsBankTransferAPI.list(queryParams);
      return response?.data;
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleDocumentCreatedAtDateChange = (date: Date) => {
    setDocumentCreatedAt(date);
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      received_date: DateFormatYMD(date),
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setDocumentCreatedAt('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      received_date: '',
      status: '',
      booking_number: '',
    }));
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.pms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pms.bankTransfer.list}>
              {strings.bank_transfer}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.bank_transfer}
          </Heading>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          boxShadow="box"
          defaultIndex={[defaultIndex]}>
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack
                    direction={['column', 'column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          name="keyword"
                          value={filterParams.keyword}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.booking_number}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          name="booking_number"
                          value={filterParams.booking_number}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.date}</FormLabel>
                      <InputGroup>
                        <InputRightElement
                          children={<BiCalendar />}
                          color="gray.400"
                        />
                        <ReactDatePickerRoot
                          dateFormat="dd.MM.yyyy"
                          placeholderText={strings.select_date}
                          name="received_date"
                          value={DateFormat(filterParams.received_date)}
                          id="received_date"
                          selected={documentCreatedAt}
                          customInput={
                            <CreatedAtInput value={documentCreatedAt} />
                          }
                          onChange={(date: Date) =>
                            handleDocumentCreatedAtDateChange(date)
                          }
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.status}</FormLabel>
                      <Select
                        name="status"
                        value={filterParams.status}
                        placeholder={strings.status}
                        rounded="sm"
                        onChange={handleInputChange}>
                        <option value="confirmed">{strings.confirmed}</option>
                        <option value="cancelled">{strings.cancelled}</option>
                      </Select>
                    </FormControl>

                    <Stack direction="row" spacing="4">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.customer_name}</Th>
                  <Th>{strings.booking_number}</Th>
                  <Th isNumeric>{strings.date}</Th>
                  <Th>{strings.status}</Th>
                  <Th isNumeric>{strings.amount} (CHF)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!pmsBankTransferList.isLoading &&
                  pmsBankTransferList?.data?.data?.map(
                    (pmsBankTransferData: any, index: number) => (
                      <PMSBankTransferListItem
                        key={pmsBankTransferData.id}
                        index={startingSN + index}
                        pmsBankTransferData={pmsBankTransferData}
                      />
                    )
                  )}

                {pmsBankTransferList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={pmsBankTransferList}
        />
      </Stack>
    </>
  );
};

export default BankTransferList;

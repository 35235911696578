import { useToast } from '@chakra-ui/react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import io, { Socket } from 'socket.io-client';

type SOCK_EVENTS =
  | 'INITIALIZE'
  | 'SET_SESSION_ID'
  | 'CONNECT'
  | 'SEND_MESSAGE'
  | 'MESSAGE_RECEIVED'
  | 'RESET_SOCKET'
  | 'SET_CONNECTION'
  | 'SET_DISCONNECTION'
  | 'SET_PAIR_STATUS';

export type SOCK_CONN_TYPE = 'Customer' | 'Employee';

export interface ISockMessage {
  date: string;
  message: any;
}

type SOCK_STATE = {
  sessionId?: string;
  deviceId?: string;
  deviceName?: string;
  socket?: Socket;
  connected: boolean;
  disconnected: boolean;
  pairStatus: boolean;
  type: SOCK_CONN_TYPE;
  message?: ISockMessage; //  change this based on the desired message format
};

type SocketProviderProps = {
  children: React.ReactNode;
  type: SOCK_CONN_TYPE;
  sessionId?: string;
  deviceName?: string;
  deviceId?: string;
  isPairInfoDeviceIdLoading?: boolean;
};

type ActionType = {
  type: SOCK_EVENTS;
  payload?: any;
};

type SocketContextProps = {
  state: SOCK_STATE;
  dispatch: React.Dispatch<ActionType>;
};

const initialObjects: SOCK_STATE = {
  sessionId: undefined,
  deviceId: undefined,
  deviceName: undefined,
  connected: false,
  disconnected: false,
  pairStatus: false,
  type: 'Customer',
};

const CONNECTION_PORT: any =
  process.env.REACT_APP_SECOND_SCREEN_SOCKET_CONNECTION;

const socketReducer = (prevState: SOCK_STATE, action: ActionType) => {
  switch (action.type) {
    case 'INITIALIZE': {
      let newState = { ...prevState };

      const socket = io(CONNECTION_PORT, {
        path: '/terminal/socket.io',
        autoConnect: false,
        transports: ['websocket'],
        upgrade: false,
      });

      if (
        action.payload?.type &&
        action.payload?.deviceName &&
        action.payload?.deviceId
      ) {
        socket.auth = {
          sessionId: action.payload?.sessionId,
          sessionType: action.payload?.type,
          deviceId: action.payload.deviceId,
          deviceName: action.payload.deviceName,
        };
        socket.connect();

        newState = {
          ...newState,
          type: action.payload.type,
          deviceName: action.payload.deviceName,
          deviceId: action.payload.deviceId,
        };
      }
      return {
        ...newState,
        socket,
        sessionId: action.payload?.sessionId,
      };
    }

    case 'SET_SESSION_ID': {
      let newState = { ...prevState };
      if (action.payload?.sessionId) {
        newState = { ...prevState, sessionId: action.payload.sessionId };
      }
      return newState;
    }

    case 'SET_CONNECTION': {
      let newState = { ...prevState };
      if (action.payload?.connected) {
        newState = { ...prevState, connected: action.payload.connected };
      }
      return newState;
    }

    case 'SET_DISCONNECTION': {
      let newState = { ...prevState };
      if (action.payload?.disconnected) {
        newState = { ...prevState, disconnected: action.payload.disconnected };
      }
      return newState;
    }

    case 'SET_PAIR_STATUS': {
      let newState = { ...prevState };
      if (action.payload?.pairStatus) {
        newState = { ...prevState, pairStatus: action.payload.pairStatus };
      }
      return newState;
    }

    case 'SEND_MESSAGE': {
      if (prevState.socket && prevState.connected && action.payload) {
        prevState.socket.emit('ec_message', action.payload);
      }
      return { ...prevState };
    }

    case 'MESSAGE_RECEIVED': {
      let newState = { ...prevState };
      if (action?.payload?.message) {
        const msg: ISockMessage = {
          date: new Date().toISOString(),
          message: action?.payload?.message,
        };
        newState = { ...prevState, message: msg };
      }
      return newState;
    }
    case 'RESET_SOCKET': {
      return { ...initialObjects };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const SocketContext = createContext<SocketContextProps | null>(null);
SocketContext.displayName = 'SOCK_CONN';

const SocketProvider: React.FC<SocketProviderProps> = (props) => {
  const [state, dispatch] = useReducer(socketReducer, initialObjects);
  const toast = useToast();

  const pairStatusMemo = useMemo(() => {
    return state.pairStatus;
  }, [state.pairStatus]);

  useEffect(() => {
    if (!props.isPairInfoDeviceIdLoading) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          type: props.type,
          sessionId: props.sessionId,
          deviceName: props.deviceName,
          deviceId: props.deviceId,
        },
      });
    }
  }, [props.isPairInfoDeviceIdLoading]);

  // This is being done because the employee screen will not know who to send the data in the beginning
  // So we establish another socket connection session where both the parties are known through pair status
  useEffect(() => {
    if (pairStatusMemo) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          type: props.type,
          sessionId: props.sessionId,
          deviceName: props.deviceName,
          deviceId: props.deviceId,
        },
      });
    }
  }, [pairStatusMemo]);

  useEffect(() => {
    if (state.socket) {
      state.socket.on('connect', () => {
        dispatch({
          type: 'SET_CONNECTION',
          payload: {
            connected: true,
          },
        });
      });

      state.socket.on('session', (payload) => {
        dispatch({
          type: 'SET_SESSION_ID',
          payload: {
            sessionId: payload.sessionId,
          },
        });
      });

      state.socket.on('ec_message', (payload) => {
        dispatch({
          type: 'MESSAGE_RECEIVED',
          payload: {
            message: payload,
          },
        });
      });

      state.socket.on('pair_status', (payload) => {
        dispatch({
          type: 'SET_PAIR_STATUS',
          payload: {
            pairStatus: payload.active,
          },
        });
      });

      state.socket.on('disconnect', () => {
        state.socket?.disconnect();
        dispatch({
          type: 'SET_DISCONNECTION',
          payload: {
            disconnected: true,
          },
        });

        localStorage.removeItem('CUST:sessionId');
        localStorage.removeItem('EMP:sessionId');
        localStorage.removeItem('CUST:custdname');
        localStorage.removeItem('CUST:empdname');
      });

      state.socket.on('connect_error', (err) => {
        console.error('socket:Err', err);
      });

      return () => {
        if (state.socket) {
          state.socket.off('connect');
          state.socket.off('disconnect');
          state.socket.off('connect_error');
        }
      };
    }
  }, [state.socket, toast]);

  return (
    <SocketContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SocketContext.Provider>
  );
};

const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error(`socket Context must be used within socket Provider`);
  }
  return context;
};

const sendMessage = (dispatch: React.Dispatch<ActionType>, payload?: any) => {
  dispatch({
    type: 'SEND_MESSAGE',
    payload,
  });
};

const resetSocket = (dispatch: React.Dispatch<ActionType>) => {
  dispatch({
    type: 'RESET_SOCKET',
  });
};

export default SocketProvider;

export { sendMessage, resetSocket, SocketProvider, useSocketContext };

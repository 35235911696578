import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { useEmployeeDeviceIdPairContext } from 'context/EmployeeDeviceIdPairContext';
import { sendMessage, useSocketContext } from 'context/SocketContext';
import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';
import CheckIn from './CheckIn';
import CheckOut from './CheckOut';

const stepStyled = {
  bg: 'white',
  my: '2',
  p: '2',
  pr: '10',
  fontSize: 'lg',
  rounded: 'sm',
  borderColor: 'gray',
  border: '0.5px solid gray',
  width: '8em',
  _selected: {
    color: 'black',
    bg: '#D9D9D9',
    border: 'none',
    fontWeight: 'bold',
  },
};
const CheckInCheckOutList: React.FC = () => {
  const { isPairInfoDeviceIdLoading } = useEmployeeDeviceIdPairContext();
  const { state, dispatch } = useSocketContext();

  const toast = useToast();

  const clearSecondScreen = () => {
    sendMessage(dispatch, {});
  };

  useEffect(() => {
    if (state.disconnected) {
      toast({
        title: strings.disconnected,
        description: strings.disconnected_from_second_screen,
        status: 'error',
      });
    }
  }, [state.disconnected]);

  if (isPairInfoDeviceIdLoading) {
    return <CenterSpinner />;
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.checkInCheckOut.list}>
            {strings.booking_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.bms.checkInCheckOut.list}>
            {strings.check_in_check_out}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between" align="end">
        <Heading size="lg" textTransform="capitalize">
          {strings.check_in_check_out}
        </Heading>
      </Flex>

      <Tabs variant="unstyled" isLazy>
        <Flex justifyContent="space-between">
          <TabList>
            <Tab sx={stepStyled}>{strings.check_in}</Tab>
            <Tab sx={stepStyled}>{strings.check_out}</Tab>
          </TabList>
          <Button
            variant="outline"
            colorScheme="primary"
            my="2"
            onClick={clearSecondScreen}>
            {strings.clear_second_screen}
          </Button>
        </Flex>
        <TabPanels>
          <TabPanel>
            <CheckIn />
          </TabPanel>
          <TabPanel>
            <CheckOut />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default CheckInCheckOutList;

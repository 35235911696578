import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import userDocumentResource from 'api/user_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import UserDocumentForm from 'components/user_document/UserDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { GeneralDocumentSchema } from 'constants/schema';
import { default as React } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditUsersDocument: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<GeneralDocumentSchema>();

  const userDocumentAPI = new userDocumentResource();

  const userDocumentQuery = useQuery(
    [`user-document${id}-edit`, id],
    () => userDocumentAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateUserDocument = useMutation((data: any) =>
    userDocumentAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    data._method = 'PUT';
    data.user_id = data.user_id?.value;
    updateUserDocument.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.general_document_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userDocumentList');
        history.goBack();
      },
    });
  };

  if (userDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userDocumentQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.general_document_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.userDocuments.list}>
              {strings.document_users}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              {userDocumentQuery?.data?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg">{strings.edit_user_document}</Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {updateUserDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.general_document_udpate_failed}
                  </Alert>
                )}
                <UserDocumentForm
                  userDocumentData={userDocumentQuery.data.data}
                />
                <ButtonGroup alignSelf="flex-end">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={updateUserDocument.isLoading}>
                    {strings.save_now}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EditUsersDocument;

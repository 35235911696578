import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FeedbackQuestionResource from 'api/feedback_question';
import { wrapperStyles } from 'assets/css/commonStyles';
import FeedbackQuestionForm from 'components/feedback/FeedbackQuestionForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FeedbackQuestionSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const AddQuestion: React.FC = () => {
  const { id: feedbackQuestionId }: any = useParams();

  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<FeedbackQuestionSchema>();
  const toast = useToast();
  const feedbackQuestionAPI = new FeedbackQuestionResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createFeedbackQuestion = useMutation((data: any) =>
    feedbackQuestionAPI.store(data)
  );

  const { data: feedbackQuestion } = useQuery(
    ['feedbackQuestionAdd', feedbackQuestionId],
    () => {
      if (!feedbackQuestionId) return null;
      return feedbackQuestionAPI
        .get(feedbackQuestionId)
        .then((res) => res.data.data)
        .catch();
    }
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    createFeedbackQuestion.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.feedback_question} ${
            feedbackQuestionId
              ? strings.has_been_updated
              : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.feedback.questions.list);
      },
      onError: () => {
        setErrMsg(
          `${strings.feedback_question} ${
            feedbackQuestionId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
              {strings.feedback}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.feedback.questions.list}>
              {strings.questions}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {feedbackQuestionId
                ? strings.edit_question
                : strings.add_new_question}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {feedbackQuestionId
              ? strings.edit_question
              : strings.add_new_question}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <FeedbackQuestionForm data={feedbackQuestion} />
              <ButtonGroup pt={['4', '8']} justifyContent="flex-end" w="100%">
                <RouterLink to={routes.feedback.questions.list}>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    size="lg"
                    colorScheme="primary">
                    {strings.cancel}
                  </Button>
                </RouterLink>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {!feedbackQuestionId ? strings.save_now : strings.update}
                </Button>
              </ButtonGroup>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddQuestion;

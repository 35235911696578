import {
  Avatar,
  Flex,
  Heading,
  Icon,
  Stack,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { setCurrentChatUser } from 'actions/data/chat';
import { getChatUserDetail } from 'api/chat';
import { strings } from 'config/localization';
import React, { useEffect } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { BiPhoneCall } from 'react-icons/bi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { truncateString } from 'utils';

interface Props {
  name: string;
  roomName: string;
  date: string;
  message: string;
  unreadChatCount?: number;
  avatar: string;
  chatId: String;
  phoneRingingRoom: string;
  callInProgressRoom: string;
  lastChatMessage?: string;
}

const ChatListItem: React.FC<Props> = (props) => {
  const {
    name,
    roomName,
    date,
    message,
    avatar,
    chatId,
    phoneRingingRoom,
    callInProgressRoom,
    unreadChatCount = 0,
    lastChatMessage,
  } = props;
  const dispatch = useDispatch();

  const { currentChatUserDetails } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (unreadChatCount > 0) {
    }
  }, [unreadChatCount]);

  const handleCurrentChatUser = async () => {
    const response = await getChatUserDetail(chatId);
    dispatch(setCurrentChatUser(response.data.data));
  };

  // Indicate that a given room is making a call or is in an active call
  const isRoomInCall =
    phoneRingingRoom === roomName || callInProgressRoom === roomName;

  return (
    <Stack
      _hover={{ cursor: 'pointer' }}
      position="relative"
      rounded="sm"
      p="1"
      onClick={() => handleCurrentChatUser()}>
      <Stack
        direction={['column', 'column']}
        spacing="0"
        p={['1', '2', '4', '4']}
        shadow="box"
        bg="#F9F9F9"
        sx={
          currentChatUserDetails._id === chatId
            ? { boxShadow: '0px 3px 0px 0px #6C90F7' }
            : { boxShadow: 'none' }
        }>
        <Stack
          direction={['column', 'column', 'row', 'row']}
          spacing="2"
          width="100%">
          <Stack width={['25%', '15%']}>
            <Avatar
              shadow="md"
              size={'md'}
              name={name}
              bg="gray.400"
              color="white"
            />
          </Stack>
          <Stack direction="column" width={['75%', '85%']} pl={['2', '1']}>
            <Flex
              justify="space-between"
              direction={['column', 'column', 'row', 'row']}>
              <Heading
                width="75%"
                size="sm"
                color="chat.400"
                fontWeight="medium"
                wordBreak="break-all">
                {name}
              </Heading>
              <Text
                width="25%"
                fontSize="xs"
                fontWeight="normal"
                color="gray.400"
                wordBreak="normal"
                textAlign="right">
                {date}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text
                wordBreak="break-all"
                fontSize="sm"
                fontWeight={unreadChatCount > 0 ? 'semibold' : 'normal'}>
                {truncateString(lastChatMessage, 24)}
              </Text>
              {unreadChatCount > 0 ? (
                <Tag
                  bg="chat.400"
                  h="18px"
                  w="18px"
                  rounded="full"
                  alignSelf="center"
                  color="white"
                  fontSize="10px">
                  {unreadChatCount}
                </Tag>
              ) : (
                <Icon as={AiOutlineCheck} w="5" h="5" color="chat.400" />
              )}
            </Flex>
          </Stack>
        </Stack>
        <Stack>
          <Flex justify="space-between">
            <Text fontSize="xs" color="gray.400">
              {message}
            </Text>
            {isRoomInCall && (
              <Tooltip
                hasArrow
                label={strings.call_in_progress}
                textAlign="right">
                <span>
                  <Icon as={BiPhoneCall} w="4" h="4" color="green.400" />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChatListItem;

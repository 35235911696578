import { Td, Text, Tr } from '@chakra-ui/react';
import { selectColorScheme, tableRowStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { BMSBooking } from 'constants/schemas/bookings';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  data: BMSBooking;
  search: string;
}

const BookingListItem: React.FC<Props> = (props) => {
  const { data: booking, search } = props;

  const { role } = useSelector((state: any) => ({
    role: state?.data?.auth?.user.role,
  }));

  const checkManageBookingPermission = useCheckPermission(
    [PermissionRequest['read:bookings']],
    routes.bms.booking.details.replace(':id', booking.id.toString()) + search,
    undefined,
    'some'
  );

  return (
    <>
      <Tr
        key={booking.id}
        sx={tableRowStyles}
        onClick={checkManageBookingPermission}>
        <Td>{booking.apartment?.name}</Td>
        <Td>{DateFormat(booking.from_date)}</Td>
        <Td>{DateFormat(booking.to_date)}</Td>
        <Td>{booking.customer.name}</Td>
        <Td>
          <Text color={selectColorScheme(booking.status)}>
            {strings[booking.status] || booking.status}
          </Text>
        </Td>
        <Td>{booking.booking_number}</Td>
        {role !== 'owner' && (
          <>
            <Td>{strings[booking.payment_status] || booking.payment_status}</Td>
            <Td>
              {(booking.booking_info?.distribution_channel &&
                strings[booking.booking_info.distribution_channel]) ||
                '-'}
            </Td>
            <Td>{formatCurrency(Number(booking.total_amount))}</Td>
            <Td>{formatCurrency(Number(booking.open_amount))}</Td>
          </>
        )}
      </Tr>
    </>
  );
};

export default BookingListItem;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import BMSInvoicesResource from 'api/bms-invoices';
import { tableRowStyles, wrapperStyles } from 'assets/css/commonStyles';
import InvoicesSearch from 'components/bms_booking/bms_invoices/InvoicesSearch';
import InvoicePreview from 'components/bms_invoice/InvoicePreview';
import OwnerInvoicePreview from 'components/bms_invoice/OwnerInvoicePreview';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { InvoiceType, MasterInvoice } from 'constants/schemas/invoice';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiFilter, BiShow } from 'react-icons/bi';
import { FiDownload } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { changeURL, formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  invoice_number: string;
  name: string;
  created_at_from: string;
  created_at_to: string;
  apartment_id: string;
  apartment: string;
  invoice_type: string | '';
}

const InvoicesList: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const printRef = useRef<any>();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const { role } = useSelector((state: any) => ({
    role: state?.data?.auth?.user.role,
  }));

  const [hasInvoiceLoaded, setHasInvoiceLoaded] = useState<boolean>(false);
  const [isDownloadClicked, setIsDownloadClicked] = useState<boolean>(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<any>(null);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState('');
  const [selectedInvoiceType, setSelectedInvoiceType] = useState<any>(null);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    invoice_number: searchValues.invoice_number ?? '',
    name: searchValues.name ?? '',
    created_at_from: searchValues.created_at_from ?? '',
    created_at_to: searchValues.created_at_to ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    apartment: searchValues.apartment,
    invoice_type: searchValues.invoice_type ?? '',
  });

  const bmsInvoiceAPI = new BMSInvoicesResource();

  const invoiceListData: any = useQuery(
    [
      'invoice-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        invoice_number: filterParams.invoice_number,
        name: filterParams.name,
        created_at_from: filterParams.created_at_from ?? '',
        created_at_to: filterParams.created_at_to ?? '',
        apartment_id: filterParams.apartment_id,
        apartment: filterParams.apartment,
        invoice_type: filterParams.invoice_type,
      },
    ],
    () => {
      let {
        invoice_number,
        name,
        created_at_from,
        created_at_to,
        apartment_id,
        invoice_type,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (invoice_number) queryParams.invoice_number = invoice_number;
      if (name) queryParams.customer = name;
      if (created_at_from) queryParams.created_at_from = created_at_from;
      if (created_at_to) queryParams.created_at_to = created_at_to;
      if (apartment_id) queryParams.apartment_id = apartment_id;
      if (invoice_type) queryParams.type = invoice_type;

      return bmsInvoiceAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  const handleAdvancedSearch = useCallback((data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      invoice_number: data.invoice_number,
      name: data.name,
      created_at_from: data.created_at_from,
      created_at_to: data.created_at_to,
      apartment_id: data.apartment?.id,
      apartment: data.apartment?.name,
      invoice_type: data.invoice_type,
    }));
  }, []);

  useEffect(() => {
    const data: any = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
    window.scrollTo({
      top: 0,
    });
  }, [history, filterParams]);

  const setInvoiceIdAndType = (invoice: any) => {
    setSelectedInvoiceId(invoice?.id || null);
    setSelectedInvoiceType(invoice?.type || null);
  };

  const onDownload = (invoice: any) => {
    setInvoiceIdAndType(invoice);
    setSelectedInvoiceNumber(invoice?.invoice_number);
    setIsDownloadClicked(true);
  };

  const onViewPress = (invoice: any) => {
    setInvoiceIdAndType(invoice);
    onOpen();
  };
  const onViewClose = () => {
    setInvoiceIdAndType(null);
    onClose();
  };

  const getInvoiceType = (type: InvoiceType) => {
    const value = type === 'owner' ? 'owner_invoice' : 'customer_invoice';
    return value;
  };

  const handleInvoicePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { padding-left:2px,padding-right:2px }',
    documentTitle: selectedInvoiceNumber,
  });

  useEffect(() => {
    if (hasInvoiceLoaded) handleInvoicePrint();
    setHasInvoiceLoaded(false);
    setIsDownloadClicked(false);
    // eslint-disable-next-line
  }, [hasInvoiceLoaded]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.booking_management}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.bms.invoices.list}>
              {strings.invoice}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.invoice}
          </Heading>
        </Flex>
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <InvoicesSearch
                filterParams={filterParams}
                handleAdvancedSearch={handleAdvancedSearch}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.invoice_number}</Th>
                  {role !== 'owner' && (
                    <>
                      <Th>{strings.name}</Th>
                      <Th>{strings.invoice_type}</Th>
                    </>
                  )}
                  <Th>{strings.date_of_invoice}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.total_amount}</Th>
                  <Th>{strings.amount_open}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoiceListData?.data?.data?.map((invoice: MasterInvoice) => {
                  return (
                    <Tr key={invoice.id} sx={tableRowStyles}>
                      <Td>{invoice?.invoice_number}</Td>
                      {role !== 'owner' && (
                        <>
                          <Td>
                            {invoice?.type === 'owner'
                              ? invoice?.owner_rent?.owner?.name
                              : invoice?.booking?.customer?.name}
                          </Td>
                          <Td>{`${strings[getInvoiceType(invoice.type)]}`}</Td>
                        </>
                      )}
                      <Td>{DateFormat(invoice?.created_at)}</Td>
                      <Td>
                        {invoice?.type === 'owner'
                          ? invoice?.owner_rent?.booking?.apartment?.name
                          : invoice?.booking?.apartment?.name}
                      </Td>
                      <Td>
                        {invoice?.type === 'owner'
                          ? formatCurrency(
                              Number(
                                invoice?.owner_rent
                                  ?.rent_commission_owner_amount
                              )
                            )
                          : formatCurrency(
                              Number(invoice?.booking?.total_amount)
                            )}
                      </Td>
                      <Td>
                        {invoice?.type === 'owner'
                          ? '-'
                          : formatCurrency(
                              Number(invoice?.booking?.open_amount)
                            )}
                      </Td>
                      <Td>
                        <Tooltip hasArrow label={strings.download}>
                          <IconButton
                            icon={<FiDownload size="18" />}
                            variant="link"
                            aria-label={strings.download}
                            color="primary.300"
                            minW="8"
                            onClick={() => onDownload(invoice)}
                          />
                        </Tooltip>

                        <Tooltip hasArrow label={strings.view}>
                          <IconButton
                            icon={<BiShow size="18" />}
                            variant="link"
                            aria-label={strings.view}
                            color="primary.300"
                            minW="8"
                            onClick={() => onViewPress(invoice)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              {invoiceListData.isLoading && (
                <TableSkeletonLoader rows={7} cols={role !== 'owner' ? 8 : 6} />
              )}
            </Table>
          </TableContainer>
        </Stack>

        <Pagination
          dataList={invoiceListData}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>

      <div style={{ display: 'none' }}>
        {selectedInvoiceType === 'customer' ? (
          <InvoicePreview
            masterInvoiceId={selectedInvoiceId}
            ref={printRef}
            setHasInvoiceLoaded={setHasInvoiceLoaded}
            isDownloadClicked={isDownloadClicked}
          />
        ) : (
          <OwnerInvoicePreview
            masterInvoiceId={selectedInvoiceId}
            ref={printRef}
            setHasInvoiceLoaded={setHasInvoiceLoaded}
            isDownloadClicked={isDownloadClicked}
          />
        )}
      </div>

      <Modal isOpen={isOpen} onClose={onViewClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.invoice}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" alignSelf="center">
            {selectedInvoiceType === 'customer' ? (
              <InvoicePreview
                masterInvoiceId={selectedInvoiceId}
                setHasInvoiceLoaded={setHasInvoiceLoaded}
                isDownloadClicked={isDownloadClicked}
              />
            ) : (
              <OwnerInvoicePreview
                masterInvoiceId={selectedInvoiceId}
                setHasInvoiceLoaded={setHasInvoiceLoaded}
                isDownloadClicked={isDownloadClicked}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={onViewClose}>
                {strings.back}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InvoicesList;

import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ServiceResource from 'api/sms_services';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ServiceForm from 'components/sms_service/ServiceForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ServiceSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { buildFormData } from 'utils';

const EditService: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { search } = useLocation();

  const methods = useForm<ServiceSchema>();

  const serviceAPI = new ServiceResource();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const serviceQuery = useQuery(
    [`service${id}-edit`, id],
    () => serviceAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateGroup = useMutation((data: any) =>
    serviceAPI.updateService(id, data)
  );

  const onSubmit = (data: any) => {
    let formdata: any = { ...data };
    if (formdata.service_group_id)
      formdata.service_group_id = data.service_group_id.value;
    if (formdata.v_service_id) formdata.v_service_id = data.v_service_id.value;
    formdata._method = 'PUT';
    setIsLoading(true);
    let form = buildFormData(formdata);
    updateGroup.mutate(form, {
      onSuccess: (res: any) => {
        toast({
          title: strings.service_group_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('serviceGroupList');
        history.push(routes.sms.services.details.replace(':id', id) + search);
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.errors['scheme_data.0.name'] &&
          error?.response?.data?.errors['scheme_data.0.name'][0] ===
            'Duplicate Price Scheme'
        ) {
          setErrorMessage(strings.duplicate_price_scheme);
        } else {
          setErrorMessage(strings.service_creation_failed);
        }
        setIsLoading(false);
      },
    });
  };

  if (serviceQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (serviceQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.service_group_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.service_management}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.sms.services.list + search}>
            {strings.services}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {serviceQuery?.data?.data?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.edit_service}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {errorMessage && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <ServiceForm serviceData={serviceQuery.data.data} />
              <ButtonGroup justifyContent="flex-end">
                <Button
                  colorScheme="primary"
                  size="lg"
                  variant="outline"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {strings.update}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditService;

import { CenterSpinner } from 'components/common/CenterSpinner';
import ScreenSelector from 'components/customer-screen/ScreenSelector';
import SocketProvider from 'context/SocketContext';
import useCustomerScreenDeviceId from 'hooks/useCustomerScreenDeviceId';
import React from 'react';

const SecondScreen: React.FC = () => {
  const { isPairing, appState } = useCustomerScreenDeviceId();

  if (isPairing) {
    return <CenterSpinner />;
  }

  return (
    <SocketProvider
      type={'Customer'}
      deviceName={appState.customerDeviceName}
      deviceId={appState.customerDeviceId}
      sessionId={appState.sessionId}>
      <ScreenSelector />
    </SocketProvider>
  );
};

export default SecondScreen;

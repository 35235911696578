import {
  Flex,
  Heading,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { LogoPNG } from 'assets/images';
import { strings } from 'config/localization';
import React, { forwardRef, useEffect, useState } from 'react';
import { DateFormat } from 'utils/DateFormat';

const infoStyles = {
  color: 'gray.900',
  fontWeight: 'normal',
};

interface Props {
  apartmentScoreData: any;
  ref: any;
  filterParams: any;
}

const FeedbackResponseDetailsPDF = forwardRef((props: Props, ref: any) => {
  const { apartmentScoreData, filterParams } = props;
  const [showFilterParams, setShowFilterParams] = useState<boolean>(false);

  useEffect(() => {
    const filterParamsFlag =
      filterParams?.keyword ||
      filterParams?.feedback_at_from ||
      filterParams?.feedback_at_to;
    setShowFilterParams(!!filterParamsFlag);
  }, [
    filterParams?.feedback_at_from,
    filterParams?.feedback_at_to,
    filterParams?.keyword,
  ]);

  const selectColorSchemeId = (value: number) => {
    if (value < 5) {
      return 'tag-background-color-red';
    } else if (value >= 5 && value < 8) {
      return 'tag-background-color-orange';
    } else {
      return 'tag-background-color-green';
    }
  };

  return (
    <Stack direction="column" id="feedback-print" ref={ref}>
      <Flex justify="space-between">
        <Stack pl={2} direction="row" alignItems="center">
          <Image src={LogoPNG} width="80px" alt="MyVal Logo" />
          <Stack ml="10px !important">
            <Heading size="lg" textTransform="capitalize">
              MyVal Service Center
            </Heading>
            <Heading size="sm">Appartementvermittlung Familie Clausen</Heading>
          </Stack>
        </Stack>
        <Heading
          size="md"
          textTransform="capitalize"
          marginTop="10px"
          marginRight="10px">
          {strings.feedback_detail}
        </Heading>
      </Flex>
      <Stack
        direction="column"
        bg="white"
        p={['3', '6']}
        margin="0 !important"
        sx={infoStyles}>
        <div id="feedback-filter-block">
          <span id="filter-header-text">{strings.filter}</span>
          <span>
            <span className="filter-keyword-text">{strings.text_search}:</span>
            &ensp;{filterParams?.keyword ? filterParams?.keyword : '-'}
          </span>
          <span>
            <span className="filter-keyword-text">{strings.date}:</span>
            &ensp;
            {filterParams?.feedback_at_from
              ? DateFormat(filterParams?.feedback_at_from)
              : '-'}
            &nbsp;/&nbsp;
            {filterParams?.feedback_at_to
              ? DateFormat(filterParams?.feedback_at_to)
              : '-'}
          </span>
        </div>
        <TableContainer overflowY="auto">
          <Table
            size="sm"
            style={{
              whiteSpace: 'normal',
            }}>
            <Thead
              style={{
                borderBottom: '1px solid #000',
              }}>
              <Tr>
                <Th color="blackAlpha.900">{strings.sn}</Th>
                <Th color="blackAlpha.900">{strings.objects}</Th>
                <Th color="blackAlpha.900">{strings.score_total}</Th>
                <Th color="blackAlpha.900">{strings.reviews_general}</Th>
                <Th color="blackAlpha.900">{strings.reviews_object_page}</Th>
                <Th color="blackAlpha.900">
                  {strings.public_score_calculation}
                </Th>
                <Th color="blackAlpha.900">{strings.real_score}</Th>
                <Th color="blackAlpha.900">{strings.public_score}</Th>
              </Tr>
            </Thead>
            <Tbody id="feedback-pdf-table-body">
              {apartmentScoreData?.data?.map((item: any, index: number) => (
                <>
                  <div className="page-break" />
                  <Tr key={index}>
                    <Td>{index + 1}</Td>

                    <Td>{item.apartment}</Td>
                    <Td>{item.total_feedback_received}</Td>
                    <Td>{item.total_review}</Td>
                    <Td>{item.total_apartment_review}</Td>
                    <Td>{item.total_feedback_received_rating}</Td>
                    <Td>
                      <span
                        className="rating-tag"
                        id={selectColorSchemeId(Number(item.total_score))}>
                        {Number(item.total_score).toFixed(1)}
                      </span>
                    </Td>
                    <Td>
                      <span
                        className="rating-tag"
                        id={selectColorSchemeId(Number(item.published_score))}>
                        {Number(item.published_score).toFixed(1)}
                      </span>
                    </Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
});

FeedbackResponseDetailsPDF.displayName = 'FeedbackDetailsPDF';

export default FeedbackResponseDetailsPDF;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import OwnerGroupDocumentListItem from 'components/owner_document/OwnerGroupDocumentListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCloudUpload, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
}

const OwnerDocumentList: React.FC = () => {
  let ownerDocumentAPI = new OwnerDocumentResource();
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword');

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: url_keyword ?? '',
  });

  const ownerDocumentList = useQuery(
    [
      'ownerDocumentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await ownerDocumentAPI.getByOwnerDocument(queryParams);
      response && setIsFilterLoading(false);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    const data: any = {
      ...filterParams,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.dms.ownerDocuments.list);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.dms.ownerDocuments.list}>
              {strings.all_owners}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.owner_documents}
          </Heading>
          <AccessControl
            allowedPermissions={[PermissionRequest['manage:owner-dms']]}>
            <RouterLink to={routes.dms.ownerDocuments.upload}>
              <Button
                colorScheme="primary"
                leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
                size="lg">
                {strings.upload_document}
              </Button>
            </RouterLink>
          </AccessControl>
        </Flex>
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="20px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack
                    direction={['column', 'row']}
                    spacing="4"
                    align={['start', 'end']}>
                    <FormControl>
                      <FormLabel>{strings.owner_name}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          type="text"
                          name="keyword"
                          size="lg"
                          value={filterParams.keyword}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>

                    <Stack direction="row" spacing="4">
                      <ButtonGroup>
                        <Button
                          colorScheme="primary"
                          variant="outline"
                          size="lg"
                          onClick={handleReset}>
                          {strings.reset_filter}
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.owner_name}</Th>
                  <Th isNumeric>{strings.no_of_documents}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!ownerDocumentList.isLoading &&
                  ownerDocumentList?.data?.data?.map(
                    (ownerDocumentData: any, index: number) => (
                      <OwnerGroupDocumentListItem
                        key={ownerDocumentData?.apartment_id}
                        index={startingSN + index}
                        ownerGroupDocumentData={ownerDocumentData}
                        search={search}
                      />
                    )
                  )}

                {ownerDocumentList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={3} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={ownerDocumentList}
        />
      </Stack>
    </>
  );
};

export default OwnerDocumentList;

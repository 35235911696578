import { ButtonProps } from '@chakra-ui/react';
import { MergeSmall } from 'assets/icons';
import theme from 'config/theme';

// Styles for paginated buttons ------------------------------ //

export const baseStyles: ButtonProps = {
  w: 8,
  h: 8,
  fontSize: 'sm',
  _disabled: {
    bg: 'white',
    pointerEvents: 'none',
  },
};

export const normalStyles: ButtonProps = {
  ...baseStyles,
  _hover: {
    bg: 'primary.400',
    color: 'white',
  },
  bg: 'white',
  color: 'gray.900',
};

export const activeStyles: ButtonProps = {
  ...baseStyles,
  bg: 'primary.400',
  color: 'white',
  _hover: {
    bg: 'primary.400',
    color: 'white',
  },
};

export const separatorStyles: ButtonProps = {
  w: 7,
  pointerEvents: 'none',
};

// ---------------------------------------------------------- //

// Styles for react-select ------------------------------ //

export const reactSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,

    minHeight: '42px',
    boxShadow: theme.shadows.input,
    borderRadius: theme.radii.sm,
    borderColor: state.isDisabled
      ? theme.colors.gray[200]
      : state.isFocused
      ? theme.colors.blue
      : 'inherit',
    transition: 'all 0.35s ease-in-out',
    backgroundColor: theme.colors.white,
    opacity: state.isDisabled ? '0.4' : '1',
    cursor: state.isDisabled ? 'not-allowed' : 'inherit',

    '&:hover': {
      borderColor: theme.colors.gray[300],
    },
  }),

  placeholder: (provided: any) => ({
    ...provided,
    color: theme.colors.gray[400],
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: theme.colors.gray[300],
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: theme.colors.gray[500],
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? theme.colors.primary[300]
      : state.isFocused
      ? theme.colors.primary[100]
      : 'transparent',
  }),

  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: theme.colors.blue[50],
    p: 0,
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 10px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: theme.radii.sm,
    zIndex: '3',
    fontSize: theme.fontSizes.sm,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};
export const reactSelectTicketContactStyles = {
  control: (provided: any, state: any) => ({
    ...provided,

    minHeight: '34px',
    boxShadow: theme.shadows.input,
    borderRadius: theme.radii.sm,
    borderColor: state.isDisabled
      ? theme.colors.gray[200]
      : state.isFocused
      ? theme.colors.blue
      : 'inherit',
    transition: 'all 0.35s ease-in-out',
    backgroundColor: theme.colors.white,
    opacity: state.isDisabled ? '0.4' : '1',
    cursor: state.isDisabled ? 'not-allowed' : 'inherit',

    '&:hover': {
      borderColor: theme.colors.gray[300],
    },
  }),

  placeholder: (provided: any) => ({
    ...provided,
    color: theme.colors.black,
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: theme.colors.gray[100],
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: theme.colors.black,
  }),

  option: (provided: any, state: any) => {
    if (state.data.is_intermixed) {
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? theme.colors.primary[300]
          : state.isFocused
          ? theme.colors.primary[100]
          : 'transparent',
        color: '#000',
        opacity: 0.5,
        ':before': {
          content: `url(${MergeSmall})`,
          marginRight: '5px',
        },
      };
    } else {
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? theme.colors.primary[300]
          : state.isFocused
          ? theme.colors.primary[100]
          : 'transparent',
      };
    }
  },

  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: theme.colors.blue[50],
    p: 0,
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 10px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: theme.radii.sm,
    zIndex: '3',
    fontSize: theme.fontSizes.sm,
  }),
};
// ---------------------------------------------------------- //
// Payment Status  && Booking Status
// ---------------------------------------------------------- //

export const selectColorScheme = (status: any) => {
  const statusName = status.toLowerCase();
  const statusColorScheme: { [key: string]: string } = {
    pending: 'orange.400',
    confirmed: 'primary.500',
    cancelled: 'red.400',
    reserved: '#767575',
  };
  return statusColorScheme[statusName] ?? 'blackAlpha.700';
};
// ---------------------------------------------------------- //
// Recepient
// ---------------------------------------------------------- //
export const selectBGScheme = (group: any) => {
  const groupName = group.toLowerCase();
  const groupBGScheme: { [key: string]: string } = {
    owner: 'orange.50',
    guest: 'blue.50',
    lead: 'pink.50',
    supplier: 'green.50',
    app: 'purple.50',
    test: 'gray.50',
  };
  return groupBGScheme[groupName];
};

export const selecLabelColorScheme = (group: any) => {
  const groupName = group.toLowerCase();
  const groupColorScheme: { [key: string]: string } = {
    owner: 'orange.400',
    guest: 'blue.400',
    lead: 'pink.400',
    supplier: 'green.400',
    app: 'purple.400',
    test: 'gray.400',
  };
  return groupColorScheme[groupName];
};

export const labelStyles = {
  color: 'gray.900',
  fontweight: 'bold',
  mb: 1,
};
export const infoStyles = {
  color: 'gray.600',
  fontWeight: 'normal',
};
export const wrapperStyles = {
  bg: 'white',
  p: [3, 6],
  shadow: 'box',
  w: 'full',
  direction: 'column',
  spacing: '4',
  rounded: 'sm',
};
export const tableRowStyles = {
  cursor: 'pointer',
  _hover: {
    bg: 'blackAlpha.50',
  },
};

export const feedbackScoreStyle = {
  borderRadius: 'full',
  color: 'black',
  w: '4',
  h: '4',
  ml: '2',
  textAlign: 'center',
  fontSize: '12px',
  size: 'md',
  justifyContent: 'center',
};

export const activeFeedbackScoreStyle = {
  bg: 'blackgreen.400',
  color: 'white',
  borderColor: 'blackgreen.400',
  boxShadow: 'none',
};

export const customerScreenHeadingStyle = {
  fontFamily: 'overpass',
  color: 'white',
};

export const tableRowSelectStyles = {
  bg: 'blackAlpha.50',
  cursor: 'pointer',
};

export const inputTypeStyle = {
  _readOnly: {
    bg: 'gray.200',
    cursor: 'not-allowed',
  },
};

/* eslint-disable import/no-anonymous-default-export */
import { ChatAction, ChatActionTypes } from 'actions/data/chat';

const INITIAL_STATE = {
  currentChatUser: {},
  employees: [],
  chatItemList: [],
  unreadCount: 0,
  employeeSearchLoading: false,
  chatPageVisible: false,
};

export default function (chatState = INITIAL_STATE, action: ChatAction) {
  switch (action.type) {
    case ChatActionTypes.SET_CURRENT_CHAT_USER:
      return {
        ...chatState,
        currentChatUser: action.data,
      };

    case ChatActionTypes.SET_EMPLOYEE_LIST:
      return {
        ...chatState,
        employees: action.data,
      };

    case ChatActionTypes.SET_CHAT_ITEM_LIST:
      return {
        ...chatState,
        chatItemList: action.data,
      };
    case ChatActionTypes.SET_CHAT_UNREAD_COUNT:
      return {
        ...chatState,
        unreadCount: action.data,
      };
    case ChatActionTypes.SET_EMPLOYEE_SEARCH_LOADING:
      return {
        ...chatState,
        employeeSearchLoading: action.data,
      };
    case ChatActionTypes.SET_CHAT_PAGE_VISIBLE:
      return {
        ...chatState,
        chatPageVisible: action.data,
      };
    default:
      return chatState;
  }
}

import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  data: any;
  editing: boolean;
}

const DescriptionForm: React.FC<Props> = (props) => {
  const { editing, data } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const methods = useForm<any>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  let lang = strings.getLanguage();
  const [locale, setLocale] = useState<any>(lang);
  const objectAPI = new OMSObjectsResource();
  const updateArticle = useMutation((formData: any) =>
    objectAPI.update(data.id, formData)
  );
  useEffect(() => {
    if (!data) return;
    let { translations } = data;
    let currentLocale = translations.find(
      (item: any) => item.locale === locale
    );

    if (currentLocale) {
      let { headline, directions, full_description, short_description } =
        currentLocale;
      setValue('headline', headline);
      setValue('directions', directions);
      setValue('full_description', full_description);
      setValue('short_description', short_description);
    } else {
      setValue('headline', null);
      setValue('directions', null);
      setValue('full_description', null);
      setValue('short_description', null);
    }
  }, [data, locale, setValue]);

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    let formData = {
      descriptions: {
        [locale]: {
          ...data,
        },
      },
    };
    updateArticle.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(`apartmentDetails`);
        toast({
          title: `${strings.apartment} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(`${strings.apartment} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="column" spacing="8">
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <Stack direction="row" spacing="8">
            <Grid gap="4" w="100%">
              <GridItem>
                <Heading color="green.600" size="md" textTransform="capitalize">
                  {strings.text}
                </Heading>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>{strings.language}</FormLabel>
                  <Select
                    isDisabled={!editing}
                    value={locale}
                    onChange={(e: any) => setLocale(e.target.value)}
                    placeholder={strings.select}>
                    <option value="en">{strings.english}</option>
                    <option value="de">{strings.german}</option>
                    <option value="fr">{strings.french}</option>
                    <option value="it">{strings.italian}</option>
                    {/* <option value="pl">{strings.polish}</option> */}
                  </Select>
                  <FormErrorMessage>
                    <>{errors?.language && errors?.language?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>{strings.headline}</FormLabel>
                  <Input
                    isDisabled={!editing}
                    {...register('headline', {
                      required: strings.appartment_is_required,
                    })}
                    type="text"
                    placeholder={strings.headline}
                  />
                  <FormErrorMessage>
                    <>{errors?.headline && errors?.headline?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>{strings.full_description}</FormLabel>
                  <Textarea
                    {...register('full_description')}
                    isDisabled={!editing}
                    size="lg"
                    placeholder={strings.full_description}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.short_description}</FormLabel>
                  <Textarea
                    {...register('short_description')}
                    isDisabled={!editing}
                    size="sm"
                    placeholder={strings.short_description}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.directions}</FormLabel>
                  <Textarea
                    {...register('directions')}
                    isDisabled={!editing}
                    size="sm"
                    placeholder={strings.directions}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          {editing && (
            <ButtonGroup alignSelf="end">
              <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                {strings.save}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default DescriptionForm;

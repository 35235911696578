import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RolesResource from 'api/roles';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { RoleSchema } from 'constants/schema';
import React, { useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const UserRoles: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const rolesApi = new RolesResource();
  const history = useHistory();

  const {
    data: rolesData,
    isLoading,
    isError,
  } = useQuery('userRoles', () => rolesApi.list().then((res) => res.data.data));

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.roles_not_found}
        </Alert>
      </Box>
    );
  }
  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.user_roles}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading as="h1" size="lg">
            {strings.user_roles}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.role}</Th>
                  <Th>{strings.description}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {rolesData?.map((role: RoleSchema, index: number) => (
                  <Tr key={role.id}>
                    <Td>{index + 1}</Td>
                    <Td
                      fontWeight="medium"
                      color="gray.500"
                      _hover={{ color: 'gray.600' }}>
                      <RouterLink
                        to={routes.users.roles.view.replace(
                          ':id',
                          role.id.toString()
                        )}>
                        {strings.getString(role.name.split(' ').join('_'))}
                      </RouterLink>
                    </Td>
                    <Td>{role.description}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_user}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red">{strings.delete}</Button>
              <Button variant="outline">{strings.cancel}</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserRoles;

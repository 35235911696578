import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  RadioGroup,
  Stack,
  Textarea,
  useRadioGroup,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import ImageUploader from 'components/common/ImageUploader';
import RoundCustomRadio from 'components/common/RoundCustomRadio';
import { strings } from 'config/localization';
import { SERVICE_GROUP_SEARCH_API } from 'constants/common';
import { ServiceSchema, ServiceTypeOptions } from 'constants/schema';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import ServiceSchemeForm from './ServiceSchemeForm';

interface Props {
  serviceData?: ServiceSchema;
}

const ServiceForm: React.FC<Props> = (props) => {
  const { serviceData } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext();

  const [groupInputKey, setGroupInputKey] = useState<string>(
    serviceData?.service_group?.name ?? ''
  );
  const [schemePage, setSchemePage] = useState(
    serviceData?.price_scheme_type || 'PriceSchemeByQuantity'
  );

  useEffect(() => {
    setValue(
      'price_scheme_type',
      (serviceData && serviceData?.price_scheme_type) || 'PriceSchemeByQuantity'
    );
  }, [serviceData, setValue]);

  /**
   * Fetch service groups
   */
  const {
    loading: serviceGroupLoading,
    result: groupQueryResult,
    setQuery: setServiceGroupQuery,
  } = useWordSearch(
    `${SERVICE_GROUP_SEARCH_API}/?page=1&limit=50&include_dog_service=1`
  );

  const groupList = groupQueryResult?.data;

  const groupOptions = groupList?.map((group: any) => ({
    label: group.name,
    value: group.id,
  }));

  //set v-office dropdown
  useEffect(() => {
    if (!serviceData?.v_service?.id) return;
    let vService = {
      value: serviceData?.v_service?.id,
      label: serviceData?.v_service?.name,
    };
  }, [serviceData, setValue]);
  //set service group dropdown
  useEffect(() => {
    if (!serviceData?.service_group?.id) return;
    let serviceGroup = {
      value: serviceData?.service_group?.id,
      label: serviceData?.service_group?.name,
    };
    setServiceGroupQuery({ keyword: serviceGroup.label });
    setValue('service_group_id', serviceGroup);
  }, [serviceData, setServiceGroupQuery, setValue]);

  const handleSchemeType = (schemeName: any) => {
    setValue('price_scheme_type', schemeName);
    setSchemePage(schemeName);
  };

  const handleGroupInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setGroupInputKey(value);
      setServiceGroupQuery({ keyword: value });
    }
  };
  const options: ServiceTypeOptions = [
    { label: 'by_quantity', value: 'PriceSchemeByQuantity' },
    { label: 'one_time_price', value: 'PriceSchemeOneTime' },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'SchemeTypeRadio',
    defaultValue: serviceData?.price_scheme_type ?? 'PriceSchemeByQuantity',
    onChange: handleSchemeType,
  });

  const group = getRootProps();

  return (
    <form>
      <Stack direction="column" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.service_name}</FormLabel>
              <Input
                type="text"
                size="lg"
                defaultValue={serviceData?.name}
                placeholder={strings.service_name}
                {...register('name', {
                  required: strings.required_service_name,
                })}
              />
              <FormErrorMessage>
                <>{errors?.name && errors?.name?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.service_group_id}>
              <FormLabel>{strings.service_group}</FormLabel>
              <Controller
                control={control}
                name="service_group_id"
                rules={{ required: strings.required_service_group }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="service_group_id"
                    placeholder={strings.select}
                    options={groupOptions}
                    isLoading={serviceGroupLoading}
                    styles={reactSelectStyles}
                    onInputChange={handleGroupInputChange}
                    inputValue={groupInputKey}
                  />
                )}
              />
              <FormErrorMessage>
                <>
                  {errors?.service_group_id &&
                    errors?.service_group_id?.message}
                </>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.description} isRequired>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                defaultValue={serviceData?.description}
                placeholder={strings.description}
                rows={7}
                {...register('description', {
                  required: strings.required_descriptions,
                })}
              />
              <FormErrorMessage>
                <>{errors?.description && errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.cover} isRequired={true}>
              <FormLabel>{strings.cover_image}</FormLabel>
              <ImageUploader
                fileKey="cover"
                required={true}
                uploadedFiles={serviceData?.cover}
                title={strings.drag_icon_or_click_to_select_icon}
              />
              <FormErrorMessage>
                <>{errors?.cover && errors?.cover?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <Stack spacing="4">
              <Grid
                gap="4"
                templateColumns="repeat(1, 1fr)"
                flex="1"
                w="100%"
                overflow="auto">
                <GridItem>
                  <FormControl
                    isInvalid={!!errors?.price_scheme_type}
                    isRequired>
                    <FormLabel>{strings.price_scheme_type}</FormLabel>
                    <Controller
                      name="price_scheme_type"
                      rules={{
                        required: strings.required,
                      }}
                      defaultValue={serviceData?.price_scheme_type}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          id="price_scheme_type"
                          onChange={handleSchemeType}>
                          <Flex {...group} my="2">
                            {options.map(({ value, label }) => {
                              const radio = getRadioProps({ value });
                              return (
                                <RoundCustomRadio
                                  type={'radioNav'}
                                  key={value}
                                  {...radio}>
                                  {strings[label]}
                                </RoundCustomRadio>
                              );
                            })}
                          </Flex>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      <>
                        {errors?.price_scheme_type &&
                          errors?.price_scheme_type?.message}
                      </>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>
            <Stack spacing="4" mt="4">
              {schemePage !== 'PriceSchemeByNone' && (
                <ServiceSchemeForm
                  schemeType={getValues('price_scheme_type')}
                  schemeData={serviceData}
                />
              )}
            </Stack>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default ServiceForm;

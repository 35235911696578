import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { defaultCreatePaymentLinkMessage } from 'constants/common';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CreateCustomerDetailPaymentLinkForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  return (
    <Grid
      gap="4"
      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
      w="100%">
      <GridItem>
        <FormControl isInvalid={!!errors?.customer_name} isRequired>
          <FormLabel>{strings.customer_name}</FormLabel>
          <Input
            type="text"
            placeholder={strings.customer_name}
            {...register('customer_name', {
              required: strings.required_customer_name,
            })}
          />
          <FormErrorMessage>
            <>{errors?.customer_name && errors?.customer_name?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={!!errors?.email} isRequired>
          <FormLabel>{strings.email_address}</FormLabel>
          <Input
            type="email"
            placeholder={strings.email_address}
            {...register('email', {
              required: strings.required_email,
            })}
          />
          <FormErrorMessage>
            <>{errors?.email && errors?.email?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem>
        <FormControl isInvalid={!!errors?.amount} isRequired>
          <FormLabel>{strings.amount} (CHF)</FormLabel>
          <Input
            type="number"
            placeholder={strings.amount}
            {...register('amount', {
              required: strings.amount_is_required,
              min: {
                value: 0,
                message: strings.less_than_zero,
              },
            })}
          />
          <FormErrorMessage>
            <>{errors?.amount && errors?.amount?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={[1, 2]}>
        <FormControl isInvalid={!!errors?.message} isRequired>
          <FormLabel>{strings.message}</FormLabel>
          <Textarea
            placeholder={strings.message}
            defaultValue={defaultCreatePaymentLinkMessage}
            {...register('message', {
              required: strings.required_message,
              validate: (value) => {
                if (value?.trim() === '') return strings.required_message;
              },
            })}
          />
          <FormErrorMessage>
            <>{errors?.message && errors?.message?.message}</>
          </FormErrorMessage>
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default CreateCustomerDetailPaymentLinkForm;

import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import BMSBookingResource from 'api/bms-bookings';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomSelect from 'components/common/CustomSelect';
import Pagination from 'components/common/Pagination';
import { strings } from 'config/localization';
import {
  BMS_APARTMENT_SEARCH_API,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { BookingStatus } from 'constants/schema';
import {
  ApartmentWithBookings,
  CalendarBookings,
  PartialBookings,
} from 'constants/schemas/bookings';
import moment from 'moment';
import { Component } from 'react';
import Scheduler, {
  CellUnits,
  SchedulerData,
  ViewTypes,
} from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { checkPermissions } from 'utils/listInfo';

type ComponentProps = RouteComponentProps<any, any, unknown> & {
  userPermissions: any;
};

interface ComponentState {
  viewModel: any;
  errorMessage: string;
  apartments: ApartmentWithBookings[];
  bookings: CalendarBookings[];
  isLoading: boolean;
  apartmentListRes: any;
  // filter state
  booking_from: string;
  booking_to: string;
  currentPage: number;
  pageSize: number;
  apartmentId: string;
  apartmentName: string;
}

class BookingCalendar extends Component<ComponentProps, ComponentState> {
  constructor(props: any) {
    super(props);

    this.fetchApartment = this.fetchApartment.bind(this);
    this.checkSeasonsDate = this.checkSeasonsDate.bind(this);
    this.checkBookingCreatePermission =
      this.checkBookingCreatePermission.bind(this);
    this.handleApartmentFilterChange =
      this.handleApartmentFilterChange.bind(this);

    moment.locale(strings.lang);
    let schedulerData = new SchedulerData(
      moment().format('YYYY-MM-DD'), // date
      ViewTypes.Custom, // view types -> week, month, etc
      false, // agendaView
      false, // eventPerspective
      // configs
      {
        resourceName: strings.appartment_overview,
        eventItemHeight: 68,
        eventItemLineHeight: 72,
        checkConflict: true,
        schedulerWidth: '90%',
        schedulerMaxHeight: 500,
        // monthCellWidth: 32,
        customCellWidth: 48,
        calendarPopoverEnabled: false,
        nonWorkingTimeHeadColor: '#424360',
        nonWorkingTimeHeadBgColor: 'rgba(223, 223, 218, 0.59)',
        nonWorkingTimeBodyBgColor: 'rgba(223, 223, 218, 0.59)',
        views: [],
        startResizable: false,
        endResizable: false,
        eventItemPopoverEnabled: false,
        movable: false,
        scrollToSpecialMomentEnabled: true,
      },
      // behaviors
      {
        getScrollSpecialMomentFunc: this.getScrollSpecialMoment,
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime,
      },
      //locale
      moment
    );

    this.state = {
      viewModel: schedulerData,
      errorMessage: '',
      apartments: [],
      bookings: [],
      isLoading: false,
      apartmentListRes: [],
      // filter state
      booking_from: moment().startOf('month').format('YYYY-MM-DD'),
      booking_to: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'),
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      apartmentName: '',
      apartmentId: '',
    };

    schedulerData.setResources(this.state.apartments);
    schedulerData.setEvents(this.state.bookings);
  }

  bmsBookingAPI = new BMSBookingResource();

  checkBookingCreatePermission() {
    const { userPermissions } = this.props;

    let isPermitted = checkPermissions(userPermissions, [
      PermissionRequest['manage:bookings'],
    ]);

    return isPermitted;
  }

  checkSeasonsDate(startDate: string, endDate: string, apartmentId: number) {
    this.bmsBookingAPI
      .appartmentBookingPrice({
        from_date: startDate,
        to_date: endDate,
        apartment_id: apartmentId,
      })
      .then(() => {
        this.props.history.push(
          routes.bms.booking.add +
            `?start=${startDate}&end=${endDate}&obj=${apartmentId}`
        );
      })
      .catch((err) =>
        this.setState({ errorMessage: strings.selected_dates_not_available })
      );
  }

  fetchApartment() {
    let schedulerData = this.state.viewModel;
    schedulerData.setResources([]);
    this.setState({
      isLoading: true,
    });
    this.bmsBookingAPI
      .listApartmentWithBookings({
        limit: this.state.pageSize,
        page: this.state.currentPage,
        booking_from: this.state.booking_from,
        booking_to: this.state.booking_to,
        apartment_id: this.state.apartmentId,
      })
      .then((data: any) => {
        const apartments: ApartmentWithBookings[] = data?.data.data || [];
        this.setState({
          apartmentListRes: data,
          apartments,
        });

        let apartmentBookings: PartialBookings[] = [];
        apartments.forEach((item) => {
          apartmentBookings = [...apartmentBookings, ...item?.bookings];
        });

        // booking list need to be sorted according to the start date for the library
        let bookingsListSortByStartDate = apartmentBookings?.sort((a, b) => {
          let dateA = new Date(a.from_date);
          let dateB = new Date(b.from_date);

          return dateA.getTime() - dateB.getTime();
        });

        let calendarBookingsList: CalendarBookings[] =
          bookingsListSortByStartDate?.map((booking) => {
            return {
              ...booking,
              resourceId: booking?.apartment_id,
              start: booking?.from_date,
              end: `${booking?.to_date} 23:59:59`,
              title: booking?.booking_number,
            };
          });

        this.setState({
          bookings: calendarBookingsList,
        });

        schedulerData.setResources(apartments);
        schedulerData.setEvents(calendarBookingsList);

        this.setState({ viewModel: schedulerData });
      })
      .catch((err) => {
        this.setState({ errorMessage: strings.error });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleApartmentFilterChange = (e: any) => {
    const { id, name } = e;
    this.setState({
      currentPage: INITIAL_CURRENT_PAGE,
      apartmentId: id,
      apartmentName: name,
    });
  };

  componentDidMount(): void {
    this.fetchApartment();
  }

  componentDidUpdate(_: ComponentProps, prevState: ComponentState) {
    if (
      this.state.currentPage !== prevState.currentPage ||
      this.state.pageSize !== prevState.pageSize ||
      this.state.booking_from !== prevState.booking_from ||
      this.state.booking_to !== prevState.booking_to ||
      this.state.apartmentId !== prevState.apartmentId
    ) {
      this.fetchApartment();
    }
  }

  render() {
    const { viewModel, errorMessage, isLoading } = this.state;
    const setState = this.setState.bind(this);

    const leftCustomHeader = (
      <FormControl w="fit-content">
        <FormLabel>{strings.apartment}</FormLabel>
        <Flex>
          <CustomSelect
            id="apartment_id"
            placeholder={strings.select}
            value={{ name: this.state.apartmentName }}
            onChange={this.handleApartmentFilterChange}
            SEARCH_API={BMS_APARTMENT_SEARCH_API}
            limit={30}
          />
          {this.state.apartmentId && this.state.apartmentName && (
            <IconButton
              icon={<BiX size="18" />}
              variant="link"
              aria-label={strings.clear}
              color="red.500"
              minW="8"
              onClick={() => {
                this.setState({ apartmentId: '', apartmentName: '' });
              }}
            />
          )}
        </Flex>
      </FormControl>
    );

    return (
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.booking_management}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bms.bookingCalendar.base}>
              {strings.booking_calendar}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.booking_calendar}
          </Heading>

          {this.checkBookingCreatePermission() && (
            <Button
              colorScheme="primary"
              size="lg"
              onClick={() => {
                this.props.history.push(routes.bms.booking.add);
              }}>
              {strings.create_new_booking}
            </Button>
          )}
        </Flex>

        {!!errorMessage && (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        )}

        <Box bg="white" shadow="box" p="2">
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            newEvent={this.newEvent}
            conflictOccurred={this.conflictOccurred}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            nonAgendaCellHeaderTemplateResolver={
              this.nonAgendaCellHeaderTemplateResolver
            }
            leftCustomHeader={leftCustomHeader}
          />
          {isLoading && <CenterSpinner />}
          <Pagination
            filterParams={{
              pageSize: this.state.pageSize,
              currentPage: this.state.currentPage,
            }}
            dataList={this.state.apartmentListRes}
            setFilterParams={setState}
          />
        </Box>
      </Stack>
    );
  }

  prevClick = (schedulerData: any) => {
    schedulerData.prev();
    this.setState({
      currentPage: 1,
      booking_from: schedulerData.startDate,
      booking_to: schedulerData.endDate,
    });
  };

  nextClick = (schedulerData: any) => {
    schedulerData.next();
    this.setState({
      currentPage: 1,
      booking_from: schedulerData.startDate,
      booking_to: schedulerData.endDate,
    });
  };

  onViewChange = (schedulerData: any, view: any) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.state.bookings);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData: any, date: any) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.bookings);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData: any, event: any) => {
    this.props.history.push(
      routes.bms.booking.details.replace(':id', event.id.toString())
    );
  };

  newEvent = (
    schedulerData: any,
    slotId: string,
    slotName: string,
    start: string,
    end: string,
    type: any,
    item: any
  ) => {
    let isPermitted = this.checkBookingCreatePermission();

    if (!isPermitted) {
      this.setState({
        errorMessage: strings.no_permission_msg,
      });
      return;
    }

    const { localeMoment } = schedulerData;

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const startDateTime = localeMoment(start);
    const endDateTime = localeMoment(end);
    let isCurrentDate = false;

    const startDate = localeMoment(start).format('YYYY-MM-DD');
    const endDate = localeMoment(end).format('YYYY-MM-DD');

    isCurrentDate = startDateTime.isAfter(date) && endDateTime.isAfter(date);

    if (isCurrentDate) {
      this.checkSeasonsDate(startDate, endDate, Number(slotId));
    } else {
      this.setState({
        errorMessage: strings.booking_cannot_be_in_the_past,
      });
    }
  };

  conflictOccurred = (
    schedulerData: any,
    action: string,
    event: any,
    type: any,
    slotId: string,
    slotName: string,
    start: string,
    end: string
  ) => {
    this.setState({
      errorMessage: strings.booking_date_conflict_error,
    });
  };

  // individual booking items
  eventItemTemplateResolver = (
    schedulerData: any,
    event: CalendarBookings,
    bgColor: string,
    isStart: boolean,
    isEnd: boolean,
    mustAddCssClass: string,
    mustBeHeight: number,
    agendaMaxEventWidth: any
  ) => {
    const { localeMoment } = schedulerData;

    let backgroundColor = '#64A586';

    if (!!event.status) {
      backgroundColor = event.status == 'confirmed' ? '#64A586' : '#767575';
    }
    let divStyle: any = {
      backgroundColor: backgroundColor,
      height: mustBeHeight,
      marginLeft: '-2px',
      width: 'calc(100% + 4px)',
    };

    let status: BookingStatus = event.status;

    return (
      <Box
        key={event.id}
        className={mustAddCssClass}
        style={divStyle}
        p="4px !important">
        <Flex justifyContent={'space-between'}>
          <span>
            {event?.customer?.first_name} {event?.customer?.last_name}
          </span>
          <span>{strings[status] || status}</span>
        </Flex>
        <Box>
          {localeMoment(event.start).format('DD. MMMM YYYY')} -{' '}
          {localeMoment(event.end).format('DD. MMMM YYYY')}
        </Box>
        <Flex justifyContent={'flex-end'}>
          {strings[event?.payment_status] || event?.payment_status}
        </Flex>
      </Box>
    );
  };

  // days number header in the month
  nonAgendaCellHeaderTemplateResolver = (
    schedulerData: any,
    item: { nonWorkingTime: boolean; time: string },
    formattedDateItems: Array<string>,
    style: Object
  ) => {
    let datetime = schedulerData.localeMoment(item.time).format('MMM DD');

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {datetime}
      </th>
    );
  };

  // define non working days such as weekends
  isNonWorkingTime = (schedulerData: any, time: string) => {
    const { localeMoment } = schedulerData;
    let dayOfWeek = localeMoment(time).day();

    if (dayOfWeek === 0 || dayOfWeek === 6) return true;

    return false;
  };

  // custom date interval, 2 months in the current scenario
  getCustomDate = (schedulerData: any, num: any, date: any = undefined) => {
    let selectDate = schedulerData.startDate;
    if (date != undefined) selectDate = date;

    let firstDayOfMonth = schedulerData
      .localeMoment(selectDate)
      .startOf('month')
      .format('YYYY-MM-DD');

    let startDate =
      num === 0
        ? firstDayOfMonth
        : schedulerData
            .localeMoment(firstDayOfMonth)
            .add(num, 'months')
            .format('YYYY-MM-DD');

    let endDate = schedulerData
      .localeMoment(startDate)
      .add(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');

    let cellUnit = CellUnits.Day;
    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  // when loading the first time the horizontal scroll bar wasn't being placed at the start
  getScrollSpecialMoment = (
    schedulerData: any,
    startMoment: any,
    endMoment: any
  ) => {
    return;
  };
}

const mapStateToProps = (state: any) => {
  return {
    userPermissions: state?.data?.auth?.permissions,
  };
};

export default connect(mapStateToProps)(
  DragDropContext(HTML5Backend)(withRouter(BookingCalendar))
);

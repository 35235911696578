import {
  Flex,
  Heading,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { LogoPNG } from 'assets/images';
import { strings } from 'config/localization';
import FeedbackAnalysisItem from 'pages/dashboard/feedback/Analysis/FeedbackAnalysisItem';
import React, { forwardRef, useEffect, useState } from 'react';
import { DateFormat } from 'utils/DateFormat';

const infoStyles = {
  color: 'gray.900',
  fontWeight: 'normal',
};

interface Props {
  feedbackAnalysis: any;
  ref: any;
  startingSN: number;
  filterParams: any;
}

const FeedbackAnalysisPDF = forwardRef((props: Props, ref: any) => {
  const { feedbackAnalysis, startingSN, filterParams } = props;
  const [showFilterParams, setShowFilterParams] = useState<boolean>(false);

  useEffect(() => {
    const filterParamsFlag =
      filterParams?.keyword ||
      filterParams?.type ||
      filterParams?.apartment_name ||
      filterParams?.start_date ||
      filterParams?.end_date;
    setShowFilterParams(!!filterParamsFlag);
  }, [
    filterParams?.apartment_name,
    filterParams?.end_date,
    filterParams?.keyword,
    filterParams?.start_date,
    filterParams?.type,
  ]);

  const selectQuestionType = (type: any) => {
    const typeName = type.toLowerCase();
    const questionType: { [key: string]: string } = {
      rating: strings.rating,
      open: strings.open_ended,
      closed: strings.closed_ended,
    };
    return questionType[typeName];
  };

  return (
    <Stack direction="column" id="feedback-print" ref={ref}>
      <Flex justify="space-between">
        <Stack pl={2} direction="row" alignItems="center">
          <Image src={LogoPNG} width="80px" alt="MyVal Logo" />
          <Stack ml="10px !important">
            <Heading size="lg" textTransform="capitalize">
              MyVal Service Center
            </Heading>
            <Heading size="sm">Appartementvermittlung Familie Clausen</Heading>
          </Stack>
        </Stack>
        <Heading
          size="md"
          textTransform="capitalize"
          marginTop="10px"
          marginRight="10px">
          {strings.feedback_analysis}
        </Heading>
      </Flex>
      <Stack
        direction="column"
        bg="white"
        p={['3', '6']}
        margin="0 !important"
        sx={infoStyles}>
        {showFilterParams && (
          <div id="feedback-filter-block">
            <span id="filter-header-text">{strings.filter}</span>
            {filterParams?.keyword && (
              <span>
                <span className="filter-keyword-text">
                  {strings.text_search}:
                </span>
                &ensp;{filterParams?.keyword}
              </span>
            )}
            {filterParams?.type && (
              <span>
                <span className="filter-keyword-text">{strings.type}:</span>
                &ensp;{selectQuestionType(filterParams?.type)}
              </span>
            )}
            {filterParams?.apartment_name && (
              <span>
                <span className="filter-keyword-text">{strings.object}:</span>
                &ensp;{filterParams?.apartment_name}
              </span>
            )}
            {filterParams?.start_date && (
              <span>
                <span className="filter-keyword-text">{strings.date}:</span>
                &ensp;{DateFormat(filterParams?.start_date)}&nbsp;-&nbsp;
                {DateFormat(filterParams?.end_date)}
              </span>
            )}
          </div>
        )}
        <TableContainer overflowY="auto">
          <Table
            size="sm"
            style={{
              whiteSpace: 'normal',
            }}>
            <Thead
              style={{
                borderBottom: '1px solid #000',
              }}>
              <Tr>
                <Th color="blackAlpha.900">{strings.sn}</Th>
                <Th color="blackAlpha.900">{strings.title}</Th>
                <Th color="blackAlpha.900">{strings.type}</Th>
                <Th color="blackAlpha.900" textAlign="center">
                  {strings.result}
                </Th>
              </Tr>
            </Thead>
            <Tbody id="feedback-pdf-table-body">
              {feedbackAnalysis?.data?.map(
                (feedbackItem: any, index: number) => {
                  return (
                    <>
                      <div className="page-break" />
                      <FeedbackAnalysisItem
                        key={index}
                        index={startingSN + index}
                        data={feedbackItem}
                      />
                    </>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
});

FeedbackAnalysisPDF.displayName = 'FeedbackAnalysisPDF';

export default FeedbackAnalysisPDF;

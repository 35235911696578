import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FAQResource from 'api/faq';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FaqListItem from 'components/faq/FaqListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { FAQSchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
} from 'utils';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  status: string;
  group_id: string;
  type: string;
}

const Faqs: React.FC = () => {
  let faqAPI = new FAQResource();
  let faqGroupAPI = new FAQGroupResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    status: searchValues.status ?? '',
    group_id: searchValues.group_id ?? '',
    type: searchValues.type ?? '',
  });

  const queryList = useQuery(
    [
      `faq-list`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        status: filterParams.status,
        group_id: filterParams.group_id,
        type: filterParams.type,
      },
    ],
    () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.group_id) queryParams.group_id = filterParams.group_id;
      if (filterParams.type) queryParams.type = filterParams.type;
      return faqAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );
  const {
    data: faqsQuery,
    isLoading: faqsLoading,
    error: faqsError,
  } = queryList;

  const { data: faqGroupList } = useQuery(
    ['faq-group-list'],
    () => {
      return faqGroupAPI
        .list()
        .then((res) => res.data?.data)
        .catch();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const faqGroupOptions = useMemo(() => {
    return faqGroupList?.map((faqGroup: any) => {
      return {
        label: faqGroup.name,
        value: faqGroup.id,
      };
    });
  }, [faqGroupList]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      status: '',
      group_id: '',
      type: '',
    }));
    history.push(routes.faq.faqList.list);
  };

  /**
   * Handle filter params submit
   */

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
            {strings.faq}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
            {strings.all_faq}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.faq}
        </Heading>
        <RouterLink to={routes.faq.faqList.add}>
          <Button colorScheme="primary" size="lg">
            {strings.add_faq}
          </Button>
        </RouterLink>
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
        <AccordionItem>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <form onSubmit={(e) => e.preventDefault()}>
                <Stack direction="column" spacing="4">
                  <Stack
                    direction={['column', 'column', 'row']}
                    spacing="4"
                    align={['start', 'start', 'end']}>
                    <Grid
                      gap={['4', '4', '6']}
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(3, 1fr)',
                      ]}
                      w="100%">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.search}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              h="100%"
                              children={<BiSearch />}
                              color="gray.800"
                            />
                            <Input
                              name="keyword"
                              size="lg"
                              value={filterParams.keyword}
                              type="text"
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.group}</FormLabel>
                          <Select
                            name="group_id"
                            size="lg"
                            value={filterParams.group_id}
                            placeholder={strings.select_group}
                            onChange={handleInputChange}
                            rounded="sm">
                            {faqGroupOptions?.map((item: any) => (
                              <option key={item.value} value={item.value}>
                                {capitalizeFirstLetter(item.label)}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.status}</FormLabel>
                          <Select
                            name="status"
                            size="lg"
                            placeholder={strings.select_status}
                            value={filterParams.status}
                            onChange={handleInputChange}
                            rounded="sm">
                            <option value="1">ON</option>
                            <option value="0">OFF</option>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Stack direction="row" spacing="4">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        type="button"
                        variant="outline"
                        onClick={() => handleReset()}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.question}</Th>
                <Th>{strings.group}</Th>
                <Th>{strings.status}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {faqsQuery?.data?.map((faqItem: FAQSchema, index: number) => (
                <FaqListItem
                  data={faqItem}
                  key={faqItem.id}
                  index={startingSN + index}
                  search={search}
                />
              ))}
            </Tbody>
            {faqsLoading && <TableSkeletonLoader rows={7} cols={5} />}
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default Faqs;

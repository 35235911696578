import {
  Icon,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { LanguageTypesSchema } from 'constants/schema';
import React from 'react';
import { BiCheck, BiChevronRightCircle, BiWorld } from 'react-icons/bi';

const hoverMenuBoxStyles = {
  maxW: '120px',
  borderRadius: 'sm',
  bg: 'primary.400',
  color: 'white',
  boxShadow: 'dark-lg',
};

const listItemHoverStyle = {
  py: '12px',
  px: '6px',
  borderRadius: 'base',
  _hover: {
    bg: 'rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
  },
};

const ChangeLanguagePopOver = () => {
  let currentLanguage = strings.getLanguage();

  const handleLanguage = (Language: LanguageTypesSchema) => {
    localStorage.setItem('language', Language);
    window.location.reload();
  };

  return (
    <Popover placement="right" trigger="hover">
      <PopoverTrigger>
        <Stack direction="row" spacing={4} alignItems="center">
          <BiWorld />
          <Text color="white">{strings.change_language}</Text>
          <BiChevronRightCircle />
        </Stack>
      </PopoverTrigger>

      <PopoverContent sx={hoverMenuBoxStyles}>
        <PopoverArrow bg="primary.400" width="16px" />
        <PopoverBody>
          <Stack direction="column">
            <List>
              <ListItem
                sx={listItemHoverStyle}
                onClick={() => handleLanguage('de')}>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <Text color="white">{strings.german}</Text>
                  {currentLanguage === 'de' && (
                    <Icon as={BiCheck} color="white" w="5" h="5" />
                  )}
                </Stack>
              </ListItem>
              <ListItem
                sx={listItemHoverStyle}
                onClick={() => handleLanguage('en')}>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <Text color="white">{strings.english}</Text>
                  {currentLanguage === 'en' && (
                    <Icon as={BiCheck} color="white" w="5" h="5" />
                  )}
                </Stack>
              </ListItem>
              <ListItem
                sx={listItemHoverStyle}
                onClick={() => handleLanguage('fr')}>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <Text color="white">{strings.french}</Text>
                  {currentLanguage === 'fr' && (
                    <Icon as={BiCheck} color="white" w="5" h="5" />
                  )}
                </Stack>
              </ListItem>
              <ListItem
                sx={listItemHoverStyle}
                onClick={() => handleLanguage('it')}>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <Text color="white">{strings.italian}</Text>
                  {currentLanguage === 'it' && (
                    <Icon as={BiCheck} color="white" w="5" h="5" />
                  )}
                </Stack>
              </ListItem>
            </List>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ChangeLanguagePopOver;

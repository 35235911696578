import { Box, Flex, useRadio } from '@chakra-ui/react';
import React from 'react';

function RoundCustomRadio(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const customCSS = {
    width: '16px',
    height: '16px',
    px: '2',
    color: '',
    borderColor: '#BFBFBF',
  };

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Flex
      as="label"
      cursor="pointer"
      fontSize="sm"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      color={customCSS.color}
      borderColor={customCSS.borderColor}
      mr="4">
      <input {...input} />

      <Box
        {...checkbox}
        borderWidth="1px"
        borderRadius="full"
        _checked={{
          bg: 'primary.600',
          color: 'white',
          borderColor: 'primary.600',
        }}
        width={customCSS.width}
        height={customCSS.height}
        px={customCSS.px}
        mr="1"></Box>
      {props.children}
    </Flex>
  );
}
export default RoundCustomRadio;

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ContactForm from 'components/crm/ContactForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ContactType } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { cleanData } from 'utils';
import history from 'utils/history';

const AddContact: React.FC = () => {
  const { id: contactID }: any = useParams();
  const { id: userID } = useSelector((state: any) => state.data.auth.user);
  const { search } = useLocation<any>();
  const searchParams = new URLSearchParams(search);
  const contactType: ContactType =
    (searchParams.get('contactType') as ContactType) || 'guest';

  const methods = useForm<any>();
  const toast = useToast();
  let contactAPI = new CRMContactsResource();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: contactDetail, isLoading: isContactDetailsLoading } = useQuery(
    ['contact-details-page', contactID],
    () => {
      if (!contactID) return null; // { data: null };
      return contactAPI
        .get(contactID)
        .then((res) => res.data.data)
        .catch();
    }
  );
  const countriesListQuery = useQuery('country-list', () =>
    contactAPI.listCountries().then((res: any) => res.data)
  );
  const countriesList = countriesListQuery?.data?.data.data;

  const createContact = useMutation((data: any) => contactAPI.store(data));
  const updateContact = useMutation((data: any) =>
    contactAPI.update(contactID, data)
  );

  const onSubmit = async (formData: any) => {
    let API;
    if (contactID) {
      formData.updated_by = userID;
      API = updateContact;
    } else {
      formData.source = 'manual';
      formData.created_by = userID;
      API = createContact;
    }
    const data = cleanData(formData);
    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.contact} ${
            contactID ? strings.has_been_updated : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.crm.contact.list);
      },
      onError: (err: any) => {
        const fallbackErrorMsg = contactID
          ? `${strings.contact} ${strings.has_not_been_updated}`
          : `${strings.contact} ${strings.has_not_been_created}`;
        toast({
          title: strings.error,
          description: err?.response?.data?.error?.message || fallbackErrorMsg,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const getAddContactHeading = () => {
    if (contactType === 'guest') return strings.add_new_guest;
    return strings.add_new_owner;
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
              {strings.contact_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.crm.contact.list + search}>
              {strings.contact_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {contactID
                ? contactDetail?.first_name + ' ' + contactDetail?.last_name
                : getAddContactHeading()}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {contactID ? strings.edit_contact : getAddContactHeading()}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {contactID &&
                (isContactDetailsLoading || countriesListQuery.isLoading) ? (
                  <CenterSpinner />
                ) : (
                  <ContactForm
                    data={contactDetail}
                    type={contactType}
                    countriesList={countriesList}
                  />
                )}
                <ButtonGroup justifyContent="flex-end">
                  <Button
                    size="lg"
                    colorScheme="primary"
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                  <Button
                    colorScheme="primary"
                    size="lg"
                    type="submit"
                    isLoading={isLoading}>
                    {!contactID ? strings.add_now : strings.update}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddContact;

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  appartmentInformation: any;
}
const AppartmentInformationOverview = (props: Props) => {
  const { appartmentInformation } = props;
  if (!appartmentInformation) return null;
  let { apartment, booking_info } = appartmentInformation;

  return (
    <>
      <AccordionItem mt="12">
        <h2>
          <AccordionButton background="gray.100">
            <Box flex="1" textAlign="left" fontWeight={700} fontSize="22">
              {strings.appartment_information}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} sx={wrapperStyles}>
          <Stack>
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.appartment_name}
                  </FormLabel>
                  <Box sx={infoStyles}>{apartment.name}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.booked_from}</FormLabel>
                  <Box sx={infoStyles}>
                    {DateFormat(booking_info.from_date)}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.booked_until}</FormLabel>
                  <Box sx={infoStyles}>{DateFormat(booking_info.to_date)}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.adult}</FormLabel>
                  <Box sx={infoStyles}>{booking_info.number_of_adults}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.children}</FormLabel>
                  <Box sx={infoStyles}>{booking_info.number_of_children}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.baby}</FormLabel>
                  <Box sx={infoStyles}>{booking_info.number_of_babies}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.dog}</FormLabel>
                  <Box sx={infoStyles}>{booking_info.number_of_dogs}</Box>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.notes}</FormLabel>
                  <Box sx={infoStyles}>
                    {appartmentInformation.remarks ?? '-'}
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};

export default AppartmentInformationOverview;

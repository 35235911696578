import axios from 'axios';
import { useEffect, useState } from 'react';
import http from 'utils/http';

const useWordSearch = (url: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [query, setQuery] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    setError(false);

    let cancle: Function;

    let debounce = setTimeout(() => {
      http({
        url: url,
        method: 'GET',
        params: query,
        cancelToken: new axios.CancelToken((c) => (cancle = c)),
      })
        .then((res) => {
          setResult(res.data);
          setLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setError(true);
        });
    }, 400);
    return () => {
      clearTimeout(debounce);
      cancle?.();
    };
  }, [query, url]);

  return { loading, error, result, setQuery };
};

export default useWordSearch;

import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ContactSchema } from '../../constants/schema';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
interface Props {
  data?: ContactSchema;
}

const ContactDetailInfo: React.FC<Props> = (props) => {
  let { data } = props;

  if (!data) return null;
  let {
    v_office_id,
    id,
    number,
    last_name,
    first_name,
    street,
    zip_code,
    location,
    company,
    house_no,
    country,
    country_code,
    language,
    letter_opening,
    letter_closing,
    salutation,
    title,
    birth_date,
    owner,
    phone,
    business_phone,
    mobile,
    fax,
    email,
    in_the,
    homepage,
    type,
    source,
    is_subscribed,
    iban,
    swift,
    institute,
    sales_tax_number,
    city,
    contactDetail: {
      contact_id,
      fellow_travellers,
      pet,
      equipment_features,
      services,
      internal_notes,
      external_notes,
      is_merged,
      created_by,
      updated_by,
      created_at,
      updated_at,
    },
  } = data;

  return (
    <>
      <Grid
        gap="4"
        templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.salutation}</FormLabel>
            <Box sx={infoStyles}>{strings[salutation] ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.title}</FormLabel>
            <Box sx={infoStyles}>{title ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.first_name}</FormLabel>
            <Box sx={infoStyles}>{first_name ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.last_name}</FormLabel>
            <Box sx={infoStyles}>{last_name ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
            <Box sx={infoStyles}>{street ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.house_no}</FormLabel>
            <Box sx={infoStyles}>{house_no ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.post_code}</FormLabel>
            <Box sx={infoStyles}>{zip_code ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.town}</FormLabel>
            <Box sx={infoStyles}>{city ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.country}</FormLabel>
            <Box sx={infoStyles}> {country?.name ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.phone}</FormLabel>
            <Box sx={infoStyles}>{phone ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.mobile}</FormLabel>
            <Box sx={infoStyles}>{mobile ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.email}</FormLabel>
            <Box sx={infoStyles}>
              {email ? <a href={`mailto:${email}`}> {email} </a> : '-'}
            </Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
            <Box sx={infoStyles}>{company ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.newsletter}</FormLabel>
            <Box sx={infoStyles}>
              {is_subscribed ? strings.subscribe : strings.unsubscribe}
            </Box>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid gap="2" templateColumns={['repeat(1, 1fr)']} w="100%">
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.notes_notizen}</FormLabel>
            <Box sx={infoStyles}>{internal_notes ?? '-'}</Box>
          </FormControl>
        </GridItem>
      </Grid>
      {type === 'owner' && (
        <>
          <Divider />
          <Heading size="md">{strings.payment_detail}</Heading>
          <Grid
            gap="2"
            templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.iban}</FormLabel>
                <Box sx={infoStyles}>{iban ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.swift_bic}</FormLabel>
                <Box sx={infoStyles}>{swift ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.institute}</FormLabel>
                <Box sx={infoStyles}>{institute ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.sales_tax_id_no}
                </FormLabel>
                <Box sx={infoStyles}>{sales_tax_number ?? '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};

export default ContactDetailInfo;

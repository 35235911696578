import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Textarea,
} from '@chakra-ui/react';
import { infoStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import 'react-perfect-scrollbar/dist/css/styles.css';

const BOOKING_SEARCH_API = `/keyscan/reservation`;
const CUSTOMER_SEARCH_API = `/keyscan/customer`;
const EXTERNAL_COMPANY_SEARCH_API = `/keyscan/external-company`;

interface Props {
  isInStock: boolean;
}

const KeyNewStatusForm: React.FC<Props> = (props) => {
  const { isInStock } = props;

  const { register, control, setValue } = useFormContext<any>();

  const onBookingChange = (e: any) => {
    setValue('reservationId', e);
  };

  const onCustomerChange = (e: any) => {
    setValue('customerId', e);
  };

  const onExternalCompanyChange = (e: any) => {
    setValue('externalCompanyId', e);
  };

  const newKeyStatus = isInStock ? strings.given_out : strings.in_stock;
  const disabled: boolean = !isInStock;

  return (
    <Grid
      gap="4"
      templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
      w="100%">
      <GridItem>
        <FormControl isDisabled>
          <FormLabel>{strings.new_status}</FormLabel>
          <Box sx={infoStyles}>{newKeyStatus ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isDisabled={disabled}>
          <FormLabel>{strings.booking_number}</FormLabel>

          <Controller
            control={control}
            name="reservationId"
            render={({ field }) => {
              return <Box sx={infoStyles}>{field?.value ?? '-'}</Box>;
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isDisabled={disabled}>
          <FormLabel>{strings.customer}</FormLabel>
          <Controller
            control={control}
            name="customerId"
            render={({ field }) => {
              return <Box sx={infoStyles}>{field?.value ?? '-'}</Box>;
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={[1, 3]}>
        <FormControl>
          <FormLabel>{strings.notes}</FormLabel>
          <Textarea placeholder={strings.notes} {...register('notes')} />
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default KeyNewStatusForm;

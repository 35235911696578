import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CustomerDocumentResource from 'api/owner_document';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import { CenterSpinner } from 'components/common/CenterSpinner';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import OwnerDocumentListItem from 'components/owner_document/OwnerDocumentListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { forwardRef, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import {
  BiCalendar,
  BiChevronLeft,
  BiChevronRight,
  BiCloudUpload,
  BiSearch,
} from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import history from 'utils/history';
import ReactDatePickerRoot from '../../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  owner_id: number;
  keyword: any;
  created_at: string;
  available_from_date: string;
  available_to_date: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';
const OwnerList: React.FC = () => {
  const { id }: any = useParams();
  const methods = useForm<any>();
  const { search } = useLocation();
  let ownerDocumentAPI = new CustomerDocumentResource();
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [totalOwnerDocument, setTotalOwnerDocument] = useState<
    number | undefined
  >(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    owner_id: id,
    keyword: null,
    created_at: '',
    available_from_date: '',
    available_to_date: '',
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [documentCreatedAt, setDocumentCreatedAt] = useState<any>();

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalOwnerDocument,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: ownerDocumentList, isLoading: isOwnerDocumentListLoading } =
    useQuery(
      [
        'ownerDocumentList',
        {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
          owner_id: id,
          keyword: filterParams.keyword,
          created_at: filterParams.created_at,
          available_from_date: filterParams.available_from_date,
          available_to_date: filterParams.available_to_date,
        },
      ],
      async () => {
        const queryParams: any = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        };
        queryParams.owner_id = id;
        if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
        if (filterParams.created_at)
          queryParams.created_at = filterParams.created_at;
        if (filterParams.available_from_date)
          queryParams.available_from_date = filterParams.available_from_date;
        if (filterParams.available_to_date)
          queryParams.available_to_date = filterParams.available_to_date;

        const response = await ownerDocumentAPI.list(queryParams);
        setTotalOwnerDocument(response?.data?.meta?.total);
        response && setIsFilterLoading(false);
        return response?.data?.data;
      }
    );

  const filterList = async (data: any) => {
    setIsFilterLoading(true);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      owner_id: id,
      keyword: data.keyword,
      created_at: data.created_at,
      available_from_date: data.available_from_date,
      available_to_date: data.available_to_date,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    if (documentCreatedAt) data.created_at = DateFormatYMD(documentCreatedAt);
    if (startDate) data.available_from_date = DateFormatYMD(startDate);
    if (endDate) data.available_to_date = DateFormatYMD(endDate);
    filterList(data);
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setDocumentCreatedAt('');
    setStartDate(null);
    setEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      owner_id: id,
      keyword: '',
      created_at: '',
      available_from_date: '',
      available_to_date: '',
    }));
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const ownerDocumentQuery = useQuery<any>(
    [`ownerDocument${id}`, id],
    () => ownerDocumentAPI.getOwner(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (ownerDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ownerDocumentQuery.isError) {
    history.push(routes.dms.ownerDocuments.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.ownerDocuments.list + search}>
              {strings.document_owners}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              {ownerDocumentQuery?.data?.full_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.owner_documents}
          </Heading>
          <AccessControl
            allowedPermissions={[PermissionRequest['manage:owner-dms']]}>
            <RouterLink
              to={routes.dms.ownerDocuments.owner.upload.replace(
                ':id',
                id.toString()
              )}>
              <Button
                colorScheme="primary"
                leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
                size="lg">
                {strings.upload_document}
              </Button>
            </RouterLink>
          </AccessControl>
        </Flex>
        <Stack>
          <Stack sx={wrapperStyles}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                <Stack
                  direction={['column', 'column', 'row']}
                  spacing="4"
                  align={['start', 'start', 'end']}>
                  <Grid
                    gap={['4', '4', '6']}
                    templateColumns={[
                      'repeat(1, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(3, 1fr)',
                    ]}
                    w="100%">
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.search}</FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BiSearch />}
                            color="gray.400"
                          />
                          <Input
                            id="title"
                            type="text"
                            {...methods.register('keyword')}
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.upload_date}</FormLabel>
                        <InputGroup>
                          <InputRightElement
                            pointerEvents="none"
                            children={<BiCalendar />}
                            color="gray.400"
                          />
                          <ReactDatePickerRoot
                            placeholderText={strings.select_date}
                            id="created_at"
                            dateFormat="yyyy-MM-dd"
                            selected={documentCreatedAt}
                            customInput={
                              <CreatedAtInput value={documentCreatedAt} />
                            }
                            onChange={(date: Date) =>
                              setDocumentCreatedAt(date)
                            }
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.availability}</FormLabel>
                        <InputGroup>
                          <InputRightElement
                            pointerEvents="none"
                            children={<BiCalendar />}
                            color="gray.400"
                          />
                          <ReactDatePickerRoot
                            placeholderText={strings.select_date}
                            dateFormat="yyyy-MM-dd"
                            customInput={<CreatedAtInput />}
                            onChange={handleDateChange}
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            value={getFormattedRangeDate(startDate, endDate)}
                            shouldCloseOnSelect={false}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Stack direction="row" spacing="4">
                    <Button
                      size="lg"
                      colorScheme="primary"
                      type="submit"
                      variant="outline"
                      onClick={handleReset}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.document_name}</Th>
                  <Th>{strings.upload_date}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isOwnerDocumentListLoading &&
                  ownerDocumentList?.map(
                    (ownerDocumentData: any, index: number) => (
                      <OwnerDocumentListItem
                        key={ownerDocumentData.id}
                        index={startingSN + index}
                        ownerDocumentData={ownerDocumentData}
                      />
                    )
                  )}
                {isOwnerDocumentListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isOwnerDocumentListLoading}
              totalCount={totalOwnerDocument}
              resultCount={ownerDocumentList?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {ownerDocumentList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isOwnerDocumentListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default OwnerList;

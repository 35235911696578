import http from 'utils/http';
import Resource from './resource';

class BMSCheckInResource extends Resource {
  constructor() {
    super('bms/booking-registers/check-in');
  }

  checkIn(id: number) {
    return http({
      url: `/bms/booking-registers/${id}/check-in`,
      method: 'PUT',
    });
  }
}

export { BMSCheckInResource as default };

import { useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { checkSomePermissions } from 'utils/listInfo';

const settings = process.env.REACT_APP_CONFIGURATION;
interface Props {
  serviceName?: string;
  component: any;
  userPermissions?: any;
  allowedPermissions?: any;
  path: string | string[];
  exact?: boolean;
}

const RoleBasedRouting: React.FC<Props> = ({
  serviceName,
  userPermissions,
  allowedPermissions,
  component: Component,
  path,
  exact = false,
}) => {
  const history = useHistory();
  const toast = useToast();
  // settings check to show/hide services(based on .env)
  if (settings === 'true') {
    switch (serviceName) {
      case 'user-service':
        if (process.env.REACT_APP_USER_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'chat-service':
        if (process.env.REACT_APP_CHAT_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'ticket-service':
        if (process.env.REACT_APP_TICKET_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'crm-service':
        if (process.env.REACT_APP_CRM_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'contact-center-service':
        if (process.env.REACT_APP_CONTACT_CENTER_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'feedback-service':
        if (process.env.REACT_APP_FEEDBACK_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'dashboard':
        if (process.env.REACT_APP_DASHBOARD === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'crm-service':
        if (process.env.REACT_APP_CRM_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'oms-service':
        if (process.env.REACT_APP_OMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'sms-service':
      case 'sms-service-group':
        if (process.env.REACT_APP_SMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'bms-booking':
        if (process.env.REACT_APP_BMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'general-list':
      case 'customer-documents':
      case 'object-documents':
      case 'owner-documents':
      case 'user-documents':
        if (process.env.REACT_APP_DMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'faq-list':
      case 'internal-faq':
      case 'group-faq':
        if (process.env.REACT_APP_FAQ_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'pms-service':
        if (process.env.REACT_APP_PMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'pns-service':
        if (process.env.REACT_APP_PNS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'newsletter-service':
        if (process.env.REACT_APP_NEWSLETTER_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'Key-service':
        if (process.env.REACT_APP_KEY_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'aerial-map-service':
        if (process.env.REACT_APP_AERIAL_MAP_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
    }
  }

  const permitted: any = checkSomePermissions(
    userPermissions,
    allowedPermissions
  );

  const handlingNoAccess = () => {
    toast({
      title: strings.no_permission_msg,
      status: 'error',
      isClosable: true,
    });
    history.goBack();
    return null;
  };

  return (
    <>
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) =>
          permitted ? <Component {...props} /> : handlingNoAccess()
        }
      />
    </>
  );
};

export default connect((state: any) => ({
  userPermissions: state?.data?.auth?.permissions,
}))(RoleBasedRouting);

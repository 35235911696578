import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Stack,
  TableContainer,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/table';
import BMSBookingResource from 'api/bms-bookings';
import { wrapperStyles } from 'assets/css/commonStyles';
import BookingListItem from 'components/bms_booking/bms_booking_overview/BookingListItem';
import CustomSelect from 'components/common/CustomSelect';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  BMS_APARTMENT_SEARCH_API,
  BMS_CUSTOMER_SEARCH_API,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { BookingPaymentStatus, BookingStatus } from 'constants/schema';
import { BMSBooking } from 'constants/schemas/bookings';
import useCheckPermission from 'hooks/useCheckPermission';
import { useDebounce } from 'hooks/useDebounce';
import React, { forwardRef, useEffect, useState } from 'react';
import { BiCalendar, BiFilter } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  booking_number: string;
  payment_status: BookingPaymentStatus | '';
  status: BookingStatus | '';
  distribution_channel: string;
  customer_id: string;
  customer: string;
  apartment_id: string;
  apartment: string;
  from_date: string | Date | null;
  to_date: string | Date | null;
}

const DatePickerInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

DatePickerInput.displayName = 'DatePickerInput';

const BookingList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const { role } = useSelector((state: any) => ({
    role: state?.data?.auth?.user.role,
  }));

  const [startDate, setStartDate] = useState<Date | null>(
    searchValues.from_date ? new Date(searchValues.from_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    searchValues.to_date ? new Date(searchValues.to_date) : null
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    booking_number: searchValues.booking_number ?? '',
    status: searchValues.status ?? '',
    payment_status: searchValues.payment_status ?? '',
    distribution_channel: searchValues.distribution_channel ?? '',
    customer_id: searchValues.customer_id ?? '',
    customer: searchValues.customer ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    apartment: searchValues.apartment ?? '',
    from_date: DateFormatYMD(startDate),
    to_date: DateFormatYMD(endDate),
  });

  const bmsBookingAPI = new BMSBookingResource();
  let bookingNumber = useDebounce(filterParams.booking_number, 500);

  const bookingListData: any = useQuery(
    [
      'booking-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        payment_status: filterParams.payment_status,
        distribution_channel: filterParams.distribution_channel,
        customer_id: filterParams.customer_id,
        customer: filterParams.customer,
        apartment_id: filterParams.apartment_id,
        from_date: DateFormatYMD(startDate),
        to_date: DateFormatYMD(endDate),
      },
      bookingNumber,
    ],
    () => {
      let {
        status,
        booking_number,
        payment_status,
        distribution_channel,
        customer_id,
        apartment_id,
        customer,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (status) queryParams.status = status;
      if (bookingNumber) queryParams.booking_number = bookingNumber;
      if (payment_status) queryParams.payment_status = payment_status;
      if (distribution_channel)
        queryParams.distribution_channel = distribution_channel;
      if (customer_id) queryParams.customer_id = customer_id;
      if (customer) queryParams.customer = customer;
      if (apartment_id) queryParams.apartment_id = apartment_id;
      if (startDate) queryParams.from_date = DateFormatYMD(startDate);
      if (endDate) queryParams.to_date = DateFormatYMD(endDate);

      return bmsBookingAPI
        .list(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch();
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleCustomerFilterChange = (e: any) => {
    const { id, name } = e;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      customer_id: id,
      customer: name,
    }));
  };

  const handleApartmentFilterChange = (e: any) => {
    const { id, name } = e;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      apartment_id: id,
      apartment: name,
    }));
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    const newFilter: any = {
      ...filterParams,
      from_date: DateFormatYMD(date),
    };
    setFilterParams(newFilter);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    const newFilter: any = {
      ...filterParams,
      to_date: DateFormatYMD(date),
    };
    setFilterParams(newFilter);
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      booking_number: '',
      status: '',
      payment_status: '',
      distribution_channel: '',
      customer_id: '',
      customer: '',
      apartment_id: '',
      apartment: '',
      from_date: null,
      to_date: null,
    }));
    setStartDate(null);
    setEndDate(null);
    history.push(routes.bms.booking.list);
  }

  const checkCreateBookingPermission = useCheckPermission(
    [PermissionRequest['manage:bookings']],
    routes.bms.booking.add
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.booking_management}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
              {strings.booking_overview}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.booking_overview}
          </Heading>

          {role !== 'owner' && (
            <Button
              colorScheme="primary"
              size="lg"
              onClick={checkCreateBookingPermission}>
              {strings.create_new_booking}
            </Button>
          )}
        </Flex>
        <Accordion bg="white" borderColor="white" allowToggle boxShadow="box">
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Grid
                    templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
                    gap="4">
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.booking_number}</FormLabel>
                        <Input
                          type="text"
                          name="booking_number"
                          placeholder={strings.booking_number}
                          value={filterParams.booking_number}
                          onChange={handleInputChange}
                          size="lg"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.booking_status}</FormLabel>
                        <Select
                          name="status"
                          value={filterParams.status}
                          placeholder={strings.booking_status}
                          onChange={handleInputChange}
                          size="lg">
                          <option value="confirmed">{strings.confirmed}</option>
                          <option value="reserved">{strings.reserved}</option>
                        </Select>
                      </FormControl>
                    </GridItem>
                    {role !== 'owner' && (
                      <>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.payment_status}</FormLabel>
                            <Select
                              name="payment_status"
                              value={filterParams.payment_status}
                              placeholder={strings.payment_status}
                              onChange={handleInputChange}
                              size="lg">
                              <option value="open">{strings.not_paid}</option>
                              <option value="paid">{strings.paid}</option>
                              <option value="partial_paid">
                                {strings.partial_paid}
                              </option>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>
                              {strings.distribution_channel}
                            </FormLabel>
                            <Select
                              name="distribution_channel"
                              value={filterParams.distribution_channel}
                              placeholder={strings.distribution_channel}
                              onChange={handleInputChange}
                              size="lg">
                              <option value="call">{strings.call}</option>
                              <option value="email">{strings.email}</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </>
                    )}

                    {role === 'owner' ? (
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.customer}</FormLabel>
                          <Input
                            type="text"
                            name="customer"
                            placeholder={strings.customer}
                            value={filterParams.customer}
                            onChange={handleInputChange}
                            size="lg"
                          />
                        </FormControl>
                      </GridItem>
                    ) : (
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.customer}</FormLabel>
                          <CustomSelect
                            id="customer_id"
                            placeholder={strings.select}
                            value={{ name: filterParams.customer }}
                            onChange={handleCustomerFilterChange}
                            SEARCH_API={BMS_CUSTOMER_SEARCH_API}
                            limit={30}
                          />
                        </FormControl>
                      </GridItem>
                    )}

                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.apartment}</FormLabel>
                        <CustomSelect
                          id="apartment_id"
                          placeholder={strings.select}
                          value={{ name: filterParams.apartment }}
                          onChange={handleApartmentFilterChange}
                          SEARCH_API={BMS_APARTMENT_SEARCH_API}
                          limit={30}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.booked_from}</FormLabel>
                        <InputGroup>
                          <InputRightElement
                            h="100%"
                            pointerEvents="none"
                            children={<BiCalendar />}
                            color="gray.500"
                          />
                          <ReactDatePickerRoot
                            name="from_date"
                            value={DateFormat(filterParams.from_date)}
                            placeholderText={strings.select_date}
                            dateFormat="dd.MM.yyyy"
                            selected={startDate}
                            customInput={<DatePickerInput value={startDate} />}
                            onChange={(date: Date) =>
                              handleStartDateChange(date)
                            }
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.booked_until}</FormLabel>
                        <InputGroup>
                          <InputRightElement
                            h="100%"
                            pointerEvents="none"
                            children={<BiCalendar />}
                            color="gray.500"
                          />
                          <ReactDatePickerRoot
                            name="to_date"
                            value={DateFormat(filterParams.to_date)}
                            placeholderText={strings.select_date}
                            dateFormat="dd.MM.yyyy"
                            selected={endDate}
                            customInput={<DatePickerInput value={endDate} />}
                            onChange={(date: Date) => handleEndDateChange(date)}
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Stack direction="row" justify="flex-end" mt="6">
                    <Button
                      alignSelf="end"
                      justifySelf="end"
                      colorScheme="primary"
                      variant="outline"
                      onClick={handleReset}
                      size="lg">
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.apartment_name}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th>{strings.customer_name}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.booking_number}</Th>
                  {role !== 'owner' && (
                    <>
                      <Th>{strings.payment_status}</Th>
                      <Th>{strings.distribution_channel}</Th>
                      <Th>{strings.total_amount}</Th>
                      <Th>{strings.amount_open}</Th>
                    </>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {bookingListData?.data?.data?.map((booking: BMSBooking) => {
                  return (
                    <BookingListItem
                      key={booking.id}
                      data={booking}
                      search={search}
                    />
                  );
                })}
              </Tbody>
              {bookingListData.isLoading && (
                <TableSkeletonLoader
                  rows={7}
                  cols={role !== 'owner' ? 10 : 6}
                />
              )}
            </Table>
          </TableContainer>
        </Stack>

        <Pagination
          dataList={bookingListData}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default BookingList;

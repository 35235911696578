import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { Salutation } from 'constants/schema';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface Props {
  customerInformation: any;
}

const CustomerInformationOverview: React.FC<Props> = (props: Props) => {
  const { customerInformation } = props;

  let salutation: Salutation = customerInformation.salutation;

  return (
    <>
      <AccordionItem>
        <h2>
          <AccordionButton background="gray.50">
            <Box flex="1" textAlign="left" fontWeight={700} fontSize="22">
              {strings.customer_information}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} sx={wrapperStyles}>
          <Stack>
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
              w="100%">
              <GridItem>
                <Grid gap="4" templateColumns={['repeat(2, 1fr)']} w="100%">
                  <FormControl>
                    <FormLabel sx={labelStyles}>{strings.salutation}</FormLabel>
                    <Box sx={infoStyles}>{strings[salutation] ?? '-'}</Box>
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={labelStyles}>{strings.title}</FormLabel>
                    <Box sx={infoStyles}>
                      {customerInformation?.title
                        ? customerInformation?.title
                        : '-'}
                    </Box>
                  </FormControl>
                </Grid>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.first_name}</FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.first_name
                      ? customerInformation?.first_name
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.last_name}</FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.last_name
                      ? customerInformation?.last_name
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.street
                      ? customerInformation?.street
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <Grid gap="4" templateColumns={['repeat(2, 1fr)']} w="100%">
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      {strings.house_number}
                    </FormLabel>
                    <Box sx={infoStyles}>
                      {customerInformation?.house_no
                        ? customerInformation?.house_no
                        : '-'}
                    </Box>
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={labelStyles}>{strings.post_code}</FormLabel>
                    <Box sx={infoStyles}>
                      {customerInformation?.zip_code
                        ? customerInformation?.zip_code
                        : '-'}
                    </Box>
                  </FormControl>
                </Grid>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.city}</FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.city
                      ? customerInformation?.city
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.country}</FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.country
                      ? customerInformation?.country.name
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.email_address}
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.email
                      ? customerInformation?.email
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.phone_number}</FormLabel>
                  <Box sx={infoStyles}>
                    {customerInformation?.phone
                      ? customerInformation?.phone
                      : '-'}
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};

export default CustomerInformationOverview;

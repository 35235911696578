import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FaqGroupItem from 'components/faq/FaqGroupItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const GroupList: React.FC = () => {
  const methods = useForm<any>();
  const toast = useToast();
  const queryClient = useQueryClient();
  let faqGroupAPI = new FAQGroupResource();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const faqGroupList = useQuery(
    [
      'faq-group-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      return faqGroupAPI
        .list()
        .then((res) => res.data)
        .catch();
    }
  );

  const handleFilterSubmit = (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };
  const handleReset = () => {
    methods.reset();
    setFilterParams((prevState) => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
    }));
  };

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    setIsLoading(true);
    handleFilterSubmit(data);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
            {strings.faq}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.faq.faqGroup.list}>
            {strings.group}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.group}
        </Heading>
        <RouterLink to={routes.faq.faqGroup.add}>
          <Button colorScheme="primary" size="lg">
            {strings.add_group}
          </Button>
        </RouterLink>
      </Flex>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.status}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {faqGroupList?.data?.data?.map((item: any, index: number) => (
                <FaqGroupItem
                  key={item.id}
                  index={startingSN + index}
                  data={item}
                />
              ))}
            </Tbody>
            {faqGroupList.isLoading && (
              <TableSkeletonLoader rows={7} cols={4} />
            )}
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={faqGroupList}
      />
    </Stack>
  );
};

export default GroupList;

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useRadioGroup,
} from '@chakra-ui/react';
import RoundCustomRadio from 'components/common/RoundCustomRadio';
import { strings } from 'config/localization';
import { ContactInputSchema, ContactType } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GoInfo } from 'react-icons/go';
import { mobileNumberRegex, phoneNumberRegex } from 'utils';
import { validateNumber, validEmail } from 'utils/validate';
import OwnerOnlyFormFields from './OwnerOnlyFormFields';

interface Props {
  data?: any;
  type: ContactType;
  countriesList: any;
}

const NOTES_CHAR_LIMIT = 2000;

const ContactForm: React.FC<Props> = (props) => {
  let { data, type, countriesList } = props;

  const [characterCount, setCharacterCount] = useState<number>(0);

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useFormContext<ContactInputSchema>();

  const notes = watch('internal_notes') || '';

  useEffect(() => {
    setCharacterCount(notes.length);
    if (notes.length > 2000) {
      setError(
        'internal_notes',
        {
          type: 'manual',
          message: strings.max_characters_exceeded,
        },
        {
          shouldFocus: true,
        }
      );
    } else {
      clearErrors('internal_notes');
    }
  }, [notes, setError, clearErrors]);

  useEffect(() => {
    if (data) {
      setValue('salutation', data.salutation);
      setValue('title', data.title);
      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
      setValue('street', data.street);
      setValue('source', data.source);
      setValue('zip_code', data.zip_code);
      setValue('house_no', data.house_no);
      setValue('city', data.city);
      setValue('country_code', data.country_code);
      setValue('phone', data.phone);
      setValue('mobile', data.mobile);
      setValue('email', data.email);
      setValue('company', data.company);
      setValue('type', data.type);
      setValue(
        'is_subscribed',
        data.is_subscribed === 'true' || data.is_subscribed === true
          ? true
          : false
      );
      setValue('internal_notes', data.contactDetail?.internal_notes);
    } else {
      setValue('type', type);
    }
  }, [data, setValue, type]);

  const handleNewsletter = (value: string | boolean) => {
    setValue(
      'is_subscribed',
      value === 'true' || value === true ? true : false
    );
  };

  const {
    getRootProps: getRootPropsNewsletter,
    getRadioProps: getRadioPropsNewsletter,
  } = useRadioGroup({
    name: 'SchemeTypeRadioNewsletter',
    defaultValue: data?.is_subscribed ? 'true' : 'false',
    onChange: handleNewsletter,
  });

  const groupNewsletter = getRootPropsNewsletter();

  return (
    <form>
      <Stack direction="column" spacing="4">
        <Stack direction="row" spacing="8">
          <Grid
            gap="4"
            templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl isInvalid={!!errors?.salutation} isRequired>
                <FormLabel>{strings.salutation}</FormLabel>
                <Select
                  placeholder={strings.select_salutation}
                  size="lg"
                  isRequired={false}
                  {...register('salutation', {
                    required: strings.salutation_is_required,
                  })}>
                  <option value="Mr">{strings.mr}</option>
                  <option value="Ms">{strings.ms}</option>
                  <option value="Diverse">{strings.diverse}</option>
                </Select>
                <FormErrorMessage>
                  {errors?.salutation && errors?.salutation?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={!!errors?.title}>
                <FormLabel>{strings.title}</FormLabel>
                <Input
                  size="lg"
                  isRequired={false}
                  placeholder={strings.title}
                  {...register('title')}
                />
                <FormErrorMessage>
                  {errors?.title && errors?.title?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.first_name}>
                <FormLabel>{strings.first_name}</FormLabel>
                <Input
                  {...register('first_name', {
                    required: strings.required_first_name,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  size="lg"
                  type="text"
                  placeholder={strings.first_name}
                  defaultValue={data?.first_name}
                />
                <FormErrorMessage>
                  {errors.first_name && errors.first_name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.last_name}>
                <FormLabel>{strings.last_name}</FormLabel>
                <Input
                  {...register('last_name', {
                    required: strings.required_last_name,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  type="text"
                  size="lg"
                  placeholder={strings.last_name}
                  defaultValue={data?.last_name}
                />
                <FormErrorMessage>
                  {errors.last_name && errors.last_name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.street}>
                <FormLabel>{strings.street}</FormLabel>
                <Input
                  {...register('street', {
                    required: strings.street_name_is_required,
                  })}
                  type="text"
                  size="lg"
                  placeholder={strings.street}
                  defaultValue={data?.street}
                />
                <FormErrorMessage>
                  {errors.street && errors.street?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors?.house_no}>
                <FormLabel>{strings.house_no}</FormLabel>
                <Input
                  {...register('house_no', {
                    validate: (value) => {
                      if (!value) {
                        return strings.house_number_is_required;
                      } else {
                        return (
                          validateNumber(value) || strings.valid_only_number
                        );
                      }
                    },
                  })}
                  size="lg"
                  type="text"
                  placeholder={strings.house_no}
                />
                <FormErrorMessage>
                  {errors?.house_no && errors?.house_no?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors?.zip_code}>
                <FormLabel>{strings.post_code}</FormLabel>
                <Input
                  {...register('zip_code', {
                    validate: (value) => {
                      if (!value) {
                        return strings.post_code_is_required;
                      } else {
                        return (
                          validateNumber(value) || strings.valid_only_number
                        );
                      }
                    },
                  })}
                  size="lg"
                  type="text"
                  isRequired={false}
                  placeholder={strings.post_code}
                  defaultValue={data?.zip_code}
                />
                <FormErrorMessage>
                  {errors?.zip_code && errors?.zip_code?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.city}>
                <FormLabel>{strings.town}</FormLabel>
                <Input
                  {...register('city', {
                    required: strings.city_is_required,
                  })}
                  size="lg"
                  type="text"
                  placeholder={strings.town}
                  defaultValue={data?.city}
                />
                <FormErrorMessage>
                  {errors.city && errors.city?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.country_code}>
                <FormLabel>{strings.country}</FormLabel>
                <Select
                  {...register('country_code', {
                    required: strings.country_is_required,
                  })}
                  size="lg"
                  placeholder={strings.select + ' ' + strings.country}
                  rounded="sm"
                  defaultValue={data?.country}>
                  {countriesList?.map((country: any) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.country_code && errors.country_code?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.phone}>
                <FormLabel>{strings.phone}</FormLabel>
                <Input
                  {...register('phone', {
                    validate: (value) => {
                      if (value) {
                        return (
                          phoneNumberRegex.test(value.trim()) ||
                          strings.invalid_phone_number
                        );
                      } else return strings.phone_number_is_required;
                    },
                  })}
                  size="lg"
                  type="text"
                  placeholder={strings.phone}
                  defaultValue={data?.phone}
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors.mobile}>
                <FormLabel>{strings.mobile}</FormLabel>
                <Input
                  {...register('mobile', {
                    validate: (value) => {
                      if (value) {
                        return (
                          mobileNumberRegex.test(value.trim()) ||
                          strings.invalid_phone_number
                        );
                      } else return true;
                    },
                  })}
                  type="text"
                  size="lg"
                  placeholder={strings.mobile}
                  defaultValue={data?.mobile}
                />
                <FormErrorMessage>
                  {errors.mobile && errors.mobile?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors?.email}>
                <FormLabel>{strings.email}</FormLabel>
                <Input
                  {...register('email', {
                    validate: (value) => {
                      if (value) {
                        return validEmail(value) || strings.valid_email_address;
                      } else return strings.email_required;
                    },
                  })}
                  size="lg"
                  type="email"
                  placeholder={strings.email_placeholder}
                  defaultValue={data?.email}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.company}</FormLabel>
                <Input
                  {...register('company')}
                  type="text"
                  size="lg"
                  placeholder={strings.company}
                  defaultValue={data?.company}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.newsletter}</FormLabel>
                <Controller
                  name="is_subscribed"
                  defaultValue={data?.is_subscribed ?? 'false'}
                  render={({ field }) => {
                    field.value =
                      field.value === 'true' || field.value === true
                        ? 'true'
                        : 'false';
                    return (
                      <RadioGroup {...field} id="is_subscribed">
                        <Stack spacing={5} direction="row" {...groupNewsletter}>
                          <RoundCustomRadio
                            {...getRadioPropsNewsletter({ value: 'true' })}>
                            {strings.subscribe}
                          </RoundCustomRadio>
                          <RoundCustomRadio
                            {...getRadioPropsNewsletter({ value: 'false' })}>
                            {strings.unsubscribe}
                          </RoundCustomRadio>
                        </Stack>
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={!!errors.internal_notes}>
                <Flex direction="row" align="baseline" justify="flex-start">
                  <FormLabel>{strings.notes_notizen}</FormLabel>
                  <Tooltip
                    hasArrow
                    label={
                      strings.notes_cannot_be_more_than_2000_characters_long
                    }
                    placement="right-start"
                    size="sm"
                    zIndex={1}>
                    <span>
                      <Icon as={GoInfo} h="12px" w="12px" color="gray.500" />
                    </span>
                  </Tooltip>
                </Flex>

                <Textarea
                  {...register('internal_notes', {
                    maxLength: {
                      value: NOTES_CHAR_LIMIT,
                      message: strings.max_characters_exceeded,
                    },
                  })}
                  rows={7}
                  placeholder={strings.notes_notizen}
                  defaultValue={data?.contactDetail?.internal_notes}
                />
                <Text
                  textAlign="left"
                  color={
                    characterCount > NOTES_CHAR_LIMIT ? 'red.500' : 'gray.400'
                  }>
                  {characterCount}/{NOTES_CHAR_LIMIT}
                </Text>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        {type === 'owner' && <OwnerOnlyFormFields data={data} />}
      </Stack>
    </form>
  );
};

export default ContactForm;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PMSPaymentLinkAPI from 'api/pms_payment_link';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PMSPaymentLinksListItem from 'components/pms/PMSPaymentLinksListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PMS_PAYMENT_LINK_STATUS_OPTIONS,
  PMS_PAYMENT_METHOD_OPTIONS,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar, BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  bookingNumber: string;
  customerName: string;
  email: string;
  amount: string;
  paymentMethod: string;
  sendFrom: Date | null;
  sendTo: Date | null;
  paidFrom: Date | null;
  paidTo: Date | null;
  status: string;
}

const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const PaymentLinksList: React.FC = () => {
  const pmsPaymentLinkAPI = new PMSPaymentLinkAPI();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (var pair of searchEntries) searchValues[pair[0]] = pair[1];
  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const [sendStartDate, setSendStartDate] = useState<Date | null>(
    searchValues.sendFrom ? new Date(searchValues.sendFrom) : null
  );
  const [sendEndDate, setSendEndDate] = useState<Date | null>(
    searchValues.sendTo ? new Date(searchValues.sendTo) : null
  );

  const [paidStartDate, setPaidStartDate] = useState<Date | null>(
    searchValues.paidFrom ? new Date(searchValues.paidFrom) : null
  );
  const [paidEndDate, setPaidEndDate] = useState<Date | null>(
    searchValues.paidTo ? new Date(searchValues.paidTo) : null
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    bookingNumber: searchValues.bookingNumber ?? '',
    customerName: searchValues.customerName ?? '',
    email: searchValues.email ?? '',
    amount: searchValues.amount ?? '',
    paymentMethod: searchValues.paymentMethod ?? '',
    sendFrom: searchValues.sendFrom ?? null,
    sendTo: searchValues.sendTo ?? null,
    paidFrom: searchValues.paidFrom ?? null,
    paidTo: searchValues.paidTo ?? null,
    status: searchValues.status ?? '',
  });

  const paymentLinksList = useQuery(
    [
      'paymentLinksList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        bookingNumber: filterParams.bookingNumber,
        customerName: filterParams.customerName,
        email: filterParams.email,
        amount: filterParams.amount,
        paymentMethod: filterParams.paymentMethod,
        sendFrom: filterParams.sendFrom,
        sendTo: filterParams.sendTo,
        paidFrom: filterParams.paidFrom,
        paidTo: filterParams.paidTo,
        status: filterParams.status,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.bookingNumber)
        queryParams.booking_number = filterParams.bookingNumber;
      if (filterParams.customerName)
        queryParams.keyword = filterParams.customerName;
      if (filterParams.email) queryParams.email = filterParams.email;
      if (filterParams.amount) queryParams.amount = filterParams.amount;
      if (filterParams.paymentMethod)
        queryParams.payment_method = filterParams.paymentMethod;
      if (filterParams.sendFrom)
        queryParams.email_sent_at_from = filterParams.sendFrom;
      if (filterParams.sendTo)
        queryParams.email_sent_at_to = filterParams.sendTo;
      if (filterParams.paidFrom)
        queryParams.paid_on_from = filterParams.paidFrom;
      if (filterParams.paidTo) queryParams.paid_on_to = filterParams.paidTo;
      if (filterParams.status) queryParams.status = filterParams.status;
      return { data: [] };
      const response = await pmsPaymentLinkAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleSendOnChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setSendStartDate(start);
    setSendEndDate(end);
    const newFilter: any = {
      ...filterParams,
      sendFrom: DateFormatYMD(start),
      sendTo: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
  };

  const handlePaidOnChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setPaidStartDate(start);
    setPaidEndDate(end);
    const newFilter: any = {
      ...filterParams,
      paidFrom: DateFormatYMD(start),
      paidTo: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const handleReset = () => {
    setSendStartDate(null);
    setSendEndDate(null);
    setPaidStartDate(null);
    setPaidEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      bookingNumber: '',
      customerName: '',
      email: '',
      amount: '',
      paymentMethod: '',
      sendFrom: null,
      sendTo: null,
      paidFrom: null,
      paidTo: null,
      status: '',
    }));
    history.push(routes.pms.paymentLinks.list);
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const checkCreatePaymentLinkPermission = useCheckPermission(
    [PermissionRequest['read:pms']],
    routes.pms.paymentLinks.add
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.paypal.list}>
              {strings.pms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pms.paymentLinks.list}>
              {strings.payment_links}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.all_payment_links}
          </Heading>
          <Button
            size="lg"
            colorScheme="primary"
            type="button"
            onClick={checkCreatePaymentLinkPermission}>
            {strings.create_payment_links}
          </Button>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          boxShadow="box"
          defaultIndex={[defaultIndex]}>
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack>
                <Stack sx={wrapperStyles}>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Stack direction="row" spacing="4" align="end">
                      <Grid
                        gap="4"
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(4, 1fr)',
                        ]}
                        flex="1">
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.booking_number}</FormLabel>
                            <InputGroup>
                              <Input
                                type="text"
                                name="bookingNumber"
                                placeholder={strings.booking_number}
                                value={filterParams.bookingNumber}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.customer_name}</FormLabel>
                            <InputGroup>
                              <Input
                                type="text"
                                name="customerName"
                                placeholder={strings.customer_name}
                                value={filterParams.customerName}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.email_address}</FormLabel>
                            <InputGroup>
                              <Input
                                type="text"
                                name="email"
                                placeholder={strings.email}
                                value={filterParams.email}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.amount} (CHF)</FormLabel>
                            <Input
                              type="number"
                              name="amount"
                              value={filterParams.amount}
                              placeholder={strings.amount}
                              onChange={handleInputChange}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.payment_method}</FormLabel>
                            <Select
                              name="paymentMethod"
                              value={filterParams.paymentMethod}
                              placeholder={strings.payment_method}
                              rounded="sm"
                              onChange={handleInputChange}>
                              {PMS_PAYMENT_METHOD_OPTIONS?.map(
                                (paymentMethod) => {
                                  return (
                                    <option
                                      key={paymentMethod}
                                      value={paymentMethod}>
                                      {strings[paymentMethod]}
                                    </option>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.send_on}</FormLabel>
                            <InputGroup>
                              <InputRightElement
                                children={<BiCalendar />}
                                color="gray.400"
                              />
                              <ReactDatePickerRoot
                                placeholderText={strings.send_on}
                                dateFormat="yyyy-MM-dd"
                                customInput={<CreatedAtInput />}
                                selectsRange
                                onChange={handleSendOnChange}
                                selected={sendStartDate}
                                startDate={sendStartDate}
                                endDate={sendEndDate}
                                value={getFormattedRangeDate(
                                  sendStartDate,
                                  sendEndDate
                                )}
                                shouldCloseOnSelect={false}
                                autoComplete="off"
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.paid_on}</FormLabel>
                            <InputGroup>
                              <InputRightElement
                                children={<BiCalendar />}
                                color="gray.400"
                              />
                              <ReactDatePickerRoot
                                placeholderText={strings.paid_on}
                                dateFormat="yyyy-MM-dd"
                                customInput={<CreatedAtInput />}
                                onChange={handlePaidOnChange}
                                selected={paidStartDate}
                                startDate={paidStartDate}
                                endDate={paidEndDate}
                                selectsRange
                                value={getFormattedRangeDate(
                                  paidStartDate,
                                  paidEndDate
                                )}
                                shouldCloseOnSelect={false}
                                autoComplete="off"
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.status}</FormLabel>
                            <Select
                              name="status"
                              value={filterParams.status}
                              placeholder={strings.status}
                              rounded="sm"
                              onChange={handleInputChange}>
                              {PMS_PAYMENT_LINK_STATUS_OPTIONS?.map(
                                (status) => {
                                  return (
                                    <option
                                      key={status.value}
                                      value={status.value}>
                                      {strings[status.text]}
                                    </option>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing="4"
                      pt="4"
                      justifyContent="flex-end">
                      <Button
                        colorScheme="primary"
                        variant="outline"
                        size="lg"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.email_address}</Th>
                  <Th isNumeric>{strings.amount} (CHF)</Th>
                  <Th>{strings.customer_name}</Th>
                  <Th>{strings.booking_number}</Th>
                  <Th>{strings.payment_method}</Th>
                  <Th>{strings.last_sent_on}</Th>
                  <Th>{strings.paid_on}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!paymentLinksList.isLoading &&
                  paymentLinksList?.data?.data?.map(
                    (paymentLinkListData: any, index: number) => (
                      <PMSPaymentLinksListItem
                        key={paymentLinkListData.id}
                        index={startingSN + index}
                        paymentLinkListData={paymentLinkListData}
                      />
                    )
                  )}

                {paymentLinksList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={10} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={paymentLinksList}
        />
      </Stack>
    </>
  );
};

export default PaymentLinksList;

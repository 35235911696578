import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ExternalCompanyResource from 'api/external_company';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ExternalCompanySchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { maskPhone } from 'utils';

interface Props {
  companyData: ExternalCompanySchema;
  index: number;
  filterParams?: any;
  search: any;
}
const CompanyListItem: React.FC<Props> = (props) => {
  const { companyData, index, search } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const externalCompanyAPI = new ExternalCompanyResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const checkEditUserPermission = useCheckPermission(
    [PermissionRequest['manage:external-companies']],
    routes.users.externalCompanies.edit.replace(
      ':id',
      companyData.id.toString()
    )
  );

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteCompany = useMutation(
    () => externalCompanyAPI.destroy(companyData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('companyList');
        setDeleteModalOpen(false);
        toast({
          title: strings.company_deleted,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        setDeleteModalOpen(false);
        toast({
          title: strings.company_delete_error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteCompany.mutate();
  };

  return (
    <Tr key={companyData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.users.externalCompanies.profile.replace(
              ':id',
              companyData.id.toString()
            ) + search
          }>
          <HStack>
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {companyData.name}
            </Text>
          </HStack>
        </RouterLink>
      </Td>
      <Td>{companyData.email ?? '-'}</Td>
      <Td isNumeric>{maskPhone(companyData.phone) ?? '-'}</Td>

      <Td>
        <RouterLink
          to={
            routes.users.externalCompanies.profile.replace(
              ':id',
              companyData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_company}>
            <IconButton
              icon={<BiShow size="18" />}
              variant="link"
              aria-label={strings.view_company}
              color="green.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <Link onClick={checkEditUserPermission}>
          <Tooltip hasArrow label={strings.edit_company}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_company}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </Link>
        <Tooltip hasArrow label={strings.delete_company}>
          <IconButton
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete_company}
            color="red.300"
            minW="8"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_company}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['manage:external-companies'],
                  ]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    colorScheme="red"
                    onClick={onDeleteConfirm}
                    isLoading={deleteCompany.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default CompanyListItem;

import {
  Radio,
  RadioGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { Salutation } from 'constants/schema';
import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface Props {
  data: any;
  setMergeColumns: any;
  value: any;
}
let keys = [
  'first_name',
  'last_name',
  'type',
  'source',
  'location',
  'street',
  'house_no',
  'zip_code',
  'country_code',
  'company',
  'phone',
  'mobile',
  'email',
  'company',
  'is_subscribed',
  'salutation',
  'title',
  'city',
];
let detailsKey = ['internal_notes', 'external_notes', 'created_by'];

const MergeContactList: React.FC<Props> = (props) => {
  let { data, setMergeColumns, value: columns } = props;

  useEffect(() => {
    let df: any = {};
    if (data && Object.keys(columns).length === 0) {
      keys.forEach((key) => {
        let P = data.find((ele: any) => ele[key] !== null);
        if (P) df[key] = { id: P.id, val: P[key] };
      });
      detailsKey.forEach((key) => {
        let P = data.find((ele: any) => ele.contactDetail[key] !== null);
        if (P) df[key] = { id: P.id, val: P.contactDetail[key] };
      });

      let P = data.find((ele: any) => ele.country?.name !== null);
      if (P) df['country_name'] = { id: P.id, val: P.country?.name };

      setMergeColumns((prev: any) => {
        return { ...prev, ...df };
      });
    }
  }, [columns, data, setMergeColumns]);

  if (!data) return null;

  function onChange(name: string, id: any, val: any) {
    setMergeColumns((prev: any) => {
      return { ...prev, [name]: { id, val } };
    });
  }

  return (
    <>
      <TableContainer>
        <Table bg="white" p="4" shadow="box" rounded="sm">
          <Tbody>
            <RadioGroup as="tr" value={columns?.salutation?.id}>
              <Th>{strings.salutation}</Th>
              {data.map(({ id, salutation }: any) => {
                return (
                  <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                    <Radio
                      colorScheme="primary"
                      value={id ?? ''}
                      onClick={() => onChange('salutation', id, salutation)}>
                      {strings[salutation as Salutation]}
                    </Radio>
                  </Td>
                );
              })}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.title?.id}>
              <Th>{strings.title}</Th>
              {data.map(({ id, title }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('title', id, title)}
                    display="block">
                    {title ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.first_name?.id}>
              <Th>{strings.first_name}</Th>
              {data.map(({ id, first_name }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('first_name', id, first_name)}
                    display="block">
                    {first_name ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.last_name?.id}>
              <Th>{strings.last_name}</Th>

              {data.map(({ id, last_name }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    name="last_name"
                    onClick={() => onChange('last_name', id, last_name)}>
                    {last_name ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.source?.id}>
              <Th>{strings.source}</Th>

              {data.map(
                ({
                  id,
                  source,
                }: {
                  id: string;
                  source: 'manual' | 'v-office';
                }) => (
                  <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                    <Radio
                      colorScheme="primary"
                      value={id ?? ''}
                      onClick={() => onChange('source', id, source)}>
                      {strings[source]}
                    </Radio>
                  </Td>
                )
              )}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.city?.id}>
              <Th>{strings.city}</Th>

              {data.map(({ id, city }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('city', id, city)}>
                    {city ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.street?.id}>
              <Th>{strings.street}</Th>

              {data.map(({ id, street }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('street', id, street)}>
                    {street ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.house_no?.id}>
              <Th>{strings.house_no}</Th>

              {data.map(({ id, house_no }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('house_no', id, house_no)}>
                    {house_no ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.zip_code?.id}>
              <Th>{strings.post_code}</Th>

              {data.map(({ id, zip_code }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('zip_code', id, zip_code)}>
                    {zip_code ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.country_code?.id}>
              <Th>{strings.country}</Th>

              {data.map(({ id, country_code, country }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => {
                      onChange('country_name', id, country?.name);
                      onChange('country_code', id, country_code);
                    }}>
                    {country?.name ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.phone?.id}>
              <Th>{strings.phone_number}</Th>

              {data.map(({ id, phone }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('phone', id, phone)}>
                    {phone ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.mobile?.id}>
              <Th>{strings.mobile_number}</Th>

              {data.map(({ id, mobile }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('mobile', id, mobile)}>
                    {mobile ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.email?.id}>
              <Th>{strings.email}</Th>

              {data.map(({ id, email }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('email', id, email)}>
                    {email ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.company?.id}>
              <Th>{strings.company}</Th>

              {data.map(({ id, company }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() => onChange('company', id, company)}>
                    {company ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.is_subscribed?.id}>
              <Th>{strings.newsletter}</Th>

              {data.map(({ id, is_subscribed }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() =>
                      onChange('is_subscribed', id, is_subscribed)
                    }>
                    {is_subscribed ? strings.subscribe : strings.unsubscribe}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
            <RadioGroup as="tr" value={columns?.internal_notes?.id}>
              <Th>{strings.notes_notizen}</Th>

              {data.map(({ id, contactDetail: { internal_notes } }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  <Radio
                    colorScheme="primary"
                    value={id ?? ''}
                    onClick={() =>
                      onChange('internal_notes', id, internal_notes)
                    }>
                    {internal_notes ?? '-'}
                  </Radio>
                </Td>
              ))}
            </RadioGroup>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MergeContactList;

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  schemeData: any;
  type: string;
}

const SchemeFormByDuration: React.FC<Props> = (props) => {
  const { schemeData, type } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    reset,
    getValues,
  } = useFormContext<any>();

  useEffect(() => {
    return () => {
      let vals = { ...getValues() };
      //remove property prom object
      delete vals.scheme_data;
      reset(vals);
    };
  }, [getValues, reset]);
  useEffect(() => {
    if (!schemeData || type !== 'PriceSchemeByDuration') return;
    for (let index: number = 0; index < 2; index++) {
      setValue(
        `scheme_data.${index}.name`,
        schemeData[index]['meta']['duration']
      );
      setValue(`scheme_data.${index}.value`, schemeData[index]['price']);
    }
  }, [schemeData, setValue, type]);

  const schemeByDuration = () => {
    let content = [];

    for (let index: number = 0; index < 2; index++) {
      content.push(
        <Fragment key={index}>
          <GridItem>
            <FormControl isInvalid={
              //@ts-ignore
              //TODO Fix type error
              !!errors?.scheme_data?.[index]?.name}>
              <FormLabel>{strings.duration}</FormLabel>
              <Select
                size="lg"
                placeholder={strings.select}
                rounded="sm"
                {...register(`scheme_data.${index}.name`, {
                  required: strings.required,
                })}>
                <option value="PER_DAY">{strings.per_day} </option>
                <option value="PER_WEEK">{strings.per_week}</option>
              </Select>
              <FormErrorMessage>
                {
                  //@ts-ignore
                  //TODO Fix type error
                  errors?.scheme_data?.[index]?.name &&
                    //@ts-ignore
                    //TODO Fix type error
                  errors?.scheme_data?.[index]?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={
              //@ts-ignore
              //TODO Fix type error
              !!errors?.scheme_data?.[index]?.value}>
              <FormLabel>{strings.price} (CHF)</FormLabel>
              <Input
                type="number"
                defaultValue={0}
                size="lg"
                placeholder={strings.enter_value_in_cents}
                {...register(`scheme_data.${index}.value`, {
                  required: strings.required,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {
                  //@ts-ignore
                  //TODO Fix type error
                  errors?.scheme_data?.[index]?.value &&
                    //@ts-ignore
                    //TODO Fix type error
                  errors?.scheme_data?.[index]?.value?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Fragment>
      );
    }

    return content;
  };

  return (
    <>
      <Grid
        gap="4"
        templateColumns="repeat(2, 1fr)"
        flex="1"
        w="100%"
        borderRadius="lg"
        pt="2">
        {schemeByDuration()}
      </Grid>
    </>
  );
};

export default SchemeFormByDuration;

import { Td, Text, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  data: any;
  index: number;
  search?: string;
}

const ContactCenterUserListItem: React.FC<Props> = (props) => {
  const { data, index, search } = props;
  const { reservation_id: feedbackId, reservation, feedback_at } = data;
  const status: 'pending' | 'confirmed' = data.status;
  const { customer_info, booking_number, apartment, from_date, to_date } =
    reservation;

  const from_date_DD_MM_YY = DateFormat(from_date);
  const to_date_DD_MM_YY = DateFormat(to_date);
  const feedback_at_DD_MM_YY = DateFormat(feedback_at);

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.contactCenter.members.profile.replace(
              ':id',
              feedbackId.toString()
            ) + search
          }>
          <Text
            fontWeight="medium"
            color="gray.500"
            _hover={{ color: 'gray.600' }}>
            {`${customer_info?.forename} ${customer_info?.surname}`}
          </Text>
        </RouterLink>
      </Td>
      <Td>{booking_number}</Td>
      <Td>{apartment?.name}</Td>
      <Td isNumeric>{`${from_date_DD_MM_YY} - ${to_date_DD_MM_YY}`}</Td>
      <Td isNumeric> {feedback_at_DD_MM_YY}</Td>
      <Td>
        <Text
          color={status === 'pending' ? 'blue.300' : 'green.300'}
          fontWeight="medium">
          {strings[status]}
        </Text>
      </Td>
      <Td>-</Td>
      <Td>-</Td>
    </Tr>
  );
};

export default ContactCenterUserListItem;

import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  customerGroupDocumentData: any;
  index: number;
  search?: string;
}
const CustomerGroupDocumentListItem: React.FC<Props> = (props) => {
  const { customerGroupDocumentData, index, search } = props;

  return (
    <Tr key={customerGroupDocumentData.customer_id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.dms.customerDocuments.customer.list.replace(
              ':id',
              customerGroupDocumentData?.customer_id.toString()
            ) + search
          }>
          {customerGroupDocumentData?.customer?.full_name}
        </RouterLink>
      </Td>
      <Td isNumeric>{customerGroupDocumentData?.total || 0}</Td>
    </Tr>
  );
};

export default CustomerGroupDocumentListItem;

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { TICKET_CUSTOMER_SEARCH_API } from 'constants/common';
import routes from 'constants/routes';
import { ReactSelectOptionSchema } from 'constants/schema';
import useWordSearch from 'hooks/useWordSearch';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import history from 'utils/history';

const PRIORITIES = ['low', 'high', 'medium', 'urgent'];
const COSTRECIPIENTS = ['Guest', 'Object', 'Owner', 'Company', 'Other'];

interface Apartment {
  id: number;
  name: string;
}

interface User {
  id: number;
  email: string;
  image_url: string;
  name: string;
  status: boolean;
  is_intermixed: boolean;
}

interface Customer {
  id: number;
  name: string;
  is_intermixed: boolean;
}

interface TicketFormInputs {
  title: string;
  apartmentId: ReactSelectOptionSchema | null;
  assignedUserId: ReactSelectOptionSchema | null;
  customerId: ReactSelectOptionSchema | null;
  costRecipient: string;
  houseOwner: ReactSelectOptionSchema | null;
  priority: string;
  description: string;
  source: string;
  createdBy: string;
  updatedBy: string;
  due_date: Date;
}

interface Props {
  roomName: string;
}
const CreatedAtInput = forwardRef((props: any, ref: any) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const TicketForm: React.FC<Props> = (props) => {
  const { roomName } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const ticketAPI = new TicketResource();
  const methods = useForm<TicketFormInputs>();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [errMsg, setErrMsg] = useState('');
  const [draft, setDraft] = useState({
    title: '',
    apartmentId: null,
    assignedUserId: null,
    customerId: null,
    costRecipient: '',
    houseOwner: null,
    priority: '',
    description: '',
    due_date: new Date(),
  });
  const [ownerName, setOwnerName] = useState('');
  const [dueDate, setDueDate] = useState<Date | null>(new Date());

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const createTicket = useMutation((data: any) => ticketAPI.store(data));

  const {
    loading: customerListLoading,
    result: customerQuery,
    setQuery,
  } = useWordSearch(`${TICKET_CUSTOMER_SEARCH_API}/?is_owner=false&limit=50`);

  const apartmentQuery = useQuery(
    [`apartment-list-ticket`],
    () => ticketAPI.getApartmentList().then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const userQuery = useQuery(
    'user-list-ticket',
    () => ticketAPI.getUserList().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const apartmentList = apartmentQuery?.data?.data;
  const userList = userQuery?.data;
  const customerList = customerQuery?.data;

  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
    customer: apartment?.customer,
  }));

  const userOptions = userList?.map((user: User) => ({
    label: user.name,
    value: user.id,
  }));

  const customerOptions = customerList?.map((customer: Customer) => ({
    label: customer.name,
    value: customer.id,
    is_intermixed: customer.is_intermixed,
    isDisabled: customer.is_intermixed,
  }));

  useEffect(() => {
    setDraft({
      title: '',
      apartmentId: null,
      assignedUserId: null,
      customerId: null,
      costRecipient: '',
      houseOwner: null,
      priority: '',
      description: '',
      due_date: new Date(),
    });
    if (roomName) {
      const draftData: any = JSON.parse(
        localStorage.getItem(`form-${roomName}`) || '{}'
      );
      if (Object.keys(draftData).length !== 0) {
        setDraft({
          title: draftData?.title,
          apartmentId: draftData?.apartmentId,
          assignedUserId: draftData?.assignedUserId,
          customerId: draftData?.customerId,
          costRecipient: draftData?.costRecipient,
          houseOwner: draftData?.houseOwner,
          priority: draftData?.priority,
          description: draftData?.description,
          due_date: draftData?.due_date,
        });
        setOwnerName(draftData?.apartmentId?.customer?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomName]);

  /**
   * Create Ticket
   */
  const handleCreateTicket = async (data: TicketFormInputs) => {
    const cleanData = setFormData(data);
    createTicket.mutate(cleanData, {
      onSuccess: (res: any) => {
        toast({
          title: (
            <p
              onClick={() =>
                history.push(
                  routes.ticket.task.view.replace(
                    ':id',
                    res?.data?.id.toString()
                  )
                )
              }
              style={{ cursor: 'pointer' }}>
              {strings.ticket}#{res?.data?.id} {strings.has_been_created}
            </p>
          ),
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('tickets');
        handleReset();
      },
      onError: () => setErrMsg(strings.ticket_error),
    });
  };

  const handleDateChange = (date: Date) => {
    setDueDate(date);
    setValue('due_date', date);
  };

  /**
   * Remove keys if no data
   */
  const setFormData = (data: TicketFormInputs) => {
    let formData: any = {
      title: data.title,
      apartment_id: data.apartmentId?.value,
      assigned_user_id: data.assignedUserId?.value,
      cost_recipient: data.costRecipient,
      house_owner: data.houseOwner?.value,
      customer_id: data.customerId?.value,
      description: data.description,
      priority: data.priority,
      source: 'Chat',
      created_by: loggedInUser.id,
      updated_by: loggedInUser.id,
    };

    let cleanObj = {};
    Object.keys(formData).forEach((val: any) => {
      const newVal = formData[val];
      cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
    });
    return cleanObj;
  };

  /**
   * Reset inputs
   */
  const handleReset = () => {
    setDraft({
      title: '',
      apartmentId: null,
      assignedUserId: null,
      customerId: null,
      costRecipient: '',
      houseOwner: null,
      priority: '',
      description: '',
      due_date: new Date(),
    });
    setOwnerName('');
    form.current?.reset();
    localStorage.removeItem(`form-${roomName}`);
    setErrMsg('');
  };

  /**
   * Handle Field Change for Draft
   */
  const handleFieldChange = (fieldName: string, value: any) => {
    if (fieldName === 'apartmentId' && value?.customer?.id) {
      setOwnerName(value?.customer?.name);
      setValue('houseOwner', {
        value: value?.customer?.id,
        label: value?.customer?.name,
      });
      setDraft((draft) => ({ ...draft, houseOwner: value?.customer?.id }));
      localStorage.setItem(
        `form-${roomName}`,
        JSON.stringify({ ...draft, houseOwner: value?.customer?.id })
      );
    }
    setDraft((draft) => ({ ...draft, [fieldName]: value }));
    localStorage.setItem(
      `form-${roomName}`,
      JSON.stringify({ ...draft, [fieldName]: value })
    );
  };

  const handleCustomerChange = (value: string) => {
    if (!value) return;
    setQuery({ name: value });
  };

  return (
    <Stack
      direction="column"
      spacing="4"
      bg="white"
      shadow="box"
      rounded="md"
      maxH="790px"
      overflow="auto">
      <Heading size="sm" px="4" paddingTop="4" textTransform="capitalize">
        {strings.create_ticket}
      </Heading>
      <Stack>
        <form ref={form} onSubmit={methods.handleSubmit(handleCreateTicket)}>
          <Stack direction="column" spacing="4" p="4">
            {errMsg && (
              <Alert status="error">
                <AlertIcon />
                {errMsg}
              </Alert>
            )}
            <FormControl isInvalid={!!errors?.title} isRequired>
              <FormLabel>{strings.title}</FormLabel>
              <Input
                id="title"
                size="lg"
                type="text"
                placeholder={strings.title}
                value={draft.title || ''}
                {...register('title', {
                  required: strings.title_required,
                })}
                onChange={(e: any) =>
                  handleFieldChange('title', e.target.value)
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>{strings.apartment}</FormLabel>
              <Controller
                control={control}
                name="apartmentId"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <ReactSelect
                    id="apartmentId"
                    onBlur={onBlur}
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                      handleFieldChange('apartmentId', selectedOption);
                    }}
                    value={draft.apartmentId || null}
                    ref={ref}
                    placeholder={strings.select_apartment}
                    options={apartmentOptions}
                    styles={reactSelectStyles}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{strings.assignee}</FormLabel>
              <Controller
                control={control}
                name="assignedUserId"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <ReactSelect
                    id="assignedUserId"
                    onBlur={onBlur}
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                      handleFieldChange('assignedUserId', selectedOption);
                    }}
                    value={draft.assignedUserId || null}
                    ref={ref}
                    placeholder={strings.select_assignee}
                    options={userOptions}
                    styles={reactSelectStyles}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{strings.customer}</FormLabel>
              <Controller
                control={control}
                name="customerId"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <ReactSelect
                    id="customerId"
                    onBlur={onBlur}
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                      handleFieldChange('customerId', selectedOption);
                    }}
                    onInputChange={handleCustomerChange}
                    isLoading={customerListLoading}
                    value={draft.customerId || null}
                    ref={ref}
                    placeholder={strings.select_customer}
                    options={customerOptions}
                    styles={reactSelectStyles}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{strings.cost_recipient}</FormLabel>
              <Select
                id="costRecipient"
                size="lg"
                placeholder={strings.select_cost_recepient}
                value={draft.costRecipient}
                {...methods.register('costRecipient')}
                onChange={(e: any) =>
                  handleFieldChange('costRecipient', e.target.value)
                }>
                {COSTRECIPIENTS.map((costRecipient: any) => {
                  return (
                    <option key={costRecipient} value={costRecipient}>
                      {strings.getString(costRecipient.toLowerCase())}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.house_owner}</FormLabel>
              <Input
                id="houseOwner"
                type="hidden"
                {...register('houseOwner')}
              />
              <Input id="houseOwner" value={ownerName} size="lg" disabled />
            </FormControl>
            <FormControl>
              <FormLabel>
                {strings.priority} {draft.priority}
              </FormLabel>
              <Select
                size="lg"
                value={draft.priority}
                id="priority"
                placeholder={strings.select_priority}
                {...methods.register('priority')}
                onChange={(e: any) =>
                  handleFieldChange('priority', e.target.value)
                }>
                {PRIORITIES.map((priority: any) => {
                  return (
                    <option key={priority} value={priority}>
                      {strings
                        .getString(priority.split('-').join(''))
                        .toUpperCase()}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                id="description"
                placeholder={strings.write_description}
                value={draft.description}
                {...methods.register('description')}
                onChange={(e: any) =>
                  handleFieldChange('description', e.target.value)
                }
              />
            </FormControl>
            <Box>
              <Stack direction="row" spacing="4" justifyContent="flex-end">
                <Button onClick={handleReset} variant="outline" size="md">
                  {strings.cancel}
                </Button>
                <Button
                  bg="chat.400"
                  color="white"
                  _hover={{ bg: 'chat.500' }}
                  _active={{ bg: 'chat.500' }}
                  type="submit"
                  isLoading={createTicket.isLoading}
                  size="md">
                  {strings.create_ticket}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
};

export default TicketForm;
